<template>
    <div class="switch-lector-bar">
        <img src="@/assets/img/schedule/users.svg" alt="" class="switch-lector-bar__icon">
        <div class="switch-lector-bar__area d-flex justify-content-between">
            <div class="d-flex align-items-center gap-5">
                {{$i18n.t('switch-lector.bar.try-another-lector')}}
            </div>
            <div
                @click="runSwitchLectorFlow"
                class="d-flex align-items-center gap-5 c-pointer"
            >
                <span class="switch-lector-bar__link">{{$i18n.t('switch-lector.bar.check-lectors')}}</span>
                <img src="@/assets/img/arrow-blue.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SwitchLectorBar',
    computed: {
        ...mapState({
            specialityData: state => state.schedule.switchLector.specialityData
        }),
    },
    methods: {
        runSwitchLectorFlow() {
            this.$store.dispatch('schedule/runSwitchLectorFlow');
        }
    }
}
</script>

<style scoped lang="scss">
    .switch-lector-bar {
        margin: 24px 0 0;
        width: 100%;
        display: flex;
        border: 1px solid $gray-300;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px;
        border-radius: 6px;
        flex-wrap: wrap;
    }

    .switch-lector-bar__area {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        column-gap: 10px;
    }

    .switch-lector-bar__icon {
        margin-right: 10px;
        padding: 4px 0;
    }

    .switch-lector-bar__link {
        color: $primary;
    }

    .gap-5 { 
        gap: 5px;
    }

    .c-pointer {
        cursor: pointer;
    }
</style>