import isBrowserSupported, {supportedBrowserListHtml} from './helpers/browserSupport';
import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n, locales } from '@/i18n/i18n'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Notifications from 'vue-notification'
import { ValidationObserver, ValidationProvider} from 'vee-validate'
import './plugins/veeValidate'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import VueTelInput from 'vue-tel-input'
import './registerServiceWorker'

if (isBrowserSupported()) {
    Vue.config.productionTip = false
    Vue.prototype.$bus = new Vue()
    Vue.prototype.$gtag = data => {
        window.dataLayer = window.dataLayer || []
        data.event = data.event || 'userAction'

        window.dataLayer.push(data)
    }
    Vue.use(VueMoment, {
        moment
    })
    Vue.use(VueMeta)
    Vue.use(Notifications)
    //vee-validate
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    Vue.component('VueTimepicker', VueTimepicker)
    Vue.component('VueSlider', VueSlider)
    Vue.component('qr-code', VueQRCodeComponent)
    Vue.use(VueTelInput)

    //locales
    router.beforeEach((to, from, next) => {
        const locale = locales[to.params.locale]

        if(!locale) {
            let newLocale = localStorage.getItem('locale')
            if(!newLocale) {
                newLocale = navigator.language.slice(0, 2)
                newLocale = newLocale === 'cs' ? 'cz' : newLocale
                if(!Object.keys(locales).includes(newLocale)) newLocale = 'en'
            }

            next({name: 'login', params: {locale: newLocale}})
        } else {
            if(from.params.locale !== to.params.locale) {
                //save locale as actual
                localStorage.setItem('locale', to.params.locale)
                i18n.locale = locale
                moment.locale(locale)
                //set Monday as a first day of a week
                moment.updateLocale(locale, {
                    week: {
                        dow: 1
                    }
                })
            }
            next()
        }
    })

    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app')

    if(window.Cypress) {
        window.app = {
            router,
            store,
            pl: i18n.messages.pl,
            en: i18n.messages.en,
            graphql: {},
            moment
        }
    }
} else {
    const supportInfoEl = document.querySelector('.js-browser-unsupported');

    supportInfoEl.innerHTML = supportedBrowserListHtml(i18n);
    supportInfoEl.classList.remove('hide');
}
