<template>
    <div :class="[
        'learning__footer',
        {
            'learning__footer--correct': isTaskFinished && isAnswerCorrect,
            'learning__footer--incorrect': isTaskFinished && !isAnswerCorrect,
            'learning__footer--half-correct': isTaskFinished && isAnswerCorrect === 'HALF_CORRECT' || (isFlashcardsActivity && dontKnow),
        }
    ]">
        <div :class="['learning__footer-wrapper', { 'learning__footer-wrapper--summary': isSummary, 'learning__footer-wrapper--goals': isGoals }]">
            <template v-if="isSummary">
                <Button
                    class="default"
                    :text="$t('learning.summary.return-to-space', transUi)"
                    @click.native="handleCloseAction"
                />
                <Button
                    v-if="!isCompleted || getNextMaterial"
                    class="primary ml-auto"
                    :text="isCompleted ? $t('learning.summary.next-lesson', transUi) : $t('learning.activities.footer.continue', transUi)"
                    @click.native="handleSummaryPrimaryBtn"
                    :loading="summaryPrimaryBtnLoading"
                />
            </template>
            <template v-else-if="isTaskFinished">
                <Button
                    v-if="isPreviewMode"
                    class="default"
                    :text="$t('learning.activities.footer.back', transUi)"
                    @click.native="$store.commit('learning/previousActivity', {
                        store: $store
                    })"
                />
                <div v-if="showAnswer" class="answer">
                    <StatusIcon v-if="!isMobile && !isFakeActivity" :success="isAnswerCorrect" />
                    <div v-if="!isFakeActivity" class="answer__header">
                        <div class="answer__title">
                            <StatusIcon v-if="isMobile" :size="24" :iconSize="16" :success="isAnswerCorrect" />
                            {{ answerTitle }}
                        </div>
                        <div class="answer__info">
                            <p class="answer__status">{{ answerStatus }}</p>
                            <p v-if="((!isAnswerCorrect || isAnswerCorrect === 'HALF_CORRECT') || getCurrentSlide.activity?.type === 'open_ended') && getCorrectAnswer" class="answer__correct-answer">{{ getCorrectAnswer }}</p>
                        </div>
                    </div>
                </div>
                <Button
                    :class="[correctAnswerClass, 'elearning-go-forward']"
                    :text="$t('learning.activities.footer.continue', transUi)"
                    @click.native="$store.commit('learning/nextActivity')"
                />
            </template>
            <template v-else>
                <Button
                    v-if="handleBtns.default"
                    :class="['default', handleBtns.default.class]"
                    :text="handleBtns.default.text"
                    v-bind="handleBtns.default.props"
                    @click.native="handleBtns.default.onClick"
                    :disabled="handleBtns.default.disabled" />

                <Button
                    v-if="handleBtns.primary"
                    :text="handleBtns.primary.text"
                    :class="[handleBtns.primary.class, 'elearning-go-forward']"
                    v-bind="handleBtns.primary.props"
                    @click.native="handleBtns.primary.onClick"
                    :disabled="handleBtns.primary.disabled"
                    :loading="handleBtns.primary.loading" />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Button from '@/components/reusable/Button'
import StatusIcon from '@/components/reusable/StatusIcon'
import { ADDITIONAL_SLIDES } from '@/constants/learning';
import returnToSpace from '@/mixins/returnToSpace'

export default {
    name: 'LearningFooter',
    components: {
        Button,
        StatusIcon
    },
    data() {
        return {
            handleKeyPress: null,
            summaryPrimaryBtnLoading: false
        }
    },
    mounted() {
        this.handleKeyPress = (e) => {
            if (e.key === 'Enter') document.querySelector('.elearning-go-forward')?.click();
        };

        document.body.addEventListener('keypress', this.handleKeyPress);
    },
    beforeDestroy() {
        document.body.removeEventListener('keypress', this.handleKeyPress);
    },
    mixins: [returnToSpace],
    computed: {
        ...mapState(['windowWidth']),
        ...mapState('learning', ['currentSlideIndex', 'currentTaskIndex', 'taskLoading', 'learningStatus', 'flashcardsStatus']),
        ...mapState('flashcards', ['currentAnswer', 'dontKnow']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('learningCoursePlan', ['getAllMaterials']),
        ...mapGetters('learning', ['isMobile', 'getProgressPercent', 'isPreviewMode', 'isTaskFinished', 'isAnswerCorrect', 'getCorrectAnswer', 'getUserAnswer', 'getCurrentSlide', 'getCurrentSlideType', 'getCurrentTask', 'isFlashcardsActivity', 'isFakeActivity', 'isSummarySlide', 'isAdditionalSlide']),
        ...mapGetters('flashcards', ['isDefinitionExercise', 'isChoiceExercise', 'isOrderExercise']),
        ...mapGetters({
            getCorrectAnswerFlashcards: 'flashcards/getCorrectAnswer'
        }),
        isSummary() {
            return this.getCurrentSlideType === ADDITIONAL_SLIDES.ITERATION_SUMMARY;
        },
        isGoals() {
            return this.getCurrentSlideType === ADDITIONAL_SLIDES.GOALS;
        },
        answerTitle() {
            if(this.isAnswerCorrect === 'HALF_CORRECT') return this.$t('learning.activities.half-correct', this.transUi)
            return this.$t(this.isAnswerCorrect ? 'learning.activities.correct-answer' : 'learning.activities.incorrect-answer', this.transUi)
        },
        answerStatus() {
            if(this.isAnswerCorrect === 'HALF_CORRECT') return this.$t('learning.activities.correct-answer-presentation', this.transUi)
            return this.$t(this.isAnswerCorrect ? 'learning.activities.congratulations' : 'learning.activities.correct-answer-presentation', this.transUi)
        },
        isCheckBtnDisabled() {
            if(this.isFlashcardsActivity) {
                if(this.isOrderExercise) return this.getCorrectAnswerFlashcards.length !== this.currentAnswer?.length

                return !this.currentAnswer && !this.isDefinitionExercise
            }

            if(this.getCurrentSlide.activity?.type === 'right_order') {
                return this.getUserAnswer?.length !== Object.values(this.getCurrentTask.data.wordList).length
            }

            if (this.getCurrentSlide.activity?.type === 'gaps_word_list') {
                return Object.keys(this.getUserAnswer || {})?.length !== this.getCurrentTask.data.text.filter(el => el.type === 'blank').length
            }

            return !this.getUserAnswer && !this.isFakeActivity
        },
        correctAnswerClass() {
            if (this.isFakeActivity) return 'primary ml-auto';
            if (this.isAnswerCorrect === 'HALF_CORRECT' || (this.isFlashcardsActivity && this.dontKnow)) return 'warning-default ml-auto';
            return this.isAnswerCorrect ? 'success-default' : 'danger-middle'
        },
        handleBtns() {
            return {
                default: this.handleDefaultBtn,
                primary: this.handlePrimaryBtn
            }
        },
        handleDefaultBtn() {
            if (this.getCurrentSlideType === ADDITIONAL_SLIDES.GOALS 
                && this.windowWidth < 768
                && ([ADDITIONAL_SLIDES.GOALS, ADDITIONAL_SLIDES.ITERATION_SUMMARY].includes(this.getCurrentSlideType))) {
                    return {
                        text: this.$t('learning.summary.return-to-space', this.transUi),
                        onClick: this.returnToSpace,
                        class: 'order-2'
                    };
            }

            if (this.isFlashcardsActivity && !this.isDefinitionExercise) {
                return {
                    text: this.$t('learning.activities.footer.dont-know', this.transUi),
                    onClick: this.flashcardsSetDontKnow
                };
            }

            return null;
        },
        handlePrimaryBtn() {
            if (this.getCurrentSlideType === ADDITIONAL_SLIDES.GOALS) {
                if (this.learningStatus === 'NEW' && this.flashcardsStatus === 'NEW') {
                    // let's start
                    if (!this.isSummarySlide) return {
                        class: 'primary ml-auto',
                        text: this.$t('learning.goals.footer.lets-start', this.transUi),
                        onClick: () => { this.$store.dispatch('learning/submitTask'); },
                        loading: this.taskLoading
                    }
                } else if (this.learningStatus === 'COMPLETED' && this.flashcardsStatus === 'COMPLETED') {
                    // preview
                    if (!this.isSummarySlide) return {
                        class: 'secondary ml-auto',
                        text: this.$t('learning.goals.footer.preview', this.transUi),
                        onClick: () => { this.$store.dispatch('learning/submitTask'); },
                        loading: this.taskLoading
                    }
                } else {
                    // continue
                    if (!this.isSummarySlide) return {
                        class: 'primary ml-auto',
                        text: this.$t('learning.goals.footer.continue', this.transUi),
                        onClick: () => { this.$store.dispatch('learning/submitTask'); },
                        loading: this.taskLoading
                    }
                }
            } else {
                if (!this.isSummarySlide) {
                    return {
                        class: 'primary ml-auto',
                        text: this.isDefinitionExercise ? this.$t('learning.activities.footer.continue', this.transUi) : this.$t('learning.activities.footer.check', this.transUi),
                        onClick: () => this.$store.dispatch('learning/submitTask'),
                        disabled: this.isCheckBtnDisabled,
                        loading: this.taskLoading
                    }
                }
                return null;
            }
            return null;
        },
        showAnswer() {
            return !this.isPreviewMode || (this.isPreviewMode && this.getCurrentSlide.activity?.type === 'open_ended')
        },
        isCompleted() {
            return this.getProgressPercent === 100
        },
        getNextMaterial() {
            const currentMaterialIndex = this.getAllMaterials.findIndex(material => material.materialUuid === this.$route.params.uuid)
            const nextMaterial = this.getAllMaterials[currentMaterialIndex + 1]

            return nextMaterial
        }
    },
    methods: {
        flashcardsSetDontKnow() {
            this.$store.commit('flashcards/setDontKnow', true)
            const uuid = this.getCurrentSlide?.uuid;

            if(this.isChoiceExercise) setTimeout(() => this.$bus.$emit('flashcardsAudio', uuid), 1000);
        },
        async handleSummaryPrimaryBtn() {
            if(!this.isCompleted) return this.$store.dispatch('learning/getData', {
                uuid: this.$route.params.uuid,
                forceSlideIndex: 1
            })
            if(!this.getNextMaterial.materialUuid) {
                this.summaryPrimaryBtnLoading = true
                await this.$store.dispatch('learningCoursePlan/initLearningCoursePlan')
                this.summaryPrimaryBtnLoading = false
            }

            this.$router.push({ name: 'elearning', params: {
                uuid: this.getNextMaterial.materialUuid,
                coursePlanUuid: this.$route.params.coursePlanUuid
            }})
        },
        handleCloseAction() {
            if (window.history.length > 2) this.$router.go(-1);
            else {
                let routeObject = { name: 'learningCoursePlan' };

                const currentRouteParams = this.$route.params;

                if (currentRouteParams && currentRouteParams.coursePlanUuid) routeObject.params = { coursePlanUuid: currentRouteParams.coursePlanUuid };

                this.$router.push(routeObject);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order-2 {
    order: 2
}

.learning__footer {
    position: sticky;
    bottom: 0;
    padding: 16px 16px 32px 16px;
    background: $white;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid $gray-200;
    z-index: 1;

    @media(min-width: $mobile-learning) {
        padding: 12px 20px;
        bottom: 24px;
    }

    &--correct {
        color: $secondary-dark;
        background: $secondary-light;
        border-color:  $secondary-light;
    }

    &--incorrect {
        color: $danger-dark;
        background: $danger-light;
        border-color:  $danger-light;
    }

    &--half-correct {
        color: $orange;
        background: $orange-light;
        border-color:  $orange-light;
    }
}

.learning__footer-wrapper {
    width: 730px;
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    &--summary {
        opacity: 0;
        animation: fade-in 1.5s ease-out forwards 1s;

        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @media (max-width: $mobile-learning) {
            flex-direction: column-reverse;
        }
    }

    @media(min-width: $mobile-learning) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: 88px;
    }

    .btn {
        flex-shrink: 0;
        width: 100%;

        @media(min-width: $mobile-learning) {
            width: auto;
            @include font-h3;
            padding: 11px 31px;
        }

        &.ml-auto {
            margin-left: auto;
        }
    }
}

.answer {
    display: flex;
    align-items: center;
    gap: 16px;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media(min-width: $mobile-learning) {
            gap: 0;
        }
    }

    &__title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        display: flex;
        gap: 12px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media(min-width: $mobile-learning) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__status {
        @include font-small-12-regular;
        font-weight: 600;

        @media(min-width: $mobile-learning) {
            font-weight: 400;
        }
    }

    &__correct-answer {
        @include font-small-12-regular;
    }
}
</style>