<template>
    <div :data-demo-student="isDemo" v-if="!loading">
        <router-view/>
        <LessonSurvey 
            v-if="surveyModal" 
            @hide="hideSurveyModal"
            :data="surveyModalData"
        />

        <Schedule v-if="scheduleModal" />
        <ToolsGeneral />
        <UnverifiedEmailLayer v-if="isEmailUnverified"/>
    </div>
</template>

<script>
import { userData } from '@/graphql/queries/userData'
import { messageByUuid } from '@/graphql/queries/messageByUuid'
import { senderOfMessageByUuid } from '@/graphql/queries/senderOfMessageByUuid'
import { lessonByUuid } from '@/graphql/queries/lessonByUuid'
import api from '@/graphql/api.js'
import LessonSurvey from '@/components/modals/LessonSurvey'
import Schedule from '@/components/modals/Schedule'
import getLessonSurveyData from '@/functions/getLessonSurveyData'
import defaultLogout from '@/functions/defaultLogout'
import Pusher from 'pusher-js'
import ToolsGeneral from '@/components/tools/ToolsGeneral'
import UnverifiedEmailLayer from '@/components/layout/UnverifiedEmailLayer'
import mobileAuth from '@/mixins/mobileAuth';
import { mapGetters, mapState } from 'vuex'

import { SCHEDULE_MODE, SCHEDULE_SCREEN } from '@/constants/schedule';
import { APP_MODES } from '@/router/appModes'

export default {
    name: 'LoggedWrapper',
    components: {
        LessonSurvey,
        Schedule,
        ToolsGeneral,
        UnverifiedEmailLayer
    },
    mixins: [mobileAuth],
    data() {
        return {
            pusherChannel: null,
            loading: true,
            surveyModal: false,
            surveyModalData: null,
            sendMobileAppStatusTimeout: 0
        }
    },
    created() {
        this.$bus.$on('lessonSurvey', data => this.showSurveyModal(data))

        setTimeout(() => {
            if (this.isIOSApp || this.isAndroidApp) this.sendMobileAppStatus();
        }, 500);
    },
    beforeDestroy() {
        this.$bus.$off('lessonSurvey');
        this.pusherChannel?.unbind_all();
        clearTimeout(this.sendMobileAppStatusTimeout);
    },
    async mounted() {
        const { res:user } = await api(userData, {}, 'userData')

        if(!user || (user.currentUser.type === 'teacher' && this.$route.name !== 'messagesOnly')) {
            defaultLogout()
            this.$router.push({name: 'login'})
            return
        }
        this.$store.commit('setUser', user.currentUser)
        this.$store.commit('updateConfig', user.configuration)
        this.$moment.tz.setDefault(this.$store.getters.timezone)
        this.setAccountRedirect()
        this.setLocale()
        const appModeData = this.setAppMode()

        if(appModeData?.redirectToLogin) {
            defaultLogout()
            return this.$router.push({ name: 'login' })
        }

        if(this.isEmailUnverified) document.documentElement.classList.add('overflow-y-hidden')

        const pusher = new Pusher('2a7c7171f024f359c64f', {
            cluster: 'eu',
            forceTLS: true
        })

        this.pusherChannel = pusher.subscribe(this.$store.getters.userUuid)

        this.pusherChannel.bind('SPACE_LESSON_STARTED', async data => {
            this.$bus.$emit('updateDashboard')            

            const {res:lesson} = await api(lessonByUuid, {
                lessonUuid: data.lessonUuid
            })

            const joinLessonUrl = lesson.lessonByUuid.studentLesson.joinLessonUrl
            this.$bus.$emit('updateLesson', lesson.lessonByUuid)
            if(joinLessonUrl) {
                this.$notify({
                    title: this.$i18n.t('notify.start-lesson.title'),
                    text: this.$i18n.t('notify.start-lesson.subtitle'),
                    data: {
                        type: 'success',
                        links: [
                            {label: this.$i18n.t('notify.start-lesson.link'), href: joinLessonUrl}
                        ]
                    }
                })
            }
        })

        this.pusherChannel.bind('SPACE_LESSON_FINISHED', () => {
            this.$bus.$emit('updateDashboard')            
            this.$bus.$emit('updateLesson')
        })

        this.pusherChannel.bind('SPACE_MESSAGE_CREATED', async data => {
            this.$bus.$emit('WS::getNextMessages', data.messageGroupUuid)

            const disableRoutes = ['messages', 'setAccount', 'messagesOnly']
            const disableGroup = this.$route.name === 'singleLesson' && data.messageGroupUuid === this.$route.meta.messageGroupUuid && this.$store.state.windowWidth > 1200
            
            //show notification
            if(!disableRoutes.includes(this.$route.name) && !disableGroup) {
                const { res } = await api(senderOfMessageByUuid, {
                    messageUuid: data.messageUuid
                })

                if(res.messageByUuid.sender.uuid !== this.$store.getters.studentUuid) {
                    this.$store.dispatch('getUnreadMessagesCount')

                    this.$notify({
                        title: res.messageByUuid.sender.firstName,
                        text: `${this.$i18n.t('notify.new-message.title')} ${data.messageGroupName}.`,
                        data: {
                            type: 'msg',
                            buttons: [
                                {label: this.$i18n.t('notify.new-message.subtitle'), push: {name: 'messages', params: {groupUuid: data.messageGroupUuid}}}
                            ],
                            avatarUrl: res.messageByUuid.sender.avatarUrl
                        },
                        ignoreDuplicates: true
                    })
                }
            }
        })

        this.pusherChannel.bind('SPACE_MESSAGE_UPDATED', async data => {
            const { res } = await api(messageByUuid, {
                messageUuid: data.messageUuid
            })

            this.$store.commit('handleUpdatedChatMessage', {
                ...res.messageByUuid,
                messageGroupUuid: data.messageGroupUuid,
                platformMessageUuid: data.platformMessageUuid
            })
        })

        this.pusherChannel.bind('SPACE_GUIDE_UPDATED', () => {
            this.$bus.$emit('updateGuide')
        })

        this.pusherChannel.bind('SPACE_ONBOARDING_MEETING_BOOKED', () => {
            this.$bus.$emit('meetingBooked')
        })

        this.pusherChannel.bind('SPACE_EMAIL_VERIFIED', () => {
            this.user.isEmailVerified = true
            document.documentElement.classList.remove('overflow-y-hidden')

            this.$notify({
                title: this.$i18n.t('notify.email-verified.title'),
                text: this.$i18n.t('notify.email-verified.subtitle'),
                data: {
                    type: 'success'
                }
            })
        })

        this.pusherChannel.bind('SPACE_UPDATE_PROGRESS_TEST', () => {
            this.$bus.$emit('updateDashboard')
        })
        
        this.pusherChannel.bind('SPACE_UPDATE_PLACEMENT_TEST', () => {
            this.$bus.$emit('updateDashboard')
        })

        this.pusherChannel.bind('SPACE_UPDATE_TEACHER_AI_MESSAGES', () => {
            this.$store.dispatch('tools/getTeacherAIChatMessages');
        })

        this.pusherChannel.bind('SPACE_UPDATE_TEACHER_AI_GAME_MESSAGES', (data) => {
            this.$store.dispatch('tools/getTeacherAIGameMessages', {
                gameUuid: data.gameUuid
            })
        })

        this.pusherChannel.bind('SPACE_RESTART_TEACHER_AI_GAME', (data) => {
            this.$store.dispatch('tools/getTeacherAIGameMessages', {
                gameUuid: data.gameUuid
            })
        })

        /* if(process.env.NODE_ENV === 'production') {
            //zendesk
            let zendesk = document.createElement('script')
            zendesk.innerHTML = `
            $zopim(function() {
            $zopim.livechat.setName('${this.$store.state.user.firstName} ${this.$store.state.user.lastName}');
            $zopim.livechat.setEmail('${this.$store.state.user.email}');
            });
            `
            document.head.appendChild(zendesk)
        } */

        if(!this.$store.getters.actionRequired) {
            const modalParams = this.$route.params.modal

            if(modalParams?.name === 'lessonSurvey') {
                const lessonUuid = modalParams.data.lessonUuid

                const {data} = await getLessonSurveyData(lessonUuid)
                this.showSurveyModal(data)
            }

            if(modalParams?.name === SCHEDULE_MODE.CANCEL_LESSON || modalParams?.name === SCHEDULE_MODE.BOOK_LESSON) {
                this.showScheduleModalFromParams(modalParams);
            }

            if(modalParams?.name === 'loadSchedule') {
                this.$store.dispatch("schedule/showScheduleModal");
            }
        }

        if(user.currentUser.type === 'student') {
            await this.$store.dispatch('getBookingInfo')

            try {
                this.$store.dispatch('tools/getAvailableDictionaries');
            } catch (err) {
                console.error('Dictionary and synonym tools are unavailable')
            }
        }

        if (this.$route.hash === '#selectNewTeacher') this.openSwitchLectorModal();

        this.loading = false
    },
    computed: {
        ...mapState(['windowWidth', 'isMobile', 'user', 'isIOSApp', 'isAndroidApp']),
        ...mapState('schedule', [ 'scheduleModal' ]),
        ...mapGetters([
            'actionRequired',
            'isBusiness',
            'isDemo',
            'isSpaceModeAvailable',
            'isLearningModeAvailable',
            'isSpaceMode',
            'isLearningMode'
        ]),
        isEmailUnverified() {
            return !this.user.isEmailVerified && this.$route.name !== 'setAccount'
        }
    },
    methods: {
        showSurveyModal(data) {
            if(data) {
                this.surveyModalData = data
                this.surveyModal = true
            }
        },
        hideSurveyModal() {
            this.surveyModal = false
        },
        showScheduleModalFromParams(params) {
            const { name, data } = params;
            this.$store.commit('schedule/setMode', { name, params: data });
        },
        setAccountRedirect() {
            if(sessionStorage.getItem('isEmployee')) return

            if(this.actionRequired && this.$route.name !== 'setAccount') this.$router.push({name: 'setAccount'})
            else if(!this.actionRequired && this.$route.name === 'setAccount') {
                const { SPACE, LEARNING } = APP_MODES

                if(this.isSpaceModeAvailable) return this.$router.replace({ name: SPACE.defaultRoute })
                if(this.isLearningModeAvailable) return this.$router.replace({ name: LEARNING.defaultRoute })
            }
        },
        setLocale() {
            if(this.$store.state.user.locale !== this.$route.params.locale) {
                const URL = window.location.href.split('/')
                URL[3] = this.$store.state.user.locale
                
                window.location.href = URL.join('/')
            }
        },
        setAppMode() {
            if(['messagesOnly', 'setAccount'].includes(this.$route.name)) return

            if(!this.isSpaceModeAvailable && !this.isLearningModeAvailable) return { redirectToLogin: true }

            const { SPACE, LEARNING } = APP_MODES
            const lastAppMode = sessionStorage.getItem('isEmployee') ? null : localStorage.getItem('appMode')
            const replaceRoute = name => this.$router.replace({ name }).catch(() => {})

            if(this.$route.params.forceAppMode) {
                if(lastAppMode === SPACE.type && this.isSpaceModeAvailable) return replaceRoute(SPACE.defaultRoute)
                if(lastAppMode === LEARNING.type && this.isLearningModeAvailable) return replaceRoute(LEARNING.defaultRoute)
                
                if(this.isSpaceModeAvailable) return replaceRoute(SPACE.defaultRoute)
                if(this.isLearningModeAvailable) return replaceRoute(LEARNING.defaultRoute)
            }

            if(this.isSpaceMode && !this.isSpaceModeAvailable) return replaceRoute(LEARNING.defaultRoute)
            if(this.isLearningMode && !this.isLearningModeAvailable) return replaceRoute(SPACE.defaultRoute)
        },
        openSwitchLectorModal() {
            this.$store.dispatch('schedule/showScheduleModal', { forceScreen: SCHEDULE_SCREEN.SPECIALITY });
        },
        sendMobileAppStatus() {
            this.$store.dispatch('sendAppStatus');
            this.sendMobileAppStatusTimeout = setTimeout(this.sendMobileAppStatus, 1000 * 60 * 5);
        }
    },
    watch: {
        $route() {
            this.setAccountRedirect()
            this.setLocale()
            if (this.isMobile) {
                this.$store.commit('tools/setToolsButtons', false)
            }
            this.$store.state.blurBackground = false;
            if(this.isEmailUnverified) document.documentElement.classList.add('overflow-y-hidden')
        }
    }
}
</script>

