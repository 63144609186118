import { SCHEDULE_MODE } from '@/constants/schedule';

const openBookLessonModal = {
    methods: {
        openBookLessonModal(course) {
            const { 
                instantBookingUrl, 
                group: { type },
                studentPackageGroup: { language: { shortName: lang } }
            } = course;

            const scheduleUrlParamsArray = instantBookingUrl.split('/');

            const studentUuid = scheduleUrlParamsArray[5];
            const groupUuid = scheduleUrlParamsArray[6];
            const userUuid = scheduleUrlParamsArray[7];
            
            this.$store.state.schedule.selectedCourse.type = type;
            this.$store.state.schedule.selectedCourse.lang = lang;

            this.$store.state.schedule.switchLector.filters.groupType = {
                value: type,
                active: true
            }

            this.$store.commit('schedule/setMode', {
                name: SCHEDULE_MODE.BOOK_LESSON,
                params: {
                    studentUuid,
                    groupUuid,
                    userUuid
                }
            });
        },
    }
}

export default openBookLessonModal;