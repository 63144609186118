<template>
    <div
        @click="playSound"
        :class="[
            'volume',
            { 'volume--disabled' : dictionary.isPlaying }
        ]"
    >
        <button class="volume__button" :disabled="dictionary.isPlaying">
            <img
                class="volume__icon"
                :src="dictionary.isPlaying ? volumeGrayIcon : volumeIcon"
                alt="Icon"
            />
        </button>
        <span v-if="text?.length" class="volume__label">{{ text }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import volumeGrayIcon from "@/assets/img/tools/volume-gray.svg";
import volumeIcon from "@/assets/img/tools/volume.svg";

export default {
    name: 'VolumeBtn',
    props: ['text', 'link'],
    data() {
        return {
            volumeGrayIcon,
            volumeIcon
        }
    },
    computed: {
        ...mapState('tools', ['dictionary'])
    },
    methods: {
        async playSound() {
            if (this.dictionary.isPlaying) return;

            const audio = new Audio();

            audio.src = this.link;
            
            audio.onloadedmetadata = () => {
                audio.play();
                this.dictionary.isPlaying = true;
            }
            
            audio.onended = () => {
                this.dictionary.isPlaying = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.volume {
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background: $primary-light;
    }

    &--disabled {
        cursor: default;
        &:hover {
            background: $gray-100;
        }

        .volume__button {
            cursor: default;
        }
    }

    &__button {
        border: 0px;
        background: transparent;
        cursor: pointer;
    }

    &__label {
        @include font-small-10;
        margin-left: 2px;
        color: $gray-600;
    }
}
</style>