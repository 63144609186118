<template>
    <div :class="['modal', { 'modal--ios': isIOSApp }]" @mousedown.self="hide">
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Modal',
    methods: {
        hide(data) {
            this.$emit('hide', data)
        }
    },
    watch: {
        $route() {
            this.hide({ force: true })
        }
    },
    computed: {
        ...mapState(['isIOSApp'])
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        &--ios {
            .footer {
                padding: 21px 21px 32px 21px !important;
            }
        }
    }
</style>