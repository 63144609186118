const SCHEDULE_MODE = {
    BOOK_LESSON: 'bookLesson',
    CANCEL_LESSON: 'cancelLesson',
    TEACHER_PREVIEW: 'previewMode',
    SAVED_SCHEDULE: 'savedSchedule'
}

const SCHEDULE_LOCAL_STORAGE = {
    SAVED_SCHEDULE: SCHEDULE_MODE.SAVED_SCHEDULE
}

const SCHEDULE_SCREEN = {
    SPECIALITY: 'speciality',
    LIST: 'list',
    LECTOR_DETAILS: 'lectorDetails',
    FILTERS_DATE: 'filtersDate'
}

const SCHEDULE_STATE = {
    BOOK_SUCCESS: 'booked-success',
    CANCEL_SUCCESS: 'cancel-success',
    CANCEL_CONFIRMATION: 'cancel-confirmation',
}

export {
    SCHEDULE_MODE,
    SCHEDULE_LOCAL_STORAGE,
    SCHEDULE_SCREEN,
    SCHEDULE_STATE
}