import gql from 'graphql-tag'

export const senderOfMessageByUuid = gql`
    query($messageUuid: ID!) {
        messageByUuid(input: { messageUuid: $messageUuid }) {
            sender {
                firstName
                lastName
                uuid
                avatarUrl
            }
        }
    }
`