<template>
    <div :class="['file-upload', { 'file-upload--simple': simpleChat }]">
        <label>
            <img src="@/assets/img/chat/upload.svg" alt="">
            <input type="file" @change="$emit('setFiles', $event)" multiple>
        </label>
    </div>
</template>

<script>
export default {
    name: 'FileUpload',
    props: {
        simpleChat: Boolean
    }
}
</script>

<style lang="scss" scoped>
.file-upload {
    width: 24px;

    &--simple {
        img {
            margin-right: 0;
            filter: brightness(1.3);
        }
    }

    label {
        display: flex;
        align-items: center;

        input {
            display: none;
        }
    }
}
</style>