import { mapState } from "vuex"
import { STUDENT_TYPE } from '@/constants/studentTypes';

export default {
    computed: {
        ...mapState(['user'])
    },
    methods: {
        showLimit(course) {
            if (!course) return;
            return course.group.company && course.group.type === STUDENT_TYPE.INDIVIDUAL && this.user.student.studentLessonsLimit
        }
    }
}