<template>
    <div :class="['module-btns', { 'module-btns--mobile' : isMobile }]">
        <div class="module-btn info-tooltip" v-for="(button, idx) in modules" :key="idx">
            <Tooltip v-if="receiveButtonState(button.state, idx) === 'lock' && !isMobile" :text="$t('elearning.course-plan.lesson.lock')"/>
            <div :class="['rounded-btn-wrapper', { 'first' : idx === 0 }]">
                <span :class="['rounded-btn', receiveButtonState(button.state, idx)]">{{ isMobile ? '' : idx + 1 }}</span>
                <span v-if="idx !== modules.length - 1" class="line"></span>
            </div>
            <div class="right-side">
                <div :class="['text-wrapper', { 'text-wrapper--last' : idx === modules.length - 1 }, receiveButtonState(button.state, idx)]">
                    <span v-if="isMobile" class="lesson-count">{{ $t('elearning.module-btns.subtitle') }} {{ idx + 1 }}</span>
                    <div class="title">
                        {{ button.title }}
                        <img v-if="receiveButtonState(button.state, idx) === 'lock' && !isMobile" class="lock" src="@/assets/img/learning/coursePlan/lock.svg">
                    </div>
                    <div v-if="!isMobile" class="subtitle" v-html="button.description"></div>
                </div>
                <Button
                    @click.native="goToExercises(button.materialUuid)"
                    :disabled="receiveButtonState(button.state, idx) === 'lock'"
                    :class="['btn button', receiveButtonState(button.state, idx), { 'button--mobile': isMobile }]"
                    :text="$t(`elearning.module-btns.button.${ button.state.toLocaleLowerCase() }`)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/reusable/Tooltip';
import Button from '@/components/reusable/Button';

export default {
    name: 'ModuleBtns',
    components: { Button, Tooltip },
    props: ['modules', 'materialId'],
    computed: {
        ...mapState(['isMobile']),
    },
    methods: {
        receiveButtonState(state, idx) {
            if (idx > 0 && this.modules[idx - 1].state.toLocaleLowerCase() !== 'completed') return 'lock'
            else return state.toLocaleLowerCase()
        },
        goToExercises(materialUuid) {
            const materialID = this.materialId;
            if (this.$route.fullPath !== `${ this.$route.path }?${ new URLSearchParams({ materialID }) }`) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { materialID }
                })
            }

            this.$router.push({ name: 'elearning', params: { uuid: materialUuid, coursePlanUuid: this.$route.params.coursePlanUuid } });
        }
    },
}
</script>


<style lang="scss" scoped>
.module-btns {
    padding: 24px;

    &--mobile {
        padding: 36px 24px;

        .rounded-btn {
            min-width: 24px;
            height: 24px;

            &.new {
                background-image: none;
                border: 3px solid $gray-200;
            }
            
            &.completed {
                border: none;
                background-image: url('~@/assets/img/check-light.svg');
                background-position: center;
                background-repeat: no-repeat;
            }

            &.in_progress {
                background-image: none;
                border: 3px solid $warning;
            }

            &.lock {
                border: none;
                background-image: url('~@/assets/img/learning/coursePlan/lock.svg');
                background-position: center;
                background-repeat: no-repeat;
                opacity: .3;
            }
        }

        .text-wrapper {
            gap: 2px;
        }

        .rounded-btn-wrapper {
            &.first {
                margin-top: 12px;
            }
        }

        .right-side {
            margin-top: -12px;
        }
    }
}

.module-btn {
    display: flex;
    position: relative;
    gap: 24px;

    &:hover {
        .tooltip {
            display: block;
            border-radius: 4px;
        }
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 48px;
        margin-top: -4px;

        &.lock {
            opacity: .5;
        }

        .title {
            display: flex;
            @include font-h3;

            .lock {
                margin-left: 8px;
            }
        }

        .subtitle {
            color: $gray-600;
            @include font-regular;
        }

        .lesson-count {
            color: $gray-600;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.4px;
        }

        &--last {
            margin-bottom: 0;
        }
    }

    .button {
        max-width: 96px;
        min-width: 96px;
        padding: 8px 16px;
        border-radius: 6px;
        @include font-medium-text;
        font-weight: 600;
        white-space: nowrap;
        height: fit-content;
        flex-shrink: 0;
        margin-top: 4px;
        margin-left: auto;

        &--mobile {
            max-width: 86px;
            min-width: 86px;
            max-height: 32px;
            min-height: 32px;
        }

        &.new {
            color: $white;
            background-color: $primary;
        }

        &.completed {
            color: $gray-900;
            background-color: $white;
            border: 1px solid $gray-200;

            &:hover {
                background-color: $gray-100;
            }
        }

        &.in_progress {
            background-color: $primary;
            color: $white;

            &:hover {
                background-color: $primary-dark;
            }
        }

        &.lock {
            opacity: .2;
            cursor: default;
            background-color: $gray-500;
            color: $white;
        }
    }
}

.right-side {
    display: flex;
    gap: 32px;
    width: 100%;
}

.rounded-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .line {
        flex: 1;
        width: 2px;
        background: #E6E7E9;
    }

    &.first {
        margin-top: 4px;
    }
}

.rounded-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 40px;
    height: 40px;
    border-radius: 99px;
    @include font-h3;

    &.new {
        background-image: none;
        border: 2px solid $gray-200;
    }

    &.completed {
        background-color: $secondary;
        color: $white;
    }

    &.in_progress {
        background-image: url('~@/assets/img/learning/coursePlan/rounded-number-in-progress.svg');
        color: $warning-dark;
    }

    &.lock {
        color: $gray-600;
        border: 2px solid $gray-200;
    }
}
</style>