const langsMap = {
    en: {
        code: 'en',
        transKey: 'english'
    },
    pl: {
        code: 'pl',
        transKey: 'polish'
    },
    ua: {
        code: 'uk',
        transKey: 'ukrainian'
    },
    cz: {
        code: 'cs',
        transKey: 'czech'
    },
    hu: {
        code: 'hu',
        transKey: 'hungarian'
    },
    pt: {
        code: 'pt',
        transKey: 'portuguese'
    },
    sk: {
        code: 'sk',
        transKey: 'slovak'
    },
    es: {
        code: 'es',
        transKey: 'spanish'
    },
    bg: {
        code: 'bg',
        transKey: 'bulgarian'
    },
    ro: {
        code: 'ro',
        transKey: 'romanian'
    },

}

export default langsMap;