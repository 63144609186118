<template>
    <div class="input search">
        <input :name="data.name" :type="data.type ? data.type : 'text'" :disabled="data.disabled" :value="value" @change="onChange">
        <div class="action" @click="action">
            <img class="search-icon" src="@/assets/img/search.svg" alt="">
            <img class="loading-icon" src="@/assets/img/loader.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: ['data', 'value'],
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.value)
        },
        action() {
           this.$bus.$emit('getCompanyData')
        }
    }
}
</script>
