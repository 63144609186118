import gql from 'graphql-tag'

export const messageGroups = gql`
    {
        currentUser {
            messageGroups {
                uuid
                name
                group {
                    uuid
                    name
                    type
                    isTest
                    isActive
                }
                receivers {
                    uuid
                    firstName
                    avatarUrl
                    role
                }
                unreadMessagesCount
                lastMessage {
                    createdAt
                }
                messageFiles {
                    uuid
                    name
                    path
                    size
                }
                messageImages {
                    uuid
                    name
                    path
                    size
                    createdAt
                }
                messageLinks {
                    uuid
                    name
                    path
                }
            }
        }
    }
`