<template>
    <div :class="['range-input', orientation, { 'range-input--disabled': disabled }]" @mousedown="handleMouseDown">
        <div class="track"></div>
        <div class="filled-track" :style="filledTrackStyle"></div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        orientation: {
            type: String,
            default: 'horizontal'
        },
        disabled: Boolean
    },
    data() {
        return {
            isDragging: false,
            thumbPosition: this.valueToPosition(this.value)
        };
    },
    mounted() {
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);
    },
    computed: {
        filledTrackStyle() {
            if (this.orientation === 'horizontal') {
                return { width: this.thumbPosition + '%' };
            } else {
                return { height: this.thumbPosition + '%' };
            }
        }
    },
    methods: {
        handleMouseDown(event) {
            this.isDragging = true;
            this.updateValueFromEvent(event);
        },
        handleMouseUp() {
            this.isDragging = false;
        },
        handleMouseMove(event) {
            if (this.isDragging) {
                this.updateValueFromEvent(event);
            }
        },
        updateValueFromEvent(event) {
            const rect = this.$el.getBoundingClientRect();
            let position;

            if (this.orientation === 'horizontal') {
                position = (event.clientX - rect.left) / rect.width;
            } else {
                position = 1 - (event.clientY - rect.top) / rect.height;
            }
            const newValue = this.positionToValue(position);
            this.$emit('changed', newValue);
            this.thumbPosition = this.valueToPosition(newValue);
        },
        positionToValue(position) {
            const range = this.max - this.min;
            const value = position * range + this.min;
            const steppedValue = Math.round(value / this.step) * this.step;
            return Math.min(Math.max(steppedValue, this.min), this.max);
        },
        valueToPosition(value) {
            const range = this.max - this.min;
            return ((value - this.min) / range) * 100;
        }
    },
    watch: {
        value(newValue) {
            this.thumbPosition = this.valueToPosition(newValue);
        }
    }
}
</script>

<style lang="scss">
.range-input {
    position: relative;
    cursor: pointer;

    &.horizontal {
        width: 100%;

        .track {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 4px;
            background-color: $gray-300;
            border-radius: 100px;
        }

        .filled-track {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 4px;
            background-color: $gray-700;
            border-radius: 100px;
        }

        &:active .filled-track {
            transform: translateY(-50%) scaleY(1.5);
        }
    }

    &.vertical {
        height: 100%;
 
        .track {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 100%;
            background-color: $gray-300;
            border-radius: 100px;
        }

        .filled-track {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            background-color: $gray-700;
            border-radius: 100px;
        }

        &:active .filled-track {
            transform: translateX(-50%) scaleX(1.5);
        }
    }

    &--disabled {
        pointer-events: none;
    }
}
</style>
