<template>
    <div 
        :class="[
            'tool-button', 
            {
              'tool-button--active': tool === activeTool,
              'tool-button--askAI': tool === 'askAI'
            }
        ]"
        @click="handleAction"
        v-if="(isGrammarSupported && tool === 'grammar') || tool !== 'grammar'"
    >
        <img 
            width="16" 
            height="16" 
            :src="require(`@/assets/img/tools/${ tool }-small.svg`)" 
            alt=""
        >
        {{ 
            toggleMoreTools 
                ? $i18n.t(`tools.${ moreTools ? 'less' : 'more' }.short-name`) 
                : $i18n.t(`tools.${ tool }.short-name`) 
        }}
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import toolsRedirect from '@/mixins/tools/toolsRedirect'

export default {
    name: 'ToolButton',
    mixins: [toolsRedirect],
    props: {
        tool: {
            type: String
        }
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('tools', ['activeTool', 'showToolsButtons', 'moreTools']),
        ...mapGetters('tools', ['isGrammarSupported']),
        windowWidth() {
            return this.$store.state.windowWidth
        },
        toggle() {
            return this.tool === 'toggle'
        },
        toggleMoreTools() {
            return this.tool === 'toggleMoreTools'
        }
    },
    methods: {
        handleAction() {
            if(this.toggleMoreTools) {
                this.$store.commit('tools/toggleMoreTools')
                
                this.$gtag({
                    category: 'tool',
                    action: this.moreTools ? 'more' : 'less'
                })
            } else {
                if(this.isMobile) this.$router.push(this.toolsRedirect(this.tool))
                else {
                    this.$gtag({
                        category: 'tool',
                        action: this.activeTool === this.tool ? 'close' : 'open',
                        label: this.tool
                    })
                    
                    this.$store.commit('tools/setTool', this.tool)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/components/_tool_button.scss";
</style>