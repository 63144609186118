import gql from 'graphql-tag'

export const availableCountries = gql`
    {
        configuration {
            availableCountries {
                code
                name
            }
        }
    }
`