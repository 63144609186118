<template>
    <div>
        <div class="gaps-word-list__drop-wrapper">
            <span v-for="(el, idx) in task.data.text" :key="idx">
                <span class="text" v-if="el.type === 'text'" v-html="handleText(el.text)"></span>

                <DropBlank 
                    v-else-if="el.type === 'blank'"
                    :uuid="el.index"
                    @savePartialAnswer="savePartialAnswer"
                    @pushToDragTiles="pushToDragTiles"
                    @click.native="removeElement(el.index)"
                />
            </span>
        </div>

        <draggable
            v-model="dragTiles"
            class="gaps-word-list__drag"
            :group="1"
            :disabled="isTaskFinished"
        >
            <div
                :class="[
                    'gaps-word-list__gap',
                    { 'correct': isCorrect(el) },
                ]"
                v-for="(el, index) in dragTiles"
                :key="index"
                :value="el.uuid"
                @click="moveToFirstEmpty(el)"
            >{{ el.text }}</div>
        </draggable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from "vuedraggable";
import DropBlank from '@/components/learning/activity/components/DropBlank.vue';
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'GapsWordList',
    props: ['task'],
    mixins: [handleBoldedText],
    components: {
        draggable,
        DropBlank
    },
    data() {
        return {
            userAnswers: {},
            dragTiles: Object.entries(this.task.data.wordList).map(([uuid, text]) => ({ uuid, text }))
        };
    },
    created() {
        if (this.isPreviewMode) this.setCorrectAnswersFromStart();
    },
    computed: {
        ...mapGetters('learning', ['isTaskFinished', 'getBackendAnswer', 'isPreviewMode', 'getCurrentSlide', 'getCurrentTask'])
    },
    methods: {
        moveToFirstEmpty(el) {
            const firstEmpty = this.task.data.text.find(item => item.type === 'blank' && !this.userAnswers[item.index]);

            if (firstEmpty) {
                this.$bus.$emit('setAnswerForGap', { dropArea: firstEmpty.index, uuid: el.uuid });
                this.savePartialAnswer({ dropArea: firstEmpty.index, uuid: el.uuid });
                this.dragTiles = [...this.dragTiles.filter(item => item.uuid !== el.uuid)];
            }
        },
        savePartialAnswer(value) {
            if (!value.uuid && this.userAnswers[value.dropArea]) delete this.userAnswers[value.dropArea];
            else this.userAnswers = {...this.userAnswers, [value.dropArea]: value.uuid};

            this.$store.commit('learning/setUserAnswer', this.userAnswers);
        },
        pushToDragTiles(el) {
            this.dragTiles.push(...el);
        },
        isCorrect(el) {
            if (!this.getBackendAnswer) return false;

            const { uuid } = el;
            return Object.values(this.getBackendAnswer).includes(uuid);
        },
        setCorrectAnswersFromStart() {
            Object.values(this.getBackendAnswer).forEach(uuid => {
                const el = this.dragTiles.find(el => el.uuid === uuid);
                if (el) this.dragTiles = this.dragTiles.filter(el => el.uuid !== uuid);
            });
        },
        removeElement(index) {
            if (this.userAnswers[index]) {
                this.$bus.$emit('setAnswerForGap', { dropArea: index, uuid: null });

                const answersByUser = { ...this.userAnswers };

                const uuid = answersByUser[index];
                const word = this.getCurrentTask.data.wordList[uuid];

                this.dragTiles.push({ uuid, text: word });
                delete this.userAnswers[index];
                this.$store.commit('learning/setUserAnswer', answersByUser);
            }
        },
    }
};
</script>

<style scoped lang="scss">
.gaps-word-list__drop-wrapper {
    border: 1px dashed $gray-200;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 20px;

    .text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.4px;
    }
}

.gaps-word-list__drag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.gaps-word-list__gap, 
::v-deep .gaps-word-list__gap {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    white-space: nowrap;
    line-height: 16px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $gray-300;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    user-select: none;
    text-align: center;

    &.correct {
        font-weight: 600;
        color: $secondary-dark !important;
        border-color: $secondary-dark !important;
        background: $secondary-light !important;
        pointer-events: none;
    }

    &.incorrect {
        font-weight: 600;
        color: $danger-dark !important;
        border-color: $danger-middle !important;
        background: $danger-light !important;
        pointer-events: none;
    }
}

.gaps-word-list__drag .gaps-word-list__gap.correct {
    background: $white !important;
    color: $secondary !important;
    border-color: $secondary !important;
}

::v-deep .gaps-word-list__drop-wrapper .gaps-word-list__gap {
    font-weight: 600;
    color: $primary-dark;
    border-color: $primary-dark;
    background: $primary-light;

    &.sortable-chosen {
        background: $white;
        border-color: $primary;
        color: $gray-900;
    }
}
</style>
