import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import LoggedWrapper from '@/wrappers/LoggedWrapper'
import LoginWrapper from '@/wrappers/LoginWrapper'
import NavWrapper from '@/wrappers/NavWrapper'
import OnboardingWrapper from '@/wrappers/OnboardingWrapper'
import Login from '@/views/Login'
import ResetPassword from '@/views/ResetPassword'
import SetPassword from '@/views/SetPassword'
import SetAccount from '@/views/SetAccount'
import Dashboard from '@/views/Dashboard'
import CoursePlanLearning from '@/components/learning/CoursePlanLearning'
import CoursePlan from '@/views/CoursePlan'
import NewMobileLesson from '@/components/coursePlan/NewMobileLesson'
import Messages from '@/views/Messages'
import MessagesOnly from '@/views/messagesOnly'
import Lessons from '@/views/Lessons'
import SingleLesson from '@/views/SingleLesson'
import SchedulePreview from '@/views/SchedulePreview'
import Account from '@/views/Account'
import AccountBasic from '@/components/account/Basic'
import AccountSecurity from '@/components/account/Security'
import AccountPayments from '@/components/account/Payments'
import AccountPackages from '@/components/account/Packages'
import AccountCalendar from '@/components/account/Calendar'
import AccountCertificates from '@/components/account/Certificates'
import Tools from '@/views/Tools'
import Page404 from '@/views/Page404'
import OnboardingMeeting from '@/views/onboarding/Meeting'
import OnboardingPreferences from '@/views/onboarding/Preferences'
import Register from '@/views/Register'
import ProgressTest from '@/views/ProgressTest'
import OpenAIChat from '@/components/openAI/OpenAIChat'
import PlacementTest from '@/views/PlacementTest'
import Flashcards from '@/views/Flashcards'
import LearningActivities from '@/views/learning/LearningActivities'

import { i18n } from '@/i18n/i18n'
import accessStorage from '@/functions/accessStorage'
import defaultLogout from '@/functions/defaultLogout'
import { APP_MODES } from '@/router/appModes'

Vue.use(VueRouter)

const routes = [
    {
        path: '/'
    },
    {
        path: '/:locale',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'calendar/:userUuid',
                name: 'SchedulePreview',
                component: SchedulePreview,
                meta: {
                    title: i18n.t('calendar.preview-page.title')
                }
            },
            {
                path: 'register',
                name: 'register',
                component: Register,
                meta: {
                    withoutAuth: true,
                }
            },
            {
                path: '',
                component: LoginWrapper,
                redirect: {name: 'login'},
                meta: {
                    withoutAuth: true
                },
                children: [
                    {
                        path: 'login',
                        name: 'login',
                        component: Login
                    },
                    {
                        path: 'reset-password',
                        name: 'resetPassword',
                        component: ResetPassword,
                        meta: {
                            backToLogin: true,
                            title: i18n.t('reset-password.html-title')
                        }
                    }, 
                    {
                        path: 'set-password',
                        name: 'setPassword',
                        component: SetPassword,
                        meta: {
                            title: i18n.t('set-password.html-title')
                        }
                    }
                ]
            },
            {
                path: '',
                component: LoggedWrapper,
                meta: {
                    requireAuth: true
                },
                children: [
                    {
                        path: '',
                        component: NavWrapper,
                        children: [
                            {
                                path: 'dashboard',
                                name: 'dashboard',
                                component: Dashboard,
                                meta: {
                                    title: i18n.t('dashboard.html-title')
                                }
                            },
                            {
                                path: 'course-plan/:uuid?',
                                name: 'coursePlan',
                                component: CoursePlan,
                                meta: {
                                    title: i18n.t('course-plan.html-title')
                                },
                            },
                            {
                                path: 'course-plan/:uuid/lesson/:lessonUuid',
                                name: 'coursePlanLesson',
                                component: NewMobileLesson,
                                meta: {
                                    title: i18n.t('single-lesson.html-title')
                                }
                            },
                            {
                                path: 'messages/:groupUuid?',
                                name: 'messages',
                                component: Messages,
                                meta: {
                                    title: i18n.t('messages.html-title')
                                }
                            },
                            {
                                path: 'lessons/:year?/:month?',
                                name: 'lessons',
                                component: Lessons,
                                meta: {
                                    title: i18n.t('lessons.html-title')
                                }
                            },
                            {
                                path: 'lesson/:uuid',
                                name: 'singleLesson',
                                component: SingleLesson,
                                meta: {
                                    title: i18n.t('single-lesson.html-title')
                                }
                            },
                            {
                                path: 'account',
                                alias: [`${ APP_MODES.LEARNING.urlPrefix }/account`],
                                name: 'account',
                                component: Account,
                                children: [
                                    {
                                        path: 'basic',
                                        name: 'accountBasic',
                                        component: AccountBasic,
                                        meta: {
                                            title: i18n.t('account.basic.html-title')
                                        }
                                    },
                                    {
                                        path: 'security',
                                        name: 'accountSecurity',
                                        component: AccountSecurity,
                                        meta: {
                                            title: i18n.t('account.security.html-title')
                                        }
                                    },
                                    {
                                        path: 'payments',
                                        name: 'accountPayments',
                                        component: AccountPayments,
                                        meta: {
                                            title: i18n.t('account.payments.html-title')
                                        }
                                    },
                                    {
                                        path: 'packages',
                                        name: 'accountPackages',
                                        component: AccountPackages,
                                        meta: {
                                            title: i18n.t('account.packages.html-title')
                                        }
                                    },
                                    {
                                        path: 'calendar',
                                        name: 'accountCalendar',
                                        component: AccountCalendar,
                                        meta: {
                                            title: i18n.t('account.calendar.html-title')
                                        }
                                    },
                                    {
                                        path: 'certificates',
                                        name: 'accountCertificates',
                                        component: AccountCertificates,
                                        meta: {
                                            title: i18n.t('account.ertificates.html-title')
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'tools/:tool/:word?',
                                alias: [`${ APP_MODES.LEARNING.urlPrefix }/tools/:tool/:word?`],
                                name: 'tools',
                                component: Tools
                            },
                            {
                                path: APP_MODES.LEARNING.urlPrefix,
                                component: {
                                    render(c) {
                                        return c('router-view')
                                    }
                                },
                                children: [
                                    {
                                        path: '',
                                        redirect: { name: 'learningCoursePlan' },
                                    },
                                    {
                                        path: 'course-plan/:coursePlanUuid?',
                                        name: 'learningCoursePlan',
                                        component: CoursePlanLearning,
                                        meta: {
                                            title: i18n.t('learning-course-plan.html-title')
                                        } 
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'openai',
                        name: 'openai',
                        component: OpenAIChat,
                        meta: {
                            title: 'openai'
                        }
                    },
                    {
                        path: 'onboarding',
                        component: OnboardingWrapper,
                        redirect: {name: 'dashboard'},
                        children: [
                            {
                                path: 'meeting',
                                name: 'onboardingMeeting',
                                component: OnboardingMeeting,
                                meta: {
                                    title: i18n.t('onboarding.meeting.html-title')
                                }
                            },
                            {
                                path: 'preferences',
                                name: 'onboardingPreferences',
                                component: OnboardingPreferences,
                                meta: {
                                    title: i18n.t('onboarding.preferences.html-title')
                                }
                            }
                        ]
                    },
                    {
                        path: 'set-account',
                        name: 'setAccount',
                        component: SetAccount,
                        meta: {
                            title: i18n.t('set-account.html-title')
                        }
                    },
                    {
                        path: 'progress-test/:uuid',
                        name: 'progressTest',
                        component: ProgressTest,
                        meta: {
                            title: i18n.t('progress-test.html-title')
                        } 
                    },
                    {
                        path: 'placement-test',
                        name: 'placementTest',
                        component: PlacementTest,
                        meta: {
                            title: i18n.t('placement-test.html-title')
                        } 
                    },
                    {
                        path: 'flashcards/:uuid/:lessonUuid?',
                        name: 'flashcards',
                        component: Flashcards,
                        meta: {
                            title: i18n.t('flashcards.html-title')
                        } 
                    },
                    {
                        path: APP_MODES.LEARNING.urlPrefix,
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'activities/:uuid?/:coursePlanUuid?',
                                name: 'elearning',
                                component: LearningActivities,
                                meta: {
                                    title: i18n.t('learning-activities.html-title')
                                } 
                            }
                        ]
                    },
                    {
                        path: 'messages-only',
                        name: 'messagesOnly',
                        component: MessagesOnly,
                        beforeEnter(to, from, next) {
                            if(!to.params.oneGroupUuid) {
                                sessionStorage.removeItem('authToken')
                                next({name: 'login', params: {locale: to.params.locale}})
                            } else next()
                        }
                    },
                ]
            },
            {
                path: '',
                component: OnboardingWrapper,
                children: [
                    {
                        path: 'meeting',
                        name: 'Meeting',
                        component: OnboardingMeeting,
                        meta: {
                            title: i18n.t('onboarding.meeting.html-title')
                        }
                    },
                ]
            },
            {
                path: 'logout',
                name: 'logout',
                beforeEnter: (to, from, next) => {
                    defaultLogout()
                    next({ name: 'login' })
                }
            },
            {
                path: 'redirect',
                name: 'redirect',
                beforeEnter: (to, from, next) => {
                    const query = to.query
                    const isEmployee = query.isEmployee
                    const authToken = query.authToken

                    if(isEmployee) {
                        sessionStorage.setItem('isEmployee', isEmployee)
                        delete query.isEmployee
                    }

                    if(authToken) {
                        isEmployee ? sessionStorage.setItem('authToken', authToken) : localStorage.setItem('authToken', authToken)
                        delete query.authToken
                    }

                    if(query.preview) {
                        sessionStorage.setItem('learningPreview', query.preview)
                        delete query.preview
                    }

                    const route = query.route || 'dashboard'
                    delete query.route

                    if(query.modal) query.modal = JSON.parse(query.modal)

                    if(query.lessonUuid) {
                        query.uuid = query.lessonUuid
                        delete query.lessonUuid
                    }

                    if(query.messageGroupUuid) {
                        const mapGroupUuid = {
                            messages: 'groupUuid',
                            messagesOnly: 'oneGroupUuid'
                        }

                        query[mapGroupUuid[route]] = query.messageGroupUuid
                        delete query.messageGroupUuid
                    }
                    
                    next({name: route, params: { locale: to.params.locale, ...query }})
                }
            },
            {
                path: '404',
                name: 'Page404',
                component: Page404,
                meta: {
                    title: i18n.t('page-404.html-title')
                }
            },
            {
                path: '*',
                redirect: {name: 'Page404'}
            }
        ],
    }
]

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//auth redirects
router.beforeEach((to, from, next) => {
    const token = accessStorage('authToken')
    to.matched.forEach(({meta}) => {
        if(meta.requireAuth && !token) next({ name: 'login', query: { redirect: to.fullPath } })
        else if(meta.withoutAuth && token) next({ name: 'dashboard', params: { forceAppMode: true } })
        else next()
    })
})

router.afterEach(to => {
    store.state.currentRoute = to
    const defaultTitle = i18n.t('html-default-title')
    document.title = to.meta.title ? `${ to.meta.title } | ${ defaultTitle }` : defaultTitle
})

export default router
