<template>
    <div class="flashcards__input">
        <FlashcardsExercise />
        <transition name="flashcards-activity-hide">
            <Input 
                v-if="showActivity"
                ref="inputRef"
                :data="{
                    placeholder: $t('flashcards.input.placeholder', transUi),
                    disabled: getAnswerStatus,
                    status: getAnswerStatus,
                    event: 'input'
                }"
                @input="setAnswer"
                :value="currentAnswer"
                cross
            />
        </transition>
    </div>
</template>

<script>
import FlashcardsExercise from '@/components/flashcards/FlashcardsExercise'
import Input from '@/components/reusable/Input'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'FlashcardsInput',
    components: {
        FlashcardsExercise,
        Input
    },
    computed: {
        ...mapState('flashcards', ['currentAnswer', 'submitted', 'dontKnow']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('flashcards', ['getAnswerStatus', 'showActivity']),
    },
    methods: {
        setAnswer(value) {
            this.$store.commit('flashcards/setCurrentAnswer', value)
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.inputRef?.$el.focus();
        }, 350)
    }
}
</script>


<style lang="scss" scoped>
.flashcards__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .input {
        margin-top: auto;
        margin-bottom: 12px !important;
        border-top: 1px solid $gray-200;
        padding: 12px 8px 0 8px;
        width: 100%;

        @media (min-width: $mobile-flashcards) {
            min-width: 564px;
            position: relative;
            bottom: auto;
            left: auto;
        }

        ::v-deep input {
            height: 50px;
            padding: 14px 12px 14px 18px;
            border-radius: 333px;
            box-shadow: 0px 4px 8px -4px rgba(70, 80, 94, 0.08);
            @include font-lead;
            border: 2px solid $primary-mid;
            margin: 0;
            background-color: $white !important;
            font-weight: 500;

            &:active {
                border: 2px solid $primary-mid;
            }

            &:hover {
                border: 2px solid $primary-dark;
            }

            &:disabled {
                border: 2px solid $gray-200;
            }
        }
    }
}
</style>