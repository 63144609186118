<template>
    <div :class="['course-plan__header-wrapper skeleton', { 'course-plan__header-wrapper--mobile': isMobile }]">
        <div class="topic">
            <div class="small dark"></div>
        </div>
        <div class="lesson-count">
            <div class="small dark"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'HeaderSkeleton',
    computed: {
        ...mapState(['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.course-plan__header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .topic {
        margin-top: 43px;

        .small {
            width: 500px;
            height: 40px;
            margin-bottom: 0;
            background: $primary-dark;
        }
    }

    .lesson-count {
        .small {
            background: $primary-dark;
            width: 76px;
            height: 32px;
            margin-bottom: 0;
        }
    }

    &--mobile {
        flex-direction: column !important;
        align-items: flex-start !important;

        .topic {
            margin-top: 0;

            .small {
                width: 160px;
                height: 24px;
                margin-bottom: 0;
            }
        }

        .lesson-count {
            .small {
                width: 60px;
                height: 24px;
                margin-bottom: 0;
            }
        }
    }
}
</style>