import gql from 'graphql-tag'

export const nextStudentLesson = gql`
    {
        currentUser {
            student {
                nextStudentLesson {
                    lesson {
                        uuid
                    }
                }
            }
        }
    }
`