<template>
    <div ref="switchLectorList" class="switch-lector-list">
        <SwitchLectorFilters />
        <div class="switch-lector-list__area">
            <SwitchLectorItem 
                v-show="item.node.matchFilters" 
                ref="switchLectorItemRef" 
                v-for="item in lectors" 
                :key="item.uuid" 
                :item="item.node" 
            />
            <div class="switch-lector-list__get-more" v-if="switchLector.showGetMoreLectors && isDateFilter">
                <p v-if="!atLeastOneFilteredLector" class="switch-lector-list__get-more-title">{{ $i18n.t('Nie znaleziono lektora spełniającego kryteria wyszukiwania') }}</p>
                <div class="switch-lector-list__get-more-buttons">
                    <Button class="default" :text="$i18n.t('Zmień kryteria')" @click.native="changeFilters" />
                    <Button class="primary" :text="$i18n.t('Pokaż więcej lektorów')" @click.native="getMoreProposedLectors({ first: 10 })" />
                </div>
            </div>
        </div>
        <div v-if="switchLector.loadingMoreLectors" class="skeleton">
            <div class="dark loading-box">
                {{ $i18n.t('switch-lector.list.loading') }}...
            </div>
        </div>

        <div v-if="emptyDisplayedLectors && !switchLector.loadingMoreLectors" class="switch-lector-empty">
            <img src="@/assets/img/schedule/ask.svg" alt="">

            <p class="switch-lector-empty__title">
                {{ $t('switch-lector.list.filters.empty.title') }}
            </p>

            <button 
                @click="resetFilters()" 
                class="btn default">
                {{ $t('switch-lector.list.filters.empty.btn') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SwitchLectorItem from '@/components/switchLector/SwitchLectorItem';
import SwitchLectorFilters from '@/components/switchLector/SwitchLectorFilters';
import Button from '@/components/reusable/Button';
import { SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    name: 'SwitchLectorList',
    components: {
        SwitchLectorItem,
        SwitchLectorFilters,
        Button
    },
    data() {
        return {
            loadingMoreLectors: false,
            IO: null,
            MO: null,
            scrollPositionTimeout: 0
        }
    },
    created() {
        const { commit } = this.$store;
        const { btnDefaultAction } = this;

        commit('schedule/setScheduleDefaultButton', { 
            btnDefaultAction: this.isSavedSchedule
                ? 'hideScheduleModal'
                : btnDefaultAction,
            btnDefaultLabel: this.isSavedSchedule 
                ? this.$i18n.t('switch-lector.exit')
                : this.$i18n.t('switch-lector.list.btn-default')
        });

        commit('schedule/setSchedulePrimaryButton', { btnPrimaryAction: false, btnPrimaryLabel: false });

        this.$store.state.schedule.queue.push(SCHEDULE_SCREEN.LIST);
    },
    mounted() {
        const { commit } = this.$store;
        const { lectorsCalendars, switchLector: { anchor } } = this.$store.state.schedule;

        if (this.emptyDisplayedLectors) return;

        this.IO = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const { lectorApiUrl } = entry.target.dataset;
                if (entry.isIntersecting) {
                    entry.target.__vue__._data.elementInViewport = true
                    if (!lectorsCalendars[lectorApiUrl]) {
                        commit('schedule/addLectorCalendarRequestToStack', { lectorApiUrl });
                    } else this.IO.unobserve(entry.target)

                    entry.target.classList.add('switch-lector-item--observed')
                } 
                else commit('schedule/removeLectorCalendarRequestFromStack', { lectorApiUrl })    
            })
        }, { threshold: 0 })
        
        this.$refs.switchLectorItemRef.forEach((el) => {
            this.IO.observe(el.$el)
        })

        if(this.isDateFilter || this.isAdvanceNoticeFilterActive) this.handleMutationObserver()

        this.$refs.switchLectorList.addEventListener('scroll', this.checkScrollPosition);

        const anchorElement = document.querySelector(`.switch-lector-item[data-lector-api-url="${anchor}"]`);
        if (anchor && anchorElement) {
            this.$refs.switchLectorList.scroll({
                top: anchorElement.offsetTop - document.querySelector('.switch-lector-filters').clientHeight
            })
        }
    },
    beforeDestroy() {
        if(this.IO) this.IO.disconnect();
        if(this.MO) this.MO.disconnect();
        this.$refs.switchLectorList.removeEventListener('scroll', this.checkScrollPosition);
    },
    computed: {
        ...mapState('schedule', ['switchLector']),
        ...mapGetters('schedule', ['isSavedSchedule', 'isDateFilter', 'isAdvanceNoticeFilterActive']),
        windowWidth() {
            return this.$store.state.windowWidth
        },
        lectors() {
            return this.$store.state.schedule.switchLector.data;
        },
        emptyDisplayedLectors() {
            return !this.lectors.length || this.lectors.every(el => !el.node.matchFilters);
        },
        atLeastOneFilteredLector() {
            return this.lectors.find(lector => lector.node.matchFilters)
        }
    },
    methods: {
        handleMutationObserver() {
            this.MO = new MutationObserver(() => {
                const el = this.$refs.switchLectorList

                if(!el) return

                const skeleton = el.querySelector('.skeleton-calendar')

                setTimeout(() => {
                    if(el.scrollHeight === el.clientHeight && !skeleton && !this.switchLector.loadingMoreLectors) {
                        this.$store.dispatch('schedule/getMoreProposedLectors', { first: 10 })
                    }
                }, 0)
            })

            this.MO.observe(this.$refs.switchLectorList, { childList: true, subtree: true })
        },
        btnDefaultAction() {
            const { commit } = this.$store;
            const { selectedCourse, selectedNewLector } = this.$store.state.schedule;
            
            commit('schedule/setSwitchLectorAnchor', false)

            if (selectedCourse.lang === 'eng') commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.SPECIALITY });
            else {
                commit('schedule/setLoading', true);
                commit('schedule/resetSlot');
                commit('schedule/setSwitchLectorFlow', { activeScreen: false, activeFlow: false });
                commit('schedule/setSwitchLectorApiUrl', false)

                if (selectedNewLector) {
                    setTimeout(() => {
                        commit("schedule/switchCoursesView", true);
                        commit('schedule/setLoading', false);
                    }, 500);
                } else {
                    commit('schedule/setLoading', false);
                }
            }
        },
        async checkScrollPosition(e) {
            clearTimeout(this.scrollPositionTimeout);

            this.scrollPositionTimeout = setTimeout(async () => {
                const { scrollHeight, scrollTop, clientHeight } = e.target;
    
                if (!this.switchLector.loadingMoreLectors && scrollTop + clientHeight + 100 > scrollHeight) {
                    /* if(this.isDateFilter) {
                        this.switchLector.showGetMoreLectors = true
                        
                        setTimeout(() => {
                            this.$refs.switchLectorList.scroll({
                                top: this.$refs.switchLectorList.scrollHeight
                            })
                        }, 0)
                    } else  */this.getMoreProposedLectors()
                }
            }, 100);
        },
        getMoreProposedLectors(payload) {
            this.$store.dispatch('schedule/getMoreProposedLectors', payload)
        },
        changeFilters() {
            this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.FILTERS_DATE })
            this.$store.commit('schedule/setModalTitle', this.$i18n.t('modal.book-lesson.header.filter-date', { slotsCount: this.isDateFilter }))
        },
        triggerScroll() {
            let scrollEvent = new Event('scroll');
            this.$refs.switchLectorList.dispatchEvent(scrollEvent);
        },
        resetFilters() {
            this.$store.commit('schedule/resetFilters', true);
            this.$store.dispatch('schedule/reloadLectorsList');
        }
    },
    watch: {
        '$store.state.schedule.switchLector.dataPaginateCursor': {
            handler() {
                setTimeout(() => {
                    this.$refs.switchLectorItemRef.forEach((el) => {
                        if (!el.$el.classList.contains('switch-lector-item--observed') || !el.$el.__vue__._data.elementInViewport) {
                            this.IO.observe(el.$el)
                        }
                    })
                }, 200);
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .switch-lector-list {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    .switch-lector-list__area {
        padding: 0;
    }

    .switch-lector-list__get-more-title {
        @include font-h3;
        text-align: center;
        margin-bottom: 16px;
    }

    .switch-lector-list__get-more-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .btn {
            @include font-medium;
        }
    }

    .switch-lector-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .switch-lector-empty__title {
        @include font-h3;
        color: $gray-600;
        margin-top: 12px;
        margin-bottom: 16px;
    }

    .loading-box {
        margin: 0 auto;
        padding: 2px;
        max-width: 300px;
        border-radius: 30px;
        text-align: center;
        color: $gray-600;
    }
</style>