import moment from 'moment-timezone';

function checkIfSlotsExists(data) {
    return data.slots.length
}

function getDaysFromSlots(slots) {
    const sortedSlots = slots.sort((a, b) => (a?.startAt > b?.startAt) ? 1 : -1);
    const startDay = moment(sortedSlots[0]?.startAt);
    const stopDay = moment(sortedSlots[sortedSlots.length - 1]?.startAt);

    const days = [];
    let currDay = startDay;

    while (moment(currDay) <= moment(stopDay)) {
        days.push(moment(currDay));
        currDay = moment(currDay).add(1, 'days').format('YYYY-MM-DD')
    }

    return days;
}

function parseSlots(slots) {
    return getDaysFromSlots(slots).map((date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const filteredSlots = slots.filter(
            (slot) => formattedDate === moment(slot?.startAt).format('YYYY-MM-DD')
        );
        return { date: formattedDate, slots: filteredSlots };
    });
}

export {
    parseSlots as default,
    checkIfSlotsExists
}