const LANGUAGE_LEVELS = [
    {
        rootName: 'A0',
        shortName: 'A0',
        baseWords: 0,
    },
    {
        rootName: 'A1',
        shortName: 'A1.1',
        baseWords: 500,
    },
    {
        rootName: 'A1',
        shortName: 'A1.2',
        baseWords: 700,
    },
    {
        rootName: 'A2',
        shortName: 'A2.1',
        baseWords: 1000,
    },
    {
        rootName: 'A2',
        shortName: 'A2.2',
        baseWords: 1300,
    },
    {
        rootName: 'A2',
        shortName: 'A2.3',
        baseWords: 1600,
    },
    {
        rootName: 'B1',
        shortName: 'B1.1',
        baseWords: 2000,
    },
    {
        rootName: 'B1',
        shortName: 'B1.2',
        baseWords: 2600,
    },
    {
        rootName: 'B1',
        shortName: 'B1.3',
        baseWords: 3300,
    },
    {
        rootName: 'B2',
        shortName: 'B2.1',
        baseWords: 4000,
    },
    {
        rootName: 'B2',
        shortName: 'B2.2',
        baseWords: 5300,
    },
    {
        rootName: 'B2',
        shortName: 'B2.3',
        baseWords: 6600,
    },
    {
        rootName: 'C1',
        shortName: 'C1.1',
        baseWords: 8000,
    },
    {
        rootName: 'C1',
        shortName: 'C1.2',
        baseWords: 10500,
    },
    {
        rootName: 'C1',
        shortName: 'C1.3',
        baseWords: 13000,
    },
    {
        rootName: 'C2',
        shortName: 'C2',
        baseWords: 16000,
    },
];

export default LANGUAGE_LEVELS;
