import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from './translations/pl'
import en from './translations/en'
import cs from './translations/cs'
import sk from './translations/sk'
import es from './translations/es'
import uk from './translations/uk'
import pt from './translations/pt'
import hu from './translations/hu'
import bg from './translations/bg'
import ro from './translations/ro'

Vue.use(VueI18n)

export const locales = {
    pl: 'pl',
    en: 'en',
    cz: 'cs',
    sk: 'sk',
    es: 'es',
    ua: 'uk',
    pt: 'pt',
    hu: 'hu',
    bg: 'bg',
    ro: 'ro'
  }

let locale
const urlLocale = window.location.pathname.split('/')[1]
if(Object.keys(locales).includes(urlLocale)) locale = locales[urlLocale]
else locale = localStorage.getItem('locale') || locales.pl

const messages = {
    pl,
    en,
    cs,
    sk,
    es,
    uk,
    pt,
    hu,
    bg,
    ro
}

export const i18n = new VueI18n({
    locale,
    fallbackLocale: locales.pl,
    messages,
    silentTranslationWarn: true
})