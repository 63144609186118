<template>
    <div v-if="this.getMaterial" class="single-lesson">
        <div class="lesson-data">
            <div class="tab plan-header">
                <div class="title-wrapper">
                    <img class="icon" src="@/assets/img/coursePlan/lesson-unplanned.svg"/>
                    <span class="text">{{ $t('course-plan.plan-header.unplanned') }}</span>
                </div>
                <span class="subject">{{ `${ getLangLevel } - ${ $route.params.lessonUuid + 1 }/${ count }: ${ subject }` }}</span>
            </div>
            <div v-if="showBookBtn" class="tab plan-lesson">
                <div v-if="getTeachers.length" class="lector-wrapper">
                    <template v-for="(el, idx) in getTeachers">
                        <img
                            :src="setAvatar(el.avatarUrl)"
                            :key="idx"
                            :style="{ transform: `translateX(${idx * 20}px)` }"
                            class="lector avatar-style"
                            width="24"
                            height="24"
                        />
                    </template>
                </div>
                <span class="title">{{ $t('course-plan.book-lesson.title') }}</span>
                <span class="description">{{ $t('course-plan.book-lesson.description') }}</span>
                <BookLessonBtn v-if="getBookingUrls.length === 1" placement="coursePlanMobileBookButton"/>
                <BookLessonBtn v-else placement="coursePlanNoPackageMobileBookBtn"/>
            </div>
            <div class="summary tab">
                <summary-section
                    class="mt-0"
                    :icon="require('@/assets/img/lesson-details.svg')"
                    :title="$t('single-lesson.lesson-details')"
                >
                    <template v-slot:content>
                        <SummaryItem
                            v-if="lesson.url"
                            :title="$t('single-lesson.materials')"
                            :subtitle="subject || $t('single-lesson.materials.subtitle')"
                            :text="$t('single-lesson.materials.btn')"
                            :state="lesson.status || MATERIAL_STATES.NOT_STARTED"
                            :btns="[{
                                link: lesson.url,
                                type: MATERIAL_TYPE.LESSON,
                                state: lesson.status || MATERIAL_STATES.NOT_STARTED
                            }]"
                        />
                        <SummaryItem
                            v-if="prelesson.url"
                            :title="$t('single-lesson.prelesson')"
                            :text="$t('single-lesson.prelesson.btn')"
                            :subtitle="subject || $t('single-lesson.materials.subtitle')"
                            :state="prelesson.status || MATERIAL_STATES.NOT_STARTED"
                            :btns="[{
                                link: prelesson.url,
                                type: MATERIAL_TYPE.PRELESSON,
                                state: prelesson.status || MATERIAL_STATES.NOT_STARTED
                            }]"
                        />
                        <SummaryItem
                            v-if="homework.url"
                            :title="$t('single-lesson.homework')"
                            :text="$t('single-lesson.homework.btn')"
                            :materialUuid="homework.uuid"
                            :subtitle="subject || $t('single-lesson.materials.subtitle')"
                            :state="homework.status || MATERIAL_STATES.NOT_STARTED"
                            :btns="[{
                                link: homework.url,
                                type: MATERIAL_TYPE.HOMEWORK,
                                state: homework.status || MATERIAL_STATES.NOT_STARTED
                            }]"
                        />
                        <SummaryItem
                            v-if="flashcardGroup"
                            :title="$t('single-lesson.flashcards')"
                            :state="flashcardGroup.status"
                            :subtitle="subject || $t('single-lesson.materials.subtitle')"
                            :btns="flashcardsBtns()"
                        />
                    </template>
                </summary-section>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import setAvatarMixin from '@/mixins/setAvatar';
import SummarySection from '@/components/singleLesson/SummarySection';
import SummaryItem from '@/components/singleLesson/SummaryItem';
import openBookLessonModal from '@/mixins/schedule/openBookLessonModal';
import BookLessonBtn  from '@/components/reusable/BookLessonBtn';
import { MATERIAL_TYPE, MATERIAL_STATES } from '@/constants/material';
import { FLASHCARDS_TYPES, FLASHCARDS_STATES } from '@/constants/flashcards';

export default {
    name: 'NewMobileLesson',
    components: {
        SummarySection,
        SummaryItem,
        BookLessonBtn
    },
    mixins:[openBookLessonModal, setAvatarMixin],
    data() {
        return {
            MATERIAL_TYPE,
            MATERIAL_STATES
        }
    },
    created() {
        if (!this.getMaterial) this.$router.go(-1);
    },
    computed: {
        ...mapState(['languageLevel']),
        ...mapState('coursePlan', ['materials', 'count']),
        ...mapGetters('coursePlan', ['getTeachers', 'getStudentGroups', 'getBookingUrls', 'getChosenCoursePlan']),
        ...mapGetters(['atLeastOneSharedOrGroupCourse', 'isBusiness']),
        homework() {
            return this.getMaterial.homework
        },
        subject() {
            return this.getMaterial.title
        },
        lesson() {
            return this.getMaterial.lesson
        },
        prelesson() {
            return this.getMaterial.preLesson
        },
        flashcardGroup() {
            return this.getMaterial.flashcard?.flashcardGroup
        },
        getLangLevel() {
            const addBusiness = this.getChosenCoursePlan.languageLevel.isBusiness || this.getChosenCoursePlan.isBusiness ? ' BE' : '';

            return this.getChosenCoursePlan.languageLevel.shortName + addBusiness
        },
        getMaterial() {
            return this.materials[this.$route.params.lessonUuid]
        },
        showBookBtn() {
            const langCondition = !this.languageLevel || this.languageLevel === this.getLangLevel;

            return (!this.isBusiness && !this.atLeastOneSharedOrGroupCourse) && langCondition || this.getBookingUrls.length && langCondition
        }
    },
    methods: {
        flashcardsBtns() {
            const link = { name: 'flashcards', params: { uuid: this.flashcardGroup.uuid }}
            const btns = [
                {
                    link,
                    type: FLASHCARDS_TYPES.LEARNING,
                    state: this.getMaterial.flashcard.status
                }
            ]

            if (this.getMaterial.flashcard.status === FLASHCARDS_STATES.COMPLETED) {
                btns.unshift({
                    link,
                    type: FLASHCARDS_TYPES.PRACTICE,
                    state: FLASHCARDS_STATES.PRACTICE
                })
            }

            return btns
        }
    }
}
</script>

<style scoped lang="scss">
    .mt-0 {
        margin-top: 0;
    }

    .plan-header {
        display: flex;
        flex-direction: column;

        .title-wrapper {
            display: flex;
            align-items: center;
            gap: 12px;

            .icon {
                width: 40px;
                height: 40px;
            }
    
            .text {
                text-transform: uppercase;
                @include font-medium;
                color: $gray-600;
            }
        }

        .subject {
            margin-top: 10px;
            @include font-h2;
            color: $gray-900;
        }
    }

    .plan-lesson {
        display: flex;
        flex-direction: column;

        .icon {
            width: 24px;
            height: 20px;
        }

        .title {
            margin-top: 8px;
            color: $gray-900;
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.4px;
        }

        .description {
            margin-top: 6px;
            color: $gray-600;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.4px;
        }

        .btn {
            margin-top: 16px;
        }
    }

    .lector-wrapper {
        margin: 16px 0 8px 0;
        display: flex;
        align-items: center;
        gap: 8px;

        .lector {
            position: absolute;
            outline: 2px solid $white;
        }
    }
</style>
