<template>
    <div :class="[
        'tools-page',
        {
            [`tools-page--${ activeTool }`]: activeTool
        }
    ]">
        <div :class="['tools-page__nav', { 'tools-page__nav--askAI-mobile' : tool === 'askAI' && isMobile }]">
            <div class="tools-page__nav-info">
                <div v-if="isMobile && activeTool === 'askAI' && !askAI.game" class="tools-page__back-btn-wrapper">
                    <img @click="$router.go(-1)" class="back-btn" src="@/assets/img/arrow-blue.svg" alt=""/>
                </div>
                <img :src="tool === 'askAI' && isMobile && askAI.game ? require('@/assets/img/tools/askAI-active.svg') : getIconByTool('bubble')" class="tools-page__icon" alt="">
                <p :class="['tools-page__title', { 'tools-page__title--askAI' : tool === 'askAI' && (!isMobile) }]">
                    {{ isMobile && activeTool === 'askAI' && askAI.game ? $t('tools.title.askAI.activities') : $t(`tools.title.${ activeTool }`) }}
                </p>
                <div v-if="isMobile && activeTool === 'askAI' && askAI.game" class="tools-page__activities-wrapper" @click="toggleMenu" v-click-outside="hideMenu">
                    <dropdown v-if="showMenu" pos="bottom">
                        <div :class="['dropdown-item', { 'disabled': askAI.loading }]" @click="resetAIActivity">{{ $t('ask-ai.game-header.restart') }}</div>
                        <div :class="['dropdown-item red', { 'disabled': askAI.loading }]" @click="endAIActivity">{{ $t('ask-ai.game-header.leave') }}</div>
                    </dropdown>
                    <img src="@/assets/img/tools/dots.svg"/>
                </div>
            </div>

            <div :class="['tools-page__back', { 'tools-page__back--hide': !contextUrl }]" @click="backToContext">
                <img src="@/assets/img/tools/shrink.svg" alt="">
            </div>
        </div>
        <component :is="toolsMap[tool]" views/>
    </div>
</template>

<script>
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import toolsMapMixin from '@/mixins/tools/toolsMap'
import getIconByTool from '@/mixins/tools/getIconByTool'
import { mapState } from 'vuex'

export default {
    name: 'Tools',
    mixins: [toolsMapMixin, getIconByTool, dropdownMixin],
    data() {
        return {
            backToContextFlow: false
        }
    },
    components: { Dropdown },
    created() {
        if(!this.toolsMap[this.tool]) this.$router.push({ name: 'dashboard' })
        this.setTool()
    },
    updated() {
        this.setTool()
    },
    computed: {
        ...mapState('tools', ['activeTool', 'contextUrl', 'askAI']),
        ...mapState(['isMobile']),
        tool() {
            return this.$route.params.tool
        }
    },
    methods: {
        backToContext() {
            this.backToContextFlow = true

            document.querySelector('grammarly-editor-plugin')?.disconnect()
            this.$store.commit('tools/openTools')

            this.$router.push(this.contextUrl)

            this.$gtag({
                category: 'tool',
                action: 'minimize',
                label: this.activeTool
            })
        },
        setTool() {
            if(this.activeTool !== this.tool) this.$store.commit('tools/setTool', this.tool)
        },
        resetAIActivity() {
            if(this.askAI.loading) return;

            this.$store.dispatch('tools/restartTeacherAIGame');
        },
        endAIActivity() {
            if(this.askAI.loading) return;

            this.$store.commit('tools/leaveTeacherAIGame');
        }
    },
    beforeDestroy() {
        if(!this.backToContextFlow) this.$store.commit('tools/closeTools')
        this.$store.commit('tools/setContextUrl', null)
    },
}
</script>

<style lang="scss" scoped>
.tools-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $white;
    margin-bottom: 12px;
    border-radius: 10px;
    position: relative;
    container: tools / inline-size;

    &--grammar {
        container: unset;
    }

    @media(max-width: $mobile-tools) {
        @include toolsNavColors;
    }

    &__back-btn-wrapper {
        display: flex;
        align-items: center;
        margin-right: 4px;

        .back-btn {
            width: 20px;
            height: 20px;
            transform: rotate(180deg);
            cursor: pointer;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;

        @media(min-width: $mobile-tools) {
            padding: 12px 16px;
        }

        &--askAI-mobile {
            padding: 16px;
        }
    }

    &__nav-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    }

    &__icon {
        @media(min-width: $mobile-tools) {
            display: none;
        }
    }

    &__title {
        @media(min-width: $mobile-tools) {
            @include font-h3;
        }

        @include font-h4;
        margin: 0;
        color: $gray-900;

        &--askAI {
            color: $orange-dark;
        }
    }

    &__back {
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-200;
        border-radius: 6px;
        cursor: pointer;
        transition: all .25s;
        display: none;

        @media(min-width: 993px) {
            display: flex;
        }
        
        &--hide {
            visibility: hidden;
        }

        &:hover {
            background: $gray-100;
        }
    }

    &__activities-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        padding: 7px;
        border-radius: 6px;
        border: 1px solid $gray-200;
        cursor: pointer;
        position: relative;

        &:hover {
            background: $gray-100;
        }

        ::v-deep {
            .main-dropdown {
                transform: translate(-42px, 100%);
                max-width: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .dropdown-item {
            width: 100%;
            text-align: center;
            @include font-medium;

            &.red {
                color: $danger-middle;
            }

            &.disabled {
                opacity: .5;
                cursor: default;

                &:hover {
                    background: $white;
                }
            }

            &:hover {
                background: $gray-100;
            }
        }
    }
}
</style>