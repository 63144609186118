<template>
    <div class="progress-bar">
        <div v-if="showLabel" class="progress-bar__label">
            {{ $t('progress').toUpperCase() }}
        </div>
        <div class="progress-bar__empty">
            <div
                :class="['progress-bar__filled', progressBarColor
                    ? 'progress-bar__filled--success'
                    : 'progress-bar__filled--progress'
                ]"
                :style="{ width: `${this.progressWidth}%` }"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        // USE EITHER THESE TWO
        totalItems: {
            type: [Number, String]
        },
        itemsFinished: {
            type: [Number, String]
        },
        // OR THIS
        percentageFilled: {
            type: [Number, String]
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        progressWidth() {
            if (this.totalItems && this.itemsFinished) {
                return (100 * this.itemsFinished) / this.totalItems
            }
            if (this.percentageFilled) {
                return this.percentageFilled
            }
            return 0
        },
        progressBarColor() {
            return this.progressWidth >= 100;
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-bar {
    width: 160px;

    &__empty {
        height: 4px;
        background-color: $primary-light;
        border-radius: 50px;
        overflow: hidden;
        width: 100%;
        display: flex;
    }

    &__filled {
        height: 100%;

        &--success {
            background-color: $primary;
        }

        &--progress {
            background-color: $primary;
        }
    }

    &__label {
        font-weight: bold;
        color: gray;
        text-align: right;
        margin: 10px 7px 5px 0;
        user-select: none;
    }
}
</style>
