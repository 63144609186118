import { SCHEDULE_SCREEN } from '@/constants/schedule';

const backToLectorCard = {
    methods: {
        backToLectorCard() {
            const { commit } = this.$store;

            commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LECTOR_DETAILS });
            commit("schedule/resetSlot");
            commit("schedule/setResponseState", false);
            commit("schedule/setRecurring", false);
        }
    }
}

export default backToLectorCard;