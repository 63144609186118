<template>
    <div>
        <div class="header">
            <img :src="data.icon" alt="Icon">
            <router-link :to="{name: 'singleLesson', params: {uuid: data.data.uuid}}" class="label">
                <h3>{{data.title}}</h3>
                <div class="date">
                    <p><img :src="calendar" alt="Icon">{{$moment(data.data.startAt).format(timezoneDate('D MMMM (ddd)'))}}</p>
                    <p><img :src="clock" alt="Icon">{{$moment(data.data.startAt).format(timezoneDate('HH:mm'))}} - {{$moment(data.data.stopAt).format(timezoneDate('HH:mm'))}}</p>
                </div>
            </router-link>
            <div v-if="data.action && data.actionUrl" class="action" @click="toggleMenu" v-click-outside="hideMenu">
                <img :src="actionIcon" alt="Icon">
                <dropdown v-if="showMenu" pos="right">
                    <div @click="openCancelLessonModal(rescheduledApiUrl)">{{$i18n.t('dashboard.next-lesson.cancel')}}</div>
                </dropdown>
            </div>
        </div>
        <p class="dsc">{{data.data.lessonSubjects?.length ? data.data.lessonSubjects?.[0].subject : ''}}</p>
        <div class="lector">
            <img class="avatar-style" :src="setAvatar(data.data.user.avatarUrl)" width="24" height="24" :alt="data.data.user.firstName"> {{data.data.user.firstName}}
            <span v-if="data.data.isTeacherSubstituted" class="info-tooltip"><img src="@/assets/img/info.svg" alt="">
                <Tooltip :text="`${ $t('lesson.replacement-info') }`"/>
            </span>
        </div>
    </div>
</template>

<script>
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import setAvatarMixin from '@/mixins/setAvatar'
import timezoneDate from '@/functions/timezoneDate'
import openCancelLessonModal from '@/mixins/schedule/openCancelLessonModal'
import Tooltip from '@/components/reusable/Tooltip'
import calendar from '@/assets/img/calendar.svg'
import clock  from '@/assets/img/clock.svg'
import actionIcon from '@/assets/img/dot-menu.svg'

export default {
    name: 'LessonHeader',
    props: ['data'],
    components: {
        Dropdown,
        Tooltip
    },
    data() {
        return {
            calendar,
            clock,
            actionIcon,
            timezoneDate
        }
    },
    mixins: [dropdownMixin, setAvatarMixin, openCancelLessonModal],
    computed: {
        rescheduledApiUrl() {
            return this.data.actionUrl;
        }
    }
}
</script>

