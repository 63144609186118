<template>
    <div class="gauge-chart"></div>
</template>

<script>
import d3 from 'd3-3.5.17';

export default {
    name: 'Gauge',
    props: ['value'],
    data() {
        return {
            size: 100
        }
    },
    mounted() {
        const arc = d3.svg.arc()
            .cornerRadius(50)
            .innerRadius(this.size - 28)
            .outerRadius(this.size - 12)
            .startAngle(-Math.PI / 2);

        const arc2 = d3.svg.arc()
            .cornerRadius(50)
            .innerRadius(this.size - 26)
            .outerRadius(this.size - 14)
            .startAngle(-Math.PI / 2);

        const svg = d3.select('.gauge-chart')
            .append('svg')
            .attr('width', this.size * 2)
            .attr('height', this.size + 12)
            .attr('class', 'gauge');

        const chart = svg
            .append('g')
            .attr('transform', 'translate(' + this.size + ',' + this.size + ')')

        chart.append('path')
            .datum({
                endAngle: Math.PI / 2
            })
            .attr('class', 'background')
            .attr('d', arc2);

        this.foreground = chart
            .append('path')
            .datum({
                endAngle: -Math.PI / 2
            })
            .attr('class', 'slider')
            .style('fill', '#ff4249')
            .attr('d', arc);

        this.update(this.value || 0);
    },
    methods: {
        update(v) {
            const color = d3.scale.linear()
            .domain([0, 50, 100])
            .range(['#FF5252', '#FFD447', '#3ECE4F']);
    
            v = d3.format('.1f')(v);

            this.foreground
                .transition()
                .duration(750)
                .style('fill', () => {
                    return color(v);
                })
                .call(this.arcTween, v);
            
            const svg = d3.select('.gauge-chart')
                .append('svg')
                .attr('width', this.size * 2)
                .attr('height', this.size + 12)
                .attr('class', 'gauge text');

            const val = svg.append('g')
                .attr('transform', 'translate(' + this.size + ',' + (this.size  * .9) + ')')
                .append('text')
                .text(0)
                .attr('text-anchor', 'middle')
                .attr('class', 'value')

            val.transition()
                .duration(750)
                .call(this.textTween, v);
        },

        arcTween(transition, v) {
            const newAngle = v / 100 * Math.PI - Math.PI / 2;

            const arc = d3.svg.arc()
                .cornerRadius(50)
                .innerRadius(this.size - 28)
                .outerRadius(this.size - 12)
                .startAngle(-Math.PI / 2);

            transition.attrTween('d', (d) => {
                const interpolate = d3.interpolate(d.endAngle, newAngle);

                return (t) => {
                    d.endAngle = interpolate(t);
                    return arc(d);
                };
            });
        },
        textTween(transition, v) {
            transition.tween('text', () => {
                const interpolate = d3.interpolate(this.innerHTML, v)
                    , round = 1;

                return function(t) {
                    this.innerHTML = (Math.round(interpolate(t) * round) / round) + '<tspan>%</tspan>';
                };
            });
        }
    }
}
</script>

<style lang="scss">
    .gauge-chart {
        display: flex;
        width: 200px;

        .gauge .background {
            fill: $gray-200;
        }
        
        .gauge .value {
            fill: #2D3642;
            font-weight: 600;
            font-size: 32px;
            line-height: 56px;
            transform: translateX(7px);
        }

        .gauge .value tspan {
            font-size: 1rem;
        }
        
        .gauge .scale text {
            fill: #999;
            font-size: 0.75rem;
        }

        .text {
            position: absolute;
        }
    }
</style>
