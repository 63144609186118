<template>
    <Dropdown pos="left" v-click-outside="hide" class="main-dropdown--tools">
        <template v-for="tool in tools">
            <router-link
                :class="{ 'tool--askAI' : tool === 'askAI', 'tool--askAI-active' : activeTool === 'askAI' && tool === $route.params.tool }"
                v-if="checkIfShowTool(tool)"
                :key="tool.text" 
                @click.native="hide" 
                :to="toolsRedirect(tool)"
            >
                <img :src="require(`@/assets/img/tools/${ tool }-small${ tool === $route.params.tool ? '-active' : '' }.svg`)" alt="">
                {{ $i18n.t(`tools.${ tool }.short-name`) }}
                <template v-if="isMobile">
                    <img class="arrow" :src="arrow" alt="">
                    <img class="arrow-active" :src="arrowBlue" alt="">
                </template>
            </router-link>
        </template>
    </Dropdown>
</template>

<script>
import Dropdown from '@/components/reusable/Dropdown'
import ClickOutside from 'vue-click-outside'
import { mapState, mapGetters } from 'vuex'
import toolsRedirect from '@/mixins/tools/toolsRedirect'
import arrow from '@/assets/img/arrow.svg'
import arrowBlue from '@/assets/img/arrow-blue.svg'

export default {
    name: 'NavTools',
    components: {
        Dropdown
    },
    mixins: [toolsRedirect],
    mounted() {
        this.popupItem = document.querySelector('.nav__dropdown--tools')
    },
    data() {
        return {
            arrow,
            arrowBlue,
        }
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('tools', ['tools', 'activeTool']),
        ...mapGetters('tools', ['isGrammarSupported'])
    },
    methods: {
        hide() {
            this.$emit('hide', 'tools');
            this.$store.state.blurBackground = false;
        },
        checkIfShowTool(val) {
            return val === 'grammar' ? this.isGrammarSupported : true
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss" scoped>
a {
    display: flex;
    align-items: center;
    gap: 8px;
    @include font-medium;

    .router-link-active {
        background: $primary-light;
        color: $primary-dark;
    }

    .tool {
        &--askAI {
            color: $orange-dark;

            &-active {
                background-color: $orange-light;
            }
        }
    }
}

    @media(max-width: $mobile-tools) {
        .main-dropdown.main-dropdown--tools {
            position: relative;
            transform: none;
            width: initial;
            padding: 8px 0 0;
            box-shadow: none;

            > a {
                margin-bottom: 0;
            }
            .arrow,
            .arrow-active {
                margin-left: auto;
                margin-right: -16px;
            }
        }

        .router-link-exact-active {
            .arrow {
                display: none !important;
            }

            .arrow-active {
                display: block !important;
            }
        }

        .arrow {
            display: block !important;
        }

        .arrow-active {
            display: none !important;
        }
    }
</style>