<template>
    <Modal>
        <div class="delete-message modal-inner">
            <img 
                src="@/assets/img/chat/delete-message.svg" 
                alt=""
                width="64"
                height="64"
            >
            <p class="title">{{ $i18n.t('messages.delete-modal.title') }}</p>
            <div class="buttons">
                <Button 
                    class="default" 
                    @click.native="$emit('hide')" 
                    :text="$i18n.t('messages.delete-modal.close')"
                />
                <Button 
                    class="danger" 
                    @click.native="deleteMessage" 
                    :text="$i18n.t('messages.delete-modal.accept')"
                />
            </div>
        </div>
    </Modal>
</template>

<script>
import modalMixin from '@/mixins/modal'
import Button from '@/components/reusable/Button'
import { mapState } from 'vuex'

export default {
    name: 'DeleteMessage',
    props: ['data'],
    components: {
        Button
    },
    mixins: [modalMixin],
    computed: {
        ...mapState([
            'editMessage'
        ])
    },
    methods: {
        deleteMessage() {
            if(this.editMessage?.uuid === this.data.uuid) this.$store.commit('setEditMessage', null)

            this.$store.dispatch('updateChatMessage', {
                uuid: this.data.uuid,
                type: this.data.type,
                isDeleted: true
            })

            this.$emit('hide')
        }
    }
}
</script>

<style lang="scss" scoped>
.delete-message {
    width: 528px;
    padding: 20px;
    text-align: center;

    @media(min-width: $mobile) {
        padding: 40px;
    }

    .title {
        @include font-h2;
        color: $gray-900;
        margin: 24px 0 45px;
    }

    .buttons {
        display: flex;
        gap: 16px;

        .btn {
            flex: 1;
        }
    }
}
</style>