<template>
    <div class="widget podcast">        
        <div class="podcast__wrapper">
            <img 
                src="@/assets/img/podcast/cover.svg"
                class="podcast__cover"
                alt=""
            >
            <div class="podcast__content">
                <div class="podcast__author">
                    <img 
                        src="@/assets/img/podcast/play.svg"
                        alt=""
                    >
                    <span>{{ $i18n.t('dashboard.podcast.title') }}</span>
                </div>
                <div class="podcast__header">{{ title }}</div>
                <div class="podcast__date-container">
                    <span>{{ publishedDate }}</span>
                    <span>{{ duration }}</span>
                </div>
            </div>
        </div>
        <div class="check-on">{{ $i18n.t('podcast-fluentbe.listen-at') }}</div>
        <div class="sources">
            <a
                v-for="source in sources"
                :key="source.name"
                class="source"
                :href="source.link"
                target="_blank"
                @click="sourceClickHandle(source.name)"
            >
                <img
                    :src="require(`@/assets/img/podcast/${ source.name }.svg`)"
                    alt=""
                    width="24"
                    height="24"
                >
                <p class="source-name">{{ source.name }}</p>
            </a>
        </div>
        <div class="pill">{{ $i18n.t('podcast-fluentbe.title') }}</div>
    </div>
</template>

<script>
import timezoneDate from '@/functions/timezoneDate';

export default {
    name: 'Podcast',
    props: ['data'],
    data() {
        return {
            sources: [
                {
                    name: 'spotify', 
                    link: 'https://open.spotify.com/show/5CLfYFBHIH6Z5CMPum6q8L?si=903092bab5854c4a'
                },
                {
                    name: 'apple', 
                    link: 'https://podcasts.apple.com/us/podcast/angielski-z-karolin%C4%85-z-fluentbe-com/id1642513510'
                },
                {
                    name: 'google', 
                    link: 'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xOTcyMzQ5LnJzcw'
                }
            ],
            title: '',
            publishedDate: '',
            duration: '',
            timezoneDate
        }
    },
    created() {
        const latestPodcast = this.data.querySelectorAll('item')[0];
    
        this.title = latestPodcast.querySelector('title').innerHTML;
        
        this.publishedDate = this.$moment(latestPodcast.querySelector('pubDate').innerHTML).format(timezoneDate('D MMMM'));

        const time = latestPodcast.querySelector('duration').innerHTML;

        const timeObject = {
            hours: Math.floor(time / 3600) > 0 ? Math.floor(time / 3600) + ' h' : '',
            minutes: Math.floor(time % 3600 / 60) > 0 ? Math.floor(time % 3600 / 60) + ' min' : '',
            seconds: Math.floor(time % 3600 % 60) > 0 ? Math.floor(time % 3600 % 60) + ' sek' : ''
        }

        this.duration = `${ timeObject.hours } ${ timeObject.minutes } ${ timeObject.seconds }`;
    },
    methods: {
        sourceClickHandle(source) {
            this.$gtag({
                category: 'btnClick',
                label: source
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.widget.podcast {
    color: $gray-900;
    background-image: url('~@/assets/img/podcast/podcast-new-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    position: relative;

    .check-on {
        @include font-small-10;
        text-transform: uppercase;
        color: $gray-700;
    }

    .pill {
        background: #B3B7BE;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        gap: 8px;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        position: absolute;
        right: 8px;
        bottom: 8px;

        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .podcast__wrapper {
        display: flex;
        gap: 17px;
    }

    .podcast__author {
        color: $gray-600;
        display: flex;
        height: 100%;
        gap: 4px;
        @include font-medium;
    }

    .podcast__cover {
        margin-top: -16px;
        width: 120px;
        height: 120px;
        margin-left: -19px;
    }

    .podcast__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 4px;
    }

    .podcast__header {
        @include font-h3;
        color: $gray-900;
    }

    .podcast__date-container {
        margin-top: 4px;
        display: flex;
        gap: 8px;
        color: $gray-500;
        @include font-small-12;
    }

    .sources {
        display: flex;
        gap: 14px;
        margin-top: 8px;
        margin-bottom: 12px;
        justify-content: space-between;
        flex-wrap: wrap;

        .source {
            display: flex;
            width: 30%;
            padding: 8px;
            gap: 8px;
            align-items: center;
            border-radius: 6px;
            background: $white;
            border: 1px solid $gray-200;
            transition: all .25s;
            flex: none;

            @media (min-width: 768px) and (max-width: 806px)  {
                gap: 4px;
                padding: 7px;
            }

            &:hover {
                background: $gray-100;
                border-color: $gray-300;
            }

            .source-name {
                @include font-medium;
                font-size: 13px;
                
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>