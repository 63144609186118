<template>
    <div v-if="loading">
        <img v-if="!isMobileDevice" src="@/assets/img/progressTest/media-permission.svg" alt="">
    </div>
    <p v-else-if="error" class="audio-setup__mic-error">{{ $t('audio-setup.mic-error') }}</p>
    <div v-else class="audio-setup">
        <div v-for="action in actions" :key="action.kind" class="action">
            <div class="action__icon">
                <img :src="action.icon" alt="">
            </div>
            <div class="action__content">
                <div class="action__header">
                    <p class="action__title">{{ action.title }}</p>
                </div>

                <div class="action__select-wrapper">
                    <Select
                        v-if="!(action.kind === 'audiooutput' && (isMobileDevice || !isAudiooutputSupported))"
                        @change="action.select.type === 'video' && setupAudio('select')" 
                        v-model="selectedDevices[action.kind]"
                        :data="{
                            label: action.select.label,
                            options: devices[action.kind].map(el => {
                                return {
                                    value: el.deviceId,
                                    text: el.label
                                }
                            })
                        }"
                    />

                    <Button
                        v-if="action.kind === 'audiooutput'"
                        class="btn default sound"
                        :text="$i18n.t('audio-setup.test-sound')"
                        @click.native="playSound"
                        :disabled="soundIsPlaying"
                    />
                </div>

                <AudioVolume
                    v-if="['audioinput', 'audiooutput'].includes(action.kind)" 
                    :value="action.kind === 'audioinput' ? 'input' : 'output'"
                    :label="$i18n.t(action.kind === 'audioinput' ? 'audio-setup.input-level' : 'audio-setup.output-level')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import audio from '@/mixins/audio'
import devices from '@/mixins/devices'
import Select from '@/components/reusable/Select'
import Button from '@/components/reusable/Button'
import AudioVolume from '@/components/reusable/AudioVolume'

export default {
    name: 'AudioSetup',
    mixins: [audio, devices],
    components: {
        Select,
        Button,
        AudioVolume
    },
    data() {
        return {
            loading: true,
            error: false,
            stream: null,
            actions: [
                {
                    icon: require('@/assets/img/audioPlayer/mike.svg'),
                    title: this.$t('audio-setup.input.title'),
                    kind: 'audioinput',
                    select: {
                        label: this.$t('audio-setup.input.select-label'),
                        type: 'video'
                    }
                },
                {
                    icon: require('@/assets/img/audioPlayer/volume.svg'),
                    title: this.$t('audio-setup.output.title'),
                    kind: 'audiooutput',
                    select: {
                        label: this.$t('audio-setup.output.select-label')
                    }
                }
            ]
        }
    },
    created() {
        this.$bus.$on('deviceChange', () => this.start())
    },
    async mounted() {
        await this.start()
    },
    beforeDestroy() {
        this.saveSelectedDevices()
        this.$bus.$off('deviceChange')
        this.stream?.getTracks().forEach(track => track.stop())
        clearInterval(this.audioInterval)
    },
    computed: {
        ...mapState(['isMobileDevice']),
        ...mapState('media', ['isAudiooutputSupported'])
    },
    methods: {
        async start() {
            this.loading = true
            this.error = false

            try {
                await this.setupAudio()
                await this.getDevices()
            } catch(e) {
                console.log(e)
                this.error = true
            } finally {
                this.loading = false
            }
        },
        async setupAudio() {
            this.stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.selectedDevices.audioinput
                }
            })
            
            this.startAudioInterval(this.stream)
        }
    },
    watch: {
        error(n) {
            this.$emit('input', n)
        }
    }
}
</script>

<style lang="scss" scoped>
$desktop: 768px;

.audio-setup {
    .action {
        margin: 24px 0;
        padding: 16px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        display: flex;
        gap: 16px;
        text-align: left;

        @media(min-width: $desktop) {
            padding: 24px;
        }

        &__icon {
            width: 24px;
            height: 24px;
            background: $gray-100;
            border-radius: 6px;
            display: none;
            align-items: center;
            justify-content: center;

            @media(min-width: $desktop) {
                display: flex;
            }
        }

        &__content {
            flex: 1;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            @include font-h3;
            margin-bottom: 16px;
        }

        &__select-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;

            .btn {
                width: 100%;
                height: 32px;
            }

            @media(min-width: $desktop) {
                flex-direction: row;

                .btn {
                    width: auto;
                }
            }

            label.input {
                margin: 0;
                flex: 1;
                width: 100%;

                ::v-deep select {
                    height: 32px;
                    padding: 7px;
                    @include font-regular;
                }
            }
        }

        .audio-volume {
            margin-top: 16px;
        }
    }

    &__mic-error {
        color: $danger;
        @include font-h3;
    }
}
</style>