<template>
    <div>
        <div v-for="n in config.days" :key="n" class="item" :class="{'active': showDetails[n]}">
            <div class="header">
                <ModernCheckbox :data="{label: capitalizeFirstLetter($moment.weekdays(true)[n-1])}" v-model="showDetails[n]"/>
                <p v-if="n === 1 && showDetails[1]" @click="copyToAll" class="copy">{{$i18n.t('onboarding.preferences.copy-to-all')}}</p>
            </div>
            <div v-if="showDetails[n]" class="ranges">
                <div v-for="(item, index) in formData[n]" :key="item.id" class="range">
                    <div class="slider">
                        <div class="hours">
                            <p>{{convertNumberToHour(item.values[0], true)}}</p>
                            <p>{{convertNumberToHour(item.values[1], true)}}</p>
                        </div>
                        <vue-slider 
                            v-model="formData[n].find(i => i.id === item.id).values"
                            :ref="item.id"
                            :min="config.min"
                            :max="config.max"
                            tooltip="none"
                            :height="8"
                            :dotSize="16"
                            :dotStyle="{
                                background: '#0782FF',
                                border: '4px solid #FFFFFF',
                                'box-shadow': '0px 2px 8px rgba(0, 0, 0, 0.16)'
                            }"
                            :processStyle="{
                                background: '#0782FF'
                            }"
                            :railStyle="{
                                background: '#E5EAEE'
                            }"
                            style="padding: 5px 0;"
                        >
                        </vue-slider>
                    </div>
                    <div class="actions">
                        <img v-if="index > 0" src="@/assets/img/onboarding/remove.svg" alt="" @click="removeRange(n, item.id)">
                        <img v-if="index === formData[n].length-1" class="add" src="@/assets/img/onboarding/add.svg" alt="" @click="addRange(n)">
                    </div>
                    <div v-if="index === formData[n].length-1" class="mobile-add" @click="addRange(n)">
                        <img src="@/assets/img/onboarding/add.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModernCheckbox from '@/components/reusable/ModernCheckbox'
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter'
import { v4 as uuidv4 } from 'uuid';
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'WeekHourRange',
    props: ['data', 'value'],
    components: {
        ModernCheckbox
    },
    data() {
        return {
            capitalizeFirstLetter,
            showDetails: {},
            formData: {},
            config: {
                min: 0,
                max: 47,
                start: 12,
                end: 40,
                days: 7,
                minDiff: this.data.min/30
            }
        }
    },
    created() {
        if(this.data.hourRangeData) {
            this.showDetails = this.data.hourRangeData.showDetails
            this.formData = this.data.hourRangeData.formData
        } else {
            let data = {},
            details = {}

            for(let i = 1; i < this.config.days+1; i++) {
                data[i] = [{
                    values: [this.config.start, this.config.end],
                    id: uuidv4()
                }]
                details[i] = false
            }
            this.showDetails = details
            this.formData = data
        }  
    },
    watch: {
        formData: {
            handler() {
                this.$emit('input', this.createData())
            },
            deep: true
        },
        showDetails: {
            handler() {
                this.$emit('input', this.createData())
            },
            deep: true
        }
    },
    methods: {
        createData() {
            this.$emit('hourRangeData', {formData: this.formData, showDetails: this.showDetails})
            let output = []
            for(let i = 1; i < this.config.days+1; i++) {
                if(this.showDetails[i]) {
                    let ranges = [...this.formData[i]]

                    const sumRanges = () => {
                        for(let range=0; range<ranges.length; range++) {
                            const rangeValues = ranges[range].values
                            const diff = rangeValues[1] - rangeValues[0]
                            if(diff < this.config.minDiff) {
                                let newRange0 = rangeValues[0]
                                let newRange1 = rangeValues[1]+= this.config.minDiff - diff
                                if(newRange1 > this.config.max) {
                                    newRange1 = this.config.max
                                    newRange0 = newRange1-= this.config.minDiff
                                }
                                this.$refs[ranges[range].id][0].setValue([newRange0, newRange1])
                            }
                        }

                        for(let range=0; range<ranges.length; range++) {
                            const rangeValues = ranges[range].values

                            for(let check=0; check<ranges.length; check++) {
                                const checkValues = ranges[check].values
                                if(
                                    ((rangeValues[0] >= checkValues[0] && rangeValues[0] <= checkValues[1]) || 
                                    (rangeValues[1] >= checkValues[0] && rangeValues[1] <= checkValues[1])) &&
                                    range !== check
                                ) {
                                    const sumArray = [...rangeValues, ...checkValues]
                                    const newRange = {values: [Math.min(...sumArray), Math.max(...sumArray)], id: uuidv4()}
                                    ranges = ranges.filter(item => ![ranges[range].id, ranges[check].id].includes(item.id))
                                    ranges.push(newRange)
                                    sumRanges()
                                    break
                                }
                            }
                        }
                    }

                    sumRanges()
                    ranges.sort((a, b) => a.values[0] - b.values[0])
                    
                    ranges.forEach(item => {
                        output.push({
                            day: String(i),
                            rangeStartAt: this.convertNumberToHour(item.values[0]),
                            rangeEndAt: this.convertNumberToHour(item.values[1])
                        })
                    })
                }
            }

            return JSON.stringify(output)
        },
        convertNumberToHour(num, formatting) {
            const hour = this.formatHour(num/2)
            const format = formatting ? timezoneDate('HH:mm') : 'HH:mm'
            const date = '1970/01/01'
            const dateFormat = 'YYYY/MM/DD HH:mm:ss'
            return hour % 1 === 0 ? this.$moment(`${date} ${hour}:00:00`, dateFormat).format(format) :
            this.$moment(`${date} ${Math.round(hour)-1}:30:00`, dateFormat).format(format)
        },
        formatHour(hour) {
            return hour < 10 ? '0'+hour : hour
        },
        addRange(day) {
            this.formData[day].push({
                values: [this.config.start, this.config.end],
                id: uuidv4()
            })
        },
        removeRange(day, id) {
            this.formData[day] = this.formData[day].filter(item => item.id !== id)
        },
        copyToAll() {
            for(let i = 2; i < this.config.days+1; i++) {
                this.showDetails[i] = true
                const ranges = this.formData[1]
                this.formData[i] = ranges.map(item => {
                    return {
                        values: [...item.values],
                        id: uuidv4()
                    }
                }) 
            }
        }
    }
}
</script>