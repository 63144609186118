export default {
    methods: {
        getGroupedClass(index) {
            const previous = this.msgs[index - 1]?.sender.uuid
            const current = this.msgs[index].sender.uuid
            const next = this.msgs[index + 1]?.sender.uuid

            if(current === next && next === previous) return 'middle'
            else if(current === previous) return 'bottom'
            else if(current === next) return 'top'
        }
    }
}