<template>
    <div class="date-select input" :class="dateFormatClass">
        {{data.label}}
        <div class="inner">
            <Select 
                v-for="field in fields"
                :key="field"
                :class="field"
                :data="createData(field)"
                v-model="formData[field]"
            />
        </div>
        <input type="hidden" :name="data.name" v-model="date">
    </div>
</template>

<script>
import Select from './Select.vue'

export default {
    name: 'DateSelect',
    props: ['data', 'value'],
    components: {
        Select
    },
    data() {
        return {
            fields: ['day', 'month', 'year'],
            formData: {
                day: this.getDatePart('DD'),
                month: this.getDatePart('MM'),
                year: this.getDatePart('YYYY')
            }
        }
    },
    computed: {
        date() {
            const {year, month, day} = this.formData

            if(year && month && day) return `${year}-${month}-${day}`

            return null
        },
        dateFormatClass() {
            const mapper = {
                ['DD/MM/YYYY']: 'dmy',
                ['MM/DD/YYYY']: 'mdy',
                ['YYYY/MM/DD']: 'ymd'
            }

            return mapper[this.$store.state.user.dateFormat]
        }
    },
    watch: {
        date() {
            this.$emit('input', this.date)
        }
    },
    methods: {
        createData(field) {
            const data = {
                label: '',
                placeholder: this.$i18n.t(`general.${field}`)
            }

            if(field === 'day') data.options = this.createNumberArray(1, 31)

            if(field === 'month') {
                const arr = this.$moment.months()
                
                data.options = arr.map((el, index) => {
                    return {
                        value: this.addZero(index + 1),
                        text: el
                    }        
                })
            }

            if(field === 'year') data.options = this.createNumberArray(
                this.$moment(this.data.min).year(), 
                this.$moment(this.data.max).year(), 
                true
            )

            return data
        },
        createNumberArray(min, max, reverse) {
            const arr = []

            for(let i = min; i < max + 1; i++) {
                i = this.addZero(i)

                arr.push({
                    value: i,
                    text: i
                })
            }

            if(reverse) arr.reverse()

            return arr
        },
        addZero(number) {
            return number < 10 ? '0' + number : number
        },
        getDatePart(value) {
            return this.value ? this.$moment(this.value).format(value) : null
        }
    }
}
</script>

<style lang="scss">
.date-select {
    flex: 1;
    cursor: default;

    &.mdy {
        .month {
            order: 1;
        }

        .day {
            order: 2;
        }

        .year {
            order: 3;
        }
    }

    &.ymd {
        .year {
            order: 1;
        }

        .month {
            order: 2;
        }

        .day {
            order: 3;
        }
    }

    .inner {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .input {
            margin: 0;
        }

        .day {
            flex: 2 !important;
        }

        .month {
            flex: 3 !important;
        }

        .year {
            flex: 2 !important;
        }
    }
}
</style>