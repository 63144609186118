<template>
    <div class="buttons-chain">
        <template v-for="(button, idx) in prepareButtons">
            <div class="button" :key="idx">
                <img :src="require(`@/assets/img/coursePlan/circle-${ ['new', 'scheduled'].includes(button.status) ? 'default' : button.status }.svg`)">
                <span class="text">{{ button.text }}</span>
                <span v-if="!showBaner" :class="['status', button.status]">
                    {{ $t(`new-course-plan.buttons-chain.status.${ button.status === 'scheduled' ? 'new' : button.status }`) }}
                </span>
                <a :class="['btn', button.btn.type]" @click="(event) => button.btn.click(event)">{{ button.btn.text }}</a>
            </div>
            <div v-if="idx !== prepareButtons.length - 1" :key="idx+'_separator'" class="separator"></div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ButtonsChain',
    props: ['material', 'materialId'],
    data() {
        return {
            buttonsData: ['preLesson', 'lesson', 'homework', 'flashcard']
        }
    },
    computed: {
        ...mapState('coursePlan', ['showBaner']),
        prepareButtons() {            
            return this.buttonsData.map(key => {
                if(!this.material[key]) return null;

                const status = this.replaceStatusString(this.material[key].status);
                const stopAt = this.material['lesson'].stopAt;
                const isCompleted = this.material['lesson'].status === 'COMPLETED';
                const url = this.material[key].url || (key === 'flashcard' && this.material[key].flashcardGroup.uuid);
                const isFlashCard = key === 'flashcard' && this.material[key].flashcardGroup?.uuid

                if (!url) return null;

                const obj = {
                    stopAt,
                    status,
                    lesson: key === 'lesson',
                    isPreLesson: key === 'preLesson',
                    isCompleted
                }

                return {
                    text: this.$t(`new-course-plan.buttons-chain.${ key.toLowerCase() }`),
                    status,
                    isFlashCard: isFlashCard,
                    url,
                    btn: {
                        text: this.$t(this.getBtnText(obj)),
                        type: this.getBtnType(obj),
                        click: (event) => this.handleBtnClick(event, isFlashCard, url)
                    }
                };
            }).filter(button => button !== null);
        },
    },
    methods: {
        replaceStatusString(status) {
            return status.replace('_', '-').toLowerCase();
        },
        getBtnType(data) {
            const { status, isPreLesson, stopAt, lesson, isCompleted } = data;
            if (this.showBaner) return 'default';

            const currentDate = new Date();
            if (isPreLesson && stopAt && !isCompleted) return 'secondary';
            if (!isCompleted) return 'default';
            if (lesson || !stopAt || new Date(stopAt) > currentDate) return 'default';

            if (['new', 'overdue'].includes(status)) return 'secondary';
            else if (status === 'completed') return 'default';
            else if (status === 'in-progress') return 'warning';
        },
        getBtnText(data) {
            const { status, isPreLesson, stopAt, lesson, isCompleted } = data;

            if (this.showBaner) return 'new-course-plan.buttons-chain.btn.preview';

            const currentDate = new Date();
            if (isPreLesson && stopAt && !isCompleted) return 'new-course-plan.buttons-chain.btn.new';
            if (!isCompleted) return 'new-course-plan.buttons-chain.btn.preview';
            if (lesson || !stopAt || new Date(stopAt) > currentDate) return 'new-course-plan.buttons-chain.btn.preview';

            if (['new', 'overdue'].includes(status)) return 'new-course-plan.buttons-chain.btn.new';
            else if (status === 'completed') return 'new-course-plan.buttons-chain.btn.preview';
            else if (status === 'in-progress') return 'new-course-plan.buttons-chain.btn.in-progress';
        },
        goToFlashCards() {
            const uuid = this.material.flashcard.flashcardGroup.uuid;

            this.$router.push({ name: 'flashcards', params: { uuid } });
        },
        handleBtnClick(event, isFlashCard, url) {
            event.preventDefault();

            const { materialId } = this

            if (this.$route.fullPath !== `${ this.$route.path }?${ new URLSearchParams({ materialId }) }`) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { materialId }
                })
            }
            isFlashCard ? this.goToFlashCards() : window.location = url
        }
    }
}
</script>

<style lang="scss" scoped>
.buttons-chain {
    display: flex;
    flex-direction: column;
    padding: 16px 0 24px 0;

    .button {
        display: flex;
        align-items: center;
        min-width: 455px;
        max-width: 455px;
        border: 1px solid $gray-200;
        border-radius: 14px;
        padding: 8px 8px 8px 16px;

        .text {
            margin-left: 12px;
            @include font-h4;
        }

        .status {
            margin: 2px 0 0 8px;
            @include font-h5;
            color: $gray-600;

            &.completed {
                color: $secondary-dark;
            }

            &.in-progress {
                color: $warning-dark;
            }

            &.overdue {
                color: $orange;
            }
        }

        ::v-deep {
            .btn {
                min-width: 98px;
                margin-left: auto;
                padding: 8px 14px;
                @include font-medium;
            }
        }
    }

    .separator {
        height: 8px;
        width: 4px;
        background-color: $gray-100;
        margin-left: 28px;
    }
}
</style>
