<template>
    <div class="switch-lector-filters">
        <div class="switch-lector-filters__box">
            <div 
                v-for="(value, filter) in filters"
                :key="filter"
                class="switch-lector-filters__filter-group"
                v-click-outside="() => hideDropdown(filter)"
            >
                <Tooltip 
                    v-if="getShowBuyToBook"
                    class="tooltip--right" 
                    :text="$i18n.t('switch-lector.list.filters.disabled-button-message')"
                />
                <Button
                    :showClose="showFilterCloseBtn(filter)"
                    :class="[
                        'default switch-lector-filters__filter', 
                        { 'switch-lector-filters__filter--active': isFilterActive(filter) }
                    ]"
                    :icon="require(`@/assets/img/schedule/${ filter }.svg`)"
                    :text="filterBtnLabel(filter)"
                    :disabled="getShowBuyToBook"
                    @close="resetFilter(filter)"
                    @click.native="() => filterClickHandle(filter)"
                />

                <TooltipHtml v-if="['nativeSpeaker', 'advanceNotice'].includes(filter) && filters[filter].open">
                    <div class="filter-tooltip__header">
                        <p class="filter-tooltip__title">{{ filters[filter].tooltip.title }}</p>
                        <ModernCheckbox 
                            @input="value => setFilter(filter, value)"
                            :value="switchLector.filters[filter].value"
                        />
                    </div>

                    <p class="filter-tooltip__desc">{{ filters[filter].tooltip.description }}</p>
                </TooltipHtml>

                <Dropdown
                    v-if="filters[filter].open && filter === 'languagesISpeak'"
                    pos="bottom"
                    class="not-simple-dropdown"
                >
                    <MultiSelect
                        :data="{
                            options: lectorLanguagesFormatted,
                            buttons: {
                                secondary: {
                                    text: $t('switch-lector.list.filters.languagesISpeak.btn.secondary'),
                                },
                                primary: {
                                    text: $t('switch-lector.list.filters.languagesISpeak.btn.primary'),
                                    showSelectedOptions: true
                                }
                            },
                            defaultSelected: switchLector.filters.languagesISpeak.value,
                            mobileTitle: $t('switch-lector.list.filters.languagesISpeak.mobile-title'),
                            focusOnMounted: !isMobileDevice
                        }"
                        @secondaryAction="() => hideDropdown(filter)"
                        @primaryAction="selected => languagesISpeakFilterSubmit(selected)"
                    />
                </Dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Button from '@/components/reusable/Button'
import TooltipHtml from '@/components/reusable/TooltipHtml';
import Tooltip from '@/components/reusable/Tooltip';
import MultiSelect from '@/components/reusable/MultiSelect';
import Dropdown from '@/components/reusable/Dropdown';
import ModernCheckbox from '@/components/reusable/ModernCheckbox'
import ClickOutside from 'vue-click-outside';
import resetLectorFilterStatus from '@/mixins/schedule/resetLectorFilterStatus'
import { SCHEDULE_SCREEN } from '@/constants/schedule';
import dropdownMixin from '@/mixins/dropdown'

export default {
    name: 'SwitchLectorFilters',
    components: {
        Button,
        Tooltip,
        TooltipHtml,
        MultiSelect,
        Dropdown,
        ModernCheckbox
    },
    directives: { ClickOutside },
    mixins: [resetLectorFilterStatus, dropdownMixin],
    data() {
        return {
            filters: {
                advanceNotice: {
                    open: false,
                    tooltip: {
                        key: 'advanceNotice',
                        title: this.$i18n.t('switch-lector.list.filters.advanceNotice.tooltip.title'),
                        description: this.$i18n.t('switch-lector.list.filters.advanceNotice.tooltip.desc')
                    }
                },
                date: {
                    daysCount: 30,
                    startHour: 6, 
                    gapFromNow: 3
                },
                nativeSpeaker: {
                    open: false,
                    tooltip: {
                        key: 'nativeSpeaker',
                        title: this.$i18n.t('switch-lector.list.filters.nativeSpeaker.tooltip.title'),
                        description: this.$i18n.t('switch-lector.list.filters.nativeSpeaker.tooltip.desc')
                    }
                },
                languagesISpeak: {
                    open: false,
                }
            }
        }
    },
    mounted() {
        if(!this.isMobileDevice) window.addEventListener('resize', this.hideFilterDropdown);
    },
    beforeDestroy() {
        if(!this.isMobileDevice) window.removeEventListener('resize', this.hideFilterDropdown);
    },
    created() {
        if (!this.lectorsLanguages) this.$store.dispatch('getLectorsLanguages');
    },
    computed: {
        ...mapState(['lectorsLanguages', 'isMobileDevice', 'windowWidth']),
        ...mapState('schedule', ['switchLector', 'lectorLangs']),
        ...mapGetters(['getShowBuyToBook']),
        ...mapGetters('schedule', [
            'isDateFilter',
            'islanguagesISpeakFilterActive',
            'isAdvanceNoticeFilterActive',
            'isNativeSpeakerFilterActive'
        ]),
        lectorLanguagesFormatted() {
            return this.lectorsLanguages.map(obj => ({
                text: this.$te(obj.name.toLowerCase()) ? this.$t(obj.name.toLowerCase()) : obj.name,
                value: obj.shortName
            }));
        }
    },
    methods: {
        isFilterActive(filter) {
            if(filter === 'date' && this.isDateFilter) return true;
            if(filter === 'languagesISpeak' && this.islanguagesISpeakFilterActive) return true;
            if(filter === 'advanceNotice' && this.isAdvanceNoticeFilterActive) return true;
            if(filter === 'nativeSpeaker' && this.isNativeSpeakerFilterActive) return true;
        },

        filterClickHandle(filter) {
            ['nativeSpeaker', 'advanceNotice'].includes(filter) 
                ? this.openTooltip(filter) 
                : this.setFilter(filter)
        },

        setFilter(filter) {
            if(filter === 'date') {
                this.createFilterDays()
                this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.FILTERS_DATE })
                this.$store.commit('schedule/setModalTitle', this.$i18n.t('modal.book-lesson.header.filter-date', { slotsCount: this.isDateFilter }))
                return;
            }

            if(filter === 'languagesISpeak') {
                this.$bus.$emit('toggleLangISpeakDropdown', !this.filters.languagesISpeak.open);
                this.filters.languagesISpeak.open = !this.filters.languagesISpeak.open
                return;
            }

            if(filter === 'advanceNotice') {
                if(this.isDateFilter) this.resetFilter('date')
                this.switchLector.filters[filter].value = !this.switchLector.filters[filter].value;
                this.$store.commit('schedule/toggleFilterActive', filter);

                if(this.isAdvanceNoticeFilterActive) {
                    this.$gtag({
                        category: 'btnClick',
                        action: 'filter_24h'
                    })
                }
            }

            if(filter === 'nativeSpeaker') {
                this.switchLector.filters[filter].value = !this.switchLector.filters[filter].value;
                this.$store.commit('schedule/toggleFilterActive', filter);
            }

            setTimeout(() => this.$store.dispatch('schedule/reloadLectorsList'), 500);
        },
        resetFilter(filter) {
            if(filter === 'date') {
                this.switchLector.filters.date.value = []
                this.resetLectorFilterStatus(this.switchLector.data)
                document.querySelector('.switch-lector-list').scroll({ top: 0 })
                this.$bus.$emit('resetDateFilter')
            } 
            
            if(['languagesISpeak'].includes(filter)) {
                this.switchLector.filters[filter] = {
                    value: [],
                    active: false
                }
                this.$store.dispatch('schedule/reloadLectorsList');
            } 
        },
        showFilterCloseBtn(filter) {
            if (!this.isFilterActive(filter)) return;

            return ['date', 'languagesISpeak'].includes(filter)
        },
        createFilterDays() {
            const { daysCount, gapFromNow } = this.filters.date
            const daysArray = []

            for(let i = 0; i < daysCount; i++) {
                const isToday = i === 0
                
                if(isToday && this.$moment().hours() >= 24 - gapFromNow) continue

                const day = {}

                day.date = this.$moment().add(i, 'days').format('YYYY-MM-DD')
                day.slots = this.getAvaliableHourSlots(day.date, isToday)

                daysArray.push(day)
            }

            this.$store.state.schedule.filterDays = daysArray
        },
        getAvaliableHourSlots(date, isToday) {
            const { startHour, gapFromNow } = this.filters.date
            let i = startHour

            if(isToday) i = Math.max(this.$moment().hours() + gapFromNow, startHour)

            const slots = []

            for(i; i < 24; i++) {
                const startAt = this.$moment(date).add(i, 'hours').format('YYYY-MM-DD HH:mm:ss')

                slots.push({
                    booked: this.switchLector.filters.date.value.includes(startAt) || false,
                    startAt
                })
            }

            return slots
        },
        filterBtnLabel(filter) {
            const { filters } = this.switchLector;
            
            if(filters[filter].active && filter === 'languagesISpeak') {
                return this.$i18n.t(`switch-lector.list.filters.${ filter }.activeBtn`) + ' ' + filters[filter].value.length 
            } 

            return this.$i18n.t(`switch-lector.list.filters.${ filter }`);
        },
        languagesISpeakFilterSubmit(selected) {
            this.switchLector.filters.languagesISpeak.value = selected;
            this.switchLector.filters.languagesISpeak.active = !!this.switchLector.filters.languagesISpeak.value.length;
            this.$store.dispatch('schedule/reloadLectorsList');
            this.$bus.$emit('toggleLangISpeakDropdown', false);

            this.$gtag({
                category: 'btnClick',
                action: 'filter_languages'
            })
        },
        hideFilterDropdown() {
            Object.keys(this.filters).map(el => {
                if (this.filters[el].open) {
                    this.filters[el].open = false;
                }
            });
        },
        hideDropdown(filter) {
            if (filter === 'languagesISpeak') this.$bus.$emit('toggleLangISpeakDropdown', false);

            if (['nativeSpeaker', 'advanceNotice', 'languagesISpeak'].includes(filter)) {
                this.filters[filter].open = false;
            }
        },

        openTooltip(filter) {
            if (['nativeSpeaker', 'advanceNotice'].includes(filter)) {
                this.filters[filter].open = !this.filters[filter].open;
            }
        }
    },
    watch: {
        windowWidth() {
            this.filters.languagesISpeak.open = false;
        }
    }
}
</script>

<style lang="scss" scoped>

::v-deep {
    .main-dropdown {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: none;
        box-shadow: none;
        bottom: 12px;
        border-radius: 0;
        padding: 0;
        
        @media(min-width: $tablet-switch-lector) {
            height: 300px;
            width: 224px;
            position: absolute;
            left: initial;
            top: initial;
            padding: 8px 0;
        }

        @media(max-width: ($tablet-switch-lector - 1)) {
            transform: translate(0, 0) !important;
        }
    }

    .multi-select {
        border-radius: 0;

        @media(min-width: $tablet-switch-lector) {
            border-radius: 12px;
        }

        .buttons {
            flex-direction: column;
            justify-content: flex-end;
            padding: 24px 15px;

            .btn {
                padding: 11px 15px;

                &:first-child {
                    order: 1;

                    @media (min-width: $tablet-switch-lector) {
                        order: initial;
                        padding: 7px 15px;
                    }
                }
            }

            @media (min-width: $tablet-switch-lector) {
                padding: 11px 15px;
                flex-direction: row;
            }
        }
    }
}

.button-text {
    white-space: nowrap;
}

.switch-lector-list .switch-lector-filters {
    position: sticky;
    top: 0;
    gap: 8px;
    display: flex;
    z-index: 2;
    background: $white;
    padding: 6px 0 8px;

    &__filter-group {
        flex-shrink: 0;

        &:has(.filter-tooltip__header) {
            position: relative;
        }
    }

    &__filter {
        @include font-medium;

        &--active {
            border-color: $primary-mid;
            background: $primary-light;

            &:hover {
                border-color: $primary-mid;
                background: #daedff; 
            }

        }
    }

    .tooltip {
        width: 170px;
        white-space: initial;

        &.tooltip--right {
            transform: translate(8px, -34%);

            @media(min-width: $mobile-switch-lector) {
                transform: translate(8px, -50%);
            }
        }

        @media(min-width: $mobile-switch-lector) {
            width: initial;
            white-space: nowrap;
        }
    }
}

.switch-lector-filters__box {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.filter-tooltip__header {
    padding: 5px 10px;
    background-color: $gray-100;
    border-radius: 6px;
    gap: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modern-checkbox {
        grid-template-columns: initial;
        width: 32px;
        min-width: 32px;
        height: 20px;
        display: block;

        ::v-deep .checkmark {
            height: 20px !important;
            width: 32px !important;
        }

        ::v-deep .point {
            top: 3px !important;
            left: 3px !important;
            width: 14px;
            height: 14px;
        }

        ::v-deep input:checked ~ .checkmark .point {
            transform: translateX(12px) !important;
        }
    }
}


.filter-tooltip__title {
    @include font-h4;
    line-height: 20px;
}

.filter-tooltip__desc {
    @include font-small-12-regular;
    padding: 8px 8px 4px;
}
</style>