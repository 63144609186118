<template>
    <div v-if="!loading" class="payments">
        <div v-if="$store.getters.isBusiness" class="business">
            <p class="package">{{$i18n.t('dashboard.student.business-package')}}
                <span class="info-tooltip"><img src="@/assets/img/info.svg" alt="">
                    <Tooltip :text="`${$i18n.t('account.payments.paid-by')}:<br>${studentCompany}`"/>
                </span>
            </p>
            <p class="frequency">{{showLimit(course) ? `${$i18n.t('dashboard.student.lessons-limit')}: ${showLimit(course)}` : $i18n.t('account.payments.no-limit')}}</p>
        </div>
        <template v-else>
            <h3 class="section-title">{{$i18n.t('account.payments.list.title')}}</h3>
            <List :data="listData" />
        </template>
    </div>
</template>

<script>
import { salePayments } from '@/graphql/queries/salePayments'
import { activeStudentGroups } from '@/graphql/queries/activeStudentGroups'
import api from '@/graphql/api.js'
import List from '@/components/reusable/list/List'
import Tooltip from '@/components/reusable/Tooltip'
import { mapGetters } from 'vuex'
import courseLimitMixin from '@/mixins/courseLimit'

export default {
    name: 'Payments',
    components: {
        List,
        Tooltip
    },
    data() {
        return {
            loading: true,
            listData: {
                filters: [
                    {
                        id: 'date',
                        label: this.$i18n.t('account.payments.list.date')
                    },
                    {
                        id: 'offer',
                        label: this.$i18n.t('account.payments.list.offer')
                    },
                    {
                        id: 'price',
                        label: this.$i18n.t('account.payments.list.price')
                    },
                    {
                        id: 'invoice',
                        label: this.$i18n.t('account.payments.list.invoice')
                    }
                ],
                items: null,
                type: 'profilePayments',
                perPage: 10
            },
            course: null
        }
    },
    mixins: [courseLimitMixin],
    async created() {
        const {res} = await api(salePayments)
        this.listData.items = res.currentUser.student.salePayments

        const {res:groups} = await api(activeStudentGroups)
        this.course = groups.currentUser.student.activeStudentGroups.find(item => item.group.company)

        this.loading = false
        this.$emit('loaded')
    },
    computed: {
        ...mapGetters(['studentCompany'])
    }
}
</script>

