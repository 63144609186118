import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

export const preventEnter = Extension.create({
    name: 'preventEnter',
    addProseMirrorPlugins() {
        return [
            new Plugin({
            key: new PluginKey('preventEnter'),
            props: {
                handleKeyDown: (view, event) => {
                    if(event.key === 'Enter' && localStorage.getItem('enterSend') === 'true') return true
                }
            },
            }),
        ]
    },
})