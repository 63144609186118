<template>
    <div class="onboarding-wrapper">
        <div class="bg-white nav-bar">
            <div class="wrapper wrapper--big">
                <nav class="nav">
                    <div class="nav__left">
                        <img class="logo" :src="logo" alt="Fluentbe">
                        <div class="title">Onboarding - {{ printOnboardingTitle }}</div>
                    </div>
                    <div class="nav__right" v-if="showCancelBtn">
                        <Button :icon="require('@/assets/img/logout.svg')" :text="$i18n.t('onboarding.nav.cancel')" class="default font-medium" @click.native="$router.push({name: 'dashboard'})"/>
                    </div>
                </nav>
            </div>
        </div>
        <div class="onboarding-content" :class="{'wide': wideOnboarding}">
            <router-view />
        </div>
    </div>
</template>

<script>
import logo from '@/assets/img/logo-small.svg'
import Button from '@/components/reusable/Button'
import onboardingType from '@/mixins/onboardingMeetingType';

export default {
    name: 'OnboardingWrapper',
    mixins: [onboardingType],
    components: {
        Button
    },
    data() {
        return {
            logo
        }
    },
    computed: {
        wideOnboarding() {
            return this.$store.state.wideOnboarding
        },
        showCancelBtn() {
            return this.$route.name !== 'Meeting';
        }
    }
}
</script>
