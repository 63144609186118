import gql from 'graphql-tag'

export const validateStudentPreferences = gql`
    mutation($userUuid: ID!, $type: String!, $token: String!, $step: Int, $fields: [UpdateStudentPreferencesFieldInput]!, $locale: String!) {
        validateStudentPreferences(input: {userUuid: $userUuid, type: $type, token: $token, step: $step, fields: $fields, locale: $locale}) {
            step
            title
            values {
                label
                parameters
                type
                value
            }
        }
    }
`