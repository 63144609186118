import { mapState } from 'vuex'

export default {
    mounted() {
        window.addEventListener('requestedUserUuid', () => {
            if (this.$store.getters.userUuid) {
                if(this.isIOSApp) window.dispatchEvent(new CustomEvent('receivedUserUuid', { detail: this.$store.getters.userUuid }))
                else window.Android.receivedUserUuid(this.$store.getters.userUuid);
            }
        })
    },
    beforeDestroy() {
        if(this.isIOSApp) window.dispatchEvent(new CustomEvent('firebaseUnsubscribe'))
        else if(this.isAndroidApp) window.Android.firebaseUnsubscribe();
    },
    computed: {
        ...mapState(['isAndroidApp', 'isIOSApp']),
    }
}