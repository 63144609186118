var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['single-choice', { 'single-choice--completed': _vm.isCompleted }]},[_c('div',{staticClass:"single-choice__header"},[_c('RoundedNumber',{attrs:{"size":"large","character":_vm.data.number}}),_c('p',{staticClass:"single-choice__title"},[_vm._v(_vm._s(_vm.data.name))])],1),_c('div',{class:['single-choice__content', { 'single-choice__content--disabled': _vm.disabled }]},[(_vm.data.media?.length)?_c('Media',{attrs:{"data":_vm.data.media}}):_vm._e(),_vm._l((_vm.data.questions),function(question){return _c('div',{key:question.uuid,staticClass:"question"},[_c('div',{staticClass:"question__title"},_vm._l((question.question),function(part,index){return _c('span',{key:index,class:[
                        'question__title-part', 
                        { 'question__title-part--text': part, 'question__title-part--blank': !part }
                    ]},[_vm._v(" "+_vm._s(part)+" ")])}),0),_c('div',_vm._l((question.answers),function(answer){return _c('Radio',{key:answer.uuid,staticClass:"question__answer",attrs:{"data":{
                        name: question.uuid,
                        label: answer.answer,
                        radioValue: answer.uuid,
                        isCorrect: answer.isCorrect,
                        isCompleted: _vm.isCompleted
                    }},model:{value:(_vm.formData[_vm.data.arrayBased ? 0 : question.uuid]),callback:function ($$v) {_vm.$set(_vm.formData, _vm.data.arrayBased ? 0 : question.uuid, $$v)},expression:"formData[data.arrayBased ? 0 : question.uuid]"}})}),1)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }