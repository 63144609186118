<template>
    <div 
        :class="['tools', {
            [`tools--${ activeTool }`]: activeTool,
            'tools--safari' : isSafariBrowser,
            'tools--grammar-safari-overflow' : activeTool === 'grammar' && isSafariBrowser,
            'tools--no-resize': isAskAIModalOpen || isAskAIResultsModalOpen
        }]"
        ref="tools"
    >
        <div ref="toolsNav" class="tools__nav">
            <div class="tools__nav-info">
                <img :src="getIconByTool('bubble')" alt="" draggable="false">
                <p :class="['tools__nav-title', { 'tools__nav-title--askAI' : activeTool === 'askAI' }]">{{ $i18n.t(`tools.title.${ activeTool }`) }}</p>
            </div>
            <div class="tools__nav-actions">
                <img 
                    v-if="!isMobile"
                    @click="openToolsPage" 
                    :src="getIconByTool('extend')" 
                    alt=""
                    draggable="false"
                >
                <img 
                    @click="closeTools(true)" 
                    :src="getIconByTool('close')" 
                    alt=""
                    draggable="false"
                >
            </div>
        </div>
        <div class="tools__content">
            <component v-if="showTools" :is="toolsMap[activeTool]" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import toolsMapMixin from '@/mixins/tools/toolsMap'
import getIconByTool from '@/mixins/tools/getIconByTool'
import toolsRedirect from '@/mixins/tools/toolsRedirect'
import { checkSpecificBrowsers } from '@/helpers/browserSupport';

export default {
    name: 'Tools',
    data() {
        return {
            tools: null,
            toolsNav: null,
            moveToolsTimeout: null
        }
    },
    mixins: [toolsMapMixin, getIconByTool, toolsRedirect],
    mounted() {
        this.tools = this.$refs.tools
        this.toolsNav = this.$refs.toolsNav

        if (!this.isMobile) this.addMoveEvents()

        this.$bus.$on('setToolsStyle', () => setTimeout(this.setToolsStyle, 0));

        this.setToolsStyle()

        const content = document.querySelector('.tools__content');
        const RO = new ResizeObserver(() => {
            this.$store.commit('tools/setContentWidth', content.offsetWidth);
        })

        RO.observe(content);
    },
    beforeDestroy() {
        this.$bus.$off('setToolsStyle');
    },
    computed: {
        ...mapState('tools', ['activeTool', 'showTools', 'toolContentWidth', 'askAI']),
        ...mapState(['isMobile']),
        ...mapGetters('tools', ['isAskAIModalOpen', 'isAskAIResultsModalOpen']),
        window() {
            return {
                width: this.$store.state.windowWidth,
                height: this.$store.state.windowHeight
            }
        },
        isSafariBrowser() {
            return checkSpecificBrowsers(['safari']);
        }
    },
    methods: {
        addMoveEvents() {
            this.toolsNav.addEventListener('mousedown', this.mouseDownHandle)
        },
        mouseDownHandle() {
            document.body.classList.add('user-select-none')

            document.addEventListener('mousemove', this.moveTools)
            document.addEventListener('mouseup', this.stopMoveTools)
        },
        moveTools(e) {
            const width = Math.max(...[360, parseInt(this.tools.style.width)])
            const height = Math.max(...[400, parseInt(this.tools.style.height)])

            this.tools.style.left = parseInt(this.tools.style.left) + e.movementX + 'px'
            this.tools.style.top = parseInt(this.tools.style.top) + e.movementY + 'px'

            if(parseInt(this.tools.style.left) <= 0) this.tools.style.left = 0
            if(parseInt(this.tools.style.left) + width >= document.documentElement.clientWidth) {
                this.tools.style.left = document.documentElement.clientWidth - width + 'px'
            }
            
            if(parseInt(this.tools.style.top) <= 0) this.tools.style.top = 0
            if(parseInt(this.tools.style.top) + height >= document.documentElement.clientHeight) {
                this.tools.style.top = document.documentElement.clientHeight - height + 'px'
            }

            clearTimeout(this.moveToolsTimeout)
            this.moveToolsTimeout = setTimeout(() => {
                this.$gtag({
                    category: 'tool',
                    action: 'move',
                    label: this.activeTool
                })
            }, 1000)
        },
        stopMoveTools() {
            document.body.classList.remove('user-select-none')

            document.removeEventListener('mousemove', this.moveTools)
            document.removeEventListener('mouseup', this.stopMoveTools)
        },
        closeTools(directClose) {
            if(directClose) {
                this.$gtag({
                    category: 'tool',
                    action: 'close',
                    label: this.activeTool
                })
            }
            
            this.$store.commit('tools/closeTools')
        },
        openToolsPage() {
            const tool = this.activeTool

            document.querySelector('grammarly-editor-plugin')?.disconnect()
            this.closeTools()
            this.$store.commit('tools/setContextUrl', this.$router.currentRoute.path)

            this.$router.push(this.toolsRedirect(tool))

            this.$gtag({
                category: 'tool',
                action: 'maximize',
                label: tool
            })
        },
        setToolsStyle() {
            const { left, width } = document.querySelector('.tools-buttons').getBoundingClientRect()

            if (this.isMobile) {
                this.$refs.tools.style.width = '100%'
                this.$refs.tools.style.height = `calc(${getComputedStyle(document.querySelector(':root')).getPropertyValue('--vh')} - 64px)`
                this.$refs.tools.style.top = '65px'
                this.$refs.tools.style.left = '0px'
                return;
            } 
            this.$refs.tools.style.width = '735px'
            this.$refs.tools.style.height = '400px'
            this.$refs.tools.style.top = document.documentElement.clientHeight - 68 - 400 + 'px'
            this.$refs.tools.style.left = left + width + 16 + 'px'
        },
        resetAIActivity() {
            this.$store.dispatch('tools/restartTeacherAIGame');
        },
        endAIActivity() {
            this.$store.commit('tools/leaveTeacherAIGame');
        }
    },
    watch: {
        window: {
            deep: true,
            handler() {
                setTimeout(() => {
                    this.setToolsStyle()
                }, 10);
            }
        },
        isMobile(n) {
            if (n) {
                this.stopMoveTools();
                this.toolsNav.removeEventListener('mousedown', this.mouseDownHandle, false)
            } else this.addMoveEvents();
        },
        $route() {
            if (this.isMobile) {
                this.closeTools();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tools {
    z-index: 998;
    position: fixed;
    min-height: 400px;
    background: $white;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    container: tools / inline-size;

    &--grammar {
        container: unset;
    }

    @media(min-width: $mobile-tools) {
        resize: both;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
        min-width: 360px;

        &--no-resize {
            resize: none;
        }
    }

    @include toolsNavColors;

    &--safari {
        padding-bottom: 10px;
    }

    &--grammar-safari-overflow {
        overflow: visible;
        padding-bottom: 0px;

        .tools__nav {
            @media(min-width: $mobile-tools) {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 8px 16px;

        @media(min-width: $mobile-tools) {
            cursor: grab;
        }
    }

    &__nav-info, &__nav-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__nav-actions {
        gap: 16px;

        img {
            cursor: pointer;
        }
    }

    &__nav-title {
        @include font-h4;
        color: $gray-900;

        &--askAI {
            color: $orange-dark;
        }
    }

    &__content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
}
</style>