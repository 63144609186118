const store = {
    state: {
        devices: {
            audioinput: [],
            audiooutput: []
        },
        selectedDevices: {
            audioinput: null,
            audiooutput: null
        },
        audioVolume: {
            input: 0,
            output: 0
        },
        isAudiooutputSupported: false
    },
    actions: {
        checkDevices: async context => {
            const devices = await navigator.mediaDevices.enumerateDevices()
            context.state.isAudiooutputSupported = devices.some(device => device.kind === 'audiooutput')

            context.state.devices.audioinput = devices.filter(el => el.kind === 'audioinput')
            context.state.devices.audiooutput = devices.filter(el => el.kind === 'audiooutput')
        },
        setSpeaker: async (context, audio) => {
            if(context.rootState.isMobileDevice || !context.state.isAudiooutputSupported) return

            await audio.setSinkId(context.state.selectedDevices.audiooutput || 'default')
        }
    },
    mutations: {
        resetSelectedDevices: state => {
            state.selectedDevices = {
                audioinput: null,
                audiooutput: null
            }
        }
    },
    getters: {
        
    }
}

export default store