var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',{staticClass:"accordion skeleton"},[_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"card-container skeleton"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"number"},[_c('div',{staticClass:"small dark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"small dark"})]),_c('div',{staticClass:"date-wrapper"},[_c('div',{staticClass:"date"},[_c('div',{staticClass:"small dark"})]),_c('div',{staticClass:"status"},[_c('div',{staticClass:"small dark"})])]),_c('div',{staticClass:"progress-wrapper"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"small dark"})]),_c('div',{staticClass:"status-bar"},[_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-wrapper"},[_c('div',{staticClass:"status"},[_c('div',{staticClass:"small dark"})]),_c('div',{staticClass:"lesson-number"},[_c('div',{staticClass:"small dark"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topic"},[_c('div',{staticClass:"small dark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date"},[_c('div',{staticClass:"small dark"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"}),_c('div',{staticClass:"small dark"})])
}]

export { render, staticRenderFns }