<template>
    <span class="tooltip" v-html="text"></span>
</template>

<script>
export default {
    name: 'Tooltip',
    props: ['text']
}
</script>
