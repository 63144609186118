<template>
    <div class="flashcards__progress-wrapper">
        <div class="flashcards__progress">
            <div class="bar green" :style="{ width: `${ progressBarStyle }%` }"></div>
            <div v-if="progressBarStyle !== 100" class="bar gray" :style="{ width: `${ 100 - progressBarStyle }%` }"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'FlashcardsProgress',
    props: {
        current: Number,
        max: Number
    },
    computed: {
        ...mapGetters('flashcards', ['getCurrentProgress', 'getAllExercisesLength']),
        progressBarStyle() {
            return this.getCurrentProgress / this.getAllExercisesLength * 100
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__progress {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 2px;

    :first-child {
        border-radius: 99px 0px 0px 99px;
    }

    :last-child {
        border-radius: 0px 99px 99px 0px;
    }

    :only-child {
        border-radius: 99px;
    }

    .bar {
        height: 8px;

        &.green {
            background: #3ECE4F;
        }
    
        &.gray {
            background: $gray-200;
        }
    }
}
</style>