<template>
    <div class="header-container__mobile" v-if="isMobile">
        <div class="header">
            <img src="@/assets/img/lesson.svg" alt="Icon">
            <div class="labels">
                <div class="label">
                    <img src="@/assets/img/calendar.svg" alt="Icon">
                    <span>{{ $moment(lesson.startAt).format(timezoneDate('D MMMM (ddd)')) }}</span>
                </div>
                <div class="label">
                    <img src="@/assets/img/clock.svg" alt="Icon">
                    <span>{{ $moment(lesson.startAt).format(timezoneDate('HH:mm')) }} - {{ $moment(lesson.stopAt).format(timezoneDate('HH:mm')) }}</span>
                </div>
                <Pill :status="getLessonStatus" :text="$t(`lessons.status.${ getLessonStatus }`)" />
            </div>
        </div>
        <span class="dsc dsc--mobile">{{ lesson.languageLevel ? lesson.languageLevel.shortName : '' }}{{ lesson.number && lesson.languageLevel ? ` - ${ lesson.number }` : '' }}{{ lesson.languageLevel || lesson.nubmer ? ': ' : '' }}{{ lesson.lessonSubjects.length ? `${ lesson.lessonSubjects[0].subject }` : '' }}</span>
        <div class="lector-wrapper">
            <img class="avatar-style" :src="setAvatar(lesson.user.avatarUrl)" width="24" height="24" :alt="lesson.user.firstName"> <span class="name">{{lesson.user.firstName}}</span> ({{$i18n.t('single-lesson.lector')}})
            <span v-if="isTeacherSubstituted" class="info-tooltip"><img src="@/assets/img/info.svg" alt="">
                <Tooltip :text="`${ $t('lesson.replacement-info') }`"/>
            </span>
        </div>
    </div>
    <div v-else>
        <div class="header">
            <img src="@/assets/img/lesson-big.svg" alt="Icon">
            <div class="dsc">
                <span class="text">{{ lesson.languageLevel ? lesson.languageLevel.shortName : '' }}{{ lesson.number && lesson.languageLevel ? ` - ${ lesson.number }` : '' }}{{ lesson.languageLevel || lesson.nubmer ? ': ' : '' }}{{ lesson.lessonSubjects.length ? `${ lesson.lessonSubjects[0].subject }` : '' }}</span>
                <Pill :status="getLessonStatus" :text="$t(`lessons.status.${ getLessonStatus }`)" />
                <div class="labels">
                    <div class="label">
                        <img src="@/assets/img/singleLesson/calendar.svg" alt="Icon">
                        <span>{{ $moment(lesson.startAt).format(timezoneDate('D MMMM (ddd)')) }}</span>
                    </div> 
                    <div class="label">
                        <img src="@/assets/img/singleLesson/clock.svg" alt="Icon">
                        <span>{{ $moment(lesson.startAt).format(timezoneDate('HH:mm')) }} - {{ $moment(lesson.stopAt).format(timezoneDate('HH:mm')) }}</span>
                    </div> 
                    <div class="label">
                        <span>{{ $i18n.t(`single-lesson.type.${ lesson.type }`) }}</span>
                    </div> 
                    <div class="lector-wrapper">
                        <img class="avatar-style" :src="setAvatar(lesson.user.avatarUrl)" width="24" height="24" :alt="lesson.user.firstName"> <span class="name">{{lesson.user.firstName}}</span> ({{$i18n.t('single-lesson.lector')}})
                        <span v-if="isTeacherSubstituted" class="info-tooltip"><img src="@/assets/img/info.svg" alt="">
                            <Tooltip :text="`${ $t('lesson.replacement-info') }`"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import timezoneDate from '@/functions/timezoneDate'
import setAvatarMixin from '@/mixins/setAvatar'
import { mapState } from 'vuex'
import Tooltip from '@/components/reusable/Tooltip'
import Pill from '@/components/reusable/Pill'

export default {
    name: 'Header',
    mixins: [setAvatarMixin],
    components: { Tooltip, Pill },
    props: {
        lesson: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timezoneDate,
        }
    },
    computed: {
        ...mapState(['isMobile']),
        joinLessonUrl() {
            return this.lesson.studentLesson.joinLessonUrl
        },
        getLessonStatus() {
            return this.joinLessonUrl ? 'in-progress' : this.lesson.status;
        },
        isTeacherSubstituted() {
            return ['in-progress', 'pending', 'scheduled'].includes(this.getLessonStatus) && this.lesson.isTeacherSubstituted
        }
    }
}
</script>