<template>
    <div :class="[
        'dictionary__search-bar',
        {
            'dictionary__search-bar--active': searchActive,
            'dictionary__search-bar--tool-mode': !showTools && !contextUrl && !isMobile,
            'dictionary__search-bar--tool-mode-extended': !showTools && contextUrl && !isMobile
        }
    ]">
        <div class="dictionary__search-wrapper">
            <LangDropdown 
                type="source_lang" 
                :langs="dictionary.supportedLangs" 
                :chosenLang="dictionary.chosenLang"
                @setLang="setLang"
                :shorter="isMobile"
            />
            <input
                @input="searchInputHandle"
                :value="dictionary.word"
                class="search-input"
                type="text"
                :placeholder="isMobile ? $t('tools.dictionary.placeholder.mobile') : $t('tools.dictionary.placeholder')"
                @keyup.enter="dictionary.word && $store.dispatch('tools/searchDictionaryWord')"
                ref="searchInput"
                @focus="focusSearchInput"
                @blur="searchActive = false"
            >
        </div>
        <Button
            class="dictionary__search-btn btn primary"
            :text="$i18n.t('tools.dictionary.search')"
            @click.native="searchClicked"
            :disabled="!dictionary.word || dictionary.word === dictionary.data?.[0]?.word"
            type="button"
            :loading="dictionary.loading"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button';
import LangDropdown from '@/components/tools/LangDropdown';
import { mapState } from 'vuex';

export default {
    name: 'DictionarySearch',
    components: { Button, LangDropdown },
    data() {
        return { searchActive: false }
    },
    created() {
        this.$bus.$on('focusSearchDictionaryInput', this.focusSearchInput)
    },
    beforeDestroy() {
        this.$bus.$off('focusSearchDictionaryInput');
    },
    mounted() {
        const isMobileAndWord = this.isMobileDevice && this.dictionary.word
        if(!isMobileAndWord) this.focusSearchInput();
    },
    computed: {
        ...mapState(['isMobileDevice', 'isMobile']),
        ...mapState('tools', ['dictionary', 'showTools', 'contextUrl'])
    },
    methods: {
        focusSearchInput() {
            this.searchActive = true;
            this.$refs.searchInput.focus();
            this.$refs.searchInput.select();
        },
        searchClicked() {
            this.$refs.searchInput.blur();
            this.$store.dispatch('tools/searchDictionaryWord')
        },
        searchInputHandle(event) {
            this.dictionary.word = event.target.value
        },
        setLang(lang) {
            if (lang !== this.dictionary.chosenLang) {
                this.dictionary.chosenLang = lang;
                this.dictionary.cachedData = [];
                this.$store.dispatch('tools/searchDictionaryWord');
                this.$store.commit('tools/setEnDialect', { fromTool : 'dictionary', toLang: lang });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dictionary {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: hidden;

    &__search-bar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        padding: 0px 16px;
        width: 100%;
        top: 40px;
        position: absolute;
        border: 1px solid $gray-200;
        background: $white;
        z-index: 1;

        &--active {
            border-color: $primary;
        }

        &--tool-mode {
            top: 48px;
        }

        &--tool-mode-extended {
            top: 56px;
        }
    }

    &__search-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;

        .search-input {
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            border: 0px;
            outline: 0px;
            width: 100%;

            &::placeholder {
                color: $gray-600;
            }
        }
    }

    &__search-btn {
        height: 32px;
    }
}

</style>