import gql from 'graphql-tag'

export const startTeacherAIGame = gql`
    mutation($input: StartTeacherAIGameInput!) {
        startTeacherAIGame(input: $input) {
            uuid
            name
            gameType {
                uuid
                type
                prompt
            }
            state {
                score {
                    current
                    max
                }
                status
            }
            messages {
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    cursor
                    node {
                        uuid
                        author
                        content
                        createdAt
                    }
                }
            }
        }
    }
`