import gql from 'graphql-tag'

export const availableTeachersSpecialities = gql`
    {
        availableTeachersSpecialities {
            general {
                key
                name
            }
            business {
                key
                name
            }
            exam {
                key
                name
            }
            special {
                key
                name
            }
        }
    }
`