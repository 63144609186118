<template>
    <div :class="['numbered-list', { [`numbered-list--${ size }`] : size }]" >
        <RoundedNumber :size="size" :character="character"/>
        <div class="numbered-list__text-wrapper">
            <span class="text" v-html="text"></span>
            <div v-if="description" :class="['description', { 'description--border' : borderBottom, 'description--reverse': description.iconPosition === 'right' }]">
                <img class="icon" v-if="description.icon" :src="description.icon">
                {{ description.text }}
            </div>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import RoundedNumber from '@/components/reusable/RoundedNumber'

export default {
    name: 'NumberedList',
    components: { RoundedNumber },
    props: {
        character: {
            required: false
        },
        size: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        description: {
            type: Object,
            required: false
        },
        borderBottom: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.numbered-list {
    display: flex;
    gap: 24px;

    &--large {
        .numbered-list__text-wrapper {
            .text {
                @include font-lead;
            }

            .description {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    &--small {
        gap: 16px;

        .numbered-list__text-wrapper {
            .text {
                @include font-regular;
            }

            .description {
                @include font-small-10;
                font-weight: 400;
            }
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .text {
            @include font-h3;
            font-weight: 400;
        }

        .description {
            display: flex;
            gap: 8px;
            @include font-regular;
            color: $gray-600;
            align-items: flex-start;

            &--reverse {
                flex-direction: row-reverse;
            }

            &--border {
                padding-bottom: 24px;
                border-bottom: 1px solid $gray-200;
            }
        }

        .icon {
            margin-top: 4px;
        }
    }

    &:last-of-type {
        padding-bottom: 10px;
    }
}
</style>
