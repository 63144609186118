<template>
    <span 
        :class="{
            'grammar__match': data.match,
            'grammar__match--hide': grammar.hideMatches,
            [`grammar__match--${ getCategoryClass }`]: getCategoryClass
        }"
        @mouseenter="onMouseEnter(data)"
        @mouseleave="onMouseLeave(data)"
    >{{ data.text }}<GrammarReplacements v-if="isMatchActive" :data="data.match" /></span>
</template>

<script>
import GrammarReplacements from '@/components/tools/grammar/GrammarReplacements'
import { mapState } from 'vuex'

export default {
    name: 'GrammarMatch',
    props: {
        data: Object
    },
    components: {
        GrammarReplacements
    },
    computed: {
        ...mapState('tools', ['grammar']),
        isMatchActive() {
            return this.grammar.activeMatch === this.data.match?.uuid
        },
        getCategoryClass() {
            const { match } = this.data

            if(!match) return

            const { id } = match.rule.category
            const orange = ['CASING', 'CONFUSED_WORDS', 'PUNCTUATION', 'GRAMMAR', 'MISC', 'SEMANTICS']
            const primary = ['REDUNDANCY']

            if(orange.includes(id)) return 'orange'
            if(primary.includes(id)) return 'primary'

            return 'danger'
        }
    },
    methods: {
        onMouseEnter(data) {
            if(data.match && !this.grammar.hideMatches) this.$store.commit('tools/grammarSetActiveMatch', data.match.uuid)
        },
        onMouseLeave(data) {
            if(data.match && !this.grammar.hideMatches) this.$store.commit('tools/grammarResetActiveMatch')
        }
    }
}
</script>

<style lang="scss" scoped>
.grammar__match {
    position: relative;
    border-bottom: 2px solid transparent;
    transition: all .25s;

    &--danger {
        border-color: $danger;

        &:hover {
            background: $danger-light;
        }
    }

    &--primary {
        border-color: $primary;

        &:hover {
            background: $primary-light;
        }
    }

    &--orange {
        border-color: $orange;

        &:hover {
            background: $orange-light;
        }
    }

    &--hide {
        background: transparent !important;
        border-color: transparent;
    }
}
</style>