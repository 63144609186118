<template>
    <div :class="['learning__content', { 'learning__content--flashcards': isFlashcardsActivity }]">
        <LearningExercise />
        <!-- <StepProgress/> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LearningExercise from '@/components/learning/LearningExercise'
// import StepProgress from '@/components/learning/views/StepProgress'

export default {
    name: 'LearningContent',
    components: {
        LearningExercise
        // StepProgress,
        // LearningSummary
    },
    computed: {
        ...mapGetters('learning', ['isFlashcardsActivity'])
    }
}
</script>

<style lang="scss" scoped>
.learning__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 32px 20px;
    width: 732px;
    max-width: 100%;
    margin: 0 auto auto;
    overflow: hidden;

    &--flashcards {
        padding: 20px 0 0 0 !important;
    }

    @media(min-width: $mobile-learning) {
        padding: 32px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
    }

    ::v-deep {
        .dnd, .words, .input {
            bottom: 0px !important;
        }
    }
}
</style>