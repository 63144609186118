<template>
    <div class="register">
        <img v-if="!isEmailView" class="main-logo" src="@/assets/img/logo-full.svg" alt="Fluentbe">
        <div class="register-form">
            <component :is="isEmailView ? 'span' : 'h1'" class="title">
                {{ getTitle }}
            </component>
            <div v-if="isEmailView" class="email-form">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(submitEmail)" novalidate>
                        <ValidationProvider v-for="{ name, rules, component, data } in form" :key="name" :rules="name == 'password2' ? `${ rules }|check:${emailFormData['password1']},repeatPassword` : rules" v-slot="{ classes, errors }">
                            <component :is="component" v-model="emailFormData[name]" :class="classes" :data="data"/>
                            <p class="invalid-feedback">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <Button class="primary" :loading="loading" :text="$t('register.create-account')"/>
                    </form>
                </ValidationObserver>
            </div>
            <template v-else>
                <div
                    :class="['buttons google-login-btn-wrapper', { 'buttons--disabled': !areRequirementsChecked }]" 
                    @click="!areRequirementsChecked && handleDisabledClick()"
                >
                    <ThirdPartyLoginButtons :areRequirementsChecked="areRequirementsChecked" @handleRegister="register" register/>
                    <Button @click.native="setEmailView(true)" :icon="require('@/assets/img/social/envelope-white.svg')" class="primary b2 b2--login" :text="$t('register.use-email')" />
                </div>
                <div class="requirements-section">
                    <Checkbox
                        :data="{
                            label: $t('register.terms', { url: termsUrl }),
                            danger: disabledClick && !requirements.terms
                        }"
                        v-model="requirements.terms"
                    />
                    <Checkbox
                        :data="{
                            label: $t('register.privacy', { url: privacyUrl }),
                            danger: disabledClick && !requirements.privacy
                        }" 
                        v-model="requirements.privacy"
                    />
                </div>
            </template>
        </div>
        <div class="nav-bottom">
            <Button
                v-if="isEmailView"
                class="btn default"
                @click.native="setEmailView(false)"
                :text="$t('register.back-btn')"
            />
            <router-link v-else :to="{name: 'login'}" class="btn default">
                {{ $t('register.back-btn') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { locales } from '@/i18n/i18n'
import { schoolV2 } from '@/plugins/axios';
import { metaInfo } from '@/helpers/metaInfo';
import Button from '@/components/reusable/Button'
import ThirdPartyLoginButtons from '@/components/ThirdPartyLoginButtons'
import { register as form } from '@/forms'
import brandAppUrls from '@/mixins/brandAppUrls'
import Checkbox from '@/components/reusable/Checkbox'

export default {
    name: 'Register',
    metaInfo() {
        return metaInfo('register', { 
            locales, 
            route: this.$route, 
            i18n: this.$i18n 
        })
    },
    components: {
        Button,
        ThirdPartyLoginButtons,
        Checkbox
    },
    mixins: [brandAppUrls],
    data() {
        return {
            loading: false,
            form,
            emailFormData: {},
            requirements: {},
            disabledClick: false,
            isEmailView: false
        }
    },
    computed: {
        ...mapGetters(['getFacebookLoginScope']),
        areRequirementsChecked() {
            const { terms, privacy } = this.requirements

            return terms && privacy
        },
        getTitle() {
            return this.isEmailView
                ? this.$i18n.t('register.registrationData.nested.title')
                : this.$i18n.t('register.registrationData.title')
        }
    },
    methods: {
        handleDisabledClick() {
            this.disabledClick = true
            this.$emit('customInput', {
                name: 'disabledClick',
                value: true
            })
        },
        async register(registrationData, studentData) {
            this.loading = true
            const searchParams = new URL(location.href).searchParams
            const utm = JSON.parse(searchParams.get('utm')) || []

            try {
                const res = await schoolV2.post('register/student', {
                    studentData: {
                        locale: this.$route.params.locale,
                        ...studentData
                    },
                    registrationData,
                    utm
                });
               
                localStorage.setItem('authToken', res.data.token);
                this.$router.push({ name: 'setAccount' });
                if (registrationData.type === 'facebook') this.getFacebookLoginScope?.logout();
            } catch(err) {
                const code = err.response.data?.code
                const takenEmail = code === 'ERR_ALREADY_TAKEN_EMAIL'
                const emptyEmail = code === 'ERR_EMPTY_EMAIL'

                const text = takenEmail && 'notify.taken-email'
                || emptyEmail && 'notify.empty-email'
                || 'notify.error.subtitle'

                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t(text),
                    data: {
                        type: 'error'
                    }
                })
            } finally {
                this.loading = false
            }
        },
        setEmailView(status) {
            this.isEmailView = status
        },
        submitEmail() {
            this.register({
                type: 'email',
                email: this.emailFormData.email,
                password: this.emailFormData.password1
            })
        }
    }
}
</script>
<style scoped lang="scss">
.register {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 68px;
    padding: 64px 32px 96px 32px;

    @media (min-width: 991px) {
        padding: 124px 32px;
    }
}

.nav-bottom {
    background: $white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $gray-200;
    z-index: 999;
    padding: 20px;

    a.btn.default {
        display: inline-flex;
    }
}

.register-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .title {
        @include font-h2;
        text-align: center;
        margin-bottom: 32px;
    }

    .email-form {
        width: 464px;
        max-width: 100%;
        margin: auto;

        .btn {
            width: 100%;
        }
    }

    .buttons {
        width: 376px;
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 32px;
        transition: opacity .25s;

        &--disabled {
            * {
                pointer-events: none;
            }
        }
    }

    .requirements-section {
        width: 376px;
        max-width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .checkbox {
            display: flex;
            @include font-regular;
            line-height: 20px;
            text-align: left;
            padding-left: 30px;
            transition: all .25s;
        }
    }
}
</style>