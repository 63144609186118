function getFirstDateFromVisibleCalendarPage(calendar) {
    return calendar.querySelector('.schedule-calendar__hour').dataset.slotDate;
}

const scrollToDate = {
    methods: {
        isEarlier(data1, data2) {
            const format = 'YYYY-MM-DD HH:mm:ss';
            const date1 = this.$moment(data1, format);
            const date2 = this.$moment(data2, format);
        
            return date1.isBefore(date2);
        },
        async scrollToDate(date = null) {
            if (this.isDateFilterScreen) return;

            const { schaduleCalendarWrapper } = this.$refs;
            if (!schaduleCalendarWrapper) return;
            
            const arrowRightLink = schaduleCalendarWrapper.querySelector('.schedule-arrow--right .schedule-arrow__link');
            const arrowLeftLink = schaduleCalendarWrapper.querySelector('.schedule-arrow--left .schedule-arrow__link');
            
            const arrowToClick = this.isEarlier(date, getFirstDateFromVisibleCalendarPage(schaduleCalendarWrapper)) || !date
                ? arrowLeftLink : arrowRightLink;

            let attempts = 0;

            while (attempts < 10) {
                if (schaduleCalendarWrapper.querySelector(`.schedule-calendar__hour[data-slot-date="${ date }"]`)) {
                    break;
                } else {
                    await new Promise(resolve => setTimeout(() => {
                        arrowToClick.click();
                        resolve();
                    }, 100));

                    if (arrowToClick.classList.contains('disabled')) break;
                    attempts++;
                }
            }
        },
    }
    
}

export default scrollToDate;