<template>
    <div v-if="!loading" :class="['lessons', { 'lessons--empty': noLessons }]">
        <List :border="border" @noLessons="value => noLessons = value"/>
        <div class="lessons__sidebar">
            <Calendar/>
            <AddCalendar/>
        </div>
    </div>
</template>

<script>
import List from '@/components/lessons/List'
import Calendar from '@/components/lessons/Calendar'
import AddCalendar from '@/components/lessons/AddCalendar'
import { borderLessonOfUser } from '@/graphql/queries/borderLessonOfUser'
import api from '@/graphql/api.js'

export default {
    name: 'Lessons',
    components: {
        List,
        Calendar,
        AddCalendar
    },
    data() {
        return {
            loading: true,
            border: {},
            noLessons: false
        }
    },
    async created() {
        await Promise.all([
            this.getBorder('first'), this.getBorder('last')
        ])

        this.checkDate(this.$route.params.year, this.$route.params.month)
        
        this.loading = false
    },
    methods: {
        checkDate(year, month) {
            if(!this.$moment(`${year}-${month}`, 'YYYY-MM', true).isValid()) this.$router.replace({name: 'lessons', params: {year: this.$moment().format('YYYY'), month: this.$moment().format('MM')}})
        },
        async getBorder(order) {
            const {res} = await api(borderLessonOfUser, {
                userUuid: this.$store.getters.userUuid,
                order
            })
            this.border[order] = res.borderLessonOfUser?.startAt
        }
    }
}
</script>
