<template>
    <div :class="['multi-step', { 'multi-step--completed': isCompleted }]">
        <div :key="currentStepIndex" class="multi-step__content">
            <p v-if="title" class="multi-step__title">{{ $i18n.t(`${ title }.${ currentStep.name }.${ nestedPart ? 'nested.' : '' }title`) }}</p>
            <component
                v-if="currentStep"
                :is="currentStep.component"
                :data="currentStep.data"
                :loading="loading"
                v-model="formData[currentStep.name]"
                :customData="customData"
                :isCompleted="isCompleted"
                @finish="finish"
                @setNestedPart="value => nestedPart = value"
                @customInput="data => customData[data.name] = data.value"
            />
        </div>
        <div class="multi-step__nav-bottom">
            <div v-if="!hideProgress" class="multi-step__progress" :style="progressStyle"></div>
            <div class="wrapper wrapper--big">
                <div class="multi-step__buttons">
                    <Button
                        v-if="buttons.close?.steps === 'all' || buttons.close?.steps.includes(currentStepIndex)"
                        class="default  multi-step__close"
                        @click.native="buttons.close.action" 
                        :text="$i18n.t(buttons.close.text)"
                    />
                    <Button
                        v-if="!disableBackButton"
                        class="default multi-step__back"
                        :disabled="nextStepLoading" 
                        @click.native="changeStep(false)" 
                        :text="buttons.back?.texts?.[currentStepIndex] || $i18n.t('register.footer.back')"
                    />
                    <Button
                        v-if="buttons.custom?.steps.includes(currentStepIndex)"
                        class="default"
                        @click.native="buttons.custom.action" 
                        :text="$i18n.t(buttons.custom.text)"
                        :icon="buttons.custom.icon"
                    />
                    <Button
                        v-if="buttons.finish?.steps.includes(currentStepIndex)"
                        class="primary"
                        @click.native="buttons.finish.action" 
                        :text="$i18n.t(buttons.finish.text)"
                    />
                    <Button
                        v-if="!isLastStep || (isLastStep && showNextButtonOnLastStep)"
                        class="primary multi-step__next"
                        :disabled="disableNextButton"
                        :loading="nextStepLoading"
                        @click.native="checkValidation" 
                        :text="buttons.next?.texts?.[currentStepIndex] || $i18n.t('register.footer.next')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'

export default {
    name: 'MultiStep',
    props: {
        steps: Array,
        loading: Boolean,
        title: String,
        buttons: {
            type: Object,
            default: () => ({})
        },
        hideProgress: Boolean,
        showNextButtonOnLastStep: Boolean,
        data: Object,
        isCompleted: Boolean
    },
    components: {
        Button
    },
    data() {
        return {
            currentStepIndex: 1,
            formData: {
                AUDIO_SETUP: true,
            },
            customData: this.data || {},
            nextStepLoading: false,
            nestedPart: false
        }
    },
    created() {
        this.$bus.$on('input', data => {
            this.formData.studentData = { ...this.formData.studentData, locale: this.$route.params.locale };
            this.formData.registrationData = data;
        })

        this.$bus.$on('finish', callback => this.finish(callback))
        this.$bus.$on('multiStepSubmit', this.checkValidation)
    },
    beforeDestroy() {
        this.$bus.$off('input')
        this.$bus.$off('finish')
        this.$bus.$off('multiStepSubmit')
    },
    computed: {
        stepsCount() {
            return this.steps.length
        },
        currentStep() {
            return this.steps[this.currentStepIndex - 1]
        },
        isLastStep() {
            return this.currentStepIndex === this.stepsCount
        },
        progressStyle() {
            return {
                width: `${ this.currentStepIndex / this.stepsCount * 100 }%`
            }
        },
        disableBackButton() {
            if(this.nestedPart) return

            return this.currentStepIndex === 1 || this.buttons.back?.disabledSteps.includes(this.currentStepIndex)
        },
        disableNextButton() {
            if(!this.currentStep || this.isCompleted) return

            const { required, name, data, type } = this.currentStep
            const value = this.formData[name]

            if(Array.isArray(value)) {
                return required && !value.length
            }

            if(type === 'ORAL') {
                return required && !value?.base64
            }
            
            if(typeof value === 'object') {
                return required && Object.keys(value).length !== data.questions.length
            }

            return required && !value
        }
    },
    methods: {
        changeStep(next) {
            if(this.nestedPart) this.$bus.$emit('multiStep/setNestedPart', false)
            else next ? this.currentStepIndex++ : this.currentStepIndex--

            this.$emit('setStep', this.currentStepIndex)
            window.scrollTo(0, 0)
        },
        setStep(value) {
            this.currentStepIndex = value
            this.$emit('setStep', value)
            window.scrollTo(0, 0)
        },
        finish(callback) {
            this.$emit('finish', {
                formData: this.formData,
                callback
            })
        },
        async checkValidation() {
            const { validation, name, isLastOralStep } = this.currentStep

            if(validation) {
                this.nextStepLoading = true

                const res = await validation({
                    stepName: name,
                    stepData: this.formData[name],
                    isLastOralStep
                })
                
                this.nextStepLoading = false

                if(res?.silenceError) return

                if(res?.error) {
                    this.$notify({
                        title: this.$i18n.t('notify.error.title'),
                        text: this.$i18n.t('notify.error.subtitle'),
                        data: {
                            type: 'error'
                        }
                    })

                    return
                }
            }

            if(!this.isLastStep) this.changeStep(true)
        },
        updateFormData(data) {
            const formData = { ...this.formData }
            
            this.formData = Object.assign(formData, data)
        }
    }
}
</script>

<style lang="scss" scoped>
.multi-step {
    display: flex;
    flex-direction: column;
    min-height: var(--vh);
    padding: 104px 16px 104px;

    &--completed {
        .multi-step__back {
            margin-left: auto;
            margin-right: unset;
        }
    }

    @media(min-width: 768px) {
        padding: 128px 32px 128px; 

        &--top-gap {
            padding-top: 144px;
        }
    }

    &__content {
        width: 608px;
        max-width: 100%;
        margin: auto;
        text-align: center;
        flex: 1;
    }

    &__title {
        @include font-h2;
        color: $gray-900;
        margin-bottom: 40px;
    }

    @include stickyBottomStepsNav;
}
</style>