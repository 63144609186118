<template>
    <div
        :class="[
            'accordion',
            {
                'accordion--active' : active,
                'accordion--inactive' : !rotateArrows
            }
        ]"
    >
        <div
            @click="clickHeader"
            :class="['accordion__header', { 'accordion__header--disabled' : disabled }]"
        >
            <slot name="header"></slot>
            <span
                v-if="rotateArrows"
                class="accordion__arrow"
                :class="{ 'accordion__arrow--active': isExpanded && !disabled }"
                alt="Icon"
            ></span>
        </div>
        <div
            v-if="!disabled"
            :class="['accordion__content', { 'accordion__content--disabled' : disabled }]"
            ref="contentHeight"
            :style="contentStyle"
        >
            <slot name="content"></slot>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
import scrollToMaterialByUrlQueryParam from '@/mixins/scrollToMaterialByUrlQueryParam';

export default {
    name: 'Accordion',
    mixins: [scrollToMaterialByUrlQueryParam],
    props: {
        active: Boolean,
        rotateArrows: Boolean,
        disabled: Boolean,
        isExpanded: Boolean,
        coursePlanAccordion: Boolean
    },
    data() {
        return {
            allowExpand: true,
            contentHeight: 0
        }
    },
    computed: {
        contentStyle() {
            return { height: this.contentHeight }
        }
    },
    mounted() {
        if (this.isExpanded) this.hideOrExpand();
        if (this.coursePlanAccordion) this.scrollToMaterialByUrlQueryParam();
    },
    methods: {
        clickHeader() {
            this.$emit('clicked');
            this.hideOrExpand();
        },
        updateHeight() {
            this.contentHeight = `${ this.$refs.contentHeight?.scrollHeight }px`;
        },
        hideOrExpand() {
            if(!this.allowExpand) return

            const delay = 400;

            this.allowExpand = false

            this.updateHeight()

            setTimeout(() => {
                this.contentHeight = this.isExpanded ? 'auto' : 0
                this.allowExpand = true
            }, this.isExpanded ? delay : 0)
        }
    },
    watch: {
        isExpanded: {
            handler () {                    
                this.hideOrExpand()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.accordion {
    cursor: pointer;
    border-bottom: 1px solid $gray-100;
    overflow: hidden;
    transition: all 400ms;

    &:hover {
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
    }

    &--lessons-planned {
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }

    &--inactive {
        cursor: default;
    }

    &--active {
        background: rgba(230, 243, 255, 0.3);
        border-bottom: 1px solid $gray-200;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
    }

    &__header {
        display: flex;
        padding: 16px;

        &--disabled {
            opacity: 0.3;
            cursor: default;
            pointer-events: none;
        }
    }

    &__content {
        height: 0;
        padding: 0px 16px;
        overflow: hidden;
        transition: all 500ms;
        width: 100%;
        cursor: default;

        &--disabled {
            opacity: 0.3;
            cursor: default;
            pointer-events: none;
        }
    }

    &__arrow {
        display: flex;
        align-self: flex-start;
        margin-left: auto;
        transform: rotate(0deg);
        transition: all $arrow-transition-time;
        background-color: $gray-600;
        mask-image: url("~@/assets/img/arrow.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        width: 16px;
        height: 16px;

        &--active {
            transform: rotate(180deg);
            background-color: $primary;
        }
    }
}
</style>
