const findAndUpdateChatMessage = (messages, updatedMessage) => {
    const { uuid, platformMessageUuid, messagesFromPlatform, content, files, images, isDeleted } = updatedMessage
    let platformMessage

    const message = messages?.find(el => {
        if(el.messagesFromPlatform && el.uuid === uuid) {
            platformMessage = el.messagesFromPlatform.chatMessages.find(el2 => el2.uuid === platformMessageUuid)
        }

        return el.uuid === uuid
    })

    if(platformMessage) {
        const { content, files, images, isDeleted } = messagesFromPlatform.chatMessages.find(el3 => el3.uuid === platformMessage.uuid)

        return update(platformMessage, { content, files, images, isDeleted })
    }
    
    if(message) return update(message, { content, files, images, isDeleted })
}

const update = (message, payload) => {
    message = Object.assign(message, payload)
}

export default findAndUpdateChatMessage