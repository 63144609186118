<template>
    <div class="emojies">
        <div v-if="frequlentyUsed && frequlentyUsed.length && !search">
            <p class="sub-title">{{$i18n.t('messages.emojies.recently')}}</p>
            <div class="emoji-wrapper">
                <div v-for="emoji in frequlentyUsed" :key="emoji" @click="add(emoji)" class="emoji">{{emoji}}</div>
            </div>
        </div>
        <div v-for="(list, category) in filteredEmojies" :key="category">
            <p class="sub-title">{{category}}</p>
            <div class="emoji-wrapper" >
                <div v-for="{emoji} in list" :key="emoji" @click="add(emoji)" class="emoji">{{emoji}}</div>
            </div>
        </div>
        <p v-if="!Object.keys(filteredEmojies).length" class="sub-title">{{$i18n.t('messages.emojies.not-found')}}</p>
    </div>
</template>

<script>
import emojiesJSON from '@/assets/emojies'

export default {
    name: 'Emojies',
    props: ['search'],
    data() {
        return {
            emojies: {},
            categories: ['Smileys & Emotion', 'People & Body', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Objects', /* 'Symbols' */],
            frequlentyUsed: JSON.parse(localStorage.getItem('emojies'))
        }
    },
    created() {
        this.categories.forEach(category => {
            this.emojies[category] = emojiesJSON.filter(emoji => emoji.category === category)
        })
    },
    computed: {
        filteredEmojies() {
            if(this.search) {
                const filtered = {}

                for(const key in this.emojies) {
                    filtered[key] = this.emojies[key].filter(({description}) => description.includes(this.search))
                }

                for(const key in filtered) {
                    if(!filtered[key].length) delete filtered[key]
                }

                return filtered
            }

            return this.emojies   
        }
    },
    methods: {
        add(emoji) {
            this.$bus.$emit('emoji', emoji)

            //update localstorage
            const count = 9*3
            let emojies = JSON.parse(localStorage.getItem('emojies')) || []
            emojies = emojies.filter(item => item != emoji)
            emojies.unshift(emoji)
            if(emojies.length > count) emojies = emojies.slice(0, count)
            this.frequlentyUsed = emojies
            localStorage.setItem('emojies', JSON.stringify(emojies)) 
        }
    }
}
</script>

<style>

</style>