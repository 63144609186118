<template>
    <div class="initial-heigh-box">
        <MediaVideoFile
            :url="videoUrl"
            :videoUrl="videoUrl" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MediaVideoFile from '@/components/reusable/MediaVideoFile';

export default {
    name: 'Video',
    props: ['data'],
    components: {
        MediaVideoFile
    },
    computed: {
        ...mapGetters('learning', ['getCurrentMedia']),
        videoUrl() {
            return this.data;
        },

        posterUrl() {
            return this.data?.posterUrl;
        }
    }
};
</script>

<style scoped lang="scss">
.initial-heigh-box {
    height: 64.50vw;

    @media (min-width: $mobile-learning) {
        height: 420px;
    }
}
::v-deep .video__controls {
    position: initial;
}
</style>
