<template>
    <div class="tags">
        <span
            v-for="(tag,idx) in tags"
            :key="idx"
            @click="changeStep(idx)"
            :class="['tag', { 'active': activeIndex === idx }]"
        >
            {{ $te(`ask-ai.category.${ tag }`) ? $t(`ask-ai.category.${ tag }`) : snakeCaseToString(tag, true) }}
        </span>
    </div>
</template>

<script>
import snakeCaseToString from '@/functions/snakeCaseToString'

export default {
    name: 'Tags',
    props: {
        tags: {
            default: Array
        },
        activeTagName: {
            default: String
        }
    },
    data() {
        return {
            activeIndex: 0,
            snakeCaseToString
        }
    },
    mounted() {
        this.setActiveTagName();
    },
    methods: {
        changeStep(idx) {
            this.$emit('newTag', this.tags[idx]);
            this.activeIndex = idx;
        },
        setActiveTagName() {
            const activeTagIndex = this.tags.findIndex(tag => tag === this.activeTagName);

            if (activeTagIndex !== -1) this.activeIndex = activeTagIndex;
        }
    },
    watch: {
        activeTagName(newVal) {
            if(newVal) this.setActiveTagName();
        }
    }
}
</script>
<style lang="scss" scoped>
    .tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .tag {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            @include font-h6-uc;
            border-radius: 100px;
            background-color: $gray-100;
            color: $gray-900;
            cursor: pointer;

            &.active {
                background-color: $gray-900;
                color: $white;
            }
        }
    }
</style>
