import moment from 'moment-timezone';

export default {
    computed: {
        isStudentAdult() {
            if (!this.$store.state.user.student?.birthDate) return true; // If no date of birth, we treat it as an adult

            const birthDate = moment(this.$store.state.user.student?.birthDate, 'YYYY-MM-DD HH:mm:ss');
            const today = moment();
            const ageInYears = today.diff(birthDate, 'years');
            return ageInYears >= 18;
        }
    }
}