const deepl = require('deepl')
import api from '@/graphql/api.js'
import router from '@/router'
// import { checkDictionariesStatus } from '@/graphql/queries/checkDictionariesStatus'
import { getDictionaryWord } from '@/graphql/queries/getDictionaryWord'
import { teacherAIStart } from '@/graphql/queries/teacherAIStart'
import { teacherAIChatMessages } from '@/graphql/queries/teacherAIChatMessages'
import { teacherAIGame } from '@/graphql/queries/teacherAIGame'
import { startTeacherAIGame } from '@/graphql/mutations/startTeacherAIGame'
import { restartTeacherAIGame } from '@/graphql/mutations/restartTeacherAIGame'
import { deeplTranslation } from '@/graphql/queries/deeplTranslation'
import { schoolV2 } from '@/plugins/axios'
import accessStorage from '@/functions/accessStorage'
import { i18n } from '@/i18n/i18n'
import { v4 as uuidv4 } from 'uuid'
import removeMultipleNewLines from '@/functions/removeMultipleNewLines'

const toolsStore = {
    state: () => ({
        showTools: false,
        showToolsButtons: true,
        tools: [
            'askAI',
            'translator',
            'grammar'
        ],
        toolContentWidth: 0,
        activeTool: '',
        moreTools: false,
        contextUrl: null,
        translator: {
            sourceText: '',
            targetText: '',
            source_lang: '',
            target_lang: '',
            sourceLangs: ['cz', 'de', 'fr', 'en', 'es', 'hu', 'pl', 'pt', 'sk', 'ua'],
            targetLangs: ['cz', 'de', 'fr', 'en-gb', 'en-us', 'es', 'hu', 'pl', 'pt', 'sk', 'ua'],
            formalityLangs: ['de', 'fr', 'es', 'pl', 'pt'],
            formality: 'less',
            reverseLangs: false,
            duringTranslation: false,
            showSourceLangs: false,
            showTargetLangs: false,
            lastUsedEnglish: 'en-gb',
            charactersLimit: 1000
        },
        grammar: {
            loading: false,
            text: '',
            isSupported: null,
            active: false,
            matches: [],
            renderValue: 0,
            charactersLimit: 1500,
            hideMatches: false,
            disableCheck: false,
            showMistakes: false,
            activeMatch: null,
            scrollTop: 0
        },
        dictionary: {
            word: '',
            data: [],
            cachedData: [],
            showError: false,
            isPlaying: false,
            showLogo: true,
            loading: false,
            supportedLangs: ['en-gb', 'en-us'],
            chosenLang: 'en-gb',
            provider: ''
        },
        tooltip: {
            type: 'basic',
            word: '',
            buttons: [],
            wordId: false,
            mouseTargetDimensions: {},
            visible: false,
            keepShowingTooltip: false,
            extendedOptions: {},
            translated: [],
            isPlaying: false,
        },
        askAI: {
            ready: false,
            prompt: '',
            hints: [],
            messages: [],
            loading: false,
            isDisabled: false,
            showStartTooltip: true,
            game: null
        },
        askAIModal: {
            ready: false,
            open: false,
            activeTab: 'talk',
            talk: {
                prompts: [],
                filter: 'all',
                filterTags: [],
            },
            game: {
                prompts: [],
                filter: 'all',
                filterTags: [],
            },
        }
    }),
    actions: {
        async getTeacherAI({ state }) {
            const { res } = await api(teacherAIStart)
            const { canSendMessages, isFirstTime, chat } = res.teacherAI

            state.askAI.isDisabled = !canSendMessages
            state.askAI.showStartTooltip = isFirstTime
            state.askAI.hints = chat.suggestions
            state.askAI.messages = chat.messages.edges

            if(!res.teacherAIChatSuggestionsList.edges.length && !res.teacherAIGamesList.edges.length) state.askAIModal = null

            if(!state.askAI.messages.length) {
                this.commit('tools/addAskAIMessage', {
                    author: 'GPT',
                    content: i18n.t('widget.gpt-chat.question'),
                    createdAt: this._vm.$moment()
                })
            }

            state.askAI.ready = true
        },
        async getTeacherAIChatMessages({ state }) {
            if(!state.askAI.ready || state.askAI.game) return

            state.askAI.loading = true

            const { res, error } = await api(teacherAIChatMessages, {
                after: state.askAI.messages.filter(el => el.cursor).pop()?.cursor
            })

            if(error) return this.commit('tools/handleAskAIError')

            const { canSendMessages, chat } = res.teacherAI
            
            state.askAI.isDisabled = !canSendMessages
            state.askAI.messages = [...state.askAI.messages.filter(el => el.cursor || el.node.author === 'GPT'), ...chat.messages.edges]
            
            if(!state.askAI.messages.length) {
                this.commit('tools/addAskAIMessage', {
                    author: 'GPT',
                    content: i18n.t('widget.gpt-chat.question'),
                    createdAt: this._vm.$moment()
                })
            }

            state.askAI.loading = false
            this._vm.$bus.$emit('focusAskAIInput');
        },
        async startTeacherAIGame({ state }, data) {
            this.commit('tools/setAskAIDefaultGameState', data)

            state.askAI.prompt = ''
            this._vm.$nextTick(() => this._vm.$bus.$emit('resizeAskAIInput'));

            const { res, error } = await api(startTeacherAIGame, {
                input: {
                    gameTypeUuid: data.uuid
                }
            })

            if(error) {
                const startedGame = error.find(el => el.message.includes('Game is already started'))

                if(startedGame) {
                    const hex = '[0-9a-f]'
                    const uuidFormat = `${ hex }{8}-${ hex }{4}-${ hex }{4}-${ hex }{4}-${ hex }{12}`
                    const regex = new RegExp(`\\b${ uuidFormat }\\b`, 'gi')
                    const startedGameUuid = startedGame.message.match(regex)[0]

                    return await this.dispatch('tools/getTeacherAIGame', { game: { uuid: startedGameUuid }})
                }

                return this.commit('tools/handleAskAIError')
            }

            const { gameType, messages } = res.startTeacherAIGame

            if(state.askAIModal.ready) state.askAIModal.game.prompts.find(el => el.node.uuid === gameType.uuid).node.game = res.startTeacherAIGame
            state.askAI.game = res.startTeacherAIGame
            state.askAI.messages = messages.edges
            state.askAI.loading = false
            this._vm.$bus.$emit('focusAskAIInput');
        },
        async getTeacherAIGame({ state }, data) {
            this.commit('tools/setAskAIDefaultGameState', data)

            const { res, error } = await api(teacherAIGame, {
                uuid: data.game.uuid
            })

            if(error) return this.commit('tools/handleAskAIError')

            const { messages } = res.teacherAIGame

            state.askAI.game = res.teacherAIGame
            state.askAI.messages = messages.edges
            state.askAI.loading = false
            state.askAI.prompt = ''
            this._vm.$nextTick(() => this._vm.$bus.$emit('resizeAskAIInput'));
            this._vm.$bus.$emit('focusAskAIInput');
        },
        async restartTeacherAIGame({ state }) {
            const { game } = state.askAI

            this.commit('tools/setAskAIDefaultGameState', {
                uuid: game.uuid,
                prompt: game.gameType.prompt
            })

            const { error } = await api(restartTeacherAIGame, {
                input: {
                    gameUuid: game.uuid
                }
            })

            if(error) return this.commit('tools/handleAskAIError')
        },
        async getTeacherAIGameMessages({ state }, data) {
            if(!state.askAI.ready || state.askAI.game?.uuid !== data.gameUuid) return

            state.askAI.loading = true

            const { res, error } = await api(teacherAIGame, {
                uuid: data.gameUuid,
                after: state.askAI.messages.filter(el => el.cursor).pop()?.cursor
            })

            if(error) return this.commit('tools/handleAskAIError')

            const { canSendMessages } = res.teacherAI
            const { messages } = res.teacherAIGame
            
            state.askAI.isDisabled = !canSendMessages
            state.askAI.messages = [...state.askAI.messages.filter(el => el.cursor), ...messages.edges]
            state.askAI.game = res.teacherAIGame

            const { game } = state.askAI

            if(game.state.status === 'FINISHED') {
                const gameType = state.askAIModal.game.prompts.find(el => el.node.uuid === game.gameType.uuid)
                if(gameType) gameType.node.game = null
            }

            state.askAI.loading = false
            this._vm.$bus.$emit('focusAskAIInput');
        },
        getAvailableDictionaries: async function ({ state, commit }) {
            state.tools = ['askAI', 'translator', 'grammar'];
            // const { res } = await api(checkDictionariesStatus);

            // if (res.getWordsHealth.oxford.healthStatus === 'ALIVE') {
            //     state.dictionary.supportedLangs = ['en-gb', 'en-us', 'es'];
            //     commit('setShowAllTools', 'OXFORD');
            // } else if (res.getWordsHealth.freeDictionary.healthStatus === 'ALIVE') {
            //     commit('setShowAllTools', 'FREE_DICTIONARY');
            // }
            state.dictionary.supportedLangs = ['en-gb', 'en-us', 'es'];
            commit('setShowAllTools', 'OXFORD');
        },
        async translateWordForTooltip(_, { word, target_lang }) {
            try {
                const response = await deepl({
                    text: word.english,
                    source_lang: 'en',
                    target_lang,
                    auth_key: process.env.VUE_APP_DEEPL_KEY
                })
                return response.data.translations[0].text;
            } catch(e) {
                return;
            }
        },
        async searchDictionaryWordForTooltip({ state }, { word }) {
            try {
                const { res: { getWords }} = await api(getDictionaryWord, { word: word.english, locale: 'EN_GB', source: 'FREE_DICTIONARY' });
                if (getWords.errors.length || getWords.status !== "SUCCESS") return { error: true };
                return getWords.words;
            } catch(e) {
                return { error: true };
            }
        },
        async searchDictionaryWord({ state }) {
            if(!state.dictionary.word.length) return;

            const route = router.currentRoute

            if(route.name === 'tools' && route.params.word !== state.dictionary.word) {
                router.push({name: 'tools', params: { tools: 'dictionary', word: state.dictionary.word }})
            }

            state.dictionary.showError = false;
            state.dictionary.showLogo = false;
            const cachedWord = state.dictionary.cachedData.find(item => item[0]?.word === state.dictionary.word)

            if (cachedWord) {
                state.dictionary.data = cachedWord
                if (!cachedWord.length) state.dictionary.showError = true;
                return
            }
            
            state.dictionary.loading = true;

            const locale = state.dictionary.chosenLang.toUpperCase().replace('-', '_');

            const response = await api(getDictionaryWord, { 'word': state.dictionary.word, 'locale': locale, 'source': state.dictionary.provider })
                .catch(() => {
                    state.dictionary.showError = true;
                    state.dictionary.loading = false;
                });

            if (!response?.res?.getWords?.words.length) state.dictionary.showError = true;

            state.dictionary.data = response?.res?.getWords?.words;
            if (state.dictionary.data) state.dictionary.cachedData.push(state.dictionary.data)
            state.dictionary.loading = false;
            if (document.querySelector('.dictionary__content')) document.querySelector('.dictionary__content').scrollTop = 0;
        },
        async translate(context) {
            if(context.getters.isOverTranslatorLimit) return

            const text = context.state.translator.sourceText

            if(!text.trim().length) {
                this.commit('tools/setTargetText', text)
                return
            }

            context.state.translator.duringTranslation = true

            let { source_lang, target_lang, formality } = context.state.translator
            if(source_lang === 'cz') source_lang = 'cs'
            if(target_lang === 'cz') target_lang = 'cs'
            if(source_lang === 'ua') source_lang = 'uk'
            if(target_lang === 'ua') target_lang = 'uk'
            if(target_lang === 'pt') target_lang = 'pt-pt'

            const { res, error } = await api(deeplTranslation, {
                text,
                sourceLang: source_lang.replace('-', '_'),
                targetLang: target_lang.replace('-', '_'),
                ...(context.getters.isFormalitySupported && { formality })
            })

            if(res?.deeplTranslation) this.commit('tools/setTargetText', res.deeplTranslation.text)

            if(error) {
                const limitError = error.find(el => el.message === 'User reached daily translation limit')

                this._vm.$notify({
                    title: i18n.t(limitError ? 'tools.translator.limit-error.title' : 'notify.error.title'),
                    text: i18n.t(limitError ? 'tools.translator.limit-error.subtitle' :'notify.error.subtitle'),
                    data: {
                        type: limitError ? 'warning' : 'error'
                    }
                })
            }

            context.state.translator.duringTranslation = false
        },
        async grammarCheck(context) {
            context.state.grammar.scrollTop = document.querySelector('.grammar__input')?.scrollTop
            context.state.grammar.loading = true
            context.state.grammar.disableCheck = true
            context.state.grammar.showMistakes = false

            const formData = new URLSearchParams({
                data: JSON.stringify({ text: removeMultipleNewLines(context.state.grammar.text) }),
                language: 'en-GB',
                enabledOnly: false
            })

            try {
                const res = await schoolV2.post('languagetool/check', formData,
                {
                    headers: {
                        'Authorization': `Bearer ${ accessStorage('authToken') }`
                    }
                })

                this.commit('tools/grammarHandleMatches', res.data.matches)
            } catch {
                context.state.grammar.loading = false

                this._vm.$notify({
                    title: i18n.t('notify.error.title'),
                    text: i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            }

        },
    },
    mutations: {
        leaveTeacherAIGame(state) {
            state.askAI.loading = true
            state.askAI.messages = []
            state.askAI.game = null

            this.dispatch('tools/getTeacherAIChatMessages');
        },
        setEnDialect(state, payload) {
            const changeEnDialect = payload.toLang;

            if (changeEnDialect.split('-')[0] !== 'en') return;
            if (payload.fromTool !== 'dictionary') {
                state.dictionary.chosenLang = changeEnDialect;
                state.dictionary.cachedData = [];
                this.dispatch('tools/searchDictionaryWord');
            }
            if (payload.fromTool !== 'translator') {
                const targetLang = state.translator.target_lang
                
                if(targetLang.split('-')[0] === 'en' && targetLang !== changeEnDialect) {
                    this.commit('tools/setTranslatorTargetLang', changeEnDialect)
                    this.dispatch('tools/translate')
                } else this.commit('tools/setTranslatorLastUsedEnglish', changeEnDialect)
            }
        },
        setTool(state, data) {
            if(state.activeTool === data) this.commit('tools/closeTools')
            else {
                state.activeTool = data
                
                if(router.currentRoute.name !== 'tools') this.commit('tools/openTools')
            }
        },
        setShowAllTools(state, payload) {
            state.tools.push('dictionary')
            state.dictionary.provider = payload;
            this._vm.$bus.$emit('setToolsStyle');
        },
        setContentWidth(state, data) {
            state.toolContentWidth = data;
        },
        openTools(state) {
            state.showTools = true
            if (this.state.isMobile) document.documentElement.classList.add('overflow-y-hidden');
        },
        closeTools(state) {
            state.showTools = false
            state.activeTool = null
            document.documentElement.classList.remove('overflow-y-hidden');
        },
        toggleToolsButtons(state) {
            state.showToolsButtons = !state.showToolsButtons
            
            this._vm.$gtag({
                category: 'tool',
                action: state.showToolsButtons ? 'show' : 'hide'
            })
        },
        setToolsButtons(state, payload) {
            state.showToolsButtons = payload
        },
        toggleMoreTools(state) {
            state.moreTools = !state.moreTools
        },
        setMoreTools(state, payload) {
            state.moreTools = payload
        },
        setContextUrl(state, payload) {
            state.contextUrl = payload
        },
        setSourceText(state, payload) {
            state.translator.sourceText = payload
        },
        setTargetText(state, payload) {
            state.translator.targetText = payload
        },
        setTranslatorSourceLang(state, payload) {
            state.translator.source_lang = ['en-gb', 'en-us'].includes(payload) ? 'en' : payload
        },
        setTranslatorTargetLang(state, payload) {
            this.commit('tools/setTranslatorLastUsedEnglish', payload)

            state.translator.target_lang = payload === 'en' ? state.translator.lastUsedEnglish : payload
        },
        async toggleTranslatorLangs(state) {
            const { source_lang, target_lang, targetText } = state.translator

            this.commit('tools/setTranslatorSourceLang', target_lang)
            this.commit('tools/setTranslatorTargetLang', source_lang)
            this.commit('tools/setSourceText', targetText)
            this.commit('tools/setTargetText', '')
            state.translator.reverseLangs = !state.translator.reverseLangs
            await this.dispatch('tools/translate')
        },
        async setTranslatorFormality(state, payload) {
            state.translator.formality = payload
            this._vm.$gtag({
                category: 'tool',
                action: 'style_change',
                value: payload === 'less' ? 'informal' : 'formal'
            })

            await this.dispatch('tools/translate')
        },
        toggleTranslatorSourceLangs(state) {
            state.translator.showSourceLangs = !state.translator.showSourceLangs
        },
        toggleTranslatorTargetLangs(state) {
            state.translator.showTargetLangs = !state.translator.showTargetLangs
        },
        setTranslatorLastUsedEnglish(state, payload) {
            if(['en-gb', 'en-us'].includes(payload)) state.translator.lastUsedEnglish = payload
        },

        showTooltip(state, payload) {
            state.tooltip.type = payload.type;
            state.tooltip.word = payload.word;
            state.tooltip.buttons = payload.buttons;
            state.tooltip.wordId = payload.wordId;
            state.tooltip.mouseTargetDimensions = payload.mouseTargetDimensions;
            state.tooltip.visible = true;
            state.tooltip.extendedOptions = payload?.extendedOptions;
            state.tooltip.data = payload.data;
        },
        hideTooltip(state) {
            state.tooltip.type = 'basic';
            state.tooltip.word = {};
            state.tooltip.mouseTargetDimensions = {};
            state.tooltip.visible = false;
            state.tooltip.data = null
        },
        keepShowingTooltip(state) {
            state.tooltip.keepShowingTooltip = true;
        },
        stopShowingTooltip(state) {
            state.tooltip.keepShowingTooltip = false;
            this.commit('tools/hideTooltip');
        },
        addAskAIMessage: (state, data) => state.askAI.messages.push({ node: data }),
        grammarHandleMatches: (state, data) => {
            const text = removeMultipleNewLines(state.grammar.text)
            const result = []
            let currentIndex = 0

            data.forEach(el => {
                result.push({
                    text: text.substring(currentIndex, el.offset),
                })
                result.push({
                    text: text.substring(el.offset, el.offset + el.length),
                    match: { ...el, uuid: uuidv4() }
                })
                
                currentIndex = el.offset + el.length
            })

            result.push({
                text: text.substring(currentIndex)
            })
            
            state.grammar.matches = result.filter(el => el?.text || el?.match)
            state.grammar.hideMatches = false
            state.grammar.showMistakes = true
            state.grammar.loading = false
            state.grammar.renderValue++

            setTimeout(() => {
                state.grammar.text = document.querySelector('.grammar__input')?.innerText
            }, 0)
        },
        grammarReplacement: (state, data) => {
            const matchItem = state.grammar.matches.find(el => el.match?.uuid === data.uuid)

            delete matchItem.match
            matchItem.text = data.text
            
            setTimeout(() => {
                state.grammar.text = document.querySelector('.grammar__input')?.innerText
            }, 0)
        },
        grammarSetActiveMatch: (state, data) => {
            state.grammar.activeMatch = data
        },
        grammarResetActiveMatch: (state) => {
            state.grammar.activeMatch = null
        },
        handleAskAIError(state) {
            state.askAI.loading = false
            this._vm.$notify({
                title: i18n.t('notify.error.title'),
                text: i18n.t('notify.error.subtitle'),
                data: {
                    type: 'error'
                }
            })
        },
        setAskAIDefaultGameState: (state, data) => {
            state.askAI.loading = true
            state.askAI.messages = []
            state.askAI.game = {
                uuid: data.uuid,
                gameType: {
                    prompt: data.prompt
                }
            }
        }
    },
    getters: {
        isFormalitySupported(state) {
            return state.translator.formalityLangs.includes(state.translator.target_lang)
        },
        translatorCharacters(state) {
            return state.translator.sourceText.length
        },
        isOverTranslatorLimit(state, getters) {
            return getters.translatorCharacters > state.translator.charactersLimit
        },
        isFreeApiAvaliable(state) {
            return state.tools.includes('dictionary');
        },
        isGrammarSupported(state) {
            return state.grammar.isSupported
        },
        isOverGrammarLimit(state) {
            return state.grammar.text.length >= state.grammar.charactersLimit
        },
        isGrammarCheckDisabled(state, getters) {
            return getters.isOverGrammarLimit || state.grammar.disableCheck || !state.grammar.text.length
        },
        isAskAIModalOpen(state) {
            return state.askAIModal?.open
        },
        isAskAIResultsModalOpen(state) {
            return state.askAI.game?.state?.status === 'FINISHED'
        }
    }
}

export default toolsStore