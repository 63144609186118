export default {
    methods: {
        isMotherTongue(name) {
            return name === "Mother Tongue";
        },
        handleLanguages(speak, idx, langArrLength) {
            let lang = '';
            const langShortname = speak.languageLevel?.shortName;

            if (this.isMotherTongue(langShortname)) lang += `<span class="switch-lector-item__level--mother-tongue">${ langShortname }</span> `;
            lang += speak.language?.name;
            if (!this.isMotherTongue(langShortname)) lang += ` <span class="switch-lector-item__level">${ langShortname }</span>`;
            if (idx !== langArrLength - 1) lang += ', ';

            return lang
        }
    }
}