<template>
    <div :class="['audio-player', { 'audio-player--sticky': sticky }]">
        <audio :src="audioElement" ref="audioPlayer">
            <source type="audio/mp3">
        </audio>
        <template v-if="mini">
            <Button
                v-if="!isPlaying"
                class="mini-btn btn--default"
                :loading="loading"
                @click.native="playAudio"
                :disabled="isError"
                :icon="require('@/assets/img/audioPlayer/play_volume.svg')"
            />
            <Button
                v-else
                class="mini-btn mini-btn__volume-disabled btn--default"
                @click.native="stopAudio"
                :icon="require('@/assets/img/audioPlayer/pause.svg')"
            />
        </template>
        <div v-else-if="record" class="recorder">
            <button
                :class="[`recorder__btn recorder__btn--${ recorderBtn.class }`, { 'recorder__btn--loading': encodingLoading }]"
                @click="recorderBtn.action()"
            >
                <img :src="recorderBtn.icon" alt="">
                {{ $t(recorderBtn.text) }}
                
                <div v-if="encodingLoading" class="recorder__encoding-loading">
                    <Loading danger/>
                </div>
            </button>
            <div :class="['recorder__slider-wrapper',
                {
                    'recorder__slider-wrapper--disabled': isRecording || !audioElement,
                    'recorder__slider-wrapper--empty': !audioElement && !isRecording
                }
            ]">
                <div class="recorder__timer">{{ formattedTime }}</div>
                <Slider
                    :value="currentTime"
                    :max="audioElement ? duration : maxRecorderDuration"
                    orientation="horizontal"
                    @changed="updateCurrentTime"
                    :disabled="isInfinityDuration"
                />
                <img
                    :class="['recorder__delete', { 'recorder__delete--hide': !audioElement }]"
                    src="@/assets/img/audioPlayer/trash.svg"
                    alt=""
                    @click="setAudioElement(null, true)"
                >
            </div>
        </div>
        <div v-else-if="recordOpenEnded" class="recorder recorder--open-ended">
            <div v-if="isAudioRecorderVisible" :class="['recording-wrapper', { 'recording-wrapper--typing': !microphoneInputStatus }]">
                <template v-if="!audioElement && !isRecording && !transcribing">
                    <div @click="toggleMenu" v-click-outside="hideMenu" class="recording-wrapper__button default">
                        <img src="@/assets/img/audioPlayer/dot-btn.svg" alt=""/>
                        <dropdown v-if="showMenu" pos="top">
                            <div v-if="microphoneInputStatus" @click="setMicStatus" class="dropdown-item">
                                <img src="@/assets/img/learning/pen-active.svg" alt=""/>
                                <p>{{ $t('audioplayer.answer-in-writing', transUi) }}</p>
                            </div>
                            <div v-else @click="setMicStatus" class="dropdown-item">
                                <img src="@/assets/img/learning/microphone-active.svg" alt=""/>
                                <p>{{ $t('audioplayer.answer-in-speaking', transUi) }}</p>
                            </div>
                        </dropdown>
                    </div>
                    <div v-if="microphoneInputStatus" class="start-recording">
                        <span class="tap-to-record">{{ $t('audioplayer.tap-to-record', transUi) }}</span>
                        <div @click="recorderBtn.action()" class="recording-wrapper__button primary">
                            <img src="@/assets/img/audioPlayer/microphone-white.svg" alt=""/>
                        </div>
                    </div>
                </template>
                <template v-else-if="!audioElement && isRecording && !transcribing">
                    <span class="recorder-time danger">{{ this.getRecordingTime }}</span>
                    <div class="bars-wrapper">
                        <div v-for="(bar, idx) in audioData" :key="idx" class="bar" :style="`height: ${ bar }px`"></div>
                    </div>
                    <div @click="stopRecording" class="recording-wrapper__button danger">
                        <img src="@/assets/img/audioPlayer/stop.svg" alt=""/>
                    </div>
                </template>
                <template v-else-if="transcribing">
                    <span class="recorder-time default">{{ this.getRecordingTime }}</span>
                    <span class="tap-to-record">{{ $t('audioplayer.checking-your-answer', transUi) }}...</span>
                    <Loading primaryLight/>
                </template>
            </div>
            <div v-else-if="isCrossBtnVisible" @click="resetRecording" class="recording-wrapper__button cross">
                <img src="@/assets/img/audioPlayer/cross.svg" alt=""/>
            </div>
        </div>
        <div
            v-else
            @click="!showAll && playAudio()"
            :class="['player-wrapper', { 'player-wrapper--hidden' : !showAll, 'player-wrapper--mobile': isMobile }]"
            @mouseover="!showAll && highlightParentText(true)"
            @mouseleave="!isPlaying && !loading && highlightParentText(false)"
        >
            <template v-if="!showAll">
                <Button
                    class="button"
                    @click.native="playAudio()"
                    :icon="require('@/assets/img/audioPlayer/play_volume.svg')"
                    :disabled="isError"
                    :loading="loading"
                />
                <span class="title">{{ $t('audioPlayer.listen', transUi) }}</span>
            </template>
            <div :class="['show-buttons', { 'show-buttons--active' : showAll }]">
                <Button
                    class="button btn--secondary"
                    @click.native="rewindAudio"
                    :disabled="isError"
                    :icon="require('@/assets/img/audioPlayer/replay_10.svg')"
                />
                <Button
                    v-if="isPlaying"
                    class="button btn--secondary"
                    @click.native="pauseAudio()"
                    :icon="require('@/assets/img/audioPlayer/pause.svg')"
                />
                <Button
                    v-else
                    class="button btn--secondary"
                    @click.native="playAudio()"
                    :icon="require('@/assets/img/audioPlayer/play.svg')"
                    :disabled="isError"
                    :loading="loading"
                />
                <Button
                    class="button btn--secondary"
                    @click.native="forwardAudio"
                    :disabled="isError"
                    :icon="require('@/assets/img/audioPlayer/forward_10.svg')"
                />
                <Slider
                    class="duration-slider"
                    v-if="!isMobile"
                    :value="currentTime"
                    :max="duration"
                    orientation="horizontal"
                    @changed="updateCurrentTime"
                />
                <div v-if="!isMobile && showTime" class="time-display">{{ formattedTime }}</div>
                <Button
                    class="button btn--secondary"
                    @click.native="stopAudio"
                    :disabled="isError"
                    :icon="require('@/assets/img/audioPlayer/replay_all.svg')"
                />
                <div v-if="!isMobile" class="volume-control" @mouseover="showVolumeSlider = true" @mouseleave="showVolumeSlider = false">
                    <Button
                        class="button btn--secondary"
                        @click.native="toggleMute"
                        :icon="!isMuted ? VolumeUpIcon : VolumeMuteIcon"
                    />
                    <div class="volume-wrapper" v-if="showVolumeSlider">
                        <Slider
                            :value="volume"
                            :max="1"
                            :min="0"
                            :step="0.01"
                            orientation="vertical"
                            @changed="updateVolume"
                        />
                        <span class="transparent-block"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import Button from '@/components/reusable/Button';
import Slider from '@/components/reusable/media/Slider';
import Loading from '@/components/reusable/Loading';
import ClickOutside from 'vue-click-outside'
import VolumeUpIcon from '@/assets/img/audioPlayer/volume_up.svg';
import VolumeMuteIcon from '@/assets/img/audioPlayer/volume_mute.svg';
import { v4 as uuidv4 } from 'uuid';
import recorder from '@/mixins/recorder'

export default {
    name: 'AudioPlayer',
    components: { Button, Dropdown, Slider, Loading },
    mixins: [recorder, dropdownMixin],
    directives: {
        ClickOutside
    },
    props: {
        data: {
            type: String
        },
        text: {
            type: String,
            default: null
        },
        mini: {
            type: Boolean,
            default: false
        },
        record: {
            type: Boolean,
            default: false
        },
        recordOpenEnded: {
            type: Boolean,
            default: false
        },
        sticky: {
            type: Boolean,
            default: false
        },
        hideControls: {
            default: false
        },
        microphoneInputStatus: {
            default: true
        },
        transcribing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentTime: 0,
            duration: 0,
            audioElement: null,
            volume: 1,
            loading: false,
            showVolumeSlider: false,
            isPlaying: false,
            isError: false,
            uuid: uuidv4(),
            showAll: this.hideControls ? false : true,
            isMuted: false,
            wakeLock: null,
            VolumeUpIcon,
            VolumeMuteIcon
        };
    },
    created() {
        setTimeout(() => {
            if(this.data) this.setAudioElement(this.data);
        }, 0)
    },
    mounted() {
        this.$bus.$on('resetRecorder', this.resetRecording)

        const audioElement = this.$refs.audioPlayer;

        audioElement.addEventListener('loadedmetadata', () => {
            this.duration = audioElement.duration;
        });

        audioElement.addEventListener('timeupdate', () => {
            if(this.audioElement) this.currentTime = audioElement.currentTime;

            if(this.isInfinityDuration) this.duration = audioElement.duration;
        });
    },
    beforeDestroy() {
        this.$bus.$off('resetRecorder');
        this.releaseWakeLock();
    },
    computed: {
        ...mapState(['windowWidth']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('learning', ['getUserAnswer']),
        isMobile() {
            return this.windowWidth < 768
        },
        formattedTime() {
            const currentTime = Math.round(this.currentTime);
            const duration = this.audioElement ? Math.round(this.duration) : this.maxRecorderDuration;

            const currentMinutes = Math.floor(currentTime / 60);
            const currentSeconds = currentTime % 60;
            const durationMinutes = Math.floor(duration / 60);
            const durationSeconds = duration % 60;
            const formattedDuration = `/${ durationMinutes }:${ durationSeconds.toString().padStart(2, '0') }`

            return `${ currentMinutes }:${ currentSeconds.toString().padStart(2, '0') }${ this.isInfinityDuration ? '' : formattedDuration }`;
        },
        showTime() {
            return this.duration
        },
        isInfinityDuration() {
            return this.duration === Infinity
        },
        isAudioRecorderVisible() {
            return !this.audioElement && this.microphoneInputStatus
                || (!this.microphoneInputStatus && !this.getUserAnswer)
                || this.transcribing
        },
        isCrossBtnVisible() {
            return this.audioElement && !this.isRecording && !this.transcribing
                || !this.microphoneInputStatus && this.getUserAnswer
        }
    },
    methods: {
        async requestWakeLock() {
            if ('wakeLock' in navigator) {
                try {
                    this.wakeLock = await navigator.wakeLock.request('screen');
                    console.log('Wake Lock is active');
                } catch (err) {
                    console.error(`${err.name}, ${err.message}`);
                }
            } else {
                console.warn('Wake Lock API is not supported by this browser.');
            }
        },
        releaseWakeLock() {
            if (this.wakeLock !== null) {
                this.wakeLock.release()
                    .then(() => {
                        this.wakeLock = null;
                        console.log('Wake Lock released');
                    });
            }
        },
        resetRecording() {
            this.setAudioElement(null, true);
            this.$emit('resetRecording');
        },
        setMicStatus() {
            this.$emit('setMicStatus', !this.microphoneInputStatus);
        },
        setAudioElement(data, emit) {
            this.audioElement = this.recordOpenEnded ?
                data
                : data ? `data:audio/mp3;base64, ${ data }` : data;
            this.duration = 0
            
            if(!data) {
                this.currentTime = 0
                this.stopAudio()
            }

            if(emit) this.$emit('input', this.audioElement)
        },
        highlightParentText(val) {
            if(this.mini) return;

            const el = this.$refs.audioPlayer.closest('.audio-highlight');
            const activeClass = 'audio-highlight--active';

            val ? el?.classList.add(activeClass) : el?.classList.remove(activeClass);
        },

        async playAudio() {
            this.loading = true;
            this.highlightParentText(true);
            this.$emit('playAudio');
            this.showAll = true;

            await this.requestWakeLock(); // Request wake lock when playing audio

            if (!this.audioElement) {
                const src = await this.$store.dispatch('getSynthesizeText', {
                    text: this.text
                });

                this.audioElement = src;
                this.loading = false;
            }

            setTimeout(async () => {
                this.loading = false;
                if (this.record) await this.$store.dispatch('media/setSpeaker', this.$refs.audioPlayer);
                this.$refs.audioPlayer?.play();
                this.isPlaying = true;
                this.$store.state.audioUuid = this.uuid;
                this.handleGtag('play');
            }, 0);
        },
        pauseAudio() {
            this.$refs.audioPlayer.pause();
            this.isPlaying = false;
            this.highlightParentText(false);
            this.releaseWakeLock();
            this.handleGtag('pause');
        },
        stopAudio() {
            this.$refs.audioPlayer.pause();
            this.$refs.audioPlayer.currentTime = 0;
            this.isPlaying = false;
            this.highlightParentText(false);
            this.releaseWakeLock();
            this.handleGtag('stop');
        },
        forwardAudio() {
            this.$refs.audioPlayer.currentTime += 10;
            this.handleGtag('forward_10_sec');
        },
        rewindAudio() {
            this.$refs.audioPlayer.currentTime -= 10;
            this.handleGtag('rewind_10_sec');
        },
        toggleVolumeSlider() {
            this.showVolumeSlider = !this.showVolumeSlider;
        },
        hideVolumeSlider() {
            this.showVolumeSlider = false;
        },
        updateCurrentTime(val) {
            const audioElement = this.$refs.audioPlayer;
            audioElement.currentTime = val;
        },
        updateVolume(newVolume) {
            const audioElement = this.$refs.audioPlayer;
            audioElement.volume = newVolume;
            this.volume = newVolume;
            this.isMuted = false;

            if (this.volume <= 0.2) {
                this.isMuted = true;
            }
        },
        toggleMute() {
            const audioElement = this.$refs.audioPlayer;

            if (!this.isMuted) {
                this.volume = audioElement.volume;
                audioElement.volume = 0;
                this.isMuted = true;
            } else {
                audioElement.volume = this.volume;
                this.isMuted = false;

                if (this.volume <= 0.2) {
                    this.isMuted = false;
                    this.volume = 0.3;
                    audioElement.volume = 0.3;
                }
            }
        },
        handleGtag(label) {
            this.$gtag({
                category: this.lessonMode,
                action: 'text_to_speech',
                label
            })
        }
    },
    watch: {
        currentTime(newTime) {
            const audioElement = this.$refs.audioPlayer;

            if (newTime === this.duration) this.isPlaying = false;

            if (audioElement.paused) {
                audioElement.currentTime = newTime;
                this.isPlaying = false;
            }
        },
        '$store.state.audioUuid': {
            handler(uuid) {
                if (this.uuid !== uuid && this.isPlaying) this.stopAudio();
            }
        },
        isPlaying(n) {
            if(!n) this.$emit('stopAudio');
        }
    }
}
</script>

<style lang="scss" scoped>
.audio-player {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    transition: all .5s;

    &--sticky {
        position: sticky;
        top: 64px;
        background: $white;
    }

    .mini-btn {
        width: 32px;
        height: 32px;
        padding: 4px 8px;
        text-align: center;
        font-size: 16px;
        background: transparent;
        border: 0;
        min-width: 32px;

        &__volume-disabled {
            filter: grayscale(100%);
        }
    }

    .player-wrapper {
        pointer-events: auto;
        display: flex;
        height: 32px;
        padding: 8px 12px;
        align-items: center;
        border-radius: 100px;
        background: $gray-100;
        cursor: default;
        width: 100%;
        margin: auto;

        &--mobile {
            width: auto;
        }

        &--hidden {
            cursor: pointer;
            border: 2px solid transparent;
            gap: 8px;

            &:hover {
                border: 2px solid $gray-300;
            }

            .btn {
                border-color: transparent !important;
            }
        }

        .show-buttons {
            display: flex;
            transition: width .5s, opacity 2s;
            overflow: hidden;
            width: 0;
            gap: 16px;
            align-items: center;
            justify-content: flex-end;
            opacity: 0;

            &--active {
                width: 100%;
                overflow: visible;
                opacity: 1;
            }
        }

        .title {
            @include font-medium;
            text-transform: capitalize;
        }

        .time-display {
            color: var(--gray-700, #46505E);
            text-align: center;
            @include font-small-12;
            flex-shrink: 0;
        }

        .button {
            height: 24px;
            width: 24px;
            background: none;
            padding: 0;
            flex-shrink: 0;
            border-color: $gray-100;

            &:hover {
                border-color: $gray-300;
            }
        }

        .duration-slider {
            min-width: 120px;
        }

        .volume-control {
            position: relative;
        }

        .volume-wrapper {
            position: absolute;
            right: 3px;
            bottom: 30px;
            height: 96px;
            padding: 8px 10px;
            border-radius: 6px;
            background: $gray-100;

            .transparent-block {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 0px;
            }
        }
    }

    .recorder {
        width: 100%;
        display: flex;
        gap: 8px;
        margin: 8px 0;

        &--open-ended {
            justify-content: center;
        }

        &__btn {
            width: 80px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border: 0;
            border-radius: 48px;
            outline: 0;
            @include font-medium;
            font-weight: 600;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            &--record {
                background: $primary-light;
                color: $primary-dark;
            }

            &--recording {
                background: $danger-light;
                color: $danger-middle;
            }

            &--play, &--playing {
                background: $gray-100;
                color: $gray-700;
            }

            &--loading {
                pointer-events: none;
            }

            &:hover {
                opacity: .9;
            }
        }

        &__btn-openEnded {
            display: flex;
            gap: 10px;
            justify-content: center;
            cursor: pointer;
            border: 0;
            padding: 16px 20px 16px 18px;
            border-radius: 12px;
            @include font-h3;
            font-weight: 600;
            color: $white;
            margin: 11px 0;
            background: $gray-100;

            img {
                margin-top: 2px;
            }
        }

        &__encoding-loading {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $danger-light;
        }

        &__slider-wrapper {
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            gap: 24px;
            border-radius: 48px;
            padding: 12px 16px;
            background: $gray-100;
            transition: all .25s;

            &--disabled {
                pointer-events: none;
            }

            &--empty {
                opacity: .5;
            }
        }

        &__timer {
            word-break: keep-all;
            @include font-small-12;
            color: $gray-600;
        }

        &__delete {
            cursor: pointer;
            transition: all .25s;

            &:hover {
                filter: brightness(.6);
            }

            &--hide {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .recording-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        background-color: $gray-100;
        padding: 16px 16px 16px 20px;
        gap: 20px;
        animation: slideIn 0.3s ease;
        min-width: 343px;
        min-height: 74px;

        .recorder-time {
            @include font-h4;

            &.danger {
                color: $danger-middle;
            }

            &.default {
                color: $gray-500;
            }
        }

        .loading {
            width: 40px;
            display: flex;
        }

        &__button {
            display: flex;
            justify-content: center;
            width: 42px;
            height: 42px;
            border-radius: 100px;
            cursor: pointer;
            position: relative;

            .main-dropdown {
                transform: translate(40%, -120%);
                min-width: 177px;
                padding: 0px;

                &:hover {
                    background-color: $gray-100;
                }
            }

            .dropdown-item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                padding: 0;
                white-space: nowrap;
                padding: 12px;

                img {
                    width: 16px;
                }
            }

            &.primary {
                background-color: $primary;

                &:hover {
                    background-color: $primary-dark;
                }
            }

            &.default {
                background-color: $gray-200;

                &:hover {
                    background-color: $gray-300;
                }
            }

            &.danger {
                background-color: $danger;
                
                img {
                    width: 18px;
                }

                &:hover {
                    background-color: $danger-dark;
                }
            }

            &.cross {
                margin: 12px 0;
                background-color: $gray-200;
                width: 52px;
                height: 52px;

                img {
                    width: 24px;
                }

                &:hover {
                    background-color: $gray-300;
                }
            }

            img {
                z-index: 1;
                width: 24px;
            }
        }

        &--typing {
            background-color: $white;
        }
    }

    .bars-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        min-width: 182px;
        transition: height .5s ease;

        .bar {
            width: 4px;
            border-radius: 12px;
            background-color: $danger-middle;
            max-height: 42px;
            min-height: 1px;
        }
    }
    
    .tap-to-record {
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.4px;
    }

    .start-recording {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .remove-button {
        cursor: pointer;
        margin: 13px 0;
    }
}
</style>
