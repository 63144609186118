var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[_c('label',{class:['input', { 'error': _vm.validation }]},[_vm._v(" "+_vm._s(_vm.data.label)+" "),_c('vue-tel-input',{ref:"telInput",attrs:{"value":_vm.newValue || _vm.value,"mode":"international","styleClasses":['tel-input'],"defaultCountry":_vm.defaultCountry,"validCharactersOnly":true,"autoFormat":true,"dropdownOptions":{
                showDialCodeInSelection: false,
                showFlags: true,
                showDialCodeInList: true,
                showSearchBox: true,
                tabindex: 0
            },"inputOptions":{
                placeholder: _vm.data?.placeholder,
                name: _vm.data?.name
            }},on:{"input":_vm.onInput}})],1),(_vm.validation)?_c('p',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.validation))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }