export default {
    data() {
        return {
            videos: {
                individual: 'https://www.youtube.com/watch?v=Wc8cDfAxyUc&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421t?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                shared: 'https://www.youtube.com/watch?v=33DXO1iwhnk&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421t?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                group: 'https://www.youtube.com/watch?v=J5MoewJ1wGg&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                individualCompany: 'https://www.youtube.com/watch?v=ivdJE_bqPMQ&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421t?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                sharedCompany: 'https://www.youtube.com/watch?v=33DXO1iwhnk&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421t?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                groupCompany: 'https://www.youtube.com/watch?v=zZpnR6_SZTk&list=PL57XVfwQT6_iL7Kpb-wr9aX5kFziLZUWJ?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1',
                children: 'https://www.youtube.com/watch?v=YWncubIpcGE&list=PL57XVfwQT6_inA5XZ9MDIIIsCLFZg421t?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'
            }
        }
    },
}