<template>
    <div class="apple-btn-wrapper">
        <a v-if="isMobileApp" class="apple-btn-overlay" href="https://sign-in-with-apple.com"></a>
        <div :class="[{ 'logo-only': btnMode === 'logo-only' }]" id="appleid-signin" :data-mode="btnMode || 'center-align'" data-color="black" data-border="true" data-type="sign in"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localesMap from '@/helpers/localesMap';

export default {
    props: ['btnMode', 'action'],
    computed: {
        ...mapGetters(['isMobileApp']),
    },
    created() {
        let useLocale;

        if(Object.keys(localesMap).includes(this.$route.params.locale)) useLocale = localesMap[this.$route.params.locale];
        else useLocale = localesMap['en'];

        const scriptSrc = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${useLocale}/appleid.auth.js`;

        if(!window.AppleID) {
            const scriptElement = document.createElement("script");
            scriptElement.src = scriptSrc;
            scriptElement.type = "text/javascript";
            document.head.appendChild(scriptElement);
            
            scriptElement.onload = () => {
                this.initAppleIdAuth();
            };
            return;
        }
        setTimeout(() => { this.initAppleIdAuth() }, 0);
    },
    mounted() {
        document.addEventListener('AppleIDSignInOnSuccess', this.appleIntegrate);
        document.addEventListener('AppleIDSignInOnFailure', this.appleIntegrateFail);
    },
    beforeDestroy() {
        window.removeEventListener('AppleIDSignInOnSuccess', this.appleIntegrate);
        window.removeEventListener('AppleIDSignInOnFailure', this.appleIntegrateFail);
    },
    methods: {
        async appleIntegrate(e) {
            const { authorization: { id_token: token } = {}, user: { name: student } = {}} = e.detail;
            await this.$emit('action', { token, type: 'apple', student })
        },

        appleIntegrateFail() {
            this.$emit('fail');
        },

        initAppleIdAuth() {
            const appleConfig = {
                clientId : process.env.VUE_APP_APPLE_CLIENT_ID,
                scope : 'name email',
                redirectURI : window.location.origin,
                usePopup : true
            }

            window.AppleID.auth.init(appleConfig);
        }
    }
}
</script>
<style lang="scss" scoped>
.apple-btn-wrapper {
    position: relative;

    #appleid-signin {
        height: 100%;
        width: 100%;
        cursor: pointer;
                    
        ::v-deep text {
            font-family: 'applied-button-font-0' !important;
        }

        &.logo-only {
            height: 32px;
        }
    }

    .apple-btn-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}
</style>