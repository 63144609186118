import { render, staticRenderFns } from "./Flashcards.vue?vue&type=template&id=df8fa98e&scoped=true"
import script from "./Flashcards.vue?vue&type=script&lang=js"
export * from "./Flashcards.vue?vue&type=script&lang=js"
import style0 from "./Flashcards.vue?vue&type=style&index=0&id=df8fa98e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df8fa98e",
  null
  
)

export default component.exports