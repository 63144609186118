<template>
    <div :class="['multiple-choice', { 'multiple-choice--completed': isCompleted }]">
        <div class="multiple-choice__header">
            <RoundedNumber size="large" :character="data.number"/>
            <p class="multiple-choice__title">{{ data.name }}</p>
        </div>
        <div class="multiple-choice__content">
            <Media v-if="data.media?.length" :data="data.media" />
            <p class="multiple-choice__reminder">
                <img src="@/assets/img/warning-500.svg" alt="">
                {{ $t('exercises.multiple-choice.reminder') }}
            </p>
            <div class="multiple-choice__answers">
                <div
                    v-for="item in data.questions[0].answers"
                    :key="item.uuid"
                    :class="[
                        'multiple-choice__answer',
                        { 'multiple-choice__answer--active': isAnswerActive(item) },
                        { [`multiple-choice__answer--${ getAnswerStatusClass(item) }`]: getAnswerStatusClass(item) }
                    ]"
                    @click="setAnswersArray(item.uuid)"
                >
                    <div class="multiple-choice__checkbox">
                        <img v-if="isAnswerActive(item)" src="@/assets/img/check-light.svg" alt="">
                    </div> {{ item.answer }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Media from '@/components/reusable/Media'
import RoundedNumber from '@/components/reusable/RoundedNumber';

export default {
    name: 'MultipleChoice',
    props: ['data', 'value', 'isCompleted'],
    components: {
        Media,
        RoundedNumber
    },
    data() {
        return {
            answersArray: this.value || []
        }
    },
    methods: {
        isAnswerActive(item) {
            return this.answersArray.includes(item.uuid)
        },
        setAnswersArray(item) {
            const index = this.answersArray.indexOf(item)

            if(index === -1) this.answersArray.push(item)
            else this.answersArray.splice(index, 1)

            this.$emit('input', this.answersArray)
        },
        getAnswerStatusClass(item) {
            if(item.isCorrect === null || !this.isCompleted) return

            if(item.isCorrect && this.isAnswerActive(item)) return 'correct'

            if(item.isCorrect && !this.isAnswerActive(item)) return 'should-be-correct'

            if(!item.isCorrect && this.isAnswerActive(item)) return 'incorrect'

            return null
        }
    }
}
</script>

<style lang="scss" scoped>
$mobile: 768px;

.multiple-choice {

    &--completed {
        pointer-events: none;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;

        @media(min-width: $mobile) {
            flex-direction: row;
            gap: 24px;
        }
    }

    &__title {
        @include font-lead;
        text-align: left;
    }

    &__content {
        @media(min-width: $mobile) {
            padding-left: 56px;
        }
    }

    &__reminder {
        @include font-small-12;
        color: $gray-500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-bottom: 12px;
    }

    &__answers {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
        width: 100%;

        @media(min-width: $mobile) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__answer {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: $gray-900;
        padding: 11px 15px;
        border: 1px solid $gray-300;
        border-radius: 6px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
        cursor: pointer;
        text-align: left;
        transition: all .25s;

        &:hover {
            background: $gray-100;
        }

        &--active {
            border-color: $primary !important;

            .multiple-choice__checkbox {
                background: $primary;
                border-color: $primary;
            }
        }

        &--correct {
            background: $secondary-light;
            border-color: $secondary !important;
            color: $secondary;

            .multiple-choice__checkbox {
                background: $secondary;
                border-color: $secondary;
            }
        }

        &--should-be-correct {
            background: transparent;
            border-color: $secondary !important;
            color: $secondary;

            .multiple-choice__checkbox {
                background: transparent;
                border-color: $secondary;
            }
        }

        &--incorrect {
            background: $danger-light;
            border-color: $danger !important;
            color: $danger;

            .multiple-choice__checkbox {
                background: $danger;
                border-color: $danger;
            }
        }
    }

    &__checkbox {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 2px solid $gray-300;
        transition: all .25s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
}
</style>