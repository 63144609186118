<template>
    <div ref="el" class="tooltip-html" contenteditable="false">
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'TooltipHtml',
    props: {
        data: Object
    },
    mounted() {
        this.setPosition()
    },
    computed: {
        ...mapState(['windowWidth', 'windowHeight']),
    },
    methods: {
        setPosition() {
            const { el } = this.$refs
            const gap = 20
            let { left, top, width, height } = el.getBoundingClientRect()

            if(left + width + gap > this.windowWidth) left = this.windowWidth - width - gap

            if(left < gap) left = gap

            if(top + height + gap > this.windowHeight) top = top - height - gap
            
            const style = {
                position: 'fixed',
                left: left + 'px',
                top: top + 'px'
            }

            for(let property in style) {
                el.style[property] = style[property]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tooltip-html {
    width: 250px;
    padding: 8px 10px;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    position: absolute;
    left: 0;
    top: 37px;
    z-index: 1;
    user-select: none
}
</style>