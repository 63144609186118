<template>
    <div class="bg-white nav-bar">
        <div :class="['wrapper wrapper--big', { 'wrapper--p0': isMobile }]">
            <nav v-if="isMobile" class="nav-mobile">
                <div class="lesson-stats-container">
                    <LearningProgress v-if="!isAdditionalSlide && !loading" />
                    <div class="close-learning-wrapper" @click="handleCloseAction">
                        <img src="@/assets/img/learning/close.svg" alt="" >
                    </div>
                </div>
                <p v-if="!isDefinitionExercise && getCurrentExercise?.type && !isAdditionalSlide" class="exercise-title">{{ $t(`flashcards.exercise.title.${ getCurrentExercise.type.toLowerCase() }`) }}</p>
                <p v-else class="exercise-title"></p>
            </nav>
            <nav v-else class="nav">
                <div v-if="!isAdditionalSlide" class="nav__left">
                    <template>
                        <img class="logo" src="@/assets/img/logo-small.svg" alt="Fluentbe">
                        <p class="title">{{ $t('learning.activities.header.title') }} {{ subject ? ` - ${ subject }` : '' }}</p>
                    </template>
                </div>
                <div class="nav__middle">
                    <LearningProgress v-if="!isAdditionalSlide && !loading" />
                </div>
                <div class="nav__right">
                    <div v-if="showClose" class="close-learning-wrapper" @click="handleCloseAction">
                        <div class="close-learning">{{ $t('learning.activities.header.return-to-space', transUi) }}</div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LearningProgress from '@/components/learning/LearningProgress'

export default {
    name: 'LearningHeader',
    components: {
        LearningProgress
    },
    computed: {
        ...mapState('learning', ['subject', 'slides', 'loading']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('learning', ['isMobile', 'isAdditionalSlide', 'getCurrentSlideType']),
        ...mapGetters('flashcards', ['isDefinitionExercise', 'getCurrentExercise']),
        showClose() {
            return !sessionStorage.getItem('learningPreview') && !this.isGoals && !this.loading
        },
        isGoals() {
            return this.getCurrentSlideType === 'GOALS';
        }
    },
    methods: {
        handleCloseAction() {
            let routeObject = { name: 'learningCoursePlan' };

            const currentRouteParams = this.$route.params;

            if (currentRouteParams && currentRouteParams.coursePlanUuid) routeObject.params = { coursePlanUuid: currentRouteParams.coursePlanUuid };

            this.$router.push(routeObject);
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    justify-content: center;
    height: 79px;
    padding: 16px;
}

.lesson-stats-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.exercise-title {
    color: $gray-900;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.4px;
    min-height: 21px;
}

.nav {
    height: 56px;
    gap: 12px;
    justify-content: space-between;
    display: flex;

    &__left {
        flex: 1;
        height: inherit;
        overflow: hidden;
    }

    &__right {
        margin-left: auto;
        flex: 1;

        .close-learning-wrapper {
            margin-left: auto;
        }
    }

    &__middle {
        max-width: 730px;
        flex: 1;
    }
}

.logo {
    margin-right: 24px;
}

.title {
    @include font-small-12;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-900;

    @media(min-width: $mobile-learning) {
        @include font-h3;
    }
}

.close-learning-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;

    img {
        width: 20px;
        height: 20px;
    }
}

.close-learning {
    @include font-medium;
    color: $primary;
    padding: 7px 13px;
    background: $white;
    border-radius: 6px;
    border: 1px solid $gray-200;
    transition: all .25s;

    &:hover {
        border: 1px solid $gray-300;
    }
}
</style>