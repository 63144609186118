<template>
    <div>
        <div class="item" :class="{'clickable': data.arr.length, 'active': activeAssets === data.type}" @click="changeActiveAssets(data.type)">
            <div class="icon">
                <img :src="activeAssets === data.type ? data.activeIcon : data.icon" alt="Icon">
            </div>
            <div class="info">
                <p class="label">{{data.label}}</p>
                <p class="data">{{checkCount(data.arr.length, data.type)}}{{size}}</p>
            </div>
            <div v-if="data.arr.length" class="switcher">
                <img :src="arrow" alt="Arrow">
            </div>
        </div>
        <div v-if="activeAssets === data.type" class="list" :class="data.type">
            <template v-if="data.type === 'documents'">
                <a v-for="item in data.arr" :href="item.path" :key="item.uuid">{{item.name}}</a>
            </template>
            <template v-if="data.type === 'images'">
                <div v-for="item in data.arr" class="image" @click="showGallery(item)" :key="item.uuid">
                    <div class="thumbnail" :style="`background-image: url('${item.path}')`"></div>
                    <div class="data">
                        <p class="name">{{item.name}}</p>
                        <p class="date">{{formatDate(item.createdAt, true)}}</p>
                    </div>
                </div>
            </template>
            <template v-if="data.type === 'links'">
                <a v-for="item in data.arr" target="_blank" :href="item.path" :key="item.uuid">{{item.name}}</a>
            </template>
        </div>
    </div>
</template>

<script>
import arrow from '../../assets/img/arrow-dark.svg'
import formatDateMixin from '@/mixins/formatDate'

export default {
    name: 'AssetsItem',
    props: ['data', 'activeAssets'],
    data() {
        return {
            arrow
        }
    },
    mixins: [formatDateMixin],
    methods: {
        changeActiveAssets(assets) {
            if(this.data.arr.length) this.$emit('changeActiveAssets', assets)
        },
        showGallery(data) {
            this.$bus.$emit('updateGallery', data)
        },
        checkCount(length, type) {
            const newType = type === 'links' ? 'links' : 'files'
            if(!length) return this.$i18n.t('general.lack')
            else {
                let output
                if(length === 1) output = this.$i18n.t(`messages.${newType}.count.1`)
                else if(length >= 2 && length <= 4) output = this.$i18n.t(`messages.${newType}.count.2-4`)
                else if(length >= 5) output = this.$i18n.t(`messages.${newType}.count.5+`)

                return `${length} ${output}`
            }
        }
    },
    computed: {
        size() {
            if(!this.data.arr.length) return ''
            else {
                let output = 0
                this.data.arr.map(item => output+=item.size)

                return isNaN(output) ? '' : `, ${Math.round(output/1000000)} MB`
            }
        }
    }
}
</script>
