import gql from 'graphql-tag'

export const companyData = gql`
    query($taxNumber: String!) {
        companyData(input: {taxNumber: $taxNumber}) {
            street
            city
            postCode
            countryCode
            companyName
            taxNumber
        }
    }
`