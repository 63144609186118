<template>
    <span class="tool-clear"></span>
</template>

<script>
export default {
    name: "ToolClear",
}
</script>

<style scoped lang="scss">
    .tool-clear {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: $gray-200;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        cursor: pointer;
        
        &:hover {
            background-color: $gray-300;
        }

        &::before {
            content: '';
            background-image: url('~@/assets/img/notify/close.svg');
            background-repeat: no-repeat;
            filter: brightness(100);
            display: inline-block;
            width: 14px;
            height: 14px;
            background-position: bottom;
            background-size: contain;
        }
    }
</style>