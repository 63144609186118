import gql from 'graphql-tag'

export const teacherAIChatMessages = gql`
    query($after: String){
        teacherAI {
            canSendMessages
            chat {
                messages(after: $after) {
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                    edges {
                        cursor
                        node {
                            uuid
                            author
                            content
                            createdAt
                            suggestion {
                                uuid
                                type
                                prompt
                            }
                            game {
                                uuid
                                name
                                gameType {
                                    uuid
                                    type
                                    prompt
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`