<template>
    <div class="help-wrapper" style="position: relative" v-click-outside="hideMenu">
        <img class="help" @click="toggleMenu" src="@/assets/img/ask.svg" alt="Icon">
        <dropdown v-if="showMenu" pos="custom-dropdown not-simple-dropdown center">
            <div class="title">{{ $t('nav.help.title') }}</div>
            <div v-if="$route.params.locale === 'pl' && buttonsToShow.length" class="course-wrapper">
                <span v-for="(button, idx) in buttonsToShow" :key="idx" @click="showVideoModal(button.url)" class="course">{{ $t(button.name) }}</span>
            </div>
            <div class="accordion-wrapper">
                <Accordion @clicked="handleAccordion" :isExpanded="isExpanded" :rotateArrows="true">
                    <template v-slot:header>
                        <div class="accordion__header-content">{{ $t('nav.help.contact-us') }}</div>
                    </template>
                    <template v-slot:content>
                        <div class="content">
                            <div class="info">
                                <span>
                                    {{ $t('nav.help.describe-issue') }}
                                    <div class="email-wrapper">
                                        <span class="email">{{ $t('nav.help.email') }}:</span>
                                        <a class="email-adress" :href="`mailto:${$t('nav.help.email-adress')}`">{{ $t('nav.help.email-adress') }}</a>
                                    </div>
                                </span>
                            </div>
                            <div class="info">
                                <span>
                                    {{ $t('nav.help.talk-to-us') }}
                                    <div class="phone-wrapper">
                                        <span class="phone">{{ $t('nav.help.phone-number.title') }}:</span>
                                        <a class="phone-adress" :href="`tel:${ getPhoneNumber }`">
                                            {{ getPhoneNumber }}
                                        </a>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </template>
                </Accordion>
            </div>
        </dropdown>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';
import Dropdown from '@/components/reusable/Dropdown';
import Accordion from '@/components/reusable/Accordion';
import dropdownMixin from '@/mixins/dropdown';
import videoLinks from '@/mixins/videoLinks';

export default {
    name: 'Help',
    mixins: [dropdownMixin, videoLinks],
    data() {
        return {
            isExpanded: false,
            buttonsToShow: [],
        }
    },
    components: { Accordion, Dropdown },
    directives: { ClickOutside },
    mounted() {
        this.handleButtonsToShow();
    },
    methods: {
        handleButtonsToShow() {
            const addButton = (name, url) => {
                if(this.buttonsToShow.some(el => el.url === url)) return

                this.buttonsToShow.push({ name, url })
            };

            this.activeStudentGroups.some(el => {
                if (!this.isBusiness) {
                    if (el.group.type === 'individual') addButton('nav.help.course-type.ind', this.videos.individual);
                    if (el.group.type === 'shared') addButton('nav.help.course-type.shared', this.videos.shared);
                    if (el.group.type === 'group') addButton('nav.help.course-type.group', this.videos.group);
                    if (['kid', 'teen', 'young_adult'].includes(el.group.type)) addButton('nav.help.course-type.children', this.videos.children);
                } else {
                    if (el.group.type === 'individual') addButton('nav.help.course-type.ind',this.videos.individualCompany);
                    if (el.group.type === 'shared') addButton('nav.help.course-type.shared', this.videos.sharedCompany);
                    if (el.group.type === 'group') addButton('nav.help.course-type.group', this.videos.groupCompany);
                }
            });

            if (this.getChildren.length) addButton('nav.help.course-type.children', this.videos.children);
        },
        handleAccordion() {
            this.isExpanded = !this.isExpanded
        },
        showVideoModal(url) {
            this.hideMenu();
            this.$bus.$emit('setVideoModal', { show: true, videoUrl: url })
        }
    },
    computed: {
        ...mapGetters(['isBusiness', 'getChildren']),
        ...mapState(['activeStudentGroups']),
        getPhoneNumber() {
            const langsWithPhonenumber = ['pl', 'sk', 'cz', 'hu', 'es'];

            return langsWithPhonenumber.includes(this.$route.params.locale)
                ? this.$i18n.t(`nav.help.phone-number.${ this.$route.params.locale }`)
                : this.$i18n.t('nav.help.phone-number.pl')
        }
    }
}
</script>

<style lang="scss" scoped>
    .help-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .help {
        padding: 4px;
        background-color: $gray-300;
        border-radius: 100px;
    }

    .title {
        @include font-small-12;
        color: $gray-600;
        padding: 8px 12px;
        border-bottom: 1px solid $gray-100;
    }

    .course-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: column;
        border-bottom: 1px solid $gray-100;
        padding: 8px 8px 8px 12px;

        .course {
            color: $primary;
            cursor: pointer;
            @include font-medium-text;
        }
    }

    .accordion-wrapper {
        padding: 14px 12px;
    }

    .accordion {
        padding: 0!important;
        flex-direction: column;
        border-bottom: none;

        &__header-content {
            padding: 0px !important;
        }

        ::v-deep {

            &:hover {
                box-shadow: none;
            }

            .accordion__header {
                padding: 0 !important;
                justify-content: center;
                align-items: center;
                color: $gray-700;
            }

            .accordion__arrow {
                width: 16px;
                height: 16px;
                background: $gray-500;
            }

            .accordion__content {
                margin: 0;
                padding: 0;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .info {
                color: $gray-600;
                font-size: 11px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: -0.4px;

                &:first-of-type {
                    margin-top: 8px;
                    max-width: 136px;
                }
                &:last-of-type() {
                    max-width: 156px;
                }
            }

            .email-wrapper, .phone-wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            .email, .phone {
                color: $gray-600;
                font-weight: 500;
            }

            .email-adress, .phone-adress {
                color: $primary;
                margin-left: 2px;
                padding: 0;
            }
        }
    }

    .modal-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 878px;
        min-height: 488px;
        text-align: center;
    }
</style>