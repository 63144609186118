<template>
    <div class="dashboard">
        <div class="column column__1">
            <template v-if="!loading">
                <MobileAppWidget v-if="!isMobileAppInstalled && isMobile" @hide="isMobileAppWidgetHide = true"/>
                <SmartLearning v-if="showSmartLearning" />
                <Student :data="student" :courses="activeStudentGroups"/>
                <Children v-if="children.length" :data="children"/>
                <Parent v-if="showBackToParent && parents.length"/>
            </template>
            <template v-else>
                <Skeleton type="student"/>
            </template>
        </div>
        <div class="column column__2">
            <template v-if="!loading">
                <template v-for="item in upsellBanners">
                    <Upsell 
                        v-if="item.left > 0 || atLeastOneSharedOrGroupCourse"
                        :key="createUpsellKey(item)"
                        :data="item"
                        :single="upsellSingle"
                    />
                </template>
                <NoPackageReminder v-if="showNoPackageReminder"/>

                <Phone v-if="showPhoneWidget"/>

                <Welcome v-if="showWelcome" :data="{ placementTest, oralPlacementTest }"/>
                
                <Guide v-for="(guide, index) in unFinishedGuides" :key="index" :data="guide"/>
                
                <Introduction v-if="showNewStudentWidgets && !showNoPackageReminder && !isIOSApp"/>
                
                <PlatformSetup v-if="platformSetupUrl" :url="platformSetupUrl" />

                <PlacementTest v-if="showPlacementTest" :data="{ placementTest, oralPlacementTest }"/>
                
                <ProgressTest v-for="test in progressTests" :key="test.uuid" :data="test"/>
                
                <FinishedProgressTest v-for="test in finishedProgressTests" :key="test.uuid" :data="test"/>
                
                <NextLesson v-if="nextLesson" :data="nextLesson" />
                
                <PlanLesson v-if="showPlanLesson" :data="bookableLessonsCount"/>
                
                <LastLesson v-if="lastLesson" :lesson="lastLesson.lesson"/>
            </template>
            <template v-else>
                <Skeleton type="lesson"/>
                <Skeleton type="lesson"/>
            </template>
        </div>
        <div class="column column__3">
            <Column3
                :activeStudentGroups="activeStudentGroups" 
                :loading="loading"
                :unFinishedGuides="unFinishedGuides"
                :lastLearningLesson="lastLearningLesson"
            />
        </div>
    </div>
</template>

<script>
import PlacementTest from '@/components/dashboard/widgets/PlacementTest'
import ProgressTest from '@/components/dashboard/widgets/ProgressTest'
import FinishedProgressTest from '@/components/dashboard/widgets/FinishedProgressTest'
import Student from '@/components/dashboard/widgets/Student'
import Children from '@/components/dashboard/widgets/Children'
import Parent from '@/components/dashboard/widgets/Parent'
import NextLesson from '@/components/dashboard/widgets/NextLesson'
import PlanLesson from '@/components/dashboard/widgets/PlanLesson'
import LastLesson from '@/components/dashboard/widgets/LastLesson'
import Upsell from '@/components/reusable/Upsell'
import Phone from '@/components/dashboard/widgets/Phone'
import NoPackageReminder from '@/components/dashboard/widgets/NoPackageReminder'
import Welcome from '@/components/dashboard/widgets/Welcome'
import Introduction from '@/components/dashboard/widgets/Introduction'
import Guide from '@/components/dashboard/widgets/Guide'
import PlatformSetup from '@/components/dashboard/widgets/PlatformSetup'
import MobileAppWidget from '@/components/dashboard/widgets/MobileAppWidget'
import SmartLearning from '@/components/dashboard/widgets/SmartLearning'
import Column3 from '@/components/dashboard/Column3'
import Skeleton from '@/components/dashboard/widgets/Skeleton'
import { dashboard } from '@/graphql/queries/dashboard'
import { guides } from '@/graphql/queries/guides'
import api from '@/graphql/api.js'
import upsellMixin from '@/mixins/upsell'
import changingProfiles from '@/mixins/changingProfiles'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Dashboard',
    components: {
        PlacementTest,
        ProgressTest,
        FinishedProgressTest,
        NoPackageReminder,
        Student,
        Children,
        Parent,
        NextLesson,
        PlanLesson,
        LastLesson,
        Upsell,
        Phone,
        Welcome,
        Introduction,
        Guide,
        PlatformSetup,
        MobileAppWidget,
        SmartLearning,
        Column3,
        Skeleton
    },
    data() {
        return {
            data: null,
            loading: true,
            guides: null,
            isFirstLessonWidget: false,
            isMobileAppWidgetHide: false,
            lastLearningLesson: null
        }
    },
    mixins: [upsellMixin, changingProfiles],
    async created() {
        this.$bus.$on('updateGuide', async () => {
            await this.getGuides()
        })
        this.$bus.$on('updateDashboard', async () => {
            await this.getData()
        })

        await this.getData()

        this.loading = false

        if(this.$route.params.isEmailVerified) {
            this.$notify({
                title: this.$i18n.t('notify.email-verified.title'),
                text: this.$i18n.t('notify.email-verified.subtitle'),
                data: {
                    type: 'success'
                }
            })
        }
    },
    beforeDestroy() {
        this.$bus.$off('updateGuide')
        this.$bus.$off('updateDashboard')
    },
    methods: {
        async getGuides() {
            const {res} = await api(guides)
            if(res) this.guides = res.currentUser.student?.guides
        },
        async getDashboard() {
            const {res} = await api(dashboard, {}, 'dashboard')
            this.data = res.currentUser
            this.packagesGroups = res.currentUser.student.studentPackagesGroups
            this.lastLearningLesson = res.elearningCourseLastLesson

            this.isFirstLessonWidget = !this.lastLesson
                && !this.nextLesson
                && this.getLanguageLevel
        },
        async getData() {
            await Promise.all([
                this.getDashboard(), this.getGuides()
            ])
        }
    },
    computed: {
        ...mapState(['isIOSApp']),
        ...mapGetters([
            'isBusiness',
            'getLanguageLevel',
            'getHavePackage',
            'atLeastOneSharedOrGroupCourse',
            'isMobileAppInstalled'
        ]),
        showNoPackageReminder() {
            return !this.getHavePackage && !this.nextLesson && this.lastLesson && !this.atLeastOneSharedOrGroupCourse
        },
        showPlanLesson() {
            return !this.nextLesson && this.bookingGroups.length && !this.showNoPackageReminder
        },
        student() {
            return this.data
        },
        nextLesson() {
            return this.data.student.nextStudentLesson
        },
        lastLesson() {
            return this.data.student.lastStudentLesson
        },
        activeStudentGroups() {
            return this.data?.student.activeStudentGroups.filter(item => !item.group.isTest)
        },
        children() {
            return this.data.student.children
        },
        parents() {
            return this.data.student.parents
        },
        bookingGroups() {
            return this.activeStudentGroups.filter(item => item.instantBookingUrl)
        },
        bookableLessonsCount() {
            return this.data.student.studentBookableLessonsCount
        },
        unFinishedGuides() {
            return this.guides?.filter(item => !item.isFinished)
        },
        isPlacementTestInGuide() {
            return this.unFinishedGuides?.some(guide => {
                return guide.steps.some(step => ['PLACEMENT_TEST', 'CONTINUE_PLACEMENT_TEST'].includes(step.name))
            })
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        platformSetupUrl() {
            return false
            //return this.data.student.deviceSetup
        },
        showPhoneWidget() {
            return !this.$store.state.user.phone 
                && !this.data.student.studentPackagesGroups.length
        },
        showNewStudentWidgets() {
            return !this.getHavePackage 
                && !this.isBusiness && !this.atLeastOneSharedOrGroupCourse
        },
        showWelcome() {
            return this.showNewStudentWidgets && !this.showNoPackageReminder
        },
        placementTest() {
            return this.data.student.placementTest
        },
        oralPlacementTest() {
            return this.data.student.oralPlacementTest
        },
        showPlacementTest() {
            if(this.showWelcome && (!this.getLanguageLevel || this.oralPlacementTest)) return
            if(this.isPlacementTestInGuide) return
            
            return this.placementTest
        },
        progressTests() {
            return this.data.student.progressTests.filter(({ status }) => ['NEW', 'IN_PROGRESS'].includes(status))
        },
        finishedProgressTests() {
            return this.data.student.progressTests.filter(({ status, isRecent }) => status === 'COMPLETED' && isRecent)
        },
        isMobile() {
            return this.windowWidth < 768
        },
        showSmartLearning() {
            if(!this.isMobile || this.lastLearningLesson) return

            if(this.isMobileAppInstalled) return true

            return this.isMobileAppWidgetHide
        }
    }
}
</script>

<style scoped lang="scss">
    $mobile: 768px;
    $tablet: 1200px;

    .dashboard {
        gap: 0 24px;

        @media(min-width: $mobile) {
            display: grid;
            grid-template-areas: 
                'c1 c3'
                'c2 c3';
            grid-template-columns: 1fr 1fr;
        }

        @media(min-width: $tablet) {
            display: grid;
            grid-template-areas: 
                'c1 c2 c3';
            grid-template-columns: 320px 1fr 1fr;
        }
    }

    .column {
        &__1 {
            grid-area: c1;
        }

        &__2 {
            grid-area: c2;
        }

        &__3 {
            grid-area: c3;
        }

        @media(min-width: $mobile) {
            &__2 {
                grid-row-end: none;

                & > .widget:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>