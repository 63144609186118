<template>
     <div :class="['single-choice', { 'single-choice--completed': isCompleted }]">
        <div class="single-choice__header">
            <RoundedNumber size="large" :character="data.number"/>
            <p class="single-choice__title">{{ data.name }}</p>
        </div>
        <div :class="['single-choice__content', { 'single-choice__content--disabled': disabled }]">
            <Media v-if="data.media?.length" :data="data.media" />
            <div 
                v-for="question in data.questions" 
                :key="question.uuid"
                class="question"
            >
                <div class="question__title">
                    <span 
                        v-for="(part, index) in question.question" 
                        :key="index"
                        :class="[
                            'question__title-part', 
                            { 'question__title-part--text': part, 'question__title-part--blank': !part }
                        ]"
                    >
                        {{ part }}
                    </span>
                </div>
                <div>
                    <Radio
                        v-for="answer in question.answers"
                        :key="answer.uuid"
                        class="question__answer"
                        v-model="formData[data.arrayBased ? 0 : question.uuid]"
                        :data="{
                            name: question.uuid,
                            label: answer.answer,
                            radioValue: answer.uuid,
                            isCorrect: answer.isCorrect,
                            isCompleted
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Radio from '@/components/reusable/Radio'
import Media from '@/components/reusable/Media'
import RoundedNumber from '@/components/reusable/RoundedNumber';

export default {
    name: 'SingleChoice',
    props: ['data', 'value', 'isCompleted'],
    components: {
        Radio,
        Media,
        RoundedNumber
    },
    data() {
        return {
            formData: this.value || (this.data.arrayBased ? [] : {}),
            disabled: false
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(n) {
                this.$emit('input', n)

                if(this.data.arrayBased) {
                    this.disabled = true

                    setTimeout(() => {
                        this.$bus.$emit('multiStepSubmit')
                    }, 0)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$mobile: 768px;

.single-choice {
    &--completed {
        pointer-events: none;
    }

    user-select: none;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;

        @media(min-width: $mobile) {
            flex-direction: row;
            gap: 24px;
        }
    }

    &__title {
        @include font-lead;
        text-align: left;
    }

    &__content {
        @media(min-width: $mobile) {
            padding-left: 56px;
        }

        &--disabled {
            pointer-events: none;
        }
    }
}

.question {
    padding: 0;
    text-align: left;

    &:not(:last-child) {
        margin-bottom: 28px;
    }

    &__title {
        margin-bottom: 8px;
    }

    &__title-part {
        &--text {
            position: relative;
            top: -10px;
            font-size: 18px;
            line-height: 32px;
            font-weight: 400;
        }

        &--blank {
            display: inline-block;
            width: 56px;
            height: 32px;
            background: $gray-100;
            border-radius: 8px;
            margin: -4px 4px 0;

            &:first-child {
                margin-left: 0px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    &__answer {
        margin: 12px 0 0;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;

        &:first-child {
            margin: -8px 0 0;
        }

        &:hover {
            background: $gray-100;
        }
    }

    label.radio {
        padding-left: 52px;
    }
}
</style>