<template>
    <div 
        :data-lector-api-url="lectorCalendarUrl"
        :style="style"
        ref="switchLectorItem"
        :class="['switch-lector-item',
            { 'switch-lector-item--observed': lectorCalendar }
        ]
        ">
        <div 
            class="switch-lector-item__lector-info" 
            data-switch-lector-box-id="info" 
            ref="info"
        >
            <div class="switch-lector-item__lector-photo-wrapper">
                <img v-if="elementInViewport" :src="item.avatar" class="switch-lector-item__lector-photo" alt="Avatar" />

                <span 
                    v-if="item.details.profileVideo && !isSwitchLectorMobile"
                    @click="displayVideo(item.details.profileVideo)"
                    class="badge--video"
                >
                    <img src="@/assets/img/schedule/play-gray.svg" class="badge--video__img" alt="">
                    {{ this.$i18n.t('switch-lector.list.video') }}
                </span>

                <div v-if="item.advanceNotice < 24" class="info-tooltip switch-lector-item__advance-notice">
                    <Tooltip 
                        class="tooltip--right" 
                        :text="$i18n.t('switch-lector.list.filters.less-than-24.tooltip')"
                    />
                    <img src="@/assets/img/schedule/circle-bolt.svg"/>
                </div>
            </div>

            <div class="switch-lector-item__lector-name">
                {{ item.firstName }}

                <span 
                    v-if="item.details.profileVideo && isSwitchLectorMobile"
                    @click="displayVideo(item.details.profileVideo)"
                    class="badge--video"
                >
                    <img src="@/assets/img/schedule/play-gray.svg" class="badge--video__img" alt="">
                    {{ this.$i18n.t('switch-lector.list.video') }}
                </span>
            </div>

            <div class="switch-lector-item__lector-content">
                <LectorInfoRow>
                    <template #header>
                        <img src="@/assets/img/schedule/schoolGraduation.svg" class="mt-3px" alt="">
                        <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-teach')"/>
                    </template>

                    <div>
                        <div 
                            v-for="teach in item.languagesITeach" :key="teach.language.shortName"
                            class="d-flex align-items-center gap-8">
                            <img 
                                :src="getCourseIcon(teach.language.shortName)" 
                                class="switch-lector-item__flag"
                                alt="Course">
                            {{ $i18n.t(`dashboard.lector.${ teach.language.shortName }`) }}
                        </div>
                    </div>
                </LectorInfoRow>

                <LectorInfoRow>
                    <template #header>
                        <img src="@/assets/img/schedule/languagesISpeak.svg" class="mt-5px" alt="">
                        <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-speak')"/>
                    </template>

                    <div class="switch-lector-item__restricted-wrapper">
                        <div v-for="(speak, value) in firstTwoSpeakLanguages" 
                            :key="speak.language.shortName"
                            class="switch-lector-item__lang-item"
                            v-html="handleLanguages(speak, value, firstTwoSpeakLanguages.length)"
                        >
                        </div>

                        <span 
                            v-if="restSpeakLanguage.length"
                            class="switch-lector-item__plus-more-btn">
                            {{ `+${ restSpeakLanguage.length }` }}
                        </span>
                    </div>
                </LectorInfoRow>

                <LectorInfoRow v-if="item.certificates.length && examChoosen">
                    <template #header>
                        <img src="@/assets/img/schedule/write.svg" class="mt-5px" alt="">
                        <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-exams')"/>
                    </template>

                    <div class="switch-lector-item__restricted-wrapper">
                        <span 
                            v-for="(cert, index) in firstCertificate" :key="cert+index"
                            class="switch-lector-item__certificate"
                        >
                            {{ cert }}
                        </span>
                        <template v-if="restCertificates.length">
                            <span 
                                class="switch-lector-item__plus-more-btn"
                            >{{ `+${ restCertificates.length }` }}</span>
                        </template>
                    </div>
                </LectorInfoRow>

                <div v-if="!isSwitchLectorMobile" class="btn-profile-area">
                    <Button 
                        @click.native="goToLectorCard"
                        class="default" 
                        :text="this.$i18n.t('switch-lector.list.go-to-full-profile')"
                        :disabled="!lectorCalendar && Boolean(isDateFilter)"
                    />
                </div>
            </div>
        </div>

        <div 
            :class="[
                'switch-lector-calendar',
                { 'switch-lector-calendar--limit-height': setLimitHeightClass }
            ]" 
            data-switch-lector-box-id="calendar" 
            ref="calendar"
        >
            <template v-if="isSwitchLectorMobile">
                <SwitchLectorSomeFreeSlots
                    v-if="lectorCalendar"
                    :slots="lectorCalendarSlots"
                    :lector="formatedLector"
                    :lectorApiUrl="lectorCalendarUrl"
                    :item="item"
                    :key="item.uuid"
                    @showCalendar="openLectorCalendar"
                />
                <SkeletonCalendarMobile v-else />

                <div class="btn-profile-area">
                    <template v-if="getShowBuyToBook">
                        <a
                            v-if="isStudentAdult"
                            class="btn button primary"
                            :href="getLinkForPayment"
                            @click="setLocalStorageSavedScheduleOnClick"
                        >
                            {{ $t('calendar.blue-overlay.buy-packages') }}
                        </a>

                        <a
                            v-else
                            class="btn secondary button"
                            :href="startUrl"
                            target="_blank"
                        >{{ $i18n.t('course-plan.baner-contact') }}</a>
                    </template>

                    <Button 
                        @click.native="goToLectorCard"
                        class="default" 
                        :text="this.$i18n.t('switch-lector.list.go-to-full-profile')"
                        :disabled="!lectorCalendar && Boolean(isDateFilter)"
                    />
                </div>
            </template>

            <template v-else>
                <SkeletonCalendarDesktop v-if="!lectorCalendar || loadingDelay || !elementInViewport" />

                <template v-else>
                    <SwitchLectorCalendar mode="mini" :slots="lectorCalendarSlots" :item="item" :key="item.uuid" :lector="formatedLector" />

                    <div 
                        @click.stop="showMoreCalendarSlots"
                        :class="['switch-lector-calendar__more',
                            { 'switch-lector-calendar__more--visible': calendarMoreVisible }
                        ]">
                        <div class="switch-lector-calendar__more-btn">
                            {{ !calendarMore ? this.$i18n.t('switch-lector.list.show-more-hours') : this.$i18n.t('switch-lector.list.less') }}
                            <img 
                                :class="[
                                    'switch-lector-calendar__more-btn-arrow',
                                    {'switch-lector-calendar__more-btn-arrow--less': calendarMore } 
                                ]"
                                src="@/assets/img/arrow-blue.svg" alt="">
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SwitchLectorSomeFreeSlots from '@/components/switchLector/SwitchLectorSomeFreeSlots';
import SwitchLectorCalendar from '@/components/switchLector/SwitchLectorCalendar';
import SkeletonCalendarMobile from '@/components/switchLector/SkeletonCalendarMobile';
import SkeletonCalendarDesktop from '@/components/switchLector/SkeletonCalendarDesktop';
import LectorInfoRow from '@/components/switchLector/LectorInfoRow';
import getLinkToOffer from '@/mixins/getLinkToOffer';
import brandAppUrls from '@/mixins/brandAppUrls';
import isStudentAdult from '@/mixins/isStudentAdult';
import handleLectorLangList from '@/mixins/schedule/handleLectorLangList';

import flagMixin from '@/mixins/flag'
import apiUrlForThisLector from '@/mixins/schedule/apiUrlForThisLector';
import isDateBetweenFilterRanges from '@/mixins/schedule/isDateBetweenFilterRanges';
import Tooltip from '@/components/reusable/Tooltip'
import Button from '@/components/reusable/Button';

export default {
    name: 'SwitchLectorItem',
    props: [ 'item' ],
    mixins: [ flagMixin, apiUrlForThisLector, getLinkToOffer, brandAppUrls, isStudentAdult, isDateBetweenFilterRanges, handleLectorLangList],
    components: {
        SwitchLectorSomeFreeSlots,
        SwitchLectorCalendar,
        SkeletonCalendarMobile,
        SkeletonCalendarDesktop,
        LectorInfoRow,
        Tooltip,
        Button
    },
    data() {
        return {
            infoBoxH: 150,
            calendarBoxH: 150,
            calendarMore: false,
            calendarMoreVisible: false,
            forceLimitHeight: true,
            loadingDelay: true,
            elementInViewport: false,
        }
    },
    created() {
        let timeout = 0;

        this.$bus.$on('calendarSlidesDays', ({ lector, height, type }) => {
            if (this.item.uuid !== lector.uuid) return;

            if (this.calendarMore || !type) {
                this.calendarMoreVisible = height > 150;
                return;
            }

            clearTimeout(timeout);
            this.forceLimitHeight = true;

            timeout = setTimeout(() => {
                this.calendarMoreVisible = height > 150;
                this.forceLimitHeight = false;
            }, 400);
        })
    },
    beforeDestroy() {
        this.$bus.$off('calendarSlidesDays');
    },
    mounted() {
        setTimeout(() => {
            this.loadingDelay = false;

            const { info, calendar } = this.$refs;

            const RO = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    if (entry.target.dataset.switchLectorBoxId === 'info') this.infoBoxH = entry.contentRect.height + 32;
                    if (entry.target.dataset.switchLectorBoxId === 'calendar') this.calendarBoxH = entry.contentRect.height;
                })
            })

            if (info) RO.observe(info);
            if (calendar) RO.observe(calendar);
        }, 100);
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapGetters(['getShowBuyToBook']),
        ...mapState('schedule', ['switchLector']),
        ...mapGetters('schedule', ['isDateFilter']),
        windowWidth() {
            return this.$store.state.windowWidth
        },
        formatedLector() {
            const { avatar, firstName, uuid } = this.item;
            return { uuid, name: firstName, avatarUrl: avatar }
        },
        isSwitchLectorMobile() {
            return this.$store.state.windowWidth < 575;
        },
        style() {
            return { height: this.isMobile ? 'auto' : this.getHeighestHeight + 'px' };
        },
        getHeighestHeight() {
            return Math.max.apply(Math, [this.infoBoxH, this.calendarBoxH]);
        },
        lectorCalendar() {
            return this.$store.state.schedule.lectorsCalendars[this.lectorCalendarUrl];
        },
        lectorCalendarSlots() {
            const slots = this.$store.state.schedule.lectorsCalendars[this.lectorCalendarUrl]?.slots;

            if(this.isDateFilter) return slots.filter(slot => this.isDateBetweenFilterRanges(slot.startAt))

            return slots
        },
        setLimitHeightClass() {
            return this.forceLimitHeight || (this.calendarMoreVisible && !this.calendarMore);
        },
        lectorCalendarUrl() {
            return this.apiUrlForThisLector(this.item.uuid);
        },
        firstCertificate() {
            return this.item.certificates.slice(0, 1);
        },
        restCertificates() {
            return this.item.certificates.slice(1);
        },
        firstTwoSpeakLanguages() {
            return this.item.languagesISpeak.slice(0, 2);
        },
        restSpeakLanguage() {
            return this.item.languagesISpeak.slice(2);
        },
        examChoosen() {
            return this.$store.state.schedule.switchLector.selectedSpeciality === 'exam';
        }
    },
    methods: {
        openLectorCalendar() {
            this.$store.commit("schedule/resetSlot");
            this.$store.commit('schedule/setSwitchLectorApiUrl', this.lectorCalendarUrl);
            this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: false });
            this.$store.commit('schedule/setSwitchLectorAnchor', this.lectorCalendarUrl);
            this.$store.state.schedule.queue[this.$store.state.schedule.queue.length - 1] = 'list-click-calendar';
        },
        goToLectorCard() {
            this.$store.commit('schedule/setSwitchLectorAnchor', this.lectorCalendarUrl);
            this.$store.dispatch('schedule/lectorDetailsRequest', { uuid: this.item.uuid })

            this.$gtag({
                category: 'btnClick',
                action: 'teacher_profile_view'
            })
        },
        showMoreCalendarSlots() {
            this.calendarMore = !this.calendarMore;
            this.forceLimitHeight = false;
        },
        displayVideo(url) {
            this.$bus.$emit('setVideoModal', { show: true, videoUrl: url })

            this.$gtag({
                category: 'btnClick',
                action: 'teacher_video_view'
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .switch-lector-item {
        grid-template-columns: 1fr;
        width: 100%;
        min-height: 250px;
        border-radius: 6px;
        border: 1px solid $gray-100;
        display: grid;
        grid-template-rows: max-content;
        overflow-y: hidden;
        margin-bottom: 12px;
        transition: all 0.2s;

        @media(min-width: $tablet-switch-lector) {
            grid-template-columns: 5fr 4fr;
        }
    }

    ::v-deep .switch-lector-item {
        &__level {
            &--mother-tongue {
                color: $gray-900;
                @include font-medium;
                font-weight: 600;
            }
        }
    }

    .switch-lector-item__lector-info {
        padding: 16px 16px 8px;
        border-right: 1px solid $gray-100;
        display: grid;
        grid-template-areas:
            "avatar name"
            "content content";
        grid-template-columns: 64px 1fr;
        column-gap: 16px;

        @media(min-width: $mobile-switch-lector) {
            padding: 16px;
            grid-template-areas:
                "avatar name"
                "avatar content";
            grid-template-rows: inherit;
            grid-template-columns: 96px 1fr;
        }
    }

    .switch-lector-item__lector-photo-wrapper {
        grid-area: avatar;
        position: relative;
    }

    .switch-lector-item__lector-photo {
        width: 64px;
        height: 64px;
        border-radius: 50%;

        @media(min-width: $mobile-switch-lector) {
            width: 96px;
            height: 96px;
        }
    }

    .switch-lector-item__lector-content {
        grid-area: content;
    }

    .switch-lector-item__lector-name {
        grid-area: name;
        color: $gray-900;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        transition: all 0.3s;
        justify-content: center;

        @media(min-width: $mobile-switch-lector) {
            justify-content: flex-start;
            font-size: 24px;
            align-items: center;
            align-items: flex-start;
            margin-bottom: 2px;
        }
    }

    .switch-lector-calendar {
        position: relative;
    }

    .switch-lector-calendar__more {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        bottom: 0;
        z-index: 1;
        position: relative;
        opacity: 0;
        pointer-events: none;

        &.switch-lector-calendar__more--visible {
            opacity: 1;
            pointer-events: initial;
            transition: all 0.2s ease;
        }

        &::before {
            position: absolute;
            display: block;
            content: '';
            top: -7px;
            left: 3%;
            width: 90%;
            height: 7px;
            border-bottom: 1px solid $gray-200 ;

            @media(min-width: $tablet-switch-lector) {
                left: 10%;
                width: 80%;
            }
        }

        &:hover {
            .switch-lector-calendar__more-btn {
                background-color: $primary-light;
                border-radius: 3px;
                text-decoration: none;
            }
        }
    }

    .switch-lector-calendar__more-btn {
        transition: all 0.3s ease;
        text-decoration: underline;
        color: $primary;
        line-height: 28px;
        padding: 0 10px;
        display: flex;
        gap: 5px;
        cursor: pointer;
    }

    .switch-lector-calendar__more-btn-arrow {
        rotate: (90deg);
        transition: all 0.3s ease;
    }

    .switch-lector-calendar__more-btn-arrow--less {
        rotate: (270deg);
    }

    .badge--video {
        display: flex;
        width: fit-content;
        gap: 6px;
        align-items: center;
        border: 1px solid $gray-300;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 16px;
        padding: 0 6px;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 8px;

        &:hover {
            color: $white;
            background-color: $primary;
            border-color: $primary;

            .badge--video__img {
                filter: brightness(3);
            }
        }
    }

    .switch-lector-item__lector-photo-wrapper {
        .badge--video {
            margin: 2px auto 0;
        }
    }

    .badge--video__img {
        height: 10px;
    }

    .btn-profile-area {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;
        @include font-medium;


        .btn {
            padding: 8px 14px;
            max-height: 32px;
        }
    }

    .switch-lector-item__advance-notice {
        width: 22px;
        height: 22px;
        position: absolute;
        top: 42px;
        right: 0px;
        position: absolute;

        @media(min-width: $mobile-switch-lector) {
            width: 26px;
            height: 26px;
            top: 70px;
        }
    }

    .switch-lector-calendar {
        .btn-profile-area {
            padding: 0 16px 16px 16px;

            .btn.default {
                width: 100%;
            }
        }
    }
    
</style>