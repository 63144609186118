<template>
    <div class="translator__langs" v-click-outside="handleClickOutside">
        <p
            :class="['translator__langs-lang', { 'translator__langs-lang--active': translator[type] === lang }]"
            v-for="lang in isSource ? translator.sourceLangs : translator.targetLangs"
            :key="lang"
            @click="isSource ? setSourceLang(lang) : setTargetLang(lang)"
        >
            {{ $i18n.t(`general.language.${ lang }`) }}
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ClickOutside from 'vue-click-outside'

export default {
    name: 'TranslatorLangs',
    props: {
        type: {
            type: String
        }
    },
    mounted() {
        this.popupItem = document.querySelector(this.isSource ? '.tools-pill--source' : '.tools-pill--target')
    },
    computed: {
        ...mapState('tools', ['translator']),
        isSource() {
            return this.type === 'source_lang'
        }
    },
    methods: {
        async setSourceLang(lang) {
            this.$gtag({
                category: 'tool',
                action: 'lang_from',
                label: 'translator',
                value: lang
            })

            if(this.getLang(lang) === this.getLang(this.translator.target_lang)) this.$store.commit('tools/toggleTranslatorLangs')
            else this.$store.commit('tools/setTranslatorSourceLang', lang)

            this.$store.commit('tools/toggleTranslatorSourceLangs')
            await this.$store.dispatch('tools/translate')
        },
        async setTargetLang(lang) {
            this.$gtag({
                category: 'tool',
                action: 'lang_to',
                label: 'translator',
                value: lang
            })

            if(this.getLang(lang) === this.getLang(this.translator.source_lang)) {
                this.$store.commit('tools/setTranslatorLastUsedEnglish', lang)
                this.$store.commit('tools/toggleTranslatorLangs')
            } else this.$store.commit('tools/setTranslatorTargetLang', lang)

            this.$store.commit('tools/toggleTranslatorTargetLangs')
            await this.$store.dispatch('tools/translate')
            this.$store.commit('tools/setEnDialect', { fromTool : 'translator', toLang: lang });
        },
        getLang(lang) {
            return lang.split('-')[0]
        },
        handleClickOutside() {
            const property = this.isSource ? 'showSourceLangs' : 'showTargetLangs'

            this.$store.state.tools.translator[property] = false
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss" scoped>
.translator {
    &__langs {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 24px;
        overflow: auto;
        z-index: 1;
    }

    &__langs-lang {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $gray-900;
        cursor: pointer;
        padding: 4px 8px;
        transition: all .25s;
        border-radius: 6px;

        &:hover, &--active {
            background: $primary-light;
        }
    }
}
</style>