<template>
    <div ref="scheduleTransitionContainer" class="schedule__transition-container">
        <transition :name="transitionName + '-fade'">
            <div class="schedule__transition-item" :key="currentDays[0]?.date">
                <div
                    class="schedule-calendar schedule-calendar--head">
                    <ScheduleCalendarHeader 
                        :date="day.date" 
                        :inactive="day.inactive" 
                        :transitionName="transitionName"
                        :key="`h-${day.date.toString()}`"
                        :slots="day.slots"
                        v-for="day in daysArray"/>
                </div>
                
                <div v-if="slotsExists || mode !== 'default' || isDateFilterScreen"> 
                    <div class="schedule-calendar schedule-calendar--body">
                        <ScheduleCalendarDay 
                            :date="day.date" 
                            :inactive="day.inactive" 
                            :transitionName="transitionName"
                            :key="`b-${day.date.toString()}`"
                            :lector="lector"
                            :slots="day.slots"
                            :markMatchedFilters="['mini', 'mini-mobile'].includes(mode) || (mode === 'default' && isSwitchLectorFlowActive)"
                            v-for="day in daysArray"/>
                    </div>
                </div>

                <ScheduleEmptySlots v-else />
            </div>
        </transition>
    </div>
</template>

<script>
import ScheduleCalendarDay from './ScheduleCalendarDay';
import ScheduleCalendarHeader from './ScheduleCalendarHeader';
import ScheduleEmptySlots from './ScheduleEmptySlots';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'ScheduleCalendarSlide',
    components: { 
        ScheduleCalendarDay, 
        ScheduleCalendarHeader, 
        ScheduleEmptySlots
    },
    props: {
        currentDays: {
            type: Array,
            required: true
        },
        transitionName: {
            type: String
        },
        dayLimits: {
            type: Array
        },
        lector: {
            type: Object
        },
        mode: String
    },

    mounted() {
        const callback = mutationList => {
            for (const mutation of mutationList) {
                if (mutation.type !== 'childList') return;
                if (mutation.target.classList.contains('schedule__transition-container')) {
                    this.$bus.$emit('calendarSlidesDays', {
                        lector: this.lector, 
                        height: mutation.target.querySelector('.schedule-calendar--body')?.getBoundingClientRect().height,
                        type: this.setEventType(mutation)
                    })
                }

                if (mutation.target.classList.contains('schedule-calendar--body')) {
                    this.$bus.$emit('calendarSlidesDays', {
                        lector: this.lector, 
                        height: mutation.target?.getBoundingClientRect().height,
                    })
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(this.$refs.scheduleTransitionContainer, { childList: true, subtree: true });
    },
    computed: {
        ...mapState('schedule', ['slotsExists']),
        ...mapGetters('schedule', ['isSwitchLectorFlowActive', 'isCalendarPreviewMode', 'isDateFilterScreen']),
        daysArray() {
            return this.slotsExists || this.mode !== 'default' || this.isDateFilterScreen
                ? this.currentDays
                : this.createEmptySlotsDays()
        }
    },
    methods: {
        setEventType(mutation) {
            return mutation.removedNodes.length ? 'finish' : 'start';
        },
        createEmptySlotsDays() {
            const darray = []

            for(let i = 0; i < 7; i++) {
                const day = {}
                day.date = this.$moment().add(i, 'days').format('YYYY-MM-DD')
                darray.push(day)
            }

            return darray;
        }
    }
}
</script>

<style lang="scss" scoped>
    .schedule-calendar-wrapper.schedule-calendar-wrapper--empty-slots {
        .schedule-calendar--head {
            grid-template-areas: ". . .";
            grid-template-columns: repeat(3, 1fr);

            @media(min-width: $tablet-switch-lector) {
                grid-template-areas: ". . . . . . .";
                grid-template-columns: repeat(7, 1fr);
            }

            .schedule-calendar__header:nth-child(n + 4) {
                display: none;

                @media(min-width: $tablet-switch-lector) {
                    display: grid;
                }
            } 
        }
    }
</style>