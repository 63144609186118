const APP_MODES = {
    SPACE: {
        type: 'SPACE',
        defaultRoute: 'dashboard',
        urlPrefix: null
    },
    LEARNING: {
        type: 'LEARNING',
        defaultRoute: 'learningCoursePlan',
        urlPrefix: 'elearning'
    }
}

export {
    APP_MODES
}