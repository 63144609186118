import gql from 'graphql-tag'

export const coursePlan = gql`
query ($groupUuid: String!) {
    coursePlan(groupUuid: $groupUuid) {
        hasPackage
        isPresentations

        statistics {
            preLessons {
                completed
                started
                overdue
                total
            }
            homeworks {
                completed
                started
                overdue
                total
            }
            flashcards {
                completed
                overdue
                total
            }
            lessons {
                completed
                total
            }
        }

        studentGroups {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    group {
                        uuid
                        name
                        language {
                            name
                            shortName
                        }
                        languageLevel {
                            name
                            shortName
                        }
                    }
                    studentPackageGroup {
                        language {
                            shortName
                        }
                    }
                    instantBookingUrl
                }
            }
        }

        materials {
            title
            details {
                previewImage
                descriptionData
            }
            lesson {
                uuid
                status
                url
                startAt
                stopAt
                teacher {
                    uuid
                    avatarUrl
                }
            }
            preLesson {
                status
                url
            }
            homework {
                status
                url
            }
            flashcard {
                status
                flashcardGroup {
                    uuid
                }
            }
        }
    }
}

`