<template>
    <div v-if="!loading" :class="['side-content', { 'side-content--mobile': isMobile, 'side-content--no-package': showBaner  }]">
        <div class="progress-widget">
            <div class="progress-header">
                <CircleProgress :percentage="percentage"/>
                <div class="title-wrapper">
                    <span class="title">{{ $t('new-course-plan.side.course-progress') }}</span>
                    <span class="subtitle">{{ $t('new-course-plan.side.all-activities') }}</span>
                </div>
            </div>
            <div class="progress-content">
                <template v-if="!isMobile">
                    <ProgressBar v-for="(barData, index) in progressBarData" :key="index" :data="barData"/>
                </template>
                <div v-else>
                    <Accordion @clicked="handleAccordion" :isExpanded="isExpanded" :rotateArrows="true">
                        <template v-slot:header>
                            <div class="accordion-header-mobile">
                                <div class="title">{{ $t('new-course-plan.side.course-progress') }}</div>
                            </div>
                        </template>
                        <template v-slot:content>
                            <div class="accordion-content-mobile">
                                <ProgressBar v-for="(barData, index) in progressBarData" :key="index" :data="barData"/>
                            </div>
                        </template>
                    </Accordion>
                </div>
            </div>
        </div>
        <NoPackageBaner v-if="!isMobile"/>
    </div>
    <SideSkeleton v-else/>
</template>

<script>
import { mapState } from 'vuex';
import Accordion from '@/components/reusable/Accordion';
import NoPackageBaner from '@/components/coursePlan/NoPackageBaner';
import SideSkeleton from '@/components/coursePlan/skeletons/SideSkeleton';
import ProgressBar from '@/components/coursePlan/ProgressBar';
import CircleProgress from '@/components/reusable/CircleProgress';

export default {
    name: 'SideContent',
    components: {
        NoPackageBaner,
        ProgressBar,
        CircleProgress,
        Accordion,
        SideSkeleton
    },
    data() {
        return {
            isExpanded: false,
            percentage: null
        }
    },
    mounted() {
        this.countTotalExercises();
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('coursePlan', ['loading', 'materials', 'count', 'statistics', 'showBaner']),
        progressBarData() {
            const createData = (titleKey, statsKey, type) => ({
                title: this.$t(titleKey),
                results: {
                    completed: this.statistics[statsKey].completed,
                    started: this.statistics[statsKey]?.started,
                    overdue: this.statistics[statsKey]?.overdue,
                },
                type,
                totalCount: this.statistics[statsKey].total
            });

            let progressBars = [
                createData('new-course-plan.side.lessons', 'lessons'),
                createData('new-course-plan.side.preLessons', 'preLessons'),
                createData('new-course-plan.side.homeworks', 'homeworks'),
            ];

            if(this.$route.params.locale !== 'en') progressBars.push(createData('new-course-plan.side.flashcards', 'flashcards'));

            return progressBars
        },
    },
    methods: {
        handleAccordion() {
            this.isExpanded = !this.isExpanded
        },
        countTotalExercises() {
            const mapTable = ['lessons', 'preLessons', 'homeworks'];

            if(this.$route.params.locale !== 'en') mapTable.push('flashcards');

            const completed = mapTable.map(key => this.statistics[key]?.completed).reduce((sum, item) => sum + item, 0);
            const total = mapTable.map(key => this.statistics[key]?.total).reduce((sum, item) => sum + item, 0);

            this.percentage = Math.round((completed / total) * 100)
        }
    },
    watch: {
        statistics() {
            this.countTotalExercises();
        }
    }
}
</script>

<style lang="scss" scoped>
.side-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    width: 100%;
    max-width: 352px;
    position: sticky;
    top: 96px;

    @media(max-width: 1200px) {
        top: 80px;
    }

    .progress-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $white;
        border-radius: 10px;
    }

    .progress-header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 24px;
        border-bottom: 1px solid $gray-200;
        width: 100%;

        .title-wrapper {
            display: flex;
            flex-direction: column;

            .title {
                @include font-h2;
            }

            .subtitle {
                @include font-regular-text;
                color: $gray-600;
            }
        }
    }

    .progress-content {
        margin: 24px 0;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .accordion-header-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            @include font-medium;
        }
    }

    .accordion-content-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 24px 24px 24px;
        margin-top: 4px;
    }

    &--mobile {
        max-width: initial;
        position: static;
        top: 0;

        .progress-content {
            margin: 0;
            padding: 0;
            width: 100%;

            ::v-deep {
                .accordion__header {
                    padding: 20px 24px 20px;
                }

                .accordion__arrow {
                    width: 20px;
                    height: 20px;
                }

                .accordion__content {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    &--no-package {
        position: static !important;
    }
}
</style>
