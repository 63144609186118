var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fade-transition',{attrs:{"mode":"out-in"}},[(_vm.courses)?_c('div',{staticClass:"action action--courses"},[_c('div',{staticClass:"courses-wrapper"},[(_vm.courses.length)?[_c('p',{staticClass:"courses-area-title"},[_vm._v(_vm._s(_vm.$t('schedule-modal.select-course.your-teachers')))]),_c('div',{staticClass:"courses-area courses-area--lectors"},_vm._l((_vm.sortedCourses),function(item){return _c('div',{key:item.group.uuid,staticClass:"course-box",class:{'course--selected': _vm.isSelected(item.instantBookingUrl) && !_vm.selectedNewLector},on:{"click":function($event){return _vm.selectCourse(item)}}},[_c('div',{staticClass:"course-box__avatar-area"},[_c('img',{staticClass:"course-box__avatar avatar avatar-style",attrs:{"src":_vm.setAvatar(item.group.user.avatarUrl),"width":"32","height":"32","alt":""}}),_c('img',{staticClass:"course-box__flag",attrs:{"src":_vm.getCourseIcon(item.studentPackageGroup.language.shortName),"alt":"Course"}})]),_c('div',{staticClass:"course-box__content-area"},[_c('div',[_c('div',{staticClass:"course-box__name"},[_vm._v(_vm._s(item.group.user.firstName))]),_c('div',{staticClass:"course-box__course-type"},[_vm._v(" "+_vm._s(_vm.$i18n.t(`schedule-modal.select-course.${ item.studentPackageGroup.type }`))+" ")]),_c('div',{staticClass:"course-badges mt-6"},[_c('div',{class:[
                                        'course-badge',
                                        { 'course-badge--blue': item.nextLesson?.startAt && item.nextLesson?.status === 'scheduled' },
                                        { 'course-badge--green': !item.nextLesson && item.lastLesson },
                                        { 'course-badge--yellow course-badge--icon-clock': item.nextLesson?.startAt && item.nextLesson?.status === 'pending' },
                                        { 'course-badge--gray course-badge--icon-error': !item.nextLesson && !item.lastLesson},
                                    ]},[_vm._v(" "+_vm._s(_vm.getLessonLabel(item))+" ")]),(item.studentPackageGroup.duration)?_c('LessonDuration',{attrs:{"duration":item.studentPackageGroup.duration}}):_vm._e()],1)])])])}),0)]:_vm._e(),(_vm.newLectorCourses.length && _vm.courses.length)?_c('div',{staticClass:"courses-area-separator"}):_vm._e(),(_vm.newLectorCourses.length)?[_c('p',{staticClass:"courses-area-title"},[_vm._v(_vm._s(_vm.$t('schedule-modal.select-course.start-new-course')))]),_c('div',{staticClass:"courses-area courses-area--add-new"},_vm._l((_vm.newLectorCourses),function(item){return _c('div',{key:item.key,staticClass:"course-box",on:{"click":function($event){return _vm.selectAddNewLector(item)}}},[_c('div',{staticClass:"course-box__avatar-area"},[_c('div',{staticClass:"course-box__add-icon"},[_c('img',{attrs:{"src":require("@/assets/img/plus-gray-600.svg"),"alt":""}})]),_c('img',{staticClass:"course-box__flag",attrs:{"src":item.flag,"alt":"Course"}})]),_c('div',{staticClass:"course-box__content-area"},[_c('div',{staticClass:"course-box__name"},[_vm._v(" "+_vm._s(_vm.$t(`schedule-modal.select-course.${ item.groupType }`))+" ")]),(_vm.$te(`schedule-modal.select-course.language.${ item.langShortName }`))?_c('div',{staticClass:"course-box__course-type"},[_vm._v(" "+_vm._s(_vm.$t(`schedule-modal.select-course.language.${ item.langShortName }`))+" ")]):_vm._e(),_c('div',{staticClass:"course-badges mt-6"},[(![null, undefined].includes(item.left))?_c('div',{class:[
                                    'course-badge',
                                    { 'course-badge--gray': item.left >= 0 && !item.pendingExists},
                                    { 'course-badge--yellow': item.pendingExists }
                                ]},[_vm._v(" "+_vm._s(_vm.$i18n.t('schedule-modal.select-course.lessons'))+": "+_vm._s(item.left)+" ")]):_vm._e(),(item.duration)?_c('LessonDuration',{attrs:{"duration":item.duration}}):_vm._e()],1)])])}),0)]:_vm._e()],2)]):_c('div',{staticClass:"action action--courses"},[_c('div',{staticClass:"loader-wrapper"},[_c('div',{staticClass:"loader"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }