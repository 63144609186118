var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'msg',
        { 
            'big': _vm.onlyEmoji(_vm.getContent(_vm.data.content)),
            'editable': _vm.isEditable,
            'editing': _vm.editMessage?.uuid === _vm.data.uuid,
            'deleted': _vm.data.isDeleted
        },
        ..._vm.getSenderClasses(_vm.data.sender.role)
    ],attrs:{"data-uuid":_vm.data.uuid}},[_c('p',{staticClass:"author"},[_vm._v(_vm._s(_vm.messageAuthor))]),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"avatar-wrapper"},[_c('img',{staticClass:"avatar avatar-style",attrs:{"src":_vm.setAvatar(_vm.data.sender.avatarUrl),"alt":"Avatar"}}),(['current_user', 'user'].includes(_vm.data.sender.role))?_c('img',{staticClass:"star",attrs:{"src":require("@/assets/img/chat/star.svg"),"alt":"","width":"22","height":"22"}}):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{ref:"text",staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.getContent(_vm.data.content))}}),(!_vm.data.isDeleted)?[(_vm.areFiles)?_c('div',{staticClass:"files"},_vm._l((_vm.data.files),function(item){return _c('a',{key:item.uuid,staticClass:"file",attrs:{"href":item.path}},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"file-icon",attrs:{"src":_vm.fileIcon,"alt":""}}),_vm._v(_vm._s(item.name)+" ")]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":_vm.download,"alt":""}})])])}),0):_vm._e(),(_vm.areImages)?_c('div',{staticClass:"images"},_vm._l((_vm.data.images),function(item){return _c('div',{key:item.uuid,staticClass:"image",style:(`background-image: url('${item.path}');`),on:{"click":function($event){return _vm.showGallery(item)}}})}),0):_vm._e(),(_vm.metadata)?_c('div',{staticClass:"gif"},[_c('img',{staticStyle:{"height":"auto"},attrs:{"src":_vm.metadata.gif.url,"width":_vm.metadata.gif.width,"height":_vm.metadata.gif.height,"alt":""}}),_vm._m(0)]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"footer"},[(_vm.isEditable)?_c('span',{staticClass:"footer-actions"},[_c('span',{staticClass:"delete",on:{"click":_vm.deleteMessage}},[_vm._v(_vm._s(_vm.$i18n.t('messages.delete')))]),_vm._v(" | "),(!_vm.data.metadata)?[_c('span',{staticClass:"edit",on:{"click":_vm.setEditMessage}},[_vm._v(_vm._s(_vm.$i18n.t('messages.edit')))]),_vm._v(" | ")]:_vm._e()],2):_vm._e(),_vm._v(" "+_vm._s(_vm.formatDate(_vm.data.createdAt))+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://giphy.com/","target":"_blank"}},[_c('img',{staticStyle:{"margin":"10px 0 -5px"},attrs:{"width":"135","height":"15","src":require("../../assets/img/giphy-small.png"),"alt":""}})])
}]

export { render, staticRenderFns }