<template>
    <div @click="handleClick" :class="['radio-choice', { 'chosen': chosenUuid === exerciseData.uuid }, getRadioStatus]">
        <input :disabled="isTaskFinished" class="radio" type="radio" :checked="chosenUuid === exerciseData.uuid"/>
        <span class="description">{{ exerciseData.text }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'RadioBtn',
    props: ['exerciseData', 'chosenUuid'],
    methods: {
        handleClick() {
            if (this.isTaskFinished) return;

            this.$emit('clickBtn', this.exerciseData.uuid);
        }
    },
    computed: {
        ...mapGetters('learning', ['isTaskFinished', 'getBackendAnswer']),
        getRadioStatus() {
            if (!this.isTaskFinished) return;

            if (this.getBackendAnswer === this.exerciseData.uuid) return 'radio-choice--correct';
            else if (this.chosenUuid === this.exerciseData.uuid) return 'radio-choice--incorrect';
            return '';
        }
    }
};
</script>

<style scoped lang="scss">
.radio-choice {
    display: flex;
    padding: 12px 16px;
    border: 1px solid $gray-300;
    border-radius: 6px;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    align-items: center;
    gap: 16px;
    min-width: calc(50% - 6px);
    cursor: pointer;

    .radio {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 16px;
        height: 16px;
        border: 2px solid $gray-300;
        border-radius: 100px;
        outline: none;
        position: relative;
        flex-shrink: 0;

        &:checked {
            border: 2px solid $primary;
        }

        &:checked::before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background-color: $primary;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:checked:disabled {
            background-color: transparent;
        }
    }

    .description {
        color: $gray-900;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.4px;
    }

    &--correct {
        border: 1px solid $secondary;

        &.chosen {
            background-color: $secondary-light !important;
        }

        .description {
            color: $secondary !important;
        }

        .radio {
            border: 2px solid $secondary !important;

            &:checked {
                border: 2px solid $secondary !important;
            }

            &:checked::before {
                background-color: $secondary !important;
            }
        }
    }

    &--incorrect {
        border: 1px solid $danger;

        &.chosen {
            background-color: $danger-light !important;
        }

        .description {
            color: $danger !important;
        }

        .radio {
            border: 2px solid $danger !important;

            &:checked {
                border: 2px solid $danger !important;
            }

            &:checked::before {
                background-color: $danger !important;
            }
        }
    }
}
</style>
