<template>
    <div class="status" :class="status">
        <span class="icon"></span>
        <span>{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: 'Pill',
    props: {
        status: {
            required: true,
            type: String
        },
        text: {
            required: true,
            type: String
        },
    }
}
</script>

<style lang="scss" scoped>
.status {
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-h5-uc;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 28px;
    display: inline-flex;

    .icon {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
    }

    &.finished, &.completed {
        background: $secondary-light;
        color: $secondary-dark;

        .icon {
            background-image: url('@/assets/img/singleLesson/finished.svg');
        }
    }

    &.cancelled {
        background: $danger-light;
        color: $danger-dark;

        .icon {
            background-image: url('@/assets/img/singleLesson/cancelled.svg');
        }
    }

    &.scheduled {
        background: $primary-light;
        color: $primary-dark;

        .icon {
            background-image: url('@/assets/img/singleLesson/scheduled.svg');
        }
    }

    &.pending {
        background: $warning-light;
        color: $warning-dark;

        .icon {
            background-image: url('@/assets/img/singleLesson/pending.svg');
        }
    }

    &.in-progress {
        background: $calm-light;
        color: $calm-dark;

        .icon {
            background-image: url('@/assets/img/singleLesson/in-progress.svg');
        }
    }
}
</style>