import { mapGetters, mapState } from 'vuex'
import resetLectorFilterStatus from '@/mixins/schedule/resetLectorFilterStatus';
import { SCHEDULE_SCREEN } from '@/constants/schedule';

const scheduleActionsHandle = {
    mixins: [resetLectorFilterStatus],
    data() {
        return {
            switchViewTimeout: 0
        }
    },
    computed: {
        ...mapState('schedule', [
            'switchLector', 
            'filterDays',
        ]),
        ...mapGetters([
            'isB2CGroupStudent'
        ]),
        ...mapGetters('schedule', [
            'isAdvanceNoticeFilterActive', 
            'isDateFilter'
        ]),
    },
    methods: {
        hideCancelConfirmation() {
            this.$store.commit('schedule/setResponseState', false)
        },
        showCancelConfirmation() {
            this.$store.commit('schedule/setResponseState', 'cancel-confirmation');
        },
        async showCalendar () {
            this.$store.dispatch('schedule/showCalendar');
        },
        async submit () {
            this.$store.dispatch('schedule/submitLesson');
        },
        async cancel() {
            this.$store.dispatch('schedule/cancelLesson');
        },
        async backToCourses() {
            const { courses } = this.$store.state.schedule;
            
            this.$store.commit('schedule/resetSlot');
            this.$store.commit("schedule/switchCoursesView", true);
        },
        backToSwitchLectorList() {
            this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LIST });
            this.$store.commit('schedule/setModalTitle', this.isB2CGroupStudent ? this.$i18n.t('schedule.modal-title.new-individual-course') : false);

            if (this.$store.state.schedule.lectorDetails) {
                this.$store.state.schedule.lectorDetails = null;
            }
        },
        goToSwitchLectorList() {
            this.$store.dispatch('schedule/runSwitchLectorFlow');
            this.backToSwitchLectorList()
        },
        hideScheduleModal() {
            this.$store.commit('schedule/setInitialState');
            this.$store.commit('schedule/hideScheduleModal');
        },
        setupDateFilter() {
            if(this.isAdvanceNoticeFilterActive) {
                this.$store.state.schedule.switchLector.filters['advanceNotice'] = {
                    active: false,
                    value: null
                }
                this.$store.dispatch('schedule/reloadLectorsList') 
            } else {
                this.resetLectorFilterStatus(this.switchLector.data) 
            }

            this.switchLector.anchor = null
            
            this.backToSwitchLectorList()

            if(this.isDateFilter) {
                this.$gtag({
                    category: 'btnClick',
                    action: 'filter_time'
                })
            }
        },
        resetDateFilter() {
            this.switchLector.filters.date.value = []
            this.filterDays.forEach(day => {
                day.slots.forEach(slot => slot.booked = false)
            })
            this.$store.commit('schedule/setModalTitle', this.$i18n.t('modal.book-lesson.header.filter-date', { slotsCount: 0 }))
        }
    }
};

export default scheduleActionsHandle;