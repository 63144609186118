import gql from 'graphql-tag'

export const message = gql`
    {
        uuid
        content
        files {
            uuid
            name
            path
        }
        images {
            uuid
            name
            path
            createdAt
        }
        createdAt
        sender {
            firstName
            lastName
            uuid
            avatarUrl
            role
        }
        metadata
        messagesFromPlatform {
            chatMessages {
                uuid
                content
                createdAt
                sender {
                    firstName
                    lastName
                    uuid
                    avatarUrl
                    role
                }
                files {
                    uuid
                    name
                    path
                    size
                    createdAt
                }
                images {
                    uuid
                    name
                    path
                    size
                    createdAt
                }
                isDeleted
            }
            lesson {
                startAt
                stopAt
                startedAt
                stoppedAt
            }
        }
        isDeleted
    }
`