<template>
    <div class="widget">
        <Button
            class="default"
            @click.native="goBackToParentAccount()"
            :text="$i18n.t('nav.back-to-parent')"
        />
    </div>
</template>

<script>
import changingProfiles from '@/mixins/changingProfiles'
import Button from '@/components/reusable/Button'

export default {
    name: 'Parent',
    components: { Button },
    mixins: [changingProfiles],
}
</script>
