<template>
    <div class="flashcards__order">
        <FlashcardsExercise />
        <transition name="flashcards-activity-hide">
            <DragAndDropFlashcards
                v-if="showActivity"
                :word="getCorrectAnswer"
                :status="getAnswerStatus"
                @input="setAnswer"
                :correctAnswer="correctAnswerToArray"
                :getCorrectAnswer="getCorrectAnswer"
            />
        </transition>
    </div>
</template>

<script>
import FlashcardsExercise from '@/components/flashcards/FlashcardsExercise'
import DragAndDropFlashcards from '@/components/flashcards/DragAndDropFlashcards'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'FlashcardsOrder',
    components: {
        FlashcardsExercise,
        DragAndDropFlashcards
    },
    computed: {
        ...mapState('flashcards', ['previewMode']),
        ...mapGetters('flashcards', ['getCurrentExercise', 'getAnswerStatus', 'getCorrectAnswer', 'showActivity']),
        correctAnswerToArray() {
            return this.previewMode ? this.getCorrectAnswer.split(this.getCorrectAnswer.includes(' ') ? ' ' : '') : []
        }
    },
    methods: {
        setAnswer(value) {
            this.$store.commit('flashcards/setCurrentAnswer', value)
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__order {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .dnd {
        margin-top: auto;
        margin-bottom: 12px;
        padding: 12px 8px 0 8px;
        border-top: 1px solid $gray-100;
        width: 100%;

        @media (min-width: $mobile-flashcards) {
            min-width: 564px;
            position: relative;
            bottom: auto;
            left: auto;
            padding: 12px 0 0 0;
        }

        ::v-deep {
            .dnd-drag-area {
                padding: 0 8px;

                @media (min-width: $mobile-flashcards) {
                    padding: 0;
                }
            }
        }
    }
}
</style>