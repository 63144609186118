<template>
    <div v-if="!loading" class="packages">
        <div v-if="packagesGroups?.length && !$store.getters.isBusiness" class="balance">
            <div class="header">
                <p>{{$i18n.t('account.packages.your-balance')}}</p>
            </div>
            <div class="groups">
                <div v-for="(item, index) in packagesGroups" class="group" :key="index">
                    <div class="course-icon">
                        <img :src="getCourseIcon(item.language.shortName)" alt="Course">
                    </div>
                    <div class="data">
                        <p class="lang">{{$i18n.t(`general.type.${item.type}`)}}</p>
                        <p class="left">{{item.left}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="upsellBanners?.length" class="upsell-banners">
            <Upsell 
                v-for="item in upsellBanners"
                :key="createUpsellKey(item)"
                :data="item"
                :single="upsellSingle"
            />
        </div>
        <h3 class="section-title">{{$i18n.t('account.packages.list.title')}}</h3>
        <List :data="listData" />
    </div>
</template>

<script>
import { studentPackages } from '@/graphql/queries/studentPackages'
import { studentPackagesGroups } from '@/graphql/queries/studentPackagesGroups'
import api from '@/graphql/api.js'
import List from '@/components/reusable/list/List'
import flagMixin from '@/mixins/flag'
import Upsell from '@/components/reusable/Upsell'
import upsellMixin from '@/mixins/upsell'

export default {
    name: 'Packages',
    components: {
        List,
        Upsell
    },
    data() {
        return {
            loading: true,
            listData: {
                filters: [
                    {
                        id: 'type',
                        label: this.$i18n.t('account.packages.list.type')
                    },
                    {
                        id: 'points',
                        label: this.$i18n.t('account.packages.list.points')
                    },
                    {
                        id: 'date',
                        label: this.$i18n.t('account.packages.list.date')
                    },
                    {
                        id: 'remarks',
                        label: this.$i18n.t('account.packages.list.remarks')
                    }
                ],
                items: null,
                type: 'profilePackages',
                perPage: 10
            }
        }
    },
    mixins: [flagMixin, upsellMixin],
    async created() {
        const {res} = await api(studentPackages)
        this.listData.items = res.currentUser.student.studentPackages

        const {res:groups} = await api(studentPackagesGroups)
        this.packagesGroups = groups.currentUser.student.studentPackagesGroups

        this.loading = false
        this.$emit('loaded')
    }
}
</script>

