var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'dictionary__search-bar',
    {
        'dictionary__search-bar--active': _vm.searchActive,
        'dictionary__search-bar--tool-mode': !_vm.showTools && !_vm.contextUrl && !_vm.isMobile,
        'dictionary__search-bar--tool-mode-extended': !_vm.showTools && _vm.contextUrl && !_vm.isMobile
    }
]},[_c('div',{staticClass:"dictionary__search-wrapper"},[_c('LangDropdown',{attrs:{"type":"source_lang","langs":_vm.dictionary.supportedLangs,"chosenLang":_vm.dictionary.chosenLang,"shorter":_vm.isMobile},on:{"setLang":_vm.setLang}}),_c('input',{ref:"searchInput",staticClass:"search-input",attrs:{"type":"text","placeholder":_vm.isMobile ? _vm.$t('tools.dictionary.placeholder.mobile') : _vm.$t('tools.dictionary.placeholder')},domProps:{"value":_vm.dictionary.word},on:{"input":_vm.searchInputHandle,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.dictionary.word && _vm.$store.dispatch('tools/searchDictionaryWord')},"focus":_vm.focusSearchInput,"blur":function($event){_vm.searchActive = false}}})],1),_c('Button',{staticClass:"dictionary__search-btn btn primary",attrs:{"text":_vm.$i18n.t('tools.dictionary.search'),"disabled":!_vm.dictionary.word || _vm.dictionary.word === _vm.dictionary.data?.[0]?.word,"type":"button","loading":_vm.dictionary.loading},nativeOn:{"click":function($event){return _vm.searchClicked.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }