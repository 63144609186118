<template>
    <label :class="['checkbox', { 'checkbox--danger': data.danger }]">
        <span v-html="data.label"></span>
        <input ref="checkbox" type="checkbox" @change="onChange">
        <span :class="[{'radio': data.asRadio}, 'checkmark']">
            <img v-if="!data.asRadio" class="check" src="../../assets/img/check-light.svg" alt="">
        </span>
    </label>
</template>

<script>
export default {
    name: 'Checbkox',
    props: ['data', 'value'],
    mounted() {
        this.setValue()
    },
    watch: {
        value() {
            this.setValue()
        }
    },
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.checked)
        },
        setValue() {
            this.$refs.checkbox.checked = this.value
        }
    }
}
</script>
