<template>
    <Modal @hide="hide">
        <div class="lesson-survey modal-inner">
            <ModalHeader :title="$i18n.t('modal.lesson-survey.header')" @hide="hide"/>
            <div class="action">
                <template v-if="start">
                    <img src="@/assets/img/modals/lessonSurvey/start.svg" alt="" class="icon">
                    <p class="title">{{$i18n.t('modal.lesson-survey.start.title', {name: data.studentName})}}</p>
                    <p class="subtitle">{{$i18n.t('modal.lesson-survey.start.subtitle', {name: data.groupName})}}</p>
                    <Button class="primary" :text="$i18n.t('modal.lesson-survey.btn.start')" @click.native="startSurvey()"/>
                </template>

                <template v-if="showStep">
                    <p class="question">{{getQuestion()}}</p>
                    <div class="rating">
                        <p>{{$i18n.t('modal.lesson-survey.rating.disagree')}}</p>
                        <div class="stars">
                            <img v-for="n in 5" :key="n" @click="rate(n)" :src="getAnswer() >= n ? star : emptyStar" width="32" height="32" alt="">
                        </div>
                        <p>{{$i18n.t('modal.lesson-survey.rating.agree')}}</p>
                    </div>
                    <template v-if="!showRemaining">
                        <Button v-if="lastStep" class="primary" :text="$i18n.t('modal.lesson-survey.btn.send')" :loading="submitLoading" :disabled="!getAnswer()" @click.native="submit()"/>
                        <Button v-else class="primary" :text="$i18n.t('modal.lesson-survey.btn.next')" :disabled="!getAnswer()" @click.native="nextStep()"/>
                    </template>
                    <template v-else>
                        <Button class="primary" :text="$i18n.t('modal.lesson-survey.btn.next')" :disabled="!getAnswer()" @click.native="nextStep(true)"/>
                    </template>
                </template>

                <template v-if="end">
                    <img src="@/assets/img/modals/lessonSurvey/end.svg" alt="" class="icon">
                    <template v-if="!showRemaining">
                        <p class="title">{{$i18n.t('modal.lesson-survey.submitted.title')}}</p>
                        <p class="subtitle">{{$i18n.t('modal.lesson-survey.submitted.subtitle')}}</p>
                        <Button class="primary" :text="$i18n.t('modal.lesson-survey.btn.more')" @click.native="getMore()"/>
                    </template>
                    <template v-else>
                        <p class="title">{{$i18n.t('modal.lesson-survey.submitted-remaining.title')}}</p>
                        <p class="subtitle">{{$i18n.t('modal.lesson-survey.submitted-remaining.subtitle')}}</p>
                        <Button class="primary" :text="$i18n.t('modal.lesson-survey.btn.finish')" @click.native="endSurvey()"/>
                    </template>
                </template>
                <p class="prev-step" :class="{'active': currentStep > 1 && showStep}" @click="currentStep--">{{$i18n.t('modal.lesson-survey.back')}}</p>
            </div>
        </div>
    </Modal>
</template>

<script>
import modalMixin from '@/mixins/modal'
import Button from '@/components/reusable/Button'
import star from "@/assets/img/modals/lessonSurvey/star.svg"
import emptyStar from "@/assets/img/modals/lessonSurvey/empty-star.svg"
import { updateLessonFeedbackSurvey } from '@/graphql/mutations/updateLessonFeedbackSurvey'
import api from '@/graphql/api.js'
import enTranslations from '@/i18n/translations/en'

export default {
    name: 'LessonSurvey',
    props: ['data'],
    components: {
        Button
    },
    mixins: [modalMixin],
    data() {
        return {
            star,
            emptyStar,
            start: true,
            end: false,
            currentStep: null,
            answers: {},
            submitLoading: false,
            showRemaining: false
        }
    },
    methods: {
        startSurvey() {
            this.start = false
            this.currentStep = 1 
        },
        async nextStep(submit) {
            if(submit) {
                await api(updateLessonFeedbackSurvey, this.createMutationBody())
            }
            this.lastStep ? this.end = true : this.currentStep++
        },
        async submit() {
            this.submitLoading = true
            const { error } = await api(updateLessonFeedbackSurvey, this.createMutationBody())

            if(!error) {
                this.$bus.$emit('updateDashboard')
                this.$bus.$emit('updateLesson')
            }

            this.end = true
            this.submitLoading = false
        },
        createMutationBody() {
            return {
                userUuid: this.$store.getters.userUuid,
                groupUuid: this.data.groupUuid,
                lessonUuid: this.data.lessonUuid,
                survey: this.createSurveyData()
            }  
        },
        getMore() {
            this.showRemaining = true
            this.end = false
            this.currentStep = 1
            this.answers = {}
        },
        endSurvey() {
            this.$emit('hide')
        },
        getQuestion() {
            const steps = this.getCurrentSteps()
            return this.$i18n.t(steps.find(({uuid}) => uuid === this.questionUuid).questionKey)
        },
        rate(val) {
            this.answers[this.questionUuid] = val
            this.$forceUpdate()
        },
        getAnswer() {
            return this.answers[this.questionUuid]
        },
        createSurveyData() {
            const output = []

            if(this.showRemaining) {
                output.push({
                    uuid: this.questionUuid,
                    value: this.answers[this.questionUuid]
                })
            } else {
                for(const [key, value] of Object.entries(this.answers)) {
                    output.push({
                        uuid: key,
                        value
                    })
                }
            }

            output.forEach(item => {
                this.$gtag({
                    category: 'lesson',
                    action: 'rated',
                    label: enTranslations[this.getCurrentSteps().find(({ uuid }) => uuid === item.uuid).questionKey],
                    value: item.value
                })
            })

            return output
        },
        getCurrentSteps() {
            return this.showRemaining ? this.remainingSteps : this.steps
        }
    },
    computed: {
        steps() {
            return this.data.selected
        },
        stepsCount() {
            return this.data.selected.length
        },
        lastStep() {
            const steps = this.getCurrentSteps()
            return this.currentStep === steps.length
        },
        remainingSteps() {
            return this.data.remaining
        },
        remainingStepsCount() {
            return this.data.remaining.length
        },
        showStep() {
            return !this.start && !this.end
        },
        questionUuid() {
            const steps = this.getCurrentSteps()
            return steps[this.currentStep-1].uuid
        }
    }
}
</script>
