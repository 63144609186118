<template>
    <ScheduleCalendar 
        v-if="days.length"
        :mode="mode"
        :buttonsOff="true"
        :key="item.uuid"
        :lector="lector"
        :days="days"
    />
</template>

<script>
import ScheduleCalendar from '@/components/schedule/ScheduleCalendar';
import parseSlots from '@/functions/parseSlots';

export default {
    name: 'SwitchLectorCalendar',
    components: {
        ScheduleCalendar
    },
    props: [ 'slots', 'item', 'lector', 'mode' ],
    computed: {
        days() {
            return parseSlots(this.slots)
        }
    }
}
</script>