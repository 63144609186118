<template>
    <div class="translator__header">
        <div class="translator__source">
            <ToolsPill 
                @click.native="$store.commit('tools/toggleTranslatorSourceLangs')"
                :class="['tools-pill--source', { 'tools-pill--active': translator.showSourceLangs }]"
                :lang="translator.source_lang"
                pointer
            />
        </div>
        <div class="translator__target">
            <div 
                :class="[
                    'translator__switch-langs', 
                    { 'translator__switch-langs--reverse': translator.reverseLangs }
                ]" 
                @click="toggleLangs"
            >
            </div>
            <ToolsPill 
                @click.native="$store.commit('tools/toggleTranslatorTargetLangs')"
                :class="['tools-pill--target', { 'tools-pill--active': translator.showTargetLangs }]"
                :lang="translator.target_lang"
                pointer
            />
            <TranslatorFormality v-if="isFormalitySupported" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TranslatorFormality from './TranslatorFormality.vue'
import ToolsPill from '../ToolsPill.vue'

export default {
    name: 'TranslatorHeader',
    components: {
        TranslatorFormality,
        ToolsPill
    },
    computed: {
        ...mapState('tools', ['translator']),
        ...mapGetters('tools', ['isFormalitySupported']),
        source_lang() {
            return this.translator.source_lang
        },
        target_lang() {
            return this.translator.target_lang
        }
    },
    methods: {
        toggleLangs() {
            if(this.translator.duringTranslation) return

            this.$store.commit('tools/toggleTranslatorLangs')
        }
    }
}
</script>

<style lang="scss" scoped>
.translator {
    &__header {
        padding: 6px 16px;
        display: flex;
        align-items: center;
        border-top: 1px solid $gray-200;
    }

    &__source, &__target {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        flex: 1;
    }

    &__target {
        .translator__switch-langs, .tools-pill {
            position: relative;
            left: -18px;
        }
    }

    &__switch-langs {
        @include toolsIcon('exchange');

        &--reverse {
            transform: scaleX(-1);
        }
    }
}
</style>