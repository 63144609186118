<template>
    <div :class="['course-container', { 'course-container--mobile': isMobile }]">
        <div v-if="isMobile" class="course-container__title">{{ $t('new-course-plan.content.your-lessons') }}</div>
        <template v-if="loading">
            <ContentSkeleton v-for="n in 8" :key="n"/>
        </template>
        <template v-else v-for="(material, idx) in getCoursePlanMaterials">
            <Accordion
                v-if="!isMobile"
                @clicked="expandAccordion(idx)"
                :class="{ 'accordion--lessons-planned': idx > nextLessonIdx && idx !== expanded && !showBaner }"
                :rotateArrows="checkCondition(material)"
                :disabled="showBaner && idx >= 3"
                :key="idx"
                coursePlanAccordion
                :data-material-id="idx"
                :isExpanded="expanded === idx"
                :active="expanded === idx"
            >
                <template v-slot:header>
                    <NumberedList :character="idx + 1" size="large" :text="material.title">
                        <template v-slot:content>
                            <div v-if="material.lesson.status !== 'NEW'" class="accordion-title-wrapper">
                                <span class="calendar-icon"></span>
                                <span v-if="material.lesson.startAt" class="description">{{ $moment(material.lesson.startAt).format(timezoneDate('D MMMM (ddd)')) }},  {{ $moment(material.lesson.startAt).format(timezoneDate('HH:mm')) }} - {{ $moment(material.lesson.stopAt).format(timezoneDate('HH:mm')) }}</span>
                                <Pill class="pill" :text="$t(`lessons.status.${ replaceStatusString(material.lesson.status) }`)" :status="material.lesson.status.toLowerCase()"/>
                            </div>
                            <div v-else class="accordion-title-wrapper">
                                <span class="calendar-icon"></span>
                                <span class="not-planned">{{ $t('new-course-plan.content.not-planned') }}</span>
                            </div>
                            <DottedLine v-if="isPresentationBased" :statuses="getStatuses(material)"/>
                        </template>
                    </NumberedList>
                </template>
                <template v-slot:content v-if="isPresentationBased">
                    <div class="text-wrapper">
                        <img v-if="material.details.previewImage" class="lesson-img" :src="material.details.previewImage + '/-/scale_crop/216x216/center/'">
                        <div class="description-wrapper">
                            <span class="title">{{ $t('new-course-plan.content.after-lesson') }}</span>
                            <ul class="list">
                                <li v-for="(description, idx) in material.details.descriptionData" :key="idx" v-html="description"></li>
                            </ul>
                        </div>
                    </div>
                    <ButtonsChain :material="material" :materialId="idx"/>
                </template>
                <template v-if="idx === nextLessonIdx && showBookBtn" v-slot:footer>
                    <div v-if="getBookingUrls.length === 1" class="next-lesson-wrapper">
                        <BookLessonBtn placement="coursePlanButton"/>
                    </div>
                    <div v-else class="next-lesson-wrapper">
                        <BookLessonBtn placement="coursePlanNoPackage"/>
                    </div>
                </template>
            </Accordion>
            <div v-else :key="idx" :class="['lesson-card', {'lesson-card--planned': showBaner ? idx >= 3 : (idx > nextLessonIdx)}]">
                <div
                    @click="redirectToLesson(material.lesson.status.toLowerCase(), material.lesson.uuid, idx)"
                    :class="['card-container',
                        {
                            'card-container--next-lesson': idx === nextLessonIdx,
                            'card-container--disabled': showBaner && idx >= 3 && isPresentationBased,
                        }
                    ]"
                >
                    <div class="text-container">
                        <div class="lesson">
                            <span :class="`status ${ material.lesson.status.toLowerCase() }`"></span>
                            <span class="title">{{ $t('new-course-plan.content.lesson') }} {{ idx + 1 }}</span>
                        </div>
                        <span class="lesson-title">{{ material.title }}</span>
                        <span v-if="material.lesson.startAt" class="date">{{ $moment(material.lesson.startAt).format(timezoneDate('D MMMM (ddd)')) }},  {{ $moment(material.lesson.startAt).format(timezoneDate('HH:mm')) }} - {{ $moment(material.lesson.stopAt).format(timezoneDate('HH:mm')) }}</span>
                        <span v-else class="date date--unavailable">{{ $t('new-course-plan.content.not-planned') }}</span>
                        <DottedLine v-if="isPresentationBased" :statuses="getStatuses(material)" onlyBars/>
                    </div>
                    <span v-if="isPresentationBased" class="arrow"></span>
                </div>
                <template v-if="idx === nextLessonIdx && showBookBtn">
                    <div v-if="getBookingUrls.length === 1" class="next-lesson-wrapper">
                        <BookLessonBtn placement="coursePlanButton"/>
                    </div>
                    <div v-else class="next-lesson-wrapper">
                        <BookLessonBtn placement="coursePlanNoPackage"/>
                    </div>
                </template>
            </div>
        </template>
        <NoPackageBaner v-if="isMobile"/>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import timezoneDate from '@/functions/timezoneDate';
import Accordion from '@/components/reusable/Accordion';
import DottedLine from '@/components/coursePlan/DottedLine';
import ContentSkeleton from '@/components/coursePlan/skeletons/ContentSkeleton';
import ButtonsChain from '@/components/coursePlan/ButtonsChain';
import NumberedList from '@/components/reusable/NumberedList';
import NoPackageBaner from '@/components/coursePlan/NoPackageBaner';
import BookLessonBtn  from '@/components/reusable/BookLessonBtn';
import Pill from '@/components/reusable/Pill'

export default {
    name: 'Content',
    components: {
        Accordion,
        NumberedList,
        Pill,
        DottedLine,
        ButtonsChain,
        NoPackageBaner,
        ContentSkeleton,
        BookLessonBtn
    },
    data() {
        return {
            timezoneDate,
            expanded: null,
            isAtLeastOneArrow: false,
            nextLessonIdx: null
        }
    },
    computed: {
        ...mapState(['isMobile', 'languageLevel']),
        ...mapState('coursePlan', ['showBaner', 'loading', 'isPresentationBased']),
        ...mapGetters(['atLeastOneSharedOrGroupCourse', 'isBusiness']),
        ...mapGetters('coursePlan', ['getCoursePlanMaterials', 'getStudentGroups', 'getBookingUrls', 'getChosenCoursePlan']),
        lastCompletedLessonIdx() {
            return this.getCoursePlanMaterials?.findLastIndex(material => material.lesson.status === 'COMPLETED');
        },
        showBookBtn() {
            const addBusiness = this.getChosenCoursePlan.languageLevel.isBusiness || this.getChosenCoursePlan.isBusiness ? ' BE' : '';
            const coursePlanLangLvl = this.getChosenCoursePlan.languageLevel.shortName + addBusiness;
            const langCondition = !this.languageLevel || this.languageLevel === coursePlanLangLvl;

            return (!this.isBusiness && !this.atLeastOneSharedOrGroupCourse) && langCondition || this.getBookingUrls.length && langCondition
        }
    },
    methods: {
        checkCondition(material) {
            const condition = Boolean(material.preLesson || material.homework || material.preview);

            if(condition) this.isAtLeastOneArrow = true;

            return condition;
        },
        redirectToLesson(status, uuid, idx) {
            if (status === 'new') this.$router.push({ name: 'coursePlanLesson', params: { lessonUuid: idx, fromCoursePlan: true, uuid: this.$router.currentRoute.params.uuid } })
            else this.$router.push({ name: 'singleLesson', params: { uuid, fromCoursePlan: true }})
        },
        expandAccordion(idx) {
            if (this.showBaner && idx >= 3) return;

            if (this.expanded === idx) {
                this.expanded = null;
                return;
            }

            this.expanded = idx;

            if(this.isMobile) return;
            setTimeout(()=> {
                window.scrollTo({
                    top: document.querySelectorAll('.course-container .accordion')[idx]?.offsetTop - 90,
                    behavior: 'smooth'
                });
            }, 500)
        },
        getStatuses(material) {
            const groups = ['preLesson', 'lesson', 'homework'];

            if(this.$route.params.locale !== 'en') groups.push('flashcard');

            return groups.map(el => ({ [el]: material[el]?.status.toLowerCase() }));
        },
        replaceStatusString(status) {
            return status.replace('_', '-').toLowerCase();
        },
        handleScroll() {
            let currentQueryParams = { ...this.$route.query };

            const scheduledLesson = this.getCoursePlanMaterials[this.lastCompletedLessonIdx + 1]?.lesson;
            if (currentQueryParams.materialId) {
                this.expanded = parseInt(this.$route.query.materialId);

                delete currentQueryParams.materialId;

                this.$router.push({ query: currentQueryParams });

                return;
            }
            else if (this.showBaner || this.lastCompletedLessonIdx === -1 && scheduledLesson?.status === 'NEW') this.expanded = 0
            else if (scheduledLesson?.status === 'SCHEDULED' && this.$moment(scheduledLesson.startAt).isSame(this.$moment().startOf('day'), 'day')) this.expanded = this.lastCompletedLessonIdx + 1;
            else this.expanded = this.lastCompletedLessonIdx;

            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelectorAll(`.course-container ${ this.isMobile ? '.lesson-card' : '.accordion' }`)[this.expanded]?.offsetTop - 90,
                    behavior: 'smooth'
                });
            }, 500)
        }
    },
    watch: {
        getCoursePlanMaterials() {
            const newMaterialIdx = this.getCoursePlanMaterials?.findIndex(material => material.lesson.status === 'NEW')
            this.nextLessonIdx = newMaterialIdx !== -1 ? this.nextLessonIdx = this.lastCompletedLessonIdx + 1 || 0 : newMaterialIdx;

            this.$nextTick(() => {
                this.handleScroll();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.course-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    &--mobile {
        gap: 12px;

        .dotted-line {
            margin-top: 12px
        }
    }

    &__title {
        @include font-h2;
        line-height: 25px;
        color: $gray-600;
    }

    .accordion {
        background-color: $white;
        border-radius: 10px;

        ::v-deep {
            .accordion__arrow {
                align-self: center;
                margin-top: 0;
                width: 20px;
                height: 20px;
            }

            .numbered-list {
                padding-bottom: 0;

                .rounded-number {
                    background-color: $white;
                    border: 2px solid $gray-200;
                    min-width: 48px;
                    height: 48px;
                    border-radius: 24px;
                }

                .text {
                    font-weight: 600;
                }
            }

            .accordion__content {
                padding-left: 98px;
            }

            .accordion__header {
                padding: 24px 24px 15px 24px;
            }
        }

        .accordion-title-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;

            .calendar-icon {
                mask-image: url('~@/assets/img/singleLesson/calendar.svg');
                background-color: $gray-500;
                width: 16px;
                height: 16px;
            }

            .description {
                @include font-medium;
                color: $gray-600;
            }

            .pill {
                margin-left: 8px;
            }

            .not-planned {
                text-transform: uppercase;
                @include font-medium;
                color: $gray-600;
            }
        }

        .text-wrapper {
            display: flex;
            gap: 22px;
            margin: 12px 0;

            .lesson-img {
                border-radius: 14px;
                height: 108px;
                width: 108px;
                object-fit: cover;
            }

            .description-wrapper {
                display: flex;
                flex-direction: column;

                .title {
                    @include font-h3;
                }

                .list {
                    @include font-h3;
                    margin-top: 6px;
                    margin-left: 24px;
                    font-weight: 400;
                    color: $gray-600;
                }
            }
        }

        .buttons-chain {
            margin-top: 16px;
        }
    }

    .lesson-card {
        display: flex;
        flex-direction: column;
        border: 1px solid $gray-200;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;

        &--planned {
            opacity: 0.6;
        }

        .card-container {
            display: flex;
            justify-content: space-between;
            padding: 16px;

            &--next-lesson {
                padding: 16px 16px 12px 16px;
            }

            &--disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;

            .lesson {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 6px;

                .status {
                    width: 20px;
                    height: 20px;
                    border-radius: 28px;

                    &.completed {
                        background-color: $secondary;
                        background-image: url('~@/assets/img/coursePlan/check.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &.scheduled {
                        background-color: $primary;
                        background-image: url('~@/assets/img/coursePlan/clock.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    &.new {
                        background-color: $gray-100;
                        background-image: url('~@/assets/img/coursePlan/calendar-cross.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                .title {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 18px;
                    color: $gray-600;
                }
            }

            .lesson-title {
                font-size: 17px;
                font-weight: 600;
                line-height: 22px;
            }

            .date {
                margin-top: 8px;
                color: $gray-600;
                @include font-small-12-regular;

                &--unavailable {
                    text-transform: uppercase;
                }
            }
        }

        .arrow {
            align-self: center;
            width: 16px;
            height: 16px;
            background-image: url('~@/assets/img/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(-90deg);
        }
    }

    .next-lesson-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            width: 100%;
            height: 100%;
            padding: 12px 16px;
            border: none !important;
            border-radius: 0;

            &:hover {
                border: none !important;
            }
        }
    }
}
</style>