<template>
    <div class="widget no-package">
        <div class="no-package__info-wrapper">
            <div class="no-package__info">
                <img class="no-package__icon" src="@/assets/img/info.svg"/>
            </div>
            <div class="no-package__text-block">
                <span class="title">{{ $t('widget.no-package.title') }}</span>
                <span v-if="getStudentCanBook" class="subtitle">{{ $t('widget.no-package.subtitle.change-lector') }}</span>
                <span v-else class="subtitle">{{ $t('widget.no-package.subtitle.buy-packages') }}</span>
            </div>
        </div>
        <div class="no-package__btn-wrapper">
            <template v-if="getStudentCanBook">
                <a
                    class="btn button primary"
                    :href="getLinkForLastEnded"
                >
                    {{ $t('widget.no-package.btn.buy-lessons') }}
                </a>
                <Button
                    @click.native="bookLesson"
                    :loading="bookLoading"
                    class="secondary"
                    :text="$i18n.t('widget.no-package.plan-next-lesson')"
                />

                <BookLessonBtn v-if="getLanguageLevel" placement="changeLectorWidgetButton"/>
            </template>

            <Button
                v-else
                @click.native="$store.dispatch('schedule/showScheduleModal', { forceScreen: SCHEDULE_SCREEN.SPECIALITY })"
                class="primary"
                :text="$t('widget.no-package.book-lesson')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/reusable/Button';
import getLinkToOffer from '@/mixins/getLinkToOffer';
import { SCHEDULE_SCREEN } from '@/constants/schedule';
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'

export default {
    name: 'NoPackageReminder',
    components: { Button, BookLessonBtn },
    data() {
        return {
            bookLoading: false,
            SCHEDULE_SCREEN
        }
    },
    mixins: [getLinkToOffer],
    computed: {
        ...mapGetters(['getStudentCanBook'])
    },
    methods: {
        bookLesson() {
            this.bookLoading = true
            this.$store.dispatch('schedule/showScheduleModal')
            this.bookLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.no-package {
    color: $gray-900;
    position: relative;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &__info-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 16px;
    }

    &__info {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0px;
        background: $gray-100;
        border-radius: 10px;
    }

    &__icon {
        width: 21px;
        height: 21px;
        filter: brightness(0.7);
    }

    &__text-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        flex: 1;

        .title {
            @include font-h3;
            color: $gray-900;
        }

        .subtitle {
            @include font-regular-text;
            color: $gray-600;
            max-width: 315px;
        }
    }

    &__btn-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        .button {
            width: 100%;
        }
    }
}
</style>