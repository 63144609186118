import gql from 'graphql-tag'

export const availableCoursePlans = gql`
query AvailableCoursePlans {
	availableCoursePlans {
		edges {
			node {
				uuid
				name
				language {
					name
					shortName
				}
				languageLevel {
					name
					shortName
				}
			}
		}
	}
}
`