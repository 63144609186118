<template>
    <div class="widget lector">
        <div class="lector__menu" @click="toggleMenu" v-click-outside="hideMenu">
            <img src="@/assets/img/dot-menu.svg" alt="menu">
            <dropdown v-if="showMenu" pos="right">
                <div @click="openLectorDetails">{{ $t('dashboard.lector.dropdown.lector-details')}}</div>
            </dropdown>
        </div>
        <div class="header">
            <div class="header__area" @click="openLectorDetails">
                <img class="avatar avatar-style" width="48" height="48" :src="setAvatar(data.group.user.avatarUrl)" alt="Lector">
                <div class="label">
                    <h3 class="label__title">{{ data.group.user.firstName }}</h3>
                    <p>{{ $t(`dashboard.lector.${ data.group.language.shortName }`) }}</p>
                </div>
            </div>
        </div>
        <div class="courses" v-if="Object.keys(data).length">
            <div class="course">
                <div class="header">
                    <div class="clickable">
                        <div class="course-icon">
                            <img :src="getCourseIcon(data.group.language.shortName)" alt="Course">
                        </div>
                        <div class="label">
                            <h4 class="info">
                                {{ $i18n.t('dashboard.student.lessons') }} {{ $t(`general.type.${ data.group.type }`) }}
                                <LessonDuration v-if="data.studentPackageGroup.duration" :duration="data.studentPackageGroup.duration" />
                            </h4>
                            <p>{{ $t('dashboard.student.your-level') }}: {{ languageLevel(data.group.languageLevel.shortName) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p v-else class="empty-state">{{ $i18n.t('dashboard.student.no-courses') }}</p>
        <div class="buttons">
            <Button :data-uuid="messageGroupUuid" class="default" @click.native="openMessages" :text="$i18n.t('dashboard.lector.message')"/>
            <BookLessonBtn v-if="data.instantBookingUrl" @runEmmit="handleBookLessonBtn()" placement="dashboardLectorButton"/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Button from '@/components/reusable/Button'
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import flagMixin from '@/mixins/flag'
import setAvatarMixin from '@/mixins/setAvatar'
import openBookLessonModal from '@/mixins/schedule/openBookLessonModal';
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'
import LessonDuration  from '@/components/reusable/LessonDuration'

export default {
    name: 'Lector',
    props: ['data'],
    components: { Button, BookLessonBtn, Dropdown, LessonDuration },
    mixins: [setAvatarMixin, openBookLessonModal, flagMixin, dropdownMixin],
    computed: {
        ...mapState({
            loading: state => state.schedule.triggers.dashboardLectorButton.loading
        }),
        ...mapGetters(['getLanguageLevel', 'getHavePackage']),
        messageGroupUuid() {
            return this.data.group.messageGroup.uuid
        }
    },
    methods: {
        openMessages() {
            this.$router.push({ name: 'messages', params: { groupUuid: this.messageGroupUuid }})
        },
        languageLevel(text) {
            const langLvl = text.split(/[ .]+/);

            return langLvl.length === 3 ? `${ langLvl[0] } ${ langLvl[2] }` : langLvl[0];
        },
        handleBookLessonBtn() {
            this.$store.state.schedule.triggers.dashboardLectorButton.loading = true;

            this.openBookLessonModal(this.data);

            this.$gtag({
                category: 'btnClick',
                label: 'Book lesson'
            });
        },
        openLectorDetails() {
            this.$store.dispatch('schedule/showLectorDetailsModal', { 
                lectorUuid: this.data.group.user.uuid,
                groupUuid: this.data.group.uuid,
                allowBooking: !!this.data.instantBookingUrl,
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-state {
    @include font-medium-text;
    margin-bottom: 3px;
    color: $gray-500;
}
.course {
    margin: 12px 0;
    padding-bottom: 12px;

    .header {
        padding-top: 12px;
        display: flex;
        align-items: center !important;

        .clickable {
            display: flex;
            flex: 1;
            position: relative;
            z-index: 1;
            width: 100%;
        }

        .label {
            margin-left: 16px;

            h4 {
                @include font-medium;
                color: $gray-900;
                margin-bottom: 8px;
            }

            p {
                @include font-regular;
                color: $gray-600;
                margin: 0;
            }

            .info {
                flex: 1;
                margin-right: 25px;
                @include font-medium;
                color: $gray-900;
                margin-bottom: 8px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 6px;
            }
        }
    }
}

.lector {
    position: relative;
}

.header__area {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    .label__title {
        transition: all 0.3s;
    }

    &:hover .label__title {
        color: $primary !important;
    }
}

.lector__menu {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
}
</style>
