<template>
    <transition name="fade">
        <div
            v-if="expanded"
            class="expanded"
            @click="closeIfOverlayClicked">
            <div class="expanded__box">
                <button
                    class="expanded__close-btn"
                    @click="close">
                    &times;
                </button>
                <img
                    ref="expandedPhoto"
                    :src="url" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PhotoModal',
    props: ['url', 'expanded'],
    mounted() {
        document.addEventListener('keydown', this.closeOnEscape);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.closeOnEscape);
    },
    methods: {
        close() {
            this.$emit('close');
        },
        closeIfOverlayClicked(event) {
            if (event.target !== this.$refs.expandedPhoto) this.close();
        },
        closeOnEscape(event) {
            if (this.expanded && event.key === 'Escape') {
                this.close();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__close-btn {
        user-select: none;
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: transparent;
        border: none;
        color: #000;
        font-size: 24px;
        cursor: pointer;
    }
}
</style>
>
