<template>
    <div class="flashcards__summary">
        <img :src="require(isMobile ? '@/assets/img/flashcards/confetti-mobile.svg' : '@/assets/img/flashcards/confetti.svg')" alt="">
        <img class="flashcards__summary-check" src="@/assets/img/flashcards/check.svg" alt="">
        <p class="flashcards__summary-title">{{ $t('flashcards.summary.title') }}</p>
        <p class="flashcards__summary-subtitle">
            {{ $t('flashcards.summary.subtitle') }}
            <span class="flashcards__summary-subject">{{ subject }}</span>
        </p>
        <Button
            class="primary"
            :text="$t('flashcards.summary.btn')"
            @click.native="$router.push({ name: 'singleLesson', params: { uuid: $route.params.lessonUuid }})"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'FlashcardsSummary',
    components: {
        Button
    },
    computed: {
        ...mapState('flashcards', ['subject']),
        ...mapGetters('flashcards', ['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.flashcards__summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: -24px;

    @media(min-width: $mobile-flashcards) {
        margin-top: -80px;
    }
}

.flashcards__summary-check {
    margin-top: 32px;
}

.flashcards__summary-title {
    margin: 32px 0 12px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: $gray-900
}

.flashcards__summary-subtitle {
    @include font-regular-text;
    color: $gray-900;
    max-width: 343px;


    @media(min-width: $mobile-flashcards) {
        max-width: auto;
    }
}

.flashcards__summary-subject {
    color: $gray-600;
}

.btn {
    margin: 24px auto 0;
}
</style>