<template>
    <div class="set-account">
        <div class="account-step">
            <label class="avatar-input" for="setAvatar">
                <div class="avatar">
                    <img :src="readyAvatar.src ? readyAvatar.src : checkAvatar" alt="Avatar">
                    <div class="add">
                        <img src="@/assets/img/plus.svg" alt="Add">
                    </div>
                </div>
                <input id="setAvatar" ref="input" type="file" accept="image/*" @change="getImage($event)">
            </label>
            <div v-for="(row, index) in form" :key="index" class="form-row">
                <div v-for="{ name, component, data } in row" :key="name" class="form-item">
                    <component 
                        :is="component"
                        v-model="formData[name]" 
                        :class="{ ...(getValidation(name) && { invalid: getValidation(name) }) }"
                        :data="data"
                    />
                    <p class="invalid-feedback">{{ getValidation(name) }}</p>
                </div>
            </div>
            <div v-if="!isIOSApp" class="form-row">
                <TelInput 
                    :data="{ label: $i18n.t('set-account.form.phone') }"
                    :validation="getValidation('phone')"
                    v-model="formData.phone"
                />
            </div>
        </div>
        <Modal v-if="modal" @hide="hideModal">
            <div class="set-avatar modal-inner">
                <ModalHeader :title="$i18n.t('set-avatar.title')" @hide="hideModal"/>
                <div class="action">
                    <div class="images">
                        <div class="cropping">
                            <div class="ratio-container">
                                <div class="ratio-box">
                                    <img ref="image" :src="avatar" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="destination">
                            <p class="title">{{$i18n.t('set-avatar.preview')}}</p>
                            <img :src="destination" alt="">
                        </div>
                    </div>
                    <div class="controls">
                        <div class="zoom">
                            <p class="title">{{$i18n.t('set-avatar.size')}}</p>
                            <input id="cropper-range" type="range" v-model.number="zoomValue" min="0" :max="zoomMax">
                            <div class="buttons">
                                <button @click="zoom(false)"><img :src="cropperMinus" alt=""></button>
                                <button @click="zoom(true)"><img :src="cropperPlus" alt=""></button>
                            </div>
                        </div>
                        <div class="navs">
                            <p class="title">{{$i18n.t('set-avatar.position')}}</p>
                            <div class="buttons">
                                <button @click="move('top')"><img :src="arrow2" alt="" class="top"></button>
                                <button @click="move('bottom')"><img :src="arrow2" alt="" class="bottom"></button>
                                <button @click="move('left')"><img :src="arrow2" alt="" class="left"></button>
                                <button @click="move('right')"><img :src="arrow2" alt="" class="right"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <Button class="default cancel" :text="$i18n.t('set-avatar.cancel')" @click.native="hideModal"/>
                    <Button class="primary save" :text="$i18n.t('set-avatar.save')" @click.native="saveAvatar"/>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/layout/Modal'
import ModalHeader from '@/components/layout/ModalHeader'
import Button from '@/components/reusable/Button'
import TelInput from '@/components/reusable/TelInput'
import { setAccount as form } from '@/forms'
import { userData } from '@/graphql/queries/userData'
import api from '@/graphql/api.js'
import changeAvatarMixin from '@/mixins/changeAvatar'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'SetAccountForm',
    props: ['customData'],
    components: {
        Modal,
        ModalHeader,
        Button,
        TelInput
    },
    mixins: [changeAvatarMixin],
    data() {
        return {
            form: this.getForm(),
            formData: {
                firstName: this.$store.state.user.firstName,
                lastName: this.$store.state.user.lastName,
                gender: this.$store.state.user.gender,
                phone: this.$store.state.user.phone
            },
            validation: {},
            loading: false,
        }
    },
    created() {
        this.$bus.$on('validateSetAccountForm', data => this.submit(data))
        this.$emit('input', this.formData)
    },
    beforeDestroy() {
        this.$bus.$off('validateSetAccountForm')
    },
    computed: {
        ...mapState(['languageLevel', 'isIOSApp']),
        ...mapGetters(['isStudentFromRegistration']),
    },
    methods: {
        async submit(data) {
            if(!data.error) {
                await this.updateAvatar()

                const {res:user} = await api(userData)
                this.$store.commit('setUser', user.currentUser)
                this.readyAvatar.src = null
                if(!this.isStudentFromRegistration) this.$router.push({ name: 'dashboard' });
            } else {
                this.validation = data.error.find(item => item.message === 'validation')?.extensions.validation

                if(!this.validation) {
                    this.$notify({
                        title: this.$i18n.t('notify.error.title'),
                        text: this.$i18n.t('notify.error.subtitle'),
                        data: {
                            type: 'error'
                        }
                    })
                }
            }
        },
        getValidation(name) {
            const validation = this.validation?.[name]?.[0].message

            return validation && this.$i18n.t(validation)
        },
        getForm() {
            if(this.customData.hideFirstAndLastName) {
                const newForm = [...form]
                newForm.shift()

                return newForm
            }

            return form
        }
    },
    watch: {
        'formData': {
            deep: true,
            handler() {
                this.$emit('input', this.formData)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.set-account {
    text-align: center;
    background: $white;
    width: 672px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .account-step {
        width: 464px;
        max-width: 100%;
        margin: 0 auto;
    }

    .title-section {
        margin-bottom: 24px;
        color: $gray-900;

        .title {
            @include font-h2;
        }
    }

    label {
        display: block;
    }

    .invalid {
        select {
            border-color: $danger !important;
        }
    }

    .form-row {
        display: flex;
        gap: 16px;
    }

    .form-item {
        flex: 1;
    }

    ::v-deep .tel-input {
        margin: 8px 0 0;
    }
    
    @include stickyBottomStepsNav;
}
</style>