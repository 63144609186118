<template>
    <div class="audio-volume">
        <p class="audio-volume__label">{{ label }}</p>
        <div class="audio-volume__parts">
            <div
                v-for="part in parts"
                :key="part" 
                class="audio-volume__part"
                :class="{ 
                    'audio-volume__part--active': 100 / parts * (part - 1) < audioVolume[value],
                    'audio-volume__part--low': lowVolume 
                }"
            >
            </div>
        </div>
        <p
            v-if="value === 'input'"
            class="audio-volume__info"
            :class="{ 'audio-volume__info--low': lowVolume }"
        >
            <img 
                :src="require(`@/assets/img/info-${ lowVolume ? 'warning' : 'secondary' }.svg`)" 
                alt=""
                width="12"
                height="12"
            >
            {{ $t(lowVolume ? 'audio-setup.input.low-volume' : 'audio-setup.input.good-volume') }}
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AudioVolume',
    props: {
        value: {
            type: String
        },
        label: {
            type: String
        }
    },
    data() {
        return {
            parts: 13
        }
    },
    computed: {
        ...mapState('media', ['audioVolume']),
        lowVolume() {
            return this.audioVolume[this.value] < 100 / this.parts * 2
        }
    },
}
</script>

<style lang="scss" scoped>
.audio-volume {
    &__label {
        @include font-small-10;
        text-transform: uppercase;
        color: $gray-600;
        margin-bottom: 4px;
    }

    &__parts {
        display: flex;
        gap: 2px;
    }

    &__part {
        flex: 1;
        height: 4px;
        background: $gray-200;
        transition: all .25s;

        &--active {
            background: $secondary;

            &.audio-volume__part--low {
                background: $warning;
            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 5px;
        @include font-small-10;
        color: $secondary-dark;
        margin: 2px 0 0;

        &--low {
            color: $warning-dark;
        }
    }
}
</style>