import gql from 'graphql-tag'

export const progressTests = gql`
    {
        currentUser {
            student {
                progressTests {
                    uuid
                    status
                    stopAt
                    link
                    language {
                        shortName
                    }
                    languageLevel {
                        shortName
                    }
                }
            }
        }
    }
`