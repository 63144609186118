const WELCOME_MEETING = 'welcomeMeeting';
const PLACEMENT_CONVERSATION = 'placement';

export default {
    computed: {
        printOnboardingTitle() {
            const { name, meta, params, query } = this.$route;

            const type = params.type || query.type

            if (name === 'onboardingMeeting') {
                if (this.isPlacementConversation(type)) return this.$i18n.t('onboarding.meeting.placement-conversation.page-title');
                if (this.isWelcomeMeeting(type)) return this.$i18n.t('onboarding.meeting.hello-meeting.page-title');
                return '';
            }

            if(meta.title) return meta.title;
        }
    },
    methods: {
        isWelcomeMeeting(value = false) {
            const type = value || this.$route.params.type || this.$route.query.type;
            return type === WELCOME_MEETING;
        },
        isPlacementConversation(value = false) {
            const type = value || this.$route.params.type || this.$route.query.type;
            return type === PLACEMENT_CONVERSATION;
        },
    }
}