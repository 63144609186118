<template>
    <div
        :class="[
            'rounded-number',
            { [`rounded-number--${ size }`] : size }
        ]">
            {{ character }}
    </div>
</template>

<script>
export default {
    name: 'RoundedNumber',
    props: {
        character: {
            required: true
        },
        size: {
            type: String,
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
.rounded-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #F2F3F4;
    min-width: 24px;
    height: 24px;
    color: $gray-900;
    @include font-h5-uc;
    font-weight: 600;
    flex-shrink: 0 !important;
    flex: 0 1 auto !important;
    align-self: flex-start;

    &--small {
        min-width: 20px;
        height: 20px;
        @include font-h6-uc;
        font-weight: 600;
    }

    &--large {
        min-width: 32px;
        height: 32px;
        @include font-lead;
        font-weight: 600;
    }
}
</style>
