<template>
    <label class="input check"> <div class="label-data">{{data.label}} <span>{{$i18n.t('general.password-length', {length: passwordLength})}}</span></div>
        <input :type="data.type ? data.type : 'text'" :value="value" @input="onChange">
        <div class="markers">
            <span :class="{weak, medium, good, strong}"></span>
            <span :class="{medium, good, strong}"></span>
            <span :class="{good, strong}"></span>
            <span :class="{good, strong}"></span>
            <span :class="{strong}"></span>
            <span :class="{strong}"></span>
        </div>
    </label>
</template>

<script>
export default {
    name: 'Check',
    props: ['data', 'value'],
    computed: {
        passwordLength() {
            return this.$store.state.passwordLength
        },
        weak() {
            return this.value?.length
        },
        medium() {
            return this.value?.length >= this.passwordLength
        },
        good() {
            return this.value?.length >= this.passwordLength + this.passwordLength/2
        },
        strong() {
            return this.value?.length >= this.passwordLength*2
        }
    },
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.value)
        }
    }
}
</script>