<template>
    <div class="learning__title-wrapper">
        <span class="learning__title" v-html="title"></span>
    </div>
</template>

<script>
export default {
    name: 'LearningTitle',
    props: {
        title: String
    }
};
</script>

<style scoped lang="scss">
.learning__title-wrapper {
    text-align: center;
}

.learning__title {
    @include font-h2;
    color: $gray-900;
    margin-bottom: 16px;
    display: inline-block;
    vertical-align: top;
    text-align: left;

    @media (min-width: $mobile-learning) {
        margin-bottom: 32px;
    }
}
</style>
