var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',[(!_vm.isMobileDevice)?_c('img',{attrs:{"src":require("@/assets/img/progressTest/media-permission.svg"),"alt":""}}):_vm._e()]):(_vm.error)?_c('p',{staticClass:"audio-setup__mic-error"},[_vm._v(_vm._s(_vm.$t('audio-setup.mic-error')))]):_c('div',{staticClass:"audio-setup"},_vm._l((_vm.actions),function(action){return _c('div',{key:action.kind,staticClass:"action"},[_c('div',{staticClass:"action__icon"},[_c('img',{attrs:{"src":action.icon,"alt":""}})]),_c('div',{staticClass:"action__content"},[_c('div',{staticClass:"action__header"},[_c('p',{staticClass:"action__title"},[_vm._v(_vm._s(action.title))])]),_c('div',{staticClass:"action__select-wrapper"},[(!(action.kind === 'audiooutput' && (_vm.isMobileDevice || !_vm.isAudiooutputSupported)))?_c('Select',{attrs:{"data":{
                        label: action.select.label,
                        options: _vm.devices[action.kind].map(el => {
                            return {
                                value: el.deviceId,
                                text: el.label
                            }
                        })
                    }},on:{"change":function($event){action.select.type === 'video' && _vm.setupAudio('select')}},model:{value:(_vm.selectedDevices[action.kind]),callback:function ($$v) {_vm.$set(_vm.selectedDevices, action.kind, $$v)},expression:"selectedDevices[action.kind]"}}):_vm._e(),(action.kind === 'audiooutput')?_c('Button',{staticClass:"btn default sound",attrs:{"text":_vm.$i18n.t('audio-setup.test-sound'),"disabled":_vm.soundIsPlaying},nativeOn:{"click":function($event){return _vm.playSound.apply(null, arguments)}}}):_vm._e()],1),(['audioinput', 'audiooutput'].includes(action.kind))?_c('AudioVolume',{attrs:{"value":action.kind === 'audioinput' ? 'input' : 'output',"label":_vm.$i18n.t(action.kind === 'audioinput' ? 'audio-setup.input-level' : 'audio-setup.output-level')}}):_vm._e()],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }