<template>
    <PlacementTestSkeleton v-if="loading" />
    <div v-else :class="['placement-test-info', { 'placement-test-info--mobile': isMobile, 'new' : !isInProgress }]">
        <img v-if="!isInProgress" class="icon" :src="require(`@/assets/img/learning/coursePlan/placement-test${ isMobile ? '-mobile' : '' }.svg`)">
        <img v-else class="icon write-icon" src="@/assets/img/learning/coursePlan/write.svg">
        <div class="placement-test-info__text-wrapper">
            <span class="title">{{ $t(`learning.course-plan.placement-test.${ isInProgress ? 'in-progress' : 'new' }.title`) }}</span>
            <span class="subtitle">{{ $t(`learning.course-plan.placement-test.${ isInProgress ? 'in-progress' : 'new' }.subtitle`) }}</span>
            <Button @click.native="$router.push({ name: 'placementTest' })" :text="$t(`learning.course-plan.placement-test.${ isInProgress ? 'in-progress' : 'new' }.btn`)" class="primary button"/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PlacementTestSkeleton from '@/components/learning/coursePlan/skeletons/PlacementTestSkeleton';
import Button from '@/components/reusable/Button'
import api from '@/graphql/api.js'
import { dashboard } from '@/graphql/queries/dashboard'

export default {
    name: 'PlacementTestInfo',
    components: { Button, PlacementTestSkeleton },
    data() {
        return {
            data: null,
            loading: true
        }
    },
    async created() {
        await this.getDashboard()
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapGetters(['getLanguageLevel']),
        placementTestStatus() {
            return this.data?.student.placementTest?.status
        },
        oralPlacementTestStatus() {
            return this.data?.student.oralPlacementTest?.status
        },
        isInProgress() {
            return [this.placementTestStatus, this.oralPlacementTestStatus].includes('IN_PROGRESS')
        }
    },
    methods: {
        async getDashboard() {
            const { res } = await api(dashboard, {}, 'dashboard')
            this.data = res.currentUser
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.placement-test-info {
    display: flex;
    padding: 24px;
    gap: 24px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    background-color: $white;
    width: 100%;

    &--mobile {
        flex-direction: column;
        border: 2px solid $primary;
        
        &.new {
            background: linear-gradient(90deg, #FFF 49.77%, #E6F3FF 100%);
        }

        .write-icon {
            width: 40px;
        }
    }

    .icon {
        align-self: flex-start;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 8px;
            @include font-h2;
        }

        .subtitle {
            @include font-regular-text;
        }

        .button {
            margin-top: 16px;
            max-width: fit-content;
        }
    }
}
</style>