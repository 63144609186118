<template>
    <div class="widget student">
        <div :class="['header', { 'header--has-course' : courses.length }]">
            <img :src="setAvatar($store.state.user.avatarUrl)" class="avatar-style" width="72" height="72" alt="Avatar">
            <div class="label">
                <span v-if="$store.getters.isBusiness" class="business">{{ $i18n.t('dashboard.student.business-package') }}</span>
                <h3>{{ data.firstName }} {{ data.lastName }}</h3>
                <p>
                    <template v-if="!hasChildren || (hasChildren && courses.length)">{{ $i18n.t('dashboard.student.student') }}</template>
                    <span v-if="hasChildren && courses.length" class="point"></span>
                    <template v-if="hasChildren">{{ $i18n.t('dashboard.student.parent') }}</template>
                </p>
            </div>
        </div>
        <div class="courses">
            <div class="course">
                <div class="stats">
                    <RemainingLessons v-if="studentIndividualGroups" :data="data.student.studentPackagesGroups"/>
                    <template v-else-if="companyGroup">
                        <div v-if="studentCompanyHasPackage" class="item remaining item--company">
                            <div class="info">
                                <p class="label">{{ $i18n.t('dashboard.student.remaining-lessons') }} <span v-if="showLimit(companyGroup)" class="info-tooltip"><img src="@/assets/img/info.svg" alt=""> <Tooltip :text="`${ $i18n.t('dashboard.student.lessons-limit') }: ${ showLimit(companyGroup) }`"/></span></p>
                                <div class="bar"><span class="bar-inner" :style="`width: ${ calculatePercent(leftLessonsFromAllGroups, companyGroup.studentPackageGroup.quantity) };`"></span></div>
                            </div>
                            <div class="value">
                                {{ leftLessonsFromAllGroups }}
                            </div>
                        </div>
                        <div v-if="companyGroup.group.company" class="company-info">
                            <p>{{ $i18n.t('dashboard.student.paid-by') }}:</p>
                            <p>{{ studentCompany }}</p>
                        </div>
                        <p class="end" v-if="companyGroup.studentPackageGroup.validUntil">{{ $i18n.t('dashboard.student.valid-until') }}: {{ $moment(companyGroup.studentPackageGroup.validUntil).format('DD.MM.YYYY') }}</p>
                    </template>
                    <Frequency/>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Tooltip from '@/components/reusable/Tooltip';
import setAvatarMixin from '@/mixins/setAvatar';
import courseLimitMixin from '@/mixins/courseLimit';
import RemainingLessons from './RemainingLessons';
import Frequency from './Frequency';
import flagMixin from '@/mixins/flag'
import percent from '@/mixins/percent'

export default {
    name: 'Student',
    components: { Tooltip, RemainingLessons, Frequency },
    mixins: [setAvatarMixin, courseLimitMixin, flagMixin, percent],
    props: ['data', 'courses'],
    computed: {
        ...mapState(['user']),
        ...mapGetters(['studentCompany']),
        companyGroups() {
            return this.courses.filter(el => el.group.company)
        },
        companyGroup() {
            return this.companyGroups[0]
        },
        leftLessonsFromAllGroups() {
            let limit = this.user.student.studentLessonsLimit

            this.companyGroups.forEach(el => {
                const { left, quantity } = el.studentPackageGroup

                limit -= quantity - left
            })

            return limit
        },
        hasChildren() {
            return this.data.student.children.length
        },
        studentIndividualGroups() {
            return this.data.student.studentPackagesGroups.length > 0
        },
        studentCompanyHasPackage() {
            return this.companyGroup?.studentPackageGroup?.quantity
        }
    }
}
</script>