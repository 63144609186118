<template>
    <div class="widget children">
        <div
            v-for="(child, idx) in data"
            :class="{ 'children--divider' : idx > 0 }"
            :key="child.uuid"
        >
            <ChildrenContent :data="child"/>
        </div>
    </div>
</template>

<script>
import ChildrenContent from '@/components/dashboard/widgets/ChildrenContent';

export default {
    name: 'Children',
    props: ['data'],
    components: {
        ChildrenContent
    }
}
</script>