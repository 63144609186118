<template>
    <div class="dictionary">
        <DictionarySearch />
        <DictionaryContent views/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DictionaryContent from './DictionaryContent.vue';
import DictionarySearch from './DictionarySearch.vue';

export default {
    name: 'Dictionary',
    components: {
        DictionaryContent,
        DictionarySearch
    },
    props: ['views'],
    created() {
        if(this.$route.name === 'tools') {
            this.forceSearch()
            this.$watch('$route', this.forceSearch)
            this.$watch('dictionary.provider', () => this.$store.dispatch('tools/searchDictionaryWord'))
        }
    },
    computed: {
        ...mapState('tools', ['dictionary'])
    },
    methods: {
        forceSearch() {
            const { word } = this.$route.params

            if(word && word !== this.dictionary.word) {
                this.dictionary.word = word
                if(this.dictionary.provider) this.$store.dispatch('tools/searchDictionaryWord')
            } else if(!word) {
                this.dictionary.word = ''
                this.dictionary.data = []
                this.dictionary.showError = false
                this.dictionary.showLogo = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.dictionary {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow-y: hidden;
    flex: 1;
}

</style>