<template>
    <div 
        ref="input"
        :key="grammar.renderValue"
        :class="['grammar__input', { 'grammar__input--disabled': grammar.loading }]"
        contenteditable="true"
        spellcheck="false"
        :data-placeholder="$t('tools.grammar.placeholder')"
        @input="onInput"
        @blur="onBlur"
        @focus="focusInput"
        @paste="onPaste"
    >
        <GrammarMatch
            v-for="(el, index) in grammar.matches" 
            :key="index"
            :data="el"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GrammarMatch from '@/components/tools/grammar/GrammarMatch'

export default {
    name: 'GrammarInput',
    components: {
        GrammarMatch
    },
    mounted() {
        if(!this.grammar.disableCheck) this.$refs.input.innerHTML = this.grammar.text
        this.focusInput()
    },
    computed: {
        ...mapGetters('tools', ['isGrammarCheckDisabled']),
        ...mapState('tools', ['grammar'])
    },
    methods: {
        onInput(e) {
            if(e.target.innerText === '\n') this.$refs.input.innerHTML = ''

            this.grammar.hideMatches = true
            this.grammar.disableCheck = false
            this.grammar.showMistakes = false
            this.$store.commit('tools/grammarResetActiveMatch')

            setTimeout(() => this.grammar.text = e.target.innerText, 0)
        },
        onBlur() {
            this.grammar.active = false
        },
        focusInput() {
            this.grammar.active = true
            
            const selection = window.getSelection()
            const range = document.createRange()
            selection.removeAllRanges()
            range.selectNodeContents(this.$refs.input)
            range.collapse(false)
            selection.addRange(range)
            this.$refs.input.focus()
        },
        clearInput() {
            this.grammar.text = ''
            this.$refs.input.innerHTML = ''
            this.grammar.matches = []
            this.grammar.showMistakes = false
            this.focusInput()
        },
        onPaste(e) {
            e.preventDefault()
            let text = ''
            if(e.clipboardData || e.originalEvent.clipboardData) {
                text = (e.originalEvent || e).clipboardData.getData('text/plain')
            } 

            if(document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, text)
            } else {
                document.execCommand('paste', false, text)
            }
        },
    },
    watch: {
        'grammar.renderValue': {
            handler() {
                setTimeout(() => {
                    this.$refs.input.scrollTop = this.grammar.scrollTop
                    this.focusInput()
                }, 0)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.grammar__input {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    resize: none;
    padding: 24px 45px 24px 24px;
    outline: none;
    @include font-lead;
    border: 0;
    white-space: pre-wrap;

    &--disabled {
        pointer-events: none;
    }

    &:empty:before {
        content: attr(data-placeholder);
        color: $gray-600;
        cursor: text;
    }
}
</style>