import gql from 'graphql-tag'

export const salePayments = gql`
    {
        currentUser {
            student {
                salePayments {
                    uuid
                    amount
                    currencyCode
                    offerName
                    paidAt
                    invoice {
                        uuid
                        url
                    }
                }
            }
        }
    }
`