<template>
    <div :class="['ask-ai-hint', { 'ask-ai-hint--disabled': disabled, 'ask-ai-hint--active': hint.game }]">
        <div class="ask-ai-hint__content">
            <div>
                <p class="ask-ai-hint__prompt" v-html="hint.prompt"></p>
                <p class="ask-ai-hint__type">{{ $te(`ask-ai.category.${ hint.type }`) ? $t(`ask-ai.category.${ hint.type }`) : snakeCaseToString(hint.type, true) }}</p>
            </div>
            <div class="ask-ai-hint__icon"></div>
        </div>
    </div>
</template>

<script>
import snakeCaseToString from '@/functions/snakeCaseToString'

export default {
    name: 'AskAIHint',
    props: {
        hint: Object,
        disabled: Boolean
    },
    data() {
        return {
            snakeCaseToString
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-hint {
    display: inline-block;
    max-width: 325px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid $gray-200;
    background: $white;
    cursor: pointer;
    transition: all .25s;

    &--disabled {
        cursor: default;
        opacity: .5;
    }

    &--active {
        border-color: $warning-dark !important;

        .ask-ai-hint__icon {
            background: $warning-dark !important;
        }

        &:hover {
            background: $warning-light;

            .ask-ai-hint__prompt, .ask-ai-hint__type {
                color: $warning-dark;
            }
        }
    }

    &:hover {
        border-color: $primary;

        .ask-ai-hint__icon {
            background: $primary;
        }
    }
}

.ask-ai-hint__content {
    display: flex;
    gap: 16px;
}

.ask-ai-hint__prompt {
    @include font-regular;
    line-height: 20px;
    color: $gray-900;

    ::v-deep b, strong {
        font-weight: 500;
    }
}

.ask-ai-hint__type {
    @include font-small-12-regular;
    color: $gray-600;
    margin-top: 6px;
}

.ask-ai-hint__icon {
    width: 23px;
    height: 15px;
    flex-shrink: 0;
    mask-image: url('~@/assets/img/tools/askAI-hint-primary.svg');
    background: $gray-500;
    transition: all .25s;
}
</style>