<template>
    <div 
        :class="['translator__formality', { 'translator__formality--active': showFormality }]" 
        @click="toggleFormality" 
        v-click-outside="hideFormality"
    >
        <div class="translator__formality-header">
            <p class="translator__formality-title">{{ $i18n.t(`tools.translator.formality.${ translator.formality }`) }}</p>
            <div class="translator__formality-arrow"></div>
        </div>
        <dropdown v-if="showFormality" pos="right">
            <div
                v-for="item in formalityOptions"
                :key="item"
                @click="setFormality(item)"
                :class="{ 'active': translator.formality === item }"
            >
                {{ $i18n.t(`tools.translator.formality.${ item }`) }}
            </div>
        </dropdown>
    </div>
</template>

<script>
import Dropdown from '@/components/reusable/Dropdown'
import ClickOutside from 'vue-click-outside'
import { mapState } from 'vuex'

export default {
    name: 'TranslatorFormality',
    components: {
        Dropdown
    },
    data() {
        return {
            showFormality: false,
            formalityOptions: ['less', 'more']
        }
    },
    computed: {
        ...mapState('tools', ['translator'])
    },
    methods: {
        toggleFormality() {
            if(this.translator.duringTranslation) return

            this.showFormality = !this.showFormality
        },
        hideFormality() {
            this.showFormality = false
        },
        setFormality(payload) {
            this.$store.commit('tools/setTranslatorFormality', payload)
            this.hideFormality()
        }
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss" scoped>
.translator {
    &__formality {
        margin-left: auto;
        position: relative;
        padding: 6px 0;
        cursor: pointer;

        &--active {
            .translator__formality-title {
                color: $primary-dark;
            }

            .translator__formality-arrow {
                transform: rotate(270deg);
                background: url('@/assets/img/arrow-blue.svg');
            }
        }
    }

    &__formality-header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__formality-title {
        @include font-medium;
        font-weight: 600;
        color: $gray-900;
        transition: all .25s;
    }

    &__formality-arrow {
        width: 16px;
        height: 16px;
        background: url('@/assets/img/arrow.svg');
        transition: all $arrow-transition-time;
    }
}
</style>