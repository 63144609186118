<template>
    <div
        :class="[
            'dictionary__content',
            { 'dictionary__content--logo' : dictionary.showLogo, 'dictionary__content--data' : dictionary.data?.length }
        ]"
    >
        <div v-if="dictionary.showError" class="dictionary__error">
            <span class="title">{{ $i18n.t('tools.dictionary.not-found') }}</span>
        </div>
        <template v-else v-for="(el, idx) in dictionary.data">
            <div class="dictionary__header" :key="idx">
                <span class="dictionary__separator" v-if="idx > 0"></span>  
                <div class="dictionary__title-wrapper">
                    <span class="title">{{ el.word }}</span>
                    <div class="speakers">
                        <VolumeBtn v-if="el.pronunciation?.url" :link="el.pronunciation?.url"/>
                        <div
                            class="speakers__copy-btn"
                            ref="copyWord"
                            @click="copyWord(idx)"
                        ></div>
                    </div>
                </div>

                <ToolClear v-if="idx === 0" class="clear-btn--dictionary" @click.native="clearDictionaryText" />
            </div>
            <template v-for="[key, value] in Object.entries(el.sections)">
                <div v-if="value?.entries?.length" class="dictionary__content-wrapper" :key="`${ key }-${ uuidv4() }`">
                    <div class="part-of-speach">{{ $i18n.t(`tools.dictionary.${ key }`) }}</div>
                    <div class="definitions">
                        <div
                            class="definitions__wrapper"
                            v-for="(el, idx) in value.entries"
                            :key="idx"
                        >
                            <RoundedNumber :character="idx + 1"/>
                            <div class="definitions__content">
                                <span class="title">{{ el.definition }}</span>
                                <template v-if="el.examples?.length">
                                    <span v-for="(example, key) in el.examples" class="example" :key="uuidv4() + `${ key }`">“{{ example }}”</span>
                                </template>
                                <template v-if="el.synonyms.length">
                                    <span class="synonyms-title">{{ $i18n.t('tools.dictionary.synonyms') }}</span>
                                    <div class="synonyms">
                                        <span
                                            v-for="(synonym, idx) in el.synonyms"
                                            :key="idx"
                                            class="synonym"
                                            @click="handleTag(synonym)"
                                        >
                                            {{ synonym }}
                                        </span>
                                    </div>
                                </template>
                                <template v-if="el.antonyms.length">
                                    <span class="antonyms-title">{{ $i18n.t('tools.dictionary.antonyms') }}</span>
                                    <div class="antonyms">
                                        <span 
                                            v-for="(antonym, idx) in el.antonyms" 
                                            :key="idx" 
                                            class="antonym"
                                            @click="handleTag(antonym)"
                                        >
                                            {{ antonym }}
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <div class="dictionary__oxford-logo-wrapper">
            <div class="dictionary__oxford-logo">
                <img src="@/assets/img/tools/oxford-logo.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import RoundedNumber from '@/components/reusable/RoundedNumber';
import VolumeBtn from '@/components/reusable/VolumeBtn';
import ToolClear from '@/components/tools/ToolClear.vue'
import iconAnimation from '@/mixins/tools/iconAnimation';

import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

export default {
    name: 'DictionaryContent',
    components: { 
        VolumeBtn, 
        ToolClear,
        RoundedNumber
    },
    mixins: [iconAnimation],
    props: {
        views: {
            type: Boolean
        }
    },
    data() {
        return { uuidv4 }
    },
    computed: {
        ...mapState('tools', ['dictionary'])
    },
    methods: {
        async handleTag(word) {
            this.dictionary.word = word;
            this.$store.dispatch('tools/searchDictionaryWord');
        },
        copyWord(idx) {
            navigator.clipboard.writeText(this.dictionary.word);
            this.handleIconAnimation(this.$refs.copyWord[idx], 'speakers__copy-btn--shake', 750);

            this.$gtag({
                category: 'tool',
                action: 'copy',
                label: 'dictionary'
            })
        },
        clearDictionaryText() {
            if(this.$route.name === 'tools') this.$router.push({ name: 'tools', params: { tool: 'dictionary' } })
            else {
                this.dictionary.word = '';
                this.dictionary.data = [];
                this.dictionary.showLogo = true
                this.$bus.$emit('focusSearchDictionaryInput');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dictionary {
    &__content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 50px;
        overflow-y: auto;
        min-width: 100%;
        flex: 1;

        &--logo {
            &:before {
                content: '';
                background: url('~@/assets/img/tools/dictionary.svg');
                background-repeat: no-repeat;
                opacity: 0.2;
                height: 120px;
                width: 120px;
                background-position: center;
                transform: translate(-50%, -50%);
                position: absolute;
                padding:0px;
                top: 50%;
                left: 50%;
            }
        }

        &--data {
            .dictionary__oxford-logo-wrapper {
                position: static;
                flex: 1;
                margin-top: 24px;
            }
        }
    }

    &__separator {
        margin-top: 24px;
        background: $gray-200;
        height: 1px;
        flex: none;
        width: 100%;
    }

    &__header {
        padding: 0 24px;
        display: flex;
        width: 100%;
        gap: 24px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        &:first-of-type {
            margin-top: 18px;
            padding-right: 24px;
        }
    }

    &__title-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 32px;

        .title {
            @include font-h2;
            color: $gray-900;
            text-transform: lowercase;
        }

        .speakers {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            &__copy-btn {
                @include toolsIcon('copy');
            }
        }
    }

    &__content-wrapper {
        padding: 0 24px;
        margin-top: 20px;

        .part-of-speach {
            @include font-h2;
            color: $gray-600;
            margin-bottom: 24px;
            font-style: italic;
        }

        .definitions {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &__wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 24px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .title {
                    @include font-h3;
                    font-weight: 400;
                    color: $gray-900;
                }

                .example {
                    @include font-regular;
                    color: $gray-900;
                }

                .synonyms-title, .antonyms-title {
                    @include font-regular-text;
                    color: $gray-600;
                }

                .synonyms, .antonyms {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 8px;

                    .synonym, .antonym {
                        @include font-regular;
                        background: $gray-100;
                        border-radius: 4px;
                        padding: 4px;
                        color: $gray-900;

                        &:hover {
                            cursor: pointer;
                            background: $gray-200;
                        }
                    }
                }
            }
        }
    }
    
    &__error {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: center;

        .title {
            @include font-h2;
            color: $gray-900;
        }
    }

    &__oxford-logo-wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: $white;
    }

    &__oxford-logo {
        width: 100%;
        border-top: 1px solid $gray-100;

        img {
            display: block;
        }
    }
}

.clear-btn--dictionary {
    position: absolute;
    right: 0;
    top: 0;

    @media(min-width: $mobile-tools) {
        right: 5px;
    }
}

</style>