<template>
    <div class="multiple-choice-exercise">
        <span class="title">
            <span v-html="handleText(task.data.question)"></span>
            <br>
            <span class="description">{{ $t('learning.activities.multiple-choice.info', transUi) }}</span>
        </span>
        <div class="multiple-choice-exercise__container">
            <MultipleBtn
                v-for="(exercise, idx) in task.data.answers"
                :key="idx"
                @clickBtn="clickBtn"
                :exerciseData="exercise"
                :completed="Boolean(task.result)"
                :chosenUuids="chosenUuids"
                :answersUuids="task.result?.data"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MultipleBtn from '@/components/learning/activity/components/MultipleBtn';
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'MultipleChoice',
    props: ['task'],
    components: { MultipleBtn },
    mixins: [handleBoldedText],
    data() {
        return {
            chosenUuids: [],
        }
    },
    created() {
        if(this.isPreviewMode) this.setCorrectAnswersFromStart();
    },
    computed: {
        ...mapGetters('learning', ['isPreviewMode']),
        ...mapState('learningCoursePlan', ['transUi']),
    },
    methods: {
        clickBtn(id) {
            if (this.chosenUuids.includes(id)) {
                const index = this.chosenUuids.indexOf(id);
                if (index > -1) this.chosenUuids.splice(index, 1);
            } else this.chosenUuids = [...this.chosenUuids, id];

            this.$store.commit('learning/setUserAnswer', this.chosenUuids);
        },
        setCorrectAnswersFromStart() {
            this.chosenUuids = this.task.result.data;
        }
    }
};
</script>

<style lang="scss" scoped>
.multiple-choice-exercise {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title, .description {
        @include font-lead;
        margin: 0 0 14px 8px;
        line-height: 25px;
        letter-spacing: -0.4px;
    }

    .description {
        color: $gray-600;
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: -0.4px;
        margin: 0;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 12px;

        @media(min-width: $mobile-learning) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>
