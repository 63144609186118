<template>
    <div class="widget meet-student-space">
        <div class="meet-student-space__main-img"></div>
        <div class="meet-student-space__content-container">
            <div class="text-container">
                <span class="title">{{ $t('dashboard.meet-student-space.title') }}</span>
                <span class="subtitle">{{ $t('dashboard.meet-student-space.subtitle') }}</span>
            </div>
            <Button
                @click.native="handleLinkBtn"
                class="button primary"
                :text="$t('dashboard.meet-student-space.btn')"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/reusable/Button'
import videoLinks from '@/mixins/videoLinks';

export default {
    name: 'MeetStudentSpace',
    props: ['unFinishedGuides'],
    mixins: [videoLinks],
    components: { Button },
    data() {
        return {
            bookLoading: false
        }
    },
    computed: {
        ...mapGetters(['isBusiness', 'getChildren']),
    },
    methods: {
        handleLinkBtn() {
            this.unFinishedGuides[0]?.types.some(el => {
                if (!this.isBusiness) {
                    if (el === 'individual') return this.emitVideoUrl(this.videos.individual);
                    if (el === 'shared') return this.emitVideoUrl(this.videos.shared);
                    if (el === 'group') return this.emitVideoUrl(this.videos.group);
                    if (['kid', 'teen', 'young_adult'].includes(el)) return this.emitVideoUrl(this.videos.children);
                } else {
                    if (el === 'individual') return this.emitVideoUrl(this.videos.individualCompany);
                    if (el === 'shared') return this.emitVideoUrl(this.videos.sharedCompany);
                    if (el === 'group') return this.emitVideoUrl(this.videos.groupCompany);
                }
            })
        },
        emitVideoUrl(url) {
            return this.$bus.$emit('setVideoModal', { show: true, videoUrl: url })
        }
    }
}
</script>

<style lang="scss" scoped>
.meet-student-space {
    display: flex;
    flex-direction: column;
    min-height: 353px;
    padding: 0 !important;
    border-radius: 10px !important;

    &__main-img {
        background-image: url('~@/assets/img/dashboard/step1.png');
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 192px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        padding: 16px 24px 13px 16px;
        background-image: url('~@/assets/img/dashboard/meet-student-space-bg.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        min-height: 161px;
        border-bottom-right-radius: 10px;
        position: relative;

        .text-container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .title {
                @include font-h3;
            }

            .subtitle {
                @include font-regular-text;
                color: $gray-600;
            }
        }

        .button {
            margin-top: 12px;
            max-width: fit-content;
            align-self: flex-end;
        }
    }
}
</style>