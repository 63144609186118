<template>
    <div class="single-lesson">
        <Skeleton v-if="loading" :showChat="showDesktopView"/>
        <template v-else>
            <div class="lesson-data">
                <div v-if="startInfo || buyInfo || joinLessonUrl" class="info" :class="[lesson.status, {'active': joinLessonUrl}]">
                    <template v-if="startInfo"><img class="icon" src="@/assets/img/warning-light.svg" alt=""><p>{{$i18n.t('single-lesson.start-info')}}</p></template>
                    <template v-if="joinLessonUrl"><img class="icon" src="@/assets/img/warning-light.svg" alt=""><p>{{$i18n.t('single-lesson.active-info')}}</p></template>
                    <template v-if="buyInfo"><img class="icon" src="@/assets/img/warning-dark.svg" alt=""><p>{{$i18n.t('single-lesson.buy-info')}}</p></template>
                </div>
                <div class="top tab" :class="{ 'no-radius': startInfo || buyInfo && !canRate, 'active': joinLessonUrl }">
                    <Header :lesson="lesson"/>
                    <div v-if="!['finished', 'cancelled'].includes(lesson.status) || canRate" class="buttons">
                        <span v-if="['scheduled', 'pending'].includes(lesson.status) && !showDesktopView" class="reload-info">{{ $t('lesson.reload-info') }}</span>
                        <Button
                            class="default cancel"
                            v-if="showAction && lesson.studentLesson.rescheduleLessonUrl"
                            @click.native="openCancelLessonModal(lesson.studentLesson.rescheduleLessonUrl)"
                            :text="$i18n.t('single-lesson.cancel-lesson')"
                        />
                        <span v-if="['scheduled', 'pending'].includes(lesson.status) && showDesktopView" class="reload-info">{{ $t('lesson.reload-info') }}</span>
                        <Button 
                            v-else-if="canRate"
                            class="secondary canRate"
                            :loading="surveyLoading"
                            @click.native="setLessonSurvey()"
                            :text="$i18n.t('single-lesson.rate')"
                        />
                        <Button 
                            v-else-if="rated"
                            class="rated"
                            :icon="require('@/assets/img/completed.svg')"
                            :text="$i18n.t('single-lesson.rated')"
                        />
                        <Button
                            v-if="!showDesktopView"
                            class="btn default"
                            @click.native="$router.push({ name: 'messages', params: { groupUuid: lesson.group.messageGroup.uuid }})"
                            :text="$i18n.t('single-lesson.write-message')"
                        />
                        <a
                            v-if="!['finished', 'cancelled'].includes(lesson.status)"
                            class="btn primary join"
                            :class="{'disabled': !joinLessonUrl}"
                            :href="joinLessonUrl" target="_blank"
                            @click="joinLessonHandle"
                        >
                            <img class="icon" src="@/assets/img/cam.svg" alt=""> {{$i18n.t('single-lesson.start')}}
                        </a>
                    </div>
                </div>
                <TeacherFeedback v-if="lesson.teacherFeedback" :lesson="lesson" />
                <div v-if="showSummary" class="summary tab">
                    <summary-section
                        class="mt-0"
                        v-if="showDetails" 
                        :icon="require('@/assets/img/lesson-details.svg')"
                        :title="$i18n.t('single-lesson.lesson-details')"
                    >
                        <template v-slot:content>
                            <SummaryItem 
                                v-if="lesson.materialLink?.url"
                                :title="$i18n.t('single-lesson.materials')"
                                :subtitle="lesson.lessonSubjects[0]?.subject || $i18n.t('single-lesson.materials.subtitle')"
                                :text="$i18n.t('single-lesson.materials.btn')"
                                :type="PDF_TYPE.LESSON"
                                :state="lesson.materialLink.state || MATERIAL_STATES.NOT_STARTED"
                                :materialUuid="lesson.materialLink.materialUuid"
                                :btns="[{
                                    link: lesson.materialLink.url,
                                    type: MATERIAL_TYPE.LESSON,
                                    state: lesson.materialLink.state || MATERIAL_STATES.NOT_STARTED
                                }]"
                            />
                            <SummaryItem
                                v-for="item in prelesson"
                                :key="item.url"
                                :title="$i18n.t('single-lesson.prelesson')"
                                :subtitle="item.name"
                                :text="$i18n.t('single-lesson.prelesson.btn')"
                                :type="PDF_TYPE.PRELESSON"
                                :materialUuid="item.materialUuid"
                                :state="item.state || MATERIAL_STATES.NOT_STARTED"
                                :btns="[{
                                    link: item.url,
                                    type: MATERIAL_TYPE.PRELESSON,
                                    state: item.state || MATERIAL_STATES.NOT_STARTED
                                }]"
                            />
                            <SummaryItem
                                v-for="item in homework"
                                :key="item.url"
                                :title="$i18n.t('single-lesson.homework')"
                                :subtitle="item.name"
                                :text="$i18n.t('single-lesson.homework.btn')"
                                :type="PDF_TYPE.HOMEWORK"
                                :materialUuid="item.materialUuid"
                                :state="item.state || MATERIAL_STATES.NOT_STARTED"
                                :btns="[{
                                    link: item.url,
                                    type: MATERIAL_TYPE.HOMEWORK,
                                    state: item.state || MATERIAL_STATES.NOT_STARTED
                                }]"
                            />
                            <SummaryItem
                                v-for="group in flashcardsGroups"
                                :key="group.uuid"
                                :title="$i18n.t('single-lesson.flashcards')"
                                :subtitle="flashcardsSubject(group.name)"
                                :type="PDF_TYPE.FLASHCARDS"
                                :state="group.status"
                                :btns="flashcardsBtns(group)"
                                :data="{
                                    flashcardsWordsLeft: flashcardsWordsLeft(group)
                                }"
                            />
                        </template>
                    </summary-section>

                    <div 
                        v-for="(item, index) in lesson.lessonSubjects" 
                        :key="`ls-${index}`"
                        :class="[
                            'details-wrapper',
                            { 'details-wrapper--divider': showLesson(item.materialUuid) }
                        ]" 
                    >
                        <template v-if="showLesson(item.materialUuid)">
                            <p class="details-wrapper__title">{{ item.subject }}</p>

                            <summary-section
                                v-if="getTips(item.materialUuid)?.length"
                                :icon="require('@/assets/img/singleLesson/bulb.svg')"
                                :title="$t('single-lesson.tip')"
                                :noPadding="true"
                            >
                                <template v-slot:content>
                                    <template v-for="(text, idx) in getTips(item.materialUuid)">
                                        <NumberedList size="small" :text="text" :character="idx + 1" :key="`tnl-${idx}`"/>
                                    </template>
                                </template>
                            </summary-section>

                            <summary-section
                                v-if="getTopicVocabulary(item.materialUuid)?.length || extraVocabulary.length"
                                :icon="require('@/assets/img/singleLesson/vocabulary.svg')"
                                :title="$i18n.t('single-lesson.words')"
                                :noPadding="true"
                            >
                                <template v-slot:content>
                                    <div class="summary-wrapper">
                                        <template v-if="getTopicVocabulary(item.materialUuid)?.length">
                                            <div class="summary-container">
                                                <span class="summary-container__title">{{ $t('single-lesson.from-lesson') }}</span>
                                                <div class="summary-area">
                                                    <div
                                                        v-for="(item2, idx) in getTopicVocabulary(item.materialUuid)"
                                                        v-html="item2.english"
                                                        class="summary-area__item"
                                                        :key="`tv-${idx}`"
                                                        @mouseenter="(e) => showTranslateTooltip(e, item2)"
                                                        @mouseleave="hideTranslateTooltip"
                                                    ></div>
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <template v-if="extraVocabulary.length">
                                            <div class="summary-container">
                                                <span class="summary-container__title">{{ $t('single-lesson.extra') }}</span>
                                                <div class="summary-area">
                                                    <div
                                                        v-for="(item2, idx) in extraVocabulary"
                                                        v-html="item2.english"
                                                        class="summary-area__item"
                                                        :key="`ev-${idx}`"
                                                        @mouseenter="(e) => showTranslateTooltip(e, item2)"
                                                        @mouseleave="hideTranslateTooltip"
                                                    ></div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </summary-section>

                            <summary-section
                                v-if="getGrammars(item.materialUuid)?.length"
                                :icon="require('@/assets/img/singleLesson/conversation.svg')"
                                :title="$t('single-lesson.grammar')"
                                :noPadding="true"
                            >
                                <template v-slot:content>
                                    <template v-for="(text, idx) in getGrammars(item.materialUuid)">
                                        <NumberedList size="small" :text="text" :character="idx + 1" :key="`nl-${idx}`"/>
                                    </template>
                                </template>
                            </summary-section>
                        </template>
                    </div>
                </div>
            </div>
            <div v-if="showDesktopView" class="lesson-chat">
                <Messages v-if="acessMessageGroup" :oneGroupUuid="lesson.group.messageGroup.uuid" @access="access"/>
                <div v-else class="not-allowed"><img class="icon" src="@/assets/img/warning-500.svg" alt="">{{$i18n.t('single-lesson.chat-not-allowed')}}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { lessonByUuid } from '@/graphql/queries/lessonByUuid'
import { nextStudentLesson } from '@/graphql/queries/nextStudentLesson'
import api from '@/graphql/api.js'
import Messages from '@/views/Messages'
import Skeleton from '@/components/singleLesson/Skeleton'
import Header from '@/components/singleLesson/Header'
import Button from '@/components/reusable/Button'
import SummarySection from '@/components/singleLesson/SummarySection'
import SummaryItem from '@/components/singleLesson/SummaryItem'
import openCancelLessonModal from '@/mixins/schedule/openCancelLessonModal'
import flashcardsSubject from '@/functions/flashcardsSubject'
import { tooltipTranslate } from '@/mixins/tooltip';
import lessonSurvey from '@/mixins/lessonSurvey'
import NumberedList from '@/components/reusable/NumberedList';
import TeacherFeedback from '@/components/singleLesson/TeacherFeedback'
import { MATERIAL_TYPE, MATERIAL_STATES, PDF_TYPE } from '@/constants/material';
import { FLASHCARDS_TYPES, FLASHCARDS_STATES, FLASHCARDS_EXERCISES_STATES } from '@/constants/flashcards';


export default {
    name: 'SingleLesson',
    components: {
        Messages,
        Skeleton,
        Header,
        Button,
        SummarySection,
        SummaryItem,
        NumberedList,
        TeacherFeedback
    },
    mixins: [openCancelLessonModal, tooltipTranslate, lessonSurvey],
    data() {
        return {
            loading: true,
            lesson: null,
            nextLessonUuid: null,
            acessMessageGroup: true,
            showGoToMessages: false,
            MATERIAL_TYPE,
            MATERIAL_STATES,
            PDF_TYPE,
            flashcardsSubject
        }
    },
    async created() {
        await this.getLessonData()

        this.$bus.$on('updateLesson', async data => {
            if(data?.uuid == this.$route.params.uuid) this.lesson = data
            else await this.getLessonData()
        })

        this.loading = false
    },
    beforeDestroy() {
        this.$bus.$off('updateLesson')
    },
    methods: {
        open(url) {
            open(url, '_blank')
        },
        access(bool) {
            this.showGoToMessages = bool
            this.acessMessageGroup = bool
        },
        async getLessonData() {
            const {res, error} = await api(lessonByUuid, {
                lessonUuid: this.$route.params.uuid
            })

            if(error) {
                this.$router.push({name: 'dashboard'})
                return
            }
            
            const {res:nextLesson} = await api(nextStudentLesson)

            this.lesson = res.lessonByUuid
            this.$route.meta.messageGroupUuid = this.lesson.group.messageGroup.uuid
            this.nextLessonUuid = nextLesson.currentUser.student.nextStudentLesson?.lesson.uuid
        },
        joinLessonHandle() {
            this.$gtag({
                category: 'lesson',
                action: 'join'
            })
        },
        showLesson(materialUuid) {
            return this.getTips(materialUuid)?.length
                || this.getTopicVocabulary(materialUuid)?.length
                || this.extraVocabulary?.length
                || this.getGrammars(materialUuid)?.length
        },
        getTips(materialUuid) {
            return this.tips?.find(el => el.materialUuid === materialUuid)?.materialTips
        },
        getTopicVocabulary(materialUuid) {
            return this.topicVocabulary?.find(el => el.materialUuid === materialUuid)?.keyVocabulary
        },
        getGrammars(materialUuid) {
            return this.grammars?.find(el => el.materialUuid === materialUuid)?.grammar
        },
        flashcardsBtns(group) {
            const link = { name: 'flashcards', params: { uuid: group.uuid, lessonUuid: this.lesson.uuid }}
            const btns = [
                {
                    link,
                    type: FLASHCARDS_TYPES.LEARNING,
                    state: group.status
                }
            ]

            if(group.status === FLASHCARDS_STATES.COMPLETED) {
                btns.unshift({
                    link,
                    type: FLASHCARDS_TYPES.PRACTICE,
                    state: FLASHCARDS_STATES.PRACTICE
                })
            }

            return btns
        },
        flashcardsWordsLeft(group) {
            return group.flashcards.filter(el => el.activities.some(el2 => el2.status !== FLASHCARDS_EXERCISES_STATES.COMPLETED)).length
        }
    },
    computed: {
        topicVocabulary() {
            return this.lesson.courseProgram?.materialKeyVocabulary
        },
        extraVocabulary() {
            return this.lesson.courseProgram?.extraVocabulary
        },
        homework() {
            return this.lesson.homework
        },
        prelesson() {
            return this.lesson.preLesson
        },
        flashcardsGroups() {
            return this.lesson.flashcardsGroups
        },
        tips() {
            return this.lesson.tips
        },
        grammars() {
            return this.lesson.grammars
        },
        showAction() {
            return this.lesson.status === 'scheduled' || this.lesson.status === 'pending'
        },
        startInfo() {
            return this.lesson.status === 'scheduled' && this.isNextLesson && !this.joinLessonUrl
        },
        buyInfo() {
            return this.lesson.status === 'pending'
        },
        joinLessonUrl() {
            return this.lesson.studentLesson.joinLessonUrl
        },
        isNextLesson() {
            return this.lesson.uuid === this.nextLessonUuid
        },
        showSummary() {
            return this.lesson.status !== 'cancelled' && (this.showDetails || this.topicVocabulary?.length || this.extraVocabulary?.length)
        },
        showDetails() {
            return this.lesson.materialLink?.url || this.homework?.length || this.prelesson?.length || this.flashcardsGroups?.length
        },
        showDesktopView() {
            return this.$store.state.windowWidth > 1200
        }
    }
}
</script>

<style scoped lang="scss">
    .mt-0 {
        margin-top: 0;
    }

    .details-wrapper--divider {
        padding-top: 24px;
        border-top: 1px solid $gray-100;
        margin-top: 32px;
    }

    .details-wrapper__title {
        @include font-h2;
        color: $gray-600;
    }

    .summary-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .summary-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 19px;
        position: relative;

        &::before {
            left: 0;
            top: 0;
            position: absolute;
            content: '';
            width: 3px;
            height: 100%;
            background-color: $gray-200;
            border-radius: 16px;
        }
    }

    .summary-container__title {
        @include font-h6-uc;
        font-weight: 500;
        color: $gray-600;
    }

    .summary-area {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding-bottom: 6px;
    }

    .summary-area__item {
        @include font-regular;
        color: $gray-900;
        background: $gray-100;
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;

        &:hover {
            color: $white;
            background: $primary;
        }
    }
</style>
