<template>
    <div class="game">
        <div>
            <div class="game__header">
                <p class="game__title">{{ game.gameType.prompt }}</p>
                <span class="game__badge">{{ $t('ask-ai.game-message.activity') }}</span>
            </div>
            <p class="game__subtitle">{{ snakeCaseToString(game.gameType.type, true) }}</p>
        </div>
        <Button
            :text="$t('ask-ai.game-message.continue')"
            class="default font-medium"
            @click.native="$store.dispatch('tools/getTeacherAIGame', {
                game: {
                    uuid: game.uuid
                },
                prompt: game.gameType.prompt
            })"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import snakeCaseToString from '@/functions/snakeCaseToString'

export default {
    name: 'AskAIGameMessage',
    props: {
        game: Object
    },
    components: {
        Button
    },
    data() {
        return {
            snakeCaseToString
        }
    }
}
</script>

<style lang="scss" scoped>
.game {
    display: flex;
    gap: 32px;
    color: $gray-900;

    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 8px;
    }

    &__title {
        @include font-medium;
    }

    &__badge {
        @include font-h6-uc;
        color: $primary-dark;
        background: $primary-light;
        padding: 0 4px;
        border-radius: 4px;
    }

    &__subtitle {
        @include font-small-12-regular;
    }

    .btn {
        align-self: center;
        flex-shrink: 0;
    }
}
</style>