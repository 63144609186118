import Modal from '@/components/layout/Modal'
import ModalHeader from '@/components/layout/ModalHeader'

export default {
    components: {
        Modal,
        ModalHeader
    },
    methods: {
        hide() {
            this.$emit('hide')
        }
    }
}