import gql from 'graphql-tag'

export const guides = gql`
    {
        currentUser {
            student {
                guides {
                    isFinished
                    types
                    steps {
                        name
                        title
                        description
                        link {
                            url
                            description
                            parameters
                            type
                        }
                        markAction {
                            mutationName
                            parameters
                        }
                        status
                    }
                }
            }
        }
    }
`