import Cropper from 'cropperjs'
import emptyAvatar from '@/assets/img/empty-avatar.svg'
import cropperMinus from '../assets/img/cropper-minus.svg'
import cropperPlus from '../assets/img/cropper-plus.svg'
import arrow2 from '../assets/img/arrow2.svg'
import api from '@/graphql/api.js'
import { updateAvatar } from '@/graphql/mutations/updateAvatar'
import { schoolV2 } from '@/plugins/axios'
import accessStorage from '@/functions/accessStorage'

export default {
    data() {
        return {
            destination: null,
            modal: false,
            readyAvatar: {
                src: null,
                ext: null,
                content: null
            },
            avatar: null,
            cropper: {},
            zoomValue: 10,
            zoomMax: 20,
            ext: null,
            cropperMinus,
            cropperPlus,
            arrow2
        }
    },
    computed: {
        checkAvatar() {
            return this.$store.state.user.avatarUrl || emptyAvatar
        }
    },
    methods: {
        getImage(e) {
            const file = e.target.files[0]
            e.target.value = ''
            this.ext = this.getExtension(file.name)
            this.avatar = URL.createObjectURL(file)
            setTimeout(() => {
                this.setCropper()
            }, 1);
            this.modal = true
        },
        setCropper() {
            const image = this.$refs.image
            this.cropper = new Cropper(image, {
                zoomable: true,
                scalable: true,
                viewMode: 0,
                dragMode: 'move',
                responsive: true,
                aspectRatio: 1,
                ready: () => {
                    this.chromiumRangeBar(this.zoomValue)
                },
                crop: () => {
                    const canvas = this.cropper.getCroppedCanvas()
                    this.destination = canvas.toDataURL('image/png')
                },
                zoom: (e) => {
                    if(e.detail.originalEvent) {
                        e.preventDefault()
                        if(e.detail.oldRatio < e.detail.ratio && this.zoomValue < this.zoomMax) this.zoomValue++
                        else if(e.detail.oldRatio > e.detail.ratio && this.zoomValue > 0) this.zoomValue--
                    }
                }
            })
        },
        zoom(dir) {
            if(dir && this.zoomValue < this.zoomMax) this.zoomValue++
            else if(!dir && this.zoomValue > 0) this.zoomValue--
        },
        move(dir) {
            const speed = 10
            const way = dir === 'top' ? [0, speed] :
            dir === 'bottom' ? [0, -speed] :
            dir === 'left' ? [speed, 0] : [-speed, 0]
            this.cropper.move(way[0], way[1])
        },
        saveAvatar() {
            this.readyAvatar.src = this.destination
            this.readyAvatar.ext = this.ext
            this.readyAvatar.content = this.destination.substring(this.destination.indexOf(',')+1)
            this.hideModal()
        },
        hideModal() {
            this.$refs.input.value = ''
            this.zoomValue = this.zoomMax/2
            this.modal = false
        },
        chromiumRangeBar(val) {
            //chromium range progress
            document.getElementById('cropper-range').style.setProperty("--webkitProgressPercent", `${val/this.zoomMax*100}%`)
        },
        getExtension(name) {
            const array = name.split(/\.(?=[^.]+$)/)

            return array[array.length-1]
        },
        async updateAvatar() {
            if(this.readyAvatar.src) {
                const data = {
                    content: this.readyAvatar.content,
                    name: `avatar${this.$store.getters.userUuid}.${this.readyAvatar.ext}`
                }
                const res = await schoolV2.post('file/upload-base64', data, 
                {
                    headers: {
                        'Authorization': `Bearer ${accessStorage('authToken')}`
                    }
                })

                await api(updateAvatar, {
                    userUuid: this.$store.getters.userUuid,
                    fileUuid: res.data.uuid
                })
            }
        }
    },
    watch: {
        zoomValue(n, o) {
            this.chromiumRangeBar(n)

            let diff = n - o
            if(diff < 0) diff = diff * -1

            let value = 0.1
            if(o > n)  value = -0.1

            for(let i = 0; i < diff; i++) {
                this.cropper.zoom(value)
            }
        }
    }
}