<template>
    <div 
        :class="[
            'msg',
            { 
                'big': onlyEmoji(getContent(data.content)),
                'editable': isEditable,
                'editing': editMessage?.uuid === data.uuid,
                'deleted': data.isDeleted
            },
            ...getSenderClasses(data.sender.role)
        ]"
        :data-uuid="data.uuid"
    >
        <p class="author">{{messageAuthor}}</p>
        <div class="inner">
            <div class="avatar-wrapper">
                <img 
                    class="avatar avatar-style" 
                    :src="setAvatar(data.sender.avatarUrl)"
                    alt="Avatar"
                >
                <img
                    v-if="['current_user', 'user'].includes(data.sender.role)"
                    class="star" 
                    src="@/assets/img/chat/star.svg" 
                    alt=""
                    width="22"
                    height="22"
                >
            </div>
            <div class="content">
                <div ref="text" class="text" v-html="getContent(data.content)"></div>
                <template v-if="!data.isDeleted">
                    <div v-if="areFiles" class="files">
                        <a v-for="item in data.files" :href="item.path" class="file" :key="item.uuid">
                            <div class="left">
                                <img class="file-icon" :src="fileIcon" alt="">{{item.name}}
                            </div>
                            <div class="right">
                                <img :src="download" alt="">
                            </div>
                        </a>
                    </div>
                    <div v-if="areImages" class="images">
                        <div v-for="item in data.images" :style="`background-image: url('${item.path}');`" class="image" :key="item.uuid" @click="showGallery(item)"></div>
                    </div>
                    <div v-if="metadata" class="gif">
                        <img :src="metadata.gif.url" style="height: auto;" :width="metadata.gif.width" :height="metadata.gif.height" alt="">
                        <a href="https://giphy.com/" target="_blank"><img style="margin: 10px 0 -5px;" width="135" height="15" src="../../assets/img/giphy-small.png" alt=""></a>
                    </div>
                </template>
            </div>
        </div>
        <div class="footer">
            <span v-if="isEditable" class="footer-actions">
                <span class="delete" @click="deleteMessage">{{ $i18n.t('messages.delete') }}</span> |
                <template v-if="!data.metadata">
                    <span class="edit" @click="setEditMessage">{{ $i18n.t('messages.edit') }}</span> |
                </template>
            </span>
            {{formatDate(data.createdAt)}}
        </div>
    </div>
</template>

<script>
import formatDateMixin from '@/mixins/formatDate'
import setAvatarMixin from '@/mixins/setAvatar'
import fileIcon  from '../../assets/img/file-blue.svg'
import download  from '../../assets/img/download.svg'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'SingleMessage',
    props: {
        data: Object,
        isActive: Boolean,
        isPlatformMessage: Boolean
    },
    data() {
        return {
            fileIcon,
            download,
            isUpdateMessageTimeExpired: null
        }
    },
    mixins: [formatDateMixin, setAvatarMixin],
    created() {
        this.$bus.$on('checkUpdateMessageTime', this.checkUpdateMessageTime)
        this.checkUpdateMessageTime()

        // eslint-disable-next-line
        if(!this.data.sender) this.data.sender = {}
    },
    beforeDestroy() {
        this.$bus.$off('checkUpdateMessageTime')
    },
    computed: {
        ...mapState([
            'config',
            'editMessage'
        ]),
        ...mapGetters([
            'studentUuid',
            'userUuid'
        ]),
        metadata() {
            if(!this.data.metadata) return null
            else {
                const x = JSON.parse(this.data.metadata)
                return JSON.parse(x)
            }
        },
        messageAuthor() {
            const {role, firstName, lastName} = this.data.sender

            if(['current_student', 'current_user'].includes(role)) return this.$i18n.t('messages.chat.you')
            else if(role === 'student') return `${firstName} ${lastName}`
            else if(role === 'user') return `${firstName} - ${this.$i18n.t('general.lector')}`

            return ''
        },
        areFiles() {
            return this.data.files?.length
        },
        areImages() {
            return this.data.images?.length
        },
        isMessageAuthor() {
            return [this.studentUuid, this.userUuid].includes(this.data.sender.uuid)
        },
        isEditable() {
            return !this.isUpdateMessageTimeExpired
                && this.isMessageAuthor
                && !this.data.isDeleted
                && this.isActive
        },
        messageType() {
            return this.isPlatformMessage ? 'PLATFORM' : 'SPACE'
        }
    },
    methods: {
        showGallery(data) {
            this.$bus.$emit('updateGallery', data)
        },
        getContent(content) {
            if(this.data.isDeleted) return this.$i18n.t('messages.message-deleted')

            try {
                return JSON.parse(content)
            } catch {
                return content
            }
        },
        onlyEmoji(msg) {
            if(this.data.isDeleted) return

            const emojies = /[\u1000-\uFFFF]+/g
            const tags = /(<([^>]+)>)/gi

            let output = msg.replace(tags, '')
            output = output.replace(emojies, '')

            if(!output.length) return true
        },
        getSenderClasses(role) {
            const classes = []

            if(['current_student', 'current_user'].includes(role)) classes.push('current')

            if(['current_user', 'user'].includes(role)) classes.push('user')

            return classes
        },
        deleteMessage() {
            this.$bus.$emit('showDeleteMessageModal', {
                uuid: this.data.uuid,
                type: this.messageType
            })
        },
        setEditMessage() {
            this.$store.commit('setEditMessage', {
                ...this.data,
                type: this.messageType
            })
        },
        checkUpdateMessageTime() {
            this.isUpdateMessageTimeExpired = this.$moment(this.data.createdAt).add(this.config.modifyMessagesTime, 'seconds').isBefore()

            if(this.editMessage?.uuid === this.data.uuid && this.isUpdateMessageTimeExpired) {
                this.$store.commit('setEditMessage', null)
            }
        }
    }
}
</script>

<style lang="scss">
.msg {
    margin: 24px 0;
    max-width: 75%;

    @media(max-width: $mobile) {
        max-width: 100%;
    }

    &.big {
        .content>div:first-child {
            margin: 0 !important;
        }

        .text{
            font-size: 41px !important;
            line-height: 60px !important;
        }
    }
    
    .inner {
        display: flex;
        align-items: flex-end;

        .avatar-wrapper {
            flex: 0 0 auto;
            position: relative;

            .avatar {
                width: 32px;
                height: 32px;
                display: block;
            }
        }

        .content {
            background: $white;
            border: 2px solid transparent;
            border-radius: 10px 10px 10px 0;
            box-shadow: 0px 2px 20px rgba(45, 54, 66, 0.08);
            padding: 14px;
            margin: 0 0 0 16px;
            @include font-regular-text;
            color: $gray-900;
            word-break: break-word;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 100%;

            &::before {
                content: '';
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-bottom: 12px solid $white;
                position: absolute;
                bottom: -2px;
                left: 0;
                transform: translateX(-100%);
            }

            &>div:not(:last-child) {
                margin-bottom: 12px;
            }

            .text {
                max-width: 100%;

                img {
                    height: auto !important;
                }

                a {
                    color: $primary-dark;
                }
            }

            .files {
                width: 100%;

                .file {
                    display: flex;
                    padding: 8px;
                    border-radius: 8px;
                    border: 2px solid $gray-200;
                    cursor: pointer;
                    text-decoration: none;

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    .left {
                        display: flex;
                        flex: 1;
                        padding-right: 32px;

                        .file-icon {
                            margin-right: 12px;
                        }
                    }

                    .right {
                        padding-left: 8px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        &::before {
                            content: '';
                            width: 2px;
                            height: calc(100% + 16px);
                            background: $gray-200;
                            position: absolute;
                            left: 0;
                            top: -8px;
                        }
                    }
                }
            }

            .images {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .image { 
                    cursor: pointer;
                    width: 107px;
                    height: 107px;
                    border-radius: 8px;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center;

                    img {
                        max-height: 100%;
                        width: auto;
                    }

                    &.small {
                        height: 100px;    
                    }
                }
            }

            & > p:not(:last-child) {
                margin-bottom: 5px;

            }

            img {
                display: block;
            }

            mark {
                padding: 3px;
                border-radius: 3px;
            }
        }
    }

    .footer, .author {
        @include font-small-12;
        color: $gray-500;
        margin: 4px 0 0;
        margin-left: calc(32px + 16px);
    }

    .author {
        margin-top: 0;
        margin-bottom: 4px;
    }

    &.top, &.middle {
        .avatar-wrapper {
            opacity: 0;
            visibility: hidden;
        }

        .content {
            border-radius: 10px !important;

            &::before {
                content: unset;
            }
        }

        .footer {
            display: none;
        }
    }

    &.middle, &.bottom {
        .author {
            display: none;
        }
    }

    &.top {
        margin-top: 24px;
        margin-bottom: 4px;
    }

    &.bottom {
        margin-top: 4px;
        margin-bottom: 24px;
    }

    &.middle {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &:first-of-type {
        margin-top: 0px;
    }

    &:last-of-type {
        margin-bottom: 0px;
    }

    &.user {
        .avatar-wrapper {
            &::before {
                content: '';
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                position: absolute;
                bottom: -2px;
                left: -2px;
                background: transparent;
                border: 1px solid $primary;
                border-radius: 50%;
            }

            .star {
                bottom: -8px;
                left: -8px;
                position: absolute;
            }
        }
    }

    &.current {
        display: flex;
        flex-direction: column;
        margin-left: auto;

        .inner {
            margin-left: auto;

            .avatar-wrapper {
                order: 2;
            }

            .content {
                margin: 0 16px 0 0;
                background: $primary-light;
                border-radius: 10px 10px 0 10px;

                &::before {
                    border-left: 0;
                    border-right: 8px solid transparent;
                    border-bottom-color: $primary-light;
                    left: unset;
                    right: 0;
                    transform: translateX(100%);
                }
            }
        }

        .footer, .author {
            text-align: right;
            margin-left: auto;
            margin-right: calc(32px + 16px);
        }

        .footer-actions {
            opacity: 0;
            visibility: hidden;
            transition: all .25s;
        }

        .delete, .edit {
            cursor: pointer;
            transition: all .25s;

            &:hover {
                color: $gray-600;
            }
        }
    }

    &.editing {
        .inner .content {
            border-color: #55a9ff;

            &::before {
                border-bottom-color: #55a9ff;
            }
        }
    }

    &.editable {
        &:hover {
            .footer-actions {
                opacity: 1;
                visibility: visible;
            }
        }

        .footer {
            display: block;
        }
    }

    &.deleted {
        .inner .content {
            font-style: italic;
            color: $gray-500;
        }
    }
}
</style>