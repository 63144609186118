import gql from 'graphql-tag'

export const flashcardsGroup = gql`
    query($uuid: ID!) {
        flashcardsGroup(uuid: $uuid) {
            uuid
            name
            status
            flashcards {
                uuid
                word
                english
                activities {
                    type
                    status
                }
            }
        }
    }
`