var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.config.days),function(n){return _c('div',{key:n,staticClass:"item",class:{'active': _vm.showDetails[n]}},[_c('div',{staticClass:"header"},[_c('ModernCheckbox',{attrs:{"data":{label: _vm.capitalizeFirstLetter(_vm.$moment.weekdays(true)[n-1])}},model:{value:(_vm.showDetails[n]),callback:function ($$v) {_vm.$set(_vm.showDetails, n, $$v)},expression:"showDetails[n]"}}),(n === 1 && _vm.showDetails[1])?_c('p',{staticClass:"copy",on:{"click":_vm.copyToAll}},[_vm._v(_vm._s(_vm.$i18n.t('onboarding.preferences.copy-to-all')))]):_vm._e()],1),(_vm.showDetails[n])?_c('div',{staticClass:"ranges"},_vm._l((_vm.formData[n]),function(item,index){return _c('div',{key:item.id,staticClass:"range"},[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"hours"},[_c('p',[_vm._v(_vm._s(_vm.convertNumberToHour(item.values[0], true)))]),_c('p',[_vm._v(_vm._s(_vm.convertNumberToHour(item.values[1], true)))])]),_c('vue-slider',{ref:item.id,refInFor:true,staticStyle:{"padding":"5px 0"},attrs:{"min":_vm.config.min,"max":_vm.config.max,"tooltip":"none","height":8,"dotSize":16,"dotStyle":{
                            background: '#0782FF',
                            border: '4px solid #FFFFFF',
                            'box-shadow': '0px 2px 8px rgba(0, 0, 0, 0.16)'
                        },"processStyle":{
                            background: '#0782FF'
                        },"railStyle":{
                            background: '#E5EAEE'
                        }},model:{value:(_vm.formData[n].find(i => i.id === item.id).values),callback:function ($$v) {_vm.$set(_vm.formData[n].find(i => i.id === item.id), "values", $$v)},expression:"formData[n].find(i => i.id === item.id).values"}})],1),_c('div',{staticClass:"actions"},[(index > 0)?_c('img',{attrs:{"src":require("@/assets/img/onboarding/remove.svg"),"alt":""},on:{"click":function($event){return _vm.removeRange(n, item.id)}}}):_vm._e(),(index === _vm.formData[n].length-1)?_c('img',{staticClass:"add",attrs:{"src":require("@/assets/img/onboarding/add.svg"),"alt":""},on:{"click":function($event){return _vm.addRange(n)}}}):_vm._e()]),(index === _vm.formData[n].length-1)?_c('div',{staticClass:"mobile-add",on:{"click":function($event){return _vm.addRange(n)}}},[_c('img',{attrs:{"src":require("@/assets/img/onboarding/add.svg"),"alt":""}})]):_vm._e()])}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }