var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.isGrammarSupported && _vm.tool === 'grammar') || _vm.tool !== 'grammar')?_c('div',{class:[
        'tool-button', 
        {
          'tool-button--active': _vm.tool === _vm.activeTool,
          'tool-button--askAI': _vm.tool === 'askAI'
        }
    ],on:{"click":_vm.handleAction}},[_c('img',{attrs:{"width":"16","height":"16","src":require(`@/assets/img/tools/${ _vm.tool }-small.svg`),"alt":""}}),_vm._v(" "+_vm._s(_vm.toggleMoreTools ? _vm.$i18n.t(`tools.${ _vm.moreTools ? 'less' : 'more' }.short-name`) : _vm.$i18n.t(`tools.${ _vm.tool }.short-name`))+" ")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }