<template>
    <div class="widget lesson">
        <LessonHeader :data="headerData" />
        <div class="buttons">
            <Button v-if="materials" class="default" @click.native="open(materials)" :text="$i18n.t('dashboard.last-lesson.materials')"/>
            <Button v-if="canRate" class="secondary" :loading="surveyLoading" @click.native="setLessonSurvey()" :text="$i18n.t('dashboard.last-lesson.rate')"/>
            <Button v-if="rated" class="rated" :icon="require('@/assets/img/completed.svg')" :text="$i18n.t('dashboard.last-lesson.rated')"/>
        </div>
    </div>
</template>

<script>
import LessonHeader from './LessonHeader'
import icon from '@/assets/img/lesson-gray.svg'
import Button from '@/components/reusable/Button'
import lessonSurvey from '@/mixins/lessonSurvey'

export default {
    name: 'LastLesson',
    props: ['lesson'],
    components: {
        LessonHeader,
        Button
    },
    mixins: [lessonSurvey],
    data() {
        return {
            headerData: {
                icon,
                title: this.$i18n.t('dashboard.last-lesson.title'),
                data: this.lesson
            }
        }
    },
    methods: {
        open(url) {
            open(url, '_blank')
        }
    },
    computed: {
        materials() {
            return this.lesson.materialLink?.url
        }
    }
}
</script>

