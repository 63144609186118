<template>
    <div class="flex-1">
        <label :class="['input', { 'error': validation }]">
            {{ data.label }}
            <vue-tel-input
                ref="telInput"
                :value="newValue || value"
                @input="onInput"
                mode="international"
                :styleClasses="['tel-input']"
                :defaultCountry="defaultCountry"
                :validCharactersOnly="true"
                :autoFormat="true"
                :dropdownOptions="{
                    showDialCodeInSelection: false,
                    showFlags: true,
                    showDialCodeInList: true,
                    showSearchBox: true,
                    tabindex: 0
                }"
                :inputOptions="{
                    placeholder: data?.placeholder,
                    name: data?.name
                }"
            >
            </vue-tel-input>
        </label>
        <p v-if="validation" class="invalid-feedback">{{ validation }}</p>
    </div>
</template>

<script>
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'TelInput',
    props: ['data', 'value', 'validation'],
    data() {
        return {
            defaultCountry: this.$route.params.locale === 'en' ? 'gb' : this.$route.params.locale,
            newValue: ''
        }
    },
    mounted() {
        if(this.data.focus) this.focus()
    },
    methods: {
        onInput(e) {
            this.$emit('input', e)
            this.newValue = e
        },
        focus() {
            this.$refs.telInput.focus()
        }
    }
}
</script>

<style lang="scss">
.vue-tel-input.tel-input {
    border-radius: 6px;
    border: 1px solid $gray-200;
    position: relative;

    &:hover {
        border-color: $gray-300;
    }

    &:focus-within {
        border-color: $primary;
        background: $white;
        box-shadow: none;
    }

    .vti__dropdown {
        background: $gray-200;
        border-radius: 6px 0 0 6px;
        position: static;
        outline: 0;
    }

    .vti__input {
        border: 0;
        margin-top: 0;
    }

    .vti__dropdown-list {
        width: 100%;
        box-sizing: content-box;
        top: unset;
        bottom: -2px;
        transform: translateY(100%);
        border-radius: 6px;
        z-index: 9999;
        max-height: 100px;
    }

    .vti__search_box {
        display: none;
    }

    .vti__dropdown-item {
        padding: 8px;
    }
}

.error .vue-tel-input.tel-input {
    border-color: red;
}
</style>