<template>
    <div
        :class="[
            'dot-notification',
            { 'dot-notification--border': border, 'dot-notification--pulse': pulse }
        ]"
    >
    </div>
</template>

<script>
export default {
    name: 'DotNotification',
    props: {
        border: Boolean,
        pulse: Boolean
    }
}
</script>

<style lang="scss" scoped>
.dot-notification {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $danger;
    box-sizing: content-box;
    padding: 0 !important;

    &--border {
        border: 3px solid $white;
    }

    &--pulse {
        animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0px rgba($danger, 0.5);
        }

        100% {
            box-shadow: 0 0 0 10px rgba($danger, 0);
        }
    }
}
</style>