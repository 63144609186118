<template>
<div class="lector-card">
    <div class="lector-card-wrapper">
        <div class="lector-card-info">
            <div class="lector-card-info__media">
                <div v-if="data.details.profileVideo" class="lector-card-info__video-play">
                    <img
                        @click.stop="displayVideo(data.details.profileVideo)"
                        src="@/assets/img/schedule/play2.svg" 
                        class="lector-card-info__video-play-icon"
                    />
                    <span class="lector-card-info__video-play-hover"></span>
                </div>
                <img :src="data.details.profilePicture" class="lector-card-info__photo" alt="">
            </div>

            <div class="lector-card-info-wrapper">
                <div class="pb-16">
                    <div v-if="data.firstName" class="lector-card-info__name d-flex">
                        {{ data.firstName }}
                        <div v-if="data.advanceNotice < 24" class="info-tooltip">
                            <Tooltip 
                                class="tooltip--top" 
                                :text="$i18n.t('switch-lector.list.filters.less-than-24.tooltip')"
                            />
                            <img src="@/assets/img/schedule/circle-bolt.svg" class="switch-lector-item__advance-notice" />
                        </div>
                    </div>

                    <div v-if="data.details.headline" class="lector-card-info__headline mb-16">{{ data.details.headline }}</div>

                    <div class="mb-8 t-u">{{ this.$i18n.t('lector-card.about') }}</div>

                    <div 
                        v-if="data.details.aboutMe" 
                        v-html="data.details.aboutMe" 
                        :class="['lector-card-info__about', {'lector-card-info__about--expanded': aboutMeExpanded}]"
                    ></div>

                    <span class="lector-card-info__about-btn" v-if="ifExpandBtnExists" @click="aboutMeExpanded = true">{{ this.$i18n.t('lector-card.show-more') }}</span>
                </div>

                <div class="bt-1-200 pb-16">
                    <LectorInfoRow>
                        <template #header>
                            <img src="@/assets/img/schedule/schoolGraduation.svg" class="mt-3px" alt="">
                            <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-speak')"/>
                        </template>

                        <div 
                            v-for="teach in data.languagesITeach" :key="teach.language.shortName"
                            class="d-flex align-items-center gap-8">

                            <template v-if="$i18n.te(`dashboard.lector.${ teach.language.shortName }`)">
                                <img 
                                    :src="getCourseIcon(teach.language.shortName)" 
                                    class="switch-lector-item__flag"
                                    alt="Course">
                                {{ $i18n.t(`dashboard.lector.${ teach.language.shortName }`) }}
                            </template>
                        </div>
                    </LectorInfoRow>

                    <LectorInfoRow>
                        <template #header>
                            <img src="@/assets/img/schedule/languagesISpeak.svg" class="mt-5px" alt="">
                            <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-speak')"/>
                        </template>

                        <div class="switch-lector-item__restricted-wrapper">
                            <div v-for="(speak, value) in data.languagesISpeak" 
                                :key="speak.language.shortName"
                                class="switch-lector-item__lang-item"
                                v-html="handleLanguages(speak, value, data.languagesISpeak.length)"
                            > 
                            </div>
                        </div>
                    </LectorInfoRow>

                    <LectorInfoRow v-if="data.certificates.length">
                        <template #header>
                            <img src="@/assets/img/schedule/write.svg" class="mt-5px" alt="">
                            <Tooltip class="tooltip--right" :text="$i18n.t('switch-lector.list.icon-exams')"/>
                        </template>

                        <div class="switch-lector-item__restricted-wrapper">
                            <span 
                                v-for="(cert, index) in data.certificates" :key="cert+index"
                                class="switch-lector-item__certificate"
                            >
                                {{ cert }}
                            </span>
                        </div>
                    </LectorInfoRow>
                </div>

                <div class="bt-1-200 pt-16 pb-16">
                    <div class="lector-card-info__section-title">{{ this.$i18n.t('lector-card.teaching-specialisations') }}</div>
                    <ul class="lector-card-info__list">
                        <li v-for="el in data.details.tags" :key="el">{{ specialityName(el) }}</li>
                    </ul>
                </div>

                <div class="bt-1-200 pt-16 pb-16">
                    <div class="lector-card-info__section-title">{{ this.$i18n.t('lector-card.teaching-preferences') }}</div>
                    <ul class="lector-card-info__list">
                        <li v-for="el in data.details.preferences" :key="el">{{ teachingPreferencesName(el) }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div :class="[
            'lector-card-calendar',
            { 'lector-card-calendar--disabled': !allowBookingInCurrentGroupContext && isLectorDetailsDirectOpened }
        ]">
            <SkeletonCalendarDesktop v-if="!lectorCalendar" />

            <template v-else>
                <SwitchLectorCalendar mode="mini-mobile" :slots="lectorCalendarSlots" :item="item" :key="item.uuid" :lector="formatedLector" />
            </template>
        </div>
    </div>

    <div class="lector-card-mobile-slots">
        <SwitchLectorSomeFreeSlots 
            v-if="lectorCalendar"
            :slots="lectorCalendarSlots" 
            :lector="formatedLector"
            :lectorApiUrl="lectorCalendarUrl"
            :item="item" 
            :key="item.uuid" 
            @showCalendar="openLectorCalendar"
        />
    </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SwitchLectorCalendar from '@/components/switchLector/SwitchLectorCalendar';
import SkeletonCalendarDesktop from '@/components/switchLector/SkeletonCalendarDesktop';
import SwitchLectorSomeFreeSlots from '@/components/switchLector/SwitchLectorSomeFreeSlots';
import LectorInfoRow from '@/components/switchLector/LectorInfoRow';

import apiUrlForThisLector from '@/mixins/schedule/apiUrlForThisLector';
import handleLectorLangList from '@/mixins/schedule/handleLectorLangList';
import flagMixin from '@/mixins/flag';
import Tooltip from '@/components/reusable/Tooltip'

export default {
    components: { 
        SkeletonCalendarDesktop,
        SwitchLectorCalendar,
        SwitchLectorSomeFreeSlots,
        LectorInfoRow, 
        Tooltip,
    },
    mixins: [flagMixin, apiUrlForThisLector, handleLectorLangList],
    props: ['data'],
    data() {
        return {
            aboutMeExpanded: false,
        }
    },
    async created() {
        this.$store.commit('schedule/setModalTitle', this.isLectorDetailsDirectOpened
            ? this.$i18n.t('modal.lector-details.direct.header')
            : this.$i18n.t('modal.book-lesson.header')
        );

        if (!this.lectorCalendar) await this.$store.dispatch('schedule/lectorCalendarRequest', this.lectorCalendarUrl);
    
        this.$store.commit("schedule/setSchedulePrimaryButton", false);

        if (!this.isLectorDetailsDirectOpened) {
            this.$store.commit("schedule/setScheduleDefaultButton", { 
                btnDefaultAction: this.isSavedSchedule && !this.switchLectorData.length
                    ? 'goToSwitchLectorList'
                    : 'backToSwitchLectorList',
                btnDefaultLabel: this.isSavedSchedule 
                    ? this.$i18n.t('switch-lector.calendar.go-to-lector-list') 
                    : this.$i18n.t('switch-lector.calendar.back-to-lector-list')
            });
        }
    },
    computed: {
        ...mapState({
            switchLectorData: state => state.schedule.switchLector.data
        }),
        ...mapGetters('schedule', [
            'isSavedSchedule',
            'isLectorDetailsDirectOpened',
            'allowBookingInCurrentGroupContext'
        ]),
        item() {
            return this.data;
        },
        formatedLector() {
            const { avatar, firstName, uuid } = this.item;
            return { uuid, name: firstName, avatarUrl: avatar }
        },
        lectorCalendar() {
            return this.$store.state.schedule.lectorsCalendars[this.lectorCalendarUrl];
        },
        lectorCalendarSlots() {
            return this.$store.state.schedule.lectorsCalendars[this.lectorCalendarUrl]?.slots;
        },
        lectorCalendarUrl() {
            return this.apiUrlForThisLector(this.item.uuid);
        },
        ifExpandBtnExists() {
            return this.data.details.aboutMe && this.data.details.aboutMe.length > 230 && !this.aboutMeExpanded;
        }
    },
    methods: {
        speakLanguageName(name) {
            return this.$i18n.t(name.toLowerCase());
        },
        displayVideo(url) {
            this.$bus.$emit('setVideoModal', { show: true, videoUrl: url })

            this.$gtag({
                category: 'btnClick',
                action: 'teacher_video_view'
            })
        },
        specialityName(key) {
            return this.$i18n.t(`specialities.${key}`);
        },
        teachingPreferencesName(key) {
            return this.$i18n.t(`teaching-preferences.${key}`);
        },
        openLectorCalendar() {
            this.$store.commit("schedule/resetSlot");
            this.$store.commit('schedule/setSwitchLectorApiUrl', this.lectorCalendarUrl);
            this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: false });
            this.$store.commit('schedule/setSwitchLectorAnchor', this.lectorCalendarUrl);
        }
    }
}
</script>

<style lang="scss" scoped>
    .t-u {
        text-transform: uppercase;
    }

    .pb-16 {
        padding-bottom: 16px;
    }

    .pt-16 {
        padding-top: 16px;
    }

    .mb-16 {
        margin-bottom: 16px;
    }

    .mb-8 {
        margin-bottom: 8px;
    }

    .bt-1-200 {
        border-top: 1px solid $gray-200;
    }

    .lector-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

    }
    .lector-card-mobile-slots {
        flex: 50px;
        padding: 8px 16px 0;
        border-top: 1px solid #F2F3F4;

        @media (min-width: $tablet-switch-lector) {
            display: none;
        }
    }

    .lector-card-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 16px;
        padding: 8px 8px 16px;
        overflow-y: auto;
        flex-wrap: wrap;

        @media (min-width: $tablet-switch-lector) {
            flex-wrap: nowrap;
            padding: 8px;
        }
    }
    
    .switch-lector-item__advance-notice {
        vertical-align: sub;
        margin-left: 5px;
    }

    ::v-deep .switch-lector-item {
        &__level {
            &--mother-tongue {
                color: $gray-900;
                @include font-medium;
                font-weight: 600;
            }
        }
    }

    .lector-card-info {
        height: fit-content;
        flex: 100%;
        border: 1px solid $gray-200;
        border-radius: 8px;

        @media (min-width: $tablet-switch-lector) {
            flex: 1; 
        }
    }

    .lector-card-calendar {
        display: none;
        flex: 100%;
        border-radius: 8px;
        border: 1px solid $gray-200;
        padding: 16px 0;
        
        @media (min-width: $tablet-switch-lector) {
            display: block;
            flex: 1; 
            position: sticky;
            top: 0;
        }
    }

    .lector-card-calendar--disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .lector-card-info-wrapper {
        padding: 16px;
    }

    .lector-card-info__media {
        display: flex;
        position: relative;
    }

    .lector-card-info__video-play {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        cursor: pointer;

        &:hover {
            .lector-card-info__video-play-hover {
                width: 100%;
                height: 100%;
                opacity: 1;
            }
        }
    }

    .lector-card-info__video-play-icon {
        height: 100%;
        width: 100%;
    }

    .lector-card-info__video-play-hover {
        position: absolute;
        width: 50%;
        height: 50%;
        opacity: 0;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        z-index: -1;
        background-color: white;
        border-radius: 50%;
        transition: all 0.3s;
    }
    .lector-card-info__photo {
        width: 100%;
        max-height: 350px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        object-fit: cover;
        @media (min-width: $tablet-switch-lector) {
            max-height: 208px;
        }
    }

    .lector-card-info__name {
        @include font-h2;
        font-size: 24px;
    }

    .lector-card-info__headline {
        @include font-regular;
        line-height: 20px;
        margin-top: 8px;
        color: $gray-900;
    }
    
    .lector-card-info__about {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
                line-clamp: 4; 
        -webkit-box-orient: vertical;
        max-height: 100px;

        &.lector-card-info__about--expanded {
            -webkit-line-clamp: unset;
            line-clamp: unset; 
            max-height: initial;
        }

        ::v-deep p, 
        ::v-deep span {
            margin: 0 !important;
            @include font-regular;
            line-height: 24px;
            color: $gray-600 !important;
        }
    }

    .lector-card-info__about-btn {
        color: $primary;
        cursor: pointer;
    }

    .lector-card-info__section-title {
        @include font-h4;
    }

    .lector-card-info__list {
        margin-top: 8px;
        list-style-position: inside;
        padding-left: 8px;
        li {
            font-weight: 400;
        }
    }

    .schedule-calendar-wrapper--mini .schedule-calendar__hour {
        font-size: 12px;
    }

    ::v-deep .schedule-calendar__hour .tooltip {
        display: none !important;
    }

    ::v-deep .switch-lector-free-slots {
        padding: 0;
    }

    ::v-deep .switch-lector-free-slots__content {
        width: 100%;
    }

    ::v-deep .switch-lector-free-slots__row {
        justify-content: center;
    }

    ::v-deep .switch-lector-free-slots__content-box {
        justify-content: center;
        margin: 4px 0 2px;
    }
</style>