import gql from 'graphql-tag'

export const updateRegistrationDataInput = gql`
    mutation($input: UpdateRegistrationDataInput!) {
        updateRegistrationData(input: $input) {
            source
            registrationReason
            lessonsCount
        }
    }
`