<template>
    <div class="inner">
        <p class="label">{{label}}</p>
        <p v-if="!edit" class="value">
            {{ component.name === 'DateSelect' ? dateDisplay :
            type === 'gender' ? genderDisplay :
            type === 'country' ? value ? value.name : null :
            name === 'hourFormat' ? this.$t(`general.hourFormat.${ value }`) :
            name === 'dateFormat' ? this.$t(`general.dateFormat.${ value }`) :
            name === 'language' ? this.$t(`general.language.${ value }`) :
            value }}
        </p>
        <template v-else>
            <component
                :is="component"
                :value="component.name === 'DateSelect' ? dateValue : 
                type === 'country' ? value ? value.code : null :
                value"
                :data="{options, disabled, name, placeholder, min, max}"
            />
        </template>
    </div>
</template>

<script>
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'DataRow',
    props: ['label', 'value', 'component', 'edit', 'options', 'disabled', 'type', 'name', 'placeholder', 'min', 'max'],
    data() {
        return {
            timezoneDate,
            emptyDisplay: '-'
        }
    },
    computed: {
        dateDisplay() {
            return this.value ? this.$moment(this.value).format(timezoneDate('D MMMM YYYY')) : this.emptyDisplay
        },
        genderDisplay() {
            return this.value ? this.$t(`general.gender.${ this.value }`) : this.emptyDisplay
        },
        dateValue() {
            return this.value ? this.$moment(this.value).format('YYYY-MM-DD') : null
        }
    }
}
</script>