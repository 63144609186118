<template>
    <span :class="['messages-separator', type, { 'messages-separator--simple-chat' : simpleChat }]">
        <span class="text">{{text}}</span>
    </span>
</template>

<script>
export default {
    name: 'MessagesSeparator',
    props: {
        type: {
            type: String
        },
        text: {
            type: String
        },
        simpleChat: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.messages-separator {
    @include font-small-10;
    text-align: center;
    position: relative;
    display: block;

    &::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0px;
        top: 7.5px;
    }

    .text {
        position: relative;
        background: $gray-100;
        padding: 0 4px;
    }

    &.new {
        color: $danger;

        &::before {
            background: $danger;
        }
    }

    &.lesson {
        color: $warning-dark;

        &::before {
            background: $warning-dark;
        }
    }

    &--simple-chat {
        .text {
            background: $white;
        }
    }
}
</style>