var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['summary-item', { 'success': [_vm.MATERIAL_STATES.FINISHED, _vm.FLASHCARDS_STATES.COMPLETED].includes(_vm.state) }]},[_c('div',{staticClass:"summary-item__box1 flex-1"},[_c('p',{staticClass:"summary-item__title"},[_vm._v(_vm._s(_vm.title)+" "),(_vm.titleBadge)?_c('span',{class:['summary-item__title-badge', `summary-item__title-badge--${ _vm.titleBadge.class }`]},[_vm._v(_vm._s(_vm.titleBadge.text))]):_vm._e()]),_c('p',{staticClass:"summary-item__subtitle"},[_vm._v(_vm._s(_vm.subtitle))])]),_c('div',{class:['btn-wrapper', `btn-wrapper--${ _vm.type }`]},[_vm._l((_vm.btns),function(btn){return _c('div',{key:btn.type,staticClass:"summary-item__btn"},[_c(_vm.isInternalLink(btn.link) ? 'router-link' : 'a',_vm._b({tag:"component",class:[
                    'btn default',
                    { 'warning': btn.state === _vm.MATERIAL_STATES.PENDING },
                    { 'success': btn.state === _vm.MATERIAL_STATES.FINISHED },
                    { 'success disabled': btn.state === _vm.FLASHCARDS_STATES.COMPLETED },
                    { 'primary': btn.state === _vm.FLASHCARDS_STATES.IN_PROGRESS }
                ]},"component",_vm._d({},[_vm.linkAttribute(btn.link),btn.link])),[([_vm.MATERIAL_STATES.PENDING, _vm.MATERIAL_STATES.FINISHED, _vm.FLASHCARDS_STATES.COMPLETED].includes(btn.state))?_c('img',{attrs:{"src":btn.state === _vm.MATERIAL_STATES.PENDING ? _vm.MarkText : _vm.CheckGreenDark}}):_vm._e(),_vm._v(" "+_vm._s(_vm.buttonText(btn))+" ")])],1)}),(_vm.isPlatformMaterial)?[_c('div',{staticClass:"info-tooltip"},[_c('Button',{staticClass:"button btn default",attrs:{"icon":_vm.printerIcon,"loading":_vm.loading},nativeOn:{"click":function($event){return _vm.handlePrint()}}}),_c('Tooltip',{attrs:{"text":_vm.$t('single-lesson.print.btn')}})],1),_c('div',{ref:"qrcode",staticClass:"qr-wrapper"},[_c('qr-code',{staticClass:"qrcode",attrs:{"text":_vm.getQrLink}})],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }