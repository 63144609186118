<template>
    <div :class="['send-msg', { 'send-msg--simple': simpleChat }]">
        <EditMessageInfo 
            v-if="editMessage"
            :message="editMessage"
            @cancelEdit="cancelEdit"
        />
        <div v-if="editor" class="content" :class="{'focus': focus}">
            <div class="top">
                    <editor-content 
                        class="textarea"
                        :editor="editor"
                        @keydown.enter.native="enterSend ? send() : null"
                        @keydown.native="keyDownHanlder"
                    />
                <div class="buttons">
                    <FileUpload 
                        v-if="simpleChat"
                        simpleChat
                        @setFiles="setFiles"
                    />
                    <img v-if="!isMobileDevice" class="picker-button" :src="emotesIcon" alt="Emotes" @click="togglePicker">
                    <button 
                        @click="send(true)" 
                        :class="['send', {'disabled': tooLargeFileExists}]"
                    >
                        <img :src="sendIcon" alt=""> 
                        <span class="text">{{$i18n.t('messages.send')}}</span>
                    </button>
                </div>
            </div>
            <div :class="['files', { 'files--filled': files.length }]">
                <div 
                    v-for="item in files" 
                    class="file" 
                    :class="[item.src ? 'img' : 'doc', {'large': item.file.size > maxFileSize}]" 
                    :key="item.id"
                >
                    <div v-if="item.src" class="image-preview" :style="`background-image: url('${item.src}');`"></div>
                    <template v-else>
                        <img :src="fileIcon" alt="" class="icon">
                        {{item.file.name | cut(20)}}
                    </template>
                    <img :src="removeIcon" alt="Remove file" class="remove" @click="removeFile(item.id)">
                </div>
            </div>
            <div v-if="!simpleChat" class="bottom">
                <img :src="editor.isActive('bold') ? boldActive : bold" class="bold" alt="" @click="editor.chain().focus().toggleBold().run()">
                <img :src="editor.isActive('italic') ? italicActive : italic" class="italic" alt="" @click="editor.chain().focus().toggleItalic().run()">
                <div class="highlight spacer">
                    <img :src="editor.isActive('highlight') ? highlightActive : highlight" alt="">
                    <div class="switcher">
                        <div class="inner">
                            <div v-for="item in highlightSwitcher" :key="item" :class="{active: editor.isActive('highlight', {color: item})}" :style="`background: ${item};`" @click="editor.chain().focus().toggleHighlight({color: item}).run()">
                                <img class="check" :src="check" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <img :src="editor.isActive({ textAlign: 'left' }) ? leftActive : left" alt="" @click="editor.chain().focus().setTextAlign('left').run()">
                <img :src="editor.isActive({ textAlign: 'center' }) ? centerActive : center" alt="" @click="editor.chain().focus().setTextAlign('center').run()">
                <img :src="editor.isActive('orderedList') ? orderedListActive : orderedList" alt="" @click="editor.chain().focus().toggleOrderedList().run()">
                <img class="spacer" :src="editor.isActive('bulletList') ? bulletListActive : bulletList" alt="" @click="editor.chain().focus().toggleBulletList().run()">
                <FileUpload 
                    class="spacer"
                    @setFiles="setFiles"
                />
                <!-- <img class="spacer" :src="link" alt=""> -->
                <img :src="undo" alt="" @click="editor.chain().focus().undo().run()">
                <img :src="redo" alt="" @click="editor.chain().focus().redo().run()">
                <Checkbox :data="{label: $i18n.t('messages.enter-send')}" v-model="enterSend"/>
            </div>
            <Picker v-if="showPicker" @hide="hidePicker"/>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import { preventEnter } from '@/editor/plugins'

import emotesIcon from '@/assets/img/chat/emotes.svg'
import sendIcon from '@/assets/img/chat/send.svg'
import Picker from './Picker'
import Checkbox from '@/components/reusable/Checkbox.vue'
import EditMessageInfo from '@/components/messages/EditMessageInfo'
import FileUpload from '@/components/messages/FileUpload'

import bold from '@/assets/img/chat/bold.svg'
import boldActive from '@/assets/img/chat/bold-active.svg'
import italic from '@/assets/img/chat/italic.svg'
import italicActive from '@/assets/img/chat/italic-active.svg'
import highlight from '@/assets/img/chat/highlight.svg'
import highlightActive from '@/assets/img/chat/highlight-active.svg'
import left from '@/assets/img/chat/left.svg'
import leftActive from '@/assets/img/chat/left-active.svg'
import center from '@/assets/img/chat/center.svg'
import centerActive from '@/assets/img/chat/center-active.svg'
import orderedList from '@/assets/img/chat/orderedlist.svg'
import orderedListActive from '@/assets/img/chat/orderedlist-active.svg'
import bulletList from '@/assets/img/chat/bulletlist.svg'
import bulletListActive from '@/assets/img/chat/bulletlist-active.svg'
import undo from '@/assets/img/chat/undo.svg'
import redo from '@/assets/img/chat/redo.svg'
import link from '@/assets/img/chat/link.svg'
import check from '@/assets/img/check-light.svg'

import fileIcon from '@/assets/img/file-blue.svg'
import removeIcon from '@/assets/img/chat/remove-file.svg'

import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex'

export default {
    name: 'SendMsg',
    props: ['activeGroup'],
    components: {
        EditorContent,
        Picker,
        Checkbox,
        EditMessageInfo,
        FileUpload
    },
    data() {
        return {
            maxFileSize: 10485760,
            showPicker: false,
            emotesIcon,
            sendIcon,
            msg: '',
            enterSend: localStorage.getItem('enterSend') === 'true' ? true : false,
            editor: null,
            focus: false,
            bold,
            boldActive,
            italic,
            italicActive,
            highlight,
            highlightActive,
            left,
            leftActive,
            center,
            centerActive,
            orderedList,
            orderedListActive,
            bulletList,
            bulletListActive,
            undo,
            redo,
            link,
            check,
            fileIcon,
            removeIcon,
            files: [],
            highlightSwitcher: ['#FFD447', '#f78181', '#5CADFF', '#3ECE4F']
        }
    },
    mounted() {
        this.editor = new Editor({
            content: '',
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: this.$i18n.t('messages.write-smth')
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                Highlight.configure({ multicolor: true }),
                preventEnter
            ],
            onUpdate: ({editor}) => {
                this.msg = editor.getHTML()
                //this.$emit('scroll')
            },
            onFocus: () => {
                this.focus = true
            },
            onBlur: () => {
                this.focus = false
            }
        })
        this.$bus.$on('emoji', emoji => this.addEmoji(emoji))
        this.$bus.$on('sendGif', data => this.sendGif(data))
    },
    beforeDestroy() {
        this.$bus.$off('emoji')
        this.$bus.$off('sendGif')
    },
    methods: {
        togglePicker() {
            this.showPicker = !this.showPicker
        },
        hidePicker() {
            this.showPicker = false
        },
        send(btnClicked) {
            //remove spaces
            let msg = this.msg.replace(/\s/g, '')
            //remove html tags
            msg = msg.replace(/(<([^>]+)>)/gi, "")
            if((msg.length || this.files.length) && !this.tooLargeFileExists) {
                this.$bus.$emit('createMessage', {
                    uuid: this.editMessage?.uuid,
                    type: this.editMessage?.type,
                    content: this.createLinks(this.msg),
                    files: this.createFilesArray(this.files)
                })
                this.clearInput()

                if(btnClicked) {
                    this.$gtag({
                        category: 'btnClick',
                        label: 'Message sent'
                    })
                }
            }
            this.hidePicker()
            this.editor.chain().focus()
        },
        addEmoji(emoji) {
            this.editor.chain().focus().insertContent(emoji).run()
        },
        sendGif(data) {
            this.$bus.$emit('createMessage', {
                content: '',
                files: [],
                metadata: JSON.stringify({
                    gif: {
                        width: data.width,
                        height: data.height,
                        url: data.url
                    }
                })
            })
            this.hidePicker()
        },
        setFiles(data) {
            const imageTypes = ['image/gif', 'image/jpeg', 'image/png']
            let tooLargeFile = false
            const filesArray = data.target?.files || data

            filesArray.forEach(file => {
                if(file.size > this.maxFileSize) tooLargeFile = true

                const fileObject = {
                    file,
                    id: uuidv4()
                }

                if(imageTypes.includes(file.type)) fileObject.src = URL.createObjectURL(file)
                if(file.type === 'image') fileObject.src = file.path

                this.files.push(fileObject)
            })
            
            document.querySelector('.chat .file-upload input').value = ''

            if(tooLargeFile) {
                this.$notify({
                    title: this.$i18n.t('notify.large-file.title'),
                    text: this.$i18n.t('notify.large-file.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            }
        },
        removeFile(id) {
            this.files = this.files.filter(file => file.id != id)
        },
        keyDownHanlder() {
            if(this.showPicker) this.showPicker = false
        },
        createFilesArray(arr) {
            return arr.map(item => item.file)
        },
        createLinks(msg) {
            const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig
            return msg.replace(regex, '<a href="$1" target="_blank">$1</a>')
        },
        cancelEdit() {
            this.$store.commit('setEditMessage', null)
        },
        scrollToElement(uuid) {
            const el = document.querySelector(`.chat .msg[data-uuid="${ uuid }"]`)
            const messageList = document.querySelector('.chat .box-msg')

            setTimeout(() => {
                messageList.scroll({
                    top: el.offsetTop - (this.simpleChat ? 50 : 6),
                    behavior: 'smooth'
                })
            }, 0)
        },
        clearInput() {
            this.editor.commands.clearContent(true)
            this.files = []
        }
    },
    watch: {
        enterSend(n) {
            localStorage.setItem('enterSend', n)
            this.editor.chain().focus()
        },
        activeGroup() {
            this.clearInput()
            this.$store.commit('setEditMessage', null)
        },
        editMessage: {
            deep: true,
            handler(n) {
                this.clearInput()

                if(n) {
                    const { uuid, content, files, images } = n

                    this.editor.chain().focus().insertContent(JSON.parse(content)).run()
                    this.editor.chain().focus()
                    
                    this.files = []
                    const filesArray = [
                        ...files,
                        ...images.map(image => ({ ...image, type: 'image' }))
                    ]

                    this.setFiles(filesArray)
                    this.scrollToElement(uuid)
                }
            }
        }
    },
    filters: {
        cut(value, length) {
            if(!value) return ''
            if(value.length > length) return value.substring(0, length)+'...'
            return value
        }
    },
    computed: {
        ...mapState([
            'editMessage',
            'isMobileDevice'
        ]),
        simpleChat() {
            const routes = ['singleLesson']
            return routes.includes(this.$route.name)
        },
        tooLargeFileExists() {
            return this.files.some(item => item.file.size > this.maxFileSize)
        }
    }
}
</script>

<style>

</style>