const MATERIAL_STATES = {
    NOT_STARTED: 'NOT_STARTED',
    FINISHED: 'FINISHED',
    PENDING: 'PENDING',
}

const MATERIAL_TYPE = {
    LESSON: 'lesson',
    HOMEWORK: 'homework',
    PRELESSON: 'prelesson'
}

const PDF_TYPE = {
    LESSON: 'preview',
    HOMEWORK: 'homework',
    PRELESSON: 'preLesson',
    FLASHCARDS: 'flashcards'
}

export {
    MATERIAL_TYPE,
    MATERIAL_STATES,
    PDF_TYPE
}