<template>
    <div class="chat">
        <div v-if="simpleChat" class="simple-chat-header">
            <span class="title">{{ $t('chat.lesson-chat') }}</span>
            <router-link v-if="simpleChat" :to="{ name: 'messages', params: { groupUuid: activeGroup }}" class="messages-link sticky">{{ $t('single-lesson.lector-message') }}</router-link>
        </div>
        <div ref="boxMsg" class="box-msg" :class="{'skeletona': skeleton}">
            <div v-if="!isActive && !simpleChat" class="archive sticky"><img class="icon" src="@/assets/img/warning-500.svg" alt="">{{$i18n.t('messages.archive')}}</div>
            <template v-for="(msg, index) in msgs">
                <MessagesSeparator
                    v-if="showNewMessagesSeparator(msg.uuid)"
                    :key="index"
                    :text="$i18n.t('messages.new-messages')"
                    :simpleChat="simpleChat"
                    type="new"
                />
                <PlatformMessages 
                    v-if="showPlatformMessages(msg)" 
                    :key="msg.uuid" 
                    :msgs="msg.messagesFromPlatform.chatMessages"
                    :lesson="msg.messagesFromPlatform.lesson"
                    :isActive="isActive"
                    :simpleChat="simpleChat"
                />
                <SingleMessage  
                    v-if="!msg.messagesFromPlatform"
                    :key="msg.uuid"
                    :data="msg"
                    :isActive="isActive" 
                    :class="getGroupedClass(index)" 
                />
            </template>
            <div v-if="!messagesToShowLength" class="empty-state">
                <p class="title">{{$i18n.t(`messages.empty-state.${isActive ? '' : 'archival.'}title`)}}</p>
                <p v-if="isActive" class="subtitle">{{$i18n.t('messages.empty-state.subtitle')}}</p>
            </div>
        </div>
        <SendMsg v-if="isActive" ref="sendMsg" :activeGroup="activeGroup" @scroll="scrollDown"/>
        <div v-if="!isActive && simpleChat" class="archive-simple">{{$i18n.t('messages.archive-short')}}</div>
    </div>
</template>

<script>
import SingleMessage from './SingleMessage'
import SendMsg from './SendMsg'
import MessagesSeparator from './MessagesSeparator'
import PlatformMessages from './PlatformMessages'
import groupedMessages from '@/mixins/groupedMessages'
import { mapState } from 'vuex'

export default {
    name: 'Chat',
    props: ['msgs', 'activeGroup', 'isActive', 'simpleChat'],
    components: {
        SingleMessage,
        SendMsg,
        MessagesSeparator,
        PlatformMessages
    },
    mixins: [groupedMessages],
    data() {
        return {
            scrollHeight: null,
            scrollToDown: false,
            newGroup: false,
            skeleton: true
        }
    },
    mounted() {
        this.$refs.boxMsg.addEventListener('scroll', e => {
            if(e.target.scrollTop === 0 && e.target.scrollHeight > e.target.clientHeight) {
                this.scrollHeight = e.target.scrollHeight
                this.$emit('getPreviousMessages')
            }
        })
        this.scrollDown()
    },
    computed: {
        ...mapState([
            'editMessage'
        ]),
        messagesToShowLength() {
            return this.msgs.filter(msg => !msg.messagesFromPlatform || msg.messagesFromPlatform.chatMessages.length).length
        }
    },
    methods: {
        scrollDown() {
            setTimeout(() => {
                const boxMsg = this.$refs.boxMsg
                boxMsg.scrollTo(0, boxMsg.scrollHeight)
                this.skeleton = false
            }, 1)
        },
        showNewMessagesSeparator(uuid) {
            return uuid === this.$store.state.newMessagesSeparator
        },
        showPlatformMessages(msg) {
            return msg.messagesFromPlatform?.chatMessages.length
        }
    },
    watch: {
        msgs(n, o) {
            this.newGroup = false
            if(n[0] && o[0] && n[0].uuid === o[0].uuid) this.scrollToDown = true
        },
        activeGroup() {
            this.scrollHeight = null
            this.scrollToDown = true
            this.newGroup = true
        }
    },
    updated() {
        if(!this.newGroup && !this.editMessage) {
            if(this.scrollToDown) this.$refs.boxMsg.scrollTo(0, this.$refs.boxMsg.scrollHeight)
            else this.$refs.boxMsg.scrollTo(0, this.$refs.boxMsg.scrollHeight - this.scrollHeight)
            this.scrollToDown = false
        }
    }
}
</script>
