import gql from 'graphql-tag'

export const teacherAIStart = gql`
    {
        teacherAI {
            canSendMessages
            isFirstTime
            chat {
                suggestions {
                    uuid
                    type
                    prompt
                }
                messages {
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                    edges {
                        cursor
                        node {
                            uuid
                            author
                            content
                            createdAt
                            suggestion {
                                uuid
                                type
                                prompt
                            }
                            game {
                                uuid
                                name
                                gameType {
                                    uuid
                                    type
                                    prompt
                                }
                            }
                        }
                    }
                }
            }
        }
        teacherAIChatSuggestionsList(filters: {}, first: 1) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    uuid
                }
            }
        }
        teacherAIGamesList(filters: {}, first: 1) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    uuid
                }
            }
        }
    }
`