import isDateBetweenFilterRanges from '@/mixins/schedule/isDateBetweenFilterRanges';
import { mapState } from 'vuex';

export default {
    mixins: [isDateBetweenFilterRanges],
    data() {
        return {
            selectedSlot: null
        }
    },
    created() {
        this.$bus.$on('resetDateFilter', () => this.selectedSlot = null)
    },
    computed: {
        ...mapState('schedule', ['switchLector'])
    },
    beforeDestroy() {
        this.$bus.$off('resetDateFilter')
    },
    methods: {
        checkDateFilter() {
            this.days.find(day => {
                return day.slots.find(slot => {
                    return this.selectedSlot = this.isDateBetweenFilterRanges(slot.startAt) ? slot : null
                })
            })
            
            if(!this.selectedSlot) this.setLectorFilterStatus()
        },
        checkAdvanceNoticeFilter() {
            let atLeastOneAdvanceNoticeSlot

            this.days.find(day => {
                return day.slots.find(slot => {
                    return atLeastOneAdvanceNoticeSlot = this.$moment(slot.startAt).isBefore(this.$moment().add(24, 'hours'))
                })
            })

            if(!atLeastOneAdvanceNoticeSlot) this.setLectorFilterStatus()
        },
        setLectorFilterStatus() {
            const lectors = this.switchLector.data.filter(item => item.node.uuid === this.lector.uuid)
                
            setTimeout(() => {
                lectors.forEach(lector => lector.node.matchFilters = false)
            }, 0)
        }
    }
}