<template>
    <div class="progress-bar-content">
        <div class="title">{{ data.title }}</div>
        <div class="progress-bar">
            <div v-for="(status, idx) in prepareData" :key="idx" :class="['info-tooltip', status.type]" :style="{ width: status.percent + '%' }">
                <Tooltip v-if="status.type !== 'new' && !isMobile" :text="handleText(status)"/>
            </div>
        </div>
        <div class="stats">
            <span class="percent">{{ getLessonFinishedPercent }}%</span>
            <span class="count">{{ data.results.completed }}/{{ data.totalCount }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/reusable/Tooltip';

export default {
    name: 'ProgressBar',
    props: ['data'],
    components: { Tooltip },
    data() {
        return {
            finishedPercent: null
        }
    },
    computed: {
        ...mapState(['isMobile']),
        prepareData() {
            const onePercent = 100 / this.data.totalCount;
            const data = Object.keys(this.data.results).map(type => {
                if (this.data.results[type] > 0) {
                    return { type, percent: onePercent * this.data.results[type], count: this.data.results[type] };
                }
            }).filter(item => item !== undefined);

            const rest = 100 - data.reduce((sum, item) => sum + item.percent, 0);

            if (rest > 0) data.push({ type: 'new', percent: rest });

            return data;
        },
        getLessonFinishedPercent () {
            const onePercent = 100 / this.data.totalCount;

            return Math.round(this.data.results.completed * onePercent); 
        }
    },
    methods: {
        handleText(status) {
            return this.$i18n.t(`course-plan.progreess-bar.type.${ status.type }`)
                + ` (${ status.count })`;
        }
    }
}
</script>
<style lang="scss" scoped>
.progress-bar-content {
    .title {
        @include font-medium;
        padding-bottom: 8px;
    }

    .progress-bar {
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 2px;

        :first-child {
            border-radius: 99px 0px 0px 99px;
        }

        :last-child {
            border-radius: 0px 99px 99px 0px;
        }

        :only-child {
            border-radius: 99px;
        }

        .info-tooltip {
            height: 4px;
            position: relative;

            &:hover {
                .tooltip {
                    display: block;
                    border-radius: 4px;
                }
            }

            &.started {
                background: $warning;
            }

            &.completed {
                background: $secondary;
            }

            &.overdue, &.words {
                background: $orange;
            }

            &.new {
                background: $gray-200;
            }
        }
    }

    .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;

        .percent, .count {
            @include font-medium;
            color: $gray-600;
        }
    }
}
</style>

