import gql from 'graphql-tag'

export const availableCoursePlans = gql`
    query {
        availableCoursePlans {
            groupUuid
            language {
                name
                shortName
            }
            languageLevel {
                name
                shortName
                isBusiness
            }
            isBusiness
        }
    }
`