<template>
    <div class="sidebar">
        <!-- <div class="hide">
            <img src="../../assets/img/chat/hide.svg" alt="Hide">
        </div> -->
        <div class="conversations" :class="{'active': showGroups}">
            <GroupSummary
                v-for="group in groups"
                :key="group.uuid"
                :group="group"
                @click.native="changeActiveGroup(group.uuid)"
                :class="{'active': activeGroup === group.uuid}"
                class="item"
            />
                <Button 
                    v-if="$route.name === 'messages' && bothGroupTypes" 
                    class="change-mode"
                    :text="$i18n.t(`messages.${showArchival ? 'hide' : 'show'}-archival`)"
                    @click.native="$emit('toggleArchival', !showArchival)"
                />
                <div 
                    :class="[{'active': showArchival}, 'archival-list']"
                    ref="archival-list"
                    :style="archivalListStyle"
                >
                    <GroupSummary
                        v-for="group in archivalGroups" 
                        :key="group.uuid" 
                        :group="group"
                        @click.native="changeActiveGroup(group.uuid)" 
                        :class="{'active': activeGroup === group.uuid}" 
                        class="item"
                    />
                </div>
        </div>
        <template v-if="groups.length || archivalGroups.length">
            <div v-if="activeGroupObject.group.type === 'group'" class="group-members">
                <p class="label">{{$i18n.t('messages.group-members')}}</p>
                <span class="member" v-for="(item, index) in activeGroupObject.receivers" :key="item.uuid">
                    {{item.firstName}}<span class="lector" v-if="item.role === 'user' || item.role === 'current_user'"> ({{$i18n.t('general.lector')}})</span><template v-if="index < activeGroupObject.receivers.length-1">, </template>
                </span>
            </div>
            <div class="assets" :class="{'active': showAssets}">
                <div class="header">
                    <span @click="$emit('hideAssets')" class="hide-files"><img src="../../assets/img/arrow-dark.svg" alt=""></span><p class="label">{{$i18n.t('messages.assets')}}</p>
                </div>
                <AssetsItem v-for="item in activeGroupAssets" :key="item.type" :activeAssets="activeAssets" :data="item" @changeActiveAssets="changeActiveAssets"/>
            </div>
        </template>
    </div>
</template>

<script>
import AssetsItem from './AssetsItem'
import GroupSummary from './GroupSummary'
import documents from '../../assets/img/chat/documents.svg'
import documentsActive from '../../assets/img/chat/documents-active.svg'
import images from '../../assets/img/chat/images.svg'
import imagesActive from '../../assets/img/chat/images-active.svg'
import links from '../../assets/img/chat/links.svg'
import linksActive from '../../assets/img/chat/links-active.svg'
import Button from '@/components/reusable/Button'

export default {
    name: 'Sidebar',
    props: ['groups', 'archivalGroups', 'activeGroup', 'activeGroupObject', 'showGroups', 'showAssets', 'showArchival'],
    components: {
        AssetsItem,
        GroupSummary,
        Button
    },
    data() {
        return {
            activeAssets: null,
            archivalListHeight: null
        }
    },
    mounted() {
        this.archivalListHeight = this.$refs['archival-list'].scrollHeight
    },
    methods: {
        changeActiveGroup(id) {
            this.$emit('changeActiveGroup', id)
        },
        changeActiveAssets(assets) {
            this.activeAssets = assets === this.activeAssets ? null : assets
        }
    },
    computed: {
        activeGroupAssets() {
            return [
                {
                    type: 'documents',
                    label: this.$i18n.t('messages.documents'),
                    arr: this.activeGroupObject.messageFiles,
                    icon: documents,
                    activeIcon: documentsActive
                },
                {
                    type: 'images',
                    label: this.$i18n.t('messages.images'),
                    arr: this.activeGroupObject.messageImages,
                    icon: images,
                    activeIcon: imagesActive
                },
                {
                    type: 'links',
                    label: this.$i18n.t('messages.links'),
                    arr: this.activeGroupObject.messageLinks,
                    icon: links,
                    activeIcon: linksActive
                }
            ]
        },
        bothGroupTypes() {
            return this.groups.length && this.archivalGroups.length
        },
        archivalListStyle() {
            let style
            if(this.showArchival) style = {height: this.archivalListHeight+'px'}
            return style
        }
    },
    watch: {
        activeGroup() {
            this.activeAssets = null
        }
    }
}
</script>

