const apiUrlForThisLector = {
    methods: {
        apiUrlForThisLector(lectorUuid) {
            const studentUuid = this.$store.state.user?.student?.uuid;
            const groupUuid = this.$store.state.schedule.selectedCourse.uuid || 'new-group';

            return `booking/lesson/slots/${ studentUuid }/${ groupUuid }/${ lectorUuid }`;
        }
    }
}

export default apiUrlForThisLector;