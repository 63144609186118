<template>
    <div class="learning-progress">
        <div class="learning-progress-bar">
            <div class="bar green" :style="{ width: `${ getProgressPercent }%` }"></div>
            <div v-if="getProgressPercent !== 100" class="bar gray" :style="{ width: `${ 100 - getProgressPercent }%` }"></div>
        </div>
        <div v-if="text" class="text-wrapper">
            <span class="text">{{ text }}</span>
            <span class="percent">{{ Math.round(getProgressPercent) }}%</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'LearningProgress',
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('learning', ['getProgressPercent'])
    }
}
</script>

<style lang="scss" scoped>
.learning-progress {
    width: 100%;

    .learning-progress-bar {
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 2px;

        :first-child {
            border-radius: 99px 0px 0px 99px;
        }

        :last-child {
            border-radius: 0px 99px 99px 0px;
        }

        :only-child {
            border-radius: 99px;
        }

        .bar {
            height: 8px;

            &.green {
                background: #3ECE4F;
            }
        
            &.gray {
                background: $gray-200;
            }
        }
    }

    .text-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;

        .text {
            @include font-medium;
            color: $gray-500;
        }

        .percent {
            @include font-medium;
            color: $gray-600;
        }
    }
}
</style>