<template>
    <div class="widget welcome">
        <img 
            src="@/assets/img/dashboard/confetti1.svg" 
            alt=""
            width="80"
            height="80"
        >
        <h3 class="welcome__title">{{ $i18n.t('dashboard.welcome.title') }}</h3>
        <p v-if="!getLanguageLevel" class="welcome__subtitle">{{ $i18n.t('dashboard.welcome.placement-test.paragraph') }}</p>
        <p v-else class="welcome__subtitle">{{ $i18n.t('dashboard.welcome.subtitle.text') }}</p>
        <div class="welcome__buttons-wrapper">
            <BookLessonBtn placement="welcomeWidgetButton"/>
            
            <Button
                v-if="getLanguageLevel && !data.oralPlacementTest"
                class="secondary" 
                :text="$i18n.t('dashboard.welcome.course-plan.btn')"
                @click.native="openCoursePlan"
            />
            <Button
                v-else
                class="secondary" 
                :text="$t(placementTestBtnText)"
                @click.native="openPlacementTest"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Button from '@/components/reusable/Button'
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'

export default {
    name: 'Welcome',
    props: ['data'],
    components: {
        Button,
        BookLessonBtn
    },
    data() {
        return {
            bookLoading: false
        }
    },
    computed: {
        ...mapState({ loading: state => state.schedule.triggers.welcomeWidgetButton.loading }),
        ...mapGetters(['getLanguageLevel', 'getHavePackage']),
        ...mapGetters('coursePlan', ['chosenCoursePlanUuid']),
        placementTestBtnText() {
            const { placementTest, oralPlacementTest } = this.data

            return [placementTest?.status, oralPlacementTest?.status].includes('IN_PROGRESS')
                ? 'dashboard.welcome.placement-test.btn-in-progress'
                : 'dashboard.welcome.placement-test.btn'
        }
    },
    methods: {
        openCoursePlan() {
            this.$router.push({ name: 'coursePlan', params: { uuid: this.chosenCoursePlanUuid } })
        },
        openPlacementTest() {
            this.$router.push({ name: 'placementTest' })
        }
    }
}
</script>

<style lang="scss" scoped>
.welcome {
    background-image: url('~@/assets/img/dashboard/confetti2.svg');
    background-repeat: no-repeat;
    background-position: top right;

    &__title {
        @include font-h2;
        color: $gray-900;
        margin: 16px 0 8px;
    }

    &__subtitle {
        @include font-regular-text;
        color: $gray-900;
        margin-bottom: 16px;
    }

    &__buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>