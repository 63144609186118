<template>
    <div :class="['course-container', { 'course-container--mobile': isMobile, 'course-container--disabled': !getLanguageLevel }]">
        <div v-if="isMobile" class="course-container__title">{{ $t('elearning.content.course-modules') }}</div>
        <template v-if="loading">
            <ContentSkeleton v-for="n in 8" :key="n"/>
        </template>
        <template v-else v-for="(material, idx) in getLearningCoursePlanModules">
            <div class="accordion-wrapper" :key="idx">
                <Tooltip v-if="isDisabled(idx, material) && !isMobile" :text="$t('elearning.course-plan.module.lock')"/>
                <Accordion
                    @clicked="expandAccordion(idx)"
                    :class="{ 'accordion--next-module': idx === getFirstNewMaterial, 'accordion--completed': isMaterialFinished(material.materials) }"
                    :disabled="isDisabled(idx, material)"
                    :data-material-id="idx"
                    :isExpanded="expanded === idx"
                    :active="expanded === idx"
                    coursePlanAccordion
                    rotateArrows
                >
                    <template v-slot:header>
                        <div class="header-wrapper">
                            <span class="title">{{ material.title }}</span>
                            <div class="module-wrapper">
                                <span class="module-text">{{ $t('elearning.content.module') }} {{ idx + 1 }}</span>
                                <DottedLine :statuses="getStatuses(material.materials)" onlyBars elearning/>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <ModuleBtns :modules="material.materials" :materialId="idx"/>
                    </template>
                </Accordion>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import timezoneDate from '@/functions/timezoneDate';
import Accordion from '@/components/reusable/Accordion';
import DottedLine from '@/components/coursePlan/DottedLine';
import ModuleBtns from '@/components/learning/coursePlan/ModuleBtns';
import ContentSkeleton from '@/components/learning/coursePlan/skeletons/ContentSkeleton';
import Tooltip from '@/components/reusable/Tooltip';

export default {
    name: 'Content',
    components: {
        Accordion,
        DottedLine,
        ModuleBtns,
        ContentSkeleton,
        Tooltip
    },
    data() {
        return {
            timezoneDate,
            expanded: null
        }
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('learningCoursePlan', ['loading']),
        ...mapGetters('learningCoursePlan', ['getLearningCoursePlanModules']),
        ...mapGetters(['getLanguageLevel']),
        getFirstNewMaterial() {
            return this.getLearningCoursePlanModules?.findIndex((module) => module.materials.find((material) => ['NEW', 'IN_PROGRESS'].includes(material.state)));
        }
    },
    methods: {
        expandAccordion(idx) {
            if (this.expanded === idx) {
                this.expanded = null;
                return;
            }

            this.expanded = idx;

            if(this.isMobile) return;
            setTimeout(()=> {
                window.scrollTo({
                    top: document.querySelectorAll('.course-container .accordion')[idx]?.offsetTop - 90,
                    behavior: 'smooth'
                });
            }, 500)
        },
        isDisabled(idx, material) {
            if (material.materials.map(el => el.state).filter(el => el === 'COMPLETED').length === material.materials.length) return false;
            else return idx > this.getFirstNewMaterial;
        },
        isMaterialFinished(materials) {
            const materialsStatuses = materials.map(el => el.state);

            if(materialsStatuses.filter(el => el === 'COMPLETED').length === materials.length) return true;
        },
        getStatuses(materials) {
            return materials.map(el => el.state.toLowerCase());
        },
        handleScroll() {
            let currentQueryParams = { ...this.$route.query };

            if (currentQueryParams.materialID) {
                this.expanded = parseInt(this.$route.query.materialID);

                delete currentQueryParams.materialID;

                this.$router.push({ query: currentQueryParams });

            }
            else this.expanded = this.getFirstNewMaterial === -1 ? this.getLearningCoursePlanModules.length - 1 : this.getFirstNewMaterial;

            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelectorAll('.course-container .accordion')[this.expanded]?.offsetTop - 90,
                    behavior: 'smooth'
                });
            }, 500)
        }
    },
    watch: {
        getLearningCoursePlanModules() {
            this.$nextTick(() => {
                this.handleScroll();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.course-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    .accordion-wrapper {
        position: relative;

        &:hover {
            .tooltip {
                display: block;
                border-radius: 4px;
            }
        }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
        cursor: default;
    }

    &__title {
        @include font-h2;
        line-height: 25px;
        color: $gray-600;
        margin-top: 16px;
    }

    .accordion {
        background-color: $white;
        border-radius: 10px;

        &--next-module {
            border: 2px solid $primary;
        }

        &--completed {
            border: 2px solid $secondary-mid;

            .title {
                color: $secondary-dark;
            }
        }

        .header-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .title {
                @include font-h3;
            }
            
            .module-wrapper {
                display: flex;
                gap: 10px;

                .module-text {
                    @include font-medium;
                    color: $gray-600;
                }

                ::v-deep {
                    .status-bar {
                        .info-tooltip {
                            width: 32px;
                        }
                    }
                }
            }
        }

        ::v-deep {
            .accordion__arrow {
                align-self: center;
                margin-top: 0;
                width: 20px;
                height: 20px;
            }

            .accordion__content {
                padding: 0px;
                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset;
            }

            .accordion__header {
                padding: 16px 24px;
            }
        }
    }

    &--mobile {
        gap: 12px;

        .dotted-line {
            margin-top: 12px
        }

        .course-container__title {
            margin-left: 16px;
        }

        ::v-deep {
            .accordion__arrow {
                align-self: flex-start !important;
                mask-image: url("~@/assets/img/learning/coursePlan/mobile-arrow.svg");
            }
        }
    }
}
</style>