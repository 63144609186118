<template>
    <div 
        @click="play"
        :class="[
            'flashcards__audio', 
            { 'flashcards__audio--small': small, 'flashcards__audio--disabled': loading }
        ]"
    >
        <Loading v-if="loading" :size="small ? 10 : 20" />
        <div class="flashcards__audio-wrapper" v-else>
            <span v-if="!small" class="audio-text">{{ isPlaying ? $t('flashcards-audio.pause') : $t('flashcards-audio.play') }}</span>
            <img class="audio-icon" :src="require(`@/assets/img/flashcards/${ handleFlashcardsAudioIcon }.svg`)" alt="">
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Loading from '@/components/reusable/Loading'

export default {
    name: 'FlashcardsAudio',
    props: {
        small: Boolean
    },
    components: {
        Loading
    },
    data() {
        return {
            loading: false,
            audio: null,
            isComponentAlive: true,
            isPlaying: false
        }
    },
    async created() {
        if(window.Cypress) return

        this.handleBusEmit()

        this.audio = document.createElement('audio')
        this.audio.autoplay = true

        this.audio.addEventListener('ended', this.handleAudioEnded)

        const { english } = this.getCurrentExercise

        if(this.audios[english]) this.audio.src = this.audios[english]
        else {
            this.loading = true

            const src = await this.$store.dispatch('getSynthesizeText', {
                text: this.getCurrentExercise.english
            })

            this.audios[english] = src

            if(this.isComponentAlive) this.audio.src = src
            this.loading = false
        }
    },
    beforeDestroy() {
        this.isComponentAlive = false
        this.audio?.pause()
        this.audio.removeEventListener('ended', this.handleAudioEnded)
    },
    computed: {
        ...mapState('flashcards', ['audios']),
        ...mapGetters('flashcards', ['getCurrentExercise']),
        ...mapGetters('learning', ['getCurrentSlide']),
        ...mapGetters(['isLearningMode']),
        handleFlashcardsAudioIcon() {
            return this.small ? 'volume-black' : this.isPlaying? 'pause' : 'volume'
        }
    },
    methods: {
        play() {
            if (this.loading) return

            if (this.audio.paused) {
                this.audio.play();
                this.isPlaying = true;
            }
            else {
                this.audio.pause()
                this.audio.currentTime = 0
                this.isPlaying = false;
            }
        },
        handleBusEmit() {
            this.$bus.$off('flashcardsAudio')

            this.$bus.$on('flashcardsAudio', (uuid) => {
                if (this.isLearningMode && uuid !== this.getCurrentSlide.uuid) return;

                this.play();

                if(this.audio.paused) this.play();

                this.isPlaying = true;
            })
        },
        handleAudioEnded() {
            this.isPlaying = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__audio {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: all .25s;
    flex-shrink: 0;
    border-radius: 100px;
    background: linear-gradient(0deg, #E6F3FF 0%, #E6F3FF 100%), #FFF;

    &:hover {
        background: linear-gradient(0deg, #DDEAF6 0%, #DDEAF6 100%), #FFF;
    }

    &--disabled {
        pointer-events: none;
    }
}

.flashcards__audio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px 14px;

    .audio-icon {
        width: 20px;
        height: 20px;
    }

    .audio-text {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.4px;
        color: $primary;
        flex-shrink: 0;
    }
}

.flashcards__audio--small {
    width: 32px;
    height: 32px;
    border-radius: 99px;
    background: $gray-100;

    &:hover {
        background: linear-gradient(0deg, #DDEAF6 0%, #DDEAF6 100%), #FFF;
    }

    .loading {
        padding: 4px;
    }

    .flashcards__audio-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;

        .audio-icon {
            min-width: 16px;
            height: 16px;
        }
    }
}
</style>
