<template>
    <div class="list">
        <div class="switcher">
            <p class="date">{{$moment(date).format('MMMM YYYY')}}</p>
            <div class="controls">
                <div class="control" :class="{'disabled': checkBorder(0).first <= 0 || (checkBorder(0).first > 0 && checkBorder(0).last > 0)}" @click="changeMonth(-1)">
                    <img :src="arrow" alt="">
                </div>
                <div class="control" :class="{'disabled': checkBorder(0).last >= 0 || (checkBorder(0).first < 0 && checkBorder(0).last < 0)}" @click="changeMonth(1)">
                    <img :src="arrow" alt="">
                </div>
            </div>
        </div>
        <div class="lessons-list">
            <div class="filters">
                <div v-for="{id, label} in filters" :class="['filter', id]" :key="id"><span>{{label}}</span></div>
                <!-- <div v-for="{id, label} in filters" :class="['filter', id]" :key="id"><span @click="sortLessons(id)">{{label}}</span><img v-if="sort.col === id" :class="{'dsc' : !sort.asc}" src="../../assets/img/arrow-dark.svg" alt=""></div> -->
            </div>
            <template v-if="loading">
                <Skeleton v-for="n in 3" :key="n"/>
            </template>
            <template v-else>
                <SingleLesson v-for="item in sortedLessons" :key="item.lesson.uuid" :class="{'active': activeDays.includes(parseInt($moment(item.lesson.startAt).format('D')))}" :data="item"/>
                <div v-if="noLessons" class="empty-state">
                    <template v-if="getHavePackage || getStudentHadLesson">
                        <img src="@/assets/img/graduate-warning.svg" alt="">
                        <p class="title">{{ $t('lessons.empty-state.past.title') }}</p>
                    </template>
                    <template v-else>
                        <img src="@/assets/img/graduate-purple.svg" alt="">
                        <p class="title">{{ $t('lessons.empty-state.title') }}</p>
                        <p class="subtitle">{{ $t('lessons.empty-state.subtitle') }}</p>
                    </template>
                    <BookLessonBtn placement="myLessonsPageButton"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Skeleton from '@/components/lessons/Skeleton'
import SingleLesson from '@/components/lessons/SingleLesson'
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'
import { lessonsByPeriod } from '@/graphql/queries/lessonsByPeriod'
import api from '@/graphql/api.js'
import arrow from '@/assets/img/arrow-dark.svg'
import { mapGetters } from 'vuex'

export default {
    name: 'List',
    props: ['border'],
    components: {
        BookLessonBtn,
        SingleLesson,
        Skeleton
    },
    data() {
        return {
            date: this.$moment(`${this.$route.params.year}-${this.$route.params.month}`),
            lessons: null,
            filters: [
                {
                    id: 'date',
                    label: this.$i18n.t('lessons.date')
                },
                {
                    id: 'subject',
                    label: this.$i18n.t('lessons.subject')
                },
                {
                    id: 'lector',
                    label: this.$i18n.t('lessons.lector')
                },
                {
                    id: 'status',
                    label: this.$i18n.t('lessons.status')
                },
                {
                    id: 'presence',
                    label: this.$i18n.t('lessons.presence')
                }
            ],
            sort: {
                col: 'date',
                asc: true
            },
            activeDays: [],
            loading: true,
            arrow,
        }
    },
    async created() {
        this.$bus.$on('makeLessonActive', day => this.makeLessonActive(day))
        this.$bus.$on('updateLessons', async () => {
            await this.getLessons()
        })
        const {first, last} = this.checkBorder(0)
        if(first < 0 || last > 0) {
            this.date = this.$moment(first < 0 ? this.border.first : this.border.last).startOf('month')
            this.replaceUrl()
        }
        this.sendDate()
        await this.getLessons()
        this.loading = false
    },
    beforeDestroy() {
        this.$bus.$off('makeLessonActive')
        this.$bus.$off('updateLessons')
    },
    methods: {
        changeMonth(dir) {
            const {first, last} = this.checkBorder(dir)
            if(first >= 0 && last <= 0) {
                this.date = this.$moment(this.date).add(dir, 'months')
                this.replaceUrl()
                this.sendDate()
                this.getLessons()
            }
        },
        checkBorder(dir) {
            let date = this.$moment(this.date).add(dir, 'months')
            const first = this.$moment(date).diff(this.$moment(this.border.first).startOf('month'), 'months')
            const last = this.$moment(date).diff(this.$moment(this.border.last).startOf('month'), 'months')
            return {
                first,
                last
            }
        },
        sendDate() {
            this.$bus.$emit('lessonsDate', this.date)
        },
        sendDays(val) {
            this.$bus.$emit('lessonsDays', val)
        },
        async getLessons() {
            const {res} = await api(lessonsByPeriod, {
                since: this.$moment(this.date).startOf('month'),
                until: this.$moment(this.date).endOf('month')
            }, 'lessonsByPeriod')

            this.lessons = res.currentUser.student.studentLessonsByPeriod
            
            const lessonsDays = {}
            this.lessons.forEach(item => {
                const property = this.$moment(item.lesson.startAt).format('D')
                if(!lessonsDays[property]) lessonsDays[property] = {status: [], hasParticipated: []}
                lessonsDays[property].status.push(item.lesson.status)
                if(item.isIncludedInParticipation) lessonsDays[property].hasParticipated.push(item.hasParticipated ? 'on' : 'off')
            })
            this.sendDays(lessonsDays)

            const calendarSummary = {}
            calendarSummary.finished = this.lessons.filter(item => item.lesson.status === 'finished').length
            calendarSummary.scheduled = this.lessons.filter(item => item.lesson.status === 'scheduled').length
            calendarSummary.frequency = this.calculateFrequency()
            this.$bus.$emit('calendarSummary', this.lessons.length ? calendarSummary : null)
        },
        sortLessons(col) {
            if(col === this.sort.col) this.sort.asc = !this.sort.asc
            else {
                this.sort.col = col
                this.sort.asc = true
            }
        },
        makeLessonActive(day) {
            this.activeDays.push(day)
            setTimeout(() => {
                const index = this.activeDays.indexOf(day)
                this.activeDays.splice(index, 1)
            }, 1000)
        },
        calculateFrequency() {
            let all = 0
            let participated = 0

            this.lessons.map(item => {
                if(item.isIncludedInParticipation) all++
                if(item.isIncludedInParticipation && item.hasParticipated) participated++
            })

            const output = participated / all * 100
            return isNaN(output) ? `${0}%` : `${Math.round(output)}%`
        },
        replaceUrl() {
            this.$router.replace({name: 'lessons', params: {
                year: this.$moment(this.date).format('YYYY'), 
                month: this.$moment(this.date).format('MM')
            }})
        }
    },
    computed: {
        ...mapGetters(['getStudentHadLesson', 'getHavePackage']),
        noLessons() {
            const value = !this.lessons?.length

            this.$emit('noLessons', value)

            return value
        },
        pastMonth() {
            return this.$moment(this.date).diff(this.$moment().startOf('month'), 'months') < 0
        },
        sortedLessons() {
            const lessons = this.lessons

            const compareStrings = (a, b) => {
                if(this.sort.asc) return a.localeCompare(b)
                else return b.localeCompare(a)
            }

            const compareNumbers = (a, b) => {
                if(this.sort.asc) return a - b
                else return b - a
            }

            if(lessons) {
                if(this.sort.col === 'date') lessons.sort((a, b) => compareNumbers(this.$moment(a.lesson.startAt).valueOf(), this.$moment(b.lesson.startAt).valueOf()))
                else if(this.sort.col === 'subject') lessons.sort((a, b) => compareStrings(a.lesson.lessonSubjects[0].subject, b.lesson.lessonSubjects[0].subject))
                else if(this.sort.col === 'lector') lessons.sort((a, b) => compareStrings(a.lesson.user.firstName, b.lesson.user.firstName))
                else if(this.sort.col === 'status') lessons.sort((a, b) => compareStrings(a.lesson.status, b.lesson.status))
            }

            return lessons
        }
    }
}
</script>
