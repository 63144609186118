<template>
    <div class="learning-summary">
        <Loading v-if="submitLoading" :size="30" />
        <div v-else class="progress-container">
            <template v-if="handlePercent !== 100">
                <div class="text-wrapper front">
                    <span class="title">{{ $t('learning.summary.almost-done.title', transUi) }}</span>
                    <span class="subtitle">{{ $t('learning.summary.almost-done.description', transUi) }}</span>
                </div>
            </template>
            <div class="circle-progress">
                <div :class="['stat-circle-wrapper', { 'success': handlePercent == 100 }]">
                    <div class="stat-circle-bg"></div>
                    <CircleProgress :percentage="handlePercent" :duration="audioDuration" color="green"/>
                    <template v-if="handlePercent == 100 && !isMobile">
                        <ConfettiCanon :config="{ angle: 145, spread: 90 }"/>
                        <ConfettiCanon :config="{ angle: 35, spread: 90 }"/>
                    </template>
                    <ConfettiCanon v-else-if="handlePercent == 100 && isMobile" :config="{ angle: 90, spread: 60 }"/>
                </div>
            </div>
            <div v-if="handlePercent === 100" class="text-wrapper bottom">
                <span class="title">{{ $t('learning.summary.amazing-job.title', transUi) }}</span>
                <span class="subtitle">{{ $t('learning.summary.amazing-job.description', transUi) }}</span>
                <div class="pill-wrapper">
                    <img src="@/assets/img/learning/green-check-wrapper.svg"/>
                    <span class="pill-wrapper__text">{{ $t('learning.summary.pill.next-lesson-info', transUi) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CircleProgress from '@/components/reusable/CircleProgress';
import ConfettiCanon from '@/components/reusable/ConfettiCanon';
import Loading from '@/components/reusable/Loading';
import finishedAudio from '@/assets/finished.mp3';

export default {
    name: 'Summary',
    components: { CircleProgress, ConfettiCanon, Loading },
    computed: {
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapState('flashcards', ['submitLoading']),
        ...mapGetters('learning', ['getProgressPercent', 'isMobile', 'isPreviewMode']),
        handlePercent() {
            return this.isPreviewMode ? 100 : this.getProgressPercent;
        }
    },
    data() {
        return {
            audioDuration: 0,
            audio: null,
            watcher: null
        };
    },
    mounted() {
        if(this.submitLoading) {
            this.watcher = this.$watch('submitLoading', () => {
                this.playAudioSegment();
                this.watcher();
            })
        } else this.playAudioSegment();
    },
    beforeDestroy() {
        this.audio.removeEventListener('loadedmetadata', this.playAudioSegmentHandler);
    },
    methods: {
        playAudioSegment() {
            this.audio = new Audio(finishedAudio);
            this.audio.addEventListener('loadedmetadata', this.playAudioSegmentHandler);
        },
        playAudioSegmentHandler() {
            this.audioDuration = this.audio.duration;
            this.audio.play();
        }
    }
}
</script>

<style lang="scss" scoped>
.learning-summary {
    width: 100%;

    .title-wrapper {
        margin-top: 20px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 1px solid $gray-200;

        .subject {
            color: $gray-600;
            @include font-h3;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: -0.4px;
        }
    }

    .module-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    .module {
        display: flex;
        align-items: center;
        gap: 4px;
    
        &__name {
            padding: 6px 12px;
            border-radius: 24px;
            border: 1px solid $gray-200;
            color: $gray-600;
            @include font-h6-uc;
        }
    
        &__arrow {
            mask-image: url('~@/assets/img/arrow-dark.svg');
            width: 16px;
            height: 16px;
            background-color: $gray-600;
            transform: rotate(270deg);
        }
    }

    .progress-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 32px;
    }

    .circle-progress {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .pill-wrapper {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
        border: 1px solid $gray-200;
        border-radius: 100px;
        padding: 12px 20px 12px 16px;
        margin-top: 18px;
        opacity: 0;
        animation: fade-in 1.5s ease-out forwards .5s;

        &__text {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: $gray-900;
        }

        img {
            width: 24px;
            height: 24px;
        }

        @media(min-width: $mobile-learning) {
            img {
                width: 32px;
                height: 32px;
            }

            &__text {
                font-size: 17px;
                line-height: 22px;
            }
        }
    }

    .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 6px;

        &.front {
            margin-bottom: 32px;
        }

        &.bottom {
            margin-top: 32px;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: -0.4px;

            @media(min-width: $mobile-learning) {
                font-size: 36px;
                line-height: 40px;
            }
        }

        .subtitle {
            color: $gray-600;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: -0.4px;

            @media(min-width: $mobile-learning) {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
    
    .btn-wrapper {
        width: 167px;
        display: none;
        margin-top: 32px;
        flex-direction: column;
        gap: 16px;

        @media(min-width: $mobile-learning) {
            display: flex;
        }
    }
}

.stat-circle-wrapper {
    position: relative;
    width: 164px;
    height: 164px;

    &.success {
        .stat-circle-bg {
            display: block;
        }    

        ::v-deep text {
            fill: $secondary-dark;
        }
    }
}

.stat-circle-bg {
    display: none;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        z-index: 1;
        width: 144px;
        height: 144px;
        background: $secondary-light;
        border-radius: 50%;
        animation: fade-in 1.5s ease-out forwards;
    }
}

::v-deep {
    .stat-circle {
        position: relative;
        z-index: 2;
        width: 164px;
        height: 164px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>