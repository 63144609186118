<template>
    <div>
        <Skeleton v-if="loading" :simpleChat="simpleChat"/>
        <div v-else class="messages">
            <template v-if="allGroups.length">
                <div v-if="mobileView && !simpleChat" class="mobile-nav">
                    <div
                        @click="showGroups = true"
                        :class="[
                            'show-groups', 
                            {
                                'show-groups--notification': unreadMessagesCount, 
                                'show-groups--hide': $route.name === 'messagesOnly'
                            }
                        ]"
                    >
                        <img src="../assets/img/arrow-dark.svg" alt="">
                    </div>
                    <GroupSummary 
                        class="active-conversation"
                        :group="activeGroupObject"
                    />
                    <div @click="showAssets = true" class="show-assets"><img src="../assets/img/dot-menu-dark.svg" alt=""></div>
                </div>
                <Sidebar 
                    v-if="!simpleChat"
                    :showGroups="showGroups"
                    :showAssets="showAssets"
                    :groups="groups"
                    :archivalGroups="archivalGroups"
                    :activeGroup="activeGroup"
                    :activeGroupObject="activeGroupObject"
                    @changeActiveGroup="checkMessages" 
                    @hideAssets="showAssets = false"
                    :showArchival="showArchival"
                    @toggleArchival="toggleArchival"
                />
                <Chat 
                    :msgs="msgs" 
                    @getPreviousMessages="getPreviousMessages" 
                    :activeGroup="activeGroup"
                    :isActive="isActive"
                    :simpleChat="simpleChat"
                />
            </template>
            <div v-else class="empty-state">
                <p class="title">{{$i18n.t(`messages.no-groups.title`)}}</p>
                <p class="subtitle">{{$i18n.t(`messages.no-groups.subtitle`)}}</p>
            </div>
        </div>
        <DeleteMessage 
            v-if="deleteMessageModal.show"
            :data="deleteMessageModal.data"
            @hide="deleteMessageModal.show = false"
        />
    </div>
</template>

<script>
import Sidebar from '@/components/messages/Sidebar'
import Chat from '@/components/messages/Chat'
import GroupSummary from '@/components/messages/GroupSummary'
import Skeleton from '@/components/messages/Skeleton'
import DeleteMessage from '@/components/modals/DeleteMessage'

import { latestMessages } from '@/graphql/queries/latestMessages'
import { nextMessages } from '@/graphql/queries/nextMessages'
import { previousMessages } from '@/graphql/queries/previousMessages'
import { messageGroups } from '@/graphql/queries/messageGroups'
import { archivalMessageGroups } from '@/graphql/queries/archivalMessageGroups'
import { messageGroupByUuid } from '@/graphql/queries/messageGroupByUuid'
import api from '@/graphql/api.js'
import { mapState } from 'vuex'
import findAndUpdateChatMessage from '@/functions/findAndUpdateChatMessage'

export default {
    name: 'Messages',
    props: ['oneGroupUuid'],
    components: {
        Sidebar,
        Chat,
        GroupSummary,
        Skeleton,
        DeleteMessage
    },
    data() {
        return {
            loading: true,
            groups: [],
            archivalGroups: [],
            showArchival: false,
            msgs: [],
            activeGroup: null,
            isActive: null,
            mobile: 767,
            showGroups: false,
            showAssets: false,
            deleteMessageModal: {
                show: false,
                data: null
            },
            updateMessageInterval: null
        }
    },
    beforeDestroy() {
        this.$bus.$off('createMessage')
        this.$bus.$off('WS::getNextMessages')
        this.$bus.$off('updateMessages')
        this.$bus.$off('showDeleteMessageModal')
        this.$bus.$off('handleUpdatedChatMessage')

        this.$store.commit('setEditMessage', null)
        clearInterval(this.updateMessageInterval)
    },
    async mounted() {
        this.$bus.$on('createMessage', data => this.createMessage(data))
        this.$bus.$on('WS::getNextMessages', uuid => this.getNextMessages(uuid))
        this.$bus.$on('updateMessages', this.getMessageGroups)
        this.$bus.$on('showDeleteMessageModal', data => {
            this.deleteMessageModal.show = true
            this.deleteMessageModal.data = data
        })
        this.$bus.$on('handleUpdatedChatMessage', data => {
            findAndUpdateChatMessage(this.msgs, data)
            this.getMessageGroups()
        })

        this.setUpdateMessageInterval()

        await this.getMessageGroups();
        await this.getArchivalMessageGroups();

        if(this.allGroups.length) {
            this.activeGroup = this.oneGroupUuid || this.$route.params.oneGroupUuid || this.$route.params.groupUuid || this.allGroups[0].uuid
            if(!this.activeGroupObject?.group.isActive) this.showArchival = true

            if(this.$route.name === 'messages' && !this.$route.params.groupUuid) this.$router.replace({name: 'messages', params: {groupUuid: this.activeGroup}})

            await this.checkMessages(this.activeGroup)
        }
        
        this.loading = false
    },
    methods: {
        async checkMessages(id) {
            if(this.$route.name === 'messages' && !this.loading) this.$router.replace({name: 'messages', params: {groupUuid: id}}, () => {})
            this.activeGroup = id
            this.showGroups = false
            if(this.$store.state.messages[this.activeGroup]) await this.getNextMessages(this.activeGroup, this.$store.state.messages[this.activeGroup], true)
            else await this.getLatestMessages(this.activeGroup)
            this.isActive = this.activeGroupObject?.group.isActive

            window.parent.postMessage({ messagesLoaded: true }, '*')
        },
        async getMessageGroups() {
            if(this.oneGroupUuid || this.$route.params.oneGroupUuid) {
                const {res, error} = await api(messageGroupByUuid, {
                    messageGroupUuid: this.oneGroupUuid || this.$route.params.oneGroupUuid
                })
                if(error) {
                    this.$emit('access', false)
                    return
                }
                this.groups = [res.messageGroupByUuid]
            } else {
                const { res } = await api(messageGroups, {}, 'messageGroups')
                this.groups = this.prepareMessageGroups(res.currentUser.messageGroups)
            }
        },
        async getArchivalMessageGroups() {
            if(this.$route.name === 'messages') {
                const { res } = await api(archivalMessageGroups, {}, 'archivalMessageGroups')
                this.archivalGroups = this.prepareMessageGroups(res.currentUser.archivalMessageGroups)
            }
        },
        prepareMessageGroups(data) {
            return data.filter(item => !item.group.isTest).sort((a, b) => {
                const dateMock = 0

                const dateA = new Date(a.lastMessage?.createdAt || dateMock)
                const dateB = new Date(b.lastMessage?.createdAt || dateMock)

                return dateB - dateA
            })
        },
        async getLatestMessages(id) {
            const unreadCount = this.activeGroupObject.unreadMessagesCount
            const { res, error } = await api(latestMessages, {
                count: unreadCount > 20 ? unreadCount : 20,
                messageGroupUuid: id
            })
            if(error) {
                if(this.$route.name === 'messages') this.$router.push({name: 'dashboard'})
            } else {
                this.$emit('access', true)
                this.msgs = res.latestMessages
                this.setNewMessagesSeparator()
                this.updateUnreadMessagesCount()
            }
        },
        async getNextMessages(id, arr, setSeparator) {
            const msgs = arr || this.msgs
            if(id === this.activeGroup) {
                const {res} = await api(nextMessages, {
                    count: null,
                    messageGroupUuid: this.activeGroup,
                    messageUuid: !msgs.length ? null : msgs[msgs.length -1].uuid
                })
                this.msgs = [...msgs, ...res.nextMessages]
                if(setSeparator) this.setNewMessagesSeparator()
            }

            this.$emit('access', true)
            this.updateUnreadMessagesCount()
        },
        async getPreviousMessages() {
            const {res} = await api(previousMessages, {
                count: 20,
                messageGroupUuid: this.activeGroup,
                messageUuid: this.msgs[0].uuid,
            })

            if(res.previousMessages.length) {
                this.msgs = [...res.previousMessages, ...this.msgs]
                this.$store.commit('setMessages', {uuid: this.activeGroup, arr: this.msgs})
            }
        },
        setNewMessagesSeparator() {
            const unreadCount = this.activeGroupObject.unreadMessagesCount
            const separator = unreadCount ? this.msgs[this.msgs.length - unreadCount]?.uuid : null

            this.$store.commit('setNewMessagesSeparator', separator)
        },
        async createMessage(message) {
            let isEditFlow

            if(this.editMessage) {
                isEditFlow = true
                this.$store.commit('setEditMessage', null)
            }
            
            const uploadedFiles = message.files.filter(item => item.uuid).map(item => ({ uuid: item.uuid }))
            const filesToUpload = message.files.filter(item => !item.uuid)
            let uploadError = null
    
            await Promise.all(
                filesToUpload.map(async file => {
                    const res = await this.$store.dispatch('uploadFile', file)

                    if(res.uploadError) uploadError = true
                    else uploadedFiles.push(res)
                })
            )

            if(uploadError) return

            if(isEditFlow) {
                this.$store.dispatch('updateChatMessage', {
                    uuid: message.uuid,
                    messageFileInputs: uploadedFiles,
                    content: message.content,
                    type: message.type
                })
            } else {
                this.$store.dispatch('createChatMessage', {
                    messageGroupUuid: this.activeGroup,
                    messageFileInputs: uploadedFiles,
                    content: message.content,
                    metadata: message.metadata,
                    userUuid: this.$store.getters.userUuid
                })
            }
        },
        async updateUnreadMessagesCount() {
            this.$store.dispatch('getUnreadMessagesCount')

            if(!this.simpleChat) await this.getMessageGroups()
        },
        toggleArchival(bool) {
            this.showArchival = bool
        },
        setUpdateMessageInterval() {
            this.updateMessageInterval = setInterval(() => {
                this.$bus.$emit('checkUpdateMessageTime')
            }, 60 * 1000)
        }
    },
    computed: {
        ...mapState([
            'unreadMessagesCount',
            'editMessage'
        ]),
        mobileView() {
            return this.$store.state.windowWidth <= this.mobile
        },
        allGroups() {
            return [...this.groups, ...this.archivalGroups]
        },
        activeGroupObject() {
            return this.allGroups.find(group => group.uuid === this.activeGroup)
        },
        simpleChat() {
            const routes = ['singleLesson']
            return routes.includes(this.$route.name)
        }
    },
    watch: {
        $route(n, o) {
            if(!n.params.groupUuid) this.$router.replace({name: 'messages', params: {groupUuid: o.params.groupUuid}})
        },
        showArchival(n) {
            if(!n && !this.activeGroupObject?.group.isActive) this.checkMessages(this.groups[0].uuid)
        }
    }
}
</script>
