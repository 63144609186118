var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-test-page"},[_c('div',{staticClass:"bg-white nav-bar"},[_c('div',{staticClass:"wrapper wrapper--big"},[_c('nav',{staticClass:"nav"},[_vm._m(0),_c('div',{staticClass:"nav__right"},[(_vm.timeLeft)?_c('NavBarTime',{ref:"time",attrs:{"time":_vm.timeLeft},on:{"timeEnded":_vm.endTest}}):_vm._e()],1)])])]),(_vm.loading)?_c('Loading',{attrs:{"size":40}}):_c('MultiStep',{ref:"multiStep",staticClass:"multi-step--top-gap",attrs:{"steps":_vm.steps,"isCompleted":_vm.isCompleted,"buttons":{
            back: {
                disabledSteps: [2, _vm.steps.length]
            },
            next: {
                texts: {
                    1: _vm.$t('progress-test.footer.start'),
                    [!_vm.isCompleted && [_vm.steps.length - 1]]: _vm.$t('progress-test.footer.submit')
                }
            },
            close: {
                steps: _vm.isCompleted ? 'all' : [1],
                ..._vm.closeButton
            },
            finish: {
                steps: [_vm.steps.length],
                action: () => this.$router.push({ name: 'dashboard' }),
                text: _vm.$t('progress-test.footer.finish') 
            },
            custom: {
                steps: [_vm.steps.at(-1).data.link && _vm.steps.length],
                action: _vm.downloadCert,
                text: _vm.$t('progress-test.footer.download'),
                icon: require('@/assets/img/progressTest/download.svg')
            }
        }},on:{"setStep":_vm.setStep,"finish":_vm.endTest}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__left"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo-wide.svg"),"alt":"Fluentbe"}})])
}]

export { render, staticRenderFns }