import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('learning', ['isPreviewMode']),
    },
    methods: {
        play(audioFile) {
            const audio = new Audio(audioFile);
            audio.play();
        },
        playAudio(type) {
            if (this.isPreviewMode || sessionStorage.getItem('learningPreview')) return;

            if (type === 'correct') this.play(require('@/assets/correct.mp3'))
            else if (type === 'incorrect') this.play(require('@/assets/incorrect.mp3'))
            else if (type === 'half-correct') this.play(require('@/assets/half-correct.mp3'))
            else if (type === 'dont-know') this.play(require('@/assets/dont-know.mp3'))
        }
    }
}