<template>
    <div :class="{'archival': !group.group.isActive}">
        <div class="avatars">
            <template v-if="group.group.type === 'group' || group.group.type === 'shared'">
                <div class="border-wrapper">
                    <img class="avatar avatar-style" :src="setAvatar(getAvatar(group, 'user'))" alt="">
                </div>
                <div class="avatar num avatar-style">+{{group.receivers.length - 1}}</div>
            </template>
            <template v-else>
                <div class="border-wrapper">
                    <img class="avatar avatar-style" :src="setAvatar(getAvatar(group, 'user'))" alt="">
                </div>
                <img class="avatar avatar-style" :src="setAvatar(getAvatar(group, 'student'))" alt="">
            </template>
            
        </div>
        <div class="info">
            <p v-if="group.group.type === 'group' || group.group.type === 'shared'" class="label">{{group.name}}</p>
            <p v-else class="label">{{getFirstNames(group)}}</p>
            <p class="date" :class="{'new': group.unreadMessagesCount}">{{group.lastMessage ? formatDate(group.lastMessage.createdAt, true) : '-'}}</p>
        </div>
    </div>
</template>

<script>
import formatDateMixin from '@/mixins/formatDate'
import setAvatarMixin from '@/mixins/setAvatar'

export default {
    name: 'GroupSummary',
    props: ['group'],
    mixins: [formatDateMixin, setAvatarMixin],
    methods: {
        getAvatar(group, type) {
            if(type === 'user') return group.receivers.find(item => item.role === 'user' || item.role === 'current_user')?.avatarUrl
            if(type === 'student') return group.receivers.find(item => item.role === 'student' || item.role === 'current_student')?.avatarUrl
        },
        getFirstNames(group) {
            return `
                ${group.receivers.find(item => item.role === 'user' || item.role === 'current_user')?.firstName || this.$i18n.t('general.lector')}, 
                ${group.receivers.find(item => item.role === 'student' || item.role === 'current_student')?.firstName || this.$i18n.t('general.student')}
            `
        }
    }
}
</script>
