import router from '@/router'
import shuffleArray from '@/functions/shuffleArray'
import getRandomNumber from '@/functions/getRandomNumber'
import { v4 as uuidv4 } from 'uuid'
import { flashcardsGroup } from '@/graphql/queries/flashcardsGroup'
import { updateFlashcards } from '@/graphql/mutations/updateFlashcards'
import { updateFlashcards as updateFlashcardsLearning } from '@/graphql/mutations/learning/updateFlashcards'
import api from '@/graphql/api.js'
import apiLearning from '@/graphql/apiLearning.js'
import { FLASHCARDS_STATES, FLASHCARDS_EXERCISES_STATES, FLASHCARDS_EXERCISES, FLASHCARDS_AUDIO_PREFIX } from '@/constants/flashcards';
import flashcardsSubject from '@/functions/flashcardsSubject'
import processWord from '@/functions/processWord'
import trimSpecialCharacters from '@/functions/trimSpecialCharacters'
import handleAudioPlay from '@/mixins/learning/handleAudioPlay';

const store = {
    state: {
        loading: true,
        submitLoading: false,
        subject: null,
        status: null,
        vocabulary: [],
        simpleExercisesTypes: [FLASHCARDS_EXERCISES.CHOICE, FLASHCARDS_EXERCISES.INPUT, FLASHCARDS_EXERCISES.ORDER],
        audioExercisesTypes: [FLASHCARDS_EXERCISES.CHOICE],
        exercises: [],
        previousExercises: [],
        currentAnswer: null,
        submitted: false,
        dontKnow: false,
        practiceMode: false,
        previewMode: false,
        audios: {},
        mobile: 768,
        slideTransition: 'flashcards-slide-right',
    },
    actions: {
        async getData(context, data) {
            context.commit('resetState')
            context.rootState.learningCoursePlan.transUi = null

            const { res } = await api(flashcardsGroup, data)

            if(!res.flashcardsGroup?.flashcards) return router.replace({ name: 'dashboard' })

            context.commit('createVocabulary', res.flashcardsGroup.flashcards)
            context.state.subject = flashcardsSubject(res.flashcardsGroup.name)
            context.state.practiceMode = res.flashcardsGroup.status === FLASHCARDS_STATES.COMPLETED
            context.commit('createExercises', {
                learningStatus: context.rootState.learning.learningStatus
            })

            context.state.loading = false
        },
    },
    mutations: {
        createVocabulary(state, data) {
            state.vocabulary = shuffleArray(data.map(el => {
                return {
                    ...el,
                    english: processWord(el.english),
                    word: processWord(el.word)
                }
            }))

            this.commit('flashcards/setStatus')
        },
        setStatus(state) {
            const activities = []

            state.vocabulary.forEach(el => {
                el.activities.forEach(el2 => activities.push(el2))
            })

            if(activities.every(el => el.status === FLASHCARDS_EXERCISES_STATES.NEW)) state.status = 'NEW'
            if(activities.some(el => el.status === FLASHCARDS_EXERCISES_STATES.NEW) 
                && activities.some(el => [FLASHCARDS_EXERCISES_STATES.FAILED, FLASHCARDS_EXERCISES_STATES.COMPLETED].includes(el.status))) state.status = 'IN_PROGRESS'
            if(activities.every(el => el.status !== FLASHCARDS_EXERCISES_STATES.NEW)
                && activities.some(el => el.status === FLASHCARDS_EXERCISES_STATES.FAILED)) state.status = 'FAILED'
            if(activities.every(el => el.status === FLASHCARDS_EXERCISES_STATES.COMPLETED)) state.status = 'COMPLETED'
        },
        createExercises(state, data) {
            state.vocabulary.forEach(el => {
                const getStatus = type => el.activities.find(el => el.type === type).status

                const isFinished = type => {
                    if(this.getters.isLearningMode) {
                        if(state.previewMode || state.status === 'NEW') return
                        if(state.status === 'FAILED' && ['FAILED', 'COMPLETED'].includes(data.learningStatus)) return getStatus(type) !== FLASHCARDS_EXERCISES_STATES.FAILED
                        if(state.status === 'IN_PROGRESS') return [FLASHCARDS_EXERCISES_STATES.FAILED, FLASHCARDS_EXERCISES_STATES.COMPLETED].includes(getStatus(type))
                    }

                    return getStatus(type) === FLASHCARDS_EXERCISES_STATES.COMPLETED && !state.practiceMode
                }

                const base = {
                    english: el.english,
                    translation: el.word,
                    parentUuid: el.uuid
                }

                let definitionIndex = -1

                if(!isFinished(FLASHCARDS_EXERCISES.DEFINITION)) {
                    definitionIndex = getRandomNumber(0, Math.round(state.exercises.length  * 1))

                    state.exercises.splice(definitionIndex, 0, {
                        ...base,
                        type: FLASHCARDS_EXERCISES.DEFINITION,
                        uuid: uuidv4(),
                        status: getStatus(FLASHCARDS_EXERCISES.DEFINITION)
                    })
                }

                let lastSimpleExerciseIndex
                
                state.simpleExercisesTypes.filter(type => type !== FLASHCARDS_EXERCISES.INPUT).forEach(type => {
                    if(!isFinished(type)) {
                        let simpleExerciseIndex = getRandomNumber(definitionIndex + 1, state.exercises.length)

                        state.exercises.splice(simpleExerciseIndex, 0, {
                            ...base,
                            type,
                            uuid: uuidv4(),
                            status: getStatus(type)
                        })

                        if(lastSimpleExerciseIndex === undefined || simpleExerciseIndex > lastSimpleExerciseIndex) lastSimpleExerciseIndex = simpleExerciseIndex
                        if(lastSimpleExerciseIndex === simpleExerciseIndex) lastSimpleExerciseIndex++
                    }
                })

                if(lastSimpleExerciseIndex === undefined) lastSimpleExerciseIndex = -1

                if(!isFinished(FLASHCARDS_EXERCISES.INPUT)) {
                    lastSimpleExerciseIndex = getRandomNumber(lastSimpleExerciseIndex + 1, state.exercises.length)

                    state.exercises.splice(lastSimpleExerciseIndex, 0, {
                        ...base,
                        type: FLASHCARDS_EXERCISES.INPUT,
                        uuid: uuidv4(),
                        status: getStatus(FLASHCARDS_EXERCISES.INPUT)
                    })
                }

                state.audioExercisesTypes.forEach(type => {
                    if(!isFinished(FLASHCARDS_AUDIO_PREFIX + type)) {
                        state.exercises.splice(getRandomNumber(lastSimpleExerciseIndex + 1, state.exercises.length), 0, {
                            ...base,
                            type,
                            audio: true,
                            uuid: uuidv4(),
                            status: getStatus(FLASHCARDS_AUDIO_PREFIX + type)
                        })
                    }
                })
            })
        },
        handleAnswer(state, data) {
            this.commit('flashcards/setCurrentAnswer', null)
            this.commit('flashcards/setSubmitted', false)
            state.dontKnow = false;
            
            const isAnswerCorrect = trimSpecialCharacters(this.getters['flashcards/getCorrectAnswer'])?.toLowerCase() === trimSpecialCharacters(data)?.toLowerCase()
            const exercise = state.exercises.shift()
            
            if(this.getters.isLearningMode) {
                if(state.previewMode) {
                    state.previousExercises.push(exercise)
                    this.commit('flashcards/setCorrectAnswer')
                } else {
                    this.commit('flashcards/handleAnswerRequest', { exercise, isAnswerCorrect })
                    if(!isAnswerCorrect) state.exercises = [...state.exercises, exercise]
                }

                return
            }
                
            const middleIndex = Math.floor(state.exercises.length / 2)
            if(isAnswerCorrect) {
                if(state.practiceMode) state.exercises.splice(getRandomNumber(middleIndex, state.exercises.length), 0, exercise)
                else this.commit('flashcards/handleAnswerRequest', { exercise, isAnswerCorrect })
            }
            else state.exercises.splice(getRandomNumber(1, middleIndex), 0, { ...exercise, uuid: uuidv4() })

            window.scrollTo(0, 0)
        },
        setCorrectAnswer() {
            this.commit('flashcards/setCurrentAnswer', trimSpecialCharacters(this.getters['flashcards/getCorrectAnswer']))
            this.commit('flashcards/setSubmitted', true)
        },
        async handleAnswerRequest(state, { exercise, isAnswerCorrect }) {
            state.submitLoading = true
            const { isLearningMode } = this.getters
            const selectedApi = isLearningMode ? apiLearning : api
            const selectedMutation = isLearningMode ? updateFlashcardsLearning : updateFlashcards

            const updateFlashcardsMutation = await selectedApi(selectedMutation, {
                input: [
                    {
                        flashcardUuid: state.vocabulary.find(el => el.uuid === exercise.parentUuid).uuid,
                        activityType: exercise.audio ? FLASHCARDS_AUDIO_PREFIX + exercise.type : exercise.type,
                        status: isAnswerCorrect ? FLASHCARDS_EXERCISES_STATES.COMPLETED : FLASHCARDS_EXERCISES_STATES.FAILED
                    }
                ]
            })

            if (isLearningMode) this.commit('learning/setProgress', updateFlashcardsMutation.res?.updateFlashcards.percentage);
            state.submitLoading = false
        },
        setPreviewMode(state, data) {
            state.previewMode = data
            
            if(data) setTimeout(() => this.commit('flashcards/setCorrectAnswer'), 0)
        },
        setCurrentAnswer(state, data) {
            state.currentAnswer = data
        },
        setSubmitted(state, data) {
            state.slideTransition = 'flashcards-slide-right'
            if(state.currentAnswer) this.commit('flashcards/setCurrentAnswer', processWord(state.currentAnswer))
            state.submitted = data

            if(data && this.getters['flashcards/isChoiceExercise']) {
                const uuid = this.getters['learning/getCurrentSlide']?.uuid;

                setTimeout(() => this._vm.$bus.$emit('flashcardsAudio', uuid), this.getters.isLearningMode ? 1000 : 0)
            } 
        },
        setDontKnow(state, data) {
            state.dontKnow = data;
            handleAudioPlay.methods.playAudio('dont-know');
        },
        resetState(state) {
            state.loading = true
            state.subject = null
            state.vocabulary = []
            state.exercises = []
            state.previousExercises = []
            state.currentAnswer = null
            state.submitted = false
            state.dontKnow = false
            state.practiceMode = false
            state.previewMode = false
        }
    },
    getters: {
        getCurrentExercise(state) {
            return state.exercises[0]
        },
        isDefinitionExercise(state, getters) {
            return getters.getCurrentExercise?.type === FLASHCARDS_EXERCISES.DEFINITION
        },
        isChoiceExercise(state, getters) {
            return getters.getCurrentExercise?.type === FLASHCARDS_EXERCISES.CHOICE
        },
        isOrderExercise(state, getters) {
            return getters.getCurrentExercise?.type === FLASHCARDS_EXERCISES.ORDER
        },
        isAudioExercise(state, getters) {
            return getters.getCurrentExercise?.audio
        },
        isEnglishBased(state, getters) {
            return getters.isChoiceExercise || getters.isDefinitionExercise
        },
        getAllExercisesLength(state) {
            const exercises = [FLASHCARDS_EXERCISES.DEFINITION, ...state.simpleExercisesTypes, ...state.audioExercisesTypes]

            return state.vocabulary.length * exercises.length
        },
        getCurrentProgress(state, getters) {
            return getters.getAllExercisesLength - state.exercises.length
        },
        getCanSubmit(state, getters) {
            return !state.submitted && !state.dontKnow && !getters.isDefinitionExercise
        },
        getAnswer(state, getters) {
            if(getters.isDefinitionExercise) return getters.getCorrectAnswer
            if(state.dontKnow) return null

            return state.currentAnswer
        },
        showActivity(state, getters) {
            return !getters.getAnswerStatus || ['incorrect', 'half-correct'].includes(getters.getAnswerStatus)
        },
        getCorrectAnswer(state, getters) {
            if(!getters.getCurrentExercise) return

            const { translation, english } = getters.getCurrentExercise

            return getters.isEnglishBased ? translation : english
        },
        getAnswerStatus(state, getters) {
            if (state.previewMode) return 'correct';
            if (!state.submitted && !state.dontKnow) return;
        
            if (state.dontKnow) return 'pending';

            let correctAnswer = processWord(trimSpecialCharacters(getters.getCorrectAnswer?.toLowerCase()));
            let currentAnswer = processWord(trimSpecialCharacters(state.currentAnswer?.toLowerCase()));

            if (correctAnswer === currentAnswer) {
                handleAudioPlay.methods.playAudio('correct');

                return 'correct';
            }

            if (getters.isOrderExercise) {
                correctAnswer = getters.getCorrectAnswer;
                currentAnswer = state.currentAnswer;

                const checkHalfCorrect = (correct, current) => {
                    let matches = 0;

                    for (let i = 0; i < Math.min(correct.length, current.length); i++) {
                        if (correct[i] === current[i]) matches++;
                    }

                    return matches > 0;
                };
            
                if (correctAnswer.includes(' ')) {
                    if (checkHalfCorrect(currentAnswer.split(' '), correctAnswer.split(' '))) {
                        handleAudioPlay.methods.playAudio('half-correct');
                        return 'half-correct'
                    }
                } else {
                    if(checkHalfCorrect(currentAnswer.split(''), correctAnswer.split(''))) {
                        handleAudioPlay.methods.playAudio('half-correct');

                        return 'half-correct'
                    }
                }
            }

            handleAudioPlay.methods.playAudio('incorrect');

            return 'incorrect';
        },        
        getLearnedWords(state) {
            return state.vocabulary.length - [...new Set(state.exercises.map(el => el.english))].length
        },
        isMobile(state, getters, rootState) {
            return state.mobile > rootState.windowWidth
        }
    }
}

export default store