<template>
    <div class="progress-test-start">
        <img src="@/assets/img/progressTest/progress-test.svg" alt="">
        <p class="title">{{ $i18n.t('progress-test.start.title') }}</p>
        <div class="info-box info-box--horizontal">
            <div class="info-box__small-section">
                <p class="info-box__small-title">{{ $i18n.t('progress-test.start.valid-to') }}</p>
                <p class="info-box__time"><img src="@/assets/img/calendar.svg" alt=""> {{ formattedDate }}</p>
            </div>
            <div class="info-box__small-section">
                <p class="info-box__small-title">{{ $i18n.t('progress-test.start.time-to-do') }}</p>
                <p class="info-box__time"><img src="@/assets/img/clock.svg" alt=""> {{ data.testLength }} {{ $i18n.t('calendar.minutes') }}</p>
            </div>
        </div>
        <div class="info-box">
            <p class="info-box__title">{{ $i18n.t('progress-test.start.rules') }}</p>
            <div class="info-box__text" v-html="$i18n.t('progress-test.start.description')"></div>
        </div>
    </div>
</template>

<script>
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'ProgressTestStart',
    props: ['data'],
    computed: {
        formattedDate() {
            if(!this.data.validTo) return '-'

            const date = this.$moment(this.data.validTo)

            return `${ date.format(timezoneDate('D MMMM')) }, ${ this.$i18n.t('calendar.hour-shortcut') } ${ date.format(timezoneDate('HH:mm')) }`
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-test-start {
    .title {
        @include font-h2;
        margin: 8px 0 40px;
    }

    .info-box {
        @include font-regular-text;
        text-align: left;
        padding: 24px;
        border-radius: 10px;
        border: 2px solid $gray-200;
        margin-bottom: 16px;
        
        &--horizontal {
            display: flex;
            gap: 16px;

            @media(max-width: 768px) {
                flex-direction: column;
            }
        }

        &__small-section {
            flex: 1;
        }

        &__small-title {
            @include font-small-10;
            text-transform: uppercase;
            color: $gray-600;
            margin-bottom: 8px;
        }

        &__time {
            @include font-medium;
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

        &__title {
            @include font-h3;
            margin-bottom: 8px;
        }

        &__text {
            ::v-deep p:not(:last-child) {
                margin-bottom: 24px;
            } 
        }
    }
}
</style>