<template>
    <div class="fill-textarea">
        <div class="textarea-wrapper">
            <textarea
                v-model="text"
                ref="textarea"
                :class="['textarea', {
                    'textarea--microphone-mode': isMicrophoneInput && !unlockedTextarea,
                    'textarea--first-view': !isRecordingFlowActive && isMicrophoneInput && !isPreviewMode && !unlockedTextarea,
                    'danger' : isRecording,
                    'primary' : loading,
                }]"
                :placeholder="getPlaceholder"
                :disabled="isTaskFinished || isRecording || isMicrophoneInput && !unlockedTextarea"
                @input="handleUserTextInput"
            />
            <div
                v-if="showUnlockTextareaBtn"
                @click="unlockTextarea"
                class="pen-wrapper"
            >
                <img width="24" height="24" src="@/assets/img/learning/pen-active.svg" alt="pen">
            </div>
            <div v-if="isMicrophoneInput && loading" class="dot-wrapper">
                <div class="dot-typing dot-typing--active"></div>
            </div>
        </div>
        <AudioPlayer
            :microphoneInputStatus="isMicrophoneInput"
            recordOpenEnded
            @input="transformSpeechToText"
            @startRecording="startRecording"
            @stopRecording="stopRecording"
            @setMicStatus="setMicStatus"
            @resetRecording="resetRecording"
            :transcribing="loading"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AudioPlayer from '@/components/reusable/AudioPlayer';
import { speechToText } from '@/graphql/queries/learning/speechToText'
import api from '@/graphql/apiLearning.js'

export default {
    name: 'OpenEnded',
    components: { AudioPlayer },
    created() {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = '/web-audio-recorder/WebAudioRecorder.min.js'
        document.head.appendChild(script)
    },
    mounted() {
        if(this.isPreviewMode) this.text = this.getCurrentTask.result?.data.userText;
    },
    beforeDestroy() {
        this.$store.commit('learning/setIsRecording', false);
    },
    data() {
        return {
            text: '',
            loading: false,
            isMicrophoneInput: true,
            isRecordingFlowActive: false,
            unlockedTextarea: false
        }
    },
    computed: {
        ...mapState('learning', ['isTaskFinished', 'isRecording']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('learning', ['getCurrentTask', 'isPreviewMode']),
        showUnlockTextareaBtn() {
            return this.isMicrophoneInput && !this.isRecording && !this.loading && this.text.length && !this.unlockedTextarea;
        },
        handleMicrophoneMode() {
            return this.isMicrophoneInput && !this.loading && !this.isTaskFinished
        },
        getPlaceholder() {
            if(this.isMicrophoneInput && !this.isRecording && !this.loading) return this.$i18n.t('elearning.open-ended.writing-segment.answer-will-apear', this.transUi);
            else if(this.isMicrophoneInput && this.isRecording && !this.loading) return `${ this.$i18n.t('elearning.open-ended.writing-segment.listening', this.transUi) }...`;
            else if(this.isMicrophoneInput && this.loading) return;
            
            return `${ this.$i18n.t('elearning.open-ended.writing-segment.write', this.transUi) }...`;
        }
    },
    methods: {
        unlockTextarea() {
            this.unlockedTextarea = true;
            this.$nextTick(() => this.$refs.textarea.focus());
        },
        resetRecording() {
            this.unlockedTextarea = false;
            this.text = '';
            this.$store.commit('learning/setUserAnswer', this.text);
        },
        startRecording() {
            this.isRecordingFlowActive = true;
            this.$store.commit('learning/setIsRecording', true);
        },
        stopRecording() {
            this.$store.commit('learning/setIsRecording', false);
        },
        handleUserTextInput() {
            this.$store.commit('learning/setUserAnswer', this.text);
        },
        setMicStatus(status) {
            this.isMicrophoneInput = status
        },
        async transformSpeechToText(audioRaw) {
            if (!audioRaw) return;
            this.loading = true;

            const { res, error } = await api(speechToText, {
                input: {
                    audioFormat: "OGG",
                    audio: audioRaw,
                    sampleRate: "SAMPLE_RATE_8000"
                }
            })

            if (error) {
                this.$notify({
                    title: this.$i18n.t('elearning.open-ended.recording.error', this.transUi),
                    text: this.$i18n.t('elearning.open-ended.recording.error-description', this.transUi),
                    data: { type: 'error' }
                })
            }

            this.loading = false;

            this.text = res?.speechToText || '';
            this.$store.commit('learning/setUserAnswer', this.text);
            this.isRecordingFlowActive = false;

            if(!this.text.length) this.$bus.$emit('resetRecorder');
        }
    }
};
</script>

<style scoped lang="scss">
.fill-textarea {

    .textarea-wrapper {
        position: relative;

        .dot-typing {
            position: absolute;
            left: calc(50% - 8px);
            top: 50%;
            width: 8px;
            height: 8px;
            transform: translate(5px, 1px);
            margin-top: 6px;
            margin-right: 9px;
            border-radius: 50%;
            background-color: transparent;
            box-shadow: -15px -15px 0 0 $primary, 0 -15px 0 0 $primary, 15px -15px 0 0 $primary;

            &--active {
                animation: dotTyping 1.5s infinite linear;
            }

            @keyframes dotTyping {
                0% {
                    box-shadow: -15px -15px 0 0 $primary, 0 -15px 0 0 $primary, 15px -15px 0 0 $primary;
                }
                15% {
                    box-shadow: -15px -25px 0 0 $primary, 0 -15px 0 0 $primary, 15px -15px 0 0 $primary;
                }
                30% {
                    box-shadow: -15px -15px 0 0 $primary, 0 -25px 0 0 $primary, 15px -15px 0 0 $primary;
                }
                45% {
                    box-shadow: -15px -15px 0 0 $primary, 0 -15px 0 0 $primary, 15px -25px 0 0 $primary;
                }
                60% {
                    box-shadow: -15px -15px 0 0 $primary, 0 -15px 0 0 $primary, 15px -15px 0 0 $primary;
                }
            }
        }
    }
    
    .pen-wrapper {
        width: 40px;
        height: 40px;
        background-color: $primary-light;
        padding: 8px;
        border-radius: 100px;
        position: absolute;
        bottom: 16px;
        right: 16px;
        cursor: pointer;

        &:hover {
            filter: brightness(.985);
        }
    }

    .textarea {
        border-radius: 10px;
        border: 2px solid $gray-200;
        padding: 16px;
        resize: none;
        min-width: 343px;
        min-height: 300px;
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.4px;
        background: $white;

        &--microphone-mode {
            &::placeholder {
                text-align: center;
                line-height: calc(300px - 32px - 17px/2);
            }
        }

        &--first-view {
            opacity: .3;
        }

        &.danger {
            border-color: $danger-light;
            opacity: 1;

            &::placeholder {
                color: $danger;
            }
        }

        &.primary {
            border-color: $primary-light;

            &::placeholder {
                color: $primary;
                text-align: left;
                line-height: 32px;
            }
        }

        &:focus {
            border: 2px solid $primary;
        }

        &.correct {
            border: 2px solid $secondary;
            background-color: $secondary-light;
            color: $secondary;
        }

        &.incorrect {
            border: 2px solid $danger;
            background-color: $danger-light;
            color: $danger;
        }
    }
}
</style>
