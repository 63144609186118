<template>
    <div class="fill-input">
        <span v-for="(el, idx) in task.data.text" :key="idx">
            <span class="text" v-if="el.type === 'text'" v-html="transformText(el.text)"></span>
            <div v-else-if="el.type === 'blank'" class="blank-box-wrapper">
                <div class="blank-box">
                    <input
                        @input="(e) => handleInput(e.target.value, el.index)"
                        :class="['blank-box__input', `blank-box__input--${color(el.index)}`]"
                        :disabled="isTaskFinished || isFillingIn"
                        :value="getCorrectAnswer(el.index) || userAnswers[el.index]"
                        autocorrect="off"
                        autocapitalize="none"
                        type="email"
                    />
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import trimSpecialCharacters from '@/functions/trimSpecialCharacters'
import processWord from '@/functions/processWord'
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'GapsEmptySpacesBrackets',
    props: ['task'],
    mixins: [handleBoldedText],
    data() {
        return {
            userAnswers: {},
            fillingIn: false
        };
    },
    mounted() {
        //tutaj po kliku w "click to fill" powinien pójść bus który odblokuje inputa na mobile
        // this.fillingIn = true;
    },
    computed: {
        ...mapGetters('learning', ['isMobile', 'isTaskFinished', 'getBackendAnswer', 'isPreviewMode', 'getCurrentSlide']),
        isFillingIn() {
            return false;
            // return this.isMobile && !this.fillingIn
        }
    },
    methods: {
        handleInput(value, uuid) {
            this.userAnswers[uuid] = value;

            this.$store.commit('learning/setUserAnswer', this.userAnswers);
        },
        processAnswer(text) {
            return processWord(trimSpecialCharacters(text?.toLowerCase()))
        },
        color(uuid) {
            if (!this.isTaskFinished) return;

            if (this.isPreviewMode) return 'correct';
            if (this.processAnswer(this.userAnswers[uuid]) === this.processAnswer(this.getBackendAnswer[uuid]) || this.task.result?.isCorrect) return 'correct'
            else return 'incorrect';
        },
        getCorrectAnswer(uuid) {
            if (!this.isPreviewMode) return;

            return this.getBackendAnswer[uuid];
        },
        transformText(text) {
            if (this.getCurrentSlide.activity.type === 'gaps_empty_spaces') return this.handleText(text);

            const formattedText = text.replace(/^ ?\([^()]*\)/, (match) => '<span style="color: #808793">' + match + '</span>');
            
            return this.handleText(formattedText);
        }
    }
};
</script>

<style scoped lang="scss">
.fill-input {
    border: 1px dashed $gray-200;
    padding: 16px;
    border-radius: 16px;
    min-width: 335px;

    .text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.4px;
    }

    .blank-box-wrapper {
        display: inline-block;
    }

    .blank-box {
        display: inline;
        white-space: nowrap;
        position: relative;
        margin: 0 5px;

        &__input {
            border: 1px solid $gray-300;
            box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
            display: inline-block;
            border-radius: 8px;
            padding: 8px 16px;
            max-width: 100%;
            caret-color: $primary;
            box-sizing: content-box;
            max-width: calc(91px - 32px);
            @include font-medium;

            &:focus {
                border: 1px solid $primary;
                outline: 0;
            }

            &:disabled {
                border: 1px solid $gray-300;;
            }

            &--correct {
                border: 1px solid $secondary;
                background-color: $secondary-light;
                color: $secondary;

                &:hover {
                    border: 1px solid $secondary;
                }
            }

            &--incorrect {
                border: 1px solid $danger;
                background-color: $danger-light;
                color: $danger;

                &:hover {
                    border: 1px solid $danger;
                }
            }
        }
    }
}
</style>
