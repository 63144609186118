<template>
    <div class="switch-lector-free-slots">
        <div v-if="!getShowBuyToBook" class="switch-lector-free-slots__content">
            <div class="switch-lector-free-slots__row">
                <div class="switch-lector-free-slots__row-icon">
                    <img src="@/assets/img/calendar.svg" class="mt-3px" alt="">
                </div>
                <p>
                    {{$i18n.t('switch-lector.list.next-available-date')}}:
                    {{ setDayHeader(getFirstAvaliableDayDate) }} 
                    
                    {{ $moment(getFirstAvaliableDayDate).format(timezoneDate('D MMMM')) }}
                </p>
            </div>

            <div class="switch-lector-free-slots__content-box">
                <div v-for="day in getFirstAvaliableDaySlots" :key="day.startAt">
                    <div 
                        :class="[
                            'switch-lector-free-slots__slot',
                            { 'switch-lector-free-slots__slot--match-filters': isDateBetweenFilterRanges(day.startAt) }
                        ]"
                        @click.stop="selectSlot(day)"
                    >
                        {{ parseHour(day.startAt) }}
                    </div>
                </div>

                <div class="switch-lector-free-slots__slot" @click="$emit('showCalendar')">{{$i18n.t('switch-lector.list.terms.more')}}</div>
            </div>
        </div>
        <div v-else class="switch-lector-free-slots__row">
            <div class="switch-lector-free-slots__row-icon">
                <img src="@/assets/img/calendar.svg" class="mt-3px" alt="">
            </div>
            <span class="swtich-lector-info-slot">{{ $t('calendar.mobile.full-calendar-info') }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import timezoneDate from '@/functions/timezoneDate'
import parseSlots from '@/functions/parseSlots';
import setDayHeader from '@/mixins/schedule/setDayHeader';
import isDateBetweenFilterRanges from '@/mixins/schedule/isDateBetweenFilterRanges';
import checkDateFilter from '@/mixins/schedule/checkDateFilter';

export default {
    name: 'SwitchLectorSomeFreeSlots',
    mixins: [setDayHeader, isDateBetweenFilterRanges, checkDateFilter],
    props: [ 'slots', 'item', 'lector', 'lectorApiUrl' ],
    data() {
        return {
            timezoneDate,
            days: []
        }
    },
    created() {
        this.days = parseSlots(this.slots);

        if(this.isDateFilter) this.checkDateFilter()
    },
    computed: {
        ...mapGetters(['getShowBuyToBook']),
        ...mapGetters('schedule', ['isDateFilter']),
        getFirstAvaliableDaySlots() {
            return this.selectedSlot ? [this.selectedSlot] : this.days[0].slots.slice(0, 3);
        },
        getFirstAvaliableDayDate() {
            return this.selectedSlot?.startAt || this.days[0].slots[0]?.startAt;
        },
    },
    methods: {
        parseHour(date) {
            return this.$moment(date).format(timezoneDate('HH:mm'));
        },
        selectSlot(slot) {
            this.$store.commit('schedule/setSwitchLectorApiUrl', this.lectorApiUrl);
            this.$store.commit('schedule/selectSlot', { slot: slot, lector: this.lector });
            this.$store.commit('schedule/setSwitchLectorFlow', { activeFlow: this.$store.state.schedule.switchLector.activeFlow, activeScreen: false });
        },

    }
}
</script>

<style scoped lang="scss">
    .mt-3px {
        margin-top: 3px;
    }

    .switch-lector-free-slots {
        padding: 0 16px 16px 16px;
        display: flex;
    }

    .swtich-lector-info-slot {
        padding: 8px;
        border-radius: 6px;
        background: $gray-100;
        color: $gray-600;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .switch-lector-free-slots__row {
        display: flex;
        gap: 16px;
        align-items: flex-start;
    }

    .switch-lector-free-slots__row-icon {
        flex-basis: 16px;
        min-width: 16px;
    }

    .switch-lector-free-slots__content-box {
        display: flex;
        margin-top: 10px;
        margin-left: 29px;
        flex-wrap: wrap;
        gap: 8px;
    }

    .switch-lector-free-slots__slot {
        padding: 3px 11px;
        background-color: $primary-light;
        display: flex;
        color: $primary;
        border-radius: 30px;
        transition: all 0.3s ease;
        gap: 5px;

        &:hover {
            background-color: #d2e9ff;
        }

        &--match-filters {
            background: $primary;
            color: $white;
        }
    }
</style>