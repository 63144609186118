var render = function render(){var _vm=this,_c=_vm._self._c;return (this.getMaterial)?_c('div',{staticClass:"single-lesson"},[_c('div',{staticClass:"lesson-data"},[_c('div',{staticClass:"tab plan-header"},[_c('div',{staticClass:"title-wrapper"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/coursePlan/lesson-unplanned.svg")}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('course-plan.plan-header.unplanned')))])]),_c('span',{staticClass:"subject"},[_vm._v(_vm._s(`${ _vm.getLangLevel } - ${ _vm.$route.params.lessonUuid + 1 }/${ _vm.count }: ${ _vm.subject }`))])]),(_vm.showBookBtn)?_c('div',{staticClass:"tab plan-lesson"},[(_vm.getTeachers.length)?_c('div',{staticClass:"lector-wrapper"},[_vm._l((_vm.getTeachers),function(el,idx){return [_c('img',{key:idx,staticClass:"lector avatar-style",style:({ transform: `translateX(${idx * 20}px)` }),attrs:{"src":_vm.setAvatar(el.avatarUrl),"width":"24","height":"24"}})]})],2):_vm._e(),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('course-plan.book-lesson.title')))]),_c('span',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('course-plan.book-lesson.description')))]),(_vm.getBookingUrls.length === 1)?_c('BookLessonBtn',{attrs:{"placement":"coursePlanMobileBookButton"}}):_c('BookLessonBtn',{attrs:{"placement":"coursePlanNoPackageMobileBookBtn"}})],1):_vm._e(),_c('div',{staticClass:"summary tab"},[_c('summary-section',{staticClass:"mt-0",attrs:{"icon":require('@/assets/img/lesson-details.svg'),"title":_vm.$t('single-lesson.lesson-details')},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.lesson.url)?_c('SummaryItem',{attrs:{"title":_vm.$t('single-lesson.materials'),"subtitle":_vm.subject || _vm.$t('single-lesson.materials.subtitle'),"text":_vm.$t('single-lesson.materials.btn'),"state":_vm.lesson.status || _vm.MATERIAL_STATES.NOT_STARTED,"btns":[{
                            link: _vm.lesson.url,
                            type: _vm.MATERIAL_TYPE.LESSON,
                            state: _vm.lesson.status || _vm.MATERIAL_STATES.NOT_STARTED
                        }]}}):_vm._e(),(_vm.prelesson.url)?_c('SummaryItem',{attrs:{"title":_vm.$t('single-lesson.prelesson'),"text":_vm.$t('single-lesson.prelesson.btn'),"subtitle":_vm.subject || _vm.$t('single-lesson.materials.subtitle'),"state":_vm.prelesson.status || _vm.MATERIAL_STATES.NOT_STARTED,"btns":[{
                            link: _vm.prelesson.url,
                            type: _vm.MATERIAL_TYPE.PRELESSON,
                            state: _vm.prelesson.status || _vm.MATERIAL_STATES.NOT_STARTED
                        }]}}):_vm._e(),(_vm.homework.url)?_c('SummaryItem',{attrs:{"title":_vm.$t('single-lesson.homework'),"text":_vm.$t('single-lesson.homework.btn'),"materialUuid":_vm.homework.uuid,"subtitle":_vm.subject || _vm.$t('single-lesson.materials.subtitle'),"state":_vm.homework.status || _vm.MATERIAL_STATES.NOT_STARTED,"btns":[{
                            link: _vm.homework.url,
                            type: _vm.MATERIAL_TYPE.HOMEWORK,
                            state: _vm.homework.status || _vm.MATERIAL_STATES.NOT_STARTED
                        }]}}):_vm._e(),(_vm.flashcardGroup)?_c('SummaryItem',{attrs:{"title":_vm.$t('single-lesson.flashcards'),"state":_vm.flashcardGroup.status,"subtitle":_vm.subject || _vm.$t('single-lesson.materials.subtitle'),"btns":_vm.flashcardsBtns()}}):_vm._e()]},proxy:true}],null,false,3598631230)})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }