<template>
    <div class="skeleton">
        <div class="skeleton-calendar">
            <div class="skeleton-icon dark"></div>
            <div class="skeleton-text dark"></div>
            <div class="skeleton-text dark"></div>
        </div>

        <div class="skeleton-calendar skeleton-calendar--2 mb-15">
            <div class="skeleton-slot dark"></div>
            <div class="skeleton-slot dark"></div>
            <div class="skeleton-slot dark"></div>
            <div class="skeleton-slot dark"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonCalendarMobile'
}
</script>

<style lang="scss" scoped>
.mb-15 {
    margin-bottom: 15px;
}

.skeleton-calendar {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    padding-left: 16px;
}

.skeleton-calendar--2 {
    margin-top: 17px;
    margin-left: 32px;
}

.skeleton-icon,
.skeleton-text {
    height: 16px;
    border-radius: 100px;
}

.skeleton-icon {
    width: 16px;
    margin-right: 8px;
}

.skeleton-text {
    width: 90px;
}

.skeleton-slot {
    border-radius: 100px;
    height: 26px;
    width: 55px;
}
</style>