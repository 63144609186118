import gql from 'graphql-tag'

export const studentBookingInfo = gql`
    {
        currentUser {
            student {
                lastStudentLesson {
                    lesson {
                        uuid
                        startAt
                        stopAt
                        lessonSubjects {
                            subject
                        }
                        user {
                            uuid
                            firstName
                            lastName
                            avatarUrl
                        }
                        materialLink {
                            url
                            materialUuid
                        }
                        feedbackStatus
                    }
                    lessonSurveyUrl
                }
                studentPackagesGroups {
                    uuid
                    left
                    quantity
                    language {
                        name
                        shortName
                    }
                    type
                    newOfferLink
                    isNewOfferLinkReady
                    duration
                }
                studentLanguage
                studentLanguageLevel
            }
        }
    }
`