<template>
    <label :class="['name-switch', { 'name-switch--disabled' : disabled, 'name-switch--mobile' : isMobile }]">
        <input ref="checkbox" type="checkbox" @change="onChange" :checked="isChecked">
        <div class="checkmark">
            <div class="point left">{{ firstText }}</div>
            <div class="point active">
                <transition name="fade" v-if="isChecked">
                    <span class="toggle-text" key="play">{{ secondText }}</span>
                </transition>
                <transition name="fade" v-else>
                    <span class="toggle-text" key="talk">{{ firstText }}</span>
                </transition>
            </div>
            <div class="point right">{{ secondText }}</div>
        </div>
    </label>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'NameSwitch',
    props: {
        firstText: {
            type: String,
            default: '',
        },
        secondText: {
            type: String,
            default: '',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isChecked: this.checked
        };
    },
    computed: {
        ...mapState(['isMobile']),
    },
    methods: {
        onChange($event) {
            if (this.disabled) return;
            this.isChecked = $event.target.checked;
            this.$emit('input', this.isChecked);
        }
    }
}
</script>

<style lang="scss" scoped>
    .name-switch {
        cursor: pointer;
        position: relative;
        min-height: 36px;
        min-width: 270px;
        display: flex;
        align-items: center;

        &--disabled {
            pointer-events: none;
            opacity: .5;
        }

        &--mobile {
            min-height: 40px;

            .checkmark {
                min-height: 40px !important;

                .point {
                    min-height: 32px !important;
                }
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                .point {
                    &.active {
                        transform: translateX(133px);
                    }
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            min-height: 36px;
            min-width: 270px;
            background: $gray-200;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s;

            .point {
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 1px 1px 1px rgba(70, 80, 94, 0.10);
                min-width: 129px;
                min-height: 28px;
                background: $white;
                border-radius: 6px;
                position: absolute;
                left: 4px;
                top: 4px;
                transition: all .2s linear;
                color: $gray-900;
                @include font-small-12;

                &.left {
                    left: 4px;
                    top: 4px;
                    box-shadow: none;
                    background: transparent;
                    z-index: 0;
                }

                &.right {
                    left: 133px;
                    top: 4px;
                    box-shadow: none;
                    background: transparent;
                    z-index: 0;
                }

                &.active {
                    cursor: pointer;
                    position: absolute;
                    z-index: 1;
                }
            }
        }

        .toggle-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.2s;
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity 0.2s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
</style>