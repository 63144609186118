import gql from 'graphql-tag'

export const studentPackagesGroups = gql`
    {
        currentUser {
            student {
                studentPackagesGroups {
                    left
                    language {
                        name
                        shortName
                    }
                    type
                    newOfferLink
                }
            }
        }
    }
`