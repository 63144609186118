import gql from 'graphql-tag'

export const calendarIntegrations = gql`
    {
        currentUser {
            student {
                iCalendarIntegrations {
                    url
                    type
                }
            }
        }
    }
`