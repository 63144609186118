<template>
    <div :class="[pos, 'main-dropdown']">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['pos']
}
</script>
