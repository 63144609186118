<template>
    <div :class="{'inactive': inactive || hasNoSlots}" class="schedule-calendar__day">
        <ScheduleCalendarHeader
            :date="this.date" 
            :inactive="this.inactive" 
            :slots="this.slots" />

        <div v-for="(slot, index) in slots" :key="slot.startAt"
            :class="{ 
                inactive: inactive,
                'info-tooltip--first': index === 0,
                'schedule-calendar__hour--booked': slot.booked, 
                'schedule-calendar__hour--pending': slot.lesson && slot.lesson.status === 'pending',
                'schedule-calendar__hour--match-filters': markMatchedFilters && isDateBetweenFilterRanges(slot.startAt),
                'schedule-calendar__hour--mobile': isMobileDevice
            }"
            @click.prevent="selectSlot(slot)"
            class="schedule-calendar__hour info-tooltip"
            :data-slot-date="slot.startAt"
        >
            <Tooltip 
                v-if="(slot.booked || slot.lesson && slot.lesson.status === 'pending') && !isMobileDevice" 
                :text="`${tooltipText(slot)}`" />

            {{parseHour(slot.startAt)}}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ScheduleCalendarHeader from './ScheduleCalendarHeader';
import Tooltip from '@/components/reusable/Tooltip';
import timezoneDate from '@/functions/timezoneDate'
import apiUrlForThisLector from '@/mixins/schedule/apiUrlForThisLector';
import isDateBetweenFilterRanges from '@/mixins/schedule/isDateBetweenFilterRanges';

export default {
    name: 'ScheduleCalendarDay',
    components: { ScheduleCalendarHeader, Tooltip },
    mixins: [apiUrlForThisLector, isDateBetweenFilterRanges],
    data() {
        return {
            timezoneDate
        }
    },
    props: {
        date: {
            type: String,
            required: true
        },
        inactive: {
            type: Boolean,
            default: false
        },
        slots: {
            type: Array,
            default: () => []
        },
        lector: {
            type: Object
        },
        markMatchedFilters: Boolean
    },
    computed: {
        ...mapGetters('schedule', [
            'isSwitchLectorFlowActive',
            'isCalendarPreviewMode',
            'isDateFilter', 
            'isDateFilterScreen'
        ]),
        ...mapGetters(['getHavePackage']),
        ...mapState(['isMobileDevice']),
        ...mapState('schedule', ['switchLector']),
        hasNoSlots() {
            return this.slots.length === 0;
        }
    },
    methods: {
        selectSlot(slot) {
            if (this.inactive || !this.getHavePackage) return;
            if (this.isCalendarPreviewMode) return;
            if (this.isDateFilterScreen) return this.markFilterSlot(slot)

            this.$store.commit('schedule/selectSlot', { slot: slot, lector: this.lector });
            this.$store.commit('schedule/setSwitchLectorFlow', { activeFlow: this.switchLector.activeFlow, activeScreen: false });

            if (this.isSwitchLectorFlowActive) {
                this.$store.commit('schedule/setSwitchLectorApiUrl', this.apiUrlForThisLector(this.lector.uuid));
            }
        },
        markFilterSlot(slot) {
            slot.booked = !slot.booked

            const dateFilter = this.switchLector.filters.date.value

            if(slot.booked) dateFilter.push(slot.startAt)
            else {
                const slotIndex = dateFilter.indexOf(slot.startAt)
                dateFilter.splice(slotIndex, 1)
            }

            this.$store.commit('schedule/setModalTitle', this.$i18n.t('modal.book-lesson.header.filter-date', { slotsCount: this.isDateFilter }))
        },
        parseHour(date) {
            return this.$moment(date).format(timezoneDate('HH:mm'));
        },
        tooltipText(slot) {
            if (slot.lesson && slot.lesson.status === 'pending') return this.$i18n.t('celendar.hour.tooltip.pending');
            if (this.isDateFilterScreen) return this.$i18n.t('calendar.hour.tooltip.selected')
            if (slot.booked) return this.$i18n.t('calendar.hour.tooltip.booked');
        },
    },
}
</script>