const { detect } = require('detect-browser');

const UNSUPPORTED_BROWSERS = {
    'chrome': { version: 58, showOnList: true },
    'firefox': { version: 53, showOnList: true },
    'edge-chromium': { version: 79, showOnList: true, displayName: 'edge' },
    'opera': { version: 53, showOnList: true },
    'safari': { version: 12, showOnList: true },
    'edge': {},
    'ie': {}
}

const getBrowserAndVersion = () => {
    const browser = detect();

    if (browser) return {
        name: browser.name,
        version: browser.version.match(/\d+.\d+/)?.[0]
    };

    return { name: 'ie', version: 5 };
}

const isBrowserSupported = () => {
    const { name, version } = getBrowserAndVersion();

    if (!UNSUPPORTED_BROWSERS[name]) return true;

    return UNSUPPORTED_BROWSERS[name]?.version < parseInt(version);
}

const supportedBrowserListHtml = i18n => {
    const { name, version } = getBrowserAndVersion();

    const checkUserBrowser = () => name && version
        ? `<div><small>${i18n.t('browser-unsupported.your-browser')}: ${name} ${version}</small></div>`
        : ''

    return `
        ${checkUserBrowser()}
        ${i18n.t('browser-unsupported.header1')}
        <p>${i18n.t('browser-unsupported.header2')}</p>
        <div>
            ${Object.entries(UNSUPPORTED_BROWSERS).map(([name, { version, showOnList, displayName }]) =>
        showOnList && `<span>${displayName || name} v.${version}</span>`
    ).join('')}
        </div>
    `;
}

const checkSpecificBrowsers = list => {
    const { name } = getBrowserAndVersion();

    if (list.includes('safari')) list.push('ios');

    return list.some(el => el === name);
}

const isAppleDevice = () => {
    const browserInfo = detect();
    if (!browserInfo) return;

    const userAgent = navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    const os = browserInfo.os;
    
    return os && os.toLowerCase().includes('mac') || isIOS;
}

export {
    isBrowserSupported as default,
    supportedBrowserListHtml,
    checkSpecificBrowsers,
    isAppleDevice
}
