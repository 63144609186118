<template>
    <div>
        <div class="bg-white nav-bar">
            <div class="wrapper wrapper--big">
                <nav class="nav">
                    <div class="nav__left">
                        <img class="logo" src="@/assets/img/logo-wide.svg" alt="Fluentbe">
                    </div>
                </nav>
            </div>
        </div>
        <MultiStep
            :steps="steps"
            title="setAccount"
            ref="multiStep"
            :data="{
                hideFirstAndLastName: Boolean(this.user.firstName && this.user.lastName)
            }"
            showNextButtonOnLastStep
        />
    </div>
</template>

<script>
import MultiStep from '@/components/reusable/MultiStep'
import SelectGrid from '@/components/reusable/SelectGrid'
import SelectRow from '@/components/reusable/SelectRow'
import SetAccountForm from '@/components/setAccount/SetAccountForm'
import { updateProfileData } from '@/graphql/mutations/updateProfileData'
import { updateRegistrationDataInput } from '@/graphql/mutations/updateRegistrationDataInput'
import { mapGetters, mapState } from 'vuex'
import api from '@/graphql/api.js'

export default {
    name: 'SetAccount',
    components: { MultiStep },
    data() {
        return {
            steps: [
                {
                    name: 'setAccountForm',
                    component: SetAccountForm,
                    validation: this.validateSetAccountForm
                },
                {
                    name: 'source',
                    component: SelectGrid,
                    required: true,
                    data: [
                        { type: 'facebook', label: this.$i18n.t(`register.source.facebook`), img: { file: require('@/assets/img/social/facebook.svg'), size: 56 } },
                        { type: 'instagram', label: this.$i18n.t(`register.source.instagram`), img: { file: require('@/assets/img/social/instagram.svg') } },
                        { type: 'tiktok', label: this.$i18n.t(`register.source.tiktok`), img: { file: require('@/assets/img/social/tiktok.svg') } },
                        { type: 'linkedIn', label: this.$i18n.t(`register.source.linkedin`), img: { file: require('@/assets/img/social/linkedin.svg') } },
                        { type: 'blog', label: this.$i18n.t(`register.source.blog`), img: { file: require('@/assets/img/social/blog.svg') } },
                        { type: 'family', label: this.$i18n.t(`register.source.family`), img: { file: require('@/assets/img/social/podcast.svg') } },
                        { type: 'youtube', label: this.$i18n.t(`register.source.youtube`), img: { file: require('@/assets/img/social/youtube.svg') } },
                        { type: 'search', label: this.$i18n.t(`register.source.search`), img: { file: require('@/assets/img/social/search.svg') } },
                        { type: 'other', label: this.$i18n.t(`register.source.other`), img: { file: require('@/assets/img/social/other.svg') } }
                    ]
                },
                {
                    name: 'registrationReason',
                    component: SelectGrid,
                    required: true,
                    data: [
                        { type: 'travels', label: this.$i18n.t(`register.reason.travels`), img: { file: require('@/assets/img/social/travels.svg') } },
                        { type: 'professionalDevelopment', label: this.$i18n.t(`register.reason.professionalDevelopment`), img: { file: require('@/assets/img/social/professional-development.svg') } },
                        { type: 'school', label: this.$i18n.t(`register.reason.school`), img: { file: require('@/assets/img/social/school.svg') } },
                        { type: 'wantsDevelopment', label: this.$i18n.t(`register.reason.wantsDevelopment`), img: { file: require('@/assets/img/social/wants-development.svg') } },
                        { type: 'family', label: this.$i18n.t(`register.reason.family`), img: { file: require('@/assets/img/social/family.svg') } },
                        { type: 'other', label: this.$i18n.t(`register.reason.other`), img: { file: require('@/assets/img/social/other.svg') } }
                    ]
                },
                {
                    name: 'lessonsCount',
                    component: SelectRow,
                    required: true,
                    validation: this.finishSetAccount,
                    data: [
                        { type: 1, label: this.$i18n.t(`register.lessons-count.1`) },
                        { type: 2, label: this.$i18n.t(`register.lessons-count.2`) },
                        { type: 3, label: this.$i18n.t(`register.lessons-count.3`) },
                        { type: 4, label: this.$i18n.t(`register.lessons-count.4`) },
                        { type: 5, label: this.$i18n.t(`register.lessons-count.5+`) }
                    ]
                }
            ]
        }
    },
    created() {
        if(!this.isStudentFromRegistration) this.steps = [this.steps[0]];
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters(['isStudentFromRegistration']),
    },
    methods: {
        async validateSetAccountForm(data) {
            const { error } = await api(updateProfileData, {
                userUuid: this.$store.getters.userUuid,
                ...data.stepData,
                phone: data.stepData.phone || null
            })

            this.$bus.$emit('validateSetAccountForm', { error })

            if(error) return { silenceError: error }
        },
        async finishSetAccount() {
            const stepsData = this.$refs.multiStep.formData;
            const { error } = await api(updateRegistrationDataInput, {
                input: {
                    lessonsCount: stepsData.lessonsCount,
                    registrationReason: stepsData.registrationReason,
                    source: stepsData.source
                }
            })

            if(error) return { error };
            
            this.$router.push({ name: 'dashboard' });
        },
    }
}
</script>