<template>
    <label class="input"> {{data.label}}
        <div class="select">
            <img src="../../assets/img/arrow-dark.svg" alt="">
            <select :name="data.name" :value="value" @change="onChange">
                <option v-if="data.placeholder" :value="data.autoPlaceholder ? true : ''" disabled selected>{{data.placeholder}}</option>
                <option v-for="{text, value} in data.options" :key="value" :value="value">{{text}}</option>
            </select>
        </div>
    </label>
</template>

<script>
export default {
    name: 'Select',
    props: ['data', 'value'],
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.value)
        }
    }
}
</script>
