var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'learning__footer',
    {
        'learning__footer--correct': _vm.isTaskFinished && _vm.isAnswerCorrect,
        'learning__footer--incorrect': _vm.isTaskFinished && !_vm.isAnswerCorrect,
        'learning__footer--half-correct': _vm.isTaskFinished && _vm.isAnswerCorrect === 'HALF_CORRECT' || (_vm.isFlashcardsActivity && _vm.dontKnow),
    }
]},[_c('div',{class:['learning__footer-wrapper', { 'learning__footer-wrapper--summary': _vm.isSummary, 'learning__footer-wrapper--goals': _vm.isGoals }]},[(_vm.isSummary)?[_c('Button',{staticClass:"default",attrs:{"text":_vm.$t('learning.summary.return-to-space', _vm.transUi)},nativeOn:{"click":function($event){return _vm.handleCloseAction.apply(null, arguments)}}}),(!_vm.isCompleted || _vm.getNextMaterial)?_c('Button',{staticClass:"primary ml-auto",attrs:{"text":_vm.isCompleted ? _vm.$t('learning.summary.next-lesson', _vm.transUi) : _vm.$t('learning.activities.footer.continue', _vm.transUi),"loading":_vm.summaryPrimaryBtnLoading},nativeOn:{"click":function($event){return _vm.handleSummaryPrimaryBtn.apply(null, arguments)}}}):_vm._e()]:(_vm.isTaskFinished)?[(_vm.isPreviewMode)?_c('Button',{staticClass:"default",attrs:{"text":_vm.$t('learning.activities.footer.back', _vm.transUi)},nativeOn:{"click":function($event){return _vm.$store.commit('learning/previousActivity', {
                    store: _vm.$store
                })}}}):_vm._e(),(_vm.showAnswer)?_c('div',{staticClass:"answer"},[(!_vm.isMobile && !_vm.isFakeActivity)?_c('StatusIcon',{attrs:{"success":_vm.isAnswerCorrect}}):_vm._e(),(!_vm.isFakeActivity)?_c('div',{staticClass:"answer__header"},[_c('div',{staticClass:"answer__title"},[(_vm.isMobile)?_c('StatusIcon',{attrs:{"size":24,"iconSize":16,"success":_vm.isAnswerCorrect}}):_vm._e(),_vm._v(" "+_vm._s(_vm.answerTitle)+" ")],1),_c('div',{staticClass:"answer__info"},[_c('p',{staticClass:"answer__status"},[_vm._v(_vm._s(_vm.answerStatus))]),(((!_vm.isAnswerCorrect || _vm.isAnswerCorrect === 'HALF_CORRECT') || _vm.getCurrentSlide.activity?.type === 'open_ended') && _vm.getCorrectAnswer)?_c('p',{staticClass:"answer__correct-answer"},[_vm._v(_vm._s(_vm.getCorrectAnswer))]):_vm._e()])]):_vm._e()],1):_vm._e(),_c('Button',{class:[_vm.correctAnswerClass, 'elearning-go-forward'],attrs:{"text":_vm.$t('learning.activities.footer.continue', _vm.transUi)},nativeOn:{"click":function($event){return _vm.$store.commit('learning/nextActivity')}}})]:[(_vm.handleBtns.default)?_c('Button',_vm._b({class:['default', _vm.handleBtns.default.class],attrs:{"text":_vm.handleBtns.default.text,"disabled":_vm.handleBtns.default.disabled},nativeOn:{"click":function($event){return _vm.handleBtns.default.onClick.apply(null, arguments)}}},'Button',_vm.handleBtns.default.props,false)):_vm._e(),(_vm.handleBtns.primary)?_c('Button',_vm._b({class:[_vm.handleBtns.primary.class, 'elearning-go-forward'],attrs:{"text":_vm.handleBtns.primary.text,"disabled":_vm.handleBtns.primary.disabled,"loading":_vm.handleBtns.primary.loading},nativeOn:{"click":function($event){return _vm.handleBtns.primary.onClick.apply(null, arguments)}}},'Button',_vm.handleBtns.primary.props,false)):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }