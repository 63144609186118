import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/graphql/api.js'
import { unreadMessagesCount } from '@/graphql/queries/unreadMessagesCount'
import { navData } from '@/graphql/queries/navData'
import { getAvailableLanguages } from '@/graphql/queries/getAvailableLanguages'
import { studentBookingInfo } from '@/graphql/queries/studentBookingInfo'
import { activeStudentGroups } from '@/graphql/queries/activeStudentGroups'
import { getSynthesizeText } from '@/graphql/queries/getSynthesizeText'
import { createMessage } from '@/graphql/mutations/createMessage'
import { updateChatMessage } from '@/graphql/mutations/updateChatMessage'
import { studentMobileAppUsed } from '@/graphql/mutations/studentMobileAppUsed'
import scheduleStore from '@/store/scheduleStore'
import toolsStore from '@/store/toolsStore'
import mediaStore from '@/store/mediaStore'
import flashcardsStore from '@/store/flashcardsStore'
import coursePlanStore from '@/store/coursePlanStore'
import learningCoursePlanStore from '@/store/learningCoursePlanStore'
import learningStore from '@/store/learningStore'
import { schoolV2 } from '@/plugins/axios'
import accessStorage from '@/functions/accessStorage'
import { i18n } from '@/i18n/i18n'
import findAndUpdateChatMessage from '@/functions/findAndUpdateChatMessage'

import { STUDENT_TYPE } from '@/constants/studentTypes';
import { APP_MODES } from '@/router/appModes'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            hourFormat: '24_h',
            dateFormat: 'DD/MM/YYYY',
        },
        currentRoute: null,
        studentHadLesson: false,
        activeStudentGroups: [],
        studentPackagesGroups: [],
        passwordLength: 8,
        messages: {},
        editMessage: null,
        unreadMessagesCount: null,
        newMessagesSeparator: null,
        lastLearningLesson: null,
        windowWidth: null,
        windowHeight: null,
        mobileWidth: 992,
        audioUuid: null,
        isMobile: null,
        isMobileDevice: false,
        isIOSApp: false,
        isAndroidApp: false,
        blurBackground: false,
        studentLanguage: null,
        languageLevel: '',
        lectorsLanguages: null,
        packagesGroups: {},
        havePackage: true,
        config: {
            modifyMessagesTime: null,
            timezones: null,
            defaultTimezone: 'Europe/Warsaw',
            locales: null,
            genders: null,
            dateFormats: null,
            hourFormarts: null,
            countries: null
        },
        wideOnboarding: false,
        facebookLoginScope: {}
    },
    mutations: {
        setBookingInfo: (state, data) => {
            state.studentLanguage = data.studentLanguage || 'eng';
            state.languageLevel = data.languageLevel;
            state.studentHadLesson = data.hadLesson;
            state.packagesGroups = data.packagesGroups;
            state.activeStudentGroups = data.activeStudentGroups;
            state.studentPackagesGroups = data.packagesGroups;

            if (!state.user.student?.isBusiness) {
                state.havePackage = data.havePackage
            }
        },
        setIOSApp: (state) => state.isIOSApp = true,
        setAndroidApp: (state) => state.isAndroidApp = true,
        setMessages: (state, data) => state.messages[data.uuid] = data.arr,
        setUser: (state, data) => state.user = data,
        updateConfig: (state, data) => state.config = Object.assign(state.config, data),
        setUnreadMessagesCount: (state, data) => state.unreadMessagesCount = data,
        setLastLearningLesson: (state, data) => state.lastLearningLesson = data,
        setNewMessagesSeparator: (state, data) => state.newMessagesSeparator = data,
        setEditMessage: (state, data) => state.editMessage = data,
        handleUpdatedChatMessage: function(state, data) {
            const message = findAndUpdateChatMessage(state.messages[data.messageGroupUuid], data)
            
            if(message) this._vm.$bus.$emit('updateMessages')
            else this._vm.$bus.$emit('handleUpdatedChatMessage', data)
        },
        setLectorsLanguages: (state, data) => state.lectorsLanguages = data,
        setFacebookLoginScope: (state, data) => state.facebookLoginScope = data,
        updateMobileAppStatus: (state, data) => state.user.student.mobileApp.isInstalled = data,
    },
    actions: {
        getUnreadMessagesCount: async ({ commit }) => {
            const { res } = await api(unreadMessagesCount)
            commit('setUnreadMessagesCount', res.currentUser.unreadMessagesCount)
        },
        getNavData: async ({ commit }) => {
            const { res } = await api(navData)
            commit('setUnreadMessagesCount', res.currentUser.unreadMessagesCount)
            commit('setLastLearningLesson', res.elearningCourseLastLesson)
        },
        async uploadFile(context, payload) {
            try {
                const formData = new FormData()
                formData.append('file', payload)
                let res = await schoolV2.post('file/upload', formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessStorage('authToken')}`
                    }
                })

                return { uuid: res.data.uuid }
            } catch {
                this._vm.$notify({
                    title: i18n.t('notify.error.title'),
                    text: i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
                
                return { uploadError: true }
            }
        },
        async createChatMessage(context, payload) {
            const { error } = await api(createMessage, payload)

            if(error) {
                this._vm.$notify({
                    title: i18n.t('notify.error.title'),
                    text: i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            }
        },
        async updateChatMessage(context, payload) {
            const { res, error } = await api(updateChatMessage, payload)

            if(error) {
                this._vm.$notify({
                    title: i18n.t('notify.update-message-time-error.title'),
                    text: i18n.t('notify.update-message-time-error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } else if(!res.updateChatMessage) {
                this._vm.$notify({
                    title: i18n.t('notify.error.title'),
                    text: i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            }
        },
        async getLectorsLanguages({ commit }) {
            const { res } = await api(getAvailableLanguages)
            commit('setLectorsLanguages', res.getAvailableLanguages)
        },
        async getBookingInfo({ commit }, payload ) {
            const includePending = payload ? payload.includePending : false;
            
            const { res:bookingInfo } = await api(studentBookingInfo)

            const { res:asgResponse } = await api(activeStudentGroups, { includePending });

            const packagesGroups = bookingInfo.currentUser.student?.studentPackagesGroups
            const havePackage = Boolean(packagesGroups.find(el => el.left > 0));

            commit('setBookingInfo', {
                activeStudentGroups: asgResponse.currentUser.student?.activeStudentGroups,
                studentLanguage: bookingInfo.currentUser?.student?.studentLanguage,
                languageLevel: bookingInfo.currentUser?.student?.studentLanguageLevel,
                hadLesson: bookingInfo.currentUser?.student?.lastStudentLesson,
                packagesGroups: packagesGroups,
                havePackage: havePackage,
            })

            commit('schedule/setCourses', asgResponse.currentUser.student?.activeStudentGroups, { root: true });
        },
        async getSynthesizeText(context, payload) {
            const { res } = await api(getSynthesizeText, {
                text: payload.text,
                gender: payload.gender || 'MALE'
            })

            return `data:audio/mp3;base64, ${ res.getSynthesizeText }`
        },
        async sendAppStatus({ commit }) {
            const { error } = await api(studentMobileAppUsed)
            if(error) return { error };

            commit('updateMobileAppStatus', true )
        }
    },
    getters: {
        getCurrentUserAvatar : state => state.user.avatarUrl,
        getStudentCanBook: state => {
            return state.activeStudentGroups.some(item => item.instantBookingUrl)
        },
        getStudentHadLesson: state => state.studentHadLesson,
        getPackagesGroups: state => state.packagesGroups,
        isOnlyOnePackageGroup: state => state.packagesGroups.length === 1,
        getOnlyOnePackageGroup: state => state.packagesGroups[0],
        hasAtLeastOneOnePersonPackage: state => {
            return state.packagesGroups.some(el => [
                STUDENT_TYPE.INDIVIDUAL, STUDENT_TYPE.KID, STUDENT_TYPE.TEEN, STUDENT_TYPE.YOUNG_ADULT].includes(el.type))
        },
        getHavePackage: state => state.havePackage,
        getShowBuyToBook: state => !state.havePackage,
        getLanguageLevel: state => state.languageLevel,
        getStudentLanguage: state => state.studentLanguage,
        isBusiness: state => state.user.student?.isBusiness,
        isDemo: state => state.user.student?.isDemo,
        userUuid: state => state.user.uuid,
        studentUuid: state => state.user.student?.uuid,
        userType: state => state.user.type,
        avatarUrl: state => state.user.avatarUrl,
        timezone: state => state.user.timezone,
        hourFormat: state => state.user.hourFormat,
        dateFormat: state => state.user.dateFormat,
        actionRequired: state => {
            const { firstName, lastName, gender } = state.user

            return !firstName || !lastName || !gender
        },
        getChildren(state) {
            return state.user.student.children
        },
        studentCompany: state => state.user.student?.company?.name,
        getCurrentUserLang : state => state.user.locale || 'pl',
        atLeastOneSharedOrGroupCourse: state => {
            return state.activeStudentGroups.some(el => ['group', 'shared'].includes(el.group.type) )
        },
        hasOnlyGroupCourses: state => {
            if(!state.packagesGroups.length) return;
            return state.packagesGroups.every(el => el.type === 'group');
        },
        isB2CGroupStudent: (state, getters) => !getters.isBusiness && getters.hasOnlyGroupCourses,
        isMobileApp: (state) => state.isIOSApp || state.isAndroidApp,
        isStudentFromRegistration : state => state.user.student.registrationData,
        getFacebookLoginScope: state => state.facebookLoginScope,
        isSpaceModeAvailable: state => state.user.access.space,
        isLearningModeAvailable: state => state.user.access.elearning,
        isSpaceMode: (state, getters) => !getters.isLearningMode,
        isLearningMode: state => {
            return state.currentRoute.path.split('/')[2] === APP_MODES.LEARNING.urlPrefix
        },
        getCurrentAppMode: (state, getters) => {
            const { SPACE, LEARNING } = APP_MODES

            if(getters.isLearningMode) return LEARNING

            return SPACE
        },
        isMobileAppInstalled: state => state.user.student?.mobileApp.isInstalled,
        getLastLearningLesson: state => state.lastLearningLesson,
    },
    modules: {
        schedule: {
            namespaced: true,
            ...scheduleStore
        },
        tools: {
            namespaced: true,
            ...toolsStore
        },
        media: {
            namespaced: true,
            ...mediaStore
        },
        flashcards: {
            namespaced: true,
            ...flashcardsStore
        },
        coursePlan: {
            namespaced: true,
            ...coursePlanStore
        },
        learningCoursePlan: {
            namespaced: true,
            ...learningCoursePlanStore
        },
        learning: {
            namespaced: true,
            ...learningStore
        }
    },
})
