var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ask-ai-search"},[(_vm.hint && !_vm.askAI.game && !_vm.askAI.loading && _vm.askAI.messages.length <= 1)?_c('div',{staticClass:"ask-ai-search__hint-container"},[_c('AskAiHint',{attrs:{"hint":_vm.hint,"disabled":_vm.askAI.loading || _vm.askAI.isDisabled},nativeOn:{"click":function($event){return _vm.handleHint(_vm.hint.prompt, _vm.hint.uuid)}}})],1):_vm._e(),_c('div',{staticClass:"ask-ai-search__footer"},[(_vm.askAIModal && !_vm.askAI.game)?_c('div',{staticClass:"ask-ai-search__modal-open"},[(_vm.askAI.showStartTooltip)?_c('AskAIStartTooltip'):_vm._e(),_c('img',{class:['ask-ai-search__modal-icon', { 'ask-ai-search__modal-icon--disabled': _vm.askAI.loading, 'ask-ai-search__modal-icon--mobile': _vm.isMobile }],attrs:{"src":require("@/assets/img/tools/askAI-primary-icon.svg"),"alt":""},on:{"click":_vm.openAskAIModal}})],1):_vm._e(),_c('div',{class:[
            'ask-ai-search__prompts-wrapper',
            {
                'ask-ai-search__prompts-wrapper--active' : _vm.searchActive,
                'ask-ai-search__prompts-wrapper--mobile' : _vm.isMobile
            }
        ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.askAI.prompt),expression:"askAI.prompt"}],ref:"input",staticClass:"search-input",attrs:{"placeholder":_vm.$t('widget.gpt-chat.ask-ai-placeholder'),"disabled":_vm.askAI.loading || _vm.askAI.isDisabled},domProps:{"value":(_vm.askAI.prompt)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleRequest()},"focus":_vm.focusSearchInput,"blur":function($event){_vm.searchActive = false},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.askAI, "prompt", $event.target.value)},_vm.resize]}}),_c('button',{class:['send', { 'loading': _vm.askAI.loading, 'disabled': _vm.askAI.isDisabled, 'send--mobile': _vm.isMobile }],on:{"click":function($event){return _vm.handleRequest()}}},[(!_vm.isMobile)?_c('img',{attrs:{"src":require("@/assets/img/chat/send.svg"),"width":"24","height":"24","alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/chat/send-primary.svg"),"width":"24","height":"24","alt":""}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }