<template>
    <div v-if="!loading" class="basic">
        <form @submit.prevent="submit" v-for="(item, id) in data" :name="item.name" :key="id" class="data">
            <div class="data-header">
                <h3 class="section-title">{{item.title}}</h3>
                <span v-if="allowEdit" @click="data[id].edit = true; readyAvatar.src = null" class="edit">{{$i18n.t(`account.basic.edit`)}}</span>
            </div>
            <template v-if="!lack(item.name) || data[id].edit">
                <label v-if="item.changeAvatar" class="avatar-input" :class="{'block': !data[id].edit}" for="setAvatar">
                    <div class="avatar">
                        <img :src="readyAvatar.src ? readyAvatar.src : checkAvatar" alt="Avatar">
                        <div class="add">
                            <img src="@/assets/img/plus.svg" alt="Add">
                        </div>
                    </div>
                    <input :disabled="!data[id].edit" id="setAvatar" ref="input" type="file" accept="image/*" @change="getImage($event)">
                </label>
                <div v-for="(row, index) in item.rows" :key="index" class="row">
                    <DataRow
                        :label="row.label" 
                        :value="row.value"
                        :component="row.component"
                        :edit="data[id].edit"
                        :options="row.options"
                        :disabled="row.disabled"
                        :type="row.type"
                        :name="row.name"
                        :placeholder="row.placeholder"
                        :min="row.min"
                        :max="row.max"
                        :class="{'error': data[id].edit && getValidation(row.name)}"
                    />
                    <p class="validation" v-if="data[id].edit">{{$i18n.t(getValidation(row.name))}}</p>
                </div>
            </template>
            <p v-if="lack(item.name) && !data[id].edit" class="lack">{{$i18n.t(`account.basic.lack`)}}</p>
            <div v-if="data[id].edit" class="buttons">
                <Button class="default font-medium" type="button" :text="$i18n.t(`account.basic.cancel`)" @click.native="data[id].edit = false; validation = null; readyAvatar.src = null"/>
                <Button class="primary font-medium" type="submit" :loading="submitLoading" :text="$i18n.t(`account.basic.submit`)"/>
            </div>
        </form>

        <ManageAccount v-if="!isBusiness" />

        <Modal v-if="modal" @hide="hideModal">
            <div class="set-avatar modal-inner">
                <ModalHeader :title="$i18n.t('set-avatar.title')" @hide="hideModal"/>
                <div class="action">
                    <div class="images">
                        <div class="cropping">
                            <div class="ratio-container">
                                <div class="ratio-box">
                                    <img ref="image" :src="avatar" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="destination">
                            <p class="title">{{$i18n.t('set-avatar.preview')}}</p>
                            <img :src="destination" alt="">
                        </div>
                    </div>
                    <div class="controls">
                        <div class="zoom">
                            <p class="title">{{$i18n.t('set-avatar.size')}}</p>
                            <input id="cropper-range" type="range" v-model.number="zoomValue" min="0" :max="zoomMax">
                            <div class="buttons">
                                <button @click="zoom(false)"><img :src="cropperMinus" alt=""></button>
                                <button @click="zoom(true)"><img :src="cropperPlus" alt=""></button>
                            </div>
                        </div>
                        <div class="navs">
                            <p class="title">{{$i18n.t('set-avatar.position')}}</p>
                            <div class="buttons">
                                <button @click="move('top')"><img :src="arrow2" alt="" class="top"></button>
                                <button @click="move('bottom')"><img :src="arrow2" alt="" class="bottom"></button>
                                <button @click="move('left')"><img :src="arrow2" alt="" class="left"></button>
                                <button @click="move('right')"><img :src="arrow2" alt="" class="right"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <Button class="default cancel" :text="$i18n.t('set-avatar.cancel')" @click.native="hideModal"/>
                    <Button class="primary save" :text="$i18n.t('set-avatar.save')" @click.native="saveAvatar"/>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { userData } from '@/graphql/queries/userData'
import { availableCountries } from '@/graphql/queries/availableCountries'
import { availableGenders } from '@/graphql/queries/availableGenders'
import { availableTimezones } from '@/graphql/queries/availableTimezones'
import { companyData } from '@/graphql/queries/companyData'
import { updateProfileData } from '@/graphql/mutations/updateProfileData'
import { updateProfileContactData } from '@/graphql/mutations/updateProfileContactData'
import { updateStudentAddress } from '@/graphql/mutations/updateStudentAddress'
import { updateUserRegionalSettings } from '@/graphql/mutations/updateUserRegionalSettings'
import api from '@/graphql/api.js'
import DataRow from './DataRow'
import ManageAccount from './ManageAccount'
import Button from '@/components/reusable/Button'
import Input from '@/components/reusable/Input'
import TelInput from '@/components/reusable/TelInput'
import Search from '@/components/reusable/Search'
import DateSelect from '@/components/reusable/DateSelect'
import Select from '@/components/reusable/Select'
import changeAvatarMixin from '@/mixins/changeAvatar'
import Modal from '@/components/layout/Modal'
import ModalHeader from '@/components/layout/ModalHeader'
import { mapGetters } from 'vuex'

export default {
    name: 'Basic',
    components: {
        DataRow,
        ManageAccount,
        Button,
        Modal,
        ModalHeader
    },
    data() {
        return {
            loading: true,
            submitLoading: false,
            base: {},
            contact: {},
            contract: {},
            invoice: {},
            regional: {},
            data: null,
            validation: {}
        }
    },
    mixins: [changeAvatarMixin],
    async created() {
        this.$bus.$on('getCompanyData', async () => {
            const taxNumber = document.querySelector('[name="addressTaxNumber"]').value
            if(!taxNumber) return
            document.querySelector(`form[name="invoice"] .input.search`).classList.add('loading')
            const {res} = await api(companyData, {
                taxNumber
            })

            if(!res.companyData.countryCode) delete res.companyData.countryCode
            if(!res.companyData.taxNumber) delete res.companyData.taxNumber

            for (const [key, value] of Object.entries(res.companyData)) {
                document.querySelector(`form[name="invoice"] [name="address${key.charAt(0).toUpperCase() + key.slice(1)}"]`).value = value
            }
            this.validation = {}
            document.querySelector(`form[name="invoice"] .input.search`).classList.remove('loading')
        })
        
        await this.setData()
        this.loading = false
        this.$emit('loaded')
    },
    beforeDestroy() {
        this.$bus.$off('getCompanyData')
    },
    methods: {
        async submit(e) {
            this.submitLoading = true

            //get mutation
            const mutations = {
                base: updateProfileData,
                contact: updateProfileContactData,
                contract: updateStudentAddress,
                invoice: updateStudentAddress,
                regional: updateUserRegionalSettings
            }
            const mutation = mutations[e.target.name]
            //create form data
            const formData = {}
            if(e.target.name === 'contract' || e.target.name === 'invoice') formData.type = e.target.name
            //get form inputs
            let elements = Array.from(e.target.elements)
            const tagNames = ['INPUT', 'SELECT']
            //get inputs values
            elements = elements.filter(item => tagNames.includes(item.tagName) && !item.disabled && item.name)
            elements.forEach(item => formData[item.name] = item.value || null)

            const {error} = await api(mutation, {
                ...formData,
                userUuid: this.$store.getters.userUuid
            })

            const validationFailed = error?.find(item => item.message === 'validation')
            if(validationFailed) this.validation = validationFailed.extensions.validation
            else if(error) {
                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } else {
                if(e.target.name === 'regional' && formData.language !== this.$store.state.user.locale) {
                    const URL = window.location.href.split('/')
                    URL[3] = formData.language
                    
                    window.location.href = URL.join('/')
                } else {
                    await this.updateAvatar()
                    await this.setData()
                    
                    this.$notify({
                        title: this.$i18n.t('notify.saved'),
                        text: this.$i18n.t('notify.data-changed'),
                        data: {
                            type: 'success'
                        }
                    })
                    this.validation = {}
                }
            }
            this.submitLoading = false
        },
        getValidation(val) {
            return this.validation?.[val]?.[0].message
        },
        async setData() {
            const {res} = await api(userData)
            
            this.$store.commit('setUser', res.currentUser)
            this.$moment.tz.setDefault(this.$store.getters.timezone)
            const user = this.$store.state.user
            this.readyAvatar.src = null

            this.base = {
                firstName: user.firstName,
                lastName: user.lastName,
                gender: user.gender,
                birthDate: user.student.birthDate
            }

            this.contact = {
                phone: user.phone
            }

            this.contract = user.student.contractAddress

            this.invoice = user.student.invoiceAddress

            this.regional = {
                language: user.locale,
                dateFormat: user.dateFormat,
                hourFormat: user.hourFormat,
                timezone: user.timezone
            }
            
            if(!this.countries) {
                let {res:countries} = await api(availableCountries)
                this.$store.state.config.countries = countries.configuration.availableCountries
            }
            const countiresOptions = this.countries?.map(item => {
                return {
                    value: item.code,
                    text: item.name
                }
            })

            if(!this.genders) {
                let {res:genders} = await api(availableGenders)
                this.$store.state.config.genders = genders.configuration.availableGenders
            }
            const genderOptions = this.genders?.map(item => {
                return {
                    value: item,
                    text: this.$i18n.t(`general.gender.${item}`)
                }
            })

            if(!this.timezones) {
                let {res:timezones} = await api(availableTimezones)
                this.$store.state.config.timezones = timezones.configuration.availableTimezones
            }
            const timezonesOptions = this.timezones.map(item => {
                return {
                    value: item,
                    text: item
                }
            })

            this.data = [
                {   
                    name: 'base',
                    edit: false,
                    title: this.$i18n.t(`account.basic.form.base`),
                    changeAvatar: true,
                    rows: [
                        {label: this.$i18n.t(`forms.firstName`), name: 'firstName', value: this.base.firstName, component: Input},
                        {label: this.$i18n.t(`forms.lastName`), name: 'lastName', value: this.base.lastName, component: Input},
                        {label: this.$i18n.t(`forms.gender`), name: 'gender', value: this.base.gender, component: Select, options: genderOptions, type: 'gender', placeholder: this.$t('general.choose')},
                        {label: this.$i18n.t(`forms.birthDate`), name: 'birthDate', value: this.base.birthDate, min:'1900-01-01', max: this.$moment().subtract(4, 'years').format('YYYY-MM-DD'), component: DateSelect},
                    ]
                },
                {
                    name: 'contact',
                    edit: false,
                    title: this.$i18n.t(`account.basic.form.contact`),
                    rows: [
                        {label: this.$i18n.t(`forms.email`), value: res.currentUser.email, component: Input, disabled: true},
                        {label: this.$i18n.t(`forms.phone`), name: 'phone', value: this.contact.phone, component: TelInput},
                    ]
                },
                {
                    name: 'contract',
                    edit: false,
                    title: this.$i18n.t(`account.basic.form.contract`),
                    rows: [
                        {label: this.$i18n.t(`forms.addressStreet`), name: 'addressStreet', value: this.contract.street, component: Input},
                        {label: this.$i18n.t(`forms.addressPostCode`), name: 'addressPostCode', value: this.contract.postCode, component: Input},
                        {label: this.$i18n.t(`forms.addressCity`), name: 'addressCity', value: this.contract.city, component: Input},
                        {label: this.$i18n.t(`forms.addressCountryCode`), name: 'addressCountryCode', value: this.countries.find(item => item.code === this.contract.countryCode), component: Select, options: countiresOptions, type: 'country', placeholder: this.$i18n.t(`forms.choose-country`)},
                    ]
                },
                {
                    name: 'invoice',
                    edit: false,
                    title: this.$i18n.t(`account.basic.form.invoice`),
                    rows: [
                        {label: this.$i18n.t(`forms.addressTaxNumber`), name: 'addressTaxNumber', value: this.invoice.taxNumber, component: this.$route.params.locale === 'pl' ? Search : Input},
                        {label: this.$i18n.t(`forms.addressCompanyName`), name: 'addressCompanyName', value: this.invoice.companyName, component: Input},
                        {label: this.$i18n.t(`forms.addressStreet`), name: 'addressStreet', value: this.invoice.street, component: Input},
                        {label: this.$i18n.t(`forms.addressPostCode`), name: 'addressPostCode', value: this.invoice.postCode, component: Input},
                        {label: this.$i18n.t(`forms.addressCity`), name: 'addressCity', value: this.invoice.city, component: Input},
                        {label: this.$i18n.t(`forms.addressCountryCode`), name: 'addressCountryCode', value: this.countries.find(item => item.code === this.invoice.countryCode), component: Select, options: countiresOptions, type: 'country', placeholder: this.$i18n.t(`forms.choose-country`)},
                    ]
                },
                {
                    name: 'regional',
                    edit: false,
                    title: this.$i18n.t('account.basic.form.regional'),
                    rows: [
                        {label: this.$i18n.t(`forms.language`), name: 'language', value: this.regional.language, component: Select, options: [
                            {value: 'en', text: this.$i18n.t(`general.language.en`)},
                            {value: 'bg', text: this.$i18n.t(`general.language.bg`)},
                            {value: 'cz', text: this.$i18n.t(`general.language.cz`)},
                            {value: 'es', text: this.$i18n.t(`general.language.es`)},
                            {value: 'hu', text: this.$i18n.t(`general.language.hu`)},
                            {value: 'pl', text: this.$i18n.t(`general.language.pl`)},
                            {value: 'pt', text: this.$i18n.t(`general.language.pt`)},
                            {value: 'ro', text: this.$i18n.t(`general.language.ro`)},
                            {value: 'sk', text: this.$i18n.t(`general.language.sk`)},
                            {value: 'ua', text: this.$i18n.t(`general.language.ua`)}
                        ]},
                        {label: this.$i18n.t(`forms.timezone`), name: 'timezone', value: this.regional.timezone, component: Select, options: timezonesOptions},
                        {label: this.$i18n.t(`forms.clock`), name: 'hourFormat', value: this.regional.hourFormat, component: Select, options: [
                            {value: '24_h', text: this.$i18n.t('general.hourFormat.24_h')},
                            {value: '12_h', text: this.$i18n.t('general.hourFormat.12_h')},
                        ]},
                        {label: this.$i18n.t(`forms.date-format`), name: 'dateFormat', value: this.regional.dateFormat, component: Select, options: [
                            {value: 'DD/MM/YYYY', text: this.$i18n.t('general.dateFormat.DD/MM/YYYY')},
                            {value: 'MM/DD/YYYY', text: this.$i18n.t('general.dateFormat.MM/DD/YYYY')},
                            {value: 'YYYY/MM/DD', text: this.$i18n.t('general.dateFormat.YYYY/MM/DD')},
                        ]}
                    ]
                }
            ]
        },
        lack(type) {
            if(type === 'base' || type === 'contact') return false
            else if(Object.values(this[type]).some(item => !item)) return true    
        }
    },
    computed: {
        ...mapGetters(['isBusiness']),
        date: {
            get() {
                return this.$moment(this.base.student.birthDate).format('YYYY-MM-DD')
            }, 
            set(value) {
                this.base.student.birthDate = value
            }
        },
        allowEdit() {
            return !this.data.filter(item => item.edit).length
        },
        countries() {
            return this.$store.state.config.countries
        },
        genders() {
            return this.$store.state.config.genders
        },
        timezones() {
            return this.$store.state.config.timezones
        }
    }
}
</script>

