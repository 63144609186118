import gql from 'graphql-tag'

export const material = gql`
    query($uuid: ID!) {
        material(uuid: $uuid) {
            percentage
            title
            slides {
                type
                instructions
                media {
                    type
                    data
                }
                activity {
                    type
                    tasks {
                        uuid
                        result {
                            isCorrect
                            data
                        }
                        data
                    }
                }
            }
        }
        flashcardGroup(uuid: $uuid) {
            uuid
            name
            status
            flashcards {
                uuid
                word
                english
                activities {
                    type
                    status
                }
            }
        }
    }
`