export default {
    methods: {
        handleIconAnimation(ref, animateClass, timeout) {
            if(ref.classList.contains(animateClass)) return

            ref.classList.add(animateClass)

            setTimeout(() => {
                ref.classList.remove(animateClass)
            }, timeout)
        }
    }
}