<template>
    <div>
        <ToolsWrapper :class="[{ 'tools-wrapper--hide' : !showToolsWrapper }]" />
        <AskAIModal v-if="isAskAIModalOpen" />
        <AskAIResultsModal v-if="isAskAIResultsModalOpen" :gained="askAI.game.state.score.current" :max="askAI.game.state.score.max" />
    </div>
</template>

<script>
import ToolsWrapper from '@/components/tools/ToolsWrapper'
import AskAIModal from '@/components/tools/askAI/AskAIModal'
import AskAIResultsModal from '@/components/tools/askAI/AskAIResultsModal'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'ToolsGeneral',
    components: {
        ToolsWrapper,
        AskAIModal,
        AskAIResultsModal
    },
    computed: {
        ...mapState('tools', ['askAI']),
        ...mapGetters('tools', ['isAskAIModalOpen', 'isAskAIResultsModalOpen']),
        ...mapGetters(['isLearningMode']),
        showToolsWrapper() {
            const { name, fullPath } = this.$route

            return !['tools', 'messagesOnly', 'setAccount', 'progressTest', 'openai', 'placementTest', 'flashcards'].includes(name) 
                && !fullPath.includes('/onboarding')
                && !this.isLearningMode
        }
    }
}
</script>