import accessStorage from '@/functions/accessStorage';
import { tokenByUuid } from '@/graphql/queries/tokenByUuid';
import api from '@/graphql/api.js';

export default {
    methods: {
        async loginChild(uuid) {
            this.childLoading = true
            const {res} = await api(tokenByUuid, {
                userUuid: uuid
            })
            sessionStorage.getItem('authToken') ? sessionStorage.setItem('parentAuthToken', sessionStorage.getItem('authToken')) : localStorage.setItem('parentAuthToken', localStorage.getItem('authToken'))
            accessStorage('authToken', res.tokenByUuid.token)
            this.childLoading = false
            location.href = this.getRedirectURL()
        },
        goBackToParentAccount() {
            accessStorage('authToken', accessStorage('parentAuthToken'));
            sessionStorage.getItem('parentAuthToken')
                ? sessionStorage.removeItem('parentAuthToken')
                : localStorage.removeItem('parentAuthToken');
            location.href = this.getRedirectURL();
        },
        getRedirectURL() {
            return `/${location.pathname.split('/')[1]}/dashboard`
        }
    },
    computed: {
        showBackToParent() {
            return sessionStorage.getItem('parentAuthToken') || (localStorage.getItem('parentAuthToken') && !sessionStorage.getItem('authToken')) 
        }
    }
}