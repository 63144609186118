<template>
    <div class="picker" v-click-outside="hide">
        <nav class="picker__nav">
            <p v-for="item in nav" :key="item" :class="{'active': item === navActive}" @click="navActive = item">{{item}}</p>
        </nav>
        <div class="picker__search">
            <SimpleInput ref="searchInput" v-model="search" :data="input"/>
        </div>
        <div class="picker__content">
            <Emojies v-if="navActive === nav[0]" :search="search"/>
            <Gifs v-if="navActive === nav[1]" :search="search"/>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import SimpleInput from '../reusable/SimpleInput.vue'
import Emojies from './Emojies.vue'
import Gifs from './Gifs.vue'
import { mapState } from 'vuex'

export default {
    name: 'EmojiPicker',
    components: {
        SimpleInput,
        Emojies,
        Gifs
    },
    data() {
        return {
            navActive: 'Emoji',
            input: {
                placeholder: this.$i18n.t('messages.search'),
                change: 'input'
            },
            search: ''
        }
    },
    directives: {
        ClickOutside
    },
    mounted() {
        this.focusInput()
        this.popupItem = document.querySelector('.picker-button')
    },
    computed: {
        ...mapState([
            'editMessage'
        ]),
        nav() {
            const array = ['Emoji', "Gif's"]

            if(this.editMessage) array.pop()

            return array
        }
    },
    methods: {
        hide() {
            this.$emit('hide')
        },
        focusInput() {
            this.$refs.searchInput.$el.focus()
        },
        clearInput() {
            this.$refs.searchInput.value = ''
            this.search = ''
        }
    },
    watch: {
        navActive() {
            this.clearInput()
            this.focusInput()
        }
    }
}
</script>
