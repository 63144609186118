<template>
    <div class="activity-score">
        <p class="activity-score__title">
            Visible only in material preview mode <br> Students will not see it
        </p>
        <p  
            v-for="(value, key) in data" 
            :key="key"
            class="activity-score__item"
        >
            <b>{{ key }}:</b> {{ value }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'ActivityScore',
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.activity-score {
    width: 100%;
    margin-top: 16px;
    padding: 16px;
    border: 1px solid $danger;
    border-radius: 8px;
    text-align: center;

    &__title {
        @include font-h3;
        color: $danger;
        margin-bottom: 16px;
    }

    &__item {
        margin-bottom: 8px;
    }
}
</style>