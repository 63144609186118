<template>
    <fade-transition mode="out-in">
        <div class="action action--courses" v-if="courses">
            <div class="courses-wrapper">
                <template v-if="courses.length">
                    <p class="courses-area-title">{{ $t('schedule-modal.select-course.your-teachers') }}</p>
                    <div class="courses-area courses-area--lectors">
                        <div
                            v-for="item in sortedCourses" :key="item.group.uuid"
                            @click="selectCourse(item)" 
                            class="course-box" 
                            :class="{'course--selected': isSelected(item.instantBookingUrl) && !selectedNewLector}"
                        >
                            <div class="course-box__avatar-area">
                                <img class="course-box__avatar avatar avatar-style" :src="setAvatar(item.group.user.avatarUrl)" width="32" height="32" alt=""> 
                                <img class="course-box__flag" :src="getCourseIcon(item.studentPackageGroup.language.shortName)" alt="Course">
                            </div>

                            <div class="course-box__content-area">
                                <div>
                                    <div class="course-box__name">{{ item.group.user.firstName }}</div>
                                    <div class="course-box__course-type">
                                        {{ $i18n.t(`schedule-modal.select-course.${ item.studentPackageGroup.type }`)}}
                                    </div>
                                    <div class="course-badges mt-6">
                                        <div :class="[
                                            'course-badge',
                                            { 'course-badge--blue': item.nextLesson?.startAt && item.nextLesson?.status === 'scheduled' },
                                            { 'course-badge--green': !item.nextLesson && item.lastLesson },
                                            { 'course-badge--yellow course-badge--icon-clock': item.nextLesson?.startAt && item.nextLesson?.status === 'pending' },
                                            { 'course-badge--gray course-badge--icon-error': !item.nextLesson && !item.lastLesson},
                                        ]">
                                            {{ getLessonLabel(item) }}
                                        </div>
                                        <LessonDuration v-if="item.studentPackageGroup.duration" :duration="item.studentPackageGroup.duration" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div v-if="newLectorCourses.length && courses.length" class="courses-area-separator"></div>

                <template v-if="newLectorCourses.length">
                    <p class="courses-area-title">{{ $t('schedule-modal.select-course.start-new-course') }}</p>
                    <div class="courses-area courses-area--add-new">
                        <div
                            v-for="item in newLectorCourses" :key="item.key"
                            @click="selectAddNewLector(item)" 
                            class="course-box"
                        >
                            <div class="course-box__avatar-area">
                                <div class="course-box__add-icon">
                                    <img src="@/assets/img/plus-gray-600.svg" alt="">
                                </div>

                                <img class="course-box__flag" :src="item.flag" alt="Course">
                            </div>

                            <div class="course-box__content-area">
                                <div class="course-box__name">
                                    {{ $t(`schedule-modal.select-course.${ item.groupType }`) }}
                                </div>
                                <div v-if="$te(`schedule-modal.select-course.language.${ item.langShortName }`)" class="course-box__course-type">
                                    {{ $t(`schedule-modal.select-course.language.${ item.langShortName }`) }}
                                </div>
                                <div class="course-badges mt-6">
                                    <div v-if="![null, undefined].includes(item.left)" :class="[
                                        'course-badge',
                                        { 'course-badge--gray': item.left >= 0 && !item.pendingExists},
                                        { 'course-badge--yellow': item.pendingExists }
                                    ]">
                                        {{$i18n.t('schedule-modal.select-course.lessons')}}: {{ item.left }}
                                    </div>
                                    <LessonDuration v-if="item.duration" :duration="item.duration" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div v-else class="action action--courses">
            <div class="loader-wrapper">
                <div class="loader"></div>
            </div>
        </div>
    </fade-transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FadeTransition from '@/components/reusable/FadeTransition';
import LessonDuration from '@/components/reusable/LessonDuration';
import getShortBookingUrl from '@/functions/getShortBookingUrl';
import flagMixin from '@/mixins/flag';
import setAvatarMixin from '@/mixins/setAvatar';
import modalMixin from '@/mixins/modal';
import timezoneDate from '@/functions/timezoneDate';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'ScheduleCourses',
    components: {
        FadeTransition,
        LessonDuration
    },
    mixins: [flagMixin, setAvatarMixin, modalMixin],
    data() {
        return {
            timezoneDate,
            filteredCourses: [],
            newLectorCourses: [],
        }
    },
    created() {
        this.combineNewLectorCourses();
    },
    mounted() {
        this.$store.commit("schedule/setSchedulePrimaryButton", { 
            btnPrimaryAction: () => {}, 
            btnPrimaryLabel: this.$t('calendar.courses.next')
        });
        this.$store.commit("schedule/setScheduleDefaultButton", { 
            btnDefaultAction: 'hideScheduleModal', 
            btnDefaultLabel: this.$t('calendar.courses.cancel')
        });

        this.$store.commit('schedule/setSelectedCourseInMemory', false);
        this.$store.commit('schedule/setSchedulePrimaryButtonDisabled', true);

        setTimeout(() => {
            document.querySelector('.modal-inner').classList.add('modal-inner--courses');
        }, 50);
    },
    computed: {
        ...mapState('schedule', [
            'courses',
            'selectedCourse',
            'selectedNewLector',
            'triggerButtonName'
        ]),
        ...mapState(['packagesGroups']),
        ...mapGetters('coursePlan', ['getStudentGroups']),
        sortedCourses() {
            const getLessonDate = el => new Date(el.nextLesson?.startAt || el.lastLesson.startAt)

            const sortLessonArray = (data, desc) => {
                return data.sort((a, b) => {
                    if(desc) return getLessonDate(b) - getLessonDate(a)

                    return getLessonDate(a) - getLessonDate(b)
                })
            }

            const nextLessonArray = sortLessonArray(this.courses.filter(el => el.nextLesson))
            const lastLessonArray = sortLessonArray(this.courses.filter(el => !el.nextLesson && el.lastLesson), true)
            const noLessonArray = this.courses.filter(el => !el.nextLesson && !el.lastLesson)
            
            return [...nextLessonArray, ...lastLessonArray, ...noLessonArray]
        }
    },
    methods: {
        async selectCourse(data) {
            const { commit } = this.$store;
            const { selectedCourse } = this.$store.state.schedule;

            commit('schedule/setSelectedCourseInMemory', data);
            commit('schedule/setApiUrl', selectedCourse.url);
        },
        async selectAddNewLector(data) {
            const { commit, dispatch } = this.$store;
            commit('schedule/setLoading', true);

            commit('schedule/setNewLectorSettingsInMemory', data);
            commit("schedule/switchCoursesView", false);
            await dispatch('schedule/runSwitchLectorFlow');
            commit('schedule/setLoading', false);
        },
        isSelected(url) {
            return this.selectedCourse.url === getShortBookingUrl(url);
        },
        combineNewLectorCourses() {
            const coursesFromActiveStudentGroups = this.courses.filter((item, index, self) =>
                !['group', 'shared'].includes(item.group.type) &&
                index === self.findIndex((t) => (
                    t.studentPackageGroup.language.shortName === item.studentPackageGroup.language.shortName &&
                    `${ t.group.type }${ t.studentPackageGroup.duration }` === `${ item.group.type }${ item.studentPackageGroup.duration }`
                ))
            );

            const coursesFromPackagesGroups = this.packagesGroups.filter((item) => 
                !['group', 'shared'].includes(item.type) && 
                !coursesFromActiveStudentGroups.some((course) => {
                    return course.studentPackageGroup.language.shortName === item.language.shortName && 
                        `${ course.group.type }${ course.studentPackageGroup.duration }` === `${ item.type }${ item.duration }`
                }));

            this.newLectorCourses = [...coursesFromActiveStudentGroups, ...coursesFromPackagesGroups].map(el => {
                return {
                    langShortName: el.studentPackageGroup?.language?.shortName || el.language?.shortName,
                    groupType: el.group?.type || el.type,
                    flag: this.getCourseIcon(el.studentPackageGroup?.language?.shortName || el.language?.shortName),
                    left: el.studentPackageGroup?.left ?? el.left,
                    pendingExists: this.courses.some(el2 => [el.group?.type, el.type].includes(el2.group.type) && el2.nextLesson?.status === 'pending'),
                    groupUuid: el.group?.uuid || null,
                    key: uuidv4(),
                    duration: el.studentPackageGroup?.duration || el.duration,
                    packageUuid: el.uuid
                }
            })
        },
        getLessonLabel(item) {
            const formatDate = date => this.$moment(date.startAt).format(timezoneDate('D.MM.YYYY - HH:mm'))

            if(item.nextLesson?.startAt) return formatDate(item.nextLesson)
            else if(item.lastLesson?.startAt) return formatDate(item.lastLesson)

            return this.$t('schedule-modal.select-course.no-lesson-scheduled')
        }
    }
}
</script>

<style scoped lang="scss">
.mt-6 {
    margin-top: 6px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.courses-wrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;

    @media (min-width: $tablet-switch-lector) {
        padding-right: 0;
    }
}

.courses-area {
    display: block;

    &--lectors {
        margin-bottom: 24px;
    }

    @media (min-width: $tablet-switch-lector) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }
}

.courses-area-separator {
    margin: 32px 0 24px;
    height: 1px;
    background-color: $gray-100
}

.courses-area-title {
    @include font-h3;
    color: $gray-900;
    margin: 0 16px 8px;

    @media (min-width: $tablet-switch-lector) {
        margin: 0 16px 20px;
    }
}

.courses-area--add-new {
    .course-box__flag {
        border: 2px solid $white;
    }
}

.course-box {
    border: 2px solid var(--grayscale-gray-200, #E6E7E9);
    border-radius: 16px;
    background: $white;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 18px;
    margin-bottom: 16px;
    transition: all .25s;

    @media(min-width: $tablet-switch-lector) {
        margin-bottom: 0;
    }

    &:hover {
        border-color: $primary-mid;
    }
}

.course-box__avatar-area {
    position: relative;
    flex: 56px;
    width: 56px;
    flex-grow: 0;
}

.course-box__avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.course-box__add-icon {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: $white;
    background-color: $gray-100;
    border: 2px dashed $gray-200;
}

.course-box__flag {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 2px solid $white;
}

.course-box__content-area {
    flex: 1;
}

.course-box__name {
    @include font-medium;
    color: $gray-900;
    margin-bottom: 2px;
}

.course-box__course-type {
    @include font-regular;
    color: $gray-600;
}

.course-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
}

.course-badge {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    padding: 0 3px 0 4px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;

    &--blue,
    &--green,
    &--icon-clock,
    &--icon-error {
        &::before {
            content: '';
            width: 12px;
            height: 12px;
            min-width: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    &--yellow {
        color: $warning-dark;
        background-color: $warning-light;

        &.course-badge--icon-clock {
            &::before {
                background-image: url('@/assets/img/schedule/clock-pending.svg');
            }
        }
    }

    &--blue {
        color: $primary;
        background-color: $primary-light;

        &::before {
            background-image: url('@/assets/img/schedule/clock.svg');
        }
    }

    &--gray {
        color: $gray-600;
        background-color: $gray-100;
    }

    &--icon-error {
        &::before {
            background-image: url('@/assets/img/schedule/error.svg');
        }
    }

    &--green {
        color: $secondary-dark;
        background-color: $secondary-light;

        &::before {
            background-image: url('@/assets/img/check-secondary-dark.svg');
        }
    }
}

.text-black {
    line-height: 16px;
}

.text-gray {
    line-height: 16px;
    color: $gray-600;
}

</style>
