<template>
    <Modal class="ask-ai-results-modal">
        <div class="modal-inner">
            <img class="confetti-img" src="@/assets/img/askAI/confetti.svg"/>
            <div :class="['score-wrapper', type]">
                <img v-if="type === 'won'" :src="require(`@/assets/img/askAI/star.svg`)">
                <span v-else class="result">{{ percentage }}</span>
            </div>
            <div class="text-wrapper">
                <span class="title">{{ $t(`ask-ai.results-modal.title.${ type }`) }}</span>
                <span class="subtitle">{{ $t(`ask-ai.results-modal.subtitle.${ type }`) }}</span>
            </div>
            <div class="btn-wrapper">
                <Button
                    @click.native="isLost ? restartGame() : showGames()"
                    class="primary"
                    :text="$t(isLost ? 'ask-ai.results-modal.button.try-again' : 'ask-ai.results-modal.button.next')"
                />
                <Button @click.native="hide" class="default" :text="$t('ask-ai.results-modal.button.exit')"/>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import modalMixin from '@/mixins/modal';
import Button from '@/components/reusable/Button'

export default {
    name: 'AskAIResultModal',
    mixins: [modalMixin],
    components: { Button },
    props: ['gained', 'max'],
    data() {
        return {
            percentage: '',
            type: ''
        }
    },
    created() {
        document.documentElement.classList.add('overflow-y-hidden')
    },
    mounted() {
        this.calculatePercentage();
    },
    beforeDestroy() {
        document.documentElement.classList.remove('overflow-y-hidden')
    },
    computed: {
        ...mapState('tools', ['askAI', 'askAIModal']),
        isLost() {
            return this.type === 'lost'
        },
        game() {
            return this.askAI.game
        }
    },
    methods: {
        hide() {
            this.askAI.loading = true
            this.askAI.game = null
            this.askAI.messages = []
            this.$store.dispatch('tools/getTeacherAIChatMessages')
        },
        calculatePercentage() {
            if (this.max === 0) this.percentage = '0%';

            const percentage = Math.round((this.gained / this.max) * 100);
            this.percentage = `${ percentage }%`;

            if (percentage === 100) this.type = 'won'
            else if (percentage > 40 && percentage < 100) this.type = 'good'
            else if (percentage === 0) this.type = 'lost'
            else this.type = 'ok';
        },
        restartGame() {
            this.$store.dispatch('tools/startTeacherAIGame', {
                uuid: this.game.gameType.uuid,
                prompt: this.game.gameType.prompt
            })
        },
        showGames() {
            this.askAIModal.open = true;
            this.askAIModal.activeTab = 'game';
            this.askAIModal.game.filter = 'all';
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-results-modal {
    .confetti-img {
        position: absolute;
        top: 0;
        left: 0;
    }

    .modal-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 388px;
        position: relative;
        padding: 32px;
    }

    .score-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        border-radius: 16px;
        z-index: 1;

        &.won {
            background-color: $warning-light;
            border-radius: 100px;
        }

        &.ok {
            background-color: $warning-light;
            color: $warning-dark
        }

        &.good {
            background-color: $secondary-light;
            color: $secondary-dark;
        }

        &.lost {
            background-color: $danger-light;
            color: $danger-middle;
        }

        .result {
            @include font-h2;
        }
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 18px 0 24px 0;
        gap: 12px;

        .title {
            @include font-h2;
        }

        .subtitle {
            @include font-regular;
            text-align: center;
        }
    }

    .btn-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
    }
}
</style>