import { mapState } from 'vuex';
import getSizes from '@/functions/getSizes';

const tooltipTranslate = {
    data() {
        return {
            timeout: 0
        }
    },
    methods: {
        showTranslateTooltip(e, word) {
            this.timeout = setTimeout(() => {
                this.$store.commit('tools/showTooltip', { 
                    type: 'translate',
                    word: word,
                    wordId: this.id, // only for words created by user
                    mouseTargetDimensions: getSizes(e.target),
                    extendedOptions: {
                        hasDeleteBtn: this.hasDeleteBtn,
                    }
                })
            }, 300);
        },
        hideTranslateTooltip() {
            clearTimeout(this.timeout);
            setTimeout(() => {
                if (this.$store.state.tools.tooltip.keepShowingTooltip) return false;
                
                this.$store.commit('tools/hideTooltip');
            }, 0);
        }
    }
}


const tooltipCalculations = {
    data() {
        return {
            targetWordOffsets: {},
            offsetFromEdge: 16,
            style: {
                tooltipContent: {
                    transform: 'translate(-50%, 0)',
                    top: 'initial',
                    bottom: 'initial',
                    left: 'initial',
                    width: 'initial',
                    maxHeight: 'initial'
                },
                angle: {
                    transform: 'translateX(-50%)'
                }
            },
        }
    },
    computed: {
        ...mapState(['windowWidth', 'windowHeight']),
        ...mapState('tools', ['tooltip'])
    },
    created() {
        this.targetWordOffsets = {
            top: this.tooltip.mouseTargetDimensions.top,
            right: this.windowWidth - this.tooltip.mouseTargetDimensions.right,
            left: this.tooltip.mouseTargetDimensions.left,
            bottom: this.windowHeight - this.tooltip.mouseTargetDimensions.bottom,
        }
    },
    methods: {
        addDirectionClass(direction) {
            return this.chooseDirection() === direction;
        },
        chooseDirection() {
            return ['top', 'bottom'].find(direction => this.targetWordOffsets[direction] > this.minTooltipOffset[direction])
        },
        displayVertical(direction) {
            const xSpace = dir => this.targetWordOffsets[dir] + this.tooltip.mouseTargetDimensions.width / 2 - (this.tooltipWidth / 2 + this.offsetFromEdge) 
            
            if(xSpace('right') < 0 || xSpace('left') < 0) { 
                const missing = xSpace('right') < 0 ? Math.abs(xSpace('right')) : xSpace('left');
                this.style.tooltipContent.transform = `translate(calc(-50% - ${ missing }px), 0)`; 
                this.style.angle.transform = `translateX(calc(-50% + ${ missing }px))`; 
            }

            if (direction === 'top') {
                this.style.tooltipContent.bottom = (this.windowHeight - this.tooltip.mouseTargetDimensions.top) + 8 + 'px';
                this.style.tooltipContent.left = this.tooltip.mouseTargetDimensions.left + (this.tooltip.mouseTargetDimensions.width / 2) + 'px';
                this.style.tooltipContent.maxHeight = this.tooltip.mouseTargetDimensions.top - 20 + 'px';
            } else {
                this.style.tooltipContent.bottom = 'initial';
                this.style.tooltipContent.top = this.tooltip.mouseTargetDimensions.bottom + 8 + 'px';
                this.style.tooltipContent.left = this.tooltip.mouseTargetDimensions.left + (this.tooltip.mouseTargetDimensions.width / 2) + 'px';    
                this.style.tooltipContent.maxHeight = this.windowHeight - this.tooltip.mouseTargetDimensions.bottom - 20 + 'px';
            }
        },
        setDirectionAndRun(direction) {
            switch (direction) {
                case 'top':
                    this.displayVertical('top');
                    break;
                default:
                    this.displayVertical('bottom');
                    break;
            }
        }
    }
}

export {
    tooltipTranslate,
    tooltipCalculations
};