<template>
    <transition :name="transitionName + '-fade'">
        <div :class="{'schedule-calendar__header--inactive': inactive || hasNoSlots}" class="schedule-calendar__header">
                <span class="schedule-calendar__date-day">
                    {{ setDayHeader(date) }} 
                    <i v-if="isToday" class="schedule-calendar__today-dot"></i>
                </span>
                <span class="schedule-calendar__date">
                    <span class="schedule-calendar__date">
                        <template v-if="$store.getters.dateFormat === 'DD/MM/YYYY'">
                            {{$moment(date).format(timezoneDate('D'))}}
                            <span class="upp">{{$moment(date).format(timezoneDate('MMM'))}}</span>
                        </template>
                        <template v-else>
                            <span class="upp">{{$moment(date).format(timezoneDate('MMM'))}}</span>
                            {{$moment(date).format(timezoneDate('D'))}}
                        </template>
                    </span>
                </span>
        </div>
    </transition>
</template>

<script>
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter';
import timezoneDate from '@/functions/timezoneDate';
import setDayHeader from '@/mixins/schedule/setDayHeader';

export default {
    name: 'ScheduleCalendarHeader',
    mixins: [setDayHeader],
    props: {
        transitionName: {},
        date: {
            required: true
        },
        inactive: {
            default: false
        },
        slots: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            capitalizeFirstLetter,
            timezoneDate
        }
    },
    computed: {
        isCorrectDate() {
            return this.date instanceof Date;
        },
        hasNoSlots() {
            return this.slots.length === 0;
        },
        isToday() {
            return this.$moment().isSame(this.date, 'day');
        }
    },
}
</script>