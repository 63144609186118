import gql from 'graphql-tag'

export const lessonFeedbackSurvey = gql`
    query($lessonUuid: ID!) {
        currentUser {
            student {
                lessonFeedbackSurvey(input: {lessonUuid: $lessonUuid}) {
                    lessonUuid
                    studentUuid
                    groupUuid
                    studentName
                    groupName
                    selected {
                        uuid
                        questionKey
                    }
                    remaining {
                        uuid
                        questionKey
                    }
                }
            }
        }
    }
`