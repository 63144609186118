<template>
    <nav :class="['nav', { 'blur-layer': showBlurLayer }]">
        <div class="nav__left">
            <router-link v-if="!isBackBtnFromCoursePlan" :to="getHomeRoute">
                <img class="logo" :src="isLearningMode ? logoSmartLearning : logo" alt="Fluentbe">
            </router-link>
            <div v-else-if="isBackBtnFromCoursePlan && isMobile" class="back-btn-wrapper" @click="$router.go(-1)">
                <img class="back-btn" src="@/assets/img/arrow-blue.svg" alt=""/>
            </div>
            <div class="nav__routes" :class="{'active': showMobileNav}" v-click-outside="hideNav">
                <template v-for="{text, to, count, component, type, params, path} in navRoutes">
                    <router-link v-if="to" :class="{'router-link-active': to === 'lessons' && $route.name === 'singleLesson', 'disabled': $route.name === to}" @click.native="hideNav" :key="to" :to="getRoute({to, params, path})">
                        <span class="label">{{ text }}
                            <span class="unread-count" v-if="count && unreadMessagesCount">{{ unreadMessagesCount }}</span>
                        </span>
                        <template v-if="windowWidth <= 992">
                            <img class="arrow" :src="arrow" alt="">
                            <img class="arrow-active" :src="arrowBlue" alt="">
                        </template>
                    </router-link>
                    <a 
                        v-else 
                        :class="[
                            'nav__dropdown', 
                            `nav__dropdown--${ type }`, 
                            { 'router-link-active': $route.name === type }
                        ]" 
                        :key="text" 
                        @click.self="toggleNestedRoutes(type)"
                    >
                        <div 
                            :class="[
                                'nav__dropdown-inner',
                                { 'nav__dropdown-inner--active': nestedRoutesStatus[type]}
                            ]" 
                            @click="toggleNestedRoutes(type)"
                        >
                            <span class="label">{{ text }}</span>
                            <img class="arrow" :src="arrow" alt="">
                            <img class="arrow-active" :src="arrowBlue" alt="">
                        </div>
                        <component 
                            :is="component"
                            v-if="nestedRoutesStatus[type]"
                            @hide="hideNestedRoutes"
                        />
                    </a>
                </template>
                <BookLessonBtn v-if="isSpaceMode" @hideNav="hideNav" placement="nav"/>
            </div>
        </div>
        <div class="nav__right">
            <div class="icons">
                <Help/>
                <div class="user" v-click-outside="hideMenuHandler">
                    <div class="controls" @click="toggleMenu">
                        <div class="account-avatar">
                            <img class="avatar-style" :src="setAvatar($store.state.user.avatarUrl)" width="32" height="32" alt="Icon">
                            <DotNotification v-if="!getLastLearningLesson && !isLearningMode && !loading" border />
                        </div>
                        <img :class="['arrow', {'open': showMenu}]" :src="arrow" alt="Icon">
                    </div>
                    <dropdown v-if="showMenu" pos="right">
                        <div v-if="showBackToParent" @click="goBackToParentAccount" class="back-to-parent">{{ $t('nav.back-to-parent') }}</div>
                        <router-link class="account-basic" @click.native="hideMenuHandler" :to="getAccountPath">{{ $t('nav.settings') }}</router-link>
                        <a v-if="!isLearningMode" class="test-connection" :href="goToPlatformSetup" target="_self">{{ $t('nav.test-connection') }}</a>
                        <div class="app-mode">
                            <span class="app-mode__title">{{ $t('nav.lesson-mode') }}:</span>
                            <div class="app-mode__wrapper">
                                <div v-if="isSpaceModeAvailable" @click="setAppMode(APP_MODES.SPACE.type)" :class="['app-mode__button-wrapper', { 'app-mode__button-wrapper--active': isSpaceMode }]">
                                    <span class="app-mode__option">{{ $t('nav.with-teacher') }}</span>
                                    <img v-if="isSpaceMode" src="@/assets/img/check.svg" alt="Icon">
                                </div>
                                <div v-if="isLearningModeAvailable" @click="setAppMode(APP_MODES.LEARNING.type)" :class="['app-mode__button-wrapper', { 'app-mode__button-wrapper--active': isLearningMode }]">
                                    <span class="app-mode__option">{{ $t('nav.learning') }}</span>
                                    <img v-if="isLearningMode" src="@/assets/img/check.svg" alt="Icon">
                                    <DotNotification v-else-if="!getLastLearningLesson && !loading" pulse />
                                </div>
                            </div>
                        </div>
                        <div class="child-wrapper" v-if="getChildren.length">
                            <div class="kids-count">
                                <span>{{ $t('nav.your-kids') }}:</span>
                                <span class="number">{{ getChildren.length }}</span>
                            </div>
                            <div
                                class="child-container"
                                v-for="child in getChildren"
                                @click="loginChild(child.uuid)"
                                :key="child.uuid"
                            >
                                <img class="account-avatar avatar-style" :src="setAvatar(child.avatarUrl)" width="32" height="32" alt="Icon">
                                <div class="child-container__label">{{ child.firstName }} {{ child.lastName }}</div>
                            </div>
                        </div>
                        <div class="g_id_signout" @click="logout">{{$i18n.t('nav.logout')}}</div>
                    </dropdown>
                </div>
                <div 
                    :class="[
                        'hamburger', 
                        { 'hamburger--notification': hamburgerNotification }
                    ]"
                >
                    <img 
                        @click="toggleNav" 
                        :src="showMobileNav ? require('@/assets/img/nav/hide-nav.svg') : require('@/assets/img/nav/hamburger.svg')" 
                        alt=""
                    >
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavTools from '@/components/layout/NavTools'
import Help from '@/components/layout/Help'
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'
import logo from '@/assets/img/logo-small.svg'
import logoSmartLearning from '@/assets/img/logo-smart-learning.svg'
import calendar from '@/assets/img/calendar-blue.svg'
import addUser from '@/assets/img/adduser.svg'
import arrow from '@/assets/img/arrow.svg'
import arrowBlue from '@/assets/img/arrow-blue.svg'
import Dropdown from '@/components/reusable/Dropdown'
import DotNotification from '@/components/reusable/DotNotification'
import dropdownMixin from '@/mixins/dropdown'
import setAvatarMixin from '@/mixins/setAvatar'
import changingProfiles from '@/mixins/changingProfiles'
import Scss from '@/assets/scss/includes/_variables.module.scss'
import defaultLogout from '@/functions/defaultLogout'
import { APP_MODES } from '@/router/appModes'
import { appModePath } from '@/functions/appModes'

export default {
    name: 'Nav',
    mixins: [dropdownMixin, setAvatarMixin, changingProfiles],
    components: {
        BookLessonBtn,
        Dropdown,
        Help,
        DotNotification
    },
    data() {
        return {
            showMobileNav: false,
            mobile: parseInt(Scss.mobile),
            logo,
            logoSmartLearning,
            calendar,
            addUser,
            arrow,
            arrowBlue,
            nestedRoutesStatus: {
                tools: false
            },
            APP_MODES,
            appModePath,
            showBlurLayer: false,
            loading: true
        }
    },
    async created() {
        this.$bus.$on('showUserMenu', () => {
            setTimeout(() => {
                this.toggleMenu()
                this.showBlurLayer = true
            }, 0)
        })

        await this.$store.dispatch('getNavData');

        this.loading = false
    },
    mounted() {
        this.popupItem = document.querySelector('.hamburger');
    },
    beforeDestroy() {
        this.$bus.$off('showUserMenu')
    },
    methods: {
        hideMenuHandler() {
            this.hideMenu()
            if(this.showBlurLayer) this.showBlurLayer = false
        },
        getRoute({to, params, path}) {
            if(path) return path

            return { name: to, params }
        },
        logout() {
            defaultLogout()
            this.$store.commit('setUser', null)
            this.$router.push({name: 'login'})
        },
        
        toggleNav() {
            this.showMobileNav = !this.showMobileNav
            setTimeout(() => {
                this.$store.state.blurBackground = this.showMobileNav
            }, 10);
            //double click-outside events problem
            this.showMenu = false
        },
        hideNav() {
            if(!this.showMobileNav) return
            this.showMobileNav = false
            this.$store.state.blurBackground = false
        },
        toggleNestedRoutes(type) {
            this.nestedRoutesStatus[type] = !this.nestedRoutesStatus[type]
        },
        hideNestedRoutes(type) {
            this.nestedRoutesStatus[type] = false
            this.showMobileNav = false
        },
        setAppMode(mode) {
            if(!sessionStorage.getItem('isEmployee')) localStorage.setItem('appMode', mode)

            this.$router.push({ name: APP_MODES[mode].defaultRoute })
            this.hideMenuHandler()
        }
    },
    computed: {
        ...mapState(['unreadMessagesCount', 'isMobile']),
        ...mapGetters(['isSpaceModeAvailable', 'isLearningModeAvailable', 'isSpaceMode', 'isLearningMode', 'getChildren', 'getLastLearningLesson']),
        navRoutes() {
            const dashboard = {
                text: this.$i18n.t('nav-route.dashboard'),
                to: 'dashboard'
            }
            const coursePlan = {
                text: this.$i18n.t('nav-route.course-plan'),
                to: 'coursePlan',
                params: { uuid : this.$store.state.coursePlan.chosenUuid }
            }
            const messages = {
                text: this.$i18n.t('nav-route.messages'),
                to: 'messages',
                count: true
            }
            const lessons = {
                text: this.$i18n.t('nav-route.lessons'),
                to: 'lessons',
            }
            const account = {
                text: this.$i18n.t('nav-route.account'),
                to: 'account',
                path: appModePath('account')
            }
            const tools = {
                text: this.$i18n.t('nav-route.tools'),
                component: NavTools,
                type: 'tools'
            }
            const learningCoursePlan = {
                text: this.$i18n.t('nav-route.learning-course-plan'),
                to: 'learningCoursePlan',
                params: { coursePlanUuid : this.$store.state.learningCoursePlan.chosenLevelUuid }
            }

            const spaceRoutes = [
                dashboard,
                coursePlan,
                messages,
                lessons,
                account,
                tools
            ]

            const learningRoutes = [
                learningCoursePlan,
                account,
                tools
            ]

            return this.isLearningMode ? learningRoutes : spaceRoutes
        },
        getHomeRoute() {
            const { SPACE, LEARNING } = APP_MODES

            return { name: this.isLearningMode ? LEARNING.defaultRoute : SPACE.defaultRoute, params: this.isLearningMode ? { coursePlanUuid: this.$store.state.learningCoursePlan.chosenLevelUuid } : { uuid: null } }
        },
        getAccountPath() {
            return appModePath('account')
        },
        hamburgerNotification() {
            return this.unreadMessagesCount && !this.showMobileNav && this.$route.name !== 'messages'
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        goToPlatformSetup() {
            return `${ process.env.VUE_APP_EDU_URL }${ this.$store.state.user.locale }/setup`
        },
        isBackBtnFromCoursePlan() {
            return this.$route.params.fromCoursePlan
        }
    },
    watch: {
        windowWidth(n) {
            if(n >= this.mobile) {
                this.hideNav()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .main-dropdown {
            padding: 0;
        }
    }

    .child-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-100;
        padding: 0 0 8px 0 !important;
    }

    .nav__dropdown {
        cursor: pointer;

        &.router-link-active {
            .nav__dropdown-inner {
                &.nav__dropdown-inner--active {
                    .arrow-active {
                        transform: rotate(-90deg);
                    }
                }

                .arrow {
                    display: none !important;
                }

                .arrow-active {
                    display: block;
                    transform: rotate(90deg);
                }
            }
        }

        .nav__dropdown-inner {
            display: flex;
            gap: 4px;
            justify-content: space-between;

            &.nav__dropdown-inner--active {
                .arrow {
                    transform: rotate(180deg);
                }
            }

            .arrow {
                transition: all $arrow-transition-time;
                transform: rotate(0);
            }
        }
    }
    @media(max-width: $mobile-tools) {
        .nav__left .nav__routes > a.nav__dropdown--tools {
            display: block;
        }

        .nav__left .nav__routes a.nav__dropdown--tools .arrow {
            display: block;
        }
        
    }

    .back-btn-wrapper {
        display: flex;
        min-width: 40px;
        min-height: 32px;
        align-items: center;

        .back-btn {
            width: 20px;
            height: 20px;
            transform: rotate(180deg);
        }
    }

    .kids-count {
        background: none;
        height: auto;
        padding: 12px 12px 8px 12px !important;
        display: flex;
        justify-content: space-between;
        @include font-small-12;
        color: $gray-600;
        cursor: default;

        span {
            background: none;
            height: auto;
            margin: 0;
        }

        .number {
            color: $gray-500;
        }
    }

    .account-basic, .test-connection {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 8px 12px;
    }

    .back-to-parent {
        display: flex;
        align-items: center;
        min-height: 40px;
        color: $primary;
        border-bottom: 1px solid $gray-100;
        padding: 12px !important;
    }

    .app-mode {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-100;
        padding: 12px 6px 8px 6px !important;
        border-top: 1px solid $gray-100;

        &__title {
            color: $gray-600;
            @include font-small-12;
            cursor: default;
            margin: 0 0 8px 4px !important;
            background: none;
            height: auto;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 !important;
            gap: 4px;
            cursor: default !important;
        }

        &__option {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: -0.14px;
            padding: 0 !important;
            background: none;
            min-height: 40px;
        }

        &__button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 12px !important;
            border-radius: 6px;
            max-height: 40px;
            border: 1px solid $gray-100;
            border-radius: 6px;

            &--active {
                background-color: $primary-light !important;
                color: $primary;
                pointer-events: none;
            }

            .dot-notification {
                margin-right: 4px;
            }
        }

        span {
            background: none;
            height: auto;
            margin: 0;
        }
    }

    .g_id_signout {
        display: flex;
        align-items: center;
        color: $danger-middle;
        min-height: 44px;
        padding: 0px 12px !important;
    }
</style>
