import Translator from '@/components/tools/translator/Translator'
import Grammar from '@/components/tools/grammar/Grammar'
import Dictionary from '@/components/tools/dictionary/Dictionary'
import AskAI from '@/components/tools/askAI/AskAI'

export default {
    data() {
        return {
            toolsMap: {
                translator: Translator,
                grammar: Grammar,
                dictionary: Dictionary,
                askAI: AskAI
            }
        }
    }
}