<template>
    <div class="introduction-slide">
        <img
            class="introduction-slide__image"
            :src="require(`@/assets/img/dashboard/step${ slide }.png`)"
        />
        <div class="introduction-slide__slider-wrapper">
            <span class="slider"></span>
        </div>
        <div class="introduction-slide__text-wrapper" :style="style">
            <h3 class="introduction-slide__title">{{ $i18n.t(`dashboard.introduction${ slide }.title`) }}</h3>
            <p class="introduction-slide__subtitle">{{ $i18n.t(`dashboard.introduction${ slide }.subtitle`) }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntroductionSlide',
    props: ['slide', 'activeSlide'],
    computed: {
        style() {
            const image = require(`@/assets/img/dashboard/abstract${ this.slide }.svg`)

            return {
                backgroundImage: `url(${ image })`
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.introduction-slide {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__image {
        width: 100%;
        max-height: 192px;
        object-fit: cover;
    }

    &__text-wrapper {
        background-repeat: no-repeat;
        background-position: bottom right;
        height: 160px;
        flex: 1;
        padding: 12px 16px 16px 16px;
        width: 100%;

        @media(min-width: 768px) {
            padding: 12px 26px 26px 26px;
        }
    }

    &__slider-wrapper {
        background-color: rgba(45, 54, 66, 0.5);
        position: relative;
        height: 6px;
        z-index: 1;

        .slider {
            width: 0%;
            height: 100%;
            background-color: $gray-100;
            position: absolute;
            transition: none;
        }
    }

    &__title {
        @include font-h3;
        color: $gray-900;
        margin-bottom: 8px;
        max-width: 312px;
    }

    &__subtitle {
        @include font-regular-text;
        color: $gray-600;
        max-width: 312px;
    }
}
</style>