<template>
    <div class="widget introduction">
        <div class="slide-wrapper">
            <div v-for="slide in slides" :key="slide" class="slide" :style="{ transform: `translateX(-${activeSlide * 100}%)`, transition: `transform ${ transitionTime }s ease-out` }">
                <IntroductionSlide :slide="slide" :activeSlide="activeSlide"/>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="swiper-button-prev" @click="changeSlides(false)"></button>
            <button class="swiper-button-next" @click="changeSlides(true)"></button>
        </div>
    </div>
</template>

<script>
import IntroductionSlide from './IntroductionSlide'

export default {
    name: 'Introduction',
    components: {
        IntroductionSlide
    },
    data() {
        return {
            slides: 5,
            activeSlide: 0,
            autoPlay: null,
            transitionTime: 0
        }
    },
    mounted() {
        this.startAutoPlay();
    },
    beforeDestroy() {
        this.stopAutoPlay();
    },
    methods: {
        startAutoPlay() {
            this.resetSlider();
            this.autoPlay = setInterval(() => {
                this.changeSlides(true);
            }, 6100);
        },
        stopAutoPlay() {
            clearInterval(this.autoPlay);
        },
        changeSlides(next) {
            const isLastSlide = this.activeSlide === this.slides - 1;
            const isFirstSlide = this.activeSlide === 0;
            this.stopAutoPlay();
            this.transitionTime = isLastSlide && next || isFirstSlide && !next ? 0 : .3;
            this.activeSlide = ((next ? this.activeSlide + 1 : this.activeSlide - 1) + this.slides) % this.slides;
            this.resetSlider();
            this.startAutoPlay();
        },
        resetSlider() {
            const sliders = Array.from(document.querySelectorAll('.slider'));

            sliders.forEach((slider) => {
                slider.style.transition = 'none';
                slider.style.width = '0%';

                setTimeout(() => {
                    slider.style.transition = 'all 6s linear';
                    slider.style.width = '100%';
                }, 100);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.slide-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

.slide {
    flex: 0 0 100%;
    transition: transform 0.3s ease-out;
}

.widget.introduction {
    position: relative;
    height: 352px;
    padding: 0 !important;
    overflow: hidden;

    &:hover {
        .swiper-button-prev, .swiper-button-next {
            display: flex;
        }
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;

    .swiper-button-prev, .swiper-button-next {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        opacity: 0.75;
        background: rgba(70, 80, 94, 0.60);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(4px);
        display: none;
        border: none;
        cursor: pointer;
    
        &:hover {
            opacity: 1;
        }
    
        &:after {
            content: '';
            width: 32px;
            height: 32px;
            font-family: none;
            background-repeat: no-repeat;
            background-image: url('@/assets/img/arrow-gray.svg');
            background-position: center;
        }
    }
    
    .swiper-button-prev:after {
        transform: rotate(180deg);
    }
    
    .swiper-button-next {
        margin-left: auto;
    }
}
</style>
