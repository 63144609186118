<template>
    <div :class="[
        'schedule-lector',
        { 
            'selected': selected,
            'schedule-lector--border': action
        }
    ]">
        <div :class="[
            'confirmation-book__row',
            { 
                'confirmation-book__row--calendar': !action,
                'confirmation-book__row--center': $store.state.schedule.responseState && !lectorToDisplay.headline
            }
        ]">
            <div class="confirmation-book__cell1"><img :src="lectorToDisplay.avatarUrl" class="schedule-lector__avatar" /></div>

            <div class="confirmation-book__cell2">
                <div class="schedule-lector__name">{{ lectorToDisplay.name }}</div>
                <div 
                    v-if="lectorToDisplay.headline"
                    :class="[
                        'schedule-lector__desc',
                        {'schedule-lector__desc--lines-limit': action !== 'book'}
                    ]"
                >{{ lectorToDisplay.headline }}</div>

                <Select
                    v-if="isCalendarPreviewMode && slotsExists"
                    class="change-timezone-select"
                    :data="{
                        options: timezones
                    }"
                    :value="activeTimezone"
                    @input="changeTimezone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Select from '@/components/reusable/Select'
import parseSlots from '@/functions/parseSlots';

export default {
    name: 'ScheduleLectorInfo',
    props: {
        hideLectorArrows: {},
        lector: {
            type: Object
        },
        selected: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: null
        }
    },
    components: {
        Select
    },
    data() {
        return {
            timezones: [],
            activeTimezone: null
        }
    },
    created() {
        if(this.isCalendarPreviewMode) this.handleTimezones();
    },
    computed: {
        ...mapState({
            lectorTimezone: state => state.schedule.selectedSlot.lector.timezone
        }),
        ...mapState('schedule', ['apiUrl', 'lectorsCalendars', 'slotsExists']),
        ...mapGetters('schedule', ['isCalendarPreviewMode']),
        lectorToDisplay() {
            if (this.lector) return this.lector;

            return this.$store.state.schedule.selectedSlot.lector;
        }
    },
    methods: {
        handleTimezones() {
            const timezones = this.$moment.tz.names()
            const warsawTimezone = 'Europe/Warsaw'
            const warsawTimezoneIndex = timezones.indexOf(warsawTimezone)
            
            timezones.splice(warsawTimezoneIndex, 1)
            timezones.unshift(warsawTimezone)

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            
            if(userTimezone !== warsawTimezone) {
                const userTimezoneIndex = timezones.indexOf(userTimezone)

                timezones.splice(userTimezoneIndex, 1)
                timezones.unshift(userTimezone)
            }

            this.timezones = timezones.map(el => ({ text: el, value: el }))
            this.changeTimezone(userTimezone)
        },
        changeTimezone(timezone) {
            this.activeTimezone = timezone
            const { slots } = this.lectorsCalendars[this.apiUrl]

            const slotsInNewTimezone = slots.map(slot => ({
                ...slot,
                startAt: this.dateToOtherTimezone(slot.startAt),
                stopAt: this.dateToOtherTimezone(slot.stopAt)
            }))

            this.$store.state.schedule.days = parseSlots(slotsInNewTimezone)
        },
        dateToOtherTimezone(date) {
            return this.$moment.tz(date, this.lectorTimezone).tz(this.activeTimezone).format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
</script>

<style lang="scss" scoped>
    .schedule-lector__avatar {
        width: 80px;
        height: 80px;
        min-width: 80px;
        border-radius: 50%;
        margin-right: 0;
    }

    .schedule-lector__name {
        @include font-h2;
    }

    .schedule-lector__desc {
        @include font-regular-text;
        line-height: 18px;
        color: $gray-600;

        &.schedule-lector__desc--lines-limit {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
                    line-clamp: 2; 
            -webkit-box-orient: vertical;

            @media(min-width: $tablet-switch-lector) {
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
            }
        }
    }

    .confirmation-book__row {
        text-align: left;
        align-items: center;
    }

    .confirmation-book__row--calendar {
        margin-bottom: 0;

        @media(min-width: $tablet-switch-lector) {
            width: 700px;
        }


        .confirmation-book__cell1 {
            flex-basis: initial;
        }

        .schedule-lector__desc {
            margin-bottom: 8px;
        }

        .schedule-lector__avatar {
            width: 64px;
            height: 64px;
            min-width: 64px;
            margin-right: 24px;
        }
    }

    .confirmation-book__row--center {
        justify-content: center;
    }

    .schedule-lector--border {
        border-bottom: 1px solid $gray-200;
    }

    .change-timezone-select {
        display: inline-block;
    }
</style>