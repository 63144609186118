<template>
    <div ref="schaduleCalendarWrapper" :class="[
        'schedule-calendar-wrapper',
        { 
            'schedule-calendar-wrapper--default': mode === 'default',
            'schedule-calendar-wrapper--empty-slots': mode === 'default' && !slotsExists,
            'schedule-calendar-wrapper--mini': mode === 'mini', 
            'schedule-calendar-wrapper--mini-mobile': mode === 'mini-mobile',
        }
    ]">
        <div v-if="getShowBuyToBook || isB2CGroupStudent" class="schedule-calendar-wrapper--blue-overlay">
            <a 
                :class="[
                    'btn button primary',
                    { 'mb-8px': isStudentAdult }
                ]"
                :href="getLinkForPayment"
                @click="setLocalStorageSavedScheduleOnClick"
            >{{ isStudentAdult ? $t('calendar.blue-overlay.buy-packages') : $t('course-plan.baner-contact')}}</a>

            <a
                v-if="isStudentAdult"
                class="btn secondary button"
                :href="startUrl"
                target="_blank"
            >{{ $i18n.t('course-plan.baner-contact') }}</a>
        </div>
        <div class="schedule-arrow schedule-arrow--calendar schedule-arrow--left">
            <a @click.stop="previous" :disabled="prevButtonDisabled" :class="{ 'disabled': prevButtonDisabled }" class="schedule-arrow__link">
                <img :src="arrowBlue" class="schedule-arrow__icon">
            </a>
        </div>

        <ScheduleCalendarSlide 
            :current-days="currentDays" 
            :transitionName="transitionName" 
            :dayLimits="dayLimits"
            :lector="lector"
            :mode="mode"
        />

        <div class="schedule-arrow schedule-arrow--calendar schedule-arrow--right">
            <a @click.stop="next" :disabled="nextButtonDisabled" :class="{ 'disabled': nextButtonDisabled }" class="schedule-arrow__link">
                <img :src="arrowBlue" class="schedule-arrow__icon">
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ScheduleCalendarSlide from './ScheduleCalendarSlide';
import arrowBlue from '@/assets/img/arrow-blue.svg';
import backToLectorCard from '@/mixins/schedule/backToLectorCard';
import getLinkToOffer from '@/mixins/getLinkToOffer';
import brandAppUrls from '@/mixins/brandAppUrls';
import isStudentAdult from '@/mixins/isStudentAdult';
import scrollToDate from '@/mixins/schedule/scrollToDate';
import checkDateFilter from '@/mixins/schedule/checkDateFilter';

export default {
    name: 'ScheduleCalendar',
    components: { ScheduleCalendarSlide },
    mixins: [backToLectorCard, getLinkToOffer, brandAppUrls, isStudentAdult, scrollToDate, checkDateFilter],
    data() {
        return {
            daysDisplayed: 7,
            page: 1,
            transitionName: '',
            limits: { previous: '', next: '' },
            arrowBlue
        }
    },
    props: {
        days: {
            type: Array,
            required: true
        },
        mode: {
            type: String
        },
        buttonsOff: {
            typeof: Boolean,
            default: false
        },
        lector: {
            type: Object
        }
    },
    created() {
        if (this.mode === 'default') {
            this.setDefaultButton();
            this.$store.state.schedule.queue.push('calendar');
        }

        if(['mini', 'mini-mobile'].includes(this.mode)) {
            if(this.isDateFilter) this.checkDateFilter()
            if(this.isAdvanceNoticeFilterActive) this.checkAdvanceNoticeFilter()
        }
    },
    mounted() {
        if (this.checkIfNextAndPrevArrowIsDisabled()) {
            this.$bus.$emit('calendarSlidesDays', {
                lector: this.lector,
                height: this.$refs.schaduleCalendarWrapper.querySelector('.schedule-calendar--body')?.getBoundingClientRect().height
            })
        }
        this.$nextTick(function() {
            this.setDaysNumberByWindowWidth();
        });
        window.addEventListener('resize', this.setDaysNumberByWindowWidth);

        setTimeout(() => {
            if(this.selectedSlot) this.scrollToDate(this.selectedSlot.startAt)
        }, 200);
    },
    computed: {
        ...mapState('schedule', ['lectorDetails', 'switchLector', 'slotsExists']),
        ...mapGetters([
            'getShowBuyToBook',
            'getHavePackage',
            'isB2CGroupStudent'
        ]),
        ...mapGetters('schedule', ['isDateFilter', 'isDateFilterScreen', 'isAdvanceNoticeFilterActive']),
        filteredDays() {
            return this.mode === 'mini' && this.isDateFilter ? this.days.filter(day => day.slots.length) : this.days
        },
        prevButtonDisabled() {
            return this.page === 1;
        },
        nextButtonDisabled() {
            return this.daysDisplayed * this.page >= this.filteredDays.length  || !this.getHavePackage;
        },
        currentDays() {
            if (this.daysDisplayed === 30) {
                return this.filteredDays;
            }

            return this.filteredDays.slice(this.daysDisplayed * (this.page - 1), this.daysDisplayed * this.page);
        },
        dayLimits() {
            const firstDate = this.currentDays[0]?.date;
            const lastDate = this.currentDays[this.currentDays.length - 1]?.date;
            return [firstDate, lastDate];
        }
    },
    methods: {
        setDefaultButton() {
            const { courses, switchLector } = this.$store.state.schedule;

            if(this.isDateFilterScreen) {
                this.$store.commit('schedule/setSchedulePrimaryButton', { btnPrimaryAction: 'setupDateFilter', btnPrimaryLabel: this.$i18n.t('calendar.filter') });
            } else {
                this.$store.commit('schedule/setSchedulePrimaryButton', { btnPrimaryAction: false, btnPrimaryLabel: false });
            }

            if (switchLector.activeFlow) {
                if (this.lectorDetails) {
                    this.$store.commit('schedule/setScheduleDefaultButton', {
                        btnDefaultAction: this.backToLectorCard, 
                        btnDefaultLabel: this.$i18n.t('calendar.back')
                    });
                    return;
                }

                if (this.isDateFilterScreen) {
                    this.$store.commit('schedule/setScheduleDefaultButton', {
                        btnDefaultAction: 'resetDateFilter', 
                        btnDefaultLabel: this.$i18n.t('calendar.clear')
                    });
                    return;
                }

                this.$store.commit('schedule/setScheduleDefaultButton', {
                    btnDefaultAction: 'backToSwitchLectorList', 
                    btnDefaultLabel: this.$i18n.t('switch-lector.calendar.back-to-lector-list')
                });
            } else if (!courses || this.$store.getters['schedule/isGoDirectlyToCalendarView']) {
                this.$store.commit('schedule/setScheduleDefaultButton', {
                    btnDefaultAction: 'hideScheduleModal', 
                    btnDefaultLabel: this.$i18n.t('calendar.courses.cancel')
                });
            } else {
                this.$store.commit('schedule/setScheduleDefaultButton', {
                    btnDefaultAction: 'backToCourses', 
                    btnDefaultLabel: this.$i18n.t('calendar.back-to-select-course')
                });
            }
        },
        checkIfNextAndPrevArrowIsDisabled() {
            return this.$refs.schaduleCalendarWrapper.querySelectorAll('.schedule-arrow__link.disabled').length === 2
        },
        setDaysNumberByWindowWidth() {
            if (this.mode === 'mini') {
                if (window.innerWidth < 768) this.daysDisplayed = 4;
                if (window.innerWidth >= 768 && window.innerWidth < 992) this.daysDisplayed = 5;
                if (window.innerWidth >= 992) this.daysDisplayed = 4;
            } else if (this.mode === 'mini-mobile') {
                this.daysDisplayed = window.innerWidth > 992 ? 4 : 30;
            } else this.daysDisplayed = window.innerWidth > 992 ? 7 : 30;
        },
        next() {
            if(this.nextButtonDisabled) return;

            this.transitionName = 'slide-left';
            this.page++;
        },
        previous() {
            if(this.prevButtonDisabled) return;

            this.transitionName = 'slide-right';
            this.page--;
        }
    },
    watch: {
        selectedSlot(n, o) {
            if (!n && o) this.scrollToDate(n);
        }
    }
}
</script>