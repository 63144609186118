<template>
    <notifications :position="isMobile ? 'bottom center' : 'bottom right'" width="424" :duration="10000">
        <template slot="body" slot-scope="props">
            <div class="vue-notification-template vue-notification custom" :class="props.item.data.type">
                <div class="icon">
                    <img 
                        :class="{'avatar-style': isMsgNotify(props.item.data.type)}" 
                        width="24" 
                        height="24" 
                        :src="isMsgNotify(props.item.data.type) ? setAvatar(props.item.data.avatarUrl) : require(`@/assets/img/notify/${props.item.data.type}.svg`)" 
                        alt=""
                    >
                </div>
                <div class="main">
                    <div class="notification-title">{{props.item.title}}</div>
                    <div class="notification-content" v-html="props.item.text"></div>
                    <div v-if="props.item.data.buttons" class="notifcation-buttons">
                        <button v-for="button in props.item.data.buttons" :key="button.label" @click="$router.push(button.push); props.close()">{{button.label}}</button>
                    </div>
                    <div v-if="props.item.data.links" class="notifcation-buttons">
                        <a v-for="link in props.item.data.links" :key="link.label" :href="link.href" target="_blank" @click="props.close()">{{link.label}}</a>
                    </div>
                </div>
                <div class="close">
                    <img @click="props.close()" src="@/assets/img/notify/close.svg" alt="">
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
import { mapState } from 'vuex'
import setAvatarMixin from '@/mixins/setAvatar'

export default {
    name: 'MainNotifications',
    mixins: [setAvatarMixin],
    methods: {
        isMsgNotify(type) {
            return type === 'msg'
        }
    },
    computed: {
        ...mapState(['isMobile']),
    }
}
</script>

<style lang="scss" scoped>
//notifications
.vue-notification-group {
    z-index: 999999 !important;
}

.vue-notification-wrapper {
    .vue-notification.custom {
        max-width: 100%;
        margin: 0 32px 16px;
        padding: 14px;
        font-size: 16px;
        border-radius: 6px;
        border: 2px solid $white;
        background: $white;
        display: flex;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
        color: $gray-900;

        .icon {
            margin-right: 8px;
        }
        
        .main {
            flex: 1;
            
            .notification-title {
                @include font-h4;
                padding-right: 20px;
            }
        
            .notification-content {
                @include font-regular-text;
            }
        
            .notifcation-buttons {
                margin-top: 8px;
                @include font-medium;
        
                button, a {
                    @include font-medium;
                    background: $white;
                    border: 0;
                    color: $primary-dark;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }

        .close {
            img {
                cursor: pointer;
            }
        }

        &.success {
            border-color: $secondary;
        }

        &.warning {
            border-color: $warning;
        }

        &.error {
            border-color: $danger;
        }

        &.info {
            border-color: $primary;
        }
    }

    &:last-child {
        .vue-notification.custom {
            margin: 0 32px 32px;
        } 
    }
}
</style>
