import gql from 'graphql-tag'

const dictionaryData = gql`
    {
        synonyms
        antonyms
        entries {
            definition
            examples
            synonyms
            antonyms
        }
    }
`

export const getDictionaryWord = gql`
    query($word: String!, $locale: WordingLocaleEnum!, $source: WordingProviderEnum, $limit: Int, $offset: Int) {
        getWords(word: $word, locale: $locale, source: $source, limit: $limit, offset: $offset) {
            status
            errors {
                message
                code
            }
            warnings
            fromCache
            words {
                word
                source
                pronunciation {
                    url
                    text
                }
                sections {
                    noun
                        ${ dictionaryData }
                    verb
                        ${ dictionaryData }
                    adjective
                        ${ dictionaryData }
                    numeral
                        ${ dictionaryData }
                    pronoun
                        ${ dictionaryData }
                    adverb
                        ${ dictionaryData }
                    preposition
                        ${ dictionaryData }
                    participle
                        ${ dictionaryData }
                    conjunction
                        ${ dictionaryData }
                    exclamation
                        ${ dictionaryData }
                    interjection
                        ${ dictionaryData }
                    properNoun
                        ${ dictionaryData }
                    combiningForm
                        ${ dictionaryData }
                    contraction
                        ${ dictionaryData }
                    determiner
                        ${ dictionaryData }
                    idiomatic
                        ${ dictionaryData }
                    other
                        ${ dictionaryData }
                    particle
                        ${ dictionaryData }
                    predeterminer
                        ${ dictionaryData }
                    prefix
                        ${ dictionaryData }
                    residual
                        ${ dictionaryData }
                    suffix
                        ${ dictionaryData }
                    article
                        ${ dictionaryData }
                    ideophone
                        ${ dictionaryData }
                }
            }
        }
    }
`