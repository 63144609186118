<template>
    <div class="flashcards__exercise" :key="1">
        <div class="question-wrapper">
            <div class="text-container">
                <div class="question">
                    <div class="question__header">
                        <p class="question__title">{{ getQuestion.title }}</p>
                    </div>
                    <div v-if="isAudioExercise" class="audio-exercise-wrapper">
                        <span class="audio-recording">{{ $t('flashcards.audio-recording') }}</span>
                        <FlashcardsAudio />
                    </div>
                    <div class="word-wrapper" v-else>
                        <p class="word">{{ getQuestion.word }}</p>
                        <FlashcardsAudio v-if="getQuestion.audio" small />
                    </div>
                </div>
                <div v-if="showAnswerWord" class="answer">
                    <div class="answer__header">
                        <p class="answer__title">{{ getAnswer.title }}</p>
                    </div>
                    <div class="answer__wrapper">
                        <p :class="['answer__word', { [`answer__word--${ getAnswerStatus }`]: getAnswerStatus }]">{{ getAnswer.word }}</p>
                        <FlashcardsAudio v-if="getAnswer.audio && showAnswerWord" small />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FlashcardsAudio from '@/components/flashcards/FlashcardsAudio'

import langsMap from '@/helpers/langsMap';

export default {
    name: 'FlashcardsExercise',
    components: {
        FlashcardsAudio
    },
    computed: {
        ...mapState('flashcards', ['submitted', 'dontKnow']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters(['isLearningMode']),
        ...mapGetters('flashcards', ['getCurrentExercise', 'isDefinitionExercise', 'isAudioExercise', 'isEnglishBased', 'getAnswerStatus']),
        showAnswerWord() {
            return this.submitted || this.dontKnow || this.isDefinitionExercise
        },
        englishData() {
            return {
                title: this.$t('flashcards.exercise.english', this.transUi),
                word: this.getCurrentExercise.english,
                audio: !this.isAudioExercise
            }
        },
        userLanguageData() {
            return {
                title: this.isLearningMode
                    ? this.$t(langsMap[this.$route.params.locale]?.transKey, this.transUi)
                    : this.$t(`general.language.${ this.$route.params.locale }`),
                word: this.getCurrentExercise.translation
            }
        },
        getQuestion() {
            if(this.isEnglishBased) return this.englishData
            else return this.userLanguageData
        },
        getAnswer() {
            if(this.isEnglishBased) return this.userLanguageData
            else return this.englishData
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__exercise {
    width: 100%;
    border-radius: 16px;
    border: 1px solid $gray-300;
    overflow: hidden;
    min-width: calc(100% - 32px);
    max-width: calc(100% - 32px);

    @media(min-width: $mobile-flashcards) {
        min-width: 343px;
        max-width: 488px;
    }

    ::v-deep {
        > div > .flashcards-slide-right-leave-active,
        > div > .flashcards-slide-left-leave-active {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.text-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.question-wrapper {
    display: flex;
    flex-direction: column;
}

.audio-recording {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.4px;
}

.audio-exercise-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question {
    &__header {
        display: flex;
        margin-bottom: 4px;
    }

    &__title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $gray-600;
        text-transform: uppercase;
    }

    .word-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .word {
            font-size: 32px;
            font-weight: 600;
            line-height: 36px;
            color: $black;
        }
    }
}

.answer {
    margin-top: 16px;

    &__header {
        display: flex;
        margin-bottom: 4px;
    }

    &__title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $gray-600;
        text-transform: uppercase;
    }
    
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }

    &__word {
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        color: $primary;
        transition: all .25s;

        &--correct {
            color: $secondary-dark;
        }

        &--incorrect {
            color: $danger-middle;
        }

        &--pending {
            color: $warning-dark;
        }

        &--half-correct, &--HALF_CORRECT {
            color: $orange;
        }
    }
}
</style>