import gql from 'graphql-tag'

export const messageGroupByUuid = gql`
    query($messageGroupUuid: ID!) {
        messageGroupByUuid(input: {messageGroupUuid: $messageGroupUuid}) {
            uuid
            name
            group {
                uuid
                name
                type
                isTest
                isActive
            }
            receivers {
                uuid
                firstName
                avatarUrl
                role
            }
            unreadMessagesCount
            lastMessage {
                createdAt
            }
            messageFiles {
                uuid
                name
                path
                size
            }
            messageImages {
                uuid
                name
                path
                size
                createdAt
            }
            messageLinks {
                uuid
                name
                path
            }
        }
    }
`