import store from '@/store'

function appModePath(path) {
    const { getCurrentAppMode } = store.getters
    const { locale } = store.state.currentRoute.params

    return `/${ locale }${ getCurrentAppMode.urlPrefix ? `/${ getCurrentAppMode.urlPrefix }` : '' }/${ path }`
}

export {
    appModePath
}