import getLessonSurveyData from '@/functions/getLessonSurveyData'

export default {
    data() {
        return {
            surveyLoading: false
        }
    },
    computed: {
        canRate() {
            return this.lesson.feedbackStatus === 'FEEDBACK_STATUS_TO_RATE'
        },
        rated() {
            return this.lesson.feedbackStatus === 'FEEDBACK_STATUS_RATED'
        }
    },
    methods: {
        async setLessonSurvey() {
            this.surveyLoading = true

            const { data } = await getLessonSurveyData(this.lesson.uuid)

            if(data) this.$bus.$emit('lessonSurvey', data)
            else {
                this.$bus.$emit('updateDashboard')
                this.$bus.$emit('updateLesson')
            }

            this.surveyLoading = false

            this.$gtag({
                category: 'btnClick',
                label: 'Rate lesson'
            })
        }
    }
}