import ClickOutside from 'vue-click-outside'

export default {
    data() {
        return {
            showMenu: false
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        hideMenu() {
            this.showMenu = false
        },
        toggleMenu() {
            this.showMenu = !this.showMenu
        }
    }
}