var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'accordion',
        {
            'accordion--active' : _vm.active,
            'accordion--inactive' : !_vm.rotateArrows
        }
    ]},[_c('div',{class:['accordion__header', { 'accordion__header--disabled' : _vm.disabled }],on:{"click":_vm.clickHeader}},[_vm._t("header"),(_vm.rotateArrows)?_c('span',{staticClass:"accordion__arrow",class:{ 'accordion__arrow--active': _vm.isExpanded && !_vm.disabled },attrs:{"alt":"Icon"}}):_vm._e()],2),(!_vm.disabled)?_c('div',{ref:"contentHeight",class:['accordion__content', { 'accordion__content--disabled' : _vm.disabled }],style:(_vm.contentStyle)},[_vm._t("content")],2):_vm._e(),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }