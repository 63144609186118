<template>
    <Modal v-if="init" @hide="handleHideAction" class="schedule">
        <div class="modal-inner modal-inner--courses">
            <ModalHeader :close="!isMobile" :title="modalTitle || $i18n.t('modal.book-lesson.header')" @hide="handleHideAction"/>

            <fade-transition mode="out-in">
                <template v-if="isCoursesView">
                    <ScheduleCourses />
                </template>

                <template v-else>
                    <ScheduleRoot />
                </template>
            </fade-transition>
            
            <div 
                :class="[
                    'footer',
                    { 'footer--lector-card': lectorCardFooterCondition },
                    { 'footer--hidden': isLectorDetailsDirectOpened && getSwitchLectorActiveScreen === SCHEDULE_SCREEN.LECTOR_DETAILS }
                ]"
            >
                <Button
                    v-if="$store.state.schedule.btnDefaultAction" 
                    class="default cancel"
                    @click.native="actionBtnClicked($store.state.schedule.btnDefaultAction)"
                    :text="isMobile ? $i18n.t('schedule-modal.mobile.return.btn') : $store.state.schedule.btnDefaultLabel"
                    :disabled="btnDefaultDisabled || (!isDateFilter && isDateFilterScreen)"
                />
                
                <Button 
                    v-if="$store.state.schedule.btnPrimaryAction" 
                    class="primary save" 
                    @click.native="actionBtnClicked($store.state.schedule.btnPrimaryAction)" 
                    :text="$store.state.schedule.btnPrimaryLabel"
                    :disabled="btnPrimaryDisabled" 
                />

                <template v-if="getShowBuyToBook && lectorCardFooterCondition">
                    <a v-if="isStudentAdult"
                        class="btn button primary"
                        :href="getLinkForPayment"
                        @click="setLocalStorageSavedScheduleOnClick"
                    >{{ $t('calendar.blue-overlay.buy-packages') }}</a>
                    
                    <a v-else
                        class="btn secondary button"
                        :href="startUrl"
                        target="_blank"
                    >{{ $i18n.t('course-plan.baner-contact') }}</a>
                </template>
            </div>
        </div>
    </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ScheduleRoot from '@/components/schedule/ScheduleRoot';
import ScheduleCourses from '@/components/schedule/ScheduleCourses';
import Button from '@/components/reusable/Button';
import FadeTransition from '@/components/reusable/FadeTransition';
import modalMixin from '@/mixins/modal';
import setAvatarMixin from '@/mixins/setAvatar';
import scheduleActionsHandle from '@/mixins/schedule/scheduleActionsHandle';
import getLinkToOffer from '@/mixins/getLinkToOffer';
import brandAppUrls from '@/mixins/brandAppUrls';
import isStudentAdult from '@/mixins/isStudentAdult';
import { SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    name: 'Schedule',
    components: { 
        ScheduleRoot,
        ScheduleCourses,
        Button,
        FadeTransition
    },
    mixins: [setAvatarMixin, modalMixin, scheduleActionsHandle, getLinkToOffer, brandAppUrls, isStudentAdult],
    data() {
        return {
            SCHEDULE_SCREEN
        }
    },
    async created() {
        document.documentElement.classList.add('overflow-y-hidden');
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('schedule', [
            'init',
            'modalTitle',
            'switchLector', 
            'btnPrimaryDisabled', 
            'btnDefaultDisabled',
        ]),
        ...mapGetters(['getShowBuyToBook']),
        ...mapGetters('schedule', [
            'scheduleModal', 
            'isDateFilterScreen', 
            'isDateFilter',
            'getSwitchLectorActiveScreen',
            'isLectorDetailsDirectOpened'
        ]),
        isCoursesView() {
            return this.$store.state.schedule.coursesView;
        },
        lectorCardFooterCondition() {
            return this.isMobile && this.switchLector.activeScreen === SCHEDULE_SCREEN.LECTOR_DETAILS
        }
    },
    methods: {
        actionBtnClicked(action) {
            if (this[action]) return this[action]();
            action();
        },
        handleHideAction(data) {
            if(data?.force) return this.hideScheduleModal()

            if(this.isLectorDetailsDirectOpened && this.getSwitchLectorActiveScreen === SCHEDULE_SCREEN.LECTOR_DETAILS) return this.hideScheduleModal();

            const { LECTOR_DETAILS, FILTERS_DATE } = SCHEDULE_SCREEN

            if([LECTOR_DETAILS, FILTERS_DATE].includes(this.switchLector.activeScreen)) this.goToSwitchLectorList()
            else this.hideScheduleModal()
        }
    }
}
</script>

<style lang="scss" scoped>
.schedule.modal {
    .footer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .footer--lector-card {
        padding: 0 21px 21px;
        border-top: 0;
    }

    .footer--hidden {
        display: none !important;
    }

    .btn {
        flex: 1 1 32%;
        white-space: nowrap;
        min-width: fit-content;

        @media(min-width: 992px) {
            flex: initial;
        }
    }
}
</style>