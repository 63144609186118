<template>
    <div class="translator">
        <TranslatorHeader />
        <TranslatorFormalityMobile v-if="isFormalitySupported" />
        <TranslatorInputs />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TranslatorHeader from './TranslatorHeader'
import TranslatorInputs from './TranslatorInputs'
import TranslatorFormalityMobile from './TranslatorFormalityMobile'

export default {
    name: 'Translator',
    components: {
        TranslatorHeader,
        TranslatorInputs,
        TranslatorFormalityMobile
    },
    mounted() {
        const locale = this.$route.params.locale

        if(!this.translator.source_lang) {
            this.$store.commit('tools/setTranslatorTargetLang', locale)
            this.$store.commit('tools/setTranslatorSourceLang', locale === 'en' ? 'pl' : 'en')
        }
    },
    computed: {
        ...mapState('tools', ['translator', 'contextUrl']),
        ...mapGetters('tools', ['isFormalitySupported'])
    }
}
</script>

<style lang="scss">
    @mixin mobile-translator-view {
        .translator__header {
            padding: 16px;
        }

        .translator__target {
            justify-content: flex-end;
            position: relative;

            .translator__switch-langs {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .translator__formality {
            display: none;
        }

        .translator__formality-mobile {
            display: block;
        }

        .tools-pill--target {
            left: unset;
        }

        .translator__inputs {
            flex-direction: column;
        }

        .translator__input-wrapper {
            border-radius: 0;
            overflow: visible;
            padding-bottom: 40px;

            &--left-border, &--left-border-danger {
                border-color: $gray-200;
            }

            &--source {
                border-right-width: 1.5px;
                
                .translator__langs {
                    height: 200%;
                }
            }

            &--target {
                &.translator__input-wrapper--active {
                    border-color: $primary;
                }

                .translator__langs {
                    top: -100%;
                    height: 200%;
                }
            }
        }

        .translator__skeleton {
            left: 16px;
            top: 25px;
            width: calc(100% - 16px);
        }

        .translator__input {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            padding: 16px 30px 0 16px;
        }
    }

    .wrapper--tools .translator,
    .tools-wrapper .translator {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .wrapper--tools .translator {
        @container tools (max-width: 930px) {
            @include mobile-translator-view;
        }
    }

    .tools-wrapper .translator {
        @container tools (max-width: 560px) {
            @include mobile-translator-view;
        }
    }
</style>