<template>
    <button 
        :disabled="loading || disabled" 
        :class="{'loading': loading, 'disabled' : disabled}" 
        class="btn"
    >
        <img 
            v-if="icon" 
            class="icon" 
            :src="icon" 
            :width="iconSize" 
            :height="iconSize" 
            alt="Icon"
        >
        <span v-if="text" class="button-text" v-html="text"></span>
        <img 
            v-if="showClose" 
            @click.stop="$emit('close')" 
            src="@/assets/img/close-course.svg"
            alt=""
            width="14"
            height="14"
        >
    </button>
</template>

<script>

export default {
    name: 'Button',
    props: ['text', 'icon', 'loading', 'disabled', 'iconSize', 'showClose']
}
</script>
