import gql from 'graphql-tag'

export const coursePlan = gql`
query ($courseProgramUuid: ID) {
	coursePlan(uuid: $courseProgramUuid) {
		uuid
		language {
			name
			shortName
		}
		languageLevel {
			name
			shortName
		}
		learningTime
		modules {
			title
			materials {
				title
				description
				materialUuid
				percentage
				state
			}
		}
	}
}
`