var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNav),expression:"showNav"}],staticClass:"bg-white nav-bar"},[_c('div',{staticClass:"wrapper wrapper--big"},[_c('Nav')],1)]),_c('div',{class:[
        'wrapper', 
        'wrapper--small',
        'content-box',
        { 'wrapper--tools': this.$route.name === 'tools' },
        { 'wrapper--tools-askAI': _vm.mobileAskAITool },
        { 'blur': _vm.blurBackground }
    ]},[_c('router-view')],1),_c('div',[_c('div',{staticClass:"wrapper wrapper--big"},[(_vm.showFooter)?_c('PrivacyFooter'):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }