<template>
    <div id="googleLoginBtn" :class="['google-login-btn', { 'icon-only': type === 'icon' }]"></div> 
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['type', 'action', 'abortGoogleTapFrame'],
    data() {
        return {
            interval: 0
        };
    },
    mounted() {
        this.interval = setInterval(() => {
            if (!window.google?.accounts) return;

            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                callback: this.action
            });

            window.space = window.space || {};
            window.space.googleSignInInited = true;

            this.runGoogleSignIn();
            clearInterval(this.interval);
        }, 300);
    },
    computed: {
        ...mapState(['isIOSApp', 'isAndroidApp', 'windowWidth'])
    },
    methods: {
        runGoogleSignIn() {
            window.google.accounts.id.renderButton( document.getElementById('googleLoginBtn'), { 
                type: this.type ? 'icon' : 'large', 
                theme: 'outline',
                size: this.type ? 'medium' : 'large',
                logo_alignment: 'center'
            });

            setTimeout(() => {
                if (!this.abortGoogleTapFrame && !this.isIOSApp && !this.isAndroidApp && this.windowWidth > 768) {
                    window.google.accounts.id.prompt();
                }
            }, 500);
        }
    }
};
</script>

<style scoped lang="scss">
.google-login-btn {
    &.icon-only {
        height: 32px;
    }
}
</style>
