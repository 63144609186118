import store from '../store'

const timezoneDate = format => {
    const mapDateFormats = {
        'MM/DD/YYYY': 'm',
        'DD/MM/YYYY': 'd',
        'YYYY/MM/DD': 'y'
    }

    const mapHourFormats = {
        '24_h': 'HH:mm',
        '12_h': 'h:mm A'
    }
    
    const hour = mapHourFormats[store.getters.hourFormat]
    const date = mapDateFormats[store.getters.dateFormat]

    switch(format) {
        case 'HH:mm' : return hour
        case 'D MMMM (ddd)' : 
            switch(date) {
                case 'd' : return format
                case 'm' : return 'MMMM D (ddd)'
                case 'y' : return 'MMMM D (ddd)'
            }
            break
        case 'D MMMM' : 
            switch(date) {
                case 'd' : return format
                case 'm' : return 'MMMM D'
                case 'y' : return 'MMMM D'
            }
            break
        case 'D MMM' :
            switch(date) {
                case 'd' : return format
                case 'm' : return 'MMM D'
                case 'y' : return 'MMM D'
            }
            break
        case 'D MMM HH:mm' :
            switch(date) {
                case 'd' : return `D MMM ${hour}`
                case 'm' : return `MMM D ${hour}`
                case 'y' : return `MMM D ${hour}`
            }
            break
        case 'D MMM YYYY HH:mm' : 
            switch(date) {
                case 'd' : return `D MMM YYYY ${hour}`
                case 'm' : return `MMM D YYYY ${hour}`
                case 'y' : return `YYYY MMM D ${hour}`
            }
            break
        case 'D MMMM YYYY' :
            switch(date) {
                case 'd' : return format
                case 'm' : return 'MMMM D YYYY'
                case 'y' : return 'YYYY MMMM D'
            }
            break
        case 'D MMMM - HH:mm' :
            switch(date) {
                case 'd' : return `D MMMM - ${hour}`
                case 'm' : return `MMMM D - ${hour}`
                case 'y' : return `MMMM D - ${hour}`
            }
            break
        case 'dddd, D MMMM' :
            switch(date) {
                case 'd' : return format
                case 'm' : return `MMMM D, dddd`
                case 'y' : return `MMMM D, dddd`
            }
            break
        case 'D MMMM, YYYY' :
            switch(date) {
                case 'd' : return format
                case 'm' : return `MMMM D, YYYY`
                case 'y' : return `YYYY, MMMM D`
            }
            break
        case 'D.MM.YYYY - HH:mm' :
            switch(date) {
                case 'd' : return `D.MM.YYYY - ${hour}`
                case 'm' : return `MM.D.YYYY - ${hour}`
                case 'y' : return `YYYY.MM.D - ${hour}`
            }
            break
        default : return format
    }
}

export default timezoneDate