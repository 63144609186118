export default {
    methods: {
        scrollToMaterialByUrlQueryParam() {
            if (this.$route.query.materialId) {
                const el = document.querySelector(`[data-material-id="${parseInt(this.$route.query.materialId) + 1}"]`);
                if (!el) return;
                setTimeout(() => {
                    window.scrollTo({ 
                        top: el.offsetTop - 630,
                        behavior: 'smooth' 
                    });
                }, 500);
            }
        }
    }
}