<template>
    <div :class="['summary-item', { 'success': [MATERIAL_STATES.FINISHED, FLASHCARDS_STATES.COMPLETED].includes(state) }]">
        <div class="summary-item__box1 flex-1">
            <p class="summary-item__title">{{ title }} <span v-if="titleBadge" :class="['summary-item__title-badge', `summary-item__title-badge--${ titleBadge.class }`]">{{ titleBadge.text }}</span></p>
            <p class="summary-item__subtitle">{{ subtitle }}</p>
        </div>
        <div :class="['btn-wrapper', `btn-wrapper--${ type }`]">
            <div v-for="btn in btns" :key="btn.type" class="summary-item__btn">
                <component 
                    :is="isInternalLink(btn.link) ? 'router-link' : 'a'"
                    :[linkAttribute(btn.link)]="btn.link"
                    :class="[
                        'btn default',
                        { 'warning': btn.state === MATERIAL_STATES.PENDING },
                        { 'success': btn.state === MATERIAL_STATES.FINISHED },
                        { 'success disabled': btn.state === FLASHCARDS_STATES.COMPLETED },
                        { 'primary': btn.state === FLASHCARDS_STATES.IN_PROGRESS }
                    ]">
                        <img 
                            v-if="[MATERIAL_STATES.PENDING, MATERIAL_STATES.FINISHED, FLASHCARDS_STATES.COMPLETED].includes(btn.state)" 
                            :src="btn.state === MATERIAL_STATES.PENDING ? MarkText : CheckGreenDark"
                        />
                        {{ buttonText(btn) }}
                </component>
            </div>

            <template v-if="isPlatformMaterial">
                <div class="info-tooltip">
                    <Button
                        class="button btn default"
                        @click.native="handlePrint()"
                        :icon="printerIcon"
                        :loading="loading"
                    />
                    <Tooltip :text="$t('single-lesson.print.btn')"/>
                </div>
                <div ref="qrcode" class="qr-wrapper">
                    <qr-code class="qrcode" :text="getQrLink"></qr-code>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button';
import Tooltip from '@/components/reusable/Tooltip';
import generateLessonPdf from '@/mixins/generatePdf/generateLessonPdf';

import { MATERIAL_STATES, PDF_TYPE } from '@/constants/material';
import { FLASHCARDS_STATES } from '@/constants/flashcards';

import printerIcon from '@/assets/img/printer.svg';
import CheckGreenDark from '@/assets/img/singleLesson/check-green-dark.svg';
import MarkText from '@/assets/img/singleLesson/mark-text.svg';

export default {
    name: 'SummaryItem',
    props: ['title', 'subtitle', 'text', 'btns', 'type', 'state', 'materialUuid', 'data'],
    components: { Button, Tooltip },
    mixins: [generateLessonPdf],
    data() {
        return {
            printerIcon,
            CheckGreenDark, 
            MarkText,
            MATERIAL_STATES,
            FLASHCARDS_STATES
        }
    },
    computed: {
        isPlatformMaterial() {
            return this.materialUuid
        },
         titleBadge() {
            if(this.type === PDF_TYPE.FLASHCARDS) {
                if(this.state === FLASHCARDS_STATES.NEW) return {
                    text: this.$t('single-lesson.flashcards.badge.new'),
                    class: 'blue'
                }

                if(this.state === FLASHCARDS_STATES.IN_PROGRESS) return {
                    text: `${ this.$t('single-lesson.flashcards.badge.words-left') } ${ this.data.flashcardsWordsLeft }`,
                    class: 'simple'
                }
            }

            return null
        }
    },
    methods: {
        linkAttribute(link) {
            return this.isInternalLink(link) ? 'to' : 'href';
        },
        async handlePrint() {
            const { type, materialUuid, text } = this;
            
            const qr = this.$refs.qrcode.querySelector(`.btn-wrapper--${ type } .qrcode`)
                .childNodes[1]
                .outerHTML
                .replace('style="display: block !important;"', `style="${ this.getSizes } position: absolute; bottom: 19px; left: 522px;"`);

            await this.generateLessonPdf(type, text, this.$route.params.uuid, materialUuid, this.subtitle, this.title, qr);
        },
        isInternalLink(link) {
            return typeof link === 'object';
        },
        buttonText(btn) {
            const { type, state } = btn;

            return this.$i18n.t(`course-plan.accordion.${ type }.link-name.${ state.toLowerCase() }`);
        }
    }
}
</script>

<style lang="scss" scoped>
.summary-item {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid $gray-200;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &.success {
        background-image: url('@/assets/img/singleLesson/confetti-2.svg');
        background-repeat: no-repeat;
        background-position: right -120px top;
        background-size: 250px;

        @media(min-width: 400px) {
            background-position: right -90px top;
        }

        @media(min-width: 525px) {
            background-size: initial;
            background-repeat: repeat-y;
            background-position: right;
        }
    }
}

.summary-item__box1 {
    width: 100%;
}

.summary-item__title {
    @include font-medium;
    color: $gray-900;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    &-badge {
        &--blue {
            padding: 0 4px;
            @include font-small-10;
            color: $primary;
            background: $primary-light;
            border-radius: 4px;
        }

        &--simple {
            @include font-small-12;
            text-transform: uppercase;
            color: $gray-600;
        }
    }
}

.summary-item__subtitle {
    @include font-regular;
    line-height: 20px;
    color: $gray-600;
}

.btn-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    gap: 8px;
    margin-left: auto;

    &--flashcards {
        @media(max-width: $mobile) {
            width: 100%;
            flex-direction: column;
        }
    }

    .qr-wrapper {
        display: none;
    }

    .info-tooltip {
        position: relative;

        &:hover {
            .tooltip {
                display: block;
            }
        }

        .button {
            height: 100%;
            padding: 10px;
        }
    }
}

.summary-item__btn {
    flex: 1;
}

@media(min-width: 525px) {
    .summary-item {
        padding: 23px;
        flex-direction: row;
    }

    .btn-wrapper {
        width: initial;
        flex-direction: row;
    }

    .summary-item__btn {
        flex: initial;
    }
}

.frame {
    position: fixed;
    z-index: 0;
}
</style>