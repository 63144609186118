import Input from '@/components/reusable/Input'
import Select from '@/components/reusable/Select'
import Check from '@/components/reusable/Check'
import store from '../store'
import { i18n } from '@/i18n/i18n'
const moment = require('moment')

export const login = [
    {
        name: 'username',
        rules: 'required:email|email',
        component: Input,
        data: {
            label: i18n.t('login.form.email'),
            type: 'email'
        }
    },
    {
        name: 'password',
        rules: `required:password|min:${store.state.passwordLength}`,
        component: Input,
        data: {
            label: i18n.t('login.form.password'),
            type: 'password'
        }
    }
]

export const setAccount = [
    [
        {
            name: 'firstName',
            component: Input,
            data: {
                label: i18n.t('set-account.form.firstName')
            }
        },
        {
            name: 'lastName',
            rules: 'required',
            component: Input,
            data: {
                label: i18n.t('set-account.form.lastName')
            }
        }
    ],
    [
        {
            name: 'gender',
            component: Select,
            data: {
                label: i18n.t('set-account.form.gender'),
                placeholder: i18n.t('set-account.form.gender.placeholder'),
                options: [
                    {
                        value: 'male',
                        text: i18n.t('general.gender.male')
                    },
                    {
                        value: 'female',
                        text: i18n.t('general.gender.female')
                    },
                    {
                        value: 'hide',
                        text: i18n.t('general.gender.hide')
                    }
                ]
            } 
        }
    ]
]

export const resetPassword = [
    {
        name: 'email',
        rules: 'required:email|email',
        component: Input,
        data: {
            label: i18n.t('reset-password.form.email'),
            type: 'email'
        }
    }
]

export const setPassword = [
    {
        name: 'password1',
        rules: `required:password|min:${store.state.passwordLength}`,
        component: Check,
        data: {
            label: i18n.t('set-password.form.password'),
            type: 'password'
        }
    },
    {
        name: 'password2',
        rules: 'required:repeatPassword',
        component: Input,
        data: {
            label: i18n.t('set-password.form.repeat-password'),
            type: 'password'
        }
    }
]

export const register = [
    {
        name: 'email',
        rules: 'required:email|email',
        component: Input,
        data: {
            label: i18n.t('register.form.email'),
            type: 'email'
        }
    },
    {
        name: 'password1',
        rules: `required:password|min:${store.state.passwordLength}`,
        component: Check,
        data: {
            label: i18n.t('register.form.password'),
            type: 'password'
        }
    },
    {
        name: 'password2',
        rules: 'required:repeatPassword',
        component: Input,
        data: {
            label: i18n.t('register.form.repeat-password'),
            type: 'password'
        }
    }
]