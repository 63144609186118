<template>
    <div class="ask-ai-tooltip">
        <div class="header">
            <p class="header__title">
                <span>{{ $t('ask-ai.start-tooltip.title') }}</span><span class="header__badge">{{ $t('ask-ai.start-tooltip.badge') }}</span>
            </p>
            <img
                class="header__close"
                width="16"
                height="16"
                src="@/assets/img/close-course.svg"
                alt=""
                @click="close"
            >
        </div>
        <div class="content">
            <ul class="content__list">
                <li class="content__item">{{ $t('ask-ai.start-tooltip.list-item1') }}</li>
                <li class="content__item">{{ $t('ask-ai.start-tooltip.list-item2') }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AskAIStartTooltip',
    computed: {
        ...mapState('tools', ['askAI'])
    },
    methods: {
        close() {
            this.askAI.showStartTooltip = false
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-tooltip {
    padding: 16px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px rgba(70, 80, 94, 0.16);
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(calc(-100% - 21px));
    width: max-content;
    max-width: 90cqw;
    color: $gray-900;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        left: 8px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $white;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid $gray-200;

    &__title {
        @include font-medium;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__badge {
        @include font-small-10;
        color: $white;
        background: $primary;
        padding: 0 4px;
        border-radius: 4px;
    }

    &__close {
        cursor: pointer;
    }
}

.content {
    @include font-regular-text;

    &__list {
        padding-left: 16px;
    }
}
</style>