<template>
    <div class="media">
        <div v-if="images.length" class="media__images">
            <div
                v-for="image in images"
                :key="image.data" 
                class="media__image"
            >
                <img :src="image.data" alt="">
            </div>
        </div>
        
        <div v-if="videos.length" class="media__videos">
            <div
                v-for="video in videos"
                :key="video.data" 
                class="media__video"
            >
                <MediaVideo :url="video.data" />
            </div>
        </div>

        <div v-if="audios.length" class="media__audios">
            <div
                v-for="audio in audios"
                :key="audio.data" 
                class="media__audio"
            >
                <AudioPlayer :data="audio.data" />
            </div>
        </div>
    </div>
</template>

<script>
import MediaVideo from '@/components/reusable/MediaVideo'
import AudioPlayer from '@/components/reusable/AudioPlayer'

export default {
    name: 'Media',
    props: ['data'],
    components: {
        MediaVideo,
        AudioPlayer
    },
    computed: {
        images() {
            return this.getMediaType('IMAGE')
        },
        videos() {
            return this.getMediaType('VIDEO')
        },
        audios() {
            return this.getMediaType('AUDIO')
        }
    },
    methods: {
        getMediaType(type) {
            return this.data.filter(item => item.type === type)
        }
    }
}
</script>

<style lang="scss" scoped>
.media {
    margin: 24px 0;

    &__image {
        img {
            border-radius: 6px;
        }
    }

    &__video {
        position: relative;
        padding-top: 56.25%;
        border-radius: 6px;
        overflow: hidden;

        ::v-deep .video-iframe-player {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__audios {
        margin: 32px 0;
    }
}
</style>