import { mapState } from 'vuex'
/*global WebAudioRecorder*/

export default {
    data() {
        return {
            audioData: [],
            stream: null,
            recorder: null,
            isRecording: false,
            recorderInterval: null,
            maxRecorderDuration: 300,
            encodingLoading: false,
            currentTime: 0,
            audioElement: null,
            isPlaying: false,
            microphoneInterval: null,
            wakeLock: null
        }
    },
    beforeDestroy() {
        clearInterval(this.recorderInterval);
        clearInterval(this.microphoneInterval);
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
        this.releaseWakeLock();
    },
    computed: {
        ...mapState('media', ['selectedDevices']),
        recorderBtn() {
            if (this.isRecording) {
                return {
                    icon: require('@/assets/img/audioPlayer/recorder-stop.svg'),
                    text: this.$t('audio-recorder.stop-recording'),
                    class: 'recording',
                    action: this.stopRecording
                };
            } else if (!this.audioElement) {
                return {
                    icon: require('@/assets/img/audioPlayer/recorder-mike.svg'),
                    text: this.$t('audio-recorder.start-recording'),
                    class: 'record',
                    action: this.startRecording
                };
            } else if (this.isPlaying) {
                return {
                    icon: require('@/assets/img/audioPlayer/pause.svg'),
                    text: this.$t('audio-recorder.stop-playing'),
                    class: 'playing',
                    action: this.pauseAudio
                };
            }

            return {
                icon: require('@/assets/img/audioPlayer/play.svg'),
                text: this.$t('audio-recorder.start-playing'),
                class: 'play',
                action: this.playAudio
            };
        },
        getRecordingTime() {
            function formatTime(seconds) {
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = seconds % 60;
            
                const formattedHours = hours.toString().padStart(2, '0');
                const formattedMinutes = minutes.toString().padStart(2, '0');
                const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
            
                if (hours > 0) {
                    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                } else {
                    return `${formattedMinutes}:${formattedSeconds}`;
                }
            }
            
            const seconds = this.currentTime;
            const formattedTime = formatTime(seconds);

            return formattedTime;
        }
    },
    methods: {
        async startMicrophoneInterval() {
            if (!this.stream) return;
            clearInterval(this.microphoneInterval);
            const audioContext = new AudioContext();
            const source = audioContext.createMediaStreamSource(this.stream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 512;
            analyser.smoothingTimeConstant = 0.4;
            source.connect(analyser);
            const volumes = new Uint8Array(analyser.frequencyBinCount);

            this.microphoneInterval = setInterval(() => {
                analyser.getByteFrequencyData(volumes);
                const sum = volumes.reduce((a, value) => a + value, 0);
                const average = sum / volumes.length;
                this.audioData.push(average);

                if (this.audioData.length > 13) this.audioData.shift();
            }, 75);
        },
        startRecording() {
            navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.selectedDevices.audioinput
                }
            })
                .then(stream => {
                    this.stream = stream;

                    const input = new AudioContext().createMediaStreamSource(this.stream);
                    const configs = {
                        workerDir: '/web-audio-recorder/',
                        encoding: 'mp3',
                        numChannels: 2
                    };

                    this.recorder = new WebAudioRecorder(input, configs);

                    this.recorder.onError = () => {
                        this.stopRecording(false);
                    };

                    this.recorder.setOptions({
                        encodeAfterRecord: true,
                        mp3: { bitRate: 160 }
                    });

                    this.recorder.startRecording();
                    this.isRecording = true;
                    this.$emit('startRecording');
                    this.startMicrophoneInterval();
                    this.recorderInterval = setInterval(() => {
                        this.currentTime++;

                        if (this.currentTime >= this.maxRecorderDuration) this.stopRecording();
                    }, 1000);
                })
                .catch(error => {
                    this.$notify({
                        title: this.$i18n.t('notify.mic-error.title'),
                        text: this.$i18n.t('notify.mic-error.subtitle'),
                        data: { type: 'error' }
                    });
                });
            this.requestWakeLock();
        },
        stopRecording(triggerOnComplete = true) {
            if (this.recorder && this.isRecording) {
                this.recorder.finishRecording();
                clearInterval(this.recorderInterval);
                this.encodingLoading = true;

                if (triggerOnComplete) {
                    this.recorder.onComplete = (recorder, blob) => {
                        this.isRecording = false;
                        this.$emit('stopRecording');
                        this.encodingLoading = false;
                        this.stream.getTracks().forEach(track => track.stop());
                        this.currentTime = 0;
                        this.convertToBase64(blob);
                        clearInterval(this.microphoneInterval);
                    };
                } else {
                    this.isRecording = false;
                    this.encodingLoading = false;
                    this.stream.getTracks().forEach(track => track.stop());
                    this.currentTime = 0;
                    clearInterval(this.microphoneInterval);
                }
            }
            this.releaseWakeLock();
        },
        convertToBase64(blob) {
            const reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = () => this.setAudioElement(reader.result.split(',')[1], true);
        },
        async requestWakeLock() {
            if ('wakeLock' in navigator) {
                try {
                    this.wakeLock = await navigator.wakeLock.request('screen');
                } catch { console.log('wake lock request error') }
            }
        },
        releaseWakeLock() {
            if (this.wakeLock) {
                this.wakeLock.release().then(() => {
                    this.wakeLock = null;
                });
            }
        }
    }
};
