<template>
    <div class="radio-wrapper">
        <Radio v-for="option in promoted" :key="option.value" v-model="fieldValue" :data="createOptions(option)"/>
        <p class="show-rest" :class="{'disable': restSelected, 'active': showRest}" @click="showRest = !showRest">
            {{getShowRestLabel()}} <img src="@/assets/img/onboarding/arrow-blue.svg" alt="">
        </p>
        <template v-if="showRest">
            <Radio v-for="option in rest" :key="option.value" v-model="fieldValue" :data="createOptions(option)"/>
        </template>
    </div>
</template>

<script>
import Radio from '@/components/reusable/Radio'

export default {
    name: 'RadioWrapper',
    props: ['data', 'value'],
    components: {
        Radio
    },
    data() {
        return {
            fieldValue: this.value,
            showRest: false
        }
    },
    created() {
        if(this.restSelected) this.showRest = true
    },
    computed: {
        promoted() {
            return this.data.options.filter(item => item.promoted)
        },
        rest() {
            return this.data.options.filter(item => !item.promoted)
        },
        restSelected() {
            return !this.data.options.find(item => item.value === this.fieldValue).promoted
        }
    },
    methods: {
        getShowRestLabel() {
            return this.showRest ? this.$i18n.t('onboarding.preferences.hide-options') : this.$i18n.t('onboarding.preferences.show-options')
        },
        createOptions(option) {
            return {
                    description: this.$i18n.t(option.description),
                    promoted: option.promoted,
                    radioValue: option.value,
                    name: this.data.name,
                    label: this.$i18n.t(option.label)
            }
        }
    },
    watch: {
        fieldValue() {
            this.$emit('input', this.fieldValue)
        }
    }
}
</script>
