<template>
    <!-- <div v-if="poster"> -->
    <div>
        <!-- <vue-plyr
            :class="[
                'video-iframe-player',
                { 'video-iframe-player--loading': state === 'loading' },
            ]"
            ref="plyr"
            :options="plyrOptions">
            <video :data-poster="poster">
                <source
                    size="1080"
                    :src="this.videoUrl"
                    type="video/mp4" />
            </video>
        </vue-plyr> -->
        <vue-plyr
            :class="[
                'video-iframe-player',
                { 'video-iframe-player--loading': state === 'loading' },
            ]" 
            :options="plyrOptions"
            ref="plyr"
        >
            <div data-plyr-provider="youtube" :data-plyr-embed-id="checkYTurl(videoUrl)"></div>
        </vue-plyr>
        <div class="video__controls">
            <MediaVideoControls
                v-if="state !== 'loading'"
                type="learning"
                :duration="timestamp.duration"
                :percent="timestamp.percent"
                :seconds="timestamp.seconds"
                :state="state"
                @pause="pause"
                @play="play"
                @changeVolume="changeVolume"
                @skip="onSkip" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
// import defaultPoster from '@/assets/img/dashboard/step2.png';
import MediaVideoControls from '@/components/reusable/MediaVideoControls';

Vue.use(VuePlyr);
export default {
    name: 'MediaVideoFile',
    components: {
        MediaVideoControls
    },
    props: ['url', 'videoUrl'],
    data() {
        return {
            player: null,
            state: 'loading',
            timestamp: {
                duration: 0,
                percent: 0,
                seconds: 0
            },
            plyrOptions: {
                controls: [],
                displayDuration: true,
                clickToPlay: true,
                ratio: '16:9',
                hideControls: true,
                youtube: {
                    controls: 0,
                }
            },
            // poster: this.posterUrl || null
        };
    },
    beforeDestroy() {
        this.player.off('play', this.onPlay);
        this.player.off('pause', this.onPause);
        this.player.off('ready', this.onLoaded);
        this.player.off('timeupdate', this.onTimeUpdate);

        if ('ontouchstart' in window) {
            this.$refs.plyr.$el.removeEventListener('touchend', this.handleInteraction);
        }
    },
    async mounted() {
        // if (!this.poster) await this.createPoster();
        if (this.$refs.plyr?.player) {
            if (!this.player) this.player = this.$refs.plyr.player;

            if ('ontouchstart' in window) {
                this.$refs.plyr.$el.addEventListener('touchend', this.handleInteraction);
            }

            this.player.volume = 1;

            this.player.on('play', this.onPlay);
            this.player.on('pause', this.onPause);
            this.player.on('ready', this.onLoaded);
            this.player.on('timeupdate', this.onTimeUpdate);
        }
    },
    methods: {
        handleInteraction() {
            if (this.player.paused) this.player.play();
            else this.player.pause();
        },
        onLoaded() {
            this.ensure(this.loadDuration);
        },
        pause() {
            this.player.pause();
        },
        play() {
            this.player.play();
        },
        onPause() {
            this.state = 'paused';
        },
        onPlay() {
            this.state = 'playing';
        },
        onTimeUpdate() {
            const currentTime = this.player.currentTime;
            this.timestamp.seconds = currentTime;
            this.timestamp.percent = currentTime / this.timestamp.duration;
        },
        onSkip(payload) {
            this.player.currentTime = payload.targetSeconds;
            this.timestamp.seconds = payload.targetSeconds;
            this.timestamp.percent = payload.targetPercentage;
        },
        changeVolume(payload) {
            if (this.player) {
                this.player.volume = payload;
            }
        },
        loadDuration() {
            this.timestamp.duration = this.player.duration;
            return this.timestamp.duration;
        },
        ensure(func, timeout) {
            timeout = timeout || 500;
            const result = func();
            if (result) {
                return result;
            } else {
                return setTimeout(() => this.ensure(func, timeout), timeout);
            }
        },
        // async createPoster() {
        //     await new Promise(resolve => {
        //         const video = document.createElement('video');
        //         video.crossOrigin = 'anonymous';
        //         video.src = this.videoUrl;

        //         video.onerror = () => {
        //             console.error('Failed to load video');
        //             this.poster = defaultPoster;
        //             resolve();
        //         };

        //         video.onloadedmetadata = () => {
        //             video.currentTime = video.duration / 2;
        //         };

        //         video.onseeked = async () => {
        //             const canvas = document.createElement('canvas');
        //             canvas.width = video.videoWidth;
        //             canvas.height = video.videoHeight;

        //             const ctx = canvas.getContext('2d');
        //             ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        //             try {
        //                 this.poster = canvas.toDataURL();
        //             } catch (error) {
        //                 console.error('Failed to create poster', error);
        //                 this.poster = defaultPoster;
        //             }

        //             resolve();
        //         };
        //     });
        // },
        checkYTurl(url) {
            const tempUrl = Array.isArray(url) ? url[0] : url;

            const regex = /https:\/\/youtu\.be\/([^/?&]*)/;
            const match = tempUrl.match(regex);

            if (match && match[1]) {
                return match[1];
            } else {
                return null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.video-iframe-player {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-style: solid;
    border-color: $primary-light;
    border-width: 1px 1px 0 1px;

    &--loading {
        border-width: 1px 1px 1px 1px;
        border-radius: 10px;
        margin-bottom: 52px;
    }
}

.video__controls {
    position: absolute;
    bottom: 0;
    width: 100%;
}

::v-deep {
    .plyr,
    .plyr__video-wrapper {
        background: transparent;
    }

    .video-iframe-player .plyr__video-wrapper {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .video-iframe-player.video-iframe-player--loading .plyr__video-wrapper {
        border-radius: 10px;
    }
}

::v-deep .plyr__poster {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 10px;

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 44px;
        height: 44px;
        background-color: $primary;
        border-radius: 50%;
    }
    &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 44px;
        height: 44px;
        background-color: $white;
        -webkit-mask-image: url('~@/assets/img/podcast/play.svg');
        mask-image: url('~@/assets/img/podcast/play.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        cursor: pointer;
    }
}
</style>
