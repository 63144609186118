<template>
    <label class="input"> {{data.label}}
        <input 
            :name="data.name" 
            :id="data.type === 'email' ? data.type : null" 
            :type="data.type ? data.type : 'text'" 
            :disabled="data.disabled" 
            :placeholder="data.placeholder"
            :value="value" 
            @[event]="onChange"
            :class="data.status"
        >
        <div @click="reset(data.disabled)" v-if="cross" :class="['input-delete', { 'input-delete--disabled': data.disabled }]">
            <img src="~@/assets/img/cross.svg"/>
        </div>
    </label>
</template>

<script>
export default {
    name: 'Input',
    props: {
        data: {
            type: Object,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        cross: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            event: this.data.event || 'change'
        }
    },
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.value)
        },
        reset(disabled) {
            if(disabled) return;

            this.$emit('input', '')
        }
    }
}
</script>

<style lang="scss" scoped>
.input-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 12px;
    right: 22px;
    width: 26px;
    height: 26px;
    border-radius: 100px;
    background-color: $gray-100;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }

    &:hover {
        filter: brightness(.95);
    }

    &--disabled {
        cursor: default;

        &:hover {
            filter: none;
        }
    }
}
</style>