var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['audio-levels']},[_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.audioSource}},[_c('source',{attrs:{"type":"audio/mp3"}})]),_c('div',{staticClass:"audio-levels-control"},[(_vm.isPlaying)?_c('Button',{staticClass:"button btn--secondary btn--pause",attrs:{"icon":require('@/assets/img/audioPlayer/pause.svg')},nativeOn:{"click":function($event){return _vm.pauseAudio()}}}):_c('Button',{class:[
                'button btn--secondary btn--play',
                { 'btn--disabled': _vm.loading || _vm.isRecording }
            ],attrs:{"icon":require('@/assets/img/audioPlayer/play.svg'),"disabled":_vm.loading || _vm.isRecording},nativeOn:{"click":function($event){return _vm.playAudio()}}})],1),_c('div',{staticClass:"audio-levels-progress",style:({ 'maskImage': `url('${require(`@/assets/img/audioPlayer/audio-levels-${ _vm.randomLevelIdx }.svg`)}')` })},[_c('Slider',{staticClass:"duration-slider",attrs:{"disabled":_vm.isRecording,"value":_vm.currentTime,"max":_vm.duration,"orientation":"horizontal"},on:{"changed":_vm.updateCurrentTime}})],1),_c('div',{staticClass:"audio-levels-time"},[(_vm.showTime)?_c('div',{class:[
                'time-display',
                { 'time-display--active': _vm.currentTime !== 0 },
                { 'time-display--disabled': _vm.loading || _vm.isRecording },
            ]},[_vm._v(_vm._s(_vm.currentTime !== 0 ? _vm.formattedTime : _vm.initialDuration))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }