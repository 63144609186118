import gql from 'graphql-tag'

export const submitAnswers = gql`
    mutation($inputs: [ElearningSubmitAnswersInput!]!, $save: Boolean!) {
        submitAnswers(inputs: $inputs, save: $save) {
            tasks {
                uuid
                result {
                    isCorrect
                    data
                }
            }
            materials {
                percentage
                uuid
            }
        }
    }
`