<template>
    <fade-transition mode="out-in">
        <Modal class="modal--video" v-if="show">
            <div class="modal-inner">
                <fade-transition mode="out-in">
                    <div v-if="loading" class="loader-wrapper">
                        <div class="loader"></div>
                    </div>
                    <div v-else class="video-iframe-wrapper">
                        <div class="video-iframe-header">
                            <span class="video-iframe-header__btn" @click="hideModal()">
                                <img class="close" src="@/assets/img/close.svg" alt="">
                            </span>
                        </div>

                        <MediaVideo :url="videoUrl" autoPlay />
                    </div>
                </fade-transition>
            </div>
        </Modal>
    </fade-transition>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import FadeTransition from '@/components/reusable/FadeTransition';
import MediaVideo from '@/components/reusable/MediaVideo';

export default {
    name: 'VideoModal',
    components: {
        Modal,
        FadeTransition,
        MediaVideo
    },
    data() {
        return {
            show: false,
            videoUrl: null,
            type: null,
            loading: true,
            loadingTimeout: 500
        }
    },
    created() {
        this.$bus.$on('setVideoModal', data => this.handleVideoModal(data));
    },
    beforeDestroy() {
        this.$bus.$off('setVideoModal');
    },
    methods: {
        handleVideoModal(data) {
            const { show, videoUrl } = data;
            if (show && this.isValidUrl(videoUrl)) return this.showModal(data);
            this.hideModal();
        },
        showModal(data) {
            const { videoUrl = '' } = data;
            this.show = true;
            this.videoUrl = videoUrl;

            setTimeout(() => {
                this.loading = false;
            }, this.loadingTimeout);
        },
        hideModal() {
            this.show = false;
            this.videoUrl = null;
            this.loading = true;
        },
        isValidUrl(url) {
            try { 
                return Boolean(new URL(url)); 
            }
            catch(e){ 
                console.error('Invalid video url')
                return false; 
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal--video {
        padding: 8px;

        @media (min-width: $tablet-switch-lector) {
            padding: 20px 100px;

            @media (min-height: 768px) {
                padding: 100px;
            }
        }

        .loader-wrapper {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
        }

        .modal-inner {
            position: relative;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            max-width: 1100px;
        }

        .video-iframe-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .video-iframe-header {
            display: flex;
            position: absolute;
            top: 0;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            height: 50px;
            width: 100%;
            z-index: 1234;
        }

        .video-iframe-header__btn {
            display: inline-flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            border: 1px solid $gray-900;
            background-color: $gray-900;
            border-radius: 8px;
            padding: 6px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                opacity: 0.7;
            }
        }
    }
</style>