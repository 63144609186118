import timezoneDate from '../functions/timezoneDate'

export default {
    methods: {
        formatDate(date, justYear) {
            const day =  this.$moment().isSame(date, 'day') ? this.$i18n.t('messages.today') :
            this.$moment().subtract(1, 'day').isSame(date, 'day') ? this.$i18n.t('messages.yesterday') : null

            const year = this.$moment().isSame(date, 'year')

            if(justYear) {
                if(!year) return this.$moment(date).format('YYYY')
                else return day ? `${day} ${this.$moment(date).format(timezoneDate('HH:mm'))}` : this.$moment(date).format(timezoneDate('D MMM HH:mm'))
            } else {
                if(!year) return this.$moment(date).format(timezoneDate('D MMM YYYY HH:mm'))
                else return day ? `${day} ${this.$moment(date).format(timezoneDate('HH:mm'))}` : this.$moment(date).format(timezoneDate('D MMM HH:mm'))
            }
        }
    }
}