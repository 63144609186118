import gql from 'graphql-tag'

export const getAvailableTeachers = gql`
    query($filters: TeacherFiltersInput! $first: Int!, $after: String) {
        getAvailableTeachers(filters: $filters, first: $first, after: $after) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                    uuid
                    firstName
                    avatar
                    languagesISpeak {
                        language {
                            name
                            shortName
                        }
                        languageLevel {
                            name
                            shortName
                        }
                    }
                    languagesITeach {
                        language {
                            name
                            shortName
                        }
                        languageLevel {
                            name
                            shortName
                        }
                    }
                    certificates
                    details {
                        profileVideo
                    }
                    advanceNotice
                }
            }
        }
    }
`