<template>
    <div class="form google-login-btn-wrapper">
        <div class="title-section">
            <h1 class="title">{{ $t('login.title') }}</h1>
        </div>
        <ApiError v-if="loginFail" :text="loginFail" />
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" novalidate>
                <ValidationProvider v-for="{ name, rules, component, data } in form" :key="name" :rules="rules" v-slot="{ classes, errors }">
                    <component :is="component" v-model="formData[name]" :class="classes" :data="data"/>
                    <p class="invalid-feedback">{{ errors[0] }}</p>
                </ValidationProvider>
                <div class="navigation mb-bottom">
                    <span>
                        <router-link :to="{name: 'resetPassword'}">{{ $t('login.lost-password') }}</router-link>
                    </span>
                </div>
                <Button :loading="submitLoading" class="primary b2" :text="$t('login.form.submit')"/>
            </form>
        </ValidationObserver>
        <div class="or">
            <span></span>
            <p>{{ $t('login.or') }}</p>
            <span></span>
        </div>
        <ThirdPartyLoginButtons login/>
        <span class="register-subtitle">{{ $t('login.form.dont-have-account') }}</span>
        <Button @click.native="$router.push({ name: 'register' })" class="default b2" :text="$t('login.form.register')"/>
    </div>
</template>

<script>
import { locales } from '@/i18n/i18n'
import { schoolV2 } from '@/plugins/axios'
import { metaInfo } from '@/helpers/metaInfo';
import Button from '@/components/reusable/Button'
import ThirdPartyLoginButtons from '@/components/ThirdPartyLoginButtons'
import { login as form } from '@/forms'
import ApiError from '@/components/reusable/ApiError'
import loginRedirect from '@/mixins/loginRedirect'

export default {
    name: 'Login',
    metaInfo() {
        return metaInfo('login', { 
            locales, 
            route: this.$route, 
            i18n: this.$i18n 
        })
    },
    mixins: [loginRedirect],
    components: {
        Button,
        ApiError,
        ThirdPartyLoginButtons
    },
    data() {
        return {
            formData: {},
            form,
            loginFail: null,
            submitLoading: false
        }
    },
    mounted() {
        if(this.$route.params.invalidToken) {
            this.$notify({
                title: this.$i18n.t('notify.invalid-token.title'),
                text: this.$i18n.t('notify.invalid-token.subtitle'),
                data: {
                    type: 'error'
                }
            })
        }
    },
    methods: {
        async submit() {
            this.submitLoading = true
            try {
                const res = await schoolV2.post('login_check', this.formData)
                const schoolLoginUrl = res.data.data?.schoolLoginUrl
                if(schoolLoginUrl) location.href = schoolLoginUrl
                else {
                    const token = res.data.token
                    localStorage.setItem('authToken', token)
                    window.google.accounts.id.cancel();
                    
                    this.loginRedirect()
                }
            } catch(err) {
                const { status } = err.response

                if(status === 403) this.loginFail = this.$i18n.t('login-fail-unconfirmed-account')
                else this.loginFail = this.$i18n.t('login-fail')
            } finally {
                this.submitLoading = false
            }
        },
    }
}
</script>