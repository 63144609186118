<template>
    <div v-if="!loading" class="calendar">
        <h3 class="section-title">{{$i18n.t('account.calendar.title')}}</h3>
        <p class="subtitle">{{$i18n.t('account.calendar.subtitle')}}</p>
        <div class="calendars">
            <div 
                v-for="calendar in calendars" 
                :key="calendar.type" 
                :class="['item', { 'item--active': activeCalendar.type === calendar.type }]"
                @click="setActiveCalendar(calendar)"
            >
                <img class="icon" :src="require(`@/assets/img/calendar/${ calendar.type}.png` )" alt="" width="48" height="48">
                <div class="name">{{ capitalizeFirstLetter(calendar.type) }} Calendar</div>
            </div>
        </div>
        <div class="calendar__instruction">
            <div class="step">
                <p class="step__number">{{ $t('account.calendar.instruction.step-number', { number: 1 }) }}</p>
                <template v-if="isGoogleActive">
                    <p class="step__title">{{ $t('account.calendar.instruction.google.step1.title') }}</p>
                    <div class="copy-link">
                        <Input :data="{ disabled: true }" :value="activeCalendar.url" />
                        <Button
                            class="secondary"
                            :text="$t('account.calendar.instruction.google.step1.copy')"
                            :icon="require('@/assets/img/tools/copy-active.svg')"
                            iconSize="16"
                            @click.native="copyLink"
                        />
                    </div>
                </template>
                <template v-else>
                    <p class="step__title">{{ $t('account.calendar.instruction.step1.title') }}</p>
                    <p class="step__subtitle">{{ $t('account.calendar.instruction.step1.subtitle', { file: config.fileName }) }}</p>
                    <a class="btn primary font-medium" :href="activeCalendar.url">
                        {{ config.fileName }}
                        <img src="@/assets/img/download-white.svg" alt="" class="icon">
                    </a>
                </template>
            </div>
            <div class="step">
                <p class="step__number">{{ $t('account.calendar.instruction.step-number', { number: 2 }) }}</p>
                <p class="step__title">{{ $t('account.calendar.instruction.step2.title') }}</p>
                <div class="step__list">
                    <div class="step__list-element" v-for="n in getConfig.steps" :key="n">
                        <div class="step__list-element-number">{{ n }}</div>
                        <p class="step__list-element-text" v-html="$t(`account.calendar.instruction.${ activeCalendar.type }${ n }`, { file: config.fileName, googleCalendarUrl: 'https://calendar.google.com/' })"></p>
                    </div>
                </div>
            </div>
            <div class="summary">
                <div class="summary__title">
                    <div class="summary__title-icon">
                        <img src="@/assets/img/check.svg" alt="">
                    </div>
                    <p class="summary__title-text">{{ $t('account.calendar.instruction.summary.title') }}</p>
                </div>
                <a 
                    v-if="!isGoogleActive"
                    class="summary__support-url"
                    :href="getConfig.supportUrl"
                    target="_blank"
                >
                    {{ $t('account.calendar.instruction.summary.support-url') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { calendarIntegrations } from '@/graphql/queries/calendarIntegrations'
import api from '@/graphql/api.js'
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter'
import Input from '@/components/reusable/Input'
import Button from '@/components/reusable/Button'

export default {
    name: 'AccountCalendar',
    components: {
        Input,
        Button
    },
    data() {
        return {
            loading: true,
            calendars: [],
            activeCalendar: null,
            config: {
                fileName: 'fluentbe.ics',
                google: {
                    steps: 5,
                    supportUrl: 'https://support.google.com/calendar/thread/3231927/how-do-i-import-ics-files-into-google-calendar?hl=en'
                },
                apple: {
                    steps: 2,
                    supportUrl: 'https://support.apple.com/guide/calendar/import-or-export-calendars-icl1023/mac'
                },
                outlook: {
                    steps: 4,
                    supportUrl: 'https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379'
                }
            },
            capitalizeFirstLetter
        }
    },
    async created() {
        const { res } = await api(calendarIntegrations)
        this.calendars = res.currentUser.student.iCalendarIntegrations
        this.activeCalendar = this.calendars[0]

        this.loading = false
        this.$emit('loaded')
    },
    computed: {
        getConfig() {
            return this.config[this.activeCalendar.type]
        },
        isGoogleActive() {
            return this.activeCalendar.type === 'google'
        }
    },
    methods: {
        setActiveCalendar(calendar) {
            this.activeCalendar = calendar

            setTimeout(() => {
                const el = document.querySelector('.calendar__instruction')

                window.scrollTo({
                    top: el.offsetTop - document.querySelector('nav.nav').offsetHeight,
                    behavior: 'smooth'
                })
            }, 100)
        },
        copyLink() {
            navigator.clipboard.writeText(this.activeCalendar.url)

            this.$notify({
                title: this.$i18n.t('notify.copy'),
                duration: 1000,
                ignoreDuplicates: true,
                data: {
                    type: 'success'
                }
            })
        }
    }
}
</script>
