import gql from 'graphql-tag'

export const studentPackages = gql`
    {
        currentUser {
            student {
                studentPackages {
                    quantity
                    type
                    language {
                        name
                        shortName
                    }
                    createdAt
                    reason {
                        name
                        isMarketing
                    }
                }
            }
        }
    }
`