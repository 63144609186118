<template>
    <div class="input"> {{data.label}}
        <Datepicker 
            :name="data.name" 
            :value="value === true ? null : value" 
            :format="getFormat()" 
            @input="onChange"
            :disabled-dates="disabledDates"
            :placeholder="$i18n.t(`general.choose-date`)"
            :language="locales[$route.params.locale]"
            :monday-first="true"
            :open-date="value && typeof value !== 'boolean' ? new Date(value) : data.max || data.min ? new Date(data[data.open || 'min']) : new Date()"
            calendar-class="date-input"
            wrapper-class="date-input-wrapper"
            :full-month-name="true"
            :use-utc="true"
        />
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import {en, es, cs, sk, pl, uk} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Date',
    props: ['data', 'value'],
    components: {
        Datepicker  
    },
    data() {
        return {
            locales: {
                en, 
                es, 
                cz: cs, 
                sk, 
                pl,
                ua: uk
            },
            disabledDates: this.data.min || this.data.max ? {
                to: new Date(this.data.min),
                from: new Date(this.data.max)
            } : false
        }
    },
    methods: {
        onChange($event) {
            this.$emit('input', this.$moment($event).tz(this.defaultTimezone).format('YYYY-MM-DD'))
        },
        getFormat() {
            const mapFormats = {
                'DD/MM/YYYY': 'dd/MM/yyyy',
                'MM/DD/YYYY': 'MM/dd/yyyy',
                'YYYY/MM/DD': 'yyyy/MM/dd'
            }
            return mapFormats[this.$store.getters.dateFormat]
        }
    },
    computed: {
        defaultTimezone() {
            return this.$store.state.config.defaultTimezone
        }
    }
}
</script>

<style lang="scss">
    .date-input {
        .next, .prev {
            background: url('~@/assets/img/arrow-dark.svg'), transparent !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            width: 24px !important;
            height: 24px !important;
            
            &::after {
                content: unset !important;
            }
        }

        .next {
            transform: rotate(-90deg);
        }

        .prev {
            transform: rotate(90deg);
        }
    }
</style>