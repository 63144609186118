<template>
    <label class="modern-checkbox">
        <input ref="checkbox" type="checkbox" @change="onChange">
        <div class="checkmark"><div class="point"></div></div>
        {{data?.label}}
    </label>
</template>

<script>
export default {
    name: 'ModernChecbkox',
    props: ['data', 'value'],
    mounted() {
        this.setValue()
    },
    watch: {
        value() {
            this.setValue()
        }
    },
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.checked)
        },
        setValue() {
            this.$refs.checkbox.checked = this.value
        }
    }
}
</script>
