<template>
    <div class="frequency">
        <div class="frequency__item">
            <div class="info">
                <p class="label">{{ $i18n.t('dashboard.student.month-frequency') }}</p>
                <div class="bar"><span class="bar-inner" :style="`width: ${ calculatePercent(currentFrequency, maxFrequency) };`"></span></div>
            </div>
            <div class="value">
                {{ currentFrequency }}/{{ maxFrequency }}
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/graphql/api.js'
import { lessonsByPeriod } from '@/graphql/queries/lessonsByPeriod';
import percent from '@/mixins/percent'

export default {
    name: 'Frequency',
    mixins: [percent],
    props: {
        uuid: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            lessons: []
        }
    },
    async created() {
        const { res } = await api(lessonsByPeriod, {
            since: this.$moment().startOf('month'),
            until: this.$moment(),
            userUuid: this.uuid
        })

        this.lessons = res.currentUser.student.studentLessonsByPeriod;
    },
    computed: {
        currentFrequency() {
            return this.lessons.filter(item => item.hasParticipated && item.isIncludedInParticipation).length
        },
        maxFrequency() {
            return this.lessons.filter(item => item.isIncludedInParticipation).length
        }
    }
}
</script>

<style lang="scss" scoped>
.frequency {
    margin-top: 16px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .bar-inner {
            background: $warning !important;
        }

        .info {
            flex: 1;

            .label {
                @include font-medium;
                color: $gray-900;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                gap: 2px;
            }

            .bar {
                height: 12px;
                background: $gray-200;
                border-radius: 16px;
                margin-bottom: 2px;

                span {
                    display: block;
                    height: 100%;
                    background: $secondary;
                    border-radius: 16px;
                }
            }
        }

        .value {
            width: 40px;
            height: 40px;
            background: $gray-100;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-h4;
            color: $gray-900;
        }
    }
}
</style>