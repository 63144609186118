<template>
    <div class="widget platform-setup">
        <div class="header">
            <h3 class="title">
                {{$i18n.t('dashboard.platform-setup.title')}}
            </h3>
            <p class="subtitle">
                {{$i18n.t('dashboard.platform-setup.subtitle')}}
            </p>
        </div>
        <a 
            class="btn primary"
            :href="url"
            target="_blank"
        >
            {{$i18n.t('dashboard.platform-setup.btn')}}
        </a>
    </div>
</template>

<script>


export default {
    name: 'PlatformSetup',
    props: ['url']
}
</script>

