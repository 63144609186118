import gql from 'graphql-tag'

export const getOralPlacementTest = gql`
    {
        getOralPlacementTest {
            uuid
            status
            questions {
                uuid
                type
                question
                file
            }
        }
    }
`