<template>
    <div class="tools-wrapper">
        <Tools v-show="showTools"/>

        <div :class="['tools-buttons', { 'tools-buttons--ios': isIOSApp }]" v-click-outside="hideNav">
            <transition :name="computedTransition">
                <div class="tools-buttons-animate" v-show="showToolsButtons">
                    <div class="tools-buttons__header">
                        {{ $i18n.t('tools.menu.title') }}
                        <img 
                            class="tools-buttons__close" 
                            src="@/assets/img/notify/close.svg" 
                            alt="" 
                            @click="closeToolButtonsClick(true)">
                    </div>
                    <ToolButton
                        v-for="(tool, index) in tools"
                        :key="tool"
                        :tool="tool"
                        @click.native="closeToolButtonsClick(false)"
                        :class="{ 'tool-button--hide': !moreTools && index > 1 }"
                    />
                    <ToolButton 
                        v-if="isFreeApiAvaliable && !isMobile"
                        tool="toggleMoreTools"
                    />
                </div>
            </transition>
            <div 
                v-show="showToolsButtonsTrigger"
                :class="[
                    'tool-button', 
                    'tool-button--toggle',
                    { 'tool-button--toggle-active': showToolsButtons }
                ]" 
                @click="toolButtonToggleClick"
            >
                <span class="tool-button__title">
                    {{ $i18n.t('tools.menu.title') }}
                </span>

                <img 
                    width="16" 
                    height="16" 
                    :src="require(`@/assets/img/tools/toggle-small.svg`)" 
                    alt=""
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Tools from './Tools'
import ToolButton from './ToolButton'
import ClickOutside from 'vue-click-outside'

export default {
    name: 'ToolsWrapper',
    directives: {
        ClickOutside
    },
    components: {
        Tools,
        ToolButton
    },
    mounted() {
        this.popupItem = document.querySelector('.tool-button--toggle')

        if (this.isMobile) {
            this.$store.commit('tools/setToolsButtons', false);
            this.$store.commit('tools/setMoreTools', true);
        }
    },
    computed: {
        ...mapState('tools', ['tools', 'showTools', 'showToolsButtons', 'moreTools']),
        ...mapState(['isMobile', 'isIOSApp']),
        ...mapGetters('tools', ['isFreeApiAvaliable']),
        showToolsButtonsTrigger() {
            if (!this.isMobile) return true;

            return !this.showToolsButtons && this.isMobile 
        },
        computedTransition () {
            if (this.isMobile) return '';
            return 'fade';
        }
    },
    methods: {
        toolButtonToggleClick() {
            if (!this.showToolsButtons && this.isMobile ) {
                setTimeout(() => { 
                    this.$store.state.blurBackground = true;
                }, 10);
            }

            this.$store.commit('tools/toggleToolsButtons')
        },
        closeToolButtonsClick(directClose) {
            this.$store.state.blurBackground = false;
            if (this.isMobile) {
                this.$store.commit('tools/setToolsButtons', false);

                if(directClose) {
                    this.$gtag({
                        category: 'tool',
                        action: 'hide'
                    })
                }
            }
        },
        hideNav() {
            if (!this.showToolsButtons) return;
            if (!this.isMobile) return;
            this.closeToolButtonsClick(true);
        },
    },
    watch: {
        isMobile(n) {
            if (n) {
                this.$store.commit('tools/setMoreTools', true)
                this.$store.commit('tools/setToolsButtons', false)
                this.$store.state.blurBackground = false;
                
                if (this.$store.state.tools.showTools) document.documentElement.classList.add('overflow-y-hidden');
            } else {
                this.$store.commit('tools/setMoreTools', false)
                this.$store.commit('tools/setToolsButtons', true)
                document.documentElement.classList.remove('overflow-y-hidden');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/_tool_button.scss";

.tools-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 997;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    width: 100%;
    padding: 11px 16px 16px;
    box-shadow: 0 2px 16px rgba(#000000, 0.24);

    @media(min-width: $mobile-tools) {
        box-shadow: none;
        width: initial;
        bottom: 16px;
        left: 16px;
        padding: 0;
        width: initial;
        background-color: transparent;
    }

    &--ios {
        padding: 11px 16px 32px;
    }
}

.tools-buttons-animate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media(min-width: $mobile-tools) {
        order: initial;
        width: initial;
    }
}

.tools-buttons__header {
    display: flex;
    font-weight: 600;
    width: calc(100% + 32px);
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 12px 24px;
    border-bottom: 1px solid $gray-200;
    margin: 0 -16px 23px;

    @media(min-width: $mobile-tools) {
        display: none;
    }
}

.tools-buttons__close {
    filter: invert(1) brightness(1.5);
    cursor: pointer;
    width: 25px;
}

.tools--translator,
.tools--grammar,
.tools--dictionary,
.tools--askAI {
    + .tools-buttons {
        z-index: 997;

        @media(min-width: $mobile-tools) {
            z-index: 998;
        }
    }
}

.tools--askAI {
    overflow-x: hidden;
}

.tools-wrapper--hide {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.tools-wrapper--hide {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>