var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('Skeleton',{attrs:{"simpleChat":_vm.simpleChat}}):_c('div',{staticClass:"messages"},[(_vm.allGroups.length)?[(_vm.mobileView && !_vm.simpleChat)?_c('div',{staticClass:"mobile-nav"},[_c('div',{class:[
                        'show-groups', 
                        {
                            'show-groups--notification': _vm.unreadMessagesCount, 
                            'show-groups--hide': _vm.$route.name === 'messagesOnly'
                        }
                    ],on:{"click":function($event){_vm.showGroups = true}}},[_c('img',{attrs:{"src":require("../assets/img/arrow-dark.svg"),"alt":""}})]),_c('GroupSummary',{staticClass:"active-conversation",attrs:{"group":_vm.activeGroupObject}}),_c('div',{staticClass:"show-assets",on:{"click":function($event){_vm.showAssets = true}}},[_c('img',{attrs:{"src":require("../assets/img/dot-menu-dark.svg"),"alt":""}})])],1):_vm._e(),(!_vm.simpleChat)?_c('Sidebar',{attrs:{"showGroups":_vm.showGroups,"showAssets":_vm.showAssets,"groups":_vm.groups,"archivalGroups":_vm.archivalGroups,"activeGroup":_vm.activeGroup,"activeGroupObject":_vm.activeGroupObject,"showArchival":_vm.showArchival},on:{"changeActiveGroup":_vm.checkMessages,"hideAssets":function($event){_vm.showAssets = false},"toggleArchival":_vm.toggleArchival}}):_vm._e(),_c('Chat',{attrs:{"msgs":_vm.msgs,"activeGroup":_vm.activeGroup,"isActive":_vm.isActive,"simpleChat":_vm.simpleChat},on:{"getPreviousMessages":_vm.getPreviousMessages}})]:_c('div',{staticClass:"empty-state"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$i18n.t(`messages.no-groups.title`)))]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$i18n.t(`messages.no-groups.subtitle`)))])])],2),(_vm.deleteMessageModal.show)?_c('DeleteMessage',{attrs:{"data":_vm.deleteMessageModal.data},on:{"hide":function($event){_vm.deleteMessageModal.show = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }