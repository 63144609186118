<template>
    <div :class="['action', { 
        'action--calendar': getScheduleAction === 'none' && !isSwitchLectorScreenActive,
        'action--lector-card': isSwitchLectorScreenActive && getSwitchLectorActiveScreen === SCHEDULE_SCREEN.LECTOR_DETAILS,
        'action--overflow-visible': actionOverflowState && isMobile
    }]">
        <fade-transition mode="out-in">
            <template v-if="loading">
                <div class="loader-wrapper">
                    <div class="loader"></div>
                </div>
            </template>

            <ScheduleError v-else-if="scheduleErrorActive" />

            <template v-else-if="isSwitchLectorScreenActive">
                <SwitchLectorSpeciality v-if="getSwitchLectorActiveScreen === SCHEDULE_SCREEN.SPECIALITY" />
                <SwitchLectorList v-if="getSwitchLectorActiveScreen === SCHEDULE_SCREEN.LIST" />
                <LectorCard :data="$store.state.schedule.lectorDetails" v-if="getSwitchLectorActiveScreen === SCHEDULE_SCREEN.LECTOR_DETAILS" />
                <div v-if="isDateFilterScreen" class="schedule-wrapper schedule-wrapper--calendar">
                    <ScheduleCalendar mode="default" :days="$store.state.schedule.filterDays"/>
                </div>
            </template>

            <template v-else>
                <div :key="1" v-if="getScheduleAction === 'none' && $store.state.schedule.days.length" :class="['schedule-wrapper', { 'schedule-wrapper--calendar-preview': isCalendarPreviewMode }]">
                    <ScheduleLectorInfo :lector="lector"/>
                    <ScheduleCalendar :lector="lector" mode="default" :days="$store.state.schedule.days"/>
                    <SwitchLectorBar v-if="ifShowSwitchLectorBar" />
                </div>
                
                <div :key="2" v-if="['book', 'cancel'].includes(getScheduleAction)" class="schedule-confirmation">
                    <div class="schedule-confirmation__wrapper">
                        <ScheduleLectorInfo :action="getScheduleAction" :hideLectorArrows="true" :lector="lector" />
                        <ScheduleConfirmation :action="getScheduleAction"/>
                    </div>
                </div>
            </template>
        </fade-transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ScheduleCalendar from '@/components/schedule/ScheduleCalendar';
import ScheduleLectorInfo from '@/components/schedule/ScheduleLectorInfo';
import ScheduleConfirmation from '@/components/schedule/ScheduleConfirmation';
import ScheduleError from '@/components/schedule/ScheduleError';
import SwitchLectorBar from '@/components/switchLector/SwitchLectorBar';
import SwitchLectorList from '@/components/switchLector/SwitchLectorList';
import SwitchLectorSpeciality from '@/components/switchLector/SwitchLectorSpeciality';
import LectorCard from '@/components/switchLector/LectorCard';
import FadeTransition from '@/components/reusable/FadeTransition';
import flagMixin from '@/mixins/flag';
import setAvatarMixin from '@/mixins/setAvatar';
import modalMixin from '@/mixins/modal';
import timezoneDate from '@/functions/timezoneDate';
import { SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    name: 'ScheduleRoot',
    components: { 
        ScheduleConfirmation, 
        ScheduleLectorInfo, 
        ScheduleCalendar,
        ScheduleError,
        SwitchLectorBar,
        SwitchLectorSpeciality,
        SwitchLectorList,
        FadeTransition,
        LectorCard
    },
    data () {
        return {
            timezoneDate,
            today: '',
            days: [],
            SCHEDULE_SCREEN,
            actionOverflowState: false
        }
    },
    mixins: [flagMixin, setAvatarMixin, modalMixin],
    beforeMount () {
        this.today = this.$moment().format(timezoneDate('YYYY-MM-DD'));
    },
    created() {
        const { 
            apiUrl,
            selectedNewLector,
            switchLector: { switchLectorApiUrl }
        } = this.$store.state.schedule;

        if (selectedNewLector) return;
        if (switchLectorApiUrl || apiUrl) return this.$store.dispatch('schedule/loadData');
        if (this.isCancelLessonMode) return this.$store.dispatch('schedule/loadCancelLessonData');
        if (this.isBookLessonMode) return this.$store.dispatch('schedule/loadBookLessonData');
    },
    mounted() {
        document.querySelector('.modal-inner')?.classList.remove('modal-inner--courses');
        this.$bus.$on('toggleLangISpeakDropdown', (value) => this.actionOverflowState = value);
    },
    beforeDestroy() {
        this.$bus.$off('toggleLangISpeakDropdown');
    },
    computed: {
        ...mapState('schedule', ['loading']),
        ...mapState(['isMobile']),
        ...mapGetters('schedule', [
            'getScheduleAction', 
            'isCancelLessonMode',
            'isBookLessonMode',
            'getSwitchLectorActiveScreen', 
            'isSwitchLectorScreenActive',
            'ifShowSwitchLectorBar',
            'isDateFilterScreen',
            'isCalendarPreviewMode'
        ]),
        scheduleErrorActive() {
            return this.$store.state.schedule.error.status;
        },
        lector() {
            return this.$store.state.schedule.selectedSlot.lector;
        }
    },
    watch: {
        '$store.state.schedule.switchLector.switchLectorApiUrl': {
            handler() {
                this.$store.dispatch('schedule/loadData');
            }
        }
    }
}
</script>
