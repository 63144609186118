<template>
    <form @submit.prevent="submit" v-if="!loading" class="security">
        <template v-if="passwordSet">
            <h3 class="section-title">{{$i18n.t('account.security.password-title')}}</h3>
            <Input v-model="formData.currentPassword" :data="{label: $i18n.t('account.security.current-password'), type: 'password'}"/>
            <p v-if="getValidation('currentPassword')" class="invalid-feedback">{{$i18n.t(getValidation('currentPassword'))}}</p>
            <Check v-model="formData.password" :data="{label: $i18n.t('account.security.password'), type: 'password'}"/>
            <p v-if="getValidation('password')" class="invalid-feedback">{{$i18n.t(getValidation('password'))}}</p>
            <Input v-model="formData.repeatedPassword" :data="{label: $i18n.t('account.security.repeated-password'), type: 'password'}"/>
            <p v-if="getValidation('repeatedPassword')" class="invalid-feedback">{{$i18n.t(getValidation('repeatedPassword'))}}</p>
        </template>
        <h3 class="section-title">{{$i18n.t('account.security.account-title')}}</h3>
        <Input style="margin-bottom: 32px;" :data="{label: $i18n.t('forms.email'), disabled: true}" :value="$store.state.user.email"/>
        <h3 class="section-title">{{$i18n.t('account.security.account-subtitle')}}</h3>
        <p class="subtitle">{{$i18n.t('account.security.connect-subtitle')}}</p>
        <div class="integrations">
            <div class="item" v-for="item in socials" :key="item">
                <div class="type">
                    <img width="16" height="16" :src="require(`@/assets/img/social/${item}.svg`)" alt=""> {{$i18n.t('account.security.connect-account')}} {{capitalizeFirstLetter(item)}}
                </div>
                <div class="action">
                    <div v-if="checkIntegration(item)" class="connected">
                        <div class="icon"><img width="8" height="8" src="@/assets/img/check-light.svg" alt=""></div><span class="text">{{$i18n.t('account.security.connected')}}</span>
                    </div>
                    <template v-else>
                        <GoogleLoginBtn v-if="item === 'google'" :type="'icon'" :width="130" :action="googleIntegrate" :abortGoogleTapFrame="true" />
                        <FacebookLoginBtn v-else-if="item === 'facebook'" @action="facebookIntegrate" iconOnly/>
                        <AppleIntegrateBtn v-else-if="item === 'apple'" :btnMode="'logo-only'" @action="appleIntegrate" />
                    </template>
                </div>
            </div>
        </div>
        <Button v-if="passwordSet" class="primary" type="submit" :text="$i18n.t('forms.save-changes')" :loading="submitLoading" />
    </form>
</template>

<script>
import { profileSecurity } from '@/graphql/queries/profileSecurity'
import { updateUserPassword } from '@/graphql/mutations/updateUserPassword'
import { integrateUser } from '@/graphql/mutations/integrateUser'
import api from '@/graphql/api.js'
import Input from '@/components/reusable/Input'
import Check from '@/components/reusable/Check'
import Button from '@/components/reusable/Button'
import GoogleLoginBtn from '@/components/reusable/GoogleLoginBtn'
import AppleIntegrateBtn from '@/components/reusable/AppleIntegrateBtn'
import FacebookLoginBtn from '@/components/reusable/FacebookLoginBtn'
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Security',
    components: {
        Input,
        Check,
        Button,
        GoogleLoginBtn,
        AppleIntegrateBtn,
        FacebookLoginBtn
    },
    data() {
        return {
            loading: true,
            submitLoading: false,
            socials: ['google', 'facebook', 'apple'],
            integrations: [],
            passwordSet: null,
            validation: {},
            formData: {},
            capitalizeFirstLetter
        }
    },
    async created() {
        const {res} = await api(profileSecurity)
        this.passwordSet = res.currentUser.passwordSet
        this.integrations = res.currentUser.externalIntegrations

        if(this.removeAppleIntegrateBtn) this.socials.pop();

        this.loading = false
        this.$emit('loaded')
    },
    mounted() {
        window.addEventListener('mobileSocialsAuth', this.mobileSocialsAuth);
    },
    beforeDestroy() {
        window.removeEventListener('mobileSocialsAuth', this.mobileSocialsAuth);
    },
    computed: {
        ...mapState(['isIOSApp', 'isAndroidApp']),
        ...mapGetters(['getFacebookLoginScope']),
        removeAppleIntegrateBtn() {
            return this.isAndroidApp && !this.checkIntegration('apple')
        }
    },
    methods: {
        async submit() {
            this.submitLoading = true
            const {error} = await api(updateUserPassword, {
                userUuid: this.$store.getters.userUuid,
                currentPassword: this.formData.currentPassword,
                password: this.formData.password,
                repeatedPassword: this.formData.repeatedPassword
            })

            const validationFailed = error?.find(item => item.message === 'validation')
            if(validationFailed) this.validation = validationFailed.extensions.validation
            else {
                this.$notify({
                    title: this.$i18n.t('notify.saved'),
                    text: this.$i18n.t('notify.password-changed'),
                    data: {
                        type: 'success'
                    }
                })
                this.validation = {}
                this.formData = {}
            }

            this.submitLoading = false
        },
        mobileSocialsAuth(e) {
            const { accessToken, provider } = e.detail;

            this.externalConnect(accessToken, provider);
        },
        getValidation(val) {
            return this.validation?.[val]?.[0].message
        },
        checkIntegration(type) {
            return this.integrations.find(item => item.type === type)
        },
        async googleIntegrate(response) {
            await this.externalConnect(response.credential, 'google')
        },
        async facebookIntegrate(data) {
            const { token, type } = data;

            await this.externalConnect(token, type)
            this.getFacebookLoginScope?.logout()
        },
        appleIntegrate(data) {
            const { token, type } = data;
            this.externalConnect(token, type)
        },
        async externalConnect(externalToken, type) {
            const {error} = await api(integrateUser, {
                uuid: this.$store.getters.userUuid,
                externalToken,
                integrationType: type
            })

            if(error) {
                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } else {
                const {res} = await api(profileSecurity)
                this.integrations = res.currentUser.externalIntegrations

                this.$notify({
                    title: this.$i18n.t('notify.integration.title'),
                    text: `${this.$i18n.t('notify.integration.subtitle')} ${this.capitalizeFirstLetter(type)}.`,
                    data: {
                        type: 'success'
                    }
                }) 
            }                  
        }
    }
}
</script>

