<template>
    <div class="account">
        <div class="header">
            <h2 class="title">{{$i18n.t('account.title')}}</h2>
        </div>
        <div class="content">
            <div class="mobile-nav">
                <label class="input">
                    <div class="select">
                        <img src="../assets/img/arrow-dark.svg" alt="">
                        <select @change="changeTab($event)" v-model="currentNav">
                            <option v-for="item in nav" :value="item.path" :key="item.path">{{ item.label }}</option>
                        </select>
                    </div>
                </label>
            </div>
            <div class="sidebar">
                <router-link
                    class="account-link"
                    v-for="item in nav"
                    :key="item.path"
                    :to="item.path"
                >
                    {{ item.label }} <img v-if="$route.path === item.path" src="../assets/img/arrow-blue.svg" alt="">
                </router-link>
            </div>
            <div class="view">
                <Skeleton v-if="loading"/>
                <router-view @loaded="loading = false"/>
            </div>
        </div>
    </div>
</template>

<script>
import Skeleton from '@/components/account/Skeleton'
import { mapGetters } from 'vuex'
import { appModePath } from '@/functions/appModes'

export default {
    name: 'Account',
    components: {
        Skeleton
    },
    data() {
        return {
            currentNav: this.$route.name,
            loading: true
        }
    },
    created() {
        if(this.$route.name === 'account') this.$router.replace(this.basePath + 'basic')
    },
    computed: {
        ...mapGetters(['isLearningMode']),
        basePath() {
            return appModePath('account/')
        },
        nav() {
            return [
                {
                    label: this.$t('account.nav.basic'),
                    path: this.basePath + 'basic'
                },
                {
                    label: this.$t('account.nav.security'),
                    path: this.basePath + 'security'
                },
                {
                    label: this.$t('account.nav.payments'),
                    path: this.basePath + 'payments'
                },
                {
                    label: this.$t('account.nav.packages'),
                    path: this.basePath + 'packages'
                },
                {
                    label: this.$t('account.nav.calendar'),
                    path: this.basePath + 'calendar'
                },
                {
                    label: this.$t('account.nav.certificates'),
                    path: this.basePath + 'certificates'
                }
            ]
        }
    },
    methods: {
        changeTab(e) {
            this.$router.push(e.target.value)
        }
    },
    watch: {
        $route(n, o) {
            if(n.name !== o.name) {
                this.currentNav = n.name
                this.loading = true
            }
        }
    }
}
</script>
