<template>
    <div class="course-plan">
        <div :class="['course-plan__content', { 'course-plan__content--mobile': isMobile }]">
            <div v-if="error" class="course-plan__error">{{ $t('course-plan.error.title') }}</div>
            <template v-else>
                <PlanHeader />
                <SideContent v-if="isMobile && isPresentationBased" />
                <Content :key="getChosenCoursePlanUuid" />
            </template>
        </div>
        <SideContent v-if="!error && !isMobile && isPresentationBased" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import api from '@/graphql/api.js'
import { availableCoursePlans } from '@/graphql/queries/availableCoursePlans';
import Content from '@/components/coursePlan/Content';
import PlanHeader from '@/components/coursePlan/PlanHeader';
import SideContent from '@/components/coursePlan/SideContent';

export default {
    name: 'CoursePlan',
    components: { Content, SideContent, PlanHeader },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('coursePlan', ['error', 'isPresentationBased', 'availablePlans']),
        ...mapGetters('coursePlan', ['getChosenCoursePlanUuid'])
    },
    async created() {
        await this.handleCoursePlan();
    },
    methods: {
        async handleCoursePlan() {
            if (this.$route.params.uuid) {
                this.$store.commit("coursePlan/setCoursePlanUuid", this.$route.params.uuid)
                this.$store.dispatch('coursePlan/initCourseProgram');
            }

            if (!this.availablePlans.length) {                
                const { res } = await api(availableCoursePlans);
        
                this.$store.commit("coursePlan/setAvailableCoursePlans", res);

                if (!this.$route.params.uuid) {
                    this.$store.commit("coursePlan/setCoursePlanUuid", res.availableCoursePlans[0]?.groupUuid)
                    this.$router.replace({ name: 'coursePlan', params: { uuid: res.availableCoursePlans[0]?.groupUuid } });
                }
            }
        }
    },
    watch: {
        '$route.params.uuid': {
            handler() {
                this.$store.dispatch('coursePlan/initCourseProgram');
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.course-plan {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding-bottom: 32px;

    &__error {
        color: $gray-900;
        width: 100%;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1;
        max-width: 1000px;

        &--mobile {
            width: 100%;
        }
    }
}
</style>