<template>
    <div class="skeleton">
        <div class="title">
            <div class="big dark"></div>
            <div v-if="$route.name === 'accountBasic'" class="image dark"></div>
        </div>
        <div v-for="n in 3" :key="n">
            <div v-for="n in 4" :key="n" class="flex" :class="{'margin': n === 1}">
                <div class="big dark"></div>
                <div class="big light"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountSkeleton'
}
</script>
