<template>
    <div :class="['side-content skeleton', { 'side-content--mobile': isMobile }]">
        <div class="progress-widget">
            <div class="progress-header">
                <div class="stats-circle">
                    <div class="small dark"></div>
                </div>
                <div class="title-wrapper">
                    <div class="title">
                        <div class="small dark"></div>
                    </div>
                    <div v-if="!isMobile" class="subtitle">
                        <div class="small dark"></div>
                    </div>
                    <div v-else class="time-wrapper">
                        <div class="time">
                            <div class="small dark"></div>
                        </div>
                        <div class="time-block">
                            <div class="small dark"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="progress-content">
                <div class="time-wrapper">
                    <div class="time">
                        <div class="small dark"></div>
                    </div>
                    <div class="time-block">
                        <div class="small dark"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SideSkeleton',
    computed: {
        ...mapState(['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.side-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    width: 100%;
    max-width: 352px;

    .progress-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $white;
        border-radius: 10px;
    }

    .progress-header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 24px;
        border-bottom: 1px solid $gray-200;
        border-bottom-style: dashed;
        width: 100%;

        .stats-circle {
            .small {
                width: 84px;
                height: 80px;
                margin-bottom: 0;
            }
        }

        .title-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                .small {
                    width: 122px;
                    height: 32px;
                    margin-bottom: 0;
                }
            }

            .subtitle {
                .small {
                    width: 158px;
                    height: 24px;
                    margin-top: 2px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .progress-content {
        margin: 24px 0;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .time-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time {
            display: flex;
            align-items: center;

            .small {
                width: 81px;
                height: 22px;
                margin-bottom: 0;
            }
        }

        .time-block {
            display: flex;
            align-items: center;

            .small {
                width: 96px;
                height: 41px;
                margin-bottom: 0;
            }
        }
    }

    &--mobile {
        max-width: initial;

        .stats-circle {
            .small {
                width: 64px !important;
                height: 64px !important;
            }
        }

        .title-wrapper {
            .title {
                .small {
                    width: 96px !important;
                    height: 24px !important;
                }
            }
        }

        .progress-header {
            border-bottom: none;
            padding: 16px;
        }

        .time-wrapper {
            gap: 8px;

            .time {
                .small {
                    width: 81px;
                    height: 22px;
                }
            }

            .time-block {
                .small {
                    width: 76px;
                    height: 28px;
                }
            }
        }
    }
}
</style>