<template>
    <div class="dotted-line">
        <span v-if="!onlyBars" class="text">{{ $t('new-course-plan.dotted-line.progress') }} <span class="progress">{{ calculateProgress }}</span></span>
        <div :class="['status-bar', { 'status-bar--elearning': elearning }]">
            <div v-for="(status, idx) in prepareData" :key="idx" :class="['info-tooltip', status, { 'info-tooltip--only-bars': onlyBars }]">
                <Tooltip v-if="status?.type !== 'new' && !isMobile" :text="handleText(status, idx)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/reusable/Tooltip';

export default {
    name: 'DottedLine',
    components: { Tooltip },
    props: {
        statuses: {
            type: Array,
            required: true
        },
        onlyBars: {
            type: Boolean,
            default: false
        },
        elearning: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['isMobile']),
        calculateProgress() {
            const countDoneExercises = this.prepareData.filter(status => ['completed', 'started'].includes(status)).length 

            return `${ countDoneExercises }/${ this.statuses.length }`
        },
        prepareData() {
            if (this.elearning) return this.statuses
            else return this.statuses.flatMap(el => Object.values(el));
        }
    },
    methods: {
        handleText(status, idx) {
            if (this.elearning) return `${ this.$i18n.t('elearning.course-plan.dotted-line.lesson') } ` + (idx + 1)
                + ' - '
                + this.$i18n.t(`elearning.course-plan.dotted-line.type.${ this.handleStatus(status, idx) }`);
            else return this.$i18n.t(`course-plan.dotted-line.${ Object.keys(this.statuses[idx])[0] }`)
                + ' - '
                + this.$i18n.t(`course-plan.dotted-line.type.${ status }`);
        },
        handleStatus(status, idx) {
            if (idx > 0 && this.statuses[idx - 1] !== 'completed') return 'lock'
            else return status
        },
    }
}
</script>

<style lang="scss" scoped>
.dotted-line {
    display: flex;
    align-items: center;
    gap: 12px;

    .text {
        @include font-small-12;
        color: $gray-700;
        font-weight: 500 !important;
    }

    .progress {
        margin-left: 4px;
    }

    .status-bar {
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 3px;

        :first-child {
            border-radius: 99px 0px 0px 99px;
        }

        :last-child {
            border-radius: 0px 99px 99px 0px;
        }

        &--elearning {
            gap: 4px;

            .info-tooltip {
                border-radius: 99px;
            }
        }

        .info-tooltip {
            width: 40px;
            height: 4px;
            background: $gray-200;
            position: relative;

            &--only-bars {
                width: 70px;
            }

            &:hover {
                .tooltip {
                    display: block;
                    border-radius: 4px;
                }
            }

            &.in_progress {
                background: $warning;
            }

            &.completed {
                background: $secondary;
            }

            &.overdue {
                background: $orange;
            }
        }
    }
}
</style>