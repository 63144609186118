<template>
    <div :class="['privacy-footer', {
        'privacy-footer--center': center, 
        'privacy-footer--blue': blue,
        'privacy-footer--small-padding': $route.name === 'tools'
    }]">
        <a :href="termsUrl" target="_blank">{{ $i18n.t('general.terms') }}</a>
        <a :href="privacyUrl" target="_blank">{{ $i18n.t('general.privacy') }}</a>
    </div>
</template>

<script>
import brandAppUrls from '@/mixins/brandAppUrls'

export default {
    name: 'PrivacyFooter',
    mixins: [brandAppUrls],
    props: {
        center: Boolean,
        blue: Boolean
    }
}
</script>

<style lang="scss" scoped>
.privacy-footer {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: auto;
    padding-bottom: 90px;

    @media(min-width: $mobile-tools) {
        padding-bottom: 24px;
    }

    &--center {
        justify-content: center;
    }

    a {
        @include font-medium;
        color: $gray-500;
        text-decoration: underline;
        transition: .25s all;

        &:hover {
            color: $gray-600;
        }
    }

    &--blue {
        padding-bottom: 0;
        
        a {
            color: $primary-dark;
            text-decoration: none;

            &:hover {
                color: $primary-dark;
            }
        }
    }
    &--small-padding {
        padding-bottom: 14px;
    }
}
</style>