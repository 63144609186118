<template>
    <label class="input range">
        <div class="ranges">
            <div v-for="n in data.max" :key="n" class="num" @click="changeRange(n)">{{n === data.max ? `${n}+` : n}}</div>
        </div>
        <input type="range" :id="data.name" :min="data.min" :max="data.max" :step="data.step || 1" :value="value" @input="onChange">
    </label>
</template>

<script>
export default {
    name: 'Range',
    props: ['data', 'value'],
    mounted() {
        this.setBar(this.value)
    },
    methods: {
        onChange($event) {
            this.setBar($event.target.value)
            this.$emit('input', $event.target.value)
        },
        setBar(current) {
            const part = 100/(this.data.max-1)
            document.getElementById(this.data.name).style.setProperty("--webkitProgressPercent", `${part*(current-1)}%`)
        },
        changeRange(val) {
            this.$emit('input', val)
            this.setBar(val)
        }
    }
}
</script>