import gql from 'graphql-tag'

export const dashboard = gql`
    {
        currentUser {
            firstName
            lastName
            student {
                studentBookableLessonsCount
                nextStudentLesson {
                    lesson {
                        uuid
                        startAt
                        stopAt
                        lessonSubjects {
                            subject
                        }
                        user {
                            uuid
                            firstName
                            lastName
                            avatarUrl
                        }
                        isTeacherSubstituted
                    }
                    legacyJoinLessonUrl
                    joinLessonUrl
                    rescheduleLessonUrl
                }
                lastStudentLesson {
                    lesson {
                        uuid
                        startAt
                        stopAt
                        lessonSubjects {
                            subject
                        }
                        user {
                            uuid
                            firstName
                            lastName
                            avatarUrl
                        }
                        materialLink {
                            url
                            materialUuid
                        }
                        feedbackStatus
                    }
                    lessonSurveyUrl
                }
                activeStudentGroups {
                    instantBookingUrl
                    studentPackageGroup {
                        quantity
                        left
                        validUntil
                        language {
                            shortName
                        }
                        duration
                    }
                    group {
                        uuid
                        user {
                            type
                            uuid
                            firstName
                            lastName
                            avatarUrl
                        }
                        type
                        language {
                            name
                            shortName
                        }
                        languageLevel {
                            shortName
                        }
                        messageGroup {
                            uuid
                        }
                        isTest
                        company {
                            name
                        }
                    }
                }
                children {
                    firstName
                    lastName
                    avatarUrl
                    uuid
                    student {
                        activeStudentGroups {
                            studentPackageGroup {
                                quantity
                                left
                                validUntil
                            }
                            group {
                                uuid
                                type
                                language {
                                    name
                                    shortName
                                }
                                languageLevel {
                                    shortName
                                }
                            }
                        }
                        studentPackagesGroups {
                            left
                            quantity
                            language {
                                name
                                shortName
                            }
                            type
                            duration
                        }
                    }
                }
                parents {
                    firstName
                    uuid
                }
                studentPackagesGroups {
                    left
                    quantity
                    language {
                        name
                        shortName
                    }
                    type
                    newOfferLink
                    isNewOfferLinkReady
                    duration
                }
                deviceSetup
                company {
                    name
                }
                placementTest {
                    status
                }
                oralPlacementTest {
                    status
                }
                progressTests {
                    uuid
                    status
                    validTo
                    testLength
                    link
                    isRecent
                }
                studentLanguageLevel
            }
        }
        elearningCourseLastLesson {
            lessonTitle
            coursePlanUuid
            materialUuid
            moduleIndex
            lessonIndex
            languageLevel {
                shortName
            }
        }
    }
`