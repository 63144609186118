<template>
    <div class="empty-slots">
        <img class="empty-slots__icon" src="@/assets/img/graduate-warning.svg" alt=""/>

        <p class="empty-slots__title">
            {{ isCalendarPreviewMode 
                ? $i18n.t('schedule-modal.preview-mode.empty-slots.title')
                : $i18n.t('schedule-modal.empty-slots.title')
            }}
        </p>

        <template v-if="!isCalendarPreviewMode && isOnePersonCourseSelected">
            <p class="empty-slots__desc">{{ $i18n.t('schedule-modal.empty-slots.text') }}</p>

            <button 
                @click="runSwitchLectorFlow"
                class="btn button empty-slots__btn"
            >
                <img src="@/assets/img/schedule/users.svg" alt=""/>
                {{ $i18n.t('schedule-modal.empty-slots.btn') }}
            </button>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ScheduleEmptySlots',
    computed: {
        ...mapGetters('schedule', [
            'isCalendarPreviewMode',
            'isOnePersonCourseSelected'
        ]),
    },
    methods: {
        async runSwitchLectorFlow() {
            this.$store.commit('schedule/setLoading', true);
            await this.$store.dispatch('schedule/runSwitchLectorFlow');
            this.$store.commit('schedule/setLoading', false);
        }
    }
}
</script>

<style lang="scss" scoped>
    .empty-slots {
        margin-top: 54px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .empty-slots__title {
        @include font-h2;
        color: $gray-900;
        text-align: center;
        margin-top: 18px;
    }

    .empty-slots__desc {
        @include font-medium-text;
        margin-top: 8px;
    }

    .empty-slots__btn {
        margin-top: 32px;
        border-radius: 6px;
        border: 1px solid $primary;
        background: $white;
        color: $primary-dark;
        display: flex;
        align-items: center;
    }
</style>