<template>
    <label
        class="input radio"
        :class="[getStatusClass, { 'active': isActive, 'promoted': data.promoted }]"
    >
        {{ data.label }}
        <input ref="radio" :name="data.name" type="radio" @change="onChange">
        <span class="checkmark"></span>
        <span v-if="data.promoted" class="promoted">{{ $t('onboarding.preferences.promoted') }}</span>
        <p v-if="isActive" class="dsc">{{ data.description }}</p>
    </label>
</template>

<script>
export default {
    name: 'Radio',
    props: ['data', 'value'],
    mounted() {
        this.$refs.radio.checked = this.isActive
    },
    computed: {
        isActive() {
            return this.value === this.data.radioValue
        },
        getStatusClass() {
            if(this.data.isCorrect === null || !this.data.isCompleted) return

            if(this.data.isCorrect && this.isActive) return 'correct'

            if(this.data.isCorrect && !this.isActive) return 'should-be-correct'

            if(!this.data.isCorrect && this.isActive) return 'incorrect'

            return null
        }
    },
    methods: {
        onChange() {
            this.$emit('input', this.data.radioValue)
        }
    }
}
</script>
