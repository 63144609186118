<template>
    <div class="third-party-login-btns">
        <div class="btn-wrapper">
            <FacebookLoginBtn @action="facebookLogin" class="hide-btn" thirdParty/>
            <img class="social-img" src="@/assets/img/social/facebook.svg" alt="fb" />
        </div>
        <div class="btn-wrapper">
            <GoogleLoginBtn :action="googleLogin" class="hide-btn" :abortGoogleTapFrame="register"/>
            <img class="social-img" src="@/assets/img/social/google.svg" alt="google" />
        </div>
        <div class="btn-wrapper">
            <AppleIntegrateBtn @action="appleLogin" @fail="appleLoginFail" class="hide-btn"/>
            <img class="social-img" src="@/assets/img/social/apple.svg" alt="apple" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { schoolV2 } from '@/plugins/axios';
import GoogleLoginBtn from '@/components/reusable/GoogleLoginBtn'
import AppleIntegrateBtn from '@/components/reusable/AppleIntegrateBtn'
import FacebookLoginBtn from '@/components/reusable/FacebookLoginBtn'
import loginRedirect from '@/mixins/loginRedirect'

export default {
    name: 'ThirdPartyLoginButtons',
    mixins: [loginRedirect],
    props: {
        login: {
            type: Boolean,
            default: false
        },
        register: {
            type: Boolean,
            default: false
        },
        areRequirementsChecked: {
            type: Boolean,
            default: false
        }
    },
    components: {
        GoogleLoginBtn,
        AppleIntegrateBtn,
        FacebookLoginBtn
    },
    mounted() {
        window.addEventListener('mobileSocialsAuth', (e) => this.mobileSocialsAuth(e));
    },
    beforeDestroy() {
        window.removeEventListener('mobileSocialsAuth', this.mobileSocialsAuth);
    },
    computed: {
        ...mapGetters(['isMobileApp', 'getFacebookLoginScope']),
        ...mapState(['isAndroidApp']),
    },
    methods: {
        mobileSocialsAuth(e) {
            const { accessToken, provider, firstName, lastName } = e.detail;


            if (this.login) this.externalConnect(accessToken, provider, firstName || null, lastName || null)
            else if (this.register) {
                this.handleRegister({
                    type: provider,
                    token: accessToken
                }, {
                    firstName,
                    lastName
                })
            }
        },
        async googleLogin(response) {
            if(!this.areRequirementsChecked && this.register) return

            if (this.login) await this.externalConnect(response.credential, 'google')
            else if (this.register) {
                this.handleRegister({
                    type: 'google',
                    token: response.credential
                })
            }
        },

        appleLogin(data) {
            if(!this.areRequirementsChecked && this.register) return

            const { token, type, student } = data;
            if (this.login) this.externalConnect(token, type, student?.firstName || null, student?.lastName || null)
            else if (this.register) {
                if(student) this.handleRegister({ type, token }, student)
                else this.handleRegister({ type, token });
            }
        },
        async facebookLogin(data) {
            if(!this.areRequirementsChecked && this.register) return

            const { token, type } = data;

            if (this.login) {
                await this.externalConnect(token, type);
                this.getFacebookLoginScope?.logout()
            }
            else if (this.register) this.handleRegister({ type, token });
        },
        appleLoginFail() {
            this.loginFail = this.$i18n.t('login-fail')
        },
        handleRegister(registrationData, studentData) {
            this.$emit('handleRegister', registrationData, studentData || {});
        },
        async externalConnect(externalToken, type, firstName, lastName) {
            let token = "";

            try {
                const res = await schoolV2.post('external_connect', {
                    externalToken,
                    integrationType: type,
                    confirmationType: this.$route.params.confirmationType,
                    token: this.$route.params.token
                })
                token = res.data.token
                this.handleDestinationRedirect('dashboard', token, type);
            } catch {
                if (!token) {
                    const registrationData = await schoolV2.post('register/student', {
                        registrationData: {
                            type,
                            token: externalToken
                        },
                        studentData: {
                            locale: this.$route.params.locale,
                            firstName,
                            lastName
                        }
                    })

                    this.handleDestinationRedirect('setAccount', registrationData.data.token, type);
                } else { this.loginFail = this.$i18n.t('login-wrong-account') }
            }
        },
        handleDestinationRedirect(name, token, type) {
            if (!this.isAndroidApp || (this.isAndroidApp && type !== 'apple')) {
                localStorage.setItem('authToken', token)

                if(name === 'dashboard') this.loginRedirect()
                else this.$router.push({ name })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .third-party-login-btns {
        display: flex;
        gap: 24px;

        .btn-wrapper {
            width: 100%;
            height: 44px;
            overflow: hidden;
            border-radius: 6px;
            border: 1px solid $gray-200;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: $gray-100;
            }

            .social-img {
                width: 24px;
            }
        }

        .hide-btn {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.0001;
        }
    }
</style>
