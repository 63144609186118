<template>
    <div ref="askAIToolScroll" class="ask-ai-content">
        <div v-if="!askAI.isDisabled" ref="askAiWidgetScroll" :class="['ask-ai-content__message-wrapper' , { 'ask-ai-content__message-wrapper--widget' : widgetMode }]">
            <div v-for="(message, idx) in askAI.messages" :class="['message-container', { 'message-container--user': message.node.author === 'USER', 'message-container--game': message.node.game }]" :key="idx">
                <div v-if="message.node.author === 'GPT'" :class="['message-container__ai-avatar avatar-style', { 'message-container__ai-avatar--game' : askAI.game }]">
                    <img src="@/assets/img/askAI/stars.svg" alt=""/>
                </div>
                <div class="message-block">
                    <div class="block">
                        <div class="message">
                            <span class="text" v-html="message.node.content"></span>
                            <AskAIGameMessage v-if="message.node.game" :game="message.node.game" />
                        </div>
                        <div
                            v-if="!askAI.game"
                            :class="['block__copy-btn', { 'block__copy-btn--hide' : message.node.author !== 'GPT' }]"
                            ref="copyWord"
                            @click="copyWord(idx)"
                        ></div>
                    </div>
                    <span v-if="message.node.createdAt" class="footer">{{ formatDate(message.node.createdAt) }}</span>
                </div>
                <img
                    v-if="message.node.author === 'USER'"
                    :src="setAvatar(getCurrentUserAvatar)"
                    class="message-container__user-avatar avatar-style"
                    width="32"
                    height="32"
                />
            </div>
            <div v-if="askAI.loading" class="loading-wrapper">
                <div class="dot-wrapper">
                    <div class="dot-typing dot-typing--active"></div>
                </div>
                <span class="loading-text">{{ $t('widget.gpt-chat.generating-answer') }}</span>
            </div>
        </div>
        <div v-else class="ask-ai-content__message-wrapper">
            <span class="info">{{ $t('widget.gpt-chat.disabled') }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import formatDateMixin from '@/mixins/formatDate'
import setAvatarMixin from '@/mixins/setAvatar';
import iconAnimation from '@/mixins/tools/iconAnimation';
import AskAIGameMessage from '@/components/tools/askAI/AskAIGameMessage'

export default {
    name: 'AskAIContent',
    mixins: [formatDateMixin, setAvatarMixin, iconAnimation],
    props: {
        widgetMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AskAIGameMessage
    },
    mounted() {
        this.scrollToBottom();
        this.$watch('askAI.messages', () => this.scrollToBottom());
    },
    methods: {
        scrollToBottom() {
            const el = this.widgetMode ? this.$refs.askAiWidgetScroll : this.$refs.askAIToolScroll;

            setTimeout(() => el.scrollTo(0, el.scrollHeight), 0);
        },
        copyWord(idx) {
            this.$gtag({
                category: 'tool',
                action: 'copy',
                label: 'askAI'
            })
            navigator.clipboard.writeText(this.$refs.copyWord[idx].parentNode.querySelector('.message').innerText);
            this.handleIconAnimation(this.$refs.copyWord[idx], 'block__copy-btn--shake', 750);
        }
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('tools', ['askAI']),
        ...mapGetters(['getCurrentUserAvatar'])
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-content {
    width: 100%;
    overflow-y: auto;
    flex: 1;

    .loading-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 10px;
        @include dot-typing;

        .dot-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $gray-100;
            width: 32px;
            height: 32px;
            border-radius: 56px;
        }

        .loading-text {
            color: $gray-500;
            @include font-small-12;
        }
    }

    .message-container {
        display: flex;
        gap: 16px;

        &:last-child {
            margin-bottom: 10px;
        }

        &--user {
            justify-content: flex-end;

            .message-block {
                .block {
                    display: flex;

                    .message {
                        &::before {
                            border: 0px;
                        }

                        &::after {
                            content: "";
                            width: 0px;
                            height: 0px;
                            position: absolute;
                            border-left: 4px solid $primary-light;
                            border-right: 4px solid transparent;
                            border-top: 6px solid transparent;
                            border-bottom: 6px solid $primary-light;
                            border-bottom-right-radius: 6px;
                            border-top-right-radius: 16px;
                            right: -8px;
                            bottom: 0px;
                        }

                        background: $primary-light;
                        border-radius: 10px 10px 0px 10px;
                        margin: 0 0 0 auto;
                    }
                }

                .footer {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        &--game {
            .message-block {
                .block {
                    .message {
                        background: $white;
                        border: 1px solid $gray-200;
                        border-radius: 12px;

                        &:after {
                            content: unset;
                        }
                    }
                }
            }
        }

        &__ai-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            width: 32px;
            height: 32px;
            background: $orange;
            margin-bottom: 16px;

            &--game {
                background: $primary;
            }
        }

        &__user-avatar {
            align-self: flex-end;
            margin-bottom: 16px;
        }
    }

    &__message-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        padding: 12px 16px 8px 16px;

        &--widget {
            max-height: 336px;
            min-height: 100px;
        }

        .info {
            display: flex;
            justify-content: center;
            margin-top: 10%;
            color: #808793;
            text-align: center;
            @include font-h2;
        }
    }

    .message-block {
        display: flex;
        flex-direction: column;
        flex: 1;

        @container tools (min-width: 560px) {
            max-width: 75%;
        }

        .block {
            display: flex;
            gap: 8px;

            .message {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                @include font-regular-text;
                font-size: 16px;
                padding: 16px;
                word-break: break-word;
                color: $gray-900;
                background: $orange-light;
                border-radius: 10px 10px 10px 0px;

                &::before {
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 4px solid transparent;
                    border-right: 4px solid $orange-light;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid $orange-light;
                    border-bottom-left-radius: 6px;
                    border-top-left-radius: 16px;
                    left: -8px;
                    bottom: 0px;
                }

                .text {
                    ::v-deep b, strong {
                        font-weight: 500;
                    }
                }

                ::v-deep .text > ol, ::v-deep .text > ul {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    
                    & > li {
                        margin-left: 32px;
                    }
                }

                ::v-deep .text > * {
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }


            &__copy-btn {
                @include toolsIcon('copy');
                background-size: 16px;
                width: 20px;
                height: 20px;
                flex-shrink: 0;

                &--hide {
                    display: none;
                }
            }
        }

        .footer {
            @include font-small-10;
            color: $gray-500;
            margin-right: auto;
            margin-top: 4px;

            &::before {
                content: none;
            }

            &::after {
                content: none;
            }
        }
    }
}
</style>