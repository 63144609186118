<template>
    <div class="learning__exercise">
        <transition-group :class="['learning-activity-content', { 'learning-activity-content--flashcards': isFlashcardsActivity }]" :name="slideTransition" tag="div">
            <div :key="1" v-if="isAdditionalSlide">
                <transition :name="slideTransition" mode="out-in">
                    <component :is="additionalSlideComponent"/>
                </transition>
            </div>

            <div class="learning-flashcards" :key="2" v-else-if="isFlashcardsActivity">
                <transition :name="slideTransition" mode="out-in">
                    <p v-if="!isMobile" class="learning-flashcards__exercise-title" :key="getExerciseTitle">{{ getExerciseTitle }}</p>
                </transition>
                <transition :name="slideTransition" mode="out-in">
                    <component :key="getCurrentSlide.uuid" :is="flashcardsComponent" />
                </transition>
            </div>
            
            <div :key="3" v-else>
                <transition :name="slideTransition" mode="out-in">
                    <LearningExerciseTitle 
                        v-if="title"
                        :key="getCurrentSlide.uuid"
                        :title="title"
                    />
                </transition>
                
                <transition :name="slideTransition" mode="out-in">
                    <div class="learning__media" :key="getCurrentSlide.uuid">
                        <component 
                            :is="mediaComponent"
                            :data="getCurrentMedia?.data" />
                    </div>
                </transition>

                <transition :name="slideTransition" mode="out-in">
                    <div
                        v-if="activityComponent"
                        :class="['learning__activity', { 'learning__activity--disabled': taskLoading || isTaskFinished }]" 
                        :key="getCurrentTask.uuid"
                    >
                        <component
                            :is="activityComponent"
                            :task="getCurrentTask" />
                        <ActivityScore v-if="activityScore" :data="activityScore" />
                    </div>
                </transition>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LearningExerciseTitle from '@/components/learning/LearningExerciseTitle';

// media
import Gallery from '@/components/learning/media/Gallery';
import TextListening from '@/components/learning/media/TextListening';
import Video from '@/components/learning/media/Video';
import Audio from '@/components/learning/media/Audio';

// activities
import SingleChoice from '@/components/learning/activity/SingleChoice';
import MultipleChoice from '@/components/learning/activity/MultipleChoice';
import GapsEmptySpacesBrackets from '@/components/learning/activity/GapsEmptySpacesBrackets';
import GapsWordList from '@/components/learning/activity/GapsWordList';
import OpenEnded from '@/components/learning/activity/OpenEnded';
import RightOrder from '@/components/learning/activity/RightOrder';
import BulletPoints from '@/components/learning/activity/BulletPoints';
import ActivityScore from '@/components/learning/activity/ActivityScore';

//flashcards
import FlashcardsExercise from '@/components/flashcards/FlashcardsExercise'
import FlashcardsChoice from '@/components/flashcards/FlashcardsChoice'
import FlashcardsInput from '@/components/flashcards/FlashcardsInput'
import FlashcardsOrder from '@/components/flashcards/FlashcardsOrder'

// additional slides
import Goals from '@/components/learning/additionalSlides/Goals';
import Summary from '@/components/learning/additionalSlides/Summary'

import { ADDITIONAL_SLIDES } from '@/constants/learning';

export default {
    name: 'LearningExercise',
    props: ['data'],
    components: {
        LearningExerciseTitle,
        ActivityScore
    },
    computed: {
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapState('learning', ['slideTransition', 'taskLoading']),
        ...mapGetters('learning', ['getCurrentSlide', 'getCurrentSlideType', 'getCurrentTask','getCurrentMedia', 'getCurrentInstruction', 'isTaskFinished', 'isAdditionalSlide', 'isFlashcardsActivity']),
        ...mapGetters('flashcards', ['getCurrentExercise', 'isDefinitionExercise', 'isMobile']),
        title() {
            if (this.getCurrentSlideType === ADDITIONAL_SLIDES.GOALS) return;
            return this.getCurrentInstruction
        },
        mediaComponent() {
            if (!this.getCurrentMedia) return null;

            const mapper = {
                TEXT: TextListening,
                AUDIO: Audio,
                TEXT_TO_SPEECH: Audio,
                VIDEO: Video,
                GALLERY: Gallery,
            };

            return mapper[this.getCurrentMedia.type];
        },
        activityComponent() {
            const mapper = {
                single_choice: SingleChoice,
                true_false: SingleChoice,
                multiple_choice: MultipleChoice,
                gaps_words_in_brackets: GapsEmptySpacesBrackets,
                gaps_empty_spaces: GapsEmptySpacesBrackets,
                gaps_word_list: GapsWordList,
                open_ended: OpenEnded,
                right_order: RightOrder,
                bullet_points: this.getCurrentSlideType === ADDITIONAL_SLIDES.GOALS ? null : BulletPoints
            };

            return mapper[this.getCurrentSlide.activity?.type];
        },
        additionalSlideComponent() {
            const mapper = {
                [ADDITIONAL_SLIDES.GOALS]: Goals,
                [ADDITIONAL_SLIDES.ITERATION_SUMMARY]: Summary
            };

            return mapper[this.getCurrentSlideType];
        },
        flashcardsComponent() {
            const mapper = {
                CHOICE: FlashcardsChoice,
                INPUT: FlashcardsInput,
                ORDER: FlashcardsOrder,
                DEFINITION: FlashcardsExercise
            }

            return mapper[this.getCurrentExercise.type]
        },
        activityScore() {
            return sessionStorage.getItem('learningPreview') && this.getCurrentTask.result?.data?.score
        },
        getExerciseTitle() {
            if (this.getCurrentExercise?.type === 'DEFINITION') return this.getCurrentExercise?.english.split(' ').length === 1
                ? this.$i18n.t('flashcards.exercise.title.definition.word', this.transUi)
                : this.$i18n.t('flashcards.exercise.title.definition.expression', this.transUi)

            return this.$i18n.t(`flashcards.exercise.title.${ this.getCurrentExercise.type.toLowerCase() }`, this.transUi)
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/includes/_learning-slide-animations.scss";

.learning__exercise {
    flex: 1;
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 732px;

    > div,
    > div > div {
        width: 100%;
    }

    ::v-deep {
        > div > .learning-slide-right-leave-active,
        > div > .learning-slide-left-leave-active {
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.learning__media {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 16px;

    &:not(:has(div)) {
        display: none;
    }
}

.learning__activity {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--disabled {
        pointer-events: none;
    }
}

.words {
    padding: 0;
}

.flashcards__exercise, .flashcards__input, .flashcards__choice, .flashcards__order {
    width: 100%;
}

.learning-activity-content {
    &--flashcards {
        display: flex;
        flex: 1;
        max-width: 564px;
    }
}

.learning-flashcards {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__exercise-title {
        @include font-h2;
        color: $gray-900;
        margin-bottom: 24px;
        text-align: center;
    }
}
</style>
