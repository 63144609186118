<template>
    <div class="api-error">
        <div class="icon">
            <img src="@/assets/img/error-icon.svg" alt="">
        </div>
        <p class="text">{{text}}</p>
    </div>
</template>

<script>
export default {
    name: 'ApiError',
    props: ['text']
}
</script>
