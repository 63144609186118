<template>
    <div class="navbar-time" v-if="currentTime > 0">
        <div class="navbar-time__clock">
            <img src="@/assets/img/clock.svg" alt=""> {{ formattedTime }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBarTime',
    props: ['time'],
    data() {
        return {
            currentTime: this.time,
            interval: null
        }
    },
    computed: {
        formattedTime() {
            const time = this.currentTime

            const timeObject = {
                hours: Math.floor(time / 3600),
                minutes: Math.floor(time % 3600 / 60),
                seconds: Math.round(time % 3600 % 60)
            }
                
            for (const [key, value] of Object.entries(timeObject)) {
                if(value < 10) timeObject[key] = '0' + value
            }

            return `${timeObject.hours}:${timeObject.minutes}:${timeObject.seconds}`
        },
    },
    methods: {
        run() {
            let lastTime = Date.now();

            this.interval = setInterval(() => {
                const currentTime = Date.now();
                const deltaTime = currentTime - lastTime;

                const deltaSeconds = deltaTime / 1000;
                this.currentTime -= deltaSeconds;

                lastTime = currentTime;

                if (this.currentTime <= 0) {
                    this.stop();
                    this.$emit('timeEnded');
                }
            }, 1000);
        },
        stop() {
            clearInterval(this.interval);
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-time {
    display: flex;
    align-items: center;
    gap: 16px;

    &__clock {
        padding: 6px 10px 6px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        @include font-regular;
        color: $gray-900;
        cursor: default;
        border-radius: 20px;
        border: 2px solid $gray-300;
    }
}
</style>