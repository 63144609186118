<template>
    <div class="progress-test-summary">
        <img src="@/assets/img/progressTest/progress-test.svg" alt="">
        <p class="progress-test-summary__title">{{ $i18n.t('progress-test.summary.title') }}</p>
        <p class="progress-test-summary__subtitle">{{ $i18n.t('progress-test.summary.subtitle') }}</p>
        <div class="progress-test-summary__block">
            <div class="chart-content">
                <Gauge :value="data.result.score"/>
                <div class="stats">
                    <span class="stats__text">{{ $i18n.t('progress-test.summary.result-for-level') }}</span>
                    <span class="stats__level">{{ data.languageLevel.shortName }}</span>
                    <span :class="['stats__comment', `stats__comment--${ scoreDescription }`]">
                        {{ $i18n.t(`progress-test.summary.${ scoreDescription }-result`) }}
                    </span>
                </div>
            </div>
            <div class="progress-bars">
                <div class="frequency">
                    <div v-for="item in data.result.entries" :key="item.type" class="frequency__item">
                        <div class="info">
                            <p class="label">{{ $i18n.t(`progress-test.summary.${ item.type.toLowerCase() }`) }}</p>
                            <div class="bar">
                                <span
                                    class="bar-inner"
                                    :style="{ width: `${ item.value / item.maxValue * 100 }%` }"
                                ></span>
                            </div>
                        </div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="data.result.knowledge" class="progress-test-summary__block">
            <div class="content">
                <span class="content__title">{{ $i18n.t('progress-test.summary.knowledge') }}</span>
                <div class="content__text" v-html="data.result.knowledge"></div>
            </div>
        </div>
        <div v-if="data.result.recommendations" class="progress-test-summary__block">
            <div class="content">
                <span class="content__title">{{ $i18n.t('progress-test.summary.recommendations') }}</span>
                <div class="content__text" v-html="data.result.recommendations"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Gauge from '@/components/reusable/Gauge';

export default {
    name: 'ProgressTestSummary',
    props: ['data'],
    components: { Gauge },
    computed: {
        scoreDescription() {
            const score = this.data.result.score

            if(score < 66) return 'modest'
            else if(score < 77) return 'good'
            else if(score < 91) return 'outstanding'

            return 'expert-level'
        }
    }
}
</script>
<style lang="scss" scoped>
$mobile: 768px;

.progress-test-summary {
    width: 608px;
    max-width: 100%;
    margin: auto;
    background: $white;
    margin-top: -80px;
    padding-top: 80px;
    background-image: url('~@/assets/img/progressTest/confetti1.svg');
    background-repeat: no-repeat;
    background-position: 0 42px;

    @media(min-width: $mobile) {
        background-position: 0 -7px;
    }

    &__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-top: 8px;
    }

    &__subtitle {
        @include font-regular;
        color: $gray-600;
        margin: 8px 0 40px;
    }

    &__block {
        border: 2px solid $gray-200;
        border-radius: 10px;
        text-align: left;
        margin-top: 16px;

        .chart-content {
            padding: 16px;
            border-bottom: 1px solid $gray-200;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            width: 100%;

            @media(min-width: $mobile) {
                flex-direction: row;
                gap: 17%;
                padding: 32px 24px;
            }

            .stats {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;

                &__text {
                    @include font-regular;
                    color: $gray-600;
                }

                &__level {
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 40px;
                    color: $gray-900;
                }

                &__comment {
                    border-radius: 4px;
                    padding: 4px 8px;
                    @include font-h6-uc;

                    &--modest {
                        background: $warning-light;
                        color: $warning-dark;
                    }

                    &--good, &--outstanding, &--expert-level {
                        background: $secondary-light;
                        color: $secondary-dark;
                    }
                }
            }
        }

        .progress-bars {
            padding: 16px;
            width: 100%;

            @media(min-width: $mobile) {
                padding: 24px;
            }

            .frequency {
                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;

                    &:not(:first-of-type) {
                        margin-top: 16px;
                    }
            
                    .info {
                        flex: 1;
            
                        .label {
                            @include font-medium;
                            color: $gray-900;
                            margin-bottom: 8px;
                            display: flex;
                            align-items: center;
                            padding: 0;
                        }
            
                        .bar {
                            height: 6px;
                            background: $gray-200;
                            border-radius: 16px;
                            max-width: 400px;
            
                            span {
                                display: block;
                                height: 100%;
                                background: $secondary;
                                border-radius: 16px;
                            }
                        }
                    }
            
                    .value {
                        width: 32px;
                        height: 32px;
                        background: $gray-100;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include font-h4;
                        color: $gray-900;
                    }
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 16px;
            gap: 8px;

            @media(min-width: $mobile) {
                padding: 24px;
            }

            &__title {
                @include font-h3;
                color: $gray-900;
            }

            &__text {
                @include font-regular-text;
                color: $gray-900;

                ::v-deep {
                    p:not(:last-child) {
                        margin-bottom: 24px;
                    }

                    a {
                        color: $primary-dark;
                        font-weight: 500;
                        word-break: break-all;
                    }
                }
            }
        }
    }
}
</style>
