<template>
    <div
        :class="[
            'list-pagination-page',
            { 'list-pagination-page--active': currentPage === page, 'list-pagination-page--disabled': !isNumber }
        ]"
        @click="changePage"
    >
        {{ page }}
    </div>
</template>

<script>
export default {
    name: 'ListPaginationPage',
    props: {
        page: [Number, String],
        currentPage: Number
    },
    computed: {
        isNumber() {
            return typeof this.page === 'number'
        }
    },
    methods: {
        changePage() {
            if(this.isNumber) this.$emit('changePage', this.page)
        }
    }
}
</script>

<style lang="scss" scoped>
.list-pagination-page {
    cursor: pointer;
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-600;

    &--active {
        color: $gray-900;
        font-weight: 600;
    }

    &--disabled {
        pointer-events: none;
    }
}
</style>