import { mapState } from 'vuex'

export default {
    data() {
        return {
            termsUrls: {
                en: 'en/terms',
                cz: 'cz/podminky',
                es: 'es/terminos',
                hu: 'hu/kifejezesek',
                pl: 'pl/regulamin',
                pt: 'pt/termos',
                sk: 'sk/podmienky',
                ua: 'ua/statut',
                ro: 'ro/reglementari',
                bg: 'bg/pravila'
            },
            privacyUrls: {
                en: 'en/privacy',
                cz: 'cz/zasady-ochrany-osobnich-udaju',
                es: 'es/privacidad',
                hu: 'hu/adatvedelem',
                pl: 'pl/polityka-prywatnosci',
                pt: 'pt/privacidade',
                sk: 'sk/zasady-ochrany-osobnych-udajov',
                ua: 'ua/polityka-konfidentsiynosti',
                ro: 'ro/confidentialitate',
                bg: 'bg/politika-za-poveritelnost'
            },
            startUrls: {
                en: 'en/start-test',
                cz: 'cz/zacit',
                es: 'es/iniciar',
                hu: 'hu/kezdes',
                pl: 'pl/rozpocznij',
                pt: 'pt/inicio',
                sk: 'sk/zacat',
                ua: 'ua/pochaty',
                ro: 'ro/incepe',
                bg: 'bg/nachalo'
            },
            checkoutUrls: {
                en: 'en/pricing',
                cz: 'cz/cenik',
                es: 'es/precios',
                hu: 'hu/arak',
                pl: 'pl/cennik',
                pt: 'pt/preços',
                sk: 'sk/cennik',
                ua: 'ua/prays-lyst',
                ro: 'ro/preturi',
                bg: 'bg/tsenova-lista'
            },
            checkoutIndividualOffers: {
                en: 'en/start',
                cz: 'cz/checkout/offer/869d1d3abf3e63de4fde37e55a7a5397',
                es: 'es/checkout/offer/5159ed8c00b7fdd84361174c28a4090c',
                hu: 'hu/checkout/offer/59b07350afb3539bd0726649768a84ee',
                pl: 'pl/checkout/offer/defc7025846582b32c257d4ef590598e',
                pt: 'pt/checkout/offer/6adb8a2616edd1e77b2433b87a66c8e1',
                sk: 'sk/checkout/offer/1fc1ba81ca9714435ff7546a7a5b7065',
                ua: 'ua/pochaty',
                ro: 'ro/preturi',
                bg: 'bg/tsenova-lista'
            },
            kidsCheckoutUrls: {
                en: 'en/start/english-for-kids',
                cz: 'cz/zacit/anglictina-pro-deti-a-mladez-online',
                es: 'es/iniciar/ninos-y-adolescentes',
                hu: 'hu/kezdes/angol-gyerekeknek',
                pl: 'pl/rozpocznij/angielski-dla-dzieci-i-mlodziezy',
                pt: 'pt/inicio/ingles-para-criancas',
                sk: 'sk/zacat/anglictina-pre-deti-a-mladez-online',
                ua: 'ua/start/angliska-mova-dlya-ditey-i-pidlitkiv',
                ro: 'ro/engleza-pentru-copii',
                bg: 'bg/angliyski-za-deca'
            },

            brandAppUrl: process.env.VUE_APP_BRANDAPP_URL
        }
    },
    computed: {
        ...mapState(['isIOSApp']),
        termsUrl() {
            return `${ this.brandAppUrl }${ this.termsUrls[this.$route.params.locale] || this.termsUrls.en }`
        },
        privacyUrl() {
            return `${ this.brandAppUrl }${ this.privacyUrls[this.$route.params.locale] || this.privacyUrls.en }`
        },
        checkoutUrl() {
            if (this.isIOSApp) return `${ this.brandAppUrl }${ this.startUrls[this.$route.params.locale] || this.startUrls.en }`

            return `${ this.brandAppUrl }${ this.checkoutUrls[this.$route.params.locale] || this.checkoutUrls.en }`
        },
        checkoutIndOfferUrl() {
            if (this.isIOSApp) {
                if (this.checkoutIndividualOffers[this.$route.params.locale].includes('checkout')) return `${ this.brandAppUrl }${ this.checkoutIndividualOffers[this.$route.params.locale] }`
                else return `${ this.brandAppUrl }${ this.startUrls[this.$route.params.locale] || this.startUrls.en }`
            }

            return `${ this.brandAppUrl }${ this.checkoutIndividualOffers[this.$route.params.locale] || this.checkoutIndividualOffers.en }`
        },
        kidsUrl() {
            if (this.isIOSApp) return `${ this.brandAppUrl }${ this.startUrls[this.$route.params.locale] || this.startUrls.en }`

            return `${ this.brandAppUrl }${ this.kidsCheckoutUrls[this.$route.params.locale] || this.kidsCheckoutUrls.en }`
        },
        startUrl() {
            return `${ this.brandAppUrl }${ this.startUrls[this.$route.params.locale] || this.startUrls.en }`
        }
    }
}