<template>
    <div 
        :class="['translator__inputs', { 'translator__inputs--mobile': mobileInputs }]" 
        spellcheck="false"
    >
        <div 
            :class="[
                'translator__input-wrapper',
                'translator__input-wrapper--source',
                { 
                    'translator__input-wrapper--active': sourceActive,
                    'translator__input-wrapper--danger': isOverTranslatorLimit
                }
            ]"
        >
            <TranslatorLangs v-if="translator.showSourceLangs" type="source_lang"/>

            <ToolClear v-if="translator.sourceText" class="clear-btn--translator" @click.native="clearTranslatedText" />

            <textarea
                ref="sourceInput"
                @input="sourceInputHandle"
                @focus="sourceActive = true"
                @blur="sourceActive = false" 
                @scroll="handleSourceScroll"
                class="translator__input" 
                :placeholder="$i18n.t('tools.translator.type-to-translate')"
                :value="translator.sourceText"
            >
            </textarea>
            <p :class="['translator__source-limit', { 'translator__source-limit--danger': isOverTranslatorLimit }]">
                {{ translatorCharacters }} / {{ translator.charactersLimit }}
            </p>
        </div>
        <div 
            :class="[
                'translator__input-wrapper',
                'translator__input-wrapper--target',
                { 
                    'translator__input-wrapper--active': targetActive,
                    'translator__input-wrapper--left-border': sourceActive,
                    'translator__input-wrapper--left-border-danger': isOverTranslatorLimit
                }
            ]"
        >
            <TranslatorLangs v-if="translator.showTargetLangs" type="target_lang"/>
            <Skeleton v-if="showSkeleton" class="translator__skeleton"/>
            <textarea
                ref="targetInput" 
                @focus="targetActive = true"
                @blur="targetActive = false" 
                class="translator__input"
                @input="targetInputHandle"
                :value="translator.targetText"
                @scroll="handleTargetScroll"
                :disabled="isMobileDevice"
            >
            </textarea>
            <div
                ref="copyTargetText" 
                class="translator__copy" 
                @click="copyTargetText"
            >
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TranslatorLangs from './TranslatorLangs.vue'
import Skeleton from './Skeleton.vue'
import ToolClear from '@/components/tools/ToolClear.vue'
import iconAnimation from '@/mixins/tools/iconAnimation'

export default {
    name: 'TranslatorInputs',
    components: {
        TranslatorLangs,
        Skeleton,
        ToolClear
    },
    mixins: [iconAnimation],
    data() {
        return {
            timeout: null,
            sourceActive: false,
            targetActive: false,
            sourceLatScroll: 0,
            targetLastScroll: 0,
            preventScroll: false,
            duringTranslation: false,
            mobileInputs: false
        }
    },
    mounted() {
        if(this.$route.name === 'tools' && this.isMobileDevice) visualViewport.addEventListener('resize', this.handleVisualViewport)
        
        this.focusSourceInput()
    },
    beforeDestroy() {
        visualViewport.removeEventListener('resize', this.handleVisualViewport)
    },
    computed: {
        ...mapState(['isMobileDevice']),
        ...mapState('tools', ['translator']),
        ...mapGetters('tools', ['translatorCharacters', 'isOverTranslatorLimit']),
        showSkeleton() {
            return this.duringTranslation && !this.translator.targetText.length
        }
    },
    methods: {
        handleVisualViewport() {
            this.mobileInputs = visualViewport.height < 500 
        },
        sourceInputHandle(event) {
            this.duringTranslation = true
            this.$store.commit('tools/setSourceText', event.target.value)

            clearTimeout(this.timeout)

            this.timeout = setTimeout(async () => {
                await this.$store.dispatch('tools/translate')

                if (this.$refs.targetInput?.scrollTop) this.$refs.targetInput.scrollTop = this.$refs.sourceInput.scrollTop
                this.duringTranslation = false
            }, 1000)
        },
        targetInputHandle(event) {
            this.$store.commit('tools/setTargetText', event.target.value)
        },
        focusSourceInput() {
            this.$refs.sourceInput.focus()
        },
        copyTargetText() {
            const value = this.$refs.targetInput.value.replace(/\n/g,' ')
            
            navigator.clipboard.writeText(value)

            this.handleIconAnimation(this.$refs.copyTargetText, 'translator__copy--shake', 750)

            this.$gtag({
                category: 'tool',
                action: 'copy',
                label: 'translator'
            })
        },
        handleSourceScroll() {
            if(this.preventScroll) {
                this.preventScroll = false
                return
            }

            const scrollTop = this.$refs.sourceInput.scrollTop

            if(scrollTop !== this.sourceLatScroll) {
                this.preventScroll = true
                this.$refs.targetInput.scrollTop = scrollTop
                this.sourceLatScroll = scrollTop
            }
        },
        handleTargetScroll() {
            if(this.preventScroll) {
                this.preventScroll = false
                return
            }

            const scrollTop = this.$refs.targetInput.scrollTop

            if(scrollTop !== this.targetLastScroll) {
                this.preventScroll = true
                this.$refs.sourceInput.scrollTop = scrollTop
                this.targetLastScroll = scrollTop
            }
        },
        clearTranslatedText() {
            this.$store.commit('tools/setSourceText', '');
            this.$store.commit('tools/setTargetText', '');
            this.focusSourceInput();
        }
    }
}
</script>

<style lang="scss" scoped>
.translator {
    &__inputs {
        flex: 1;
        position: relative;
        display: flex;

        &--mobile {
            flex: 0 1 auto;
            height: 325px;
        }
    }

    &__input-wrapper {
        flex: 1;
        resize: none;
        display: flex;
        flex-direction: column;
        border: 1.5px solid $gray-200;
        overflow: hidden;
        transition: border-color .5s;
        position: relative;
        padding-bottom: 64px;

        &--source {
            border-right-width: 0;
            border-bottom-left-radius: 10px;
        }

        &--target{
            border-bottom-right-radius: 10px;
        }

        &--active {
            border-color: $primary;
        }

        &--left-border {
            border-left-color: $primary;
        }

        &--left-border-danger {
            border-left-color: $danger;
        }

        &--danger {
            border-color: $danger;
        }
    }

    &__skeleton {
        position: absolute;
        left: 24px;
        top: 30px;
        width: calc(100% - 24px);
    }

    &__input {
        flex: 1;
        width: 100%;
        resize: none;
        padding: 24px 35px 0 24px;
        outline: none;
        @include font-lead;
        border: 0;
        background: $white;
        color: $gray-900;

        &::placeholder {
            color: $gray-600;
        }

        &:disabled {
            background: $white;
            color: $gray-900;
            -webkit-text-fill-color: $gray-900;
            opacity: 1;
        }
    }

    &__source-limit {
        position: absolute;
        right: 18px;
        bottom: 18px;
        color: $gray-600;
        transition: all .5s;

        &--danger {
            color: $danger;
        }
    }

    &__copy {
        @include toolsIcon('copy');
        position: absolute;
        right: 8px;
        bottom: 12px;
    }
}

.clear-btn--translator {
    position: absolute;
    right: 10px;
    top: 17px;
    
    @media(min-width: $mobile-tools) {
        top: 10px;
    }
}
</style>