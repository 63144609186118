<template>
    <div :class="['ask-ai', { 'ask-ai--widget' : widgetMode, 'ask-ai--active' : isToolsPage }]">
        <template v-if="askAI.ready">
            <AskAIGameHeader v-if="askAI.game" />
            <AskAIContent :widgetMode="widgetMode"/>
            <AskAISearch :widgetMode="widgetMode"/>
        </template>
        <Loading v-else :size="30" />
    </div>
</template>

<script>
import AskAISearch from '@/components/tools/askAI/AskAISearch';
import AskAIContent from '@/components/tools/askAI/AskAIContent';
import AskAIGameHeader from '@/components/tools/askAI/AskAIGameHeader';
import Loading from '@/components/reusable/Loading';
import { mapState } from 'vuex';

export default {
    name: 'AskAI',
    props: {
        widgetMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AskAISearch,
        AskAIContent,
        AskAIGameHeader,
        Loading
    },
    async created() {
        if(!this.askAI.ready) await this.$store.dispatch('tools/getTeacherAI')
    },
    computed: {
        ...mapState('tools', ['askAI']),
        isToolsPage() {
            return this.$route.name === 'tools';
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: 12px;
    border-top: 1px solid $gray-200;

    &--active {
        @media(max-width: 991px) {
            height: calc(var(--vh) - 50px - 56px);
        }

        @media(min-width: 992px) and (max-width: 1199px) {
            height: calc(var(--vh) - 64px - 28px - 30px - 56px);
        }

        @media(min-width: 1200px) {
            height: calc(var(--vh) - 64px - 32px - 44px - 56px);
        }
    }

    &--widget {
        position: static;
        transform: none;
        z-index: 0;
        border-radius: 10px;
        border: 0;
        box-shadow: none;
        background: $white;
    }

    .loading {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>