const FLASHCARDS_STATES = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    PRACTICE: 'PRACTICE'
}

const FLASHCARDS_EXERCISES_STATES = {
    NEW: 'NEW',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED'
}

const FLASHCARDS_TYPES = {
    LEARNING: 'flashcards-learning',
    PRACTICE: 'flashcards-practice'
}

const FLASHCARDS_EXERCISES = {
    DEFINITION: 'DEFINITION',
    CHOICE: 'CHOICE',
    INPUT: 'INPUT',
    ORDER: 'ORDER'
}

const FLASHCARDS_AUDIO_PREFIX = 'LISTENING_'

export {
    FLASHCARDS_STATES,
    FLASHCARDS_EXERCISES_STATES,
    FLASHCARDS_TYPES,
    FLASHCARDS_EXERCISES,
    FLASHCARDS_AUDIO_PREFIX
}