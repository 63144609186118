<template>
    <div class="true-false-exercise">
        <span v-if="task.data.question" class="title" v-html="handleText(task.data.question)"></span>
        <div class="true-false-exercise__container">
            <RadioBtn
                v-for="(exercise, idx) in task.data.answers"
                :key="idx"
                @clickBtn="clickBtn"
                :exerciseData="exercise"
                :chosenUuid="chosenUuid"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RadioBtn from '@/components/learning/activity/components/RadioBtn';
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'SingleChoice',
    props: ['task'],
    mixins: [handleBoldedText],
    components: { RadioBtn },
    data() {
        return {
            chosenUuid: ''
        };
    },
    created() {
        if(this.isPreviewMode) this.setCorrectAnswersFromStart();
    },
    computed: {
        ...mapGetters('learning', ['isPreviewMode']),
    },
    methods: {
        clickBtn(uuid) {
            this.chosenUuid = uuid;

            this.$store.commit('learning/setUserAnswer', this.chosenUuid);
        },
        setCorrectAnswersFromStart() {
            if (!this.task.result?.isCorrect) return;

            this.chosenUuid = this.task.result.data;
        }

    }
};
</script>

<style lang="scss" scoped>
.true-false-exercise {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
        @include font-lead;
        margin: 0 0 14px 8px;
        line-height: 25px;
        letter-spacing: -0.4px;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 12px;

        @media(min-width: $mobile-learning) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
</style>
