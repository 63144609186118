import gql from 'graphql-tag'

export const activeStudentGroups = gql`
    query($includePending: Boolean) {
        currentUser {
            student {
                activeStudentGroups {
                    nextLesson(includePending: $includePending) {
                        uuid
                        startAt
                        status
                    }
                    lastLesson {
                        startAt
                    }
                    group {
                        uuid
                        type
                        user {
                            firstName
                            avatarUrl
                        }
                        company {
                            name
                        }
                    }
                    instantBookingUrl
                    studentPackageGroup {
                        uuid
                        left
                        language {
                            name
                            shortName
                        }
                        type
                        duration
                    }
                }
            }
        }
    }
`