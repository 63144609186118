<template>
    <div>
        <template v-if="!loading">
            <MobileAppWidget v-if="isDesktop && !isMobileAppInstalled" @hide="isMobileAppWidgetHide = true"/>
            <SmartLearning v-if="showSmartLearning" />

            <MeetStudentSpace v-if="unFinishedGuides.length && $route.params.locale === 'pl'" :unFinishedGuides="unFinishedGuides"/>

            <template v-if="activeStudentGroups.length">
                <Lector v-for="item in activeStudentGroups" :key="item.group.messageGroup.uuid" :data="item"/>
            </template>
            <SmartLearningLastLesson v-if="lastLearningLesson" :data="lastLearningLesson"/>
            <div
                v-if="showPodcast || showBlog"
                class="podcast-info"
            >
                {{ $i18n.t('dashboard.may-be-interested') }}
            </div>
            <Podcast v-if="showPodcast && podcastLoading" :data="podcastData"/>
            <Blog v-if="showBlog && blogLoading" :data="blogData"/>
        </template>
        <template v-else>
            <Skeleton type="lector"/>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Lector from './widgets/Lector'
import Skeleton from './widgets/Skeleton'
import Podcast from './widgets/Podcast.vue'
import Blog from './widgets/Blog.vue'
import MobileAppWidget from './widgets/MobileAppWidget'
import SmartLearning from './widgets/SmartLearning'
import SmartLearningLastLesson from './widgets/SmartLearningLastLesson'
import MeetStudentSpace from '@/components/dashboard/widgets/MeetStudentSpace'

export default {
    name: 'Column3',
    props: ['activeStudentGroups', 'loading', 'unFinishedGuides', 'lastLearningLesson'],
    components: {
        Lector,
        Skeleton,
        Blog,
        Podcast,
        MobileAppWidget,
        SmartLearning,
        SmartLearningLastLesson,
        MeetStudentSpace
    },
    data() {
        return {
            showPodcast: this.$route.params.locale === 'pl',
            showBlog: ['pl'].includes(this.$route.params.locale),
            blogData: '',
            podcastData: '',
            blogLoading: false,
            podcastLoading: false,
            isMobileAppWidgetHide: false
        }
    },
    mounted() {
        if (this.showBlog) {
            fetch('https://insider.fluentbe.com/feed/')
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => { this.blogData = data })
            .then(() => this.blogLoading = true)
            .catch(() => this.showBlog = false)
        }

        if (this.showPodcast) {
            fetch('https://feeds.buzzsprout.com/1972349.rss')
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => { this.podcastData = data })
            .then(() => this.podcastLoading = true)
            .catch(() => this.showPodcast = false);
        }
    },
    computed: {
        ...mapState(['windowWidth']),
        ...mapGetters(['isMobileAppInstalled']),
        isDesktop() {
            return this.windowWidth >= 768
        },
        showSmartLearning() {
            if(!this.isDesktop || this.lastLearningLesson) return

            if(this.isMobileAppInstalled) return true

            return this.isMobileAppWidgetHide
        }
    }
}
</script>

<style lang="scss" scoped>
.podcast-info {
    @include font-medium;
    color: $gray-500;
    margin-bottom: 8px;
    text-transform: uppercase;
}
</style>
