<template>
    <div class="schedule-preview schedule">
        <ScheduleRoot />
    </div>
</template>

<script>
import ScheduleRoot from '@/components/schedule/ScheduleRoot';
import { SCHEDULE_MODE } from '@/constants/schedule';

export default {
    name: 'SchedulePreviews',
    components: { 
        ScheduleRoot,
    },
    created() {
        this.$store.commit('schedule/setApiUrl', this.apiUrl);
    },
    mounted() {
        this.$store.commit('schedule/setMode', { name: SCHEDULE_MODE.TEACHER_PREVIEW });
    },
    computed: {
        apiUrl () {
            const userUuid = this.$route.params.userUuid;
            return `booking/lesson/slots/preview-student/preview-group/${userUuid}`;
        },
    }
}
</script>
