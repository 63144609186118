<template>
    <div :class="['placement-test-info skeleton', { 'placement-test-info--mobile': isMobile }]">
        <div class="icon">
            <div class="small dark"></div>
        </div>
        <div class="placement-test-info__text-wrapper">
            <div class="title">
                <div class="small dark"></div>
            </div>
            <div class="subtitle">
                <div class="small dark"></div>
            </div>
            <div class="button">
                <div class="small dark"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PlacementTestSkeleton',
    computed: { ...mapState(['isMobile']) },
}
</script>

<style lang="scss" scoped>
.placement-test-info {
    display: flex;
    padding: 24px;
    gap: 24px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    background-color: $white;
    width: 100%;

    .icon {
        align-self: flex-start;

        .small {
            width: 64px;
            height: 64px;
            margin-bottom: 0;
            border-radius: 8px;
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
            margin-bottom: 8px;

            .small {
                width: 270px;
                height: 32px;
            }
        }

        .subtitle {
            .small {
                max-width: 720px;
                height: 48px;
            }
        }

        .button {
            margin-top: 16px;

            .small {
                border-radius: 10px;
                width: 190px;
                height: 40px;
            }
        }
    }

    &--mobile {
        flex-direction: column;
        border: 2px solid $primary;

        .subtitle {
            .small {
                height: 72px;
            }
        }

        .icon {
            .small {
                width: 56px;
                height: 56px;
            }
        }
    }
}
</style>