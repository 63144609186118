<template>
    <div class="unverified-email-layer">
        <img class="icon" src="@/assets/img/social/envelope.svg" alt="">
        <p class="title">{{ $i18n.t('unverified-email-layer.title') }}</p>
    </div>
</template>

<script>
export default {
    name: 'UnverifiedEmailLayer'
}
</script>

<style lang="scss" scoped>
.unverified-email-layer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    background: rgba($white, .9);
    overflow-y: auto;

    .icon {
        width: 50px;

        @media(min-width: 768px) {
            width: 130px;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        max-width: 1200px;

        @media(min-width: 768px) {
            font-size: 50px;
        }
    }
}
</style>