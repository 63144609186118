<template>
    <div class="progress-test-page">
        <div class="bg-white nav-bar">
            <div class="wrapper wrapper--big">
                <nav class="nav">
                    <div class="nav__left">
                        <img class="logo" src="@/assets/img/logo-wide.svg" alt="Fluentbe">
                    </div>
                    <div class="nav__right">
                        <NavBarTime
                            v-if="timeLeft"
                            ref="time"
                            :time="timeLeft" 
                            @timeEnded="endTest"
                        />
                    </div>
                </nav>
            </div>
        </div>
        <Loading 
            v-if="loading"
            :size="40"
        />
        <MultiStep
            v-else
            ref="multiStep"
            class="multi-step--top-gap"
            :steps="steps" 
            @setStep="setStep"
            @finish="endTest"
            :isCompleted="isCompleted"
            :buttons="{
                back: {
                    disabledSteps: [2, steps.length]
                },
                next: {
                    texts: {
                        1: $t('progress-test.footer.start'),
                        [!isCompleted && [steps.length - 1]]: $t('progress-test.footer.submit')
                    }
                },
                close: {
                    steps: isCompleted ? 'all' : [1],
                    ...closeButton
                },
                finish: {
                    steps: [steps.length],
                    action: () => this.$router.push({ name: 'dashboard' }),
                    text: $t('progress-test.footer.finish') 
                },
                custom: {
                    steps: [steps.at(-1).data.link && steps.length],
                    action: downloadCert,
                    text: $t('progress-test.footer.download'),
                    icon: require('@/assets/img/progressTest/download.svg')
                }
            }"
        />
    </div>
</template>

<script>
import MultiStep from '@/components/reusable/MultiStep'
import ProgressTestStart from '@/components/progressTest/ProgressTestStart'
import SingleChoice from '@/components/reusable/exercises/SingleChoice'
import ProgressTestSummary from '@/components/progressTest/ProgressTestSummary'
import NavBarTime from '@/components/reusable/NavBarTime'
import Loading from '@/components/reusable/Loading'

import { getProgressTest } from '@/graphql/queries/getProgressTest'
import { startProgressTest } from '@/graphql/mutations/startProgressTest'
import { endProgressTest } from '@/graphql/mutations/endProgressTest'
import { updateProgressTestStatus } from '@/graphql/mutations/updateProgressTestStatus'
import api from '@/graphql/api.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'ProgressTest',
    components: {
        MultiStep,
        NavBarTime,
        Loading
    },
    data() {
        return {
            loading: true,
            testLength: null,
            timeLeft: null,
            steps: [],
            currentStepIndex: 1,
            testUuid: this.$route.params.uuid,
            isCompleted: false
        }
    },
    async mounted() {
        await this.getTest()

        this.loading = false
    },
    computed: {
        closeButton() {
            if(this.currentStepIndex === 1) {
                return {
                    action: () => this.$router.push({ name: 'dashboard' }),
                    text: this.$t('progress-test.footer.close')
                }
            }

            if(this.currentStepIndex === this.steps.length) {
                return {
                    action: () => this.$refs.multiStep.setStep(2),
                    text: this.$t('progress-test.footer.my-answers')
                }
            }

            return {
                action: () => this.$refs.multiStep.setStep(this.steps.length),
                text: this.$t('progress-test.footer.result')
            }
        }
    },
    methods: {
        async getTest() {
            const { res } = await api(getProgressTest, {
                uuid: this.testUuid
            }, 'getProgressTest')
            
            const test = res.getProgressTest

            if(!test) this.$router.push({ name: 'dashboard' })
            else this.handleTest(test)
        },
        handleTest(test) {
            this.testLength = test.testLength
            this.timeLeft = test.timeLeft || this.testLength
            const inProgressData = {}
            let currentStep = 2

            this.steps = test.steps.map((step, index) => {
                step.uuid = uuidv4()

                if(['IN_PROGRESS', 'COMPLETED'].includes(test.status)) {
                    inProgressData[step.uuid] = {}
                    const questions = step.questions
                    
                    questions.forEach(question => {
                        question.answers.forEach(answer => {
                            if(answer.isSelected) {
                                inProgressData[step.uuid][question.uuid] = answer.uuid
                                currentStep = index + 3

                                if(currentStep > test.steps.length + 1) currentStep = test.steps.length + 1
                            }
                        })
                    })
                }

                return {
                    component: SingleChoice,
                    name: step.uuid,
                    validation: this.validateStep,
                    required: true,
                    data: {
                        number: index + 1,
                        ...step
                    }
                }
            })

            this.steps.unshift({
                component: ProgressTestStart,
                validation: this.startTest,
                data: {
                    validTo: test.validTo,
                    testLength: this.testLength / 60
                }
            })

            this.steps.push({
                component: ProgressTestSummary,
                data: {
                    languageLevel: test.languageLevel
                }
            })

            if(test.status === 'IN_PROGRESS') {
                setTimeout(() => {
                    this.$refs.multiStep.updateFormData(inProgressData)
                    this.$refs.multiStep.setStep(currentStep)
                    this.$refs.time.run()

                    if(!test.timeLeft) {
                        this.timeLeft = null
                        this.endTest()
                    }
                }, 0)
            }

            if(test.status === 'COMPLETED') {
                this.isCompleted = true
                this.timeLeft = null
                this.steps.at(-1).data = test

                setTimeout(() => {
                    this.$refs.multiStep.updateFormData(inProgressData)
                    this.$refs.multiStep.setStep(this.steps.length)
                }, 0)
            }
        },
        async startTest() {
            const { error } = await api(startProgressTest, {
                uuid: this.testUuid
            })

            if(error) return { error }

            this.$refs.time.run()
        },
        async validateStep(payload) {
            if(this.isCompleted) return

            const { error } = await api(updateProgressTestStatus, {
                uuid: this.testUuid,
                input: payload.stepData
            })

            if(error) return { error }

            const isLastQuestion = payload.stepName === this.steps.at(-2).name
            if(isLastQuestion) return await this.endTest()
        },
        async endTest() {
            const { res, error } = await api(endProgressTest, {
                uuid: this.testUuid
            }, 'endProgressTest')

            if(error) return { error }

            this.handleTest(res.endProgressTest)
            this.$refs.time?.stop()
        },
        downloadCert() {
            open(this.steps.at(-1).data.link)
        },
        setStep(data) {
            this.currentStepIndex = data
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-test-page {
    .loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>