<template>
    <div class="ask-ai-game-header">
        <div class="activity">
            <p class="activity__title">{{ $t('ask-ai.game-header.activity') }}</p>
            <p class="activity__type">{{ game.gameType.prompt }}</p>
        </div>
        <div v-if="game.state" class="controls">
            <span v-if="isMobile" class="title">{{ game.name }}</span>
            <div :class="['score', { 'score--perfect': isPerfectScore, 'score--mobile': isMobile }]">
                <img class="score__check" v-if="isPerfectScore" src="@/assets/img/check-secondary-dark.svg" alt="">
                <template v-else>{{ $t('ask-ai.game-header.score') }}</template>
                <span class="score__value">{{ game.state.score.current }}/{{ game.state.score.max }}</span>
            </div>
            <div v-if="displayControlsBtns" class="controls__buttons">
                <Button
                    class="controls__restart"
                    :text="$t('ask-ai.game-header.restart')"
                    @click.native="restartGame"
                    :disabled="askAI.loading"
                />
                <Button
                    class="controls__leave"
                    :text="$t('ask-ai.game-header.leave')"
                    @click.native="leaveGame"
                    :disabled="askAI.loading"
                />
            </div>
            <div v-if="displayDropdownControls" class="activities-dropdown" @click="toggleMenu" v-click-outside="hideMenu">
                <dropdown v-if="showMenu" pos="bottom">
                    <div class="dropdown-item" @click="restartGame">{{ $t('ask-ai.game-header.restart') }}</div>
                    <div class="dropdown-item red" @click="leaveGame">{{ $t('ask-ai.game-header.leave') }}</div>
                </dropdown>
                <img src="@/assets/img/tools/dots.svg"/>
            </div>
        </div>
        <div v-else class="controls skeleton">
            <div class="light" style="width: 80px; height: 32px; border-radius: 6px;"></div>
            <div class="light" style="width: 80px; height: 32px; border-radius: 6px;"></div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import { mapState } from 'vuex'

export default {
    name: 'AskAIGameHeader',
    components: { Button, Dropdown },
    mixins: [dropdownMixin],
    computed: {
        ...mapState('tools', ['askAI', 'toolContentWidth', 'showTools']),
        ...mapState(['isMobile']),
        game() {
            return this.askAI.game
        },
        isPerfectScore() {
            const { current, max } = this.game.state.score

            return current === max
        },
        displayControlsBtns() {
            if(this.$route.name === 'tools') return !this.isMobile

            return this.toolContentWidth > 600
        },
        displayDropdownControls() {
            if(this.$route.name === 'tools') return

            return this.toolContentWidth <= 600
        }
    },
    methods: {
        restartGame() {
            this.hideMenu();
            this.$store.dispatch('tools/restartTeacherAIGame')
        },
        leaveGame() {
            this.$store.commit('tools/leaveTeacherAIGame');
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-game-header {
    padding: 12px 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-200;
}

.activity {
    display: none;
    
    @media(min-width: $mobile-tools) {
        display: block;
        flex: 1;
        overflow: hidden;
    }

    &__title {
        @include font-small-12;
        color: $orange-dark;
        text-transform: uppercase;
    }

    &__type {
        @include font-medium;
        font-weight: 600;
        color: $black;
        margin-top: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;

    @media(min-width: $mobile-tools) {
        width: auto;
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn {
            @include font-medium;
            background: $gray-100;
            border-color: $gray-100;

            &.controls__restart {
                color: $gray-600;
            }

            &.controls__leave {
                color: $danger-middle;
            }

            &:hover {
                border-color: $gray-300;
            }
        }
    }

    &.skeleton {
        justify-content: flex-end;
    }

    .title {
        @include font-h4;
    }
}

.score {
    @include font-medium;
    color: $gray-600;
    margin-right: 12px;
    padding: 7px 11px;
    border-radius: 6px;
    border: 1px solid $gray-200;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    &:before {
        position: absolute;
        top: 0;
        right: -13px;
        width: 1px;
        height: 100%;
        background: $gray-100;

        @media(min-width: $mobile-tools) {
            content: '';
        }
    }

    &__value {
        font-weight: 600;
        color: $gray-900;
    }

    &--perfect {
        border-color: $secondary;
        background: $secondary-light;

        .score__value {
            color: $secondary-dark;
        }
    }

    &--mobile {
        margin-left: auto;
    }
}

.activities-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding: 7px;
    border-radius: 6px;
    border: 1px solid $gray-200;
    cursor: pointer;
    position: relative;

    &:hover {
        background: $gray-100;
    }

    ::v-deep {
        .main-dropdown {
            transform: translate(-42%, 100%);
            max-width: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .dropdown-item {
        width: 100%;
        text-align: center;
        @include font-medium;

        &.red {
            color: $danger-middle;
        }

        &:hover {
            background: $gray-100;
        }
    }
}
</style>