var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['translator__inputs', { 'translator__inputs--mobile': _vm.mobileInputs }],attrs:{"spellcheck":"false"}},[_c('div',{class:[
            'translator__input-wrapper',
            'translator__input-wrapper--source',
            { 
                'translator__input-wrapper--active': _vm.sourceActive,
                'translator__input-wrapper--danger': _vm.isOverTranslatorLimit
            }
        ]},[(_vm.translator.showSourceLangs)?_c('TranslatorLangs',{attrs:{"type":"source_lang"}}):_vm._e(),(_vm.translator.sourceText)?_c('ToolClear',{staticClass:"clear-btn--translator",nativeOn:{"click":function($event){return _vm.clearTranslatedText.apply(null, arguments)}}}):_vm._e(),_c('textarea',{ref:"sourceInput",staticClass:"translator__input",attrs:{"placeholder":_vm.$i18n.t('tools.translator.type-to-translate')},domProps:{"value":_vm.translator.sourceText},on:{"input":_vm.sourceInputHandle,"focus":function($event){_vm.sourceActive = true},"blur":function($event){_vm.sourceActive = false},"scroll":_vm.handleSourceScroll}}),_c('p',{class:['translator__source-limit', { 'translator__source-limit--danger': _vm.isOverTranslatorLimit }]},[_vm._v(" "+_vm._s(_vm.translatorCharacters)+" / "+_vm._s(_vm.translator.charactersLimit)+" ")])],1),_c('div',{class:[
            'translator__input-wrapper',
            'translator__input-wrapper--target',
            { 
                'translator__input-wrapper--active': _vm.targetActive,
                'translator__input-wrapper--left-border': _vm.sourceActive,
                'translator__input-wrapper--left-border-danger': _vm.isOverTranslatorLimit
            }
        ]},[(_vm.translator.showTargetLangs)?_c('TranslatorLangs',{attrs:{"type":"target_lang"}}):_vm._e(),(_vm.showSkeleton)?_c('Skeleton',{staticClass:"translator__skeleton"}):_vm._e(),_c('textarea',{ref:"targetInput",staticClass:"translator__input",attrs:{"disabled":_vm.isMobileDevice},domProps:{"value":_vm.translator.targetText},on:{"focus":function($event){_vm.targetActive = true},"blur":function($event){_vm.targetActive = false},"input":_vm.targetInputHandle,"scroll":_vm.handleTargetScroll}}),_c('div',{ref:"copyTargetText",staticClass:"translator__copy",on:{"click":_vm.copyTargetText}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }