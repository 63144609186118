<template>
    <div :class="['element', { 'element--audio': showAudio, 'element--audio-play': audioPlay }]">
        <span class="text-listening__text" v-html="formattedText"></span>

        <AudioPlayer
            v-if="showAudio"
            :text="formattedText"
            mini
            :hideControls="true"
            @playAudio="audioPlay = true"
            @stopAudio="audioPlay = false" />
    </div>
</template>

<script>
import AudioPlayer from '@/components/reusable/AudioPlayer';
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'TextListeningElement',
    props: ['element', 'showAudio'],
    mixins: [handleBoldedText],
    components: {
        AudioPlayer
    },
    data() {
        return {
            audioPlay: false
        };
    },
    computed: {
        formattedText() {
            return this.handleText(this.element) || '';
        }
    }
};
</script>

<style lang="scss" scoped>
.element {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &--audio {
        padding: 2px;
        border-radius: 4px;
        transition: all 0.5s;

        .audio-player {
            opacity: 0;
        }

        &:hover,
        &-play {
            background: $primary-light;

            .audio-player {
                opacity: 1;
            }
        }
    }
}

.text-listening__text {
    @include font-h3;
    font-weight: 400;
}

::v-deep {
    .audio-player .mini-btn {
        height: initial;
        width: initial;
    }
}

</style>
