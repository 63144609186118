<template>
    <div>
        <TooltipTrans v-if="tooltip.type === 'translate'" />
    </div>
</template>

<script>
import TooltipTrans from '@/components/reusable/TooltipTrans';
import { mapState } from 'vuex';

export default {
    name: 'TooltipWrapper',
    components: { TooltipTrans },
    computed: {
        ...mapState('tools', ['tooltip']),
    }
}
</script>