<template>
    <div style="display: contents" :data-url="url">
        <vue-plyr class="video-iframe-player" ref="plyr" :options="plyrOptions">
            <div class="plyr__video-embed">
                <iframe
                    :src="this.url"
                    allowfullscreen
                    allowtransparency
                    allow="autoplay"
                />
            </div>
        </vue-plyr>

        <div class="video__controls">
            <MediaVideoControls
                :duration="timestamp.duration"
                :percent="timestamp.percent"
                :seconds="timestamp.seconds"
                :state="state"
                @pause="pause"
                @play="play"
                @changeVolume="changeVolume"
                @skip="onSkip"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import MediaVideoControls from '@/components/reusable/MediaVideoControls';

Vue.use(VuePlyr)
export default {
    name: 'MediaVideo',
    components: {
        MediaVideoControls
    },
    props: {
        url: String,
        autoPlay: Boolean
    },
    data() {
        return {
            player: null,
            state: 'loading',
            timestamp: {
                duration: 0,
                percent: 0,
                seconds: 0
            },
            plyrOptions: {
                controls: [],
                displayDuration: true,
                clickToPlay: true,
                ratio: '16:9'
            }
        }
    },
    beforeDestroy() {
        this.player.off('play', this.onPlay)
        this.player.off('pause', this.onPause)
        this.player.off('ready', this.onLoaded)
        this.player.off('timeupdate', this.onTimeUpdate)
    },
    mounted() {
        if (!this.player) this.player = this.$refs.plyr.player;

        this.player.volume = 1;

        this.player.on('play', this.onPlay)
        this.player.on('pause', this.onPause)
        this.player.on('ready', this.onLoaded)
        this.player.on('timeupdate', this.onTimeUpdate)
    },
    computed: {
        ...mapState(['isMobile']),
    },
    methods: {
        onLoaded() {
            this.ensure(this.loadDuration);
            if (!this.isMobile && this.autoPlay) {
                this.player.play();
            }
        },
        pause() {
            this.player.pause()
        },
        play() {
            this.player.play();
        },
        onPause() {
            this.state = 'paused';
        },
        onPlay() {
            this.state = 'playing';
        },
        onTimeUpdate() {
            const currentTime = this.player.currentTime
            this.timestamp.seconds = currentTime
            this.timestamp.percent = currentTime / this.timestamp.duration
        },
        onSkip(payload) {
            this.player.currentTime = payload.targetSeconds
            this.timestamp.seconds = payload.targetSeconds
            this.timestamp.percent = payload.targetPercentage
        },
        changeVolume(payload) {
            if (this.player) {
                this.player.volume = payload;
            }
        },
        loadDuration() {
            this.timestamp.duration = this.player.duration
            return this.timestamp.duration
        },
        ensure(func, timeout) {
            timeout = timeout || 500
            const result = func()
            if (result) {
                return result
            } else {
                return setTimeout(() => this.ensure(func, timeout), timeout)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .modal--video {
        .video-iframe-player {
            flex: 1;
            height: 100%;
        }

        .plyr--video {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .video__controls {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    ::v-deep .plyr__poster {
        cursor: pointer;
    }
</style>