import gql from 'graphql-tag'

export const teacherAIModal = gql`
    {
        teacherAIChatSuggestionsList(filters: {}) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    uuid
                    type
                    prompt
                }
            }
        }
        teacherAIGamesList(filters: {}) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    uuid
                    type
                    prompt
                    game {
                        uuid
                    }
                }
            }
        }
    }
`