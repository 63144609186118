<template>
    <div class="remaining-lessons">
        <p class="remain-text">{{ $i18n.t('dashboard.student.remaining-lessons') }}</p>
        <div>
            <div v-for="(el, idx) in data" :key="idx" style="display: flex" class="remaining-lessons__item remaining-lessons__remaining">
                <div class="course-icon">
                    <img class="flag" :src="getCourseIcon(el.language.shortName)" alt="Course"/>
                </div>
                <div class="info">
                    <div class="info">
                        <p class="label">
                            <span class="type">
                                {{ $i18n.t('dashboard.student.lessons') }}
                                {{ $i18n.t(`general.type.${ el.type }`) }}
                            </span>
                            <LessonDuration v-if="el.duration" :duration="el.duration" />
                        </p>
                        <div class="bar"><span class="bar-inner" :style="`width: ${ calculatePercent(el.left, el.quantity) };`"></span></div>
                    </div>
                </div>
                <div class="value">
                    {{ el.left }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LessonDuration from '@/components/reusable/LessonDuration'
import flagMixin from '@/mixins/flag'
import percent from '@/mixins/percent'

export default {
    name: 'RemainingLessons',
    mixins: [flagMixin, percent],
    props: ['data'],
    components: {
        LessonDuration
    }
}
</script>

<style lang="scss" scoped>
.remaining-lessons {
    padding-bottom: 8px;

    .remain-text {
        color: $gray-600;
        @include font-regular;
        margin: 16px 0;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        :not(:first) {
            margin-top: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid #F2F3F4;
        }

        .info {
            flex: 1;

            .label {
                @include font-medium;
                color: $gray-900;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;

                .type {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .info-tooltip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-left: 8px;

                    &:hover {
                        .tooltip {
                            display: block;
                        }
                    }
                }
            }

            .bar {
                height: 12px;
                background: $gray-200;
                border-radius: 16px;
                margin-bottom: 2px;

                span {
                    display: block;
                    height: 100%;
                    background: $secondary;
                    border-radius: 16px;
                    max-width: 100%;
                }
            }

            .markers {
                display: flex;
                justify-content: space-between;

                span {
                    @include font-small-10;
                    color: $gray-600;
                }
            }

            .flag {
                width: 16px;
            }
        }

        .value {
            width: 40px;
            height: 40px;
            background: $gray-100;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-h4;
            color: $gray-900;
        }

        
        &:not(:last-child) {
            padding-bottom: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid #F2F3F4 !important;
        }

    }

    &__remaining {
        .bar-inner {
            background: $calm-dark !important;
        }
    }
}
</style>