import { mapState, mapGetters } from 'vuex';
import upsellMixin from '@/mixins/upsell';
import brandAppUrls from '@/mixins/brandAppUrls';
import isStudentAdult from '@/mixins/isStudentAdult';
import { SCHEDULE_LOCAL_STORAGE, SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    mixins: [upsellMixin, brandAppUrls, isStudentAdult],
    computed: {
        ...mapState('schedule', ['selectedCourse', 'switchLector', 'lectorDetails']),
        ...mapGetters(['getPackagesGroups', 'getStudentHadLesson', 'getLanguageLevel']),
        getLinkForPayment() {
            if (!this.isStudentAdult) return this.kidsUrl;

            const isUpsell = Object.keys(this.upsellBanners || {}).length;
            return `${ isUpsell
                ? this.upsellBanners.find(el => (el.type === this.selectedCourse.type))?.newOfferLink
                : this.checkoutIndOfferUrl }${ this.setQueryParam() }`
        },
        getLinkForLastEnded() {
            if (!this.isStudentAdult) return this.kidsUrl;

            const isUpsell = Object.keys(this.upsellBanners || {}).length;
            return `${ isUpsell
                ? this.upsellBanners.reduce((prev, curr) => {
                    return (prev.left > curr.left) ? prev : curr
                })?.newOfferLink
                : this.checkoutIndOfferUrl }${ this.setQueryParam() }`
        }
    },
    methods: {
        setQueryParam() {
            if(!this.getLanguageLevel) return '';
            return this.getStudentHadLesson ? '?fromSpace' : '?fromSpaceWithModal'
        },
        setLocalStorageSavedScheduleOnClick() {
            if (!this.switchLector.activeFlow) return;

            localStorage.setItem(SCHEDULE_LOCAL_STORAGE.SAVED_SCHEDULE, JSON.stringify({
                lectorUuid: this.switchLector.activeScreen === SCHEDULE_SCREEN.LECTOR_DETAILS
                    ? this.lectorDetails.uuid
                    : null,
                groupType: this.switchLector.filters.groupType,
                selectedSpeciality: this.switchLector.selectedSpeciality,
                selectedSpecialityType: this.switchLector.selectedSpecialityType
            }));
        }
    },
    created() {
        this.packagesGroups = this.getPackagesGroups;
    }
}