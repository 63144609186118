<template>
    <div class="openai-chat">
        <div class="openai-chat__message-wrapper">
            <div :class="['message-block', { 'message-block--user': message.type === 'user' }]" v-for="(message, idx) in messages" :key="idx">
                <span class="message-block__message">
                    {{ message.text }}
                </span>
            </div>
        </div>
        <div class="openai-chat__prompts-wrapper">
            <input
                v-model="prompt"
                @keyup.enter="handleRequest"
                :disabled="loading"
                ref="input"
            />
            <button 
                @click="handleRequest" 
                :class="['send', { 'loading': loading }]"
            >
                <img :src="sendIcon" alt=""> 
                <span class="text">Send</span>
            </button>
        </div>
    </div>
</template>

<script>
import { askAI } from '@/graphql/queries/askAI'
import api from '@/graphql/api.js'
import sendIcon from '@/assets/img/chat/send.svg'

export default {
    name: 'OpenAIChat',
    data() {
        return {
            prompt: '',
            loading: false,
            sendIcon,
            messages: []
        }
    },
    methods: {
        scrollToBottom() {
            const el = document.querySelector('.openai-chat__message-wrapper');

            setTimeout(() => el.scrollTo(0, el.scrollHeight), 0);
        },
        async handleRequest() {
            if(this.loading) return

            this.loading = true;
            const promptValue = this.prompt
            this.prompt = '';

            if(promptValue) this.messages.push({ type: 'user', text: promptValue });
            this.scrollToBottom();

            const { res } = await api(askAI, { prompt: promptValue });
            if(res?.askAI?.response) this.messages.push({ type: 'ai', text: res?.askAI?.response });
            this.loading = false;

            this.scrollToBottom();
            setTimeout(() => {
                this.$refs.input.focus()
            }, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.openai-chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgb(248, 248, 248);
    z-index: 999;
    gap: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid $gray-300;
    box-shadow: 0px 2px 20px rgba(45, 54, 66, 0.16);

    &__message-wrapper {
        height: 70%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        min-height: 500px;
        max-height: 500px;
        min-width: 500px;
        max-width: 500px;
        padding-bottom: 16px;
        padding: 12px 16px;
    }

    &__prompts-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 100%;
        min-height: 100%;
        min-width: 450px;
        gap: 16px;
        padding: 12px 16px;

        .send {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background: $primary;
            padding: 8px 16px;
            border: 0;
            border-radius: 6px;
            @include font-h4;
            color: $white;
            cursor: pointer;

            &.loading {
                cursor: default;
                position: relative;

                &:before {
                    content: '';
                    background: inherit;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                }

                &:after {
                    content: '';
                    animation: spin 1s linear infinite;
                    position: absolute;
                    left: calc(50% - 8px);
                    top: calc(50% - 8px);
                    width: 16px;
                    height: 16px;
                    background: url('~@/assets/img/loader.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        input {
            height: 40px;
            width: 100%;
            background: #FFF;
            box-shadow: 0px 0px 5px rgba(5, 4, 6, 0.18);
            border-radius: 10px;
            padding: 8px 8px 8px 16px;
            border: 2px solid #FFF;
            flex: 1;
            outline: 0;
        }
    }

    .message-block {
        display: flex;

        &__message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            max-width: 100%;
            background: $primary-light;
            @include font-regular-text;
            border: 2px solid transparent;
            border-radius: 10px 10px 0px 10px;
            box-shadow: 0px 2px 10px rgba(5, 4, 6, 0.18);
            padding: 14px;
            margin: 0 0 0 16px;
            color: $gray-900;
            word-break: break-word;

            &:last-of-type {
                margin-bottom: 8px;
            }
        }

        &--user {
            justify-content: flex-end;

            .message-block__message {
                background: #FFF;
                margin: 0 16px 0 0;
                border-radius: 10px 10px 10px 0px;
                box-shadow: 0px 2px 10px rgba(45, 54, 66, 0.08);
            }
        }
    }
}
</style>