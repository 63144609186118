<template>
    <div v-if="!loading" :class="['side-content', { 'side-content--mobile': isMobile, 'side-content--disabled': !getLanguageLevel }]">
        <div class="progress-widget">
            <div class="progress-header">
                <CircleProgress :percentage="percentage"/>
                <div class="title-wrapper">
                    <span class="title">{{ $t('elearning.side-content.title') }}</span>
                    <span v-if="!isMobile" class="subtitle">{{ $t('elearning.side-content.finished-lessons') }}: {{ finishedModules }}/{{ allModules }}</span>
                    <div v-else :class="['learning-time', { 'learning-time--mobile': isMobile }]">
                        <span class="learning-time__title">{{ $t('elearning.side-content.learning-time') }}</span>
                        <span class="learning-time__time-wrapper">{{ formattedTime }}</span>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="progress-content">
                <div class="learning-time">
                    <span class="learning-time__title">{{ $t('elearning.side-content.learning-time') }}</span>
                    <span class="learning-time__time-wrapper">{{ formattedTime }}</span>
                </div>
            </div>
        </div>
    </div>
    <SideSkeleton v-else/>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SideSkeleton from '@/components/learning/coursePlan/skeletons/SideSkeleton';
import CircleProgress from '@/components/reusable/CircleProgress';

export default {
    name: 'SideContent',
    components: {
        CircleProgress,
        SideSkeleton
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('learningCoursePlan', ['loading']),
        ...mapGetters(['getLanguageLevel']),
        ...mapGetters('learningCoursePlan', ['getLearningTime', 'getLearningCoursePlanModules']),
        formattedTime() {
            const time = this.getLearningTime || 0

            const timeObject = {
                hours: Math.floor(time / 3600),
                minutes: Math.floor(time % 3600 / 60)
            }

            for (const [key, value] of Object.entries(timeObject)) {
                if(value < 10) timeObject[key] = '0' + value
            }

            let formattedTimeString = '';

            if (timeObject.hours > 0) formattedTimeString += `${timeObject.hours}h `;

            formattedTimeString += `${timeObject.minutes}m`;

            return formattedTimeString;
        },
        allModules() {
            return this.getLearningCoursePlanModules?.flatMap(material => material.materials).length;
        },
        finishedModules() {
            return this.getLearningCoursePlanModules?.flatMap(material => material.materials)
                .filter(material => material.state === 'COMPLETED').length;
        },
        percentage() {
            const allPercentage = this.getLearningCoursePlanModules?.flatMap(material => material.materials)
                .map(material => material.percentage);

            return Math.round((allPercentage.reduce((a, b) => a + b, 0)/100) / this.allModules * 100)
        }
    }
}
</script>

<style lang="scss" scoped>
.side-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    width: 100%;
    max-width: 352px;
    position: sticky;
    top: 96px;

    @media(max-width: 1200px) {
        top: 80px;
    }

    .progress-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $white;
        border-radius: 10px;
    }

    .progress-header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 24px;
        width: 100%;

        .title-wrapper {
            display: flex;
            flex-direction: column;

            .title {
                @include font-h2;
            }

            .subtitle {
                @include font-regular-text;
                color: $gray-600;
            }
        }
    }

    .progress-content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        border-top: 1px solid $gray-200;
        border-top-style: dashed;
    }

    &--mobile {
        max-width: initial;
        position: static;
        top: 0;

        .progress-header {
            padding: 16px;

            .title-wrapper {
                .title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }

    .learning-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__title {
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.4px;
            color: $gray-600;
        }

        &__time-wrapper {
            border-radius: 8px;
            background-color: $gray-100;
            padding: 8px;
            color: $gray-600;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: -0.4px;
        }

        &--mobile {
            justify-content: center;
            margin-top: 2px;
            gap: 8px;

            .learning-time__time-wrapper {
                padding: 4px 8px;
                font-size: 15px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.4px;
            }
        }
    }
}
</style>
