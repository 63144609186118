<template>
    <div>
        <TooltipWrapper v-if="tooltipVisible" />
        <router-view />
        <MainNotifications />
        <Modal v-if="gallery" @hide="hideGallery">
            <div class="gallery modal-inner">
                <ModalHeader :title="galleryData.name" :download="galleryData.path" @hide="hideGallery" />
                <div class="action">
                    <div class="image">
                        <img :src="galleryData.path" alt="" />
                    </div>
                    <!-- <div class="dsc"><span class="name">{{galleryData.name}}</span> <span class="date">{{$moment(galleryData.createdAt).format('HH:mm - DD/MM/YYYY')}}</span></div> -->
                </div>
            </div>
        </Modal>

        <VideoModal />
    </div>
</template>

<script>
import Modal from './components/layout/Modal.vue'
import ModalHeader from '@/components/layout/ModalHeader.vue'
import TooltipWrapper from '@/components/reusable/TooltipWrapper'
import VideoModal from '@/components/reusable/VideoModal'
import MainNotifications from '@/components/MainNotifications.vue'

export default {
    name: 'App',
    components: {
        Modal,
        VideoModal,
        ModalHeader,
        TooltipWrapper,
        MainNotifications
    },
    data() {
        return {
            gallery: false,
            galleryData: {}
        };
    },
    created() {
        this.$bus.$on('updateGallery', (data) => this.showGallery(data))

        addEventListener('storage', (e) => {
            if (e.key === 'authToken' && !sessionStorage.getItem('isEmployee')) location.reload()
        });

        this.$store.state.isMobileDevice = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    mounted() {
        this.setWindowSize()
        addEventListener('resize', this.setWindowSize)
        window.addEventListener('setAndroidApp', () => this.$store.commit('setAndroidApp'))
        window.addEventListener('setIOSApp', () => {
            this.$store.commit('setIOSApp')
            window.dispatchEvent(new CustomEvent('iOSAppInit'))
        });
    },
    beforeDestroy() {
        removeEventListener('resize', this.setWindowSize)
        this.$bus.$off('updateGallery')
    },
    computed: {
        tooltipVisible() {
            return this.$store.state.tools.tooltip.visible
        }
    },
    methods: {
        setWindowWidth() {
            this.$store.state.windowWidth = window.innerWidth
        },
        setWindowHeight() {
            this.$store.state.windowHeight = window.innerHeight
        },
        setMobileOrDesktop() {
            this.$store.state.isMobile = this.$store.state.windowWidth < this.$store.state.mobileWidth

            this.$store.state.tools.grammar.isSupported = !this.$store.state.isMobileDevice && this.$store.state.windowWidth >= 600
        },
        setWindowSize() {
            this.setWindowWidth()
            this.setWindowHeight()
            this.setMobileOrDesktop()
            document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
        },
        showGallery(data) {
            this.galleryData = data
            this.gallery = true
        },
        hideGallery() {
            this.gallery = false
        }
    }
};
</script>

<style lang="scss">
@import 'assets/scss/styles.scss';
</style>
