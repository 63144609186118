import gql from 'graphql-tag'

export const lessonByUuid = gql`
    query($lessonUuid: ID!) {
        lessonByUuid(input: {lessonUuid: $lessonUuid}) {
            uuid
            type
            status
            user {
                firstName
                avatarUrl
            }
            startAt
            stopAt
            languageLevel {
                    shortName
                }
            group {
                languageLevel {
                    shortName
                }
                messageGroup {
                    uuid
                }
                isActive
            }
            lessonSubjects {
                materialUuid
                subject
            }
            number
            materialLink {
                url
                materialUuid
                state
            }
            tips {
                topic
                materialUuid
                materialTips
            }
            grammars {
                topic
                materialUuid
                grammar
            }
            courseProgram {
                id
                materialKeyVocabulary {
                    materialUuid
                    keyVocabulary {
                        english
                        pronunciation
                        definition
                        polish
                        examples
                    }
                }
                extraVocabulary {
                    english
                    pronunciation
                    definition
                    polish
                    examples
                }
            }
            studentLesson {
                rescheduleLessonUrl
                legacyJoinLessonUrl
                joinLessonUrl
            }
            feedbackStatus
            isTeacherSubstituted
            homework {
                name
                url
                materialUuid,
                state
            }
            preLesson {
                name
                url
                materialUuid,
                state
            }
            flashcardsGroups {
                uuid
                name
                status
                flashcards {
                    activities {
                        status
                    }
                }
            }
            teacherFeedback {
                summary
                recommendations
            }
        }
    }
`