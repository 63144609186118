<template>
    <div @click="handleClick" :class="['multiple-choice', { 'chosen': chosenUuids.includes(exerciseData.uuid) }, getMultipleStatus]">
        <input class="checkbox" type="checkbox" :checked="chosenUuids.includes(exerciseData.uuid)" :disabled="completed"/>
        <span class="description">{{ exerciseData.text }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MultipleBtn',
    props: {
        exerciseData: {
            type: Object,
            required: true,
            default: () => {}
        },
        completed: {
            type: Boolean,
            required: true,
            default: false
        },
        chosenUuids: {
            type: Array,
            required: true,
            default: () => []
        },
        answersUuids: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        handleClick() {
            if (this.completed) return;

            this.$emit('clickBtn', this.exerciseData.uuid);
        },
    },
    computed: {
        ...mapGetters('learning', ['isAnswerCorrect']),
        getMultipleStatus() {
            if (!this.completed) return '';

            const isAnswerChosenByUser = this.chosenUuids.includes(this.exerciseData.uuid);

            if (this.answersUuids.includes(this.exerciseData.uuid)) {
                if (!isAnswerChosenByUser && this.isAnswerCorrect === 'HALF_CORRECT') return 'multiple-choice--half-correct';

                return 'multiple-choice--correct';
            } else if (isAnswerChosenByUser) {
                return 'multiple-choice--incorrect';
            }

            return '';
        }
    }
};
</script>

<style scoped lang="scss">
.multiple-choice {
    display: flex;
    padding: 12px 16px;
    border: 1px solid $gray-300;
    border-radius: 6px;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    align-items: center;
    gap: 16px;
    min-width: calc(50% - 6px);
    cursor: pointer;

    .description {
        color: $gray-900;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.4px;
    }

    .checkbox {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 2px solid $gray-300;
        cursor: pointer;
        flex-shrink: 0;

        appearance: none;
        -webkit-appearance: none;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('~@/assets/img/check-light.svg') no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            pointer-events: none;
        }

        &:checked {
            border-color: $primary;
            border: 2px solid $primary;
            background-color: $primary;

            &::after {
                opacity: 1;
            }
        }
    }

    &--correct {
        border: 1px solid $secondary;

        &.chosen {
            background: $secondary-light;
        }

        .description {
            color: $secondary;
        }

        .checkbox {
            border: 2px solid $secondary;

            &:checked {
                border: 2px solid $secondary;
                background-color: $secondary;
            }
        }
    }

    &--incorrect {
        border: 1px solid $danger;

        &.chosen {
            background: $danger-light;
        }

        .description {
            color: $danger;
        }

        .checkbox {
            border: 2px solid $danger;

            &:checked {
                border: 2px solid $danger;
                background-color: $danger;
            }
        }
    }

    &--half-correct {
        border: 1px solid $orange;

        &.chosen {
            background: $orange-light;
        }

        .description {
            color: $orange;
        }

        .checkbox {
            border: 2px solid $orange;

            &:checked {
                border: 2px solid $orange;
                background-color: $orange;
            }
        }
    }
}
</style>

