<template>
    <div class="switch-lector-spec">
        <p class="switch-lector-spec__title">{{$i18n.t(`switch-lector.speciality.title`)}}</p>
        <p class="switch-lector-spec__desc">{{$i18n.t(`switch-lector.speciality.description`)}}</p>
        
        <div class="select-row">
            <div 
                :class="[ 'item',
                    { 'item--active': $store.state.schedule.switchLector.selectedSpeciality === key }
                ]"
                v-for="[key, value] in formatedSpeciatityData"
                :key="key"
                @click="setSpeciality(key)"
            >
                <p class="item__title">
                    {{ translateSpeciality('label', key) }}

                    <select 
                        v-if="value && $store.state.schedule.switchLector.selectedSpeciality === key" 
                        @change="setSpecialityType($event.target.value)" 
                        class="select-row__select" 
                    >
                        <option value="" disabled selected>{{ translateSpeciality('placeholder', key) }}</option>
                        <option 
                            v-for="option in value" 
                            :value="option.key" 
                            :key="option.key" 
                            :selected="option.key === $store.state.schedule.switchLector.selectedSpecialityType"
                        >{{ option.name }}</option>
                    </select>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SCHEDULE_SCREEN } from '@/constants/schedule';
import { STUDENT_TYPE } from '@/constants/studentTypes';

export default {
    name: 'SwitchLectorSpeciality',
    created() {
        this.$store.state.schedule.queue.push(SCHEDULE_SCREEN.SPECIALITY);
    },
    mounted() {
        const { btnPrimaryLabel } = this;
        this.$store.commit("schedule/setSchedulePrimaryButton", { btnPrimaryAction: this.getLectors, btnPrimaryLabel });

        this.$store.commit("schedule/setScheduleDefaultButton", { 
            btnDefaultAction: this.isNoCourse ? this.btnDefaultActionNoCourse : this.btnDefaultActionCourseExists, 
            btnDefaultLabel: this.isNoCourse 
                ? this.$i18n.t('switch-lector.exit')
                : this.$i18n.t('switch-lector.specification.btn-default')
        });

        this.setPrimaryBtnDisable();
    },
    data() {
        return {
            btnPrimaryLabel: this.$i18n.t('switch-lector.specification.btn-primary')
        }
    },
    computed: {
        ...mapState({
            specialityData: state => state.schedule.switchLector.specialityData
        }),
        ...mapGetters('schedule', ['isNoCourse']),
        lectors() {
            return this.$store.state.schedule.switchLector.data;
        },
        formatedSpeciatityData() {
            const { switchLector: {
                filters
            }} = this.$store.state.schedule;

            return (Object.entries(this.specialityData)).filter(el => {
                if (!filters.groupType.value || [STUDENT_TYPE.INDIVIDUAL].includes(filters.groupType.value)) return true;
                return ['general', 'exam'].includes(el[0]);
            });
        }
    },
    methods: {
        async getLectors() {
            const { commit, dispatch } = this.$store;
            const { switchLector } = this.$store.state.schedule;

            commit('schedule/setScheduleButtonsDisabled', true);

            await dispatch('schedule/getProposedLectors');
        },
        checkIfHasSelect(incomingValue) {
            return this.specialityData[incomingValue];
        },
        setSpeciality(value) {
            if (this.$store.state.schedule.switchLector.selectedSpeciality === value) return;
            this.$store.state.schedule.switchLector.selectedSpecialityType = false;
            this.$store.state.schedule.switchLector.selectedSpeciality = value;

            this.setPrimaryBtnDisable();
        },
        setSpecialityType(value) {
            this.$store.state.schedule.switchLector.selectedSpecialityType = value;
            this.$store.state.schedule.btnPrimaryDisabled = false;
        },
        setPrimaryBtnDisable() {
            const { switchLector: { selectedSpeciality, selectedSpecialityType }} = this.$store.state.schedule;

            if (!selectedSpeciality) return this.$store.state.schedule.btnPrimaryDisabled = true;
            
            if ( selectedSpeciality && !this.specialityData[selectedSpeciality]
                || selectedSpeciality && selectedSpecialityType) {
                this.$store.state.schedule.btnPrimaryDisabled = false;
            } else {
                this.$store.state.schedule.btnPrimaryDisabled = true;
            }
        },
        async btnDefaultActionCourseExists() {
            const { commit } = this.$store;
            const { selectedNewLector } = this.$store.state.schedule;

            commit('schedule/setLoading', true);
            commit('schedule/setSwitchLectorApiUrl', false)
            commit("schedule/resetSlot");
            commit('schedule/setSwitchLectorFlow', { activeScreen: false, activeFlow: false });

            if (selectedNewLector) {
                setTimeout(() => {
                    commit("schedule/switchCoursesView", true);
                    commit('schedule/setLoading', false);
                }, 500);
            } else {
                commit('schedule/setLoading', false);
            }

        },
        btnDefaultActionNoCourse() {
            const { commit } = this.$store;
            commit('schedule/setInitialState');
            commit('schedule/hideScheduleModal');
        },
        translateSpeciality(type, key) {
            return this.$i18n.t(`switch-lector.speciality.${key}.${type}`)
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/assets/scss/components/_select_row.scss";

    .switch-lector-spec {
        width: 100%;
        max-width: 500px;
        display: flex;
        align-items: center;
        flex-direction: column;

        ::v-deep .select-row {
            width: 100%;
        }
    }

    .switch-lector-spec__title {
        @include font-h2;
    }

    .switch-lector-spec__desc {
        margin-bottom: 30px;
    }

    .select-row {
        .item {
            &:hover {
                background: $white;
                color: $gray-900;

                &:before {
                    border-color: transparent;
                }
            }
            &--active {
                background: $primary-light !important;
                color: $primary-dark !important {
                    
                };

                &:before {
                    border-color: $primary !important;
                }
            }
        }
    }
</style>