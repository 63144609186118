<template>
    <ul class="bullet-points">
        <li
            class="bullet-points__el"
            v-for="el in task.data.texts"
            :key="el"
            v-html="handleText(el)"
        >
        </li>
    </ul>
</template>

<script>
import handleBoldedText from '@/mixins/learning/handleBoldedText'

export default {
    name: 'BulletPoints',
    props: ['task'],
    mixins: [handleBoldedText]
}
</script>

<style lang="scss" scoped>
.bullet-points {
    width: 100%;
    list-style-position: inside;
}

.bullet-points__el {
    font-size: 20px;
    position: relative;

    &:not(:first-of-type) {
        margin-top: 20px;
    }
}
</style>
