import { FLASHCARDS_STATES } from '@/constants/flashcards';
import { v4 as uuidv4 } from 'uuid'
import { i18n } from '@/i18n/i18n'
import trimSpecialCharacters from '@/functions/trimSpecialCharacters'
import shuffleArray from '@/functions/shuffleArray'
import apiLearning from '@/graphql/apiLearning'
import { material } from '@/graphql/queries/learning/material'
import { submitAnswers } from '@/graphql/mutations/learning/submitAnswers'
import processWord from '@/functions/processWord'
import router from '@/router';
import handleAudioPlay from '@/mixins/learning/handleAudioPlay';

import { ADDITIONAL_SLIDES } from '@/constants/learning';

const store = {
    state: {
        mode: null,
        loading: true,
        taskLoading: false,
        learningStatus: null,
        flashcardsStatus: null,
        slides: [],
        flashcardsSlides: [],
        additionalSlidesType: [],
        currentSlideIndex: 0,
        currentTaskIndex: 0,
        userAnswer: null,
        isRecording: false,
        progress: 0,
        subject: null,
        mobile: 768,
        slideTransition: 'learning-slide-right',
        withFlashcards: null
    },
    actions: {
        async getData({ commit, state, rootState }, data) {
            commit('resetState')
            commit('flashcards/resetState', null, { root: true })
            state.withFlashcards = !sessionStorage.getItem('learningPreview')

            const { res } = await apiLearning(material, {
                uuid: data.uuid
            })
            
            commit('setProgress', res.material.percentage)
            commit('setLearningStatus', res.material.slides)

            state.slides = res.material.slides.map(slide => {
                slide.uuid = uuidv4()

                if(sessionStorage.getItem('learningPreview')) {
                    slide.activity.tasks.forEach(task => task.result = null)
                }

                return slide
            })

            state.subject = res.material.title

            if(state.withFlashcards) {
                commit('setupFlashcards', {
                    flashcardGroup: res.flashcardGroup,
                    rootState
                })
            }
            commit('prepareSlides')
            commit('handleAdditionalSlides')

            if(data.forceSlideIndex) state.currentSlideIndex = data.forceSlideIndex

            state.loading = false
        },
        async submitTask({ commit, state, getters }) {
            if(getters.isAdditionalSlide || getters.isFakeActivity) return commit('nextActivity')
            if(getters.isFlashcardsActivity) return commit('submitFlashcardsActivity')
            
            if(['gaps_empty_spaces', 'gaps_words_in_brackets'].includes(getters.getCurrentSlide.activity?.type)) {
                Object.keys(state.userAnswer).forEach(el => {
                    state.userAnswer[el] = processWord(state.userAnswer[el]) || state.userAnswer[el]
                })
            }

            state.taskLoading = true

            const requestData = {
                inputs: [
                    {
                        taskUuid: getters.getCurrentTask.uuid,
                        answer: state.userAnswer
                    }
                ],
                save: !sessionStorage.getItem('learningPreview')
            }

            const { res, error } = await apiLearning(submitAnswers, requestData)

            if(error) {
                this._vm.$notify({
                    title: i18n.t('notify.error.title'),
                    text: i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } else {
                const actualMaterial = res.submitAnswers.materials.find(el => el.uuid === router.currentRoute.params.uuid);

                commit('setProgress', actualMaterial.percentage)

                getters.getCurrentTask.result = res.submitAnswers.tasks[0].result
            }

            state.taskLoading = false
        }
    },
    mutations: {
        resetState(state) {
            state.loading = true
            state.taskLoading = false
            state.slides = []
            state.flashcardsSlides = []
            state.additionalSlidesType = []
            state.currentSlideIndex = 0
            state.currentTaskIndex = 0
            state.userAnswer = null
            state.subject = null
            state.learningStatus = null
            state.flashcardsStatus = null
        },
        setLearningStatus(state, data) {
            const tasks = []
            data.forEach(slide => {
                if(['bullet_points'].includes(slide.activity.type)) return

                slide.activity.tasks.forEach(task => tasks.push(task))
            })

            if(tasks.every(task => !task.result)) state.learningStatus = 'NEW'
            if(tasks.some(task => task.result) && tasks.some(task => !task.result)) state.learningStatus = 'IN_PROGRESS'
            if(tasks.every(task => task.result) && tasks.some(task => !task.result.isCorrect)) state.learningStatus = 'FAILED'
            if(tasks.every(task => task.result?.isCorrect)) state.learningStatus = 'COMPLETED'
        },
        setupFlashcards(state, data) {
            if(!data.flashcardGroup) return

            this.commit('flashcards/setPreviewMode', data.flashcardGroup.status === FLASHCARDS_STATES.COMPLETED && state.learningStatus === 'COMPLETED')
            this.commit('flashcards/createVocabulary', data.flashcardGroup.flashcards)
            this.commit('flashcards/createExercises', {
                learningStatus: state.learningStatus
            })
            state.flashcardsStatus = data.rootState.flashcards.status

            const flashcardsSlides = data.rootState.flashcards.exercises.map(el => {
                return {
                    uuid: uuidv4(),
                    media: null,
                    activity: {
                        type: 'flashcards',
                        tasks: [
                            {
                                uuid: uuidv4(),
                                status: el.status
                            }
                        ]
                    }
                }
            })

            state.flashcardsSlides = flashcardsSlides
        },
        prepareSlides(state) {
            const goalsSlide = state.slides.shift()
            state.slides.pop()

            if(['FAILED', 'IN_PROGRESS'].includes(state.learningStatus)) {
                state.slides = state.slides.map(slide => {
                    slide.activity.tasks = slide.activity.tasks.filter(task => {
                        const isFailed = state.learningStatus === 'FAILED' 

                        return isFailed ? !task.result.isCorrect : !task.result
                    })

                    slide.activity.tasks.forEach(task => task.result = null)

                    return slide
                }).filter(slide => slide.activity.tasks.length)
            }

            if(
                ['NEW', 'IN_PROGRESS'].includes(state.flashcardsStatus)
                || ['FAILED'].includes(state.learningStatus) && ['FAILED'].includes(state.flashcardsStatus)
                || ['COMPLETED'].includes(state.learningStatus) && ['COMPLETED'].includes(state.flashcardsStatus)
            ) {
                state.slides = [...state.flashcardsSlides, ...state.slides]
            }

            if(['COMPLETED'].includes(state.learningStatus) && state.flashcardsStatus && !['COMPLETED'].includes(state.flashcardsStatus)) {
                state.slides = state.flashcardsSlides
            }

            if(['FAILED'].includes(state.learningStatus) && ['FAILED'].includes(state.flashcardsStatus)) {
                state.slides = shuffleArray(state.slides)
            }
            
            state.slides = [goalsSlide, ...state.slides]
        },
        submitFlashcardsActivity() {
            if(this.getters['flashcards/isDefinitionExercise']) {
                this.commit('learning/nextActivity')
            } else {
                this.commit('flashcards/setSubmitted', true)
            }
        },
        setIsRecording(state, data) {
            state.isRecording = data
        },
        setUserAnswer(state, data) {
            state.userAnswer = data
        },
        nextActivity(state) {
            this.commit('learning/setUserAnswer', null)
            state.slideTransition = 'learning-slide-right'

            if (this.getters['learning/isAdditionalSlide']) {
                const slidesToRemove = []

                if (Object.values(ADDITIONAL_SLIDES).includes(this.getters['learning/getCurrentSlide'].type) && slidesToRemove.includes(this.getters['learning/getCurrentSlide'].type)) {
                    state.slides = state.slides.filter(el => el.type !== this.getters['learning/getCurrentSlide'].type);
                }
                
                if(slidesToRemove.includes(this.getters['learning/getCurrentSlide'].type)) {
                    state.currentSlideIndex++;
                    return
                }
            }

            if(this.getters['learning/isFlashcardsActivity']) this.commit('flashcards/handleAnswer', this.getters['flashcards/getAnswer'])

            if(state.currentTaskIndex === this.getters['learning/getCurrentSlide'].activity?.tasks.length - 1 || !this.getters['learning/getCurrentSlide'].activity?.tasks?.length) {
                state.currentSlideIndex++
                state.currentTaskIndex = 0
            } else state.currentTaskIndex++
        },
        previousActivity(state, data) {
            this.commit('learning/setUserAnswer', null)
            state.slideTransition = 'learning-slide-left'

            if(state.currentTaskIndex === 0) {
                state.currentSlideIndex--
                state.currentTaskIndex = this.getters['learning/getCurrentSlide'].activity.tasks.length - 1
            } else state.currentTaskIndex--

            if(this.getters['learning/getCurrentSlide'].activity?.type === 'flashcards') {
                const { exercises, previousExercises } = data.store.state.flashcards

                exercises.unshift(previousExercises.pop())
                data.store.commit('flashcards/setCorrectAnswer')
            }
        },
        handleAdditionalSlides(state) {
            state.additionalSlidesType.push(ADDITIONAL_SLIDES.GOALS, ADDITIONAL_SLIDES.ITERATION_SUMMARY); 

            state.slides.push({
                uuid: uuidv4(),
                type: ADDITIONAL_SLIDES.ITERATION_SUMMARY,
            });
        },
        setProgress(state, data) {
            state.progress = data
        }
    },
    getters: {
        isMobile(state, getters, rootState) {
            return state.mobile > rootState.windowWidth;
        },
        getProgressPercent(state) {
            return state.progress
        },
        isAdditionalSlide(state, getters, rootState) {
            return getters.getAdditionalSlidesType.includes(getters.getCurrentSlideType)
        },
        getCurrentSlide(state) {
            return state.slides[state.currentSlideIndex]
        },
        getCurrentSlideType(state) {
            return state.slides[state.currentSlideIndex]?.type
        },
        isSummarySlide(state, getters) {
            return getters.getCurrentSlideType === 'SUMMARY'
        },
        getAdditionalSlidesType(state) {
            return state.additionalSlidesType
        },
        getCurrentTask(state, getters) {
            return getters.getCurrentSlide.activity?.tasks[state.currentTaskIndex]
                || getters.getCurrentSlide
        },
        getCurrentMedia(state, getters) {
            return getters.getCurrentSlide.media
        },
        getCurrentInstruction(state, getters) {
            return getters.getCurrentSlide.instructions?.[0]
        },
        getUserAnswer(state, getters) {
            const { userAnswer } = state

            if(!userAnswer) return null

            if(Array.isArray(userAnswer) && !userAnswer.length) return null

            if(typeof userAnswer === 'object' && !Object.keys(userAnswer).length) return null

            return userAnswer
        },
        isTaskFinished(state, getters, rootState) {
            if(getters.isFlashcardsActivity) return rootState.flashcards.submitted || rootState.flashcards.dontKnow

            return getters.getCurrentTask.result
        },
        isAnswerCorrect(state, getters, rootState, rootGetters) {
            if (getters.isPreviewMode) return 'correct';
            if (getters.isFlashcardsActivity) {
                if (rootState.flashcards.dontKnow) {
                    handleAudioPlay.methods.playAudio('dont-know');

                    return
                }
        
                if (rootState.flashcards.submitted) {
                    let isCorrect = trimSpecialCharacters(rootGetters['flashcards/getCorrectAnswer'])?.toLowerCase() === trimSpecialCharacters(rootState.flashcards.currentAnswer)?.toLowerCase();

                    if (rootGetters['flashcards/isOrderExercise']) {
                        const correctAnswer = trimSpecialCharacters(rootGetters['flashcards/getCorrectAnswer'])?.toLowerCase();
                        const currentAnswer = trimSpecialCharacters(rootState.flashcards.currentAnswer)?.toLowerCase();

                        const checkHalfCorrect = (correct, current) => {
                            let matches = 0;

                            for (let i = 0; i < Math.min(correct.length, current.length); i++) {
                                if (correct[i] === current[i]) matches++;
                            }
        
                            return matches > 0;
                        };

                        if (!isCorrect && checkHalfCorrect(correctAnswer, currentAnswer)) isCorrect = 'HALF_CORRECT'
                    }

                    return isCorrect;
                }
            }
        
            const result = getters.isTaskFinished;

            if(!result) return;
        
            if (getters.getCurrentSlide.activity?.type === 'multiple_choice') {
                const answers = getters.getCurrentTask.data.answers;
        
                answers.forEach(el => { el = result?.data[el.uuid] });
        
                const userCorrectedAnswers = result?.data.filter(el => !state.userAnswer?.includes(el));
        
                if (userCorrectedAnswers?.length && userCorrectedAnswers?.length < getters.getCurrentTask.result?.data.length) {
                    handleAudioPlay.methods.playAudio('half-correct');
                    return 'HALF_CORRECT';
                }
            }
        
            if (['gaps_empty_spaces', 'gaps_words_in_brackets'].includes(getters.getCurrentSlide.activity.type)) {
                const blanks = getters.getCurrentTask.data.text.filter(el => el.type === 'blank');
        
                blanks.forEach(el => el.answer = result?.data[el.index]);
        
                const processAnswer = (text) => processWord(trimSpecialCharacters(text?.toLowerCase()));
        
                const resultAnswers = blanks
                    .filter(el => processAnswer(state.userAnswer?.[el.index]) !== processAnswer(el.answer))
                    .map(el => el.answer);
        
                if (resultAnswers?.length > 0 && resultAnswers?.length < Object.keys(blanks).length) {
                    handleAudioPlay.methods.playAudio('half-correct');
                    return 'HALF_CORRECT';
                }
            }
        
            if (getters.getCurrentSlide.activity.type === 'gaps_word_list') {
                const blanks = getters.getCurrentTask.data.text.filter(el => el.type === 'blank');
        
                blanks.forEach(el => el.answer = result?.data[el.index]);
        
                const processAnswer = (text) => processWord(trimSpecialCharacters(text?.toLowerCase()));
                
                const resultAnswers = blanks
                    .filter(el => processAnswer(state.userAnswer?.[el.index]) !== processAnswer(el.answer))
                    .map(el => el.answer);

                if (resultAnswers?.length > 0 && resultAnswers?.length < Object.keys(blanks).length) {
                    handleAudioPlay.methods.playAudio('half-correct');
                    return 'HALF_CORRECT';
                }
            }

            if (getters.getCurrentSlide.activity.type === 'right_order') {
                const particallyCorrect = Object.keys(getters.getCurrentTask.data.wordList).filter((el, idx) => {
                    if (state.userAnswer?.[idx] === el) return el;
                });

                if (particallyCorrect.length > 0 && particallyCorrect.length < Object.keys(getters.getCurrentTask.data.wordList).length) {
                    handleAudioPlay.methods.playAudio('half-correct');
                    return 'HALF_CORRECT';
                }
            }

            const isCorrect = result.isCorrect;
            if(!getters.isFlashcardsActivity) handleAudioPlay.methods.playAudio(isCorrect ? 'correct' : 'incorrect');

            return isCorrect;
        },        
        getCorrectAnswer(state, getters, rootState, rootGetters) {
            if(getters.isFlashcardsActivity) return rootGetters['flashcards/getCorrectAnswer']

            const result = getters.isTaskFinished

            if(getters.getCurrentSlide.activity.type === 'right_order') {
                return result.data.map(el => getters.getCurrentTask.data.wordList[el]).join(' ')
            }

            if(getters.getCurrentSlide.activity.type === 'gaps_word_list') {
                const blanks = getters.getCurrentTask.data.text.filter(el => el.type === 'blank');

                blanks.forEach(el => { el = result?.data[el.uuid] });
                
                return blanks
                    .filter(el => !state.userAnswer[el.index] || state.userAnswer[el.index] !== el)
                    .map(el => getters.getCurrentTask.data.wordList[el.index])
                    .join(', ');
            }

            if(['gaps_empty_spaces', 'gaps_words_in_brackets'].includes(getters.getCurrentSlide.activity.type)) {
                const blanks = getters.getCurrentTask.data.text.filter(el => el.type === 'blank');

                blanks.forEach(el => el.answer = result?.data[el.index]);

                const processAnswer = (text) => processWord(trimSpecialCharacters(text?.toLowerCase()));

                return blanks
                    .filter(el => processAnswer(state.userAnswer[el.index]) !== processAnswer(el.answer))
                    .map(el => el.answer)
                    .join(', ');
            }

            if(['true_false', 'single_choice'].includes(getters.getCurrentSlide.activity.type)) {
                const answers = getters.getCurrentTask.data.answers;

                return state.userAnswer === result?.data
                    ? ''
                    : answers.find(el => el.uuid === result?.data).text
            }

            if(['multiple_choice'].includes(getters.getCurrentSlide.activity.type)) {
                const answers = getters.getCurrentTask.data.answers;

                answers.forEach(el => { el = result?.data[el.uuid] });

                return getters.getBackendAnswer.map(el => answers.find(answer => answer.uuid === el).text).join(', ');
            }

            if(['open_ended'].includes(getters.getCurrentSlide.activity.type)) {
                return result?.data.description
            }

            return result?.data
        },
        getBackendAnswer(state, getters) {
            return getters.isTaskFinished?.data
        },
        getAnswerDescription(state, getters) {
            return getters.isTaskFinished?.data.description
        },
        isLastActivity(state, getters) {
            const isLastSlide = state.currentSlideIndex === state.slides.length - 1
            const isLastTask = state.currentTaskIndex === state.slides.at(-1).activity.tasks.length - 1

            return isLastSlide && isLastTask
        },
        isFlashcardsActivity(state, getters) {
            return getters.getCurrentSlide?.activity?.type === 'flashcards'
        },
        isPreviewMode(state, getters) {
            return state.learningStatus === 'COMPLETED' && (state.flashcardsStatus === 'COMPLETED' || !state.flashcardsStatus)
        },
        isFakeActivity(state, getters) {
            if (!getters.getCurrentSlide.activity) return true;
            if (['bullet_points'].includes(getters.getCurrentSlide?.activity.type)) return true;
            return;
        }
    }
};

export default store;
