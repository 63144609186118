<template>
    <div v-if="formattedElements?.length" class="text-listening">
        <TextListeningElement
            v-for="(element, index) in formattedElements"
            :key="index"
            :element="element"
            :showAudio="formattedElements.length > 1" />
        <div
            class="audio-player"
            :key="uuidv4()">
            <AudioPlayer :hideControls="true" :text="formattedElements.join('. ')" />
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import AudioPlayer from '@/components/reusable/AudioPlayer';
import TextListeningElement from '@/components/learning/media/TextListeningElement';

export default {
    name: 'TextListening',
    props: ['data'],
    components: { TextListeningElement, AudioPlayer },
    data() {
        return {
            uuidv4,
            type: null
        };
    },
    computed: {
        ...mapGetters('learning', ['getCurrentMedia']),
        formattedElements() {
            return this.data
                .map(x => {
                    return x.split('<nl>');
                })
                .flat();
        }
    }
};
</script>

<style scoped lang="scss">
.text-listening {
    padding: 8px;
}

.audio-player {
    margin-top: 12px;
}

::v-deep {
    .audio-player .player-wrapper {
        margin: initial;
        width: initial;
    }
}
</style>
