const localesMap = {
    pl: 'pl_PL',
    en: 'en_US',
    cz: 'cs_CZ',
    sk: 'sk_SK',
    es: 'es_ES',
    ua: 'uk_UA',
    pt: 'pt_PT',
    hu: 'hu_HU',
    bg: 'bg_BG',
    ro: 'ro_RO',
}

export default localesMap;