<template>
    <div :class="['side-content skeleton', { 'side-content--mobile': isMobile }]">
        <div class="progress-widget">
            <div class="progress-header">
                <div class="stats-circle">
                    <div class="small dark"></div>
                </div>
                <div class="title-wrapper">
                    <div class="title">
                        <div class="small dark"></div>
                    </div>
                </div>
            </div>
            <div class="progress-content">
                <template v-if="!isMobile">
                    <div v-for="n in 3" :key="n" class="bar">
                        <div class="title">
                            <div class="small dark"></div>
                        </div>
                        <div class="progress-bar">
                            <div class="small dark"></div>
                        </div>
                        <div class="stats">
                            <div class="small dark"></div>
                            <div class="small dark"></div>
                        </div>
                    </div>
                </template>
                <div v-else class="bar">
                    <div class="title">
                        <div class="small dark"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SideSkeleton',
    computed: {
        ...mapState(['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.side-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
    width: 100%;
    max-width: 352px;

    &--mobile {
        max-width: initial;
    }

    .progress-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $white;
        border-radius: 10px;
    }

    .progress-header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 24px;
        border-bottom: 1px solid $gray-200;
        width: 100%;

        .stats-circle {
            .small {
                width: 80px;
                height: 80px;
                margin-bottom: 0;
            }
        }

        .title-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                .small {
                    width: 122px;
                    height: 32px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .progress-content {
        margin: 24px 0;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .bar {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                .small {
                    width: 60px;
                    height: 16px;
                    margin-bottom: 0;
                }
            }
    
            .progress-bar {
                .small {
                    width: 304;
                    height: 6px;
                    margin-bottom: 0;
                    border-radius: 99px;
                }
            }

            .stats {
                display: flex;
                justify-content: space-between;

                .small {
                    width: 30px;
                    height: 16px;
                }
            }
        }

    }

    &--mobile {
        max-width: initial;

        .progress-content {
            margin: 0;
            padding: 0;
            width: 100%;

            .bar {
                padding: 20px 24px;
            }
        }
    }
}
</style>