<template>
    <span class="lesson-duration">{{ duration }} {{ $t('calendar.minute-shortcut') }}</span>
</template>

<script>
export default {
    name: 'LessonDuration',
    props: {
        duration: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.lesson-duration {
    @include font-small-10;
    color: $gray-700;
    padding: 0 4px;
    border-radius: 4px;
    background: $gray-100;
    flex-shrink: 0;
}
</style>