<template>
    <div
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
        class="trans-tooltip"
        :class="{
            'trans-tooltip--top': addDirectionClass('top'),
            'trans-tooltip--bottom': addDirectionClass('bottom'),
        }"
        :style="style.tooltipContent" ref="tooltipRef"
    >
        <span class="trans-tooltip__angle" :style="style.angle"></span>

        <transition-group name="fade" tag="div" class="trans-tooltip__transition">
            <div class="trans-tooltip__area" v-if="ifWordInit" key="1">
                <div class="trans-tooltip__header">
                    <span class="text text--light text--italics mr-8">{{ wordToDisplay.english }}</span>

                    <template v-if="translation">
                        <strong class="text text--bold mr-8">/</strong>

                        <strong class="text text--bold trans-tooltip__translation">
                            {{ translation }}
                            <span 
                                v-if="deeplTranslation"
                                class="info-tooltip">
                                <img src="@/assets/img/info.svg" alt="">
                                <Tooltip :text="$i18n.t('lesson.sidebar.vocabulary.tooltip.the-most-popular')"/>
                            </span>
                        </strong>
                    </template>

                    <div class="trans-tooltip__speakers" v-if="wordToDisplay.pronunciation">
                        <VolumeBtn text="UK" :link="wordToDisplay.pronunciation.url" v-if="wordToDisplay.pronunciation.url"/>
                    </div>

                    <span 
                        v-if="hasDeleteBtn" 
                        @click="sendDeleteEvent" 
                        class="trans-tooltip__delete"
                    >
                        <img :src="trash" alt="Icon">
                    </span>
                </div>

                <div v-if="issetDefinition" class="trans-tooltip__body">
                    <p class="text text--label text--up mb-0">{{ $i18n.t('lesson.sidebar.vocabulary.tooltip.definition') }}</p>

                    <p v-if="definitionFromBackend" class="text text--small mb-0">
                        {{ wordToDisplay.definition }}
                    </p>
                    <p v-else-if="definitionFromApi" class="text text--small mb-0">
                        <span class="text--label text--italics mr-5">({{ partOfSpeech }})</span>
                        {{ definitionFromApi }}
                    </p>

                    <template v-if="exampleFromBackend || exampleFromApi">
                        <p class="text text--label text--up mb-0 mt-16">{{ $i18n.t('lesson.sidebar.vocabulary.tooltip.example') }}</p>

                        <p v-if="exampleFromBackend" 
                            class="text text--small mb-0" 
                            v-html="exampleFromBackend">
                        </p>

                        <p v-else-if="exampleFromApi"
                            class="text text--small mb-0" 
                            v-html="exampleFromApi">
                        </p>
                    </template>
                </div>


                <div v-if="definitionsCounter > 1" class="trans-tooltip__footer">
                    <span class="trans-tooltip__footer-title">
                        {{ $i18n.t('lesson.sidebar.vocabulary.tooltip.footer.definition') }}: {{ definitionCurrent + 1 }} / {{ definitionsCounter }}
                    </span>
                    <div class="trans-tooltip__footer-btns">
                        <Button
                            @click.native="prevDefinition"
                            class="btn--default btn--fullscreen icon--arrow-left"
                            :icon="arrowBlue"
                            :iconSize="16"
                            :disabled="definitionCurrent === 0"
                        />
                        <Button
                            @click.native="nextDefinition"
                            class="btn--default btn--fullscreen"
                            :icon="arrowBlue"
                            :iconSize="16"
                            :disabled="definitionsCounter - 1 === definitionCurrent"
                        />
                    </div>
                </div>
            </div>

            <Loading key="2" v-else />
        </transition-group>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Button from '@/components/reusable/Button.vue';
import VolumeBtn from '@/components/reusable/VolumeBtn';
import Loading from '@/components/reusable/Loading'
import Tooltip from '@/components/reusable/Tooltip'

import langsMap from '@/helpers/langsMap';

import { tooltipCalculations } from '@/mixins/tooltip';
import arrowBlue from '@/assets/img/arrow-blue.svg';
import trash from '@/assets/img/tools/trash.svg';

export default {
    name: 'TooltipTrans',
    components: { VolumeBtn, Loading, Button, Tooltip },
    mixins: [ tooltipCalculations ],
    data() {
        return {
            arrowBlue,
            trash,
            tooltipWidth: 300,
            minTooltipOffset: {
                top: 250,
                bottom: 250,
            },
            wordData: {
                english: '',
                pronunciation: '',
                definition: false,
                definitions: false,
                polish: '',
                examples: '',
            },
            definitionCurrent: 0,
            providerCheckInterval: 0
        }
    },
    async created() {
        this.initWhenProviderExists();
    },
    beforeDestroy() {
        clearInterval(this.providerCheckInterval);

        document.removeEventListener('scroll', this.hideTooltip)
    },
    mounted() {
        if (this.$refs.tooltipRef) {
            this.style.tooltipContent.width = this.tooltipWidth + 'px';
        
            const direction = this.chooseDirection();

            this.setDirectionAndRun(direction);
        }
    },
    computed: {
        ...mapState([ 'window' ]),
        ...mapState( 'tools', [ 'dictionary', 'tooltip' ]),
        ...mapGetters([ 'getCurrentUserLang' ]),
        wordToDisplay() {
            return this.wordData;
        },
        hasDeleteBtn() {
            return this.tooltip.extendedOptions?.hasDeleteBtn;
        },
        hasAudio() {
            return this.wordToDisplay.audio;
        },
        translation() {
            if (this.getCurrentUserLang === 'en') return '';
            return this.wordToDisplay.translation || this.wordToDisplay.polish
        },
        deeplTranslation() {
            return !this.wordToDisplay.polish || langsMap[this.getCurrentUserLang]?.code !== 'pl'
        },
        definitionFromBackend() {
            return this.wordToDisplay.definition
        },
        definitionFromApi() {
            return this.wordToDisplay.definitions[this.definitionCurrent].definition || '';
        },
        exampleFromBackend() {
            return this.wordToDisplay.examples;
        },
        exampleFromApi() {
            return this.wordToDisplay.definitions?.[this.definitionCurrent].example || '';
        },
        definitionsCounter() {
            return this.wordToDisplay.definitionsCounter || 0;
        },
        partOfSpeech() {
            return this.wordToDisplay.definitions[this.definitionCurrent].partOfSpeech || '';
        },
        issetDefinition() {
            return this.wordToDisplay.definition || this.wordToDisplay.definitions?.length
        },
        ifWordInit() {
            return this.wordToDisplay.init
        }
    },
    methods: {
        async initWhenProviderExists() {
            if (!this.$store.state.tools.dictionary.provider) {
                this.providerCheckInterval = setInterval(() => {
                    if (this.$store.state.tools.dictionary.provider) {
                        clearInterval(this.providerCheckInterval);
                        this.initWhenProviderExists();
                    }
                }, 300);
            } else {
                document.addEventListener('scroll', this.hideTooltip)

                const { word } = this.tooltip;

                const wordFromCache = this.checkWordInStoreCache(word.english);
                if (wordFromCache) return this.wordData =  wordFromCache;

                const [{ value: definition }, { value: translation }] = await Promise.allSettled([
                    this.getWordDefinition(word),
                    this.getWordTranslation(word)
                ]);

                const formated = this.formatFinalObject(definition, translation);
                this.wordData = formated;
                this.tooltip.translated.push(formated);
            }
        },
        showTooltip() {
            this.$store.commit('tools/keepShowingTooltip')
        },
        hideTooltip() {
            this.$store.commit('tools/stopShowingTooltip')
        },
        sendDeleteEvent() {
            this.$bus.$emit('deleteWord', this.tooltip.wordId)
        },
        checkWordInStoreCache(word) {
            return this.tooltip.translated.find(el => el.english === word)
        },
        async getWordDefinition(word) {
            if (word.definition) return;
            const response = await this.$store.dispatch('tools/searchDictionaryWordForTooltip', { word })
            return response;
        },
        async getWordTranslation(word) {
            if (this.getCurrentUserLang === 'en') return;
            if (this.getCurrentUserLang === 'pl' && word.polish) return word.polish;

            const response = await this.$store.dispatch('tools/translateWordForTooltip', { 
                word, 
                target_lang: langsMap[this.getCurrentUserLang]?.code
            })
            return response;
        },
        formatFinalObject(definition, translation) {
            let def;
            let trans = { translation };

            if (!definition 
                || (Array.isArray(definition) && !definition.length)
                || (Object.keys(definition).length === 0 && Object.getPrototypeOf(definition) === Object.prototype)) 
            {
                def = { ...this.tooltip.word }
            } else if (definition.error) {
                def = {
                    english: this.tooltip.word.english,
                    definition: this.$i18n.t('lesson.sidebar.vocabulary.tooltip.no-definitions-found')
                }
            } else {
                const { pronunciation } = definition[0];

                const definitions = this.formatDefinitionArray(definition);

                def = {
                    english: this.tooltip.word.english,
                    definition: false,
                    definitions,
                    definitionsCounter: definitions.length,
                    pronunciation
                }
            }

            def = {...def, init: true}

            return { ...def, ...trans }
        },
        formatDefinitionArray(defs) {
            let definitions = [];

            defs.forEach((def) => {
                let sections = def.sections;
                let word = def.word;

                Object.keys(sections).forEach((section) => {
                    if (sections[section] !== null) {
                        let entries = sections[section].entries;

                        entries.forEach((entry) => {
                            let definition = entry.definition;
                            let example = entry.examples;
                            definitions.push({
                                word: word,
                                partOfSpeech: section,
                                definition: definition,
                                example: example[0] || null
                            });
                        });
                    }
                });
            });

            return definitions;
        },
        prevDefinition() {
            this.definitionCurrent = this.definitionCurrent - 1;
        },
        nextDefinition() {
            this.definitionCurrent = this.definitionCurrent + 1;
        }
    }
}
</script>

<style lang="scss" scoped>

.fade-leave-active {
    display: none;
}

.mt-16 {
    margin-top: 16px;
}

.mb-0 {
    margin-bottom: 0;
}

.mr-5 {
    margin-right: 5px;
}

.mr-8 {
    margin-right: 8px;
}

.d-block {
    display: block;
}

.text {
    font-weight: 500;
    white-space: normal;
    font-size: 14px;

    &--label {
        color: $gray-600;
        font-size: 11px;
    }

    &--up {
        text-transform: uppercase;
    }

    &--small {
        font-size: 13px;
        line-height: 20px;
    }

    &--light {
        font-weight: 100;
    }

    &--bold {
        font-weight: 600;
    }

    &--italics {
        font-style: italic;
    }

    &--underline {
        text-decoration: underline;
    }
}

.icon--arrow-left {
    ::v-deep img {
        rotate: 180deg;
    }
}

.trans-tooltip {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: $white;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    border-radius: 10px;
    color: $gray-900;
    z-index: 9999;
    text-align: left;

    &--top {
        &::before {
            bottom: -8px;
        }

        .trans-tooltip__angle {
            border-top: 6px solid $white;
            bottom: -6px;
        }

        .trans-tooltip__footer {
            padding: 8px 16px 16px;
            order: 3;
        }
    }

    &--bottom {
        &::before {
            top: -8px;
        }

        .trans-tooltip__angle {
            border-bottom: 6px solid $white;
            top: -6px;
        }
        
        .trans-tooltip__footer {
            padding: 8px 16px 0;
            order: 1;
        }
    }

    &__area {
        display: flex;
        max-height: inherit;
        flex-direction: column;
    }
    &__transition {
        max-height: inherit;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 8px;
    }

    &__angle {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        left: 50%;
    }

    &__header {
        padding: 14px 45px 14px 16px;
        border-bottom: 1px solid $gray-200;
        line-height: 20px;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-items: center;
        word-break: break-word;
        order: 1;
    }

    &__translation {
        margin-right: 10px;
    }

    &__speakers {
        display: inline-flex;
        align-items: center;
    }

    &__delete {
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
            background-color: $gray-200;
        }
    }

    &__body {
        padding: 16px;
        overflow: auto;
        order: 2;
    }

    &__footer {
        display: flex; 
        justify-content: flex-end;
        align-items: center;

        .btn {
            padding: 1px 2px;
            display: inline-flex;
            background: $white;
            color: $gray-900;
            border-color: $gray-200;
            gap: 0;

            &:hover {
                border-color: $gray-300;
            }

            &:active {
                background: $gray-100;
                border-color:$gray-200;
            }

            &:disabled {
                background: $gray-100;
                color: $gray-500;
                cursor: default;
                pointer-events: none;
                opacity: .5;
            }
        }

        ::v-deep img {
            margin-right: 0;
        }
    }

    &__footer-title {
        @include font-small-12;
        font-weight: 400 !important;
        margin-right: 10px;
    }

    &__footer-btns {
        display: flex;
        justify-content: space-between;
        gap: 3px;
    }
}

.info-tooltip {
    position: relative;
    top: 1px;
    margin-left: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        .tooltip {
            display: block;

            &:first-letter {
                text-transform: capitalize;
            }
        }
    }
}
</style>
