<template>
<div class="messages skeleton">
    <div v-if="!simpleChat" class="sidebar">
        <div class="conversations">
            <div class="item active" style="border-color: #e6e7e9">
                <div class="avatars">
                    <div class="border-wrapper">
                        <div class="avatar avatar-style dark"></div>
                    </div>
                    <div class="avatar avatar-style dark"></div>
                </div>
                <div class="info">
                    <p style="width: 75%" class="label big dark"></p>
                    <p style="width: 50%; margin-top: 3px" class="footer big dark"></p>
                </div>
            </div>
        </div>
        <div class="assets">
            <div class="header">
                <p style="width: 75%" class="label big dark"></p>
            </div>
            <div>
                <div v-for="n in 3" :key="n" class="item clickable">
                <div class="icon dark"></div>
                <div class="info" style="flex: 1">
                    <p style="width: 50%" class="label dark big"></p>
                    <p style="width: 50%" class="data dark small"></p>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chat">
        <div class="box-msg">
            <div v-for="n in 5" :key="n" class="msg" :class="{'current': n % 2}">
                <div class="inner">
                <div class="avatar-wrapper">
                    <div class="avatar avatar-style dark"></div>
                </div>
                <div style="height: 56px; max-width: 100%; box-shadow: none;" :style="simpleChat ? 'width: 150px;' : 'width: 275px;'" class="content dark">
                    <div class="text"></div>
                </div>
                </div>
                <p style="width: 100px; height: 16px; border-radius: 6px" class="footer dark"></p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "MessagesSkeleton",
  props: ['simpleChat']
};
</script>
