<template>
    <div class="lesson-data skeleton" :style="showChat ? 'margin-right: 432px' : ''">
        <div v-if="isMobile" class="top tab dsc dark">
            <div class="header">
                <div style="width: 40px; height: 40px; border-radius: 6px;" class="light"></div>
                <div class="label">
                    <div class="date">
                    <p style="width: 100px;" class="small dark"></p>
                    <p style="width: 100px;" class="hours small dark"></p>
                    <div style="width: 75px; margin-bottom: 0; border-radius: 0;" class="status big light"></div>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: 64px; border-radius: 6px; margin-top: 16px;" class="dsc dark"></div>
            <div class="lector-wrapper light" style="width: 170px; height: 24px; border-radius: 16px; margin-top: 24px; margin-left: 0px;"></div>
            <div class="buttons">
                <div style="width: 150px; height: 40px;" class="btn dark"></div>
                <div style="width: 150px; height: 40px;" class="btn dark"></div>
            </div>
        </div>
        <div v-else class="top tab dsc dark">
            <div class="header" style="display: flex;">
                <div style="width: 72px; height: 72px; border-radius: 12px;" class="light"></div>
                <div class="dsc" style="display: flex; flex-direction: column;">
                    <span style="width: 656px; height: 32px; border-radius: 12px;" class="light"></span>
                    <div class="status"></div>
                    <div class="labels">
                        <div class="label light" style="width: 163px; height: 28px"></div> 
                        <div class="label light" style="width: 152px; height: 28px"></div> 
                        <div class="label light" style="width: 83px; height: 28px"></div> 
                        <div class="lector-wrapper light" style="width: 99px; height: 28px; border-radius: 16px;"></div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div style="width: 150px; height: 40px;" class="btn dark"></div>
                <div style="width: 150px; height: 40px;" class="btn dark"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'SingleLessonSkeleton',
    props: ['showChat'],
    computed: {
        ...mapState(['isMobile']),
    }
}
</script>
