<template>
    <div :class="['flashcards__footer', { [`flashcards__footer--${ getAnswerStatus }`]: getAnswerStatus }]">
        <p
            v-if="!submitted && !dontKnow"
            @click="setDontKnow"
            :class="[
                'flashcards__dont-know',
                { 'flashcards__dont-know--hide': isDefinitionExercise },
                { 'flashcards__dont-know--disabled': dontKnow || submitted },
            ]"
        >
            {{ $t('flashcards.footer.dont-know') }}
        </p>
        <div v-else :class="['info-wrapper', getAnswerStatus]">
            <div class="icon-wrapper">
                <img v-if="['correct', 'incorrect'].includes(getAnswerStatus)" :src="require(`@/assets/img/flashcards/small-${ getAnswerStatus }.svg`)" alt="">
                <span v-else class="icon">{{ getAnswerStatus === 'pending' ? '?' : '!' }}</span>
            </div>
            <span class="text-wrapper">{{ getTextWrapper }}</span>
        </div>
        <Button
            v-if="getCanSubmit"
            @click.native="submit"
            :text="$t('flashcards.footer.check')"
            :disabled="isSubmitDisabled"
            class="primary"
        />
        <Button
            v-else
            @click.native="handleAnswer"
            :text="$t(dontKnow ? 'flashcards.footer.continue' : 'flashcards.footer.next')"
            class="primary"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Button from '@/components/reusable/Button'
export default {
    name: 'FlashcardsFooter',
    components: {
        Button
    },
    created() {
        window.addEventListener('keyup', this.handleKeyUp)
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUp)
    },
    computed: {
        ...mapState('flashcards', ['currentAnswer', 'submitted', 'dontKnow']),
        ...mapGetters('flashcards', ['getCorrectAnswer', 'isDefinitionExercise', 'isChoiceExercise', 'isOrderExercise', 'getCanSubmit', 'getAnswer', 'getAnswerStatus']),
        isSubmitDisabled() {
            if(this.isOrderExercise) return this.getCorrectAnswer.length !== this.currentAnswer?.length

            return !this.currentAnswer
        },
        getTextWrapper() {
            return this.$i18n.t(`flashcards.footer.${ this.getAnswerStatus }`)
        }
    },
    methods: {
        submit() {
            this.$store.commit('flashcards/setSubmitted', true)
        },
        handleAnswer() {
            this.$store.commit('flashcards/handleAnswer', this.getAnswer)
        },
        setDontKnow() {
            this.$store.commit('flashcards/setDontKnow', true)
            if(this.isChoiceExercise) this.$bus.$emit('flashcardsAudio')
        },
        handleKeyUp(e) {
            if(e.key === 'Enter') {
                if(!this.getCanSubmit) this.handleAnswer(this.getAnswer)
                else if(this.currentAnswer) this.submit()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__footer {
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;
    border-top: 1px solid $gray-200;
    padding: 16px 16px 16px 24px;
    z-index: 1;

    @media(min-width: $mobile-flashcards) {
        max-width: 564px;
        border: 1px solid $gray-300;
        border-width: 1px 1px 0 1px;
        position: relative;
        margin-top: auto;
        padding: 16px 16px 16px 24px;
        border-radius: 16px 16px 0px 0px;
    }

    ::v-deep {
        .btn {
            border-radius: 8px;
            font-weight: 500;
        }
    }

    &--correct {
        background: $secondary-light;
        box-shadow: 0px 1px 0px 0px $secondary inset;

        @media(min-width: $mobile-flashcards) {
            border: 1px solid $secondary;
            border-width: 1px 1px 0 1px;
            box-shadow: 0px -1px 0px 0px $white inset;
        }

        ::v-deep {
            .btn {
                border-color: $secondary;
                background: $secondary;

                &:hover {
                    border-color: rgba($secondary-dark, .9);
                    background: rgba($secondary-dark, .9);
                }
            }
        }
    }

    &--pending {
        background: $warning-light;
        box-shadow: 0px 1px 0px 0px $warning-dark inset;

        @media(min-width: $mobile-flashcards) {
            border: 1px solid $warning-dark;
            border-width: 1px 1px 0 1px;
            box-shadow: 0px -1px 0px 0px $white inset;
        }

        ::v-deep {
            .btn {
                border-color: $warning-dark;
                background: $warning-dark;

                &:hover {
                    background: rgba($warning-dark, .9);
                    border-color: rgba($warning-dark, .9);
                }
            }
        }
    }

    &--incorrect {
        background: $danger-light;
        box-shadow: 0px 1px 0px 0px $danger-middle inset;

        @media(min-width: $mobile-flashcards) {
            border: 1px solid $danger-middle;
            border-width: 1px 1px 0 1px;
            box-shadow: 0px -1px 0px 0px $white inset;
        }

        ::v-deep {
            .btn {
                border-color: $danger-middle;
                background: $danger-middle;

                &:hover {
                    background: rgba($danger-middle, .9);
                    border-color: rgba($danger-middle, .9);
                }
            }
        }
    }

    &--half-correct {
        background: $orange-light;
        box-shadow: 0px 1px 0px 0px $orange inset;

        @media(min-width: $mobile-flashcards) {
            border: 1px solid $orange;
            border-width: 1px 1px 0 1px;
            box-shadow: 0px -1px 0px 0px $white inset;
        }

        ::v-deep {
            .btn {
                border-color: $orange;
                background: $orange;

                &:hover {
                    background: rgba($orange, .9);
                    border-color: rgba($orange, .9);
                }
            }
        }
    }
}

.flashcards__dont-know {
    @include font-h4;
    color: $primary;
    cursor: pointer;

    &--hide {
        opacity: 0;
        visibility: hidden;
    }

    &--disabled {
        color: $gray-500;
        pointer-events: none;
    }
}

.info-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    &.incorrect {
        .icon-wrapper {
            background-color: $danger-middle;

            img {
                width: 16px;
                height: 16px;
                min-width: 12px;
            }
        }

        .text-wrapper {
            color: $danger-middle;
        }
    }

    &.correct {
        .icon-wrapper {
            background-color: $secondary;

            img {
                width: 16px;
                height: 16px;
                min-width: 16px;
            }
        }

        .text-wrapper {
            color: $secondary-dark;
        }
    }

    &.pending {
        .icon-wrapper {
            background-color: $warning-dark;

            .icon {
                width: 16px;
                height: 16px;
                min-width: 16px;
            }
        }

        .text-wrapper {
            color: $warning-dark;
        }
    }

    &.half-correct {
        .icon-wrapper {
            background-color: $orange;

            .icon {
                width: 16px;
                height: 16px;
                min-width: 16px;
            }
        }

        .text-wrapper {
            color: $orange;
        }
    }

    .text-wrapper {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.4px;
    }

    .icon-wrapper {
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
        color: $white;
    }
}
</style>