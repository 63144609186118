import { coursePlan } from '@/graphql/queries/learning/coursePlan'
import api from '@/graphql/apiLearning.js'
import router from '@/router';

import langsMap from '@/helpers/langsMap';

const store = {
    state: {
        chosenLevelUuid: null,
        availableLevels: [],
        modules: [],
        loading: true,
        error: false,
        count: null,
        learningTime: null,
        transUi: null
    },
    actions: {
        async initLearningCoursePlan({ state, dispatch, commit }) {
            state.loading = true;

            const { res } = await api(coursePlan, { courseProgramUuid: router.currentRoute.params.coursePlanUuid });

            commit("setLearningCoursePlanUuid", router.currentRoute.params.coursePlanUuid)
            
            if (!res || res.coursePlan === null || !res.coursePlan.modules.length) {
                commit('showError');

                return;
            }

            dispatch('setCoursePlanData', res.coursePlan);
            commit('setTranslateUi')
            
            state.count = state.modules.length;

            state.loading = false;
        },
        setCoursePlanData({ state }, data) {
            state.modules = data.modules;
            state.learningTime = data.learningTime;
            state.language = data.language;
            state.languageLevel = data.languageLevel;
        }
    },
    mutations: {
        setAvailableCourseLevels: (state, data) => {
            state.availableLevels = data.edges.map(el => el.node)
        },
        setLearningCoursePlanUuid: (state, data) => state.chosenLevelUuid = data,
        showError(state) {
            state.error = true;
            state.loading = false;
        },
        setTranslateUi(state) {
            const { shortName: langLvl } = state.languageLevel;
            const { shortName: langCode = 'en' } = state.language;
        
            const extendedLangsMap = {
                ...langsMap,
                eng: {
                    code: 'en',
                    name: 'english'
                }
            };
        
            const regex = /^(A[0-3])/;
        
            if (!langLvl || regex.test(langLvl)) {
                state.transUi = null;
            } else {
                state.transUi = extendedLangsMap[langCode]?.code;
            }
        }
    },
    getters: {
        getLearningCoursePlanModules: state => state.modules,
        getChosenCourseLevelUuid: state => state.chosenLevelUuid,
        getChosenLearningCoursePlan: (state, getters) => getters.getAvailableCourseLevels.find(item => item.uuid === getters.getChosenCourseLevelUuid),
        getAvailableCourseLevels: state => state.availableLevels,
        getLearningTime: state => state.learningTime,
        getAllMaterials: state => state.modules.flatMap(module => module.materials)
    }
}

export default store