import gql from 'graphql-tag'

export const userData = gql`
    {
        configuration {
            modifyMessagesTime
        }
        currentUser {
            uuid
            access {
                space
                elearning
            }
            locale
            timezone
            hourFormat
            dateFormat
            email
            phone
            type
            firstName
            lastName
            avatarUrl
            gender
            isEmailVerified
            student {
                uuid
                isBusiness
                birthDate
                contractAddress {
                    street
                    city
                    postCode
                    countryCode
                }
                invoiceAddress {
                    street
                    city
                    postCode
                    countryCode
                    companyName
                    taxNumber
                }
                company {
                    name
                }
                children {
                    firstName
                    lastName
                    avatarUrl
                    uuid
                }
                studentLessonsLimit
                studentPackagesGroups {
                    type
                }
                registrationData {
                    source
                    registrationReason
                    lessonsCount
                }
                mobileApp {
                    isInstalled
                }
            }
        }
    }
`