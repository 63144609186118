import { mapGetters } from 'vuex';
import { APP_MODES } from '@/router/appModes'

export default {
    computed: {
        ...mapGetters(['isLearningMode', 'isSpaceMode']),
    },
    methods: {
        returnToSpace() {
            if(this.isLearningMode) this.$router.push({ name: APP_MODES.LEARNING.defaultRoute });
            if(this.isSpaceMode) this.$router.push({ name: APP_MODES.SPACE.defaultRoute });
        }
    }
}
