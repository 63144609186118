<template>
    <div class="switch-lector-item__lector-row">
        <div class="switch-lector-item__lector-row-icon info-tooltip">
            <slot name="header"></slot>
        </div>
        <div class="switch-lector-item__restricted-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LectorInfoRow',
    mixins: [],
    props: [ 'modalTitle' ]
}
</script>

<style scoped lang="scss">
    .gap-8 {
        gap: 8px;
    }
    
    .mt-3px {
        margin-top: 3px;
    }

    .mt-5px {
        margin-top: 5px;
    }

    .switch-lector-item__lector-row {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        margin-top: 8px;

        @media(min-width: $tablet-switch-lector) {
            margin-top: 16px;
        }
    }

    .switch-lector-item__lector-row-icon {
        flex-basis: 16px;
        min-width: 16px;
    }

    .switch-lector-item__flag {
        width: 16px;
        height: 16px;
        border-radius: 16px;
    }

    .switch-lector-item__level {
        color: $gray-600;
    }

    .switch-lector-item__certificate {
        display: inline-block;
        background-color: $gray-200;
        padding: 2px 6px;
        border-radius: 4px;
        @include font-small-12;
        line-height: 20px !important;
    }

    .switch-lector-item__restricted-wrapper {
        display: flex;
        width: 100%;
        font-weight: 100;
        flex-wrap: wrap;
        gap: 8px;
    }

    .switch-lector-item__plus-more {
        align-self: end;
        white-space: nowrap;
        width: 32px;
    }

    .switch-lector-item__plus-more-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        @include font-small-12;
        width: 32px;
        background-color: transparent;
        border: 1px solid $gray-200;
        text-align: center;
        padding: 2px 0;

        &.switch-lector-item__plus-more-btn--certificates {
            line-height: 18px !important;
        }
    }

    .info-tooltip {
        position: relative;
        
        &:hover {
            .tooltip {
                display: block;

                &:first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }
</style>