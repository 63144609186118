<template>
    <div class="placement-test-start">
        <img src="@/assets/img/progressTest/placement-test.svg" alt="">
        <p class="title">{{ $t('placement-test.start.title') }}</p>
        <div class="info-box">
            <p class="info-box__small-title">{{ $t('placement-test.start.time-to-do') }}</p>
            <p class="info-box__time"><img src="@/assets/img/clock.svg" alt=""> {{ $t('placement-test.start.time') }}</p>
        </div>
        <div class="info-box">
            <p class="info-box__title">{{ $t('placement-test.start.rules') }}</p>
            <div class="info-box__text" v-html="$t('placement-test.start.description')"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlacementTestStart',
    props: ['data']
}
</script>

<style lang="scss" scoped>
.placement-test-start {
    .title {
        @include font-h2;
        margin: 8px 0 40px;
    }

    .info-box {
        @include font-regular-text;
        text-align: left;
        padding: 24px;
        border-radius: 10px;
        border: 2px solid $gray-200;
        margin-bottom: 16px;

        &__small-title {
            @include font-small-10;
            text-transform: uppercase;
            color: $gray-600;
            margin-bottom: 8px;
        }

        &__time {
            @include font-medium;
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

        &__title {
            @include font-h3;
            margin-bottom: 8px;
        }

        &__text {
            ::v-deep p:not(:last-child) {
                margin-bottom: 24px;
            } 
        }
    }
}
</style>