<template>
    <div class="select-row">
        <div 
            :class="[
                'item',
                { 'item--active': activeItem === item.type }
            ]"
            v-for="item in data"
            :key="item.type"
            @click="setActiveItem(item.type)"
        >
            <p class="item__title">
                {{ item.label }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectRow',
    props: ['data', 'value'],
    data() {
        return {
            activeItem: this.value
        }
    },
    methods: {
        setActiveItem(item) {
            this.activeItem = item
            this.$emit('input', this.activeItem)
        }
    }
}
</script>

<style lang="scss" scoped>
.select-row {
    .item {
        padding: 20px;
        background: $white;
        border: 1px solid $gray-200;
        border-bottom-width: 0px;
        border-left-width: 2px;
        border-right-width: 2px;
        transition: all .25s;
        cursor: pointer;
        position: relative;
        text-align: left;

        @media(min-width: 768px) {
            padding: 20px 32px;
        }

        &--active, &:hover {
            background: $primary-light;
            color: $primary-dark;
        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: -2px;
            top: -2px;
            border: 2px solid transparent;
            z-index: 1;
            transition: all .25s;
        }

        &--active, &:hover {
            &:before {
                border-color: $primary;
            }
        }

        &:first-child {
            $border-radius: 10px 10px 0 0;

            border-top-width: 2px;
            border-radius: $border-radius;

            &:before {
                border-radius: $border-radius;
            }
        }

        &:last-child {
            $border-radius: 0 0 10px 10px;

            border-radius: $border-radius;
            border-bottom-width: 2px;

            &:before {
                border-radius: $border-radius;
            }
        }

        &__title {
            @include font-h3;
        }
    }
}
</style>