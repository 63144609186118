<template>
    <div :class="[
        'controls',
        {'controls--hide-sound-bar': hideSoundBar }
    ]">
        <span
            class="controls__button"
            @click="playPause"
        >
            <img v-if="state === 'playing'" src="@/assets/img/video/pause.svg" alt="" class="icon-video" />
            <img v-else src="@/assets/img/video/play.svg" alt="" class="icon-video" />
        </span>
        <div class="controls__progress">
            <span class="controls__time">{{ timeDisplay.seconds }}</span>
            <ProgressBar
                :percentage-filled="percent * 100"
                @click.native="onSkip"
            />
            <span class="controls__time">{{ timeDisplay.duration }}</span>
        </div>
        <div class="controls__sound-wrapper">
            <span
                @click="mutate"
                class="controls__button"
            >
                <img
                    class="button-sound__icon"
                    src="@/assets/img/video/volume-off.svg"
                    alt=""
                    v-if="isMuted"
                />
                <img
                    class="button-sound__icon"
                    src="@/assets/img/video/volume-high.svg"
                    alt=""
                    v-else
                />
            </span>

            <vue-slide-bar
                v-model="volume"
                :show-tooltip="false"
                :paddingless="true"
                :lineHeight="4"
                :min="0"
                :max="10"
                @input="onChangeVolume"
                class="controls__volume-slider"
            ></vue-slide-bar>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ProgressBar from './ProgressBar'
import VueSlideBar from 'vue-slide-bar';

export default {
    name: 'MediaVideoProgress',
    components: { ProgressBar, VueSlideBar },
    props: {
        duration: {
            type: Number,
            required: true
        },
        seconds: {
            type: Number,
            required: true
        },
        percent: {
            type: Number,
            required: true
        },
        state: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isMuted: false,
            volume: 10,
            volumeBefore: 0,
        }
    },
    computed: {
        ...mapState(['windowWidth']),
        timeDisplay() {
            let duration = {
                minutes: Math.floor(this.duration / 60),
                seconds: Math.floor(this.duration % 60)
            }
            let elapsed = {
                minutes: Math.floor(this.seconds / 60),
                seconds: Math.floor(this.seconds % 60)
            }
            return {
                seconds: `${this.leftPad(elapsed.minutes, 1)}:${this.leftPad(
                    elapsed.seconds,
                    2
                )}`,
                duration: `${this.leftPad(duration.minutes, 1)}:${this.leftPad(
                    duration.seconds,
                    2
                )}`
            }
        },
        hideSoundBar() {
            if (this.type !== 'learning') return;

            return this.windowWidth < 768
        }
    },
    methods: {
        leftPad(data, length, character = '0') {
            let string = data.toString()
            if (string.length < length) {
                return character * (length - string.length) + string
            } else {
                return string
            }
        },
        playPause() {
            if (this.state === 'playing') {
                this.$emit('pause')
            } else {
                this.$emit('play')
            }
        },
        onChangeVolume(payload) {
            if (payload > 0 && payload <= 10) {
                this.isMuted = false;
                this.volume = payload;
                this.$emit('changeVolume', payload / 10);
            } else if (payload === 0) {
                this.volume = 0;
                this.$emit('changeVolume', payload / 10);
                this.isMuted = true;
            }
        },
        mutate() {
            this.isMuted = !this.isMuted;
            if (this.isMuted) {
                this.volume === 0
                    ? this.volumeBefore = 10
                    : this.volumeBefore = this.volume;
                this.volume = 0;
                this.$emit('changeVolume', 0);
            } else {
                this.volume = this.volumeBefore;
                this.$emit('changeVolume', this.volumeBefore);
            }
        },
        onSkip(evt) {
            const target = document.querySelector('.progress-bar');

            let targetPercentage = evt.offsetX / target.offsetWidth
            let targetSeconds = targetPercentage * this.duration

            this.$emit('skip', { 
                targetSeconds: targetSeconds <= 0 ? 0 : targetSeconds, 
                targetPercentage: targetPercentage <= 0 ? 0 : targetPercentage
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.vue-slide-bar-component {
    width: 100%;
    flex-grow: 1;
    padding: 0 10px 0 0;
}

::v-deep .vue-slide-bar {
    background-color: $gray-500 !important;
    width: 100px;

    @media (min-width: $tablet-switch-lector) {
        width: 136px;
    }

    &::before {
        height: 14px;
        content: '';
        background-color: transparent;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-7px);
    }

    .vue-slide-bar-tooltip-container {
        width: 24px !important;
        height: 24px;
        top: -10.5px;
        background: rgba(255,255,255, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 5s ease;

        &::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: transparent;
        }

        &:hover {
            background-color: rgba(255,255,255,0.3);
        }
    }

    .vue-slide-bar-process {
        background-color: $white;
        width: 100px;

        @media (min-width: $tablet-switch-lector) {
            width: 136px;
        }
    }
}

.icon-video {
    width: 20px;
    height: 20px;
}

.icon-video, .button-sound__icon {
    opacity: .8;
    transition: all .25s;
}

.controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    bottom: 0;
    gap: 0;
    max-height: 100px;
    opacity: 1;
    color: $white;
    height: 52px;
    user-select: none;
    background-color: $gray-900;
    min-width: 100%;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    transition: all 0.3s ease;

    &--hide-sound-bar {
        justify-content: flex-start;
        
        .controls__sound-wrapper {
            display: none;
        }
    }

    &__button {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin: 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms cubic-bezier(0.25, 0.8, 0.5, 1);
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background-color: rgba(0,0,0,0.12);
            
            .icon-video, .button-sound__icon {
                opacity: 1;
            }
        }

        .icon {
            display: flex;
            color: $white;
        }
    }

    &__sound-wrapper {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 22px;

        .volume-slider {
            display: flex;
            width: 100%;
            margin-top: 20px;
        }

        .button-sound {
            &__icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__progress {
        display: flex;
        align-items: center;
        min-width: 30px;
        max-width: 300px;
        width: 100%;

        .progress-bar {
            margin: 0 8px;
            cursor: pointer;
        }

        &--student {
            margin-left: 10px;

            .progress-bar {
                margin: 0 8px;
                cursor: pointer;
                pointer-events: none;
            }
        }
    }
}

.controls__time {
    min-width: 40px;
}
</style>
