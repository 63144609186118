var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placement-test-page"},[_vm._m(0),(_vm.loading)?_c('Loading',{attrs:{"size":40}}):_c('MultiStep',{ref:"multiStep",staticClass:"multi-step--top-gap",attrs:{"hideProgress":"","steps":_vm.steps,"isCompleted":_vm.isCompleted,"buttons":{
            back: {
                disabledSteps: [2, _vm.steps.length]
            },
            next: {
                texts: {
                    1: _vm.$i18n.t('placement-test.footer.start')
                }
            },
            close: {
                steps: _vm.isCompleted ? 'all' : [1],
                ..._vm.closeButton
            },
            finish: {
                steps: [_vm.steps.length],
                action: () => this.$router.push({ name: 'dashboard' }),
                text: _vm.$i18n.t('placement-test.footer.finish') 
            },
        }},on:{"setStep":_vm.setStep}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white nav-bar"},[_c('div',{staticClass:"wrapper wrapper--big"},[_c('nav',{staticClass:"nav"},[_c('div',{staticClass:"nav__left"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo-wide.svg"),"alt":"Fluentbe"}})])])])])
}]

export { render, staticRenderFns }