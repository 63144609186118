<template>
    <div :class="['multi-select', { 'multi-select--ios' : isIOSApp }]">
        <div class="multi-select__title">{{ data.mobileTitle }}</div>
        <label class="search">
            <img src="@/assets/img/search-gray-500.svg" alt="">
            <input ref="search" class="search__input" type="text" v-model="search" :placeholder="$t('general.text.placeholder')">
            <img
                :class="['search__close', { 'search__close--active': search.length }]"
                @click="resetSearch"
                src="@/assets/img/close-rounded-gray-500.svg"
                alt=""
            >
        </label>

        <div class="multi-select-list">
            <template v-if="filteredOptions.length">
                <div
                    v-for="option in filteredOptions"
                    :key="option.value"
                    class="multi-select-list__option"
                    @click="handleOptionClick(option)"
                >
                    {{ option.text }}
                    <div :class="['multi-select-list__option-mark', { 'multi-select-list__option-mark--active': checkIfOptionIsSelected(option) }]">
                        <img class="multi-select-list__option-mark-icon" src="@/assets/img/check-light.svg" alt="">
                    </div>
                </div>
            </template>
            <p v-else class="multi-select-list__no-results">{{ $t('multi-select.no-results') }}</p>
        </div>

        <div v-if="data.buttons" class="buttons">
            <Button
                v-if="data.buttons.secondary"
                class="default font-medium"
                @click.native="$emit('secondaryAction')"
                :text="data.buttons.secondary.text"
            />
            <Button
                v-if="data.buttons.primary"
                class="primary font-medium"
                @click.native="$emit('primaryAction', selectedOptions)"
                :text="primaryButtonText"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/reusable/Button'

export default {
    name: 'MultiSelect',
    props: {
        data: Object, 
    },
    components: {
        Button
    },
    data() {
        return {
            search: '',
            selectedOptions: [...this.data.defaultSelected] || [],
            disabled: true
        }
    },
    mounted() {
        if(this.data.focusOnMounted) this.$refs.search.focus()
    },
    computed: {
        ...mapState(['isIOSApp']),
        filteredOptions() {
            return this.data.options.filter(el => el.text.toLowerCase().includes(this.search.toLowerCase()))
        },
        primaryButtonText() {
            const button = this.data.buttons.primary

            return button.showSelectedOptions ? `${ button.text } (${ this.selectedOptions.length })` : button.text
        }
    },
    methods: {
        resetSearch() {
            this.search = ''
        },
        handleOptionClick(option) {
            const isSelected = this.checkIfOptionIsSelected(option)

            if(isSelected) this.selectedOptions = [...this.selectedOptions.filter(el => el.value !== option.value)]
            else this.selectedOptions.push(option)

            this.$emit('input', this.selectedOptions)
        },
        checkIfOptionIsSelected(option) {
            return this.selectedOptions.find(el => el.value === option.value)
        }
    },
    watch: {
        selectedOptions(n) {
            this.disabled = JSON.stringify(n) === JSON.stringify(this.data.defaultSelected)
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .button-text {
        height: 0;
    }
}

.multi-select {
    display: flex;
    flex-direction: column;
    padding: 0 2px;
    background: $white;
    border: 1px solid $gray-200;
    border-radius: 12px;
    box-shadow: 0px 4px 24px 0px rgba(70, 80, 94, 0.16);
    height: 100%
}

.multi-select__title {
    @include font-h3;
    font-size: 18px;
    display: block;
    padding: 20px 16px;
    border-bottom: 1px solid $gray-200;

    @media (min-width: 992px) {
        display: none;
    }
}

.multi-select--ios {
    .buttons {
        padding: 24px 15px 32px 15px !important;
    }
}

.search {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid $gray-200;
    @include font-regular;

    @media (min-width: 992px) {
        @include font-small-12-regular;
    }

    &__input {
        @include font-regular;
        color: $gray-900;
        flex: 1;
        outline: 0;
        border: 0;
        min-width: 1px;
    }

    &__close {
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: all .25s;

        &--active {
            visibility: visible;
            opacity: 1;
        }
    }
}

.multi-select-list {
    padding: 6px 4px;
    margin: 2px 0;
    display: block;
    flex: 1;
    overflow-y: auto;

    &__option {
        @include font-medium;
        color: $black;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-radius: 6px;
        cursor: pointer;
        transition: all .25s;

        &:hover {
            background: $gray-100;
        }

        @media (min-width: 992px) {
            @include font-small-12-regular;
        }
    }

    &__option-mark {
        width: 16px;
        height: 16px;
        padding: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $gray-300;
        border-radius: 50%;
        background: $white;
        transition: all .25s;

        &--active {
            background: $primary;
            border-color: $primary;

            .multi-select-list__option-mark-icon {
                display: block;
            }
        }
    }

    &__option-mark-icon {
        display: none;
        width: 8px;
        height: 8px;
    }

    &__no-results {
        @include font-small-12-regular;
        color: $black;
        text-align: center;
    }
}

.buttons {
    padding: 12px 10px;
    display: flex;
    gap: 12px;
    border-top: 1px solid $gray-200;

    .btn {
        flex: 1;
    }
}
</style>