<template>
    <div>
        <div :class="['photo__wrapper', { 'photo__wrapper--multiple': data.length > 1 }]">
            <div
                class="photo"
                v-for="(image, index) in data"
                :key="image.url"
                @click="expandPhoto">
                <span class="photo__number">{{ index + 1 }}</span>
                <img
                    :src="image"
                    class="img-index" />
            </div>
        </div>

        <PhotoModal
            @close="expanded = false"
            :expanded="expanded"
            :url="expandedPhotoUrl" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PhotoModal from '@/components/learning/media/PhotoModal';

export default {
    name: 'Gallery',
    props: ['data'],
    components: {
        PhotoModal
    },
    data() {
        return {
            expanded: false,
            expandedPhotoUrl: null,
            type: null
        };
    },
    computed: {
        ...mapGetters('learning', ['getCurrentMedia']),
    },
    methods: {
        expandPhoto(event) {
            this.expanded = true;
            this.expandedPhotoUrl = event.target.src;
        }
    }
};
</script>

<style scoped lang="scss">
.photo__wrapper {
    position: relative;
    margin: 0 auto;
    width: 412px;
    max-width: 100%;

    &--multiple {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        @media (min-width: $mobile-learning) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        .photo {
            max-width: 200px;
            width: 100%;
            height: 0;
            padding-bottom: 60%;

            img {
                position: absolute;
            }

            @media (min-width: $mobile-learning) {
                height: 120px;
                padding-bottom: 0;

                img {
                    position: static;
                }
            }
        }
    }
}
.photo {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    cursor: pointer;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        border: 1px solid var(--grayscale-gray-600, #808793);
        box-shadow: 0px 5px 10px 0px rgba(70, 80, 94, 0.16);
    }
}

.photo__number {
    @include font-small-10;
    position: absolute;
    width: 18px;
    height: 16px;
    top: 4px;
    right: 4px;
    background-color: $white;
    border-radius: 50%;
    z-index: 1;
    color: $gray-900;
    text-align: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
}
</style>
