import gql from 'graphql-tag'

export const placementTest = gql`
    {
        uuid
        status
        questions {
            uuid
            name
            type
            question
            media {
                type
                data
            }
            answers {
                uuid
                answer
                isSelected
                isCorrect
            }
        }
        result {
            languageLevel {
                name
                shortName
            }
            score {
                total {
                    score
                    maxScore
                }
                grammar {
                    score
                    maxScore
                }
                vocabulary {
                    score
                    maxScore
                }
                listening {
                    score
                    maxScore
                }
                reading {
                    score
                    maxScore
                }
            }
        }
    }
`