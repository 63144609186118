<template>
    <Button
        v-if="properties.displayCondition"
        @click.native="clickBtn"
        :loading="$store.state.schedule.triggers[this.placement].loading"
        :disabled="!getLanguageLevel && getHavePackage || properties.loading"
        :icon="properties.icon ? (!getLanguageLevel && getHavePackage ? calendarDisabled : calendar) : null"
        :class="properties.class"
        :text="properties.text"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import brandAppUrls from '@/mixins/brandAppUrls';
import Button  from '@/components/reusable/Button';

import openBookLessonModal from '@/mixins/schedule/openBookLessonModal';

import calendar from '@/assets/img/calendar-blue.svg';
import calendarDisabled from '@/assets/img/calendar-primary-mid.svg';
import { SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    name: 'BookLessonBtn',
    components: { Button },
    props: ['placement'],
    mixins: [openBookLessonModal, brandAppUrls],
    data() {
        return {
            calendar,
            calendarDisabled
        }
    },
    computed: {
        ...mapGetters([
            'isBusiness',
            'getLanguageLevel',
            'getStudentCanBook',
            'getHavePackage',
            'atLeastOneSharedOrGroupCourse',
            'getStudentHadLesson',
            'hasAtLeastOneOnePersonPackage',
            'isB2CGroupStudent'
        ]),
        ...mapGetters('coursePlan', [
            'getStudentGroups', 
            'getBookingUrls'
        ]),
        showBookLessonBtnDefault() {
            return this.getStudentCanBook || (!this.isBusiness && !this.atLeastOneSharedOrGroupCourse)
        },
        properties() {
            const coursePlanPlacement = () => ({
                action: ['coursePlanNoPackage', 'coursePlanNoPackageMobileBookBtn'].includes(this.placement)
                    ? this.defaultAction
                    : this.coursePlanAction,
                icon: ['coursePlanButton', 'coursePlanNoPackage'].includes(this.placement),
                class: ['secondary'],
                text: this.$i18n.t('new-course-plan.book-lesson-btn'),
                displayCondition: this.showBookLessonBtnDefault
            });

            const propsMap = {
                nav: {
                    action: this.defaultAction,
                    icon: true,
                    class: ['secondary font-medium book'],
                    text: this.isB2CGroupStudent ? this.$i18n.t('nav.book-lesson-start-new-course') : this.$i18n.t('nav.book-lesson'),
                    displayCondition: this.getStudentCanBook 
                        || (this.isB2CGroupStudent)
                        || this.hasAtLeastOneOnePersonPackage
                },
                myLessonsPageButton: {
                    action: this.defaultAction,
                    icon: true,
                    class: ['secondary font-medium book--lessons'],
                    text: this.$i18n.t(this.getStudentHadLesson ? 'lessons.book-next-lesson' : 'lessons.book-lesson'),
                    displayCondition: this.showBookLessonBtnDefault
                },
                dashboardLectorButton: {
                    action: this.emmitAction,
                    icon: false,
                    class: ['secondary'],
                    text: this.$i18n.t('dashboard.lector.book-lesson'),
                    displayCondition: this.showBookLessonBtnDefault
                },
                welcomeWidgetButton: {
                    action: this.defaultAction,
                    icon: false,
                    class: ['primary'],
                    text: this.$i18n.t('dashboard.welcome.book-lesson.btn'),
                    displayCondition: this.showBookLessonBtnDefault
                },
                noNextLessonWidgetButton: {
                    action: this.defaultAction,
                    icon: false,
                    class: ['primary'],
                    text: this.$i18n.t('dashboard.plan-lesson.btn'),
                    displayCondition: this.showBookLessonBtnDefault
                },
                changeLectorWidgetButton: {
                    action: this.defaultAction,
                    icon: false,
                    class: ['secondary'],
                    text: this.$i18n.t('widget.no-package.btn.change-lector'),
                    displayCondition: this.showBookLessonBtnDefault
                },
                coursePlanButton: coursePlanPlacement(),
                coursePlanMobileBookButton: coursePlanPlacement(),
                coursePlanNoPackageMobileBookBtn: coursePlanPlacement(),
                coursePlanNoPackage: coursePlanPlacement(),
            }

            return propsMap[this.placement];
        }
    },
    methods: {
        defaultAction() {
            this.$store.state.schedule.triggers[this.placement].loading = true;
            if (['coursePlanNoPackage', 'coursePlanNoPackageMobileBookBtn'].includes(this.placement)) {
                this.$store.commit('schedule/setScheduleModalContext', 'coursePlan');
            }

            if (['changeLectorWidgetButton'].includes(this.placement)) {
                this.$store.dispatch('schedule/showScheduleModal', { forceScreen: SCHEDULE_SCREEN.SPECIALITY })
            } else {
                this.$store.dispatch('schedule/showScheduleModal', this.getStudentCanBook ? null : { forceScreen: SCHEDULE_SCREEN.SPECIALITY })
            }

            if(this.placement === 'nav') this.$emit('hideNav');
            if(this.isB2CGroupStudent) this.$store.commit('schedule/setModalTitle', this.$i18n.t('schedule.modal-title.new-individual-course'));
            
            this.$gtag({
                category: 'btnClick',
                label: 'Book lesson'
            });
        },

        coursePlanAction() {
            this.$store.state.schedule.triggers[this.placement].loading = true;
            this.$store.commit('schedule/setScheduleModalContext', 'coursePlan');

            this.openBookLessonModal(this.getStudentGroups.find(el => el.node.instantBookingUrl === this.getBookingUrls[0]).node);

            this.$gtag({
                category: 'btnClick',
                label: 'Book lesson'
            });
        },

        emmitAction() {
            this.$emit('runEmmit');
        },
        clickBtn() {
            this.$store.commit('schedule/setTriggerButtonName', this.placement);
            this.properties.action();
        }
    }
}
</script>
