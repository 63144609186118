<template>
    <div class="right-order">
        <DragAndDrop
            :wordList="Object.values(task.data.wordList)"
            :status="status"
            :correctAnswer="correctAnswer || null"
            @input="handleInput"
        />
    </div>
</template>

<script>
import DragAndDrop from '@/components/reusable/DragAndDrop'
import { mapGetters } from 'vuex';

export default {
    name: 'RightOrder',
    props: ['task'],
    components: {
        DragAndDrop
    },
    data() {
        return {
            wordListArray: this.setWordListArray(),
        }
    },
    computed: {
        ...mapGetters('learning', ['isTaskFinished', 'isAnswerCorrect', 'getBackendAnswer', 'isPreviewMode']),
        status() {
            if (!this.isTaskFinished) return
            if (this.isPreviewMode) return 'correct';

            return this.isAnswerCorrect && this.isAnswerCorrect !== 'HALF_CORRECT' ? 'correct' : 'incorrect'
        },
        correctAnswer() {
            if(!this.isPreviewMode) return
            return [...this.getBackendAnswer.map(el => {
                return this.task.data.wordList[el];
            })]
        }
    },
    methods: {
        setWordListArray() {
            const wordListArray = []

            for (const [key, value] of Object.entries(this.task.data.wordList)) {
                wordListArray.push({
                    uuid: key,
                    text: value
                })
            }

            return wordListArray
        },
        handleInput(inputData) {
            const wordListArray = [...this.wordListArray]
            const answerArray = []

            inputData.forEach(el => {
                const wordIndex = wordListArray.findIndex(el2 => el2.text === el)

                if(wordIndex !== -1) {
                    answerArray.push(wordListArray[wordIndex].uuid)
                    wordListArray.splice(wordIndex, 1)
                }
            })

            this.$store.commit('learning/setUserAnswer', answerArray)
        }
    }
};
</script>

<style scoped lang="scss">
.right-order {
    width: 100%;
}

::v-deep .dnd-drop-area {
    min-height: 50px;
    padding: 7px;
}

::v-deep .dnd-drag-tile {
    @include font-medium;
    padding: 7px 15px;
}
</style>
