<template>
    <div :class="['schedule-confirmation__box',
        {'schedule-confirmation__box--book': action === 'book'}]">
        <div class="schedule-confirmation__text">
            <slot name="header"></slot>
        </div>

        <slot></slot>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import backToLectorCard from '@/mixins/schedule/backToLectorCard';
import { SCHEDULE_STATE } from '@/constants/schedule';

export default {
    name: 'ScheduleSummary',
    props: ['modalTitle', 'action', 'responseState'],
    mixins: [backToLectorCard],

    data() {
        return {
            btnPrimaryAction: null,
            btnPrimaryLabel: null,
            btnDefaultAction: null,
            btnDefaultLabel: null
        }
    },
    created() {
        const { modalTitle } = this;
        this.$store.commit('schedule/setModalTitle', modalTitle);

        this.setButtons();
    },
    computed: {
        ...mapState('schedule', ['lectorDetails']),
        ...mapGetters('schedule', [
            'isCancelLessonMode', 
            'isSwitchLectorFlowActive',
            'isLectorDetailsDirectOpened'
        ]),
        recurringState() {
            return this.$store.getters['schedule/isRecurring'];
        }
    },
    methods: {
        setButtons() {
            if (this.action === 'book') this.setBookButtons();
            if (this.action === 'cancel') this.setCancelButtons();
            if (this.responseState === SCHEDULE_STATE.BOOK_SUCCESS) this.setBookSuccessButtons();
            if (this.responseState === SCHEDULE_STATE.CANCEL_SUCCESS) this.setCancelSuccessButtons();
            if (this.responseState === SCHEDULE_STATE.CANCEL_CONFIRMATION) this.setCancelConfirmationButtons();

            const { btnDefaultAction, btnDefaultLabel, btnPrimaryAction, btnPrimaryLabel } = this;

            this.$store.commit('schedule/setScheduleDefaultButton', { btnDefaultAction, btnDefaultLabel });
            this.$store.commit('schedule/setSchedulePrimaryButton', { btnPrimaryAction, btnPrimaryLabel });
        }, 
        setBookButtons() {
            this.btnPrimaryAction = 'submit', 
            this.btnPrimaryLabel = `${this.$t('calendar-confirmation.book-it')} ${this.recurringState ? this.$t('calendar.lesson-singular1') : this.$t('calendar.lesson-singular2')}`
            this.btnDefaultAction = this.lectorDetails 
                ? this.backToLectorCard : this.isSwitchLectorFlowActive 
                    ? 'backToSwitchLectorList' 
                    : 'showCalendar', 
            this.btnDefaultLabel = this.isLectorDetailsDirectOpened
                ? this.$t('modal.lector-details.direct.book-or-cancel-screen.btn.default') 
                : this.$t('calendar-confirmation.back-to-date-selection')
        },
        setCancelButtons() {
            this.btnPrimaryAction = 'showCancelConfirmation', 
            this.btnPrimaryLabel = `${this.$t('calendar-confirmation.cancel')} ${this.recurringState ? this.$t('calendar.lesson-singular1') : this.$t('calendar.lesson-singular2')}`
            this.btnDefaultAction = this.lectorDetails 
                ? this.backToLectorCard : this.isCancelLessonMode 
                    ? 'hideScheduleModal' 
                    : 'showCalendar', 
            this.btnDefaultLabel = this.lectorDetails 
                ? this.$t('modal.lector-details.direct.book-or-cancel-screen.btn.default') : this.isCancelLessonMode 
                    ? this.$t('calendar.courses.cancel') 
                    : this.$t('calendar-confirmation.back-to-date-selection')
        },
        setBookSuccessButtons() {
            this.btnPrimaryAction = 'showCalendar';
            this.btnPrimaryLabel = this.$store.state.schedule.switchLector.activeFlow 
                ? this.$t('switch-lector.exit') : this.$t('calendar-confirmation.back-to-calendar');
        },
        setCancelSuccessButtons() {
            this.btnPrimaryAction = this.lectorDetails 
                ? this.backToLectorCard : 'showCalendar';
            this.btnPrimaryLabel = this.$t('calendar-confirmation.back-to-calendar');
        },
        setCancelConfirmationButtons() {
            this.btnPrimaryAction = 'cancel', 
            this.btnPrimaryLabel = this.$t('calendar.cancel-lesson.confirm');
            this.btnDefaultAction = 'hideCancelConfirmation';
            this.btnDefaultLabel = this.$t('calendar.back');
        }
    }
}
</script>