import { lessonFeedbackSurvey } from '@/graphql/queries/lessonFeedbackSurvey'
import api from '@/graphql/api.js'

const getLessonSurveyData = async lessonUuid => {
    const {res, error} = await api(lessonFeedbackSurvey, {
        lessonUuid
    })

    let data = res.currentUser.student.lessonFeedbackSurvey
    if(!data?.selected.length || !data?.remaining.length) data = null

    return {data, error}
}

export default getLessonSurveyData