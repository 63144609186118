<template>
    <label class="input"> {{data.label}}
        <textarea 
            :name="data.name"  
            :disabled="data.disabled" 
            :value="value === true ? '' : value" 
            @change="onChange"
            rows="3"
            ref="textarea"
            :placeholder="$i18n.t(`general.text.placeholder`)"
        ></textarea>
    </label>
</template>

<script>
export default {
    name: 'Textarea',
    props: {
        data: {
            default: Object
        },
        value: {
            default: String
        }
    },
    mounted() {
        if(this.value === true) this.$refs.textarea.focus()
    },
    methods: {
        onChange($event) {
            this.$emit('input', $event.target.value)
        }
    }
}
</script>
