<template>
    <div>
        <Accordion
            @clicked="handleAccordion"
            :isExpanded="isExpanded"
            :rotateArrows="true"
        >
            <template v-slot:header>
                <div class="header">
                    <img
                        class="avatar-style"
                        width="40"
                        height="40"
                        :src="setAvatar(lesson.user.avatarUrl)"
                        alt=""
                    >
                    <p class="header__subtitle">{{ $t('single-lesson.teacher-feedback.subtitle') }}</p>
                </div>
            </template>
            <template v-slot:content>
                <div class="content">
                    <div v-if="summary">
                        <p class="content__title">{{ $t('single-lesson.teacher-feedback.summary-title', { teacher: lesson.user.firstName }) }}</p>
                        <p class="content__dsc" v-html="summary"></p>
                    </div>
                    <div v-if="recommendations">
                        <p class="content__title">{{ $t('single-lesson.teacher-feedback.recommendations-title') }}</p>
                        <p class="content__dsc" v-html="recommendations"></p>
                    </div>
                </div>
            </template>
        </Accordion>
    </div>
</template>

<script>
import Accordion from '@/components/reusable/Accordion'
import setAvatarMixin from '@/mixins/setAvatar'

export default {
    name: 'TeacherFeedback',
    props: {
        lesson: Object
    },
    components: {
        Accordion
    },
    data() {
        return {
            isExpanded: true
        }
    },
    mixins: [setAvatarMixin],
    computed: {
        summary() {
            return this.lesson.teacherFeedback.summary
        },
        recommendations() {
            return this.lesson.teacherFeedback.recommendations
        }
    },
    methods: {
        handleAccordion() {
            this.isExpanded = !this.isExpanded
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    gap: 12px;

    &__subtitle {
        @include font-h3;
        color: $gray-900;
    }
}

.content {
    padding: 20px 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $gray-700;
    position: relative;

    &:before {
        content: '';
        width: calc(100% - 48px);
        height: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $gray-100;
    }

    &__title {
        @include font-medium;
        margin-bottom: 8px;
    }

    &__dsc {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
}

::v-deep .accordion {
    border: 0;
    margin-bottom: 24px;
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);

    &__header {
        padding: 24px 24px 20px;
    }

    &__content {
        padding: 0;
    }

    &__arrow {
        align-self: center;
        margin-top: 0;
        width: 18px;
        height: 18px;
        filter: grayscale(1);
    }
}
</style>