import gql from 'graphql-tag'

export const studentPreferences = gql`
    query($locale: String!){
        configuration {
            studentPreferences(input: {locale: $locale}) {
                name
                courseTypes
                titles {
                    step
                    title
                    subTitle
                    translateParameters
                }
                tips {
                    step
                    title
                    description
                    translateParameters
                }
                actions {
                    step
                    fieldName
                    type
                    value
                    values
                }
                structure {
                    step
                    name
                    label
                    type
                    required
                    multiselect
                    asRadio
                    onCheck
                    defaultValue
                    rangeMin
                    rangeDateMax
                    rangeDateMin
                    rangeMax
                    options {
                        label
                        value
                        description
                        default
                        promoted
                    }
                    actions {
                        fieldName
                        type
                        value
                        values
                    }
                    translateParameters
                    order
                }
            }
        }
    }
`