export function metaInfo(path, options = {}) {
    const { locales, route, i18n } = options;
    
    if (!locales || !route || !i18n ) return {};

    const mapper = {
        login: {
            title: 'login.html-title'
        },
        register: {
            title: 'register-page.html-title'
        }
    };

    const linkTags = Object.keys(locales).map(locale => {
        return {
            rel: 'alternate',
            hreflang: locales[locale],
            href: `${ window.location.origin }/${ locale }/${ route.name }`
        }
    });

    linkTags.push({
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${ window.location.origin }/en/${ route.name }`
    });

    linkTags.push({ 
        vmid: 'canonical', 
        rel: 'canonical', 
        href: window.location.origin + window.location.pathname 
    });

    return {
        title: `${ i18n.t(mapper[path].title) } | ${ i18n.t('html-default-title') }`,
        meta: [
            { name: 'robots', content: 'noindex, nofollow' },
            { vmid: 'og:title', property: 'og:title', content: `${ i18n.t(mapper[path].title) } | ${ i18n.t('html-default-title') }` },
            { vmid: 'description', name: 'description', content: i18n.t('main.meta.description') },
            { vmid: 'og:description', property: 'og:description', content: i18n.t('main.meta.description') },
            { vmid: 'og:type', property: 'og:type', content: 'website' },
            { vmid: 'og:url', property: 'og:url', content: `${ window.location.origin }/${ route.params.locale }/${ path }`},
            { vmid: 'og:image', property: 'og:image', content: require('@/assets/img/logo-full.svg') }
        ],
        htmlAttrs: {
            lang: locales[route.params.locale]
        },
        link: linkTags
    };
}
