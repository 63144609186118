<template>
    <div class="header">
        <p class="title">{{ title }}</p>
        <div class="actions">
            <a v-if="download" :href="download" class="download">
                <img src="@/assets/img/gallery/download.svg" alt="" />
            </a>
            <img v-if="close" class="close" src="@/assets/img/close.svg" alt="" @click="hide" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalHeader',
    props: {
        title: {},
        download: {},
        close: {
            default: true
        }
    },
    methods: {
        hide() {
            this.$emit('hide')
        }
    }
}
</script>
