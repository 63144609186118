import { extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
import { i18n } from '@/i18n/i18n'
import moment from 'moment'

const rules = [
    {
        name: 'required',
        rule: required,
        params: ['type'],
        message: (name, {type}) => {
            switch(type) {
                case 'email' : return i18n.t('front-validator.field-required.email')
                case 'password' : return i18n.t('front-validator.field-required.password')
                case 'repeatPassword' : return i18n.t('front-validator.field-required.repeat-password')
                default : return i18n.t('front-validator.field-required')
            }
        }
    },
    {
        name: 'email',
        rule: email,
        message: () => i18n.t('front-validator.email-format')
    },
    {
        name: 'min',
        rule: min,
        params: ['length'],
        message: (name, {length}) => i18n.t('front-validator.password-too-short', {length})
    },
    
]

rules.forEach(({name, rule, params, message}) => {
    extend(name, {
        ...rule,
        params,
        message
    });
})

extend('check', {
    validate(value, {checkedValue}) {
        return value === checkedValue
    },
    params: ['checkedValue', 'type'],
    message: (name, {type}) => {
        switch(type) {
            case 'repeatPassword' : return i18n.t('front-validator.passwords-dont-match')
            default : return i18n.t('front-validator.fields-dont-match')
        } 
    }
})

extend('date', {
    validate(value) {
        return moment(value, 'YYYY-MM-DD').isValid()
    },
    message: () => {
        return i18n.t('validator.field_has_incorrect_integer_type')
    }
})