var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"schedule-calendar__day",class:{'inactive': _vm.inactive || _vm.hasNoSlots}},[_c('ScheduleCalendarHeader',{attrs:{"date":this.date,"inactive":this.inactive,"slots":this.slots}}),_vm._l((_vm.slots),function(slot,index){return _c('div',{key:slot.startAt,staticClass:"schedule-calendar__hour info-tooltip",class:{ 
            inactive: _vm.inactive,
            'info-tooltip--first': index === 0,
            'schedule-calendar__hour--booked': slot.booked, 
            'schedule-calendar__hour--pending': slot.lesson && slot.lesson.status === 'pending',
            'schedule-calendar__hour--match-filters': _vm.markMatchedFilters && _vm.isDateBetweenFilterRanges(slot.startAt),
            'schedule-calendar__hour--mobile': _vm.isMobileDevice
        },attrs:{"data-slot-date":slot.startAt},on:{"click":function($event){$event.preventDefault();return _vm.selectSlot(slot)}}},[((slot.booked || slot.lesson && slot.lesson.status === 'pending') && !_vm.isMobileDevice)?_c('Tooltip',{attrs:{"text":`${_vm.tooltipText(slot)}`}}):_vm._e(),_vm._v(" "+_vm._s(_vm.parseHour(slot.startAt))+" ")],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }