import gql from 'graphql-tag'

export const profileSecurity = gql`
    {
        currentUser {
            passwordSet
            externalIntegrations {
                uuid
                externalId
                type
            }
        }
    }
`