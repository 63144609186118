import sound from '@/assets/sound.mp3'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            audioInterval: null,
            soundPlayed: false,
            soundIsPlaying: false
        }
    },
    computed: {
        ...mapState('media', ['audioVolume'])
    },
    methods: {
        async startAudioInterval(stream) {
            clearInterval(this.audioInterval)
            
            const audioContext = new AudioContext()
            const source = audioContext.createMediaStreamSource(stream)
            const analyser = audioContext.createAnalyser()
            analyser.fftSize = 512
            analyser.smoothingTimeConstant = 0.4
            source.connect(analyser)
            const volumes = new Uint8Array(analyser.frequencyBinCount)

            this.audioInterval = setInterval(() => {
                analyser.getByteFrequencyData(volumes)
                const sum = volumes.reduce((a, value) => a + value, 0)
                const average = sum / volumes.length
                this.audioVolume.input = average
            }, 10)
        },
        async playSound() {
            const audio = new Audio()

            await this.$store.dispatch('media/setSpeaker', audio)

            audio.src = sound
            
            audio.onloadedmetadata = () => {
                audio.play()
                this.audioVolume.output = 100
                this.soundIsPlaying = true
            }
            
            audio.onended = () => {
                this.audioVolume.output = 0
                this.soundIsPlaying = false
                this.soundPlayed = true
            }
        }
    }
}