<template>
    <Modal :class="[
        'ask-ai-modal', 
        { 'ask-ai-modal--mobile': isMobile },
        { 'ask-ai-modal--ios': isIOSApp }
    ]" @hide="hide">
        <div class="modal-inner">
            <div class="header">
                <div class="title-wrapper">
                    <img width="24" height="24" src="@/assets/img/tools/askAI-primary-icon.svg" alt="Add">
                    <span class="title">{{ $t('ask-ai.modal.title') }}</span>
                </div>
                <NameSwitch
                    v-if="!loading && !isMobile"
                    @input="toggleChanged"
                    :firstText="$t('ask-ai-toggle.talk')"
                    :secondText="$t('ask-ai-toggle.play')"
                    :checked="askAIModal.activeTab === 'game'"
                    :disabled="isOnlyOneTab"
                />
                <div class="actions">
                    <img width="20" height="20" class="close" src="@/assets/img/close.svg" alt="" @click="hide">
                </div>
            </div>
            <template v-if="!loading">
                <div class="filters-wrapper">
                    <span class="title" v-html="filtersTitle"></span>
                    <Tags @newTag="changedTag" :tags="getFilters" :activeTagName="getActiveTagName"/>
                </div>
                <div ref="hintsContainer" class="hints-container">
                    <AskAIHint
                        class="hint"
                        v-for="prompt in getPrompts"
                        :key="prompt.node.uuid"
                        :hint="prompt.node"
                        @click.native="handleHint(prompt.node)"
                    />
                </div>
                <div v-if="isMobile" class="switch-mobile">
                    <NameSwitch
                        v-if="!loading"
                        @input="toggleChanged"
                        :firstText="$t('ask-ai-toggle.talk')"
                        :secondText="$t('ask-ai-toggle.play')"
                        :checked="askAIModal.activeTab === 'game'"
                        :disabled="isOnlyOneTab"
                    />
                </div>
            </template>
            <Loading :size="40" v-else/>
        </div>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import NameSwitch from '@/components/reusable/NameSwitch';
import AskAIHint from '@/components/tools/askAI/AskAIHint';
import Tags from '@/components/reusable/Tags';
import Loading from '@/components/reusable/Loading';
import modalMixin from '@/mixins/modal';
import api from '@/graphql/api.js'
import { teacherAIModal } from '@/graphql/queries/teacherAIModal'

export default {
    name: 'AskAIModal',
    mixins: [modalMixin],
    components: { NameSwitch, AskAIHint, Tags, Loading },
    data() {
        return {
            loading: false
        }
    },
    async created() {
        document.documentElement.classList.add('overflow-y-hidden')
        if(!this.askAIModal.ready) await this.getModalData()
    },
    beforeDestroy() {
        if(!document.querySelector('.modal')) document.documentElement.classList.remove('overflow-y-hidden')
    },
    methods: {
        async getModalData() {
            this.loading = true
            const { res } = await api(teacherAIModal)

            this.askAIModal.talk.prompts = res.teacherAIChatSuggestionsList.edges
            this.askAIModal.talk.filterTags = this.getTags(res.teacherAIChatSuggestionsList)
            if (!this.askAIModal.talk.prompts.length) this.askAIModal.activeTab = 'game';
            this.askAIModal.game.prompts = res.teacherAIGamesList.edges
            this.askAIModal.game.filterTags = this.getTags(res.teacherAIGamesList)
            if (!this.askAIModal.game.prompts.length) this.askAIModal.activeTab = 'talk';
            this.askAIModal.ready = true
            this.loading = false
        },
        getTags(data) {
            return [...new Set(data.edges.map(el => el.node.type))]
        },
        hide() {
            this.askAIModal.open = false;
        },
        changedTag(newTag) {
            if (newTag.toLowerCase() === this.getActiveTagName) return;

            this.askAIModal[this.getActiveTab].filter = newTag
            this.$refs.hintsContainer.scrollTop = 0
        },
        toggleChanged(isPlay) {
            this.askAIModal.activeTab = isPlay ? 'game' : 'talk';
            this.$refs.hintsContainer.scrollTop = 0
        },
        async handleHint(data) {
            this.askAIModal.open = false

            if('game' in data) {
                if(data.game) this.$store.dispatch('tools/getTeacherAIGame', data)
                else this.$store.dispatch('tools/startTeacherAIGame', data)
            } else {
                if(this.askAI.game) {
                    this.askAI.loading = true
                    this.askAI.messages = []
                    this.askAI.game = null

                    await this.$store.dispatch('tools/getTeacherAIChatMessages')
                }
                
                this.$bus.$emit('askAIModalHint', data)
            }
        }
    },
    computed: {
        ...mapState('tools', ['askAI', 'askAIModal']),
        ...mapState(['isMobile', 'isIOSApp']),
        filtersTitle() {
            const isTalkTab = this.getActiveTab  === 'talk'

            let wrappedText = isTalkTab
                ? this.$t('ask-ai.modal.filters.talk.teacher')
                : this.$t('ask-ai.modal.filters.game.activities')

            return this.$t(`ask-ai.modal.filters.${ this.getActiveTab }.title`, { name: `<span>${ wrappedText }</span>` });
        },
        getActiveTagName() {
            return this.askAIModal[this.getActiveTab].filter
        },
        getPrompts() {
            const activeTab = this.askAIModal[this.getActiveTab]

            if(activeTab.filter === 'all') return activeTab.prompts

            return activeTab.prompts.filter(el => el.node.type === activeTab.filter)
        },
        getFilters() {
            return ['all', ...this.askAIModal[this.getActiveTab].filterTags]
        },
        getActiveTab() {
            return this.askAIModal.activeTab
        },
        isOnlyOneTab() {
            return !this.askAIModal.game.prompts.length || !this.askAIModal.talk.prompts.length
        }
    }
}
</script>

<style lang="scss" scoped>
    .ask-ai-modal {
        z-index: 9999999;

        &--mobile {
            padding: 0;
            height: 100%;

            .modal-inner {
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                border-radius: 0;
                max-width: none !important;
                min-height: none !important;
            }

            .hints-container {
                overflow: scroll;
                height: 100%;
                justify-content: center !important;
                padding: 24px !important;
                max-height: none !important;

                .hint {
                    max-width: none !important;
                    width: 100%;

                    ::v-deep .ask-ai-hint__content {
                        height: 100%;
                    }

                    ::v-deep .ask-ai-hint__icon {
                        align-self: center !important;
                    }
                }
            }
        }

        &--ios {
            .modal-inner {
                .switch-mobile {
                    padding: 16px 0 28px !important;
                }
            }
        }

        .modal-inner {
            width: 100%;
            max-width: 680px;
            border: 1px solid $gray-200 !important;
            box-shadow: 0px 5px 10px 0px rgba(70, 80, 94, 0.16);

            @media(min-width: $mobile-tools) {
                min-height: 680px;
            }

            .loading {
                display: flex;
                min-height: 411px;
                justify-content: center;
                align-items: center;
            }

            .header {
                padding: 12px;

                .title-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    min-width: 192.5px;

                    .title {
                        @include font-h4;
                        max-width: 100%;
                        text-overflow: none;
                    }
                }

                .actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;

                    @media(min-width: $mobile-tools) {
                        min-width: 192.5px;
                    }
                }
            }

            .filters-wrapper {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 24px 0 20px;
                margin: 0 24px;
                border-bottom: 1px solid $gray-200;

                .title {
                    @include font-h3;
                    font-weight: 400;
                    color: $gray-900;
                }
            }

            .hints-container {
                display: flex;
                justify-content: flex-start;
                padding: 24px 0px 24px 24px;
                gap: 18px 18px;
                flex-wrap: wrap;
                max-height: 506px;
                overflow: auto;

                .hint {
                    max-width: 100%;

                    @media(min-width: $mobile-tools) {
                        min-width: 306px;
                        max-width: 306px;
                    }

                    ::v-deep .ask-ai-hint__icon {
                        margin-left: auto;
                        align-self: flex-start;
                    }
                }
            }

            .switch-mobile {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px 0;
                border-top: 1px solid $gray-100;
            }
        }
    }
</style>