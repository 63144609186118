<template>
    <div class="learning">
        <LearningHeader />
        <div :class="['learning__wrapper', { 'learning__wrapper--flashcards': isFlashcardsActivity }]">
            <Loading v-if="loading" :size="40" />
            <template v-else>
                <LearningContent />
                <LearningFooter/>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import accessStorage from '@/functions/accessStorage'
import LearningHeader from '@/components/learning/LearningHeader'
import LearningContent from '@/components/learning/LearningContent'
import LearningFooter from '@/components/learning/LearningFooter'
import Loading from '@/components/reusable/Loading'

export default {
    name: 'Learning',
    components: {
        LearningHeader,
        LearningContent,
        LearningFooter,
        Loading
    },
    data() {
        return {
            learningHearthBeatInterval: null,
            requestInProgress: false
        }
    },
    created() {
        this.$store.dispatch('learning/getData', { uuid: this.$route.params.uuid })
        if(!this.checkModule() && this.$route.params.coursePlanUuid) this.$store.dispatch('learningCoursePlan/initLearningCoursePlan');
    },
    beforeDestroy() {
        clearInterval(this.learningHearthBeatInterval);
    },
    mounted() {
        if(!sessionStorage.getItem('learningPreview')) {
            this.sendHeartbeat();

            this.learningHearthBeatInterval = setInterval(() => {
                if (document.visibilityState === 'hidden') return;

                this.sendHeartbeat();
            }, 60000)
        }
    },
    computed: {
        ...mapState(['windowWidth']),
        ...mapState('learning', ['loading']),
        ...mapGetters('learning', ['isAdditionalSlide', 'getCurrentSlide', 'isFlashcardsActivity']),
        ...mapGetters('learningCoursePlan', ['getLearningCoursePlanModules']),
    },
    methods: {
        sendHeartbeat() {
            if (this.requestInProgress) return;

            this.requestInProgress = true;

            axios.get(
                `${ process.env.VUE_APP_SCHOOL_URL }elearning/heartbeat/${ this.$route.params.uuid }`,
                {
                    headers: {
                        'Authorization': `Bearer ${ accessStorage('authToken') }`,
                        'Content-Type': 'application/json'
                    }
                }
            ).then(() => {
                this.requestInProgress = false;
            }).catch(err => {
                this.requestInProgress = false;
                console.error('Error in heartbeat request:', err);
            });
        },
        checkModule() {
            return this.getLearningCoursePlanModules?.find(el => el.materials?.find(el2 => el2.materialUuid === this.$route.params.uuid))
        }
    },
    watch: {
        'getCurrentSlide.uuid': {
            handler() {
                window.scrollTo(0, 0)
            }
        },
        '$route.params.uuid': {
            handler() {
                this.$store.dispatch('learning/getData', { uuid: this.$route.params.uuid })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$headerHeight: 56px;

.learning {
    padding: $headerHeight 0 0;
    background: $gray-100;
    min-height: var(--vh);
    display: flex;
    flex-direction: column;

    @media(min-width: $mobile-learning) {
        padding: ($headerHeight + 24px) 24px 24px;
    }

    &__wrapper {
        background: $white;
        flex: 1;
        display: flex;
        flex-direction: column;

        @media(min-width: $mobile-learning) {
            border-radius: 10px;
        }

        .loading {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &--flashcards {
            padding-top: 16px;

            @media(min-width: $mobile-learning) {
                padding-top: 78px;
            }
        }
    }

    &::after {
        content: '';
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 48px;
        background: $gray-100;
    }
}
</style>