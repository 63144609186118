<template>
    <div :class="['audio-levels']">
        <audio :src="audioSource" ref="audioPlayer">
            <source type="audio/mp3">
        </audio>
        
        <div class="audio-levels-control">
            <Button
                v-if="isPlaying"
                class="button btn--secondary btn--pause"
                @click.native="pauseAudio()"
                :icon="require('@/assets/img/audioPlayer/pause.svg')"
            />
            <Button
                v-else
                :class="[
                    'button btn--secondary btn--play',
                    { 'btn--disabled': loading || isRecording }
                ]"
                @click.native="playAudio()"
                :icon="require('@/assets/img/audioPlayer/play.svg')"
                :disabled="loading || isRecording"
            />
        </div>
        <div 
            class="audio-levels-progress"
            :style="{ 'maskImage': `url('${require(`@/assets/img/audioPlayer/audio-levels-${ randomLevelIdx }.svg`)}')` }">
            <Slider
                :disabled="isRecording"
                class="duration-slider"
                :value="currentTime"
                :max="duration"
                orientation="horizontal"
                @changed="updateCurrentTime"
            />
        </div>
        <div class="audio-levels-time">
            <div 
                v-if="showTime" 
                :class="[
                    'time-display',
                    { 'time-display--active': currentTime !== 0 },
                    { 'time-display--disabled': loading || isRecording },
                ]">{{ currentTime !== 0 ? formattedTime : initialDuration }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/reusable/Button';
import Slider from '@/components/reusable/media/Slider';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'AudioPlayer',
    components: { Slider, Button },
    props: {
        data: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            audioHtml: null,
            currentTime: 0,
            duration: 0,
            audioSource: null,
            loading: true,
            isPlaying: false,
            uuid: uuidv4(),
            currentTimeInterval: 0
        };
    },
    beforeDestroy() {
        this.audioHtml.pause();
        clearInterval(this.currentTimeInterval);
    },
    async mounted() {
        this.audioHtml = this.$refs.audioPlayer;

        this.audioHtml.addEventListener('loadedmetadata', () => {
            this.duration = this.audioHtml.duration;

            this.loading = false;
        });

        this.audioSource = this.data;
    },
    computed: {
        ...mapState(['windowWidth']),
        ...mapState('learning', ['isRecording']),
        initialDuration() {
            const duration = this.audioSource ? Math.round(this.duration) : this.maxRecorderDuration;
            const durationMinutes = Math.floor(duration / 60);
            const durationSeconds = duration % 60;

            return `${ durationMinutes }:${ durationSeconds.toString().padStart(2, '0') }`
        },
        formattedTime() {
            const currentTime = Math.round(this.currentTime);
            const currentMinutes = Math.floor(currentTime / 60);
            const currentSeconds = currentTime % 60;

            return `${ currentMinutes }:${ currentSeconds.toString().padStart(2, '0') }`
        },
        showTime() {
            return this.duration
        },
        isInfinityDuration() {
            return this.duration === Infinity
        },
        randomLevelIdx() {
            return Math.floor(Math.random() * 3) + 1;
        }
    },
    methods: {
        async playAudio() {
            this.$emit('playAudio');

            setTimeout(async () => {
                this.$refs.audioPlayer?.play();
                this.isPlaying = true;
                this.$store.state.audioUuid = this.uuid;

                this.setCurrentTimeInterval();
            }, 0);
        },
        pauseAudio() {
            this.$refs.audioPlayer.pause();
            this.isPlaying = false;
        },
        updateCurrentTime(val) {
            const audioElement = this.$refs.audioPlayer;
            audioElement.currentTime = val;
        },
        setCurrentTimeInterval() {
            clearInterval(this.currentTimeInterval);

            this.currentTimeInterval = setInterval(() => {
                if (this.isPlaying) {
                    if(this.audioSource) this.currentTime = this.audioHtml.currentTime;
                    if(this.isInfinityDuration) this.duration = this.audioHtml.duration;
                }
            }, 200);
        }
    },
    watch: {
        currentTime(newTime) {
            const audioElement = this.$refs.audioPlayer;

            if (newTime === this.duration) this.isPlaying = false;

            if (audioElement.paused) {
                audioElement.currentTime = newTime;
                this.isPlaying = false;
            }
        },
        '$store.state.audioUuid': {
            handler(uuid) {
                if (this.uuid !== uuid && this.isPlaying) this.stopAudio();
            }
        },
        isPlaying(n) {
            if(!n) this.$emit('stopAudio');
        }
    }
}
</script>

<style lang="scss" scoped>
.audio-levels {
    display: grid;
    grid-template-columns: 42px 189px 1fr;
    width: 322px;
    margin: 0 auto;
    transition: all .5s;
    padding: 12px 16px;
    border-radius: 12px;
    background-color: $gray-100;
    gap: 14px;
    place-items: center;
}
.audio-levels-control {
    .button {
        width: 42px;
        height: 42px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        border-radius: 50%;
        transition: all .3s;
        user-select: none;

        &:hover {
            background-color: $primary-dark;
        }

        &.btn--disabled {
            background-color: $gray-300;
            cursor: not-allowed;
        }

        ::v-deep img {
            width: 100%;
            filter: brightness(0) invert(1);
        }
    }

    .btn--play ::v-deep img {
        filter: brightness(0) invert(1);
        transform: translateX(2px);
    }
}


.audio-levels-progress {
    width: 171px;
    height: 50px;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

::v-deep {
    .range-input.horizontal .track {
        top: 0;
        transform: translateY(0);
        width: 100%;
        height: 50px;
        background-color: $gray-200;
        border-radius: 0;
    }

    .range-input.horizontal .filled-track {
        transition: all 0.3s;
        top: 0;
        transform: translateY(0);
        height: 50px;
        background-color: $primary-mid;
        border-radius: 0;
    }
}

.audio-levels-time {
    color: $gray-600;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.4px;
    user-select: none;
}

.time-display {
    color: $gray-600;
}

.time-display--active {
    color: $primary;
}

.time-display--disabled {
    color: $gray-300;
}
</style>
