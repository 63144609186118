<template>
    <div ref="el" class="grammar__replacements" contenteditable="false">
        <p class="grammar__replacements-title">{{ title }}</p>
        <p v-if="description" class="grammar__replacements-description">{{ description }}</p>
        <div v-if="replacements.length" class="grammar__replacements-options">
            <p 
                v-for="(el, index) in replacements"
                :key="index"
                class="grammar__replacements-option"
                @click="handleReplacement(el)"
            >
                {{ el.value == 0x00 ? '_' : el.value }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'GrammarReplacements',
    props: {
        data: Object
    },
    mounted() {
        this.setPosition()
    },
    computed: {
        ...mapState(['windowWidth', 'windowHeight']),
        replacements() {
            return this.data.replacements.slice(0, 5)
        },
        title() {
            return this.data.rule.category.name
        },
        description() {
            return this.data.message
        }
    },
    methods: {
        setPosition() {
            const { el } = this.$refs
            const gap = 29
            let { left, top, width, height } = el.getBoundingClientRect()

            if(left + width + gap > this.windowWidth) left = this.windowWidth - width - gap

            if(top + height + gap > this.windowHeight) top = top - height - gap
            
            const style = {
                position: 'fixed',
                left: left + 'px',
                top: top + 'px'
            }

            for(let property in style) {
                el.style[property] = style[property]
            }
        },
        handleReplacement(el) {
            this.$store.commit('tools/grammarReplacement', {
                uuid: this.data.uuid,
                text: el.value
            })
            this.$store.commit('tools/grammarResetActiveMatch')
        }
    }
}
</script>

<style lang="scss" scoped>
.grammar__replacements {
    width: 250px;
    padding: 8px 10px;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    position: absolute;
    left: 0;
    top: 29px;
    z-index: 1;
    user-select: none
}

.grammar__replacements-title {
    @include font-medium;
    font-weight: 600;
    color: $gray-900;
}

.grammar__replacements-description {
    @include font-regular;
    color: $gray-900;
    margin-top: 6px;
}

.grammar__replacements-options {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 8px;
}

.grammar__replacements-option {
    @include font-small-12;
    font-weight: 600;
    color: $primary-dark;
    padding: 5px 7px;
    border: 1px solid $gray-200;
    border-radius: 6px;
    cursor: pointer;
    transition: all .25s;

    &:hover {
        background: $primary;
        border-color: $primary;
        color: $white;
    }
}
</style>