import { mapState } from "vuex"

export default {
    computed: {
        ...mapState('media', ['devices', 'selectedDevices'])
    },
    methods: {
        async getDevices() {
            const devices = await navigator.mediaDevices.enumerateDevices()

            this.setDevices(devices, 'audioinput')
            if(this.isAudiooutputSupported) this.setDevices(devices, 'audiooutput')
        },
        setDevices(data, kind) {
            const devices = data.filter(el => el.kind === kind)
            this.devices[kind] = devices

            if(this.selectedDevices[kind]) return

            if(['audioinput'].includes(kind)) {
                const tracks = this.stream.getAudioTracks()
                this.selectedDevices[kind] = devices.find(el => el.label === tracks[0].label)?.deviceId || devices[0].deviceId
            } else {
                this.selectedDevices[kind] = devices.find(el => el.deviceId === 'default')?.deviceId || devices[0].deviceId
            }
        },
        getSelectedDevice(kind) {
            const devices = [...this.devices[kind]]
            return devices.find(el => el.deviceId === this.selectedDevices[kind])
        },
        saveSelectedDevices() {
            const keys = Object.keys(this.selectedDevices)
            const devices = {}

            keys.forEach(key => {
                devices[key] = this.getSelectedDevice(key)
            })

            localStorage.setItem('selectedDevices', JSON.stringify(devices))
        }
    }
}