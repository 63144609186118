<template>
    <div class="widget placement-test">
        <img src="@/assets/img/progressTest/placement-test.svg" alt="" width="40" height="40">
        <h3 class="placement-test__title">{{ $t(title) }}</h3>
        <p class="placement-test__subtitle">{{ $t('dashboard.placement-test.subtitle') }}</p>
        <Button 
            class="secondary"
            @click.native="$router.push({ name: 'placementTest' })"
            :text="$t('dashboard.placement-test.open-test')"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'

export default {
    name: 'PlacementTest',
    props: ['data'],
    components: {
        Button
    },
    computed: {
        title() {
            const { placementTest, oralPlacementTest } = this.data

            return [placementTest?.status, oralPlacementTest?.status].includes('IN_PROGRESS')
                ? 'dashboard.placement-test.in-progress-title'
                : 'dashboard.placement-test.title'
        }
    }
}
</script>

<style lang="scss" scoped>
.placement-test {
    &__title {
        @include font-h2;
        margin: 16px 0 8px;
    }

    &__subtitle {
        @include font-regular-text;
        margin-bottom: 24px;
    }
}
</style>