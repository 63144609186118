import gql from 'graphql-tag'

export const lessonsByPeriod = gql`
    query($since: DateTimeWithTimezone!, $until: DateTimeWithTimezone!, $userUuid: ID) {
        currentUser {
            student {
                studentLessonsByPeriod(input: {since: $since, until: $until, userUuid: $userUuid}) {
                    hasParticipated
                    isIncludedInParticipation
                    lesson {
                        uuid
                        status
                        startAt
                        isTeacherSubstituted
                        languageLevel {
                            shortName
                        }
                        lessonSubjects {
                            subject
                        }
                        group {
                            languageLevel {
                                shortName
                            }
                        }
                        user {
                            firstName
                            avatarUrl
                        }
                        number
                        studentLesson {
                            joinLessonUrl
                        }
                    }
                }
            }
        }
    }
`