<template>
    <div class="calendar">
        <p class="date">{{$moment(date).format('MMMM YYYY')}}</p>
        <div class="content">
            <div class="week">
                <div v-for="day in weekDays" class="tile" :key="day"><span>{{day}}</span></div>
            </div>
            <div class="month">
                <div v-for="day in firstDayOfMonth-1" :key="`${day}empty`" class="tile"></div>
                <div v-for="day in daysInMonth" :key="day" class="tile">
                    <span :class="[{'active': isToday(day)}, checkLesson(day)]" @click="checkLesson(day) ? makeActive(day) : null">{{day}}</span>
                </div>
            </div>
        </div>
        <div v-if="summary" class="summary">
            <p class="title">{{$i18n.t('lessons.calendar.this-month')}}</p>
            <div class="row">
                <div class="name">{{$i18n.t('lessons.calendar.finished')}}:</div>
                <div class="value">{{summary.finished}}</div>
            </div>
            <div class="row">
                <div class="name">{{$i18n.t('lessons.calendar.scheduled')}}:</div>
                <div class="value">{{summary.scheduled}}</div>
            </div>
            <div class="row">
                <div class="name">{{$i18n.t('lessons.calendar.frequency')}}:</div>
                <div class="value">{{summary.frequency}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Calendar',
    data() {
        return {
            date: this.$moment(`${this.$route.params.year}-${this.$route.params.month}`),
            days: null,
            summary: null
        }
    },
    created() {
        this.$bus.$on('lessonsDate', date => {
            this.date = date
            this.days = null
        })
        this.$bus.$on('lessonsDays', days => this.days = days)
        this.$bus.$on('calendarSummary', data => this.summary = data)
    },
    beforeDestroy() {
        this.$bus.$off('lessonsDate')
        this.$bus.$off('lessonsDays')
        this.$bus.$off('calendarSummary')
    },
    computed: {
        weekDays() {
            return this.$moment.weekdaysMin(true)
        },
        daysInMonth() {
            return this.$moment(this.date).daysInMonth()
        },
        firstDayOfMonth() {
            return this.$moment(this.date).startOf('month').isoWeekday()
        },
        getMonth() {
            return this.$moment(this.date).format('M')
        }
    },
    methods: {
        isToday(day) {
            const calendarDay = this.$moment(this.date).startOf('month').add(day-1, 'days')
            const today = this.$moment()
            return calendarDay.isSame(today, 'day')
        },
        checkLesson(day) {
            const data = this.days?.[day]
            if(!data) return
            let output = `${data.status.join(' ')} clickable ` 
            output += data.hasParticipated.join(' ')
            return output
        },
        makeActive(day) {
            this.$bus.$emit('makeLessonActive', day)
        }
    }
}
</script>