<template>
    <div class="widget progress-test">
        <img src="@/assets/img/progressTest/progress-test.svg" alt="" width="40" height="40">
        <h3 class="progress-test__title">{{ $i18n.t('dashboard.progress-test.title') }}</h3>
        <p class="progress-test__subtitle">{{ $i18n.t('dashboard.progress-test.subtitle') }}</p>
        <div class="info-box">
            <div class="info-box__small-section">
                <p class="info-box__small-title">{{ $i18n.t('dashboard.progress-test.valid-to') }}</p>
                <p class="info-box__time"><img src="@/assets/img/calendar.svg" alt=""> {{ formattedDate }}</p>
            </div>
            <div class="info-box__small-section">
                <p class="info-box__small-title">{{ $i18n.t('dashboard.progress-test.time-to-do') }}</p>
                <p class="info-box__time"><img src="@/assets/img/clock.svg" alt=""> {{ testLengthInMinutes }} {{ $i18n.t('calendar.minutes') }}</p>
            </div>
        </div>
        <p class="progress-test__description">{{ $i18n.t('dashboard.progress-test.description', { time: testLengthInMinutes / 2 }) }}</p>
        <Button 
            class="secondary"
            @click.native="$router.push({ name: 'progressTest', params: { uuid: data.uuid } })"
            :text="$i18n.t('dashboard.progress-test.open-test')"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'ProgressTest',
    props: ['data'],
    components: {
        Button
    },
    computed: {
        formattedDate() {
            if(!this.data.validTo) return '-'

            const date = this.$moment(this.data.validTo)

            return `${ date.format(timezoneDate('D MMMM')) }, ${ this.$i18n.t('calendar.hour-shortcut') } ${ date.format(timezoneDate('HH:mm')) }`
        },
        testLengthInMinutes() {
            return this.data.testLength / 60
        }
    }
}
</script>

<style lang="scss" scoped>
.progress-test {
    &__title {
        @include font-h2;
        margin: 16px 0 8px;
    }

    &__subtitle {
        @include font-regular-text;
    }

    &__description {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $gray-600;
        padding: 8px;
        background: $gray-100;
        border-radius: 6px;
        margin-bottom: 24px;
    }

    .info-box {
        @include font-regular-text;
        margin: 24px 0 16px;
        display: flex;
        gap: 16px;
    
        &__small-section {
            flex: 1;
        }

        &__small-title {
            @include font-small-10;
            text-transform: uppercase;
            color: $gray-600;
            margin-bottom: 8px;
        }

        &__time {
            @include font-medium;
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }
    }
}
</style>