<template>
    <div class="oral-exercise">
        <div class="oral-exercise__header">
            <NumberedList
                size="large"
                :text="$t(`placement-test.oral-exercise.title.${ data.type }`)"
                :character="data.number"
                :description="getDescription"
                borderBottom
            />
        </div>
        <div class="oral-exercise__content">
            <p v-if="isQuestion" class="oral-exercise__question">
                {{ data.question }}
            </p>
            <AudioPlayer 
                record
                sticky
                :data="value?.base64?.split(',')[1] || value?.base64"
                @input="onInput"
            />
            <div v-if="isText" class="oral-exercise__text">
                <p v-for="(row, index) in text" :key="index">{{ row }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/reusable/AudioPlayer'
import NumberedList from '@/components/reusable/NumberedList';
import bulbIcon from '@/assets/img/progressTest/bubble.svg';

export default {
    name: 'OralExercise',
    props: ['data', 'value'],
    components: {
        AudioPlayer,
        NumberedList
    },
    data() {
        return {
            bulbIcon
        }
    },
    computed: {
        isQuestion() {
            return this.data.type === 'question'
        },
        isText() {
            return this.data.type === 'text'
        },
        text() {
            return this.data.question.split('\n\n')
        },
        getDescription() {
            return { text: this.$t('placement-test.oral-exercise.warning'), icon: this.bulbIcon, iconPosition: 'left' }
        }
    },
    methods: {
        onInput(data) {
            this.$emit('input', {
                base64: data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$mobile: 768px;

.oral-exercise {
    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        text-align: left;

        @media(min-width: $mobile) {
            flex-direction: row;
            gap: 24px;
        }
    }

    &__title {
        @include font-lead;
    }

    &__subtitle {
        @include font-regular;
        color: $gray-600;
        display: flex;
        gap: 8px;
        margin-top: 8px;
        padding-bottom: 32px;
        border-bottom: 1px solid $gray-200;
    }

    &__content {
        @media(min-width: $mobile) {
            padding-left: 56px;
        }
    }

    &__question, &__text {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        color: $gray-900;
        text-align: left;
        margin: 8px 0;

        p {
            margin-bottom: 8px;
        }
    }
}
</style>