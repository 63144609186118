<template>
    <div class="widget finished-progress-test">
        <img src="@/assets/img/progressTest/finished-progress-test.svg" alt="" width="40" height="40">
        <h3 class="finished-progress-test__title">{{ $i18n.t('dashboard.finished-progress-test.title') }}</h3>
        <p class="finished-progress-test__subtitle">{{ $i18n.t('dashboard.finished-progress-test.subtitle') }}</p>
        <div class="finished-progress-test__buttons">
            <Button 
                v-if="data.link"
                class="default"
                @click.native="download"
                :text="$i18n.t('dashboard.finished-progress-test.download')"
                :icon="require('@/assets/img/progressTest/download.svg')"
            />
            <Button 
                class="secondary"
                @click.native="$router.push({ name: 'progressTest', params: { uuid: data.uuid } })"
                :text="$i18n.t('dashboard.finished-progress-test.show-result')"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'

export default {
    name: 'FinishedProgressTest',
    props: ['data'],
    components: {
        Button
    },
    methods: {
        download() {
            open(this.data.link)
        }
    }
}
</script>

<style lang="scss" scoped>
.finished-progress-test {
    &__title {
        @include font-h2;
        margin: 13px 0 8px;
    }

    &__subtitle {
        @include font-regular-text;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;
        
        .btn {
            flex: 1;
        }
    }
}
</style>
