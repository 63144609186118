<template>
    <div>
        <div class="chart-radar"></div>

        <div class="placement-test-summary__table">
            <div class="placement-test-summary__table-row placement-test-summary__table-row--head">
                <span>{{ $t('placement-test.summary.skills.table.header.skills') }}</span>
                <span>{{ $t('placement-test.summary.skills.table.header.result') }}</span>
            </div>
            <div v-for="{name, value} in this.tableData" class="placement-test-summary__table-row" :key="name">
                <span>{{ name }}</span>
                <span class="placement-test-summary__table-result">{{ value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'ChartRadar',
    props: ['score'],
    data() {
        return {
            chartInstance: null,
            tableData: []
        }
    },
    mounted() {
        const { scores, maxScores, indicators, tableData } = this.convertData(this.score);
        this.tableData = tableData;

        // const { scores } = this.scaleScores({ scores, maxScores}); // for non-scaled values
        const { newScores } = this.scaleScores({ scores, maxScores});

        var chartDom = document.querySelector('.chart-radar')
        this.chartInstance = echarts.init(chartDom);
        var option;

        option = {
            radar: {
                shape: 'circle',
                splitNumber: 4,
                nameGap: 4,
                splitArea: {
                    areaStyle: {
                        color: ['transparent']
                    }
                },
                indicator: indicators
            },
            series: [
                {
                    type: 'radar',
                    data: [
                        {
                            value: newScores,
                            // value: scores, // for non-scaled values
                            lineStyle: {
                                color: '#9ECE4F'
                            },
                            itemStyle: {
                                color: '#9ECE4F'
                            },
                        }
                    ],
                    areaStyle: { color: 'rgba(225,255,228,1)' },
                    label: {
                        show: true,
                        position: 'bottom',
                        backgroundColor: '#ffffff',
                        borderRadius: 3,
                        padding: [2, 3],
                        formatter: function(params) {
                            return Math.ceil(scores[params.dimensionIndex] / maxScores[params.dimensionIndex] * 100) + '%'
                        }
                    },
                }
            ]
        };

        option && this.chartInstance.setOption(option);

        window.addEventListener('resize', this.resizeChart);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeChart);
        this.chartInstance.dispose();
    },
    methods: {
        resizeChart() {
            this.chartInstance.resize();
        },
        convertData(score) {
            let scores = [];
            let maxScores = [];
            let indicators = [];
            let tableData = [];

            for (let key in score) {
                if (key !== 'total') {
                    scores.push(score[key].score);
                    maxScores.push(score[key].maxScore);
                    indicators.push({ name: this.$t(`placement-test.summary.score-category.${ key }.label`), max: score[key].maxScore, nameTextStyle: { color: '#2D3642' }})
                    tableData.push({ name: this.$t(`placement-test.summary.score-category.${ key }.label`), value: Math.ceil(score[key].score / score[key].maxScore * 100) + '%' })
                }
            }

            return { scores, maxScores, indicators, tableData };
            // return { scores: [7, 3, 4, 5] , maxScores, indicators, tableData }; // for testing
        },
        scaleScores(data) {
            let maxPercent = 0;
            data.scores.forEach((score, i) => {
                let percentValue = data.maxScores[i] > 0 ? (score / data.maxScores[i]) * 100 : 0;
                if (percentValue > maxPercent) {
                    maxPercent = percentValue;
                }
            });

            let scale = Math.ceil(maxPercent / 10) * 10;
            if(scale === 0) scale = 1;

            const scaledPercents = [];
            const newScores = [];
            data.scores.forEach((score, i) => {
                let percentValue = data.maxScores[i] > 0 ? (score / data.maxScores[i]) * 100 : 0;
                const sP = (percentValue / scale) * 100;
                scaledPercents.push(sP);
                newScores.push(data.maxScores[i] * sP / 100);
            });

            return {
                maxScores: data.maxScores,
                scores: data.scores,
                scaledPercents: scaledPercents,
                newScores: newScores
            };
        }

    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/components/_placement_test_summary_table.scss";
    .chart-radar {
        height: 424px;
    }
</style>