<template>
    <div class="learning-goals">
        <div v-if="showModule" class="module-wrapper">
            <div class="module">
                <span class="module__name">{{ $t('learning.goals.module-name', transUi) }} {{ getModuleIdx }}</span>
                <img class="module__arrow"/>
            </div>
            <div class="module">
                <span class="module__name">{{ $t('learning.goals.lesson-name', transUi) }} {{ getLessonIdx }}</span>
            </div>
        </div>
        <div class="title-wrapper">
            <span v-if="getModuleSubject" class="subject">{{ getModuleSubject }}</span>
            <span v-if="getModuleTitle" class="title">{{ getModuleTitle }}</span>
        </div>
        <LearningProgress :text="$t('learning.lesson-progress', transUi)"/>
        <div class="after-lesson-container">
            <span class="title">{{ getCurrentInstruction }}</span>
            <ul class="goals-list">
                <li
                    v-for="el in text"
                    :key="el"
                    v-html="el"
                >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LearningProgress from '@/components/learning/LearningProgress';

export default {
    name: 'Goals',
    components: { LearningProgress },
    computed: {
        ...mapState('learning', ['subject']),
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapGetters('learning', ['getCurrentTask', 'getCurrentInstruction']),
        ...mapGetters('learningCoursePlan', ['getLearningCoursePlanModules']),
        text() {
            return this.getCurrentTask.data.texts;
        },
        getLessonIdx() {
            const modules = this.getLearningCoursePlanModules;
            for (let i = 0; i < modules.length; i++) {
                const materials = modules[i].materials;
                const index = materials.findIndex(material => material.materialUuid === this.$route.params.uuid);
                if (index !== -1) {
                    return index + 1;
                }
            }

            return -1;
        },
        getModuleIdx() {
            return this.getLearningCoursePlanModules.findIndex(el => el.materials.find(material => material.materialUuid === this.$route.params.uuid)) + 1;
        },
        getMaterials() {
            return this.getLearningCoursePlanModules.flatMap(module => module.materials)
        },
        getModuleSubject() {
            return this.getMaterials.find(el => el.materialUuid === this.$route.params.uuid)?.title
        },
        getModuleTitle() {
            return this.getLearningCoursePlanModules[this.getModuleIdx - 1]?.title
        },
        showModule() {
            return this.getModuleIdx !== -1 && this.getLessonIdx !== -1
        }
    }
}
</script>

<style lang="scss" scoped>
.learning-goals {
    display: flex;
    flex-direction: column;
    min-height: 560px;
    width: 100%;

    .title-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 12px;

        .subject {
            color: $gray-600;
            @include font-h3;

            @media (min-width: $mobile-learning) {
                @include font-h2;
            }
        }

        .title {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: -0.4px;
            
            @media (min-width: $mobile-learning) {
                @include font-h1;
                letter-spacing: normal;
            }
        }

        @media (min-width: $mobile-learning) {
            padding: 0;
        }
    }

    .module-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 12px;

        @media (min-width: $mobile-learning) {
            gap: 8px;
        }
    }
    
    .module {
        display: flex;
        align-items: center;
        gap: 4px;
    
        &__name {
            padding: 7px 13px;
            border-radius: 24px;
            border: 1px solid $gray-200;
            color: $gray-600;
            @include font-h6-uc;

            @media (min-width: $mobile-learning) {
                @include font-h3;
                text-transform: none;
            }
        }
    
        &__arrow {
            mask-image: url('~@/assets/img/arrow-dark.svg');
            width: 16px;
            height: 16px;
            background-color: $gray-600;
            mask-repeat: no-repeat;
            transform: rotate(270deg);

            @media (min-width: $mobile-learning) {
                mask-image: url('~@/assets/img/learning/arrow-right.svg');
                width: 20px;
                height: 20px;
                transform: rotate(0deg);
            }
        }

        @media (min-width: $mobile-learning) {
            gap: 8px;
            padding: 0;
        }
    }

    .learning-progress {
        margin-top: 32px;
        padding: 0 12px;

        ::v-deep {
            .progress {
                padding: 0;
                border: none;
                height: 12px;
                background-color: $gray-200;

                &__bar {
                    height: 12px;
                }
            }
        }

        @media (min-width: $mobile-learning) {
            padding: 0;
        }
    }

    .after-lesson-container {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid $gray-200;
        padding: 16px;
        border-radius: 10px;

        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -0.4px;

            @media (min-width: $mobile-learning) {
                @include font-h2;
                letter-spacing: normal;
            }
        }

        .goals-list {
            color: $gray-600;
            margin: 12px 0 0 24px;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.4px;

            @media (min-width: $mobile-learning) {
                @include font-lead;
                letter-spacing: normal;
            }
        }
    }

    ::v-deep {
        .learning-progress {
            .text-wrapper {
                .text, .percent {
                    @media (min-width: $mobile-learning) {
                        font-size: 17px !important;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: -0.4px;
                    }
                }
            }
        }
    }
}
</style>