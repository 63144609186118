<template>
    <div class="child">
        <div class="header">
            <img :src="setAvatar(data.avatarUrl)" class="avatar-style" width="40" height="40" alt="Avatar">
            <div class="label">
                <h3>{{ data.firstName }} {{ data.lastName }}</h3>
                <p>{{ $i18n.t('dashboard.children.student') }}</p>
            </div>
        </div>
        <RemainingLessons v-if="studentIndividualGroups" :data="data.student.studentPackagesGroups"/>
        <Frequency :uuid="data.uuid"/>
        <Button @click.native="loginChild(data.uuid)" :loading="childLoading" :text="$i18n.t('dashboard.children.go-to-child')" class="default"/>
    </div>
</template>

<script>
import setAvatarMixin from '@/mixins/setAvatar'
import Button from '@/components/reusable/Button'
import RemainingLessons from './RemainingLessons';
import Frequency from './Frequency';
import { tokenByUuid } from '@/graphql/queries/tokenByUuid'
import api from '@/graphql/api.js'
import accessStorage from '@/functions/accessStorage'

export default {
    name: 'ChildrenContent',
    components: {
        Button,
        RemainingLessons,
        Frequency
    },
    mixins: [setAvatarMixin],
    props: ['data'],
    data() {
        return { childLoading: false }
    },
    computed: {
        studentIndividualGroups() {
            return this.data.student.studentPackagesGroups.length > 0
        }
    },
    methods: {
        async loginChild(uuid) {
            this.childLoading = true
            const { res } = await api(tokenByUuid, {
                userUuid: uuid
            })
            sessionStorage.getItem('authToken') ? sessionStorage.setItem('parentAuthToken', sessionStorage.getItem('authToken')) : localStorage.setItem('parentAuthToken', localStorage.getItem('authToken'))
            accessStorage('authToken', res.tokenByUuid.token)
            this.childLoading = false
            this.$router.go()
        }
    }
}
</script>