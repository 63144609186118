<template>
    <div class="placement-test-audio-setup">
        <p class="placement-test-audio-setup__title">{{ $t('placement-test.audio-setup.title') }}</p>
        <p class="placement-test-audio-setup__subtitle">{{ $t('placement-test.audio-setup.subtitle') }}</p>
        <AudioSetup v-model="error"/>
    </div>
</template>

<script>
import AudioSetup from '@/components/reusable/AudioSetup'

export default {
    name: 'PlacementTestAudioSetup',
    components: {
        AudioSetup
    },
    data() {
        return {
            error: false
        }
    },
    watch: {
        error(n) {
            this.$emit('input', !n)
        }
    }
}
</script>

<style lang="scss" scoped>
.placement-test-audio-setup {
    text-align: left;

    &__title {
        @include font-h2;
    }

    &__subtitle {
        @include font-regular-text;
        margin: 8px 0 40px;
    }
}
</style>