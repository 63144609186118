import axios from 'axios'

export const giphy = axios.create({
    baseURL: 'https://api.giphy.com/v1/gifs/'
})

export const schoolV1 = axios.create({
    baseURL: process.env.VUE_APP_SCHOOLV1
})

export const schoolV2 = axios.create({
    baseURL: process.env.VUE_APP_SCHOOLV2
})

export const gqlAPI = axios.create({
    baseURL: process.env.VUE_APP_GRAPHQL
})

export const gqlAPILearning = axios.create({
    baseURL: process.env.VUE_APP_GRAPHQL_LEARNING
})