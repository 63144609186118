export default {
    methods: {
        loginRedirect() {
            const loginParams = { forceAppMode: true }
            const { redirect } = this.$route.query

            if(redirect) this.$router.replace({ path: redirect, params: loginParams })
            else this.$router.replace({ name: 'dashboard', params: loginParams })
        }
    }
}