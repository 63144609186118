<template>
    <div class="placement-test-page">
        <div class="bg-white nav-bar">
            <div class="wrapper wrapper--big">
                <nav class="nav">
                    <div class="nav__left">
                        <img class="logo" src="@/assets/img/logo-wide.svg" alt="Fluentbe">
                    </div>
                </nav>
            </div>
        </div>
        <Loading 
            v-if="loading"
            :size="40"
        />
        <MultiStep
            v-else
            ref="multiStep"
            class="multi-step--top-gap"
            hideProgress
            :steps="steps"
            @setStep="setStep"
            :isCompleted="isCompleted"
            :buttons="{
                back: {
                    disabledSteps: [2, steps.length]
                },
                next: {
                    texts: {
                        1: $i18n.t('placement-test.footer.start')
                    }
                },
                close: {
                    steps: isCompleted ? 'all' : [1],
                    ...closeButton
                },
                finish: {
                    steps: [steps.length],
                    action: () => this.$router.push({ name: 'dashboard' }),
                    text: $i18n.t('placement-test.footer.finish') 
                },
            }"
        />
    </div>
</template>

<script>
import MultiStep from '@/components/reusable/MultiStep'
import PlacementTestStart from '@/components/placementTest/PlacementTestStart'
import SingleChoice from '@/components/reusable/exercises/SingleChoice'
import MultipleChoice from '@/components/reusable/exercises/MultipleChoice'
import PlacementTestAudioSetup from '@/components/placementTest/PlacementTestAudioSetup'
import OralExercise from '@/components/reusable/exercises/OralExercise'
import PlacementTestSummary from '@/components/placementTest/PlacementTestSummary'
import Loading from '@/components/reusable/Loading'

import { getPlacementTest } from '@/graphql/queries/getPlacementTest'
import { startPlacementTest } from '@/graphql/mutations/startPlacementTest'
import { updatePlacementTest } from '@/graphql/mutations/updatePlacementTest'

import { getOralPlacementTest } from '@/graphql/queries/getOralPlacementTest'
import { startOralPlacementTest } from '@/graphql/mutations/startOralPlacementTest'
import { updateOralPlacementTest } from '@/graphql/mutations/updateOralPlacementTest'
import { finishOralPlacementTest } from '@/graphql/mutations/finishOralPlacementTest'

import api from '@/graphql/api.js'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'PlacementTest',
    components: {
        MultiStep,
        Loading
    },
    data() {
        return {
            loading: true,
            steps: [],
            backupSteps: [],
            currentStepIndex: 1,
            isOralTest: false,
            isCompleted: false
        }
    },
    created() {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = '/web-audio-recorder/WebAudioRecorder.min.js'
        document.head.appendChild(script)
    },
    async mounted() {
        await this.$store.dispatch('media/checkDevices')
        
        navigator.mediaDevices.ondevicechange = async () => {
            this.$store.commit('media/resetSelectedDevices')
            await this.$store.dispatch('media/checkDevices')
            this.$bus.$emit('deviceChange')
        }

        const selectedDevices = JSON.parse(localStorage.getItem('selectedDevices'))
        if(this.devices.audioinput.find(device => device.deviceId === selectedDevices?.audioinput?.deviceId)) this.selectedDevices.audioinput = selectedDevices.audioinput.deviceId
        if(this.devices.audiooutput.find(device => device.deviceId === selectedDevices?.audiooutput?.deviceId)) this.selectedDevices.audiooutput = selectedDevices.audiooutput.deviceId

        this.steps.push({
            component: PlacementTestStart,
            validation: this.startTest,
            data: {}
        })

        this.steps.push({
            component: PlacementTestSummary,
            data: {}
        })

        await this.getTest()

        this.loading = false
    },
    beforeDestroy() {
        navigator.mediaDevices.ondevicechange = null
    },
    computed: {
        ...mapState('media', ['devices', 'selectedDevices']),
        ...mapGetters(['getLanguageLevel']),
        closeButton() {
            if(this.currentStepIndex === 1) {
                return {
                    action: () => this.$router.push({ name: 'dashboard' }),
                    text: this.$t('placement-test.footer.close')
                }
            }

            if(this.currentStepIndex === this.steps.length) {
                return {
                    action: () => this.$refs.multiStep.setStep(2),
                    text: this.$t('placement-test.footer.my-answers')
                }
            }

            return {
                action: () => this.$refs.multiStep.setStep(this.steps.length),
                text: this.$t('placement-test.footer.result')
            }
        }
    },
    methods: {
        async getTest() {
            const { res } = await api(getPlacementTest)

            const test = res.getPlacementTest

            if(!test && this.getLanguageLevel) this.$router.push({ name: 'dashboard' })
            else if(test) await this.handleTest(test, true)
        },
        async handleTest(test, setCurrentStep) {
            const inProgressData = {}
            let currentStep = 2
            test.questions = [].concat(...test.questions)

            const questions = test.questions.map((step, index) => {
                if(['IN_PROGRESS', 'COMPLETED'].includes(test.status)) {
                    inProgressData[step.uuid] = []
                    
                    step.answers.forEach(answer => {
                        if(answer.isSelected) {
                            inProgressData[step.uuid].push(answer.uuid)
                            currentStep = index + 3

                            if(currentStep > test.questions.length + 1) currentStep = test.questions.length + 1
                        }
                    })
                }

                return {
                    component: this.getStepComponent(step.type),
                    name: step.uuid,
                    validation: this.validateStep,
                    required: true,
                    data: {
                        number: index + 1,
                        name: step.name || step.question[0],
                        questions: [{
                            uuid: step.uuid,
                            question: step.question,
                            answers: step.answers
                        }],
                        media: step.media,
                        arrayBased: true
                    }
                }
            })

            this.steps = [this.steps[0], ...questions, this.steps.at(-1)]
            this.backupSteps = this.steps

            if(test.status === 'COMPLETED') {
                this.steps.at(-1).data = test.result
                await this.getOralTest()

                if(!this.isOralTest) currentStep = this.steps.length
            }

            if(['IN_PROGRESS', 'COMPLETED'].includes(test.status)) {
                setTimeout(() => {
                    this.$refs.multiStep.updateFormData(inProgressData)
                    if(setCurrentStep && !this.isOralTest) this.$refs.multiStep.setStep(currentStep)
                }, 0)
            }
        },
        getStepComponent(type) {
            const mapper = {
                SINGLE_CHOICE: SingleChoice,
                MULTIPLE_CHOICE: MultipleChoice
            }

            return mapper[type]
        },
        async startTest() {
            const { res, error } = await api(startPlacementTest, {}, 'startPlacementTest')

            if(error) return { error }

            this.handleTest(res.startPlacementTest, true)
        },
        async validateStep({ stepName, stepData }) {
            if(this.isCompleted) return

            const data = {
                [stepName]: stepData
            }
            
            const { res, error } = await api(updatePlacementTest, {
                input: data
            }, 'updatePlacementTest')

            if(error) return { error }

            const { result, questions } = res.updatePlacementTest
            
            if(result) {
                this.$store.state.languageLevel = result.languageLevel.shortName

                this.steps.at(-1).data = result
            }

            const lastCurrentQuestion = this.steps.at(-2).name
            const lastNewQuestion = questions.at(-1).at(-1).uuid

            if(lastCurrentQuestion !== lastNewQuestion || result) {
                await this.handleTest(res.updatePlacementTest, false)
            }
        },
        async getOralTest() {
            const { res } = await api(getOralPlacementTest, {}, 'getOralPlacementTest')
            const test = res.getOralPlacementTest

            if(!test || ['COMPLETED', 'ACCEPTED', 'REQUESTED_MEETING'].includes(test.status)) {
                this.isCompleted = true
                this.loading = false

                return setTimeout(() => {
                    if(test) this.steps.at(-1).data.oralTestFinished = true
                }, 0)
            }

            this.isOralTest = true

            const audioSetupStep = {
                component: PlacementTestAudioSetup,
                name: 'AUDIO_SETUP',
                validation: test.status === 'NEW' && this.startOralTest,
                required: true,
                data: {},
            }
            
            const inProgressData = {}
            let currentStep = 2

            const questions = test.questions.map((step, index) => {
                if(test.status === 'IN_PROGRESS' && step.file) {
                    inProgressData[step.uuid] = {
                        base64: step.file,
                        uploaded: true
                    }
                    currentStep = index + 4

                    if(currentStep > test.questions.length + 2) currentStep = test.questions.length + 2
                }

                return {
                    component: OralExercise,
                    type: 'ORAL',
                    name: step.uuid,
                    validation: this.validateOralStep,
                    required: true,
                    isLastOralStep: index === test.questions.length - 1,
                    data: {
                        number: index + 1,
                        type: step.type,
                        question: step.question
                    }
                }
            })

            this.steps = [this.steps[0], audioSetupStep, ...questions, this.steps.at(-1)]

            setTimeout(() => {
                this.$refs.multiStep.updateFormData(inProgressData)
                this.$refs.multiStep.setStep(currentStep)
            }, 0)
        },
        async startOralTest() {
            const { error } = await api(startOralPlacementTest)

            if(error) return { error }

            const startOraTestlStep = this.steps.find(el => el.component === PlacementTestAudioSetup)
            startOraTestlStep.validation = null
        },
        async validateOralStep({ stepName, stepData, isLastOralStep }) {
            if(stepData.uploaded) return

            const file = this.base64ToFile(stepData.base64, `${ stepName }.mp3`)

            const { uuid, uploadError } = await this.$store.dispatch('uploadFile', file)
            const { error } = await api(updateOralPlacementTest, {
                input: {
                    [stepName]: uuid
                }
            })

            if(uploadError) return { silenceError: uploadError }
            if(error) return { error }

            this.$refs.multiStep.updateFormData({
                [stepName]: {
                    base64: stepData.base64,
                    uploaded: true
                }
            })

            if(isLastOralStep) {
                const { error } = await api(finishOralPlacementTest)

                this.steps = this.backupSteps
                this.steps.at(-1).data.oralTestFinished = true
                this.$refs.multiStep.setStep(this.steps.length)
                this.isCompleted = true

                if(error) return { error }
            }
        },
        base64ToFile(base64, filename) {
            let arr = base64.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = window.atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }

            return new File([u8arr], filename, { type: mime })
        },
        setStep(data) {
            this.currentStepIndex = data
        }
    }
}
</script>

<style lang="scss" scoped>
.placement-test-page {
    .loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>