<template>
    <div class="bg-white nav-bar">
        <div :class="['wrapper wrapper--big', { 'wrapper--p0': isMobile }]">
            <nav v-if="isMobile" class="nav-mobile">
                <div class="lesson-stats-container">
                    <FlashcardsProgress v-if="!practiceMode" />
                    <div class="close-flashcards-wrapper" @click="handleCloseAction">
                        <img src="@/assets/img/flashcards/close.svg" alt="" >
                    </div>
                </div>
                <p v-if="getCurrentExercise?.type" class="exercise-title">{{ getExerciseTitle }}</p>
            </nav>
            <nav v-else class="nav">
                <div class="nav__left">
                    <img class="logo" src="@/assets/img/logo-small.svg" alt="Fluentbe">
                    <p class="title">{{ $t('flashcards.header.title') }} {{ subject ? ` - ${ subject }` : '' }}</p>
                </div>
                <div class="nav__middle">
                    <FlashcardsProgress v-if="!practiceMode" />
                </div>
                <div class="nav__right">
                    <div class="close-flashcards-wrapper" @click="handleCloseAction">
                        <div class="close-flashcards">{{ $t('flashcards.header.return-to-space') }}</div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FlashcardsProgress from '@/components/flashcards/FlashcardsProgress'

export default {
    name: 'FlashcardsHeader',
    components: { FlashcardsProgress },
    computed: {
        ...mapState('flashcards', ['subject', 'exercises', 'practiceMode']),
        ...mapGetters('flashcards', ['isMobile', 'isDefinitionExercise', 'getCurrentExercise', 'getAnswer', 'getCorrectAnswer']),
        getExerciseTitle() {
            if (this.getCurrentExercise?.type === 'DEFINITION') return this.getCurrentExercise?.english.split(' ').length === 1
                ? this.$i18n.t('flashcards.exercise.title.definition.word', this.transUi)
                : this.$i18n.t('flashcards.exercise.title.definition.expression', this.transUi)

            return this.$i18n.t(`flashcards.exercise.title.${ this.getCurrentExercise.type.toLowerCase() }`, this.transUi)
        }
    },
    methods: {
        handleCloseAction() {
            if (this.$route.params.lessonUuid) this.$router.push({ name: 'singleLesson', params: { uuid: this.$route.params.lessonUuid }})
            else this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>

.lesson-stats-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.exercise-title {
    color: $gray-900;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.4px;
    min-height: 21px;
}

.nav-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    justify-content: center;
    height: 79px;
    padding: 16px;
}

.nav {
    height: 56px;
    gap: 12px;
    justify-content: space-between;
    display: flex;

    &__left {
        flex: 1;
        height: inherit;
        overflow: hidden;
    }

    &__right {
        margin-left: auto;
        flex: 1;

        .close-learning-wrapper {
            margin-left: auto;
        }

        &--mobile {
            flex: 0 1 auto;
        }
    }

    &__middle {
        max-width: 730px;
        flex: 1;
    }
}

.logo {
    margin-right: 24px;
}

.title {
    @include font-small-12;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $gray-900;

    @media(min-width: $mobile-learning) {
        @include font-h3;
    }
}

.close-flashcards-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;

    img {
        width: 20px;
        height: 20px;
    }
}

.close-flashcards {
    @include font-medium;
    color: $primary;
    padding: 7px 13px;
    background: $white;
    border-radius: 6px;
    border: 1px solid $gray-200;
    transition: all .25s;

    &:hover {
        border: 1px solid $gray-300;
    }
}
</style>