<template>
    <div class="widget skeleton" :class="type">
        <div class="header">
            <div class="image dark"></div>
            <div class="flex-1" style="margin-top:6px;">
                <div class="big dark"></div>
                <div class="flex">
                    <div class="small light"></div>
                    <div v-if="lesson" class="small light"></div>
                </div>
            </div>
        </div>
        <div v-if="!lector" class="content">
            <div class="big dark"></div>
            <div class="flex flex-1">
                <div class="light" :class="[student ? 'small' : 'big']"></div>
                <div v-if="student" class="light" :class="[student ? 'small' : 'big']"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Skeleton',
    props: ['type'],
    computed: {
        student() {
            return this.type === 'student'
        },
        lesson() {
            return this.type === 'lesson'
        },
        lector() {
            return this.type === 'lector'
        }
    }
}
</script>