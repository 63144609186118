<template>
    <div class="list-pagination">
        <div
            :class="['arrow-wrapper', { 'arrow-wrapper--disabled': isFirstPage }]"
            @click="changePage(currentPage - 1)"
        >
            <img :src="arrow" alt="">
        </div>
        <ListPaginationPage
            v-for="(page, index) in paginationPages"
            :key="index"
            :page="page"
            :currentPage="currentPage"
            @changePage="changePage"
        />
        <div
            :class="['arrow-wrapper arrow-wrapper--rotate', { 'arrow-wrapper--disabled': isLastPage }]"
            @click="changePage(currentPage + 1)"
        >
            <img :src="arrow" alt="">
        </div>
    </div>
</template>

<script>
import ListPaginationPage from '@/components/reusable/list/ListPaginationPage'
import arrow from '@/assets/img/list/arrow.svg'

export default {
    name: 'ListPagination',
    props: {
        pages: Number,
        currentPage: Number
    },
    components: {
        ListPaginationPage
    },
    data() {
        return {
            arrow
        }
    },
    computed: {
        isFirstPage() {
            return this.currentPage === 1
        },
        isLastPage() {
            return this.currentPage === this.pages
        },
        paginationPages() {
            const pages = []

            pages.push(1)
            if(this.currentPage - 1 > 2 && this.pages > 4) pages.push('...')
            if(this.isLastPage && this.pages > 3) pages.push(this.pages - 2)
            if(this.currentPage - 1 > 1) pages.push(this.currentPage - 1)
            if(this.currentPage > 1 && this.currentPage < this.pages) pages.push(this.currentPage)
            if(this.currentPage + 1 < this.pages) pages.push(this.currentPage + 1)
            if(this.isFirstPage & this.pages > 3) pages.push(3)
            if(this.currentPage + 1 < this.pages - 1 && this.pages > 4) pages.push('...')
            pages.push(this.pages)

            return pages
        }
    },
    methods: {
        changePage(value) {
            this.$emit('changePage', value)
        }
    }
}
</script>

<style lang="scss" scoped>
.list-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 16px 0;
    @include font-regular;
}

.arrow-wrapper {
    cursor: pointer;
    width: 20px;
    height: 16px;

    &--disabled {
        opacity: .3;
        pointer-events: none;
    }

    &--rotate {
        transform: rotate(180deg);
    }
}
</style>