<template>
    <div v-if="!loading" class="preferences">
        <template v-if="!summary">
            <!-- step titles -->
            <p v-if="$i18n.te(titles.title)" class="title">{{$i18n.t(titles.title, replaceParams(titles))}}</p>
            <p v-if="$i18n.te(titles.subTitle)" class="subtitle">{{$i18n.t(titles.subTitle, replaceParams(titles))}}</p>
            <form>
                <!-- fields -->
                <div v-for="item in structure" :key="item.name" :class="[{'on-check' : item.onCheck}, item.type, item.order]">
                    <!-- on-check fields -->
                    <template v-if="(item.onCheck || item.type === 'bool') && showCheck(item)">
                        <Checkbox :data="{label: $i18n.t(item.label, replaceParams(item)), asRadio: item.asRadio}" :class="{'disable': disableCheck(item)}" v-model="formData[item.name]"/>
                        <template v-if="item.onCheck && formData[item.name]">
                            <component 
                                :is="mapper[item.type]" 
                                v-model="formData[item.name]"
                                :data="createFieldData(item)"
                            />
                        </template>
                        <p v-if="getValidation(item.name)" class="validation bottom">
                            {{$i18n.t(getValidation(item.name))}}
                        </p>
                    </template>
                    <!-- normal fields -->
                    <template v-if="showCheck(item) && !item.onCheck">
                        <p v-if="getValidation(item.name) && item.type === 'week-hour-range'" class="validation top">
                            {{$i18n.t(getValidation(item.name))}}
                        </p>
                        <component  
                            :is="mapper[item.type]" 
                            v-model="formData[item.name]"
                            :data="createFieldData(item)"
                            @hourRangeData="setHourRangeData"
                        />
                        <p v-if="getValidation(item.name) && item.type !== 'week-hour-range'" class="validation step">
                            {{$i18n.t(getValidation(item.name))}}
                        </p>
                    </template>
                </div>
            </form>
            <!-- step validation -->
            <div v-if="getValidation(`step_${currentStep}`)" class="validation step">
                {{$i18n.t(getValidation(`step_${currentStep}`))}}
            </div>
            <!-- step tip -->
            <div v-if="$i18n.te(tip.title) && $i18n.te(tip.description)" class="tip">
                <img class="bulb" src="@/assets/img/onboarding/bulb.svg" alt="">
                <div class="content">
                    <div class="header" @click="tipActive = !tipActive">
                        <p class="title" v-html="$i18n.t(tip.title, replaceParams(tip))"></p>
                        <img class="toggler" :class="{'active': tipActive}" src="@/assets/img/onboarding/arrow.svg" alt="">
                    </div>
                    <p v-if="tipActive" class="dsc" v-html="$i18n.t(tip.description, replaceParams(tip))"></p>
                </div>
            </div>
        </template>
        <!-- summary -->
        <div v-else class="summary">
            <p class="title">{{$i18n.t('onboarding.preferences.summary.title')}}</p>
            <div v-for="item in filteredSummary" :key="item.step" class="summary-item">
                <div class="header">
                    <p class="title">{{$i18n.t(item.title)}}</p>
                    <p @click="backToStep(item.step)" class="action">{{$i18n.t('onboarding.preferences.summary.change')}}</p>
                </div>
                <div class="row" :class="[value.type, {'no-icon': !value.label}]" v-for="(value, index) in item.values" :key="index">
                    <img :class="{'hide': !summaryIcon(value)}" src="@/assets/img/check-green.svg" alt="">
                    <p>
                        <span class="label">{{formatSummaryLabel(value)}}{{value.label && value.value && value.type !== 'time-range-string' ? ': ' : ''}}</span>
                        <span class="value" v-html="formatSummaryValue(value)"></span>
                    </p>
                </div>
            </div>
        </div>
        <!-- bottom nav -->
        <div class="bg-white nav-bar-bottom">
            <div class="progress" :style="`width: ${currentStep/stepsCount * 100}%`"></div>
            <div class="wrapper wrapper--big">
                <nav class="nav-bottom">
                    <div class="button-wrapper left">
                        <Button @click.native="changeStep(false)" class="default" :class="{'active': currentStep > 1}" :text="$i18n.t('onboarding.preferences.back')"/>
                    </div>
                    <p v-if="false" class="step-progress">{{currentStep}}/{{stepsCount}}</p>
                    <div class="button-wrapper right">
                        <Button :loading="submitLoading" @click.native="changeStep(true)" class="primary active" :text="summary ? $i18n.t('onboarding.preferences.submit') : $i18n.t('onboarding.preferences.next')"/>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { studentPreferences } from '@/graphql/queries/studentPreferences'
import { preferencesTokenValidation } from '@/graphql/queries/preferencesTokenValidation'
import { updateStudentPreferences } from '@/graphql/mutations/updateStudentPreferences'
import { validateStudentPreferences } from '@/graphql/mutations/validateStudentPreferences'
import api from '@/graphql/api.js'
import Button from '@/components/reusable/Button'
import Checkbox from '@/components/reusable/Checkbox'
import Select from '@/components/reusable/Select'
import Textarea from '@/components/reusable/Textarea'
import RadioWrapper from '@/components/reusable/RadioWrapper'
import Date from '@/components/reusable/Date'
import DateTime from '@/components/reusable/DateTime'
import Range from '@/components/reusable/Range'
import WeekHourRange from '@/components/reusable/WeekHourRange'
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter'
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'OnboardingPreferences',
    beforeRouteEnter(to, from, next) {
        if(!to.params.token && !to.params.type) {
            next({name: 'dashboard', params: {locale: to.params.locale}})
        } else next()
    },
    components: {
        Button,
        Checkbox,
        Select,
        Textarea,
        RadioWrapper,
        Date,
        DateTime,
        Range,
        WeekHourRange
    },
    data() {
        return {
            loading: true,
            preferences: null,
            currentStep: 1,
            formData: {},
            validation: null,
            tipActive: true,
            submitLoading: false,
            summary: false,
            hourRangeData: null,
            mapper: {
                select: Select,
                text: Textarea,
                textarea: Textarea,
                radio: RadioWrapper,
                range: Range,
                ['datetime-local']: DateTime,
                date: Date,
                ['week-hour-range']: WeekHourRange
                
            }
        }
    },
    async created() {
        const {res:check} = await api(preferencesTokenValidation, {
            userUuid: this.$store.getters.userUuid,
            token: this.$route.params.token
        })
        if(!check.preferencesTokenValidation.isValid) {
            this.$notify({
                title: this.$i18n.t('onboarding.preferences.invalid-token.title'),
                text: this.$i18n.t('onboarding.preferences.invalid-token.subtitle'),
                data: {
                    type: 'error'
                }
            })
            this.$router.push({name: 'dashboard'})
            return
        }

        const {res} = await api(studentPreferences, {
            locale: localStorage.getItem('locale')
        }, 'studentPreferences')

        this.preferences = res.configuration.studentPreferences.find(item => item.courseTypes.includes(this.$route.params.type))
        const form = {}
        this.preferences.structure.forEach(item => {
            if(item.type === 'select' && !item.onCheck && !item.defaultValue) form[item.name] = true
            else form[item.name] = item.defaultValue

            const valueActions = item.actions.filter(action => action.type === 'set_value')
            valueActions.forEach(action => {
                this.$watch(`formData.${action.fieldName}`, () => {
                    if((!action.values.length && this.formData[action.fieldName]) || action.values.includes(this.formData[action.fieldName])) this.formData[item.name] = action.value
                })
            })
        })
        this.formData = form

        this.loading = false
    },
    computed: {
        stepsCount() {
            const stepsArray = this.preferences.structure.map(item => item.step)

            return Math.max(...stepsArray)
        },
        structure() {
            return this.preferences.structure.filter(item => item.step == this.currentStep)
        },
        lastStep() {
            return this.currentStep === this.stepsCount
        },
        titles() {
            return this.preferences.titles.find(item => item.step === this.currentStep)
        },
        tip() {
            return this.preferences.tips.find(item => item.step === this.currentStep)
        },
        filteredSummary() {
            return this.summary.filter(item => item.values.length)
        },
        defaultTimezone() {
            return this.$store.state.config.defaultTimezone
        }
    },
    methods: {
        async changeStep(way) {
            const actions = this.preferences.actions.filter(action => action.type === 'hide')

            if(this.currentStep > 1 && !way) {
                if(this.summary) {
                    this.currentStep = this.stepsCount
                    this.summary = false
                } else {
                    this.currentStep--
                    actions.map(action => {
                        if(this.currentStep === action.step && ((!action.values.length && this.formData[action.fieldName]) || action.values.includes(this.formData[action.fieldName]))) this.currentStep--
                    })
                }
            }
            else if(way) {
                this.submitLoading = true
                if(!this.summary) {
                    const {error} = await api(validateStudentPreferences, this.preferencesMutationBody(this.currentStep))
                
                    if(!error) {
                        this.validation = null

                        if(!this.lastStep) {
                            this.currentStep++
                            actions.map(action => {
                                if(this.currentStep === action.step && ((!action.values.length && this.formData[action.fieldName]) || action.values.includes(this.formData[action.fieldName]))) this.currentStep++
                            })
                        }
                        else {
                            const {res} = await api(validateStudentPreferences, this.preferencesMutationBody())
                            this.summary = res.validateStudentPreferences
                        }
                    } 
                    else {
                        const validation = error.find(item => item.message === 'validation')?.extensions.validation
                        if(validation) this.validation = validation
                        else {
                            this.submitLoading = false
                            this.$notify({
                                title: this.$i18n.t('notify.error.title'),
                                text: this.$i18n.t('notify.error.subtitle'),
                                data: {
                                    type: 'error'
                                }
                            })
                        }
                    }
                } else {
                    await this.submit()
                }

                this.submitLoading = false
            }
        },
        preferencesMutationBody(step = null) {
            return {
                userUuid: this.$store.getters.userUuid,
                type: this.$route.params.type,
                token: this.$route.params.token,
                step,
                fields: this.createMutationFields(step),
                locale: localStorage.getItem('locale')
            }
        },
        createMutationFields(step = null) {
            let structure
            if(step) structure = this.structure
            else structure = this.preferences.structure

            return structure.map(item => {
                return {
                    name: item.name,
                    value: String(this.formData[item.name]),
                    type: item.type
                }
            })
        },
        createOptions(array) {
            return array.map(item => {
                return {
                    text: this.$i18n.t(item.label),
                    value: item.value
                }
            })
        },
        createFieldData(item) {
            return {
                options: item.type === 'select' ? this.createOptions(item.options) : item.options,
                name: item.name,
                min: item.rangeMin || this.formatDateInputs(item.rangeDateMin),
                max: item.rangeMax || this.formatDateInputs(item.rangeDateMax),
                placeholder: this.$i18n.t(`general.choose`),
                autoPlaceholder: true,
                hourRangeData: this.hourRangeData
            }
        },
        showCheck(item) {
            const show = item.actions.filter(action => action.type === 'show')
            let output = false
            if(!show.length) output = true
            else show.map(item => {
                if(item.values.includes(this.formData[item.fieldName])) output = true
            })

            return output
        },
        disableCheck(item) {
            const disable = item.actions.filter(action => action.type === 'disable')
            let output
            if(!disable.length) output = false
            else disable.map(item => {
                if(this.formData[item.fieldName] && (!item.values.length || item.values.includes(this.formData[item.fieldName]))) output = true
            })
            return output
        },
        backToStep(step) {
            this.currentStep = step
            this.summary = false
        },
        formatSummaryValue(item) {
            if(item.type === 'date') return this.$moment(item.value).format(timezoneDate('D MMMM YYYY'))
            else if(item.type === 'datetime-local') return this.$moment(item.value).format(timezoneDate('D MMMM YYYY o HH:mm'))
            else if(item.type === 'time-range-string') {
                const format = index => this.$moment(JSON.parse(item.value)[index]).tz(this.defaultTimezone).format(timezoneDate('HH:mm'))

                return `<img src="${require('@/assets/img/clock.svg')}" alt=""> ${format(0)}-${format(1)}`
            }
            else return this.$i18n.t(item.value, this.replaceParams(item))
        },
        formatSummaryLabel(item) {
            return item.type === 'time-range-string' && item.label ? capitalizeFirstLetter(this.$moment.weekdays(true)[item.label-1]) : this.$i18n.t(item.label, this.replaceParams(item))
        },
        summaryIcon(item) {
            if(item.type !== 'time-range-string') return true
            return item.label ? true : false
        },
        async submit() {
            const {error} = await api(updateStudentPreferences, this.preferencesMutationBody())
            if(error) {
                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            }
            else {
                this.$notify({
                    title: this.$i18n.t('onboarding.preferences.submitted.title'),
                    text: this.$i18n.t('onboarding.preferences.submitted.subtitle'),
                    data: {
                        type: 'success'
                    }
                })
                this.$router.push({name: 'dashboard'})
            }
        },
        getValidation(name) {
            return this.validation?.[name]?.[0].message || this.validation?.[`${name}_bottom`]?.[0].message
        },
        formatDateInputs(date) {
            return this.$moment(date).format('YYYY-MM-DD')
        },
        replaceParams(ob) {
            const params = ob.translateParameters || ob.parameters
            if(params) return JSON.parse(params)
        },
        setHourRangeData(data) {
            this.hourRangeData = data
        }
    },
    watch: {
        formData: {
            handler() {
                this.validation = null
            },
            deep: true
        },
        currentStep() {
            this.tipActive = true
        }
    }
}
</script>
