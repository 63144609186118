<template>
    <div class="inputs">
        <Date v-model="datetime.date" :data="{min: data.min, max: data.max}"/>
        <vue-timepicker class="input" placeholder="--:--" v-model="datetime.time" lazy :minute-interval="30"></vue-timepicker>
    </div>
</template>

<script>
import Date from '@/components/reusable/Date'

export default {
    name: 'DateTime',
    props: ['data', 'value'],
    components: {
        Date
    },
    data() {
        return {
            datetime: {
                date: this.formatDate(this.value, 'YYYY-MM-DD'),
                time: {
                    HH: this.formatDate(this.value, 'HH'),
                    mm: this.formatDate(this.value, 'mm'),
                }
            }
        }
    },
    methods: {
        formatDate(date, format) {
            return this.value ? this.$moment(date).format(format) : null
        }
    },
    watch: {
        datetime: {
            handler() {
                const day = this.datetime.date
                const hours = this.datetime.time?.HH
                const minutes = this.datetime.time?.mm
                if(day && hours && minutes) this.$emit('input', this.$moment(`${day} ${hours}:${minutes}`, 'YYYY-MM-DD HH:mm').format())
                else this.$emit('input', null)
            },
            deep: true
        }
    }
}
</script>
