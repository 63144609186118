<template>
    <div class="placement-test-summary">
        <img src="@/assets/img/progressTest/placement-test.svg" alt="">
        <p class="placement-test-summary__title">{{ $t('placement-test.summary.title') }}</p>
        <p class="placement-test-summary__subtitle">{{ $t('placement-test.summary.subtitle') }}</p>
        <div class="placement-test-summary__block">
            <div class="header">
                <div class="header__info">
                    <img class="header__avatar avatar-style" :src="setAvatar(getCurrentUserAvatar)" alt="">
                    <div class="header__description">
                        <p class="header__name">{{ userName }},</p>
                        <p class="header__text">
                            {{ $t('placement-test.summary.before-cefr') }}
                            <span class="header__info-cefr">{{ $t('placement-test.summary.cefr') }}<Tooltip :text="$t('placement-test.summary.cefr-description')"/></span>
                            {{ $t('placement-test.summary.after-cefr') }}
                        </p>
                    </div>
                </div>
                <div class="header__result">
                    <p class="header__result-value">{{ level }}</p>
                    <p class="header__result-label">{{ $t(`placement-test.summary.result.${ shortLevel }.label`) }}</p>
                </div>
            </div>
        </div>
        <div v-if="data.oralTestFinished" class="oral-summary">
            <div class="oral-summary__badge">
                <img src="@/assets/img/progressTest/mic-warning.svg" alt=""> {{ $t('placement-test-summary.oral.badge') }}
            </div>
            <p class="oral-summary__title">{{ $t('placement-test-summary.oral.title') }}</p>
            <p class="oral-summary__description">{{ $t('placement-test-summary.oral.description') }}</p>
            <p class="oral-summary__list-title">{{ $t('oral-summary__list-title') }}</p>
            <ul class="oral-summary__list">
                <li>{{ $t('oral-summary__list-item-1') }}</li>
                <li>{{ $t('oral-summary__list-item-2') }}</li>
            </ul>
        </div>
        <div class="placement-test-summary__block">
            <div class="placement-test-summary__badge">
                <img src="@/assets/img/progressTest/pen-primary.svg" alt=""> {{ $t('placement-test-summary.badge') }}
            </div>
            <div class="level-description">
                <div class="level-description__content">
                    <p class="level-description__title">{{ $t('placement-test.summary.result.header') }}</p>
                    <p class="level-description__text">{{ descriptionForStudentLvl }}</p>
                    <p class="level-description__text">{{ $t('placement-test.summary.result.text') }}</p>
                </div>
            </div>

            <div class="levels">
                <div 
                    v-for="el in levels" :key="el"
                    :class="[
                        `levels__bar levels__bar--${ el }`,
                        { 'levels__bar--active': el === shortLevel }
                    ]"
                >
                    <div v-if="el === shortLevel" :class="['arrow-wrapper', { 'arrow-wrapper--mirror' : el === 'A1' }]">
                        <span :class="['text', `${ el }`]">{{ $t('progress-test.summary.your-level') }}</span>
                        <img :class="['arrow']" src="@/assets/img/progressTest/gold-arrow.svg" alt="">
                    </div>
                    {{ el }}
                </div>
            </div>
            <div class="skills">
                <div 
                    v-for="skill in skills" 
                    :key="skill" 
                    :class="[`skill skill--${ skill }`]"
                >   
                    <div class="skill__bar">
                        <div :class="[`skill__bar-inner skill__bar-inner--${ shortLevel }`]"></div>
                    </div>
                    {{ $t(`placement-test.summary.${ skill }`) }}
                </div>
            </div>
            <div class="placement-test-summary__charts">
                <!-- <p class="header-h2">{{ $t('placement-test.summary.compare-to-others.header') }}</p>
                <p class="text-1">{{ $t('placement-test.summary.compare-to-others.description') }}</p> -->

                <p class="header-h2">{{ $t('placement-test.summary.skills.header') }}</p>
                <p class="text-1">{{ $t('placement-test.summary.skills.description') }}</p>

                <ChartRadar :score="data.score" />

                <p class="header-h2">{{ $t('placement-test.summary.vocabulary.header') }}</p>

                <div class="placement-test-summary__2-boxes">
                    <div class="placement-test-summary__2-boxes-box">
                        {{ $t('placement-test.summary.vocabulary.box.words-average', { lvl: level }) }}
                        <div class="placement-test-summary__2-boxes-badge">~{{ averageWords }}</div>
                    </div>
                    <div class="placement-test-summary__2-boxes-box">
                        {{ $t('placement-test.summary.vocabulary.box.after-2-sem') }}
                        <div class="placement-test-summary__2-boxes-badge primary">{{ averageWordsAfter2Semesters }}</div>
                    </div>
                </div>

                <p class="text-1 mt-4">{{ $t('placement-test.summary.vocabulary.after-2-sem.description', { lvl: levelAfter2Semesters, words: averageWordsAfter2Semesters }) }}</p>

                <p class="header-h2">{{ $t('placement-test.summary.schema.header') }}</p>

                <LevelsChart />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import setAvatarMixin from '@/mixins/setAvatar';
import Tooltip from '@/components/reusable/Tooltip'
import ChartRadar from '@/components/placementTest/charts/ChartRadar';
import LevelsChart from '@/components/placementTest/charts/LevelsChart';
import LANGUAGE_LEVELS from '@/constants/languageLevels';

export default {
    name: 'PlacementTestSummary',
    props: ['data'],
    components: {
        Tooltip,
        ChartRadar,
        LevelsChart
    },
    mixins: [setAvatarMixin],
    data() {
        return {
            userName: this.$store.state.user.firstName,
            levels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
            skills: ['beginner', 'intermediate', 'advanced'],
            levelAfter2Semesters: null,
            averageWords: null,
            averageWordsAfter2Semesters: null
        }
    },
    created() {
        const currentLevelIdx = LANGUAGE_LEVELS.findIndex(el => el.shortName === this.level);

        this.averageWords = LANGUAGE_LEVELS[currentLevelIdx].baseWords;

        this.levelAfter2Semesters = LANGUAGE_LEVELS[currentLevelIdx + 2].shortName;
        this.averageWordsAfter2Semesters = LANGUAGE_LEVELS[currentLevelIdx + 2].baseWords;
    },
    computed: {
        ...mapGetters(['getCurrentUserAvatar']),
        shortLevel() {
            return this.level.split('.')[0]
        },
        descriptionForStudentLvl() {
            return this.$t(`placement-test.summary.level-description.${ this.shortLevel }`);
        },
        level() {
            var lvlParts = this.data.languageLevel.shortName.split(' ');
            return lvlParts[0];
        }
    }
}
</script>
<style lang="scss" scoped>
$mobile: 768px;

@mixin badge {
    padding: 4px 8px;
    display: inline-flex;
    gap: 8px;
    @include font-h5-uc;
    border-radius: 6px;
}

.placement-test-summary {
    width: 608px;
    max-width: 100%;
    margin: auto;
    background: $white;
    margin-top: -80px;
    padding-top: 80px;
    background-image: url('~@/assets/img/progressTest/confetti1.svg');
    background-repeat: no-repeat;
    background-position: 0 42px;

    @media(min-width: $mobile) {
        background-position: 0 -7px;
    }

    &__title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-top: 8px;
    }

    &__subtitle {
        margin-top: 8px;
        margin-bottom: 32px;
        @include font-h2;
        color: $gray-600;

        @media(min-width: $mobile) {
            margin-bottom: 40px;
        }
    }

    &__block {
        border: 2px solid $gray-200;
        border-radius: 10px;
        text-align: left;
        margin-top: 16px;
        padding: 16px;

        @media(min-width: $mobile) {
            padding: 24px;
        }
    }

    &__badge {
        @include badge;
        color: $primary;
        background: $primary-light;
        margin-bottom: 16px;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media(min-width: $mobile) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__avatar {
            width: 64px;
            height: 64px;
        }

        &__info {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        &__name {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: $gray-900;
            margin-bottom: 8px;
        }

        &__text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: $gray-600;
        }

        &__info-cefr {
            @media(min-width: $mobile) {
                position: relative;
                color: $gray-700;
                text-decoration: underline;

                &:hover {
                    .tooltip {
                        display: block;
                    }
                }
            }
        }

        &__result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 14px 34.5px;
            background: $secondary-light;
            color: $secondary-dark;
            border: 2px solid $secondary-mid;
            border-radius: 10px;
            flex-shrink: 0;
        }

        &__result-value {
            font-size: 40px;
            line-height: 40px;
            font-weight: 600;
        }

        &__result-label {
            @include font-small-12;
        }
    }
    
    .level-description__title {
        @include font-h3;
        color: $gray-900;
    }

    .level-description__text {
        @include font-regular-text;
        margin: 8px 0 24px;
        color: $gray-900;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .level-description__content {
        text-align: left;
    }

    .levels {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        margin: 0 0 16px;
        padding-top: 32px;

        @media(min-width: $mobile) {
            padding-top: 40px;
        }

        &__bar {
            flex: 1;
            background: $primary-light;
            color: $primary-dark;
            border-radius: 8px;
            margin-top: 32px;
            padding: 8px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            position: relative;

            @media(min-width: $mobile) {
                text-align: left;
            }

            &--active {
                background: $primary;
                color: $white;
            }

            &--A1 {
                height: 56px;
            }

            &--A2 {
                height: 80px;
            }

            &--B1 {
                height: 104px;
            }

            &--B2 {
                height: 128px;
            }

            &--C1 {
                height: 152px;
            }

            &--C2 {
                height: 176px;
            }

            .arrow-wrapper {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: -65px;
                gap: 8px;
                right: 0px;
                width: 100%;

                .text {
                    color: #A07C04;
                    text-align: center;
                    font-style: normal;
                    @include font-regular;
                    white-space: nowrap;
                    max-width: 70px;
                    font-family: 'Gloria Hallelujah', cursive !important;
                    position: absolute;
                    left: 50%;
                    top: -3px;

                    &.A1, &.A2, &.B1, &.B2, &.C1 {
                        transform: translateX(-50%) rotate(-8deg);
                    }

                    &.C2 {
                        transform: translateX(-50%) rotate(8deg);
                    }
                }

                .arrow {
                    height: 32px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, calc(100% - 8px));
                }

                &--mirror {
                    .arrow {
                        top: 25px;
                        transform: translateX(-50%) rotate(-8deg) scaleX(-1);
                    }
                }
            }
        }
    }

    .skills {
        display: flex;
        gap: 2px;
        margin-bottom: 20px;
        text-align: center;
    }

    .skill {
        flex: 1;
        @include font-small-12;
        color: $gray-600;

        &__bar {
            height: 16px;
            overflow: hidden;
            background: $gray-100;
            margin-bottom: 4px;
        }

        &__bar-inner {
            height: 100%;
        }

        &--beginner {
            .skill__bar {
                border-radius: 10px 0 0 10px;
            }

            .skill__bar-inner {
                background: $secondary;

                &--A1 {
                    width: 22%;
                }

                &--A2 {
                    width: 73.5%;
                }
            }
        }

        &--intermediate {
            .skill__bar-inner {
                background: $secondary-dark;

                &--A1, &--A2 {
                    width: 0;
                }

                &--B1 {
                    width: 24.5%;
                }

                &--B2 {
                    width: 76%;
                }
            }
        }

        &--advanced {
            .skill__bar {
                border-radius: 0 10px 10px 0;
            }

            .skill__bar-inner {
                background: darken($secondary-dark, 10);
                width: 0;

                &--C1 {
                    width: 25%;
                }

                &--C2 {
                    width: 79%;
                }
            }
        }
    }

    .header-h2 {
        @include font-h3;
        padding-top: 32px;
        margin-top: 32px;
        margin-bottom: 8px;
        border-top: 1px solid $gray-200;
        color: $gray-900;
    }

    .text-1 {
        @include font-regular-text;
        color: $gray-900;
    }

    .mt-4 {
        margin-top: 24px;
    }

    .placement-test-summary__charts {
        text-align: left;
    }

    .placement-test-summary__2-boxes {
        display: flex;
        margin-top: 24px;
        justify-content: space-between;
        gap: 24px;
    }
    .placement-test-summary__2-boxes-box {
        @include font-regular-text;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;
        color: $gray-900;
    }
    .placement-test-summary__2-boxes-badge {
        display: flex;
        border-radius: 10px;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        padding: 8px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background-color: $gray-100;
        color: $gray-600;

        &.primary {
            background-color: $primary-light;
            color: $primary;
        }
    }
}

.oral-summary {
    padding: 22px;
    margin-top: 16px;
    border-radius: 10px;
    border: 2px dashed $gray-200;
    text-align: left;

    &__badge {
        @include badge;
        color: $warning-dark;
        background: $warning-light;
    }

    &__title {
        margin: 16px 0 8px;
        @include font-h3;
        color: $gray-600;
    }

    &__description {
        @include font-regular-text;
        color: $gray-600;
    }

    &__list-title {
        margin-top: 24px;
        @include font-regular-text;
        color: $gray-600;
    }

    &__list {
        @include font-regular-text;
        color: $gray-600;

        li {
            margin-left: 20px;
        }
    }
}
</style>