<template>
    <div :class="['widget sl', { 'sl--expand': expand }]">
        <div class="sl__content">
            <span class="sl__new">{{ $t('dashboard.sl.new') }}</span>
            <p class="sl__title" v-html="$t('dashboard.sl.title')"></p>
            <p class="sl__subtitle" v-html="$t('dashboard.sl.subtitle')"></p>
            <Button
                class="primary"
                :text="$t('dashboard.sl.btn')"
                @click.native="handleBtnClick"
            />
        </div>
        <div v-if="showClose" class="sl__close" @click="hide">
            <img width="12" height="12" src="@/assets/img/notify/close.svg" alt="">
        </div>
        <img class="sl__shapes" src="@/assets/img/learning/shapes.svg" alt="">
        <img class="sl__learning" src="@/assets/img/learning/learning.png" alt="">
    </div>
</template>

<script>
import { setCookie, getCookie } from '@/functions/cookies'
import Button from '@/components/reusable/Button'

export default {
    name: 'SmartLearning',
    components: {
        Button
    },
    data() {
        return {
            expand: false,
            showClose: false
        }
    },
    mounted() {
        if(!getCookie('hideSmartLearningWidget')) {
            setTimeout(() => {
                this.expand = true

                setTimeout(() => this.showClose = true, 1000)
            }, 1000)
        }
    },
    methods: {
        handleBtnClick() {
            this.$bus.$emit('showUserMenu')
        },
        hide() {
            setCookie('hideSmartLearningWidget', true, 24 * 7)
            this.expand = false
        }
    }
}
</script>

<style lang="scss" scoped>
.sl {
    padding: 0 !important;
    margin-bottom: 0 !important;
    position: relative;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 1s;

    &--expand {
        padding: 32px 24px 24px !important;
        margin-bottom: 24px !important;
        opacity: 1;
        max-height: 232px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1;
    }

    &__new {
        @include font-h6-uc;
        color: $white;
        background: $danger-middle;
        padding: 0 4px;
        border-radius: 4px;
    }

    &__title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: $gray-700;
        margin: 8px 0 14px;
    }

    &__subtitle {
        font-family: 'DINNextRoundedLTPro-Medium' !important;
        font-size: 20px;
        line-height: 18px;
        font-weight: 500;
        color: $primary;
        margin-bottom: 30px;

        ::v-deep span {
            position: relative;

            &:before {
                content: '';
                width: 100%;
                height: 5px;
                background: url('~@/assets/img/learning/yellow-curved-line.svg') no-repeat;
                position: absolute;
                left: 0;
                bottom: -7px;
            }
        }
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        filter: brightness(.7);
    }

    &__shapes {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__learning {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .btn {
        font-weight: 500;
        width: 224px;
        max-width: 100%;
    }
}
</style>