<template>
    <div class="ask-ai-search">
        <div v-if="hint && !askAI.game && !askAI.loading && askAI.messages.length <= 1" class="ask-ai-search__hint-container">
            <AskAiHint
                :hint="hint"
                :disabled="askAI.loading || askAI.isDisabled"
                @click.native="handleHint(hint.prompt, hint.uuid)"
            />
        </div>
        <div class="ask-ai-search__footer">
            <div v-if="askAIModal && !askAI.game" class="ask-ai-search__modal-open">
                <AskAIStartTooltip v-if="askAI.showStartTooltip" />
                <img
                    :class="['ask-ai-search__modal-icon', { 'ask-ai-search__modal-icon--disabled': askAI.loading, 'ask-ai-search__modal-icon--mobile': isMobile }]"
                    @click="openAskAIModal"
                    src="@/assets/img/tools/askAI-primary-icon.svg"
                    alt=""
                >
            </div>
            <div :class="[
                'ask-ai-search__prompts-wrapper',
                {
                    'ask-ai-search__prompts-wrapper--active' : searchActive,
                    'ask-ai-search__prompts-wrapper--mobile' : isMobile
                }
            ]"
            >
                <textarea
                    class="search-input"
                    :placeholder="$t('widget.gpt-chat.ask-ai-placeholder')"
                    v-model="askAI.prompt"
                    @keyup.enter="handleRequest()"
                    @focus="focusSearchInput"
                    @blur="searchActive = false"
                    @input="resize"
                    :disabled="askAI.loading || askAI.isDisabled"
                    ref="input"
                ></textarea>
                <button
                    @click="handleRequest()"
                    :class="['send', { 'loading': askAI.loading, 'disabled': askAI.isDisabled, 'send--mobile': isMobile }]"
                >
                    <img v-if="!isMobile" src="@/assets/img/chat/send.svg" width="24" height="24" alt="">
                    <img v-else src="@/assets/img/chat/send-primary.svg" width="24" height="24" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/graphql/api.js'
import { sendMessageToTeacherAI } from '@/graphql/mutations/sendMessageToTeacherAI'
import { sendMessageToTeacherAIGame } from '@/graphql/mutations/sendMessageToTeacherAIGame'
import AskAiHint from '@/components/tools/askAI/AskAIHint'
import AskAIStartTooltip from '@/components/tools/askAI/AskAIStartTooltip'

export default {
    name: 'AskAISearch',
    props: {
        widgetMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        AskAiHint,
        AskAIStartTooltip
    },
    data() {
        return {
            searchActive: false
        }
    },
    computed: {
        ...mapState('tools', ['askAI', 'askAIModal']),
        ...mapState(['isMobile']),
        hint() {
            return this.askAI.hints?.[0]
        }
    },
    created() {
        this.$bus.$on('askAIModalHint', data => this.handleHint(data.prompt, data.uuid))
        this.$bus.$on('focusAskAIInput', () => this.focusSearchInput());
        this.$bus.$on('resizeAskAIInput', () => this.resize());
        if(!this.widgetMode) {
            this.focusSearchInput();
        } else {
            this.hints = [this.hints[0]];
        }
    },
    beforeDestroy() {
        this.$bus.$off('askAIModalHint');
        this.$bus.$off('focusAskAIInput');
        this.$bus.$off('resizeAskAIInput');
    },
    methods: {
        resize() {
            const textarea = this.$refs.input;

            textarea.style.height = 'auto';

            if (this.isMobile) textarea.style.height = '26px';
            textarea.style.height = Math.min(textarea.scrollHeight, 100) + 'px';
        },
        handleHint(hint, uuid) {
            if (this.askAI.loading) return;

            this.askAI.prompt = hint;
            this.askAI.hints = this.askAI.hints.filter(item => item.prompt !== hint);

            this.$gtag({
                category: 'tool',
                action: 'hint',
                label: 'askAI'
            })

            this.handleRequest(uuid);
        },
        focusSearchInput() {
            this.searchActive = true;
            this.$nextTick(() => {
                this.$refs.input?.focus();
                this.resize();
            });
        },
        async handleRequest(hintUuid = null) {
            if(this.askAI.loading || !/\S/.test(this.askAI.prompt)) return
            this.searchActive = false;

            this.askAI.loading = true;
            this.askAI.hints = [];
            const promptValue = this.askAI.prompt
            this.askAI.prompt = '';
            this.$nextTick(() => this.resize());
            this.$store.commit('tools/addAskAIMessage', {
                author: 'USER',
                content: promptValue,
                createdAt: this.$moment()
            });

            const { error } = await api(this.askAI.game ? sendMessageToTeacherAIGame : sendMessageToTeacherAI, {
                input: {
                    content: hintUuid ? null : promptValue,
                    ...(this.askAI.game && { gameUuid: this.askAI.game.uuid }),
                    ...(!this.askAI.game && { suggestionUuid: hintUuid })
                }
            });

            if(error) this.$store.commit('tools/handleAskAIError')
            
            this.$gtag({
                category: 'tool',
                action: 'prompt',
                label: 'askAI'
            })
        },
        openAskAIModal() {
            if(!this.askAI.loading) this.askAIModal.open = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.ask-ai-search {
    width: 100%;
    padding: 0 12px;
    position: relative;

    .div-enter-active,
    .div-leave-active {
        transition: all 0.5s ease;
    }
    .div-enter-from,
    .div-leave-to {
        opacity: 0;
        transform: translateY(-50px);
    }

    &__hint-container {
        position: absolute;
        top: 0;
        left: 24px;
        transform: translateY(calc(-100% - 18px));
    }

    .ask-ai-hint {
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

        ::v-deep .ask-ai-hint__icon {
            align-self: center;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            width: 100%;
            height: calc(100% + 24px);
            background: linear-gradient(0deg, #FFF 79.35%, rgba(255, 255, 255, 0.00) 100%);
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
    }

    &__modal-open {
        position: relative;
        flex-shrink: 0;
    }

    &__modal-icon {
        cursor: pointer;
        width: 50px;
        height: 50px;
        transition: all .25s;

        &:hover {
            filter: brightness(.985);
        }

        &--disabled {
            cursor: not-allowed;
            filter: unset;
        }

        &--mobile {
            width: 40px;
            height: 40px;
        }
    }

    &__prompts-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 16px;
        padding: 7px 7px 7px 15px;
        border: 1px solid $gray-300;
        box-shadow: 0px 2px 20px rgba(45, 54, 66, 0.08);
        border-radius: 10px;
        @include font-regular-text;

        .search-input {
            &::placeholder {
                color: $gray-500;
            }
        }

        &--active {
            border: 1px solid $primary;
        }

        .send {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background: $primary;
            padding: 8px;
            border: 0;
            border-radius: 6px;
            @include font-h4;
            color: $white;
            cursor: pointer;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }

            &.loading {
                cursor: default;
                position: relative;

                &:before {
                    content: '';
                    background: inherit;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                }

                &:after {
                    content: '';
                    animation: spin 1s linear infinite;
                    position: absolute;
                    left: calc(50% - 8px);
                    top: calc(50% - 8px);
                    width: 16px;
                    height: 16px;
                    background: url('~@/assets/img/loader.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            &--mobile {
                &.loading {
                    img {
                        display: none;
                    }

                    &:after {
                        background: none;
                        background-repeat: none;
                        background-position: none;
                        mask-image: url('~@/assets/img/loader.svg');
                        mask-position: center;
                        mask-repeat: no-repeat;
                        background: $primary-dark;
                    }
                }
            }
        }

        textarea {
            height: 100%;
            width: 100%;
            background: #FFF;
            border: none;
            @include font-regular-text;
            flex: 1;
            outline: 0;
            resize: none;
        }

        &--mobile {
            padding: 8px;

            textarea {
                height: 26px;
            }

            .send {
                background: transparent;
                height: 26px;
            }
        }
    }

    .message-block {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            max-width: 100%;
            @include font-regular-text;
            padding: 16px;
            word-break: break-word;
            color: $gray-900;
            background: $calm-light;
            margin: 0 auto 0 0;
            border-radius: 10px 10px 10px 0px;
            box-shadow: 0px 2px 10px rgba(45, 54, 66, 0.08);
        }
    }
}
</style>