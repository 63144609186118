<template>
    <div v-if="!isMobile" class="accordion skeleton">
        <div class="number">
            <div class="small dark"></div>
        </div>
        <div class="title-wrapper">
            <div class="title">
                <div class="small dark"></div>
            </div>
            <div class="date-wrapper">
                <div class="date">
                    <div class="small dark"></div>
                </div>
                <div class="status">
                    <div class="small dark"></div>
                </div>
            </div>
            <div class="progress-wrapper">
                <div class="progress">
                    <div class="small dark"></div>
                </div>
                <div class="status-bar">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="card-container skeleton">
        <div class="status-wrapper">
            <div class="status">
                <div class="small dark"></div>
            </div>
            <div class="lesson-number">
                <div class="small dark"></div>
            </div>
        </div>
        <div class="topic">
            <div class="small dark"></div>
        </div>
        <div class="date">
            <div class="small dark"></div>
        </div>
        <div class="progress-bar">
            <div class="small dark"></div>
            <div class="small dark"></div>
            <div class="small dark"></div>
            <div class="small dark"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ContentSkeleton',
    computed: {
        ...mapState(['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    padding: 24px;

    .number {
        .small {
            width: 48px;
            height: 48px;
            border-radius: 24px;
            margin-bottom: 0;
        }
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 24px;

        .title {
            .small {
                width: 310px;
                height: 32px;
                margin-bottom: 0;
            }
        }

        .date-wrapper {
            display: flex;
            align-items: center;
            margin-top: 8px;

            .date {
                .small {
                    width: 230px;
                    height: 24px;
                    margin-bottom: 0;
                }
            }

            .status {
                .small {
                    width: 80px;
                    height: 16px;
                    border-radius: 28px;
                    margin-bottom: 0;
                    margin-left: 16px;
                }
            }
        }

        .progress-wrapper {
            display: flex;
            align-items: center;
            margin-top: 12px;
            gap: 12px;

            .progress {
                .small {
                    width: 63px;
                    height: 16px;
                    margin-bottom: 0;
                }
            }

            .status-bar {
                display: flex;
                gap: 4px;

                .small {
                    width: 40px;
                    height: 4px;
                    margin-bottom: 0;
                }

                :first-child {
                    border-radius: 99px 0px 0px 99px;
                }

                :last-child {
                    border-radius: 0px 99px 99px 0px;
                }
            }
        }
    }
}

.card-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid $gray-200;
    border-radius: 10px;
    padding: 16px;

    .status-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .status {
            .small {
                width: 20px;
                height: 20px;
                border-radius: 28px;
                margin-bottom: 0;
            }
        }

        .lesson-number {
            .small {
                width: 46px;
                height: 18px;
                margin-bottom: 0;
            }
        }
    }

    .topic {
        margin-top: 6px;

        .small {
            width: 280px;
            height: 22px;
        }
    }

    .date {
        .small {
            width: 164px;
            height: 16px;
            margin-top: 8px;
        }
    }

    .progress-bar {
        display: flex;
        align-items: center;
        gap: 3px;
        margin-top: 12px;

        .small {
            width: 68px;
            height: 4px;
        }

        :first-child {
            border-radius: 99px 0px 0px 99px;
        }

        :last-child {
            border-radius: 0px 99px 99px 0px;
        }
    }
}
</style>