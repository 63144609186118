import { SCHEDULE_MODE } from '@/constants/schedule';

const openCancelLessonModal = {
    methods: {
        openCancelLessonModal(url) {
            const scheduleUrlParamsArray = url.split('/');
            const studentUuid = scheduleUrlParamsArray[8];
            const lessonUuid = scheduleUrlParamsArray[9];

            this.$store.commit('schedule/setMode', {
                name: SCHEDULE_MODE.CANCEL_LESSON,
                params: {
                    studentUuid,
                    lessonUuid
                }
            });
        },
    }
}

export default openCancelLessonModal;