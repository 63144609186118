<template>
    <div class="widget guide">
        <div class="header">
            <p class="title">{{$i18n.t('guide.title', {name: this.$store.state.user.firstName})}}</p>
            <p class="subtitle">{{getSubtitle()}}</p>
        </div>
        <div class="steps">
            <div v-if="oneStepMode" class="one-step">
                <Button 
                    class="primary"
                    :text="$i18n.t(firstStep.link.description)"
                    @click.native="oneStepAction()"
                />
            </div>
            <div v-else v-for="(step, index) in data.steps" class="step" :class="{ 'finished': isFinished(step), 'failed': isFailed(step), 'current': isCurrentStep(index) }" :key="step.title">
                <div class="order">
                    <div :class="['number', getStepData(step, index)?.numberClass]">
                        <img v-if="isFinished(step)" src="@/assets/img/check-light.svg" alt="">
                        <template v-else>
                            <img v-if="getStepData(step, index)?.icon" :src="getStepData(step, index)?.icon" alt="">
                            <template v-else>
                                {{ index + 1 }}
                            </template>
                        </template>
                    </div>
                    <div v-if="!lastStep(index)" class="line"></div>
                </div>
                <div class="content">
                    <p class="title">{{ getTranslationTitle(step.title)}}</p>
                    <p class="description">{{getTranslationDesc(step.title, step.description)}}<span v-if="getDate(step)" class="date">{{getDate(step)}}</span></p>
                    <div v-if="additionalLinkCondition(step.title) && !isBusiness && !isFinished(step)" class="link-wrapper">
                        <p @click.stop="runScheduleModal" class="link">
                            {{$i18n.t('guide.preferences_step.choose_lector')}}<img src="@/assets/img/arrow2-blue.svg" alt="">
                        </p>
                    </div>
                    <div v-if="isLink(step)" class="link-wrapper">
                        <p v-if="step.link.type === 'internal'" @click="onboardingAction(step)" class="link">
                            {{$i18n.t(step.link.description)}}<img src="@/assets/img/arrow2-blue.svg" alt="">
                        </p>
                        <a v-else :href="step.link.url" target="_blank" class="link">
                            {{$i18n.t(step.link.description)}}<img src="@/assets/img/arrow2-blue.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/graphql/api.js'
import timezoneDate from '@/functions/timezoneDate'
import Button from '@/components/reusable/Button'

export default {
    name: 'Guide',
    props: ['data'],
    components: {
        Button
    },
    computed: {
        ...mapGetters(['isBusiness']),
        oneStepMode() {
            return this.data.steps.length === 1
        },
        firstStep() {
            return this.data.steps[0]
        },
    },
    methods: {
        lastStep(index) {
            return index + 1 === this.data.steps.length
        },
        isNew(step) {
            return step.status === 'NEW'
        },
        isPending(step) {
            return step.status === 'PENDING'
        },
        isFailed(step) {
            return step.status === 'FAILED'
        },
        isFinished(step) {
            return step.status === 'FINISHED'
        },
        isCurrentStep(index) {
            return index === this.data.steps.findIndex(step => !this.isFinished(step) && !this.isFailed(step))
        },
        onboardingAction(step) {
            let params = {}
            if(step.link.parameters) params = JSON.parse(step.link.parameters)

            this.$router.push({name: step.link.url, params})
        },
        getDate(step) {
            const params = step.link?.parameters
            if(!params) return

            const parsed = JSON.parse(params)

            return parsed.startAt ? this.$moment(parsed.startAt).format(timezoneDate('D MMMM - HH:mm')) : null
        },
        isLink(step) {
            return step.link?.url
        },
        getSubtitle() {
            return this.oneStepMode ? `${this.$i18n.t('guide.subtitle.one-step')} ${this.$i18n.t(this.firstStep.title)}.` : this.$i18n.t('guide.subtitle')
        },
        oneStepAction() {
            const step = this.firstStep
            step.link.type === 'internal' ? this.onboardingAction(step) : open(step.link.url, '_blank')
        },
        additionalLinkCondition(transKey) {
            return [
                'guide.young_adult.preferences_step.name',
                'guide.teen.preferences_step.name',
                'guide.kid.preferences_step.name',
                'guide.individual.preferences_step.name'].includes(transKey);
        },
        getTranslationTitle(transKey) {
            const obj = this.additionalLinkCondition(transKey) && this.isBusiness;
            return obj ? this.$i18n.t('guide.individual.preferences_step.title.b2b') : this.$i18n.t(transKey)
        },
        getTranslationDesc(title, transKey) {
            const obj = this.additionalLinkCondition(title) && this.isBusiness;
            return obj ? this.$i18n.t('guide.individual.preferences_step.description.b2b') : this.$i18n.t(transKey)
        },
        runScheduleModal() {
            this.$store.dispatch('schedule/showScheduleModal');
        },
        getStepData(step, stepIndex) {
            if(step.name === 'VERIFY_PLACEMENT_TEST') {
                if(this.isNew(step)) {
                    return {
                        numberClass: 'warning',
                        icon: require('@/assets/img/sand-clock-white.svg')
                    }
                }
                
                if(this.isFailed(step)) {
                    return {
                        numberClass: 'danger',
                        icon: require('@/assets/img/close-white.svg')
                    }
                }
            }
            
            if(step.name === 'REQUESTED_MEETING_PLACEMENT_TEST') {
                if(this.isNew(step)) {
                    return {
                        numberClass: 'orange',
                        icon: require('@/assets/img/megaphone-white.svg')
                    }
                }

                if(this.isPending(step)) {
                    return {
                        icon: require('@/assets/img/school-graduation-primary.svg')
                    }
                }
            }

            if(step.name === 'OFW_STATUS') {
                if(this.isCurrentStep(stepIndex)) {
                    return {
                        numberClass: 'warning',
                        icon: require('@/assets/img/sand-clock-white.svg')
                    }
                }
            }
        }
    }
}
</script>
