<template>
    <div class="edit-message-info">
        <p class="edit-message-info__title">{{ $i18n.t('messages.edit-info') }} ({{ formatDate(message.createdAt) }})</p>
        <img class="edit-message-info__icon" @click="cancelEdit" src="@/assets/img/notify/close.svg" alt="" width="12" height="12">
    </div>
</template>

<script>
import formatDateMixin from '@/mixins/formatDate'

export default {
    name: 'EditMessageInfo',
    props: ['message'],
    mixins: [formatDateMixin],
    methods: {
        cancelEdit() {
            this.$emit('cancelEdit')
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-message-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 6px 6px 12px;
    margin-bottom: 4px;
    border-radius: 8px;
    border: 2px solid $primary-mid;
    
    background: $white;

    &__title {
        @include font-h5;
        margin: 0;
    }

    &__icon {
        cursor: pointer;
    }
}
</style>