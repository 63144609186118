<template>
    <div :class="[
        'widget mobile-app', 
        { 'mobile-app--expand' : show }
    ]">
        <span @click="closeMobileApp" class="mobile-app__close"></span>

        <p class="mobile-app__title">{{ $t('dashboard-widget-mobile-app-title') }}</p>

        <div class="mobile-app__btns">
            <a target="_blank" :href="env.VUE_APP_APPLE_STORE_APP_URL" class="mobile-app__btn mobile-app__btn--apple"></a>
            <a target="_blank" :href="env.VUE_APP_GOOGLE_PLAY_APP_URL" class="mobile-app__btn mobile-app__btn--google"></a>
        </div>
        <div class="mobile-app__image"></div>
    </div>
</template>

<script>
import { setCookie, getCookie } from '@/functions/cookies'

export default {
    name: 'MobileAppAdv',
    data() {
        return {
            show: false,
            env: process.env
        }
    },
    created() {
        if(!getCookie('hideMobileAppWidget')) {
            setTimeout(() => this.show = true, 1000);
        } else this.$emit('hide');
    },
    methods: {
        closeMobileApp() {
            setCookie('hideMobileAppWidget', true, 24 * 7);
            this.show = false;
            this.$emit('hide');
        }
    }
}
</script>

<style lang="scss" scoped>
$mobile: 768px;

.widget.mobile-app {
    overflow: hidden;
    max-height: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 0;
    height: 220px;
    position: relative;
    background-image: url('~@/assets/img/mobileApp/gradient.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    opacity: 0;
    transition: all 1s;
}

.widget.mobile-app.mobile-app--expand {
    max-height: 300px;
    opacity: 1;
    padding-top: 24px;
    margin-bottom: 24px;
}

.mobile-app__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('~@/assets/img/notify/close.svg');
    cursor: pointer;
}

.mobile-app__title {
    padding-right: 24px;
    padding-left: 24px;
    font-size: 17px;
    line-height: 26px;
    color: $gray-900;
    font-weight: 600;
    letter-spacing: -0.4px;
    margin-bottom: 11px;
}

.mobile-app__btns {
    display: flex;
    gap: 16px;
    padding-left: 24px;
}

.mobile-app__btn {
    display: block;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.mobile-app__btn--google {
    background-image: url('~@/assets/img/mobileApp/google-play.svg');
    width: 135px;
}
.mobile-app__btn--apple {
    background-image: url('~@/assets/img/mobileApp/apple-store.svg');
    width: 120px;
}

.mobile-app__image {
    background-image: url('~@/assets/img/mobileApp/mobile-app.webp');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1;
    width: 100%;
    left: 0;
    bottom: 0;
}

@media(min-width: $mobile) {
    .widget.mobile-app {
        height: 270px;
    }
}
</style>