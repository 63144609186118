import gql from 'graphql-tag'

export const navData = gql`
    {
        currentUser {
            unreadMessagesCount
        }
        elearningCourseLastLesson {
            materialUuid
        }
    }
`