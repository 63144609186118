<template>
    <div class="grammar">
        <div class="grammar__header">
            <ToolsPill lang="en"/>
            <Button
                class="primary"
                :text="$t('tools.grammar.check')"
                @click.native="$store.dispatch('tools/grammarCheck')"
                :disabled="isGrammarCheckDisabled"
                :loading="grammar.loading"
            />
        </div>
        <div 
            :class="[
                'grammar__content',
                { 'grammar__content--active' : grammar.active }
            ]"
        >
            <GrammarInput ref="input" />
            <ToolClear 
                v-if="grammar.text.length && !grammar.loading"
                class="clear-btn--grammar" 
                @click.native="$refs.input.clearInput()" 
            />
            <div class="grammar__footer">
                <span :class="['counter', { 'counter--danger': isOverGrammarLimit }]">
                    {{
                        $i18n.t(
                            'tools.grammar.count-words',
                            { charactersCount: grammar.text.length, wordsCount: countWords() }
                        )
                    }}
                </span>
                <div v-if="grammar.showMistakes" class="grammar__mistakes">
                    <div class="grammar__mistakes-value">{{ grammar.matches.filter(el => el.match).length }}</div> {{ $t('tools.grammar.mistakes') }}
                </div>
                <div
                    class="copy-btn"
                    ref="copyWord"
                    @click="copyWord()"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import GrammarInput from '@/components/tools/grammar/GrammarInput';
import ToolsPill from '@/components/tools/ToolsPill';
import ToolClear from '@/components/tools/ToolClear';
import Button from '@/components/reusable/Button'
import iconAnimation from '@/mixins/tools/iconAnimation';
import engFlag from '@/assets/img/flags/eng.png';

export default {
    name: 'Grammar',
    components: {
        GrammarInput,
        ToolsPill,
        ToolClear,
        Button
    },
    mixins: [iconAnimation],
    data() {
        return {
            engFlag
        }
    },
    computed: {
        ...mapGetters('tools', ['isOverGrammarLimit', 'isGrammarCheckDisabled']),
        ...mapState('tools', ['grammar'])
    },
    methods: {
        copyWord() {            
            navigator.clipboard.writeText(this.grammar.text.replace(/\n/g, ' '));
            this.handleIconAnimation(this.$refs.copyWord, 'copy-btn--shake', 750);

            this.$gtag({
                category: 'tool',
                action: 'copy',
                label: 'grammar'
            })
        },
        countWords() {
            const arr = this.grammar.text.split(/ |\n/);

            return arr.filter(word => word !== '').length;
        }
    }
}
</script>

<style lang="scss" scoped>
.grammar {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1.5px solid transparent;

        &--active {
            border: 1.5px solid $primary;
            border-radius: 0px 0px 10px 10px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        position: relative;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        padding: 8px 16px;
        height: 48px;

        .button {
            margin-left: auto;
            z-index: 1;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0px 8px 12px 24px;
        position: sticky;
        bottom: 0;
        background: $white;
        border-radius: 10px;

        .copy-btn {
            margin-left: auto;
            @include toolsIcon('copy');
        }
        
        .counter {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $gray-600;

            &--danger {
                color: $danger;
            }
        }
    }

    &__mistakes {
        @include font-regular;
        color: $gray-600;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__mistakes-value {
        font-weight: 600;
        color: $gray-700;
        padding: 2px 4px;
        background: $gray-200;
        border-radius: 4px;
    }
}

.tools-wrapper {
    .grammar__content {
        overflow: hidden;
    }
}

.tools-page {
    .grammar__header {
        position: sticky;
        top: 64px;
        background: $white;
        z-index: 1;
    }
}

.clear-btn--grammar {
    position: absolute;
    right: 18px;
    top: 10px;
}
</style>