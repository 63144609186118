import timezoneDate from '@/functions/timezoneDate';
import capitalizeFirstLetter from '@/functions/capitalizeFirstLetter'

const setDayHeader = {
    methods: {
        setDayHeader(date) {
            const today = this.$moment().format(timezoneDate('YYYY-MM-DD'));
            const tomorrow = this.$moment().add(1,'days').format(timezoneDate('YYYY-MM-DD'));
            
            if (this.$moment(date).format(timezoneDate('YYYY-MM-DD')) === today) {
                return capitalizeFirstLetter(this.$i18n.t('calendar.today'));
            }

            if (this.$moment(date).format(timezoneDate('YYYY-MM-DD')) === tomorrow) { 
                return capitalizeFirstLetter(this.$i18n.t('calendar.tomorrow'));
            }

            return `${this.$moment(date).format(timezoneDate('ddd'))}.`;
        },
    }
}

export default setDayHeader;