<template>
    <draggable
        v-model="content"
        class="gaps-word-list__drop"
        type="single"
        @change="onInput"
        :group="1"
        :disabled="isTaskFinished"
    >
        <div 
            v-for="(el, index) in content"
            :class="[
                'gaps-word-list__gap',
                { 'correct': isCorrect },
                { 'incorrect': isIncorrect }
            ]"
            :key="index"
        >
            {{ el.text }}
        </div>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex';

export default {
    name: 'DropBlank',
    components: { draggable },
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            content: [],
        }
    },
    created() {
        if (this.isPreviewMode) this.setCorrectAnswersFromStart();
        const wordsObj = Object.entries(this.getCurrentTask.data.wordList).map(([uuid, text]) => ({ uuid, text }))

        this.$bus.$on('setAnswerForGap', (el) => {
            if(this.uuid !== el.dropArea) return;
            this.content = wordsObj.filter(element => element.uuid === el.uuid);
        })
    },
    computed: {
        ...mapGetters('learning', ['isTaskFinished', 'getBackendAnswer', 'isPreviewMode', 'getCurrentTask']),
        isCorrect() {
            return this.isTaskFinished && this.getBackendAnswer[this.uuid] === this.content[0].uuid;
        },
        isIncorrect() {
            return this.isTaskFinished && this.getBackendAnswer[this.uuid] !== this.content[0].uuid;
        }
    },
    methods: {
        onInput() {
            if (this.content.length > 1) this.$emit('pushToDragTiles', this.content.splice(1, this.content.length - 1));
            this.$emit('savePartialAnswer', { dropArea: this.uuid, uuid: this.content.length ? this.content[0].uuid : null });
        },
        setCorrectAnswersFromStart() {
            const wordsObj = Object.entries(this.getCurrentTask.data.wordList).map(([uuid, text]) => ({ uuid, text }))

            this.content = wordsObj.filter(el => el.uuid === this.getBackendAnswer[this.uuid]);
        }
    }
}
</script>

<style lang="scss" scoped>
.gaps-word-list__drop {
    width: 76px;
    overflow: hidden;
    background: $gray-100;
    border: 1px solid $gray-200;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    margin: 0 8px 0 8px;
    height: 34px;
    border-radius: 8px;

    &:has(.gaps-word-list__gap) {
        width: fit-content;
        border: 0;
        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    }
}
</style>
