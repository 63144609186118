import gql from 'graphql-tag'

export const getTeacher = gql`
    query($uuid: String!, $groupUuid: String, $speciality: AvailableTeachersSpecialityInput!) {
        getTeacher(uuid: $uuid, groupUuid: $groupUuid, speciality: $speciality) {
            uuid
            firstName
            avatar
            languagesISpeak {
                language {
                    name
                    shortName
                }
                languageLevel {
                    name
                    shortName
                }
            }
            languagesITeach {
                language {
                    name
                    shortName
                }
                languageLevel {
                    name
                    shortName
                }
            }
            certificates,
            details {
                headline
                aboutMe
                profileVideo
                profilePicture
                preferences
                tags
            }
            advanceNotice
        }
    }
`