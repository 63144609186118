<template>
    <div class="lesson skeleton">
        <div class="date">
            <div class="small dark"></div>
        </div>
        <div class="subject">
            <div class="small dark"></div>
        </div>
        <div class="lector">
            <div class="image dark"></div>
            <div class="small light"></div>
        </div>
        <div class="status">
            <div class="small light"></div>
        </div>
        <div class="presence">
            <div class="presence-marker dark"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LessonSkeleton'
}
</script>
