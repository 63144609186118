<template>
    <svg class="stat-circle" viewBox="0 0 20 20" width="80" height="80">
        <g transform="rotate(-90, 10, 10)">
            <circle class="bg" cx="10" cy="10" r="8"></circle>
            <circle class="progress" cx="10" cy="10" r="8" :data-percentage="percentage" :style="{ strokeDashoffset: strokeDashoffset, animationDuration: `${duration}s` }"></circle>
            <text x="50%" y="58%" transform="rotate(90, 10, 10)" :style="{ animationDuration: `${duration}s` }">{{ percentage }}%</text>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CircleProgress',
    props: ['percentage', 'duration'],
    computed: {
        strokeDashoffset() {
            return 51 - (((this.percentage === 100 ? 51 : 49.3) / 100) * this.percentage);
        }
    },
    mounted() {
        const progressCircle = this.$el.querySelector('.progress');
        progressCircle.style.setProperty('--stroke-dashoffset', this.strokeDashoffset);
    }
}
</script>

<style lang="scss" scoped>
.stat-circle {
    circle.bg {
        fill: none;
        stroke: $gray-200;
        stroke-width: 1.5;
    }

    circle.progress {
        fill: none;
        stroke: $secondary;
        stroke-width: 1.5;
        stroke-dasharray: 51 51;
        stroke-dashoffset: 51;
        stroke-linecap: round;
        animation: progress-animation var(--animation-duration, 500ms) ease-out forwards;
    }

    text {
        font-size: 4.5px;
        font-weight: 600;
        text-anchor: middle;
        fill: $gray-900;
        opacity: 0;
        animation: fade-in var(--animation-duration, 500ms) ease-out forwards;
    }

    @keyframes progress-animation {
        from {
            stroke-dashoffset: 51;
        }
        to {
            stroke-dashoffset: var(--stroke-dashoffset);
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
</style>