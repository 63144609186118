<template>
    <div class="skeleton-calendar">
        <div class="skeleton-calendar-box skeleton">
            <div></div>
            <div class="skeleton-calendar__days">
                <div class="p-10">
                    <div class="xsmall dark"></div>
                    <div class="xsmall dark"></div>
                </div>
                <div class="p-10">
                    <div class="xsmall dark"></div>
                    <div class="xsmall dark"></div>
                </div>
                <div class="p-10">
                    <div class="xsmall dark"></div>
                    <div class="xsmall dark"></div>
                </div>
                <div class="p-10">
                    <div class="xsmall dark"></div>
                    <div class="xsmall dark"></div>
                </div>
                <div class="p-10 to-hide">
                    <div class="xsmall dark"></div>
                    <div class="xsmall dark"></div>
                </div>
            </div>
            <div></div>
        </div>

        <div class="skeleton-calendar-box skeleton">
            <div class="arrow-area"></div>
            <div class="skeleton-calendar__days">
                <div class="p-10">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <template v-if="!isDateFilter">
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                    </template>
                </div>
                <div class="p-10">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <template v-if="!isDateFilter">
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                    </template>
                </div>
                <div class="p-10">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <template v-if="!isDateFilter">
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                    </template>
                </div>
                <div class="p-10">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <template v-if="!isDateFilter">
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                    </template>
                </div>
                <div class="p-10 to-hide">
                    <div class="small dark"></div>
                    <div class="small dark"></div>
                    <template v-if="!isDateFilter">
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                        <div class="small dark"></div>
                    </template>
                </div>
            </div>
            <div class="arrow-area"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MobileContentSkeleton',
    computed: {
        ...mapGetters('schedule', ['isDateFilter']),
    }
}
</script>

<style lang="scss" scoped>
.p-10 {
    padding: 8px 8px 0 8px;
}
.skeleton-calendar {
    display: grid;
    grid-template-rows: 60px calc(100% - 60px);
}

.skeleton {
    .xsmall {
        height: 16px;
        width: 50px;
        margin: 5px auto 0;
        border-radius: 100px;
    }

    .small {
        height: 24px;
        margin-bottom: 8px;
    }
    
    .big {
        height: 42px;
    }
}

.skeleton-calendar-box {
    display: grid;
    grid-template-areas: "arrowLeft . arrowRight";
    grid-template-columns: 50px 1fr 50px;
}

.to-hide {
    display: none;

    @media(min-width: 768px) {
        display: block;
    }

    @media(min-width: $tablet-switch-lector) {
        display: none;
    }
}

.skeleton-calendar__days {
    grid-template-rows: 60px calc(100% - 60px);
    flex: 0 0 100%;
    display: grid;
    height: 100%;
    grid-template-rows: 100%;
    overflow: hidden;
    order: 0;
    grid-template-areas: ". . . .";
    grid-template-columns: repeat(4, 1fr);

    @media(min-width: 768px) {
        grid-template-areas: ". . . . .";
        grid-template-columns: repeat(5, 1fr);
    }

    @media(min-width: $tablet-switch-lector) {
        grid-template-areas: ". . . .";
        grid-template-columns: repeat(4, 1fr);
    }
}

</style>