function getShortBookingUrl(url) {
    if (!url) return false;
    
    const urlParts = url.split('/');

    const studentUuid = urlParts[5];
    const groupUuid = urlParts[6];
    const userUuid = urlParts[7];
    
    return `booking/lesson/slots/${studentUuid}/${groupUuid}/${userUuid}`;
}

export {
    getShortBookingUrl as default
}