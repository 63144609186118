export default {
    data() {
        return {
            packagesGroups: null
        }
    },
    computed: {
        upsellBanners() {
            if (!this.packagesGroups.length) return;

            return this.packagesGroups.filter(item => {
                const isReady = Object.prototype.hasOwnProperty.call(item, 'isNewOfferLinkReady') ? item.isNewOfferLinkReady : true
                
                return item.newOfferLink && isReady
            })
        },
        upsellSingle() {
            return this.upsellBanners.length === 1
        }
    },
    methods: {
        createUpsellKey(item) {
            return `${item.type}-${item.language.shortName}`
        }
    }
}