import { mapGetters } from 'vuex';
import { schoolV2 } from '@/plugins/axios';
import accessStorage from '@/functions/accessStorage';
import { logoForPdf } from '@/mixins/generatePdf/logo';

export default {
    data() {
        return { 
            loading: false 
        }
    },
    computed: {
        ...mapGetters(['getCurrentUserLang', 'getLanguageLevel']),
        getQrLink() {
            return `${ process.env.VUE_APP_BRANDAPP_URL }${ this.getCurrentUserLang }?utm_medium=acquisition&utm_source=materials-printout&utm_campaign=${ this.getLangLevel() }`
        },
        getSizes() {
            return 'min-width: 32px !important; max-width: 32px !important; min-height: 32px !important; max-height: 32px !important;';
        }
    },
    methods: {
        getLangLevel() {
            if (!this.getLanguageLevel) return 'empty'
            return this.getLanguageLevel
                .replace('.', '-')
                .replace(' ', '-')
                .toLowerCase();
        },
        async generateLessonPdf(type, typeLabel, lessonUuid, materialUuid, subtitle, title, qr) {
            this.loading = true;

            const logo = `<div style='${ this.getSizes } position: absolute; bottom: 19px; left: 8px;'>${ logoForPdf }</div>`;
        
            try {
                const res = await schoolV2.post('print-pdf', 
                    {
                        materialUuid: materialUuid,
                        type: type,
                        lessonUuid: lessonUuid,
                        options: {
                            waitForSelector: '.pdf-ready',
                            width: 793,
                            height: 1122,
                            landscape: false,
                            viewport: {
                                width: 793,
                                height: 1122
                            },
                            margin: {
                                top: '80px',
                                right: '0px',
                                bottom: '80px',
                                left: '0px',
                            },
                            omitBackground: true,
                            headerTemplate: `<div style='width: 562px; height: 60px; border-bottom: 1px solid #E6E7E9; top: -9px; left: 16px; position: absolute;'>
                                <div style='font-size: 12px; line-height: 16px; font-wight: 400; position: absolute; top: 27px; color: #808793; z-index: 99999; font-family: 'Inter'; mix-blend-mode: normal;'>${ title } - ${ subtitle }</div>
                            </div>`,
                            footerTemplate: `
                                <div style='width: 562px; height: 64px; border-top: 1px solid #E6E7E9; position: absolute; left: 16px; bottom: 275px;'>
                                    ${ logo }
                                    <div style='font-size: 12px; line-height: 16px; color: #808793; font-wight: 400; position: absolute; left: 50%; bottom: 27px; transform: translateX(-50%)'><span class='pageNumber'></span>/<span class="totalPages"></span></div>
                                    ${ qr || '' }
                                </div>`,
                            format: 'A4'
                        }
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${accessStorage('authToken')}`,
                            'Content-Type': 'application/json'
                        }
                    }
                )

                const fileName = `${ subtitle.replace('?', '') } - ${ typeLabel.toLowerCase() } - Fluentbe.pdf`;
        
                this.saveBase64AsPDF(res.data.content, fileName);
            } catch {
                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } finally {
                this.loading = false;
            }
        },
        saveBase64AsPDF(base64Data, fileName) {
            const link = document.createElement('a');
            link.href = 'data:application/pdf;base64,' + base64Data;
            link.download = fileName;
            link.click();
        }
    }
}