<template>
    <div class="widget sl-last-lesson">
        <p class="sl-last-lesson__header">{{ $t('dashboard.sl-last-lesson.header') }}</p>
        <p class="sl-last-lesson__title">{{ data.lessonTitle }}</p>
        <div class="sl-last-lesson__info">
            <span v-if="languageLevel">{{ languageLevel }}</span>
            <span>{{ $t('dashboard.sl-last-lesson.module') }} {{ data.moduleIndex }}</span>
            <span>{{ $t('dashboard.sl-last-lesson.lesson') }} {{ data.lessonIndex }}</span>
        </div>
        <Button
            class="secondary"
            :text="$t('dashboard.sl-last-lesson.btn')"
            :icon="require('@/assets/img/arrow-blue.svg')"
            @click.native="openSmartLearning"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'

export default {
    name: 'SmartLearningLastLesson',
    props: ['data'],
    components: {
        Button
    },
    computed: {
        languageLevel() {
            return this.data.languageLevel?.shortName.split(' ')[0]
        }
    },
    methods: {
        openSmartLearning() {
            this.$router.push({
                name: 'elearning',
                params: {
                    uuid: this.data.materialUuid,
                    coursePlanUuid: this.data.coursePlanUuid
                }
            })
        }
    }
}
</script>

<style lang="scss">
.sl-last-lesson {
    &__header {
        @include font-h5-uc;
        color: $primary;
        margin-bottom: 4px;
    }

    &__title {
        @include font-h2;
        color: $gray-900;
        margin-bottom: 4px;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        gap: 4px 18px;

        span {
            @include font-h5-uc;
            color: $gray-600;
            position: relative;

            &:not(:last-child):before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $gray-100;
                position: absolute;
                right: -12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .btn {
        margin-top: 24px;
        flex-direction: row-reverse;
        gap: 4px;
    }
}
</style>