<template>
    <a :href="data.newOfferLink" target="_blank" class="upsell">
        <img class="upsell__icon" src="@/assets/img/upsell/icon.svg" alt="">
        <div>
            <div>
                <p class="upsell__info" v-html="$i18n.t(...infoCopy)"></p>
                <span class='upsell__type'> ({{$i18n.t(`general.type.${data.type}`)}})</span>
            </div>
            <p class="upsell__buy" v-html="$i18n.t(buyCopy)"></p>
        </div>
    </a>
</template>

<script>
export default {
    name: 'Upsell',
    props: ['data', 'single'],
    computed: {
        infoCopy() {
            const left = this.data.left
            
            if(left === 0) return ['upsell.info.lessons.0']
            else if(left === 1) return ['upsell.info.lessons.1']
            else if(left >= 2 && left <= 4) return ['upsell.info.lessons.2-4', {value: left}]

            return ['upsell.info.lessons.5+', {value: left}]
        },
        buyCopy() {
            return this.single ? 'upsell.buy.single' : 'upsell.buy.multiple'
        }
    }
}
</script>