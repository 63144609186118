const charactes = [
    ',',
    '.',
    '..',
    '...',
    ';',
    ':',
    '!',
    '?'
]

export default str => {
    const regexStr = `^[${ charactes.join('') }]+|[${ charactes.join('') }]+$`
    const regex = new RegExp(regexStr, 'g')

    return str?.replace(regex, '')
}