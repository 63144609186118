<template>
    <div class="select-grid">
        <div 
            :class="[
                'item',
                { 'item--active': activeItem === item.type }
            ]"
            v-for="item in data"
            :key="item.type"
            @click="setActiveItem(item.type)"
        >
            <div class="item__image">
                <img 
                    :src="item.img.file"
                    :width="item.img.size || item.img.width"
                    :height="item.img.size || item.img.height"
                    alt=""
                >
            </div>
            <p class="item__title">
                {{ item.label }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectGrid',
    props: ['data', 'value'],
    data() {
        return {
            activeItem: this.value
        }
    },
    methods: {
        setActiveItem(item) {
            this.activeItem = item
            this.$emit('input', this.activeItem)
        }
    }
}
</script>

<style lang="scss" scoped>
.select-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
    
    @media(min-width: 450px) {
        grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 650px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 22px;
        background: $white;
        border: 2px solid $gray-200;
        border-radius: 10px;
        transition: all .25s;
        cursor: pointer;

        &:hover {
            border-color: $primary-mid;
        }

        &--active {
            border-color: $primary !important;
            background: $primary-light;

            .item__title {
                color: $primary-dark;
            }
        }

        &__image {
            height: 88px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__title {
            @include font-h3;
        }
    }
}
</style>