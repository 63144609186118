<template>
    <div class="flashcards">
        <FlashcardsHeader />
        <div class="flashcards__wrapper">
            <Loading v-if="loading" :size="40" />
            <template v-else>
                <div class="flashcards__content">
                    <template v-if="exercises.length">
                        <transition :name="slideTransition" mode="out-in">
                            <p v-if="!isMobile" class="flashcards__exercise-title" :key="getExerciseTitle">{{ getExerciseTitle }}</p>
                        </transition>
                        <transition :name="slideTransition" tag="div" mode="out-in">
                            <component :key="getCurrentExercise.uuid" :is="getExerciseComponent" />
                        </transition>
                        
                    </template>
                    <transition v-else :name="slideTransition" tag="div" mode="out-in">
                        <FlashcardsSummary />
                    </transition>
                    <div class="debug">
                        <p><b>DEBUG:</b></p>
                        <p><b>Liczba ćwiczeń do wykonania:</b> {{ exercises.length }}</p>
                        <p v-for="(item, index) in exercises" :key="index"><b>{{ index + 1 }}:</b> {{ item }}</p>
                    </div>
                </div>
            </template>
            <FlashcardsFooter v-if="exercises.length" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FlashcardsHeader from '@/components/flashcards/FlashcardsHeader'
import FlashcardsExercise from '@/components/flashcards/FlashcardsExercise'
import FlashcardsSummary from '@/components/flashcards/FlashcardsSummary'
import FlashcardsChoice from '@/components/flashcards/FlashcardsChoice'
import FlashcardsInput from '@/components/flashcards/FlashcardsInput'
import FlashcardsOrder from '@/components/flashcards/FlashcardsOrder'
import FlashcardsFooter from '@/components/flashcards/FlashcardsFooter'
import Loading from '@/components/reusable/Loading'

export default {
    name: 'Flashcards',
    components: {
        FlashcardsHeader,
        FlashcardsExercise,
        FlashcardsSummary,
        FlashcardsChoice,
        FlashcardsInput,
        FlashcardsOrder,
        FlashcardsFooter,
        Loading
    },
    created() {
        this.$store.dispatch('flashcards/getData', { uuid: this.$route.params.uuid })
    },
    computed: {
        ...mapState('learningCoursePlan', ['transUi']),
        ...mapState('flashcards', ['loading', 'exercises', 'practiceMode', 'slideTransition']),
        ...mapGetters('flashcards', ['getCurrentExercise', 'isDefinitionExercise', 'isMobile']),
        getExerciseComponent() {
            const mapper = {
                CHOICE: FlashcardsChoice,
                INPUT: FlashcardsInput,
                ORDER: FlashcardsOrder,
                DEFINITION: FlashcardsExercise
            }

            return mapper[this.getCurrentExercise.type]
        },
        getExerciseTitle() {
            if (this.getCurrentExercise?.type === 'DEFINITION') return this.getCurrentExercise?.english.split(' ').length === 1
                ? this.$i18n.t('flashcards.exercise.title.definition.word', this.transUi)
                : this.$i18n.t('flashcards.exercise.title.definition.expression', this.transUi)

            return this.$i18n.t(`flashcards.exercise.title.${ this.getCurrentExercise.type.toLowerCase() }`, this.transUi)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/includes/_flashcards-slide-animations.scss";

.flashcards {
    margin-top: 79px;
    background: $gray-100;
    min-height: calc(var(--vh) - 79px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media(min-width: $mobile-flashcards) {
        min-height: calc(var(--vh) - 56px);
        margin-top: 56px;
    }

    &__wrapper {
        display: flex;
        padding-top: 16px;
        background: $white;
        flex: 1;
        position: relative;
        flex-direction: column;
        align-items: center;

        @media(min-width: $mobile-flashcards) {
            padding: 78px 78px 0 78px;
        }

        .loading {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        flex: 1;
        width: 100%;

        @media(min-width: $mobile-flashcards) {
            min-width: 488px;
            width: 564px;
        }
    }

    &__exercise-title {
        @include font-h2;
        color: $gray-900;
        margin-bottom: 24px;
        text-align: center;
    }
}

.debug {
    display: none;
    margin-top: 80px;
    text-align: center;

    p {
        margin: 8px 0;
    }
}
</style>