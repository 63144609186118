<template>
    <div v-if="!loading" class="form google-login-btn-wrapper">
        <div class="title-section">
            <template v-if="$route.params.confirmationType === 'account_confirmation'">
                <template v-if="step === 1">
                    <h2 class="title">{{ $t('set-password.choose-method') }}</h2>
                    <p class="dsc">{{ $t('set-password.title1') }}</p>
                    <ThirdPartyLoginButtons login class="third-party-login-btns"/>
                    <Button style="width: 100%;" :icon="require('@/assets/img/social/envelope-white.svg')" class="primary b2 b2--login" @click.native="step = 2" :text="$t('login-with-email')" />
                </template>
                <template v-if="step === 2">
                    <h2 class="title">{{ $t('login-with-email') }}</h2>
                    <p class="dsc">{{ $t('set-password.subtitle1') }}</p>
                </template>
            </template>
            <template v-else-if="$route.params.confirmationType === 'password_change_confirmation'">
                <h2 class="title">{{ $t('set-password.title2') }}</h2>
            </template>
        </div>
        <template v-if="$route.params.confirmationType === 'password_change_confirmation' || step === 2">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <ValidationProvider v-for="{name, rules, component, data} in form" :key="name" :rules="name == 'password2' ? `${rules}|check:${formData['password1']},repeatPassword` : rules" v-slot="{classes, errors}">
                        <component :is="component" v-model="formData[name]" :class="classes" :data="data"/>
                        <p class="invalid-feedback">{{errors[0]}}</p>
                    </ValidationProvider>
                    <Button class="primary b2 b2--login" :loading="submitLoading" :text="$t('set-password.form.submit')"/>
                </form>
            </ValidationObserver>
        </template>
        <p v-if="step === 2" class="back" @click="step = 1">{{ $t('set-password.back') }}</p>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import { setPassword as form } from '@/forms'
import { schoolV1 } from '@/plugins/axios'
import ThirdPartyLoginButtons from '@/components/ThirdPartyLoginButtons'

export default {
    name: 'SetPassword',
    beforeRouteEnter(to, from, next) {
        if(!to.params.confirmationType && !to.params.token) {
            next({name: 'login', params: {locale: to.params.locale}})
        } else next()
    },
    async beforeCreate() {
        const res = await schoolV1.post('token/validate', {
            token: this.$route.params.token,
            confirmationType: this.$route.params.confirmationType
        })

        if(res.data.isValid) this.loading = false
        else {
            this.$router.push({name: 'login', params: {invalidToken: true}})
            return
        }
    },
    components: { Button, ThirdPartyLoginButtons },
    data() {
        return {
            form,
            formData: {},
            step: 1,
            submitLoading: false,
            loading: true
        }
    },
    async mounted() {
        if (this.$route.params.confirmationType === 'account_confirmation') await this.setTimezone();
    },
    methods: {
        async submit() {
            this.submitLoading = true

            await this.setTimezone()

            try {
                const res = await schoolV1.post('account/password/update', {
                    token: this.$route.params.token,
                    confirmationType: this.$route.params.confirmationType,
                    password: this.formData.password1
                })
                
                const token = res.data.token
                localStorage.setItem('authToken', token)
                this.$router.push({name: 'dashboard'})
            } catch {
                this.$notify({
                    title: this.$i18n.t('notify.error.title'),
                    text: this.$i18n.t('notify.error.subtitle'),
                    data: {
                        type: 'error'
                    }
                })
            } finally {
                this.submitLoading = false
            }
        },
        async setTimezone() {
            const confirmationType = this.$route.params.confirmationType

            if(confirmationType === 'account_confirmation') {
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

                await schoolV1.post('student/timezone', {
                    confirmationType,
                    token: this.$route.params.token,
                    timezone
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.third-party-login-btns {
    margin: 24px 0;
}
</style>