import { mapState } from "vuex"
import { appModePath } from '@/functions/appModes'

export default {
    computed: {
        ...mapState('tools', ['dictionary'])
    },
    methods: {
        toolsRedirect(tool) {
            const word = tool === 'dictionary' && this.dictionary.word || null
            const baseURL = appModePath(`tools/${ tool }`)

            return word ? `${ baseURL }/${ word }` : baseURL
        }
    }
}