<template>
    <div class="skeleton">
        <div style="width: 75%" class="small dark"></div>
        <div style="width: 30%" class="small dark"></div>
    </div>
</template>

<script>
export default {
    name: "TranslatorSkeleton"
}
</script>