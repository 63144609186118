import { mapState } from "vuex"

export default {
    computed: {
        ...mapState('schedule', ['switchLector'])
    },
    methods: {
        isDateBetweenFilterRanges(startAt) {
            return this.switchLector.filters.date.value.find(filter => {
                if(
                    this.$moment(startAt).isBetween(
                        this.$moment(filter).subtract(30, 'minutes'), 
                        this.$moment(filter).add(30, 'minutes'),
                        null,
                        '[]'
                    )
                ) return true
            })
        }
    }
}