<template>
    <div :class="['dnd', getStatusClass]">
        <draggable
            v-model="dropTiles"
            class="dnd-drop-area"
            animation="150"
            :group="wordList?.join(' ') || word"
            :disabled="!!status"
            @end="dropEnd"
        >
            <div 
                :class="['dnd-drag-tile', getTileClass(tile, index)]"
                v-for="(tile, index) in dropTiles"
                :key="index"
                @click="dropClick(index)"
            >
                {{ tile }}
            </div>
        </draggable>

        <draggable
            v-model="dragTiles"
            class="dnd-drag-area"
            animation="150"
            :group="wordList?.join(' ') || word"
            :disabled="!!status"
            @end="dropEnd"
        >
            <div
                class="dnd-drag-tile"
                v-for="(tile, index) in dragTiles"
                :key="index"
                @click="dragClick(index)"
            >
                {{ tile }}
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import shuffleArray from '@/functions/shuffleArray'

export default {
    components: {
        draggable,
    },
    props: {
        word: String,
        wordList: Array,
        status: String,
        correctAnswer: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            drag: false,
            tiles: this.getTiles(this.word),
            dropTiles: [],
            dragTiles: shuffleArray(this.getTiles(this.word))
        };
    },
    created() {
        if(this.correctAnswer) this.setCorrectAnswersFromStart();
    },
    computed: {
        getStatusClass() {
            return this.status ? `dnd--${this.status}` : '';
        }
    },
    methods: {
        dropClick(index) {
            this.dragTiles.push(this.dropTiles[index])
            this.dropTiles.splice(index, 1)
            this.dropEnd()
        },
        dragClick(index) {
            this.dropTiles.push(this.dragTiles[index])
            this.dragTiles.splice(index, 1)
            this.dropEnd()
        },
        getTiles(word) {
            if(this.wordList) return [...this.wordList]

            return word.includes(" ") ? word.split(" ") : word.split("");
        },
        dropEnd() {
            this.drag = false;
            let result
            
            if(this.wordList) result = this.dropTiles
            else {
                result = this.word.includes(" ") 
                    ? this.dropTiles.join(" ")
                    : this.dropTiles.join("");
            }

            this.$emit('input', result);
        },
        getTileClass(tile, index) {
            if(this.status === 'incorrect' && this.tiles[index] === tile) return 'correct'
        },
        setCorrectAnswersFromStart() {
            this.dropTiles = this.correctAnswer;
            this.dragTiles = this.dragTiles.filter(el => !this.correctAnswer.includes(el));
        }
    },
};
</script>

<style lang="scss" scoped>
.dnd-drop-area {
    min-height: 64px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px;
    gap: 8px;
    border: 2px solid $gray-200;
    background-color: $gray-100;
    border-radius: 16px;
}

.dnd-drag-area {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    min-height: 48px;
    margin-top: 12px;
}

.dnd-drag-tile {
    padding: 8px 16px;
    font-size: 18px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid $gray-300;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    user-select: none;

    .dnd--pending & {
        color: $warning-dark;
        border-color: $warning-dark;
        background: $warning-light;
        pointer-events: none;
    }

    .dnd--incorrect & {
        color: $danger-dark;
        border-color: $danger-dark;
        background: $danger-light;
        pointer-events: none;
    }
    
    .dnd--correct &, &.correct {
        color: $secondary-dark;
        border-color: $secondary-dark;
        background: $secondary-light;
        pointer-events: none;
    }
}
</style>