import Vue from 'vue';
import api from '@/graphql/api.js'
import { i18n } from '@/i18n/i18n';
import { activeStudentGroups } from '@/graphql/queries/activeStudentGroups'
import { getAvailableTeachers } from '@/graphql/queries/getAvailableTeachers'
import { availableTeachersSpecialities } from '@/graphql/queries/availableTeachersSpecialities'
import { getTeacher } from '@/graphql/queries/getTeacher'
import { availableCoursePlans } from '@/graphql/queries/availableCoursePlans';
import { schoolV1 } from '@/plugins/axios'
import parseSlots, { checkIfSlotsExists } from '@/functions/parseSlots';
import getStudentTypeByAge from '@/functions/getStudentTypeByAge';
import getShortBookingUrl from '@/functions/getShortBookingUrl';
import getCourseParam from '@/functions/getCourseParam';
import mapFiltersValues from '@/functions/mapFiltersValues';
import resetLectorFilterStatus from '@/mixins/schedule/resetLectorFilterStatus';

import { SCHEDULE_MODE, SCHEDULE_LOCAL_STORAGE, SCHEDULE_SCREEN, SCHEDULE_STATE } from '@/constants/schedule';
import { STUDENT_TYPE } from '@/constants/studentTypes';

let switchViewTimeout = 0;

const switchLectorFilters = {
    advanceNotice: { value: null, active: false },
    date: { value: [] },
    nativeSpeaker: { value: null, active: false },
    languagesISpeak: { value: [], active: false },
    groupType: { value: null, active: false }
};

const scheduleStore = {
    state: () => ({
        init: false,
        loading: true,
        apiUrl: false,
        scheduleModal: false,
        forceScreen: null,
        context: null,

        mode: null,
        modeParams: null,

        coursesView: true,
        selectedCourse: {
            url: false,
            uuid: false,
            type: false,
            lang: false,
            packageUuid: null,
        },
        selectedNewLector: false,
        courses: false,

        days: [],
        filterDays: [],
        selectedSlot: {
            slot: {
                startAt: '', 
                stopAt: '', 
                booked: false
            },
            lector: {
                uuid: null,
                name: null,
                avatarUrl: null,
                headline: null,
                timezone: null
            }
        },

        documentTitle: '',

        error: {
            status: false,
            header: false,
            subheader: false,
        },

        btnPrimaryAction: null,
        btnPrimaryLabel: null,
        btnPrimaryDisabled: false,
        btnDefaultAction: null,
        btnDefaultLabel: null,
        btnDefaultDisabled: false,

        modalTitle: false,
        responseState: false,
        recurring: false,

        lectorsCalendars: {},
        slotsExists: false,
        lectorDetails: null,

        switchLector: {
            activeFlow: false,
            activeScreen: false,
            specialityData: false,
            selectedSpeciality: null,
            selectedSpecialityType: null,
            data: false,
            dataPaginateCursor: false,
            dataPaginateHasNextPage: true,
            requestsStack: [],
            requestsStackRun: false,
            switchLectorApiUrl: false,
            anchor: false,
            filters: JSON.parse(JSON.stringify(switchLectorFilters)),
            showGetMoreLectors: false,
            loadingMoreLectors: false
        },
        queue: [],
        triggers: {
            nav: { 
                loading: false
            },
            welcomeWidgetButton: {
                loading: false
            },
            dashboardLectorButton: {
                loading: false
            },
            noNextLessonWidgetButton: {
                loading: false
            },
            myLessonsPageButton: {
                loading: false
            },
            changeLectorWidgetButton: {
                loading: false
            },
            coursePlanButton: {
                loading: false
            },
            coursePlanNoPackage: {
                loading: false
            },
            coursePlanMobileBookButton: {
                loading: false
            },
            coursePlanNoPackageMobileBookBtn: {
                loading: false
            },
        },
        triggerButtonName: null
    }),
    actions: {
        async showScheduleModal({ state, dispatch, commit }, payload) {
            state.scheduleModal = true;
            state.forceScreen = payload?.forceScreen || null;
            await dispatch('initScheduleFlow');
            state.init = true;
            commit('resetTriggerButtons');
        },
        async showLectorDetailsModal({ state, dispatch, commit }, { lectorUuid, groupUuid, allowBooking }) {
            state.scheduleModal = true;
            state.lectorDetails = { directOpened: true, allowBooking }
            state.selectedCourse.uuid = groupUuid;
            await dispatch('initScheduleFlowFromLectorDetailsScreen', { lectorUuid });
            state.init = true;
            commit('resetTriggerButtons');
        },
        async getCoursesRequest({ commit }) {
            const { res } = await api(activeStudentGroups, { includePending: true });
            commit('setCourses', res.currentUser.student.activeStudentGroups);
            return;
        },

        async initScheduleFlowFromSpecialityScreen({ state, getters, commit, dispatch, rootState, rootGetters }) {
            let groupType;
            if (rootGetters.hasOnlyGroupCourses && !rootGetters.isBusiness) {
                // new B2C flow - avaliable create individual course 
                groupType = STUDENT_TYPE.INDIVIDUAL;
            } else if (rootGetters.isOnlyOnePackageGroup) {
                groupType = rootGetters.getOnlyOnePackageGroup.type;
            } else if (!rootGetters.getPackagesGroups.length) {
                groupType = getStudentTypeByAge(rootState.user.student.birthDate);
            } else if (state.switchLector.filters.groupType.value) {
                groupType = state.switchLector.filters.groupType.value;
            } else if (!state.courses.length) {
                groupType = getStudentTypeByAge(rootState.user.student.birthDate);
            }
            
            if ([0,1].includes(rootGetters.getPackagesGroups.length)) {
                state.selectedCourse.type = groupType;
                state.selectedCourse.lang = rootGetters.getPackagesGroups.length === 1 
                    ? rootGetters.getPackagesGroups[0].language?.shortName || 'eng'
                    : this.getters.getStudentLanguage;
                state.switchLector.filters.groupType = {
                    value: groupType,
                    active: true
                }

                await dispatch('runSwitchLectorFlow');
                commit("switchCoursesView", false);
            }

            return commit('setLoading', false);
        },

        async initScheduleFlowFromListScreen({ state, getters, commit, dispatch, rootState }) {
            if(!state.switchLector.filters.groupType?.value) {
                state.switchLector.filters.groupType = {
                    value: getStudentTypeByAge(rootState.user.student.birthDate),
                    active: true
                }
            }
            await dispatch('getProposedLectors')
            
            commit("switchCoursesView", false);
            return commit('setLoading', false);
        },

        async initScheduleFlowFromLectorDetailsScreen({ state, getters, commit, dispatch }, savedSchedule) {
            await dispatch('lectorDetailsRequest', { uuid: savedSchedule.lectorUuid });
            commit("switchCoursesView", false);
            return commit('setLoading', false)
        },

        async initScheduleFlow({ state, commit, dispatch, getters, rootGetters }) {
            commit('setLoading', true);

            const savedSchedule = JSON.parse(localStorage.getItem(SCHEDULE_LOCAL_STORAGE.SAVED_SCHEDULE))
            if (savedSchedule) {
                state.forceScreen = savedSchedule.lectorUuid ? SCHEDULE_SCREEN.LECTOR_DETAILS : SCHEDULE_SCREEN.LIST;
                state.mode = SCHEDULE_MODE.SAVED_SCHEDULE;
                state.switchLector.filters.groupType = savedSchedule.groupType;
                state.switchLector.selectedSpeciality = savedSchedule.selectedSpeciality;
                state.switchLector.selectedSpecialityType = savedSchedule.selectedSpecialityType;
            }

            await dispatch('getCoursesRequest');

            if (state.forceScreen === SCHEDULE_SCREEN.SPECIALITY) {
                return dispatch('initScheduleFlowFromSpecialityScreen');
            }

            if (state.forceScreen === SCHEDULE_SCREEN.LIST) {
                return dispatch('initScheduleFlowFromListScreen');
            }

            if (state.forceScreen === SCHEDULE_SCREEN.LECTOR_DETAILS) {
                return dispatch('initScheduleFlowFromLectorDetailsScreen', savedSchedule);
            }

            if (!state.courses.length && rootGetters.isOnlyOnePackageGroup) {
                return dispatch('initScheduleFlowFromSpecialityScreen');
            }

            if (getters.isGoDirectlyToCalendarView && !getters.isCancelLessonMode) {
                commit("setApiUrl", getShortBookingUrl(state.courses[0].instantBookingUrl));

                state.selectedCourse.uuid = state.courses[0].group.uuid;
                state.selectedCourse.type = state.courses[0].group.type;
                state.selectedCourse.lang = state.courses[0].studentPackageGroup.language.shortName;
                state.switchLector.filters.groupType = {
                    value: state.courses[0].group.type,
                    active: true
                }
            }
            
            if (getters.getScheduleAction !== 'none' || getters.isSwitchLectorFlowActive) commit('setLoading', false);

            return 
        },
        async loadDataFromStore({ state, commit }, payload) {
            const { selectedApiUrl } = payload;
            const { lectorsCalendars, queue } = state;
            
            if (checkIfSlotsExists(lectorsCalendars[selectedApiUrl])) state.slotsExists = true;
            else state.slotsExists = false;

            commit('setLectorData', lectorsCalendars[selectedApiUrl]);
            commit('setLoading', false)

            const [ prevprevScreen, prevScreen ] = queue.slice(-2);

            if (prevprevScreen === SCHEDULE_SCREEN.LIST && prevScreen === 'book-or-cancel') {
                return commit('setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LIST });
            } else if (prevprevScreen === 'calendar' && prevScreen === 'book-or-cancel') {
                if (state.switchLector.activeFlow) {
                    return  commit('setSwitchLectorFlow', { activeScreen: false });
                }
                return commit('setSwitchLectorFlow', { activeScreen: false, activeFlow: false });
            }
            return;
        },
        loadBookLessonData: ({ state, commit, dispatch }) => {
            const { studentUuid, groupUuid, userUuid } = state.modeParams;

            commit('setLoading', true);
            commit('setApiUrl', `booking/lesson/slots/${ studentUuid }/${ groupUuid }/${ userUuid }`);
            
            state.selectedCourse.uuid = groupUuid;
            state.selectedCourse.type = getCourseParam(state, groupUuid, 'type');
            state.selectedCourse.lang = getCourseParam(state, groupUuid, 'language');
            state.switchLector.filters.groupType = {
                value: getCourseParam(state, groupUuid, 'type'),
                active: true
            }

            dispatch('loadData');
        },
        loadCancelLessonData: async ({ state, commit }) => {
            commit('setLoading', true);
            
            commit('setModalTitle', i18n.t('calendar.modal-title.cancel-lesson'));
            commit('switchCoursesView', false);

            const { lessonUuid, studentUuid } = state.modeParams;
            let data;

            try {
                const response = await schoolV1.get(`booking/lesson/slots/${ studentUuid }/${ lessonUuid }/cancel/info`)
                data = response.data;
            } catch (error) {
                commit('setLoading', false);
                commit('setErrorMessage', { type: 'ERR_CANCEL_LESSON_FROM_LINK' });
            }

            const groupUuid = data.groupData.uuid;
            const userUuid = data.userData.uuid;
            
            state.selectedCourse.uuid = groupUuid;
            state.selectedCourse.type = getCourseParam(groupUuid, 'type');
            state.selectedCourse.lang = getCourseParam(groupUuid, 'language');

            commit('setLectorData', data);
            commit('selectSlot', { 
                slot: {
                    startAt: data.lessonData.startAt,
                    stopAt: data.lessonData.stopAt,
                    booked: true,
                    lesson: {
                        recurringSince: data.lessonData.recurringSince,
                        status: data.lessonData.status,
                        uuid: data.lessonData.uuid
                    }
                }
            });
            commit('setApiUrl', `booking/lesson/slots/${ studentUuid }/${ groupUuid }/${ userUuid }`);
            commit('setLoading', false);
        },
        loadData: async ({ state, commit, dispatch }) => {
            commit('setLoading', true);
            
            const { 
                apiUrl, 
                lectorsCalendars,
                switchLector: { switchLectorApiUrl },
            } = state;

            const selectedApiUrl = switchLectorApiUrl || apiUrl

            if (lectorsCalendars[selectedApiUrl]) {
                return dispatch('loadDataFromStore', { selectedApiUrl });
            }

            try {
                const res = await schoolV1.get(selectedApiUrl)

                if (checkIfSlotsExists(res.data)) state.slotsExists = true;
                else state.slotsExists = false;

                commit('setLectorData', res.data);
                Vue.set(state.lectorsCalendars, selectedApiUrl, res.data);

                if (state.switchLector.activeFlow) {
                    commit('setSwitchLectorFlow', { activeScreen: false });
                }
                commit('setLoading', false);
            } catch (error) {
                commit('setLoading', false);
                commit('setErrorMessage', { type: 'ERR_LOAD_CALENDAR' });
            }
        },
        async submitLesson({ state, getters, commit, dispatch }) {
            const selectedApiUrl = state.switchLector.switchLectorApiUrl || state.apiUrl;

            commit('setLoading', true);
            commit('setSchedulePrimaryButton', { btnPrimaryAction: null, btnPrimaryLabel: null });

            let url = selectedApiUrl;
            const data = { ...state.selectedSlot };

            if (getters.ifCreateNewGroup) {
                data.specialities = [{
                    type: state.switchLector.selectedSpeciality,
                    details: state.switchLector.selectedSpecialityType || null
                }]

                data.packageUuid = state.selectedCourse.packageUuid;

                url += `/new-teacher/${ state.switchLector.filters.groupType.value || STUDENT_TYPE.INDIVIDUAL }`;
            }

            try {
                await schoolV1({
                    url,
                    data: JSON.stringify(data),
                    method: 'post'
                })

                if(getters.ifCreateNewGroup) this.dispatch('getBookingInfo', { includePending: true });

                await dispatch('onSuccess', 'booked');
                await dispatch('refreshLectorCalendarInMemory', selectedApiUrl);
                await dispatch('getCoursesRequest');

                this._vm.$gtag(getters.ifCreateNewGroup 
                    ? { category: 'btnClick', action: 'new_teacher_lesson_booked' }
                    : { category: 'lesson', action: 'scheduled' }
                )
            } catch (error) { 
                console.log('ERROR');
                commit('setSchedulePrimaryButtonDisabled', false);
                commit('setLoading', false);

                const eMessage = error.response?.data?.message;
                if (eMessage) {
                    commit('setErrorMessage', { type: eMessage })
                    dispatch('handleErrorRedirectionTimeout');
                } else {
                    commit('setErrorMessage', { type: 'DEFAULT_ERROR' });
                }
            }
        },
        async cancelLesson({ state, commit, dispatch }) {
            const selectedApiUrl = state.switchLector.switchLectorApiUrl || state.apiUrl

            commit('setLoading', true);
            commit('setSchedulePrimaryButton', { btnPrimaryAction: null, btnPrimaryLabel: null });

            try {
                await schoolV1({
                    url: selectedApiUrl + '/cancel',
                    data: JSON.stringify(state.selectedSlot),
                    method: 'post'
                })

                await dispatch('onSuccess', 'cancel')
                await dispatch('refreshLectorCalendarInMemory', selectedApiUrl);
                await dispatch('getCoursesRequest');
                
                this._vm.$gtag({ category: 'lesson', action: 'canceled' });
            } catch (error) { 
                console.log('ERROR');
                commit('setSchedulePrimaryButtonDisabled', false);
                commit('setLoading', false);
                
                const eMessage = error.response?.data?.message;
                if (eMessage) {
                    commit('setErrorMessage', { type: eMessage })
                    dispatch('handleErrorRedirectionTimeout');
                } else {
                    commit('setErrorMessage', { type: 'DEFAULT_ERROR' });
                }
            }
        },
        async onSuccess({ commit }, type) {
            commit('setLoading', false);
            commit('setResponseState', `${ type }-success`);
            this._vm.$bus.$emit('updateDashboard');
            this._vm.$bus.$emit('updateLessons');
            this._vm.$bus.$emit('updateLesson');
            this._vm.$bus.$emit('updateMessages');
            const { res } = await api(availableCoursePlans);
            this.commit("coursePlan/setAvailableCoursePlans", res);
        },
        getProposedLectors: async ({ state, commit, getters }) => {
            const { switchLector, selectedCourse } = state;
            const filters = mapFiltersValues(state.switchLector.filters);

            commit('setLoading', true);

            const { res } = await api(getAvailableTeachers, {
                filters: {
                    groupUuid: selectedCourse.uuid || null, 
                    languagesITeach: [selectedCourse.lang || 'eng'],
                    speciality: { 
                        type: switchLector.selectedSpeciality || 'general', 
                        details: switchLector.selectedSpecialityType || null 
                    },
                    ...filters
                },
                first: 10 
            });
            commit('setLoading', false);

            if (res) {
                const edges = res.getAvailableTeachers?.edges
                resetLectorFilterStatus.methods.resetLectorFilterStatus(edges)

                switchLector.data = edges;
                switchLector.dataPaginateCursor = res.getAvailableTeachers?.pageInfo.endCursor;
                switchLector.dataPaginateHasNextPage = res.getAvailableTeachers?.pageInfo.dataPaginateHasNextPage;

                switchLector.data.length || getters.isFilterActive
                    ? commit('setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LIST })
                    : commit('setErrorMessage', { type: 'ERR_FRONT_EMPTY_SWITCH_LECTOR_LIST' });
            }
        },
        showCalendar: async ({ state, commit, dispatch }) => {
            clearTimeout(switchViewTimeout);

            if (state.responseState === SCHEDULE_STATE.BOOK_SUCCESS && state.switchLector.activeFlow) {
                commit('setInitialState');
                commit('hideScheduleModal');
                return;
            }

            await dispatch('loadData');

            commit('setErrorMessage', false);
            commit('setInitialState');
            commit("setResponseState", false);
        },
        handleErrorRedirectionTimeout: ({ commit, dispatch }) => {
            clearTimeout(switchViewTimeout);

            switchViewTimeout = setTimeout(() => {
                dispatch('showCalendar');
                commit('setErrorMessage', false)
            }, 5000);
        },
        getMoreProposedLectors: async ({ state }, payload) => {
            if (!state.switchLector.dataPaginateCursor) return;

            const { switchLector, selectedCourse } = state;
            const filters = mapFiltersValues(switchLector.filters);

            switchLector.loadingMoreLectors = true
            switchLector.showGetMoreLectors = false
            
            const { res } = await api(getAvailableTeachers, {
                filters: {
                    groupUuid: selectedCourse.uuid || null, 
                    languagesITeach: [state.selectedCourse.lang || 'eng'],
                    speciality: {
                        type: switchLector.selectedSpeciality || 'general', 
                        details: switchLector.selectedSpecialityType || null
                    },
                    ...filters
                },
                first: payload?.first || 5, 
                after: state.switchLector.dataPaginateCursor 
            })

            state.switchLector.loadingMoreLectors = false

            if (res) {
                const edges = res.getAvailableTeachers?.edges
                resetLectorFilterStatus.methods.resetLectorFilterStatus(edges)

                state.switchLector.data = [
                    ...(state.switchLector.data || []), 
                    ...edges
                ];
                state.switchLector.dataPaginateCursor = res.getAvailableTeachers?.pageInfo.endCursor;
                state.switchLector.dataPaginateHasNextPage = res.getAvailableTeachers?.pageInfo.dataPaginateHasNextPage;
            }
        },
        async runSwitchLectorFlow({ state }) {
            const { selectedCourse } = state;

            if (selectedCourse.lang === 'eng' || !selectedCourse.lang) {
                await this.dispatch('schedule/getSpecialityDataRequest');
            } else {
                await this.dispatch('schedule/getProposedLectors');
            }

            this._vm.$gtag({
                category: 'btnClick',
                action: 'new_teacher'
            })
        },

        reloadLectorsList() {
            this.dispatch('schedule/getProposedLectors');
        },

        async getSpecialityDataRequest({ state, commit }) {
            if (state.switchLector.specialityData) {
                return commit('setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.SPECIALITY });
            }
            const { res } = await api(availableTeachersSpecialities)

            if (res) {
                state.switchLector.specialityData = res.availableTeachersSpecialities;
                commit('setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.SPECIALITY });
            }
        },
        async lectorCalendarRequest({ state }, url) {
            try {
                const res = await schoolV1.get(url)
                
                Vue.set(state.lectorsCalendars, url, res.data);
            } catch (error) {
                console.log('errrr');
            }
        },

        async lectorDetailsRequest({ state, commit }, payload) {
            const { uuid } = payload;
            const { switchLector, selectedCourse } = state;
            
            commit('setLoading', true);
            const { res } = await api(getTeacher, { uuid, groupUuid: selectedCourse.uuid || null, speciality: {
                type: switchLector.selectedSpeciality || 'general', 
                details: switchLector.selectedSpecialityType || null
            } })
            commit('setLoading', false);

            if (res) {
                state.lectorDetails = state.lectorDetails 
                    ? { ...state.lectorDetails, ...res.getTeacher }
                    : res.getTeacher;
                commit('setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LECTOR_DETAILS });
            }
        },

        async getLectorCalendar({ state, dispatch }) {
            const lectorApiUrl = state.switchLector.requestsStack.pop();

            await dispatch('lectorCalendarRequest', lectorApiUrl);

            if (state.switchLector.requestsStack.length) {
                await dispatch('getLectorCalendar')
            } else {
                state.switchLector.requestsStackRun = false;
            }
        },
        async refreshLectorCalendarInMemory({ state, dispatch }, payload) {
            delete state.lectorsCalendars[payload];
            await dispatch('lectorCalendarRequest', payload);
        }
    },
    mutations: {
        hideScheduleModal(state) {
            state.apiUrl = false;
            state.mode = false;
            state.modeParams = null;
            state.init = false;
            state.forceScreen = null;
            state.context = null;
            state.courses = false;
            state.coursesView = true;
            state.lectorsCalendars = {},
            state.slotsExists = false;
            state.days = [];
            state.selectedCourse = {
                url: false, uuid: false, type: false, lang: false, packageUuid: null
            };
            state.selectedNewLector = false;
            state.queue = [];
            state.switchLector.activeScreen = false;
            state.switchLector.activeFlow = false;
            state.switchLector.switchLectorApiUrl = false;
            state.switchLector.dataPaginateCursor = false;
            state.switchLector.dataPaginateHasNextPage = false;
            state.switchLector.anchor = false;
            state.scheduleModal = false;
            state.lectorDetails = null;
            state.triggerButtonName = null;

            this.commit("schedule/resetFilters");

            localStorage.removeItem(SCHEDULE_LOCAL_STORAGE.SAVED_SCHEDULE);
            document.documentElement.classList.remove('overflow-y-hidden');
        },
        setInitialState(state) {
            this.commit("schedule/resetSlot");
            this.commit("schedule/setResponseState", false);
            this.commit("schedule/setRecurring", false);
            this.commit('schedule/setModalTitle', false);
            this.commit('schedule/setErrorMessage', false)

            state.switchLector.selectedSpeciality = null;
            state.switchLector.selectedSpecialityType = null;
        },
        setLoading(state, data) {
            if(data) state.loading = true;
            else setTimeout(() => {state.loading = false}, 200);
        },
        setApiUrl(state, data) {
            state.apiUrl = data;
            this.commit('schedule/setLoading', true);
            this.commit("schedule/switchCoursesView", false);
        },
        setCourses(state, data) {
            function filterByGroupUUID(allCourses, coursePlanCourses) {
                const uuids = new Set(coursePlanCourses.map(item => item.node.group.uuid));
                return allCourses.filter(item => uuids.has(item.group.uuid)).filter(item => item.instantBookingUrl);
            }

            if(state.context === 'coursePlan') {
                // filter courses by concrete course plan (course plan page)
                state.courses = filterByGroupUUID(data, this.getters['coursePlan/getStudentGroups'])
            }
            else state.courses = data.filter(item => item.instantBookingUrl); 
        },
        selectSlot(state, data) {
            state.selectedSlot = {...state.selectedSlot, ...data };
        },
        resetSlot(state) {
            state.selectedSlot = {...state.selectedSlot, slot: { startAt: '', stopAt: '', booked: false }};
        },
        setSchedulePrimaryButton(state, data) {
            const { btnPrimaryAction = null, btnPrimaryLabel = null, btnPrimaryDisabled = false } = data;

            state.btnPrimaryAction = btnPrimaryAction;
            state.btnPrimaryLabel = btnPrimaryLabel;
            state.btnPrimaryDisabled = btnPrimaryDisabled;
        },
        setScheduleDefaultButton(state, data) {
            const { btnDefaultAction = null, btnDefaultLabel = null, btnDefaultDisabled = false } = data;

            state.btnDefaultAction = btnDefaultAction;
            state.btnDefaultLabel = btnDefaultLabel;
            state.btnDefaultDisabled = btnDefaultDisabled;
        },
        setSchedulePrimaryButtonDisabled(state, data) {
            state.btnPrimaryDisabled = data;
        },
        setScheduleButtonsDisabled(state, data) {
            state.btnPrimaryDisabled = data;
            state.btnDefaultDisabled = data;
        },
        setResponseState(state, data) {
            state.responseState = data;
        },
        setRecurring(state, data) {
            if (!data) {
                delete state.selectedSlot.withRecurring;
                return;
            }
            state.selectedSlot.withRecurring = data;
        },
        setMode(state, data) {
            state.mode = data.name;

            if (data.name === SCHEDULE_MODE.CANCEL_LESSON || data.name === SCHEDULE_MODE.BOOK_LESSON) {
                state.modeParams = data?.params
                this.commit("schedule/switchCoursesView", false);
                this.dispatch("schedule/showScheduleModal");
            }
        },
        setModalTitle(state, data) {
            state.modalTitle = data;
        },
        switchCoursesView(state, data) {
            state.coursesView = data;
        },
        setLectorData(state, data) {
            const { slots, userData } = data;
            if (slots) state.days = parseSlots(slots);

            state.selectedSlot.lector = {
                uuid: userData.uuid,
                name: userData.fullName.split(' ')[0],
                avatarUrl: userData.avatarUrl,
                headline: userData.headline,
                timezone: userData.timezone || null
            }
        },
        setSelectedCourseInMemory(state, data) {
            state.selectedCourse.url = getShortBookingUrl(data?.instantBookingUrl);
            state.selectedCourse.uuid = data?.group?.uuid;
            state.selectedCourse.type = data?.group?.type;
            state.selectedCourse.lang = data?.studentPackageGroup?.language?.shortName;
            state.selectedCourse.packageUuid = null;
            state.selectedNewLector = false;
            state.switchLector.filters.groupType = {
                value: data?.group?.type,
                active: true
            }
        },
        setNewLectorSettingsInMemory(state, data) {
            const { langShortName, groupType, groupUuid, packageUuid } = data;
            state.selectedCourse.uuid = groupUuid;
            state.selectedCourse.type = groupType;
            state.selectedCourse.lang = langShortName;
            state.selectedCourse.packageUuid = packageUuid;
            state.selectedNewLector = true;
            state.switchLector.filters.groupType = {
                value: groupType,
                active: true
            }
        },
        setErrorMessage(state, data) {
            const { type, header, subheader } = data;
            state.error = {
                status: !!type || false,
                type: type || null,
                header: header || null,
                subheader: subheader || null
            }
        },
        setSwitchLectorFlow(state, { activeFlow = true, activeScreen, rememberScreen }) {
            state.switchLector.activeFlow = activeFlow;
            if (activeScreen || typeof activeScreen == 'boolean') state.switchLector.activeScreen = activeScreen;
            if (rememberScreen || typeof rememberScreen == 'boolean') state.switchLector.rememberScreen = rememberScreen;
        },
        async addLectorCalendarRequestToStack(state, payload) {
            const { lectorApiUrl } = payload;

            if (!state.switchLector.requestsStack.includes(lectorApiUrl)) state.switchLector.requestsStack.push(lectorApiUrl);

            if (!state.switchLector.requestsStackRun) {
                state.switchLector.requestsStackRun = true;
                await this.dispatch('schedule/getLectorCalendar')
            }
        },
        removeLectorCalendarRequestFromStack(state, payload) {
            const { lectorApiUrl } = payload;

            if (state.switchLector.requestsStack.includes(lectorApiUrl)) {
                state.switchLector.requestsStack.splice(state.switchLector.requestsStack.indexOf(lectorApiUrl), 1);
            }
        },
        setSwitchLectorApiUrl(state, payload = false) {
            state.switchLector.switchLectorApiUrl = payload
        },
        setSwitchLectorAnchor(state, payload) {
            state.switchLector.anchor = payload
        },
        setTriggerButtonName(state, data) {
            state.triggerButtonName = data
        },
        resetTriggerButtons(state) {
            for (const key in state.triggers) {
                state.triggers[key].loading = false;
            }
        },
        toggleFilterActive(state, filter) {
            state.switchLector.filters[filter].active = !state.switchLector.filters[filter].active;
        },
        resetFilters(state, keepGroup = false) {
            const temp = { ...switchLectorFilters };

            if (keepGroup) delete temp.groupType;

            state.switchLector.filters = {...state.switchLector.filters, ...JSON.parse(JSON.stringify(temp))};
        },
        setScheduleModalContext(state, data) {
            state.context = data;
        }
    },
    getters: {
        getScheduleAction: state => {
            const { startAt, stopAt, booked } = state.selectedSlot.slot;

            if (startAt && stopAt && booked) return 'cancel';
            if (startAt && stopAt && !booked) return 'book';
            return 'none';
        },
        isCancelLessonMode: state => state.mode === SCHEDULE_MODE.CANCEL_LESSON,
        isBookLessonMode: state => state.mode === SCHEDULE_MODE.BOOK_LESSON,
        isCalendarPreviewMode: state => state.mode === SCHEDULE_MODE.TEACHER_PREVIEW,
        isSavedSchedule: state => state.mode === SCHEDULE_MODE.SAVED_SCHEDULE,

        isRecurring: state => state.selectedSlot.slot.withRecurring,

        isNoCourse: state => !state.courses.length,

        isGoDirectlyToCalendarView: (state, getters, rootState) => {
            if (state.courses.length === 1) {
                if (rootState.packagesGroups.length === 1 && rootState.packagesGroups[0].type === state.courses[0].group.type) {
                    return true;
                }

                if (!rootState.packagesGroups.length) {
                    return true;
                }
            }
            return;
        },

        isSwitchLectorFlowActive: state => state.switchLector.activeFlow,

        isSwitchLectorScreenActive: state => state.switchLector.activeScreen,

        getSwitchLectorActiveScreen: state => state.switchLector.activeScreen,

        isCommingFromSwitchLectorFlow: state => state.switchLector.rememberScreen,

        ifShowSwitchLectorBar: (state, getters, rootState) => {
            return state.slotsExists
                && !getters.isSwitchLectorFlowActive 
                && !['shared', 'group'].includes(state.selectedCourse.type)
                && !getters.isCalendarPreviewMode
                && !state.loading
                && ( state.triggerButtonName === 'nav' || rootState.windowWidth > 992 )
        },
        ifCreateNewGroup: (state, getters) => {
            if (getters.isLectorDetailsDirectOpened) return false;
            return getters.isSwitchLectorFlowActive && state.switchLector.switchLectorApiUrl;
        },

        isDateFilter: state => state.switchLector.filters.date.value.length,

        isDateFilterScreen: state => state.switchLector.activeScreen === SCHEDULE_SCREEN.FILTERS_DATE,

        isAdvanceNoticeFilterActive: state => state.switchLector.filters.advanceNotice.active && state.switchLector.filters.advanceNotice.value,
        
        islanguagesISpeakFilterActive: state => state.switchLector.filters.languagesISpeak.active && state.switchLector.filters.languagesISpeak.value.length,

        isNativeSpeakerFilterActive: state => state.switchLector.filters.nativeSpeaker.active && state.switchLector.filters.nativeSpeaker.value,

        isFilterActive: (state, getters) => getters.isAdvanceNoticeFilterActive || getters.islanguagesISpeakFilterActive || getters.isNativeSpeakerFilterActive,

        isOnePersonCourseSelected: (state) => !['shared', 'group'].includes(state.switchLector.filters.groupType.value),

        isLectorDetailsDirectOpened: (state) => state.lectorDetails?.directOpened,

        allowBookingInCurrentGroupContext: (state) => state.lectorDetails?.allowBooking
    }
}

export default scheduleStore;