<template>
    <div :class="['widget phone', { 'error': validation }]">
        <div class="phone__header">
            <img src="@/assets/img/phone-gray.svg" alt="Icon">
            <div class="phone__label">
                <h3 class="phone__title">{{ $i18n.t('dashboard.phone.title') }}</h3>
                <p v-if="!isBusiness" class="phone__subtitle">{{ $i18n.t('dashboard.phone.subtitle') }}</p>
            </div>
        </div>
        <TelInput 
            :data="inputData"
            :validation="validation"
            v-model="phoneNumber"
            ref="telInput"
        />
        <Button 
            class="secondary"
            :text="$i18n.t('dashboard.phone.save')"
            :loading="loading"
            @click.native="submit()"
        />
    </div>
</template>

<script>
import TelInput from '@/components/reusable/TelInput'
import Button from '@/components/reusable/Button'
import { updateProfileContactData } from '@/graphql/mutations/updateProfileContactData'
import api from '@/graphql/api.js'
import { mapGetters } from 'vuex'

export default {
    name: 'Phone',
    props: ['placeholder'],
    components: {
        TelInput,
        Button
    },
    data() {
        return {
            loading: false,
            inputData: {
                placeholder: this.$i18n.t('general.text.placeholder')
            },
            phoneNumber: '',
            validation: null
        }
    },
    computed: {
        ...mapGetters(['userUuid', 'isBusiness'])
    },
    methods: {
        async submit() {
            this.loading = true

            const { res, error } = await api(updateProfileContactData, { 
                phone: this.phoneNumber, 
                userUuid: this.userUuid
             })

            if(res) {
                this.$store.state.user.phone = this.phoneNumber

                this.$notify({
                    title: this.$i18n.t('notify.saved'),
                    text: this.$i18n.t('notify.data-changed'),
                    data: {
                        type: 'success'
                    }
                })
            } else if(error) {
                const message = error.find(item => item.message === 'validation')?.extensions.validation.phone[0]?.message

                this.validation = message ? this.$i18n.t(message) : null

                this.$refs.telInput.focus()
            }

            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.phone {
    &__header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        gap: 16px;
    }

    &__title {
        @include font-h3;
        color: $gray-900;
        margin-bottom: 8px;
    }

    &__subtitle {
        @include font-regular-text;
        color: $gray-600;
    }
}
</style>
