<template>
    <div :class="['dnd', getStatusClass]">
        <draggable
            v-model="dropArea"
            :class="['dnd-drop-area', { 'dnd-drop-area--submitted': submitted || dontKnow }]"
            animation="150"
            :group="groupId"
            :disabled="!!status"
            @start="onDragStart"
            @end="onDragEnd"
            @change="handleDropChange"
        >
            <div v-for="(item, index) in dropArea" :key="'drop-' + index">
                <div
                    :class="['dnd-drag-tile', getTileClass(item.tile, index)]"
                    @click="dropClick(index)"
                >
                    {{ item.tile }}
                </div>
            </div>
        </draggable>
        <draggable
            v-if="!isPreviewMode"
            v-model="dragArea"
            class="dnd-drag-area"
            animation="150"
            :group="groupId"
            :move="checkMove"
            :disabled="!!status"
            @start="onDragStart"
            @end="onDragEnd"
            @change="handleDragChange"
        >
            <div v-for="(item, index) in dragArea" :key="'drag-' + index" 
                 :class="[{ 'dnd-placeholder-wrapper': !item.tile }, { 'pointer-none': !isDragging && !item.tile }]"
            >
                <div
                    v-if="item.tile"
                    :class="['dnd-drag-tile', getTileClass(item.tile, index)]"
                    @click="dragClick(index)"
                >
                    {{ item.tile }}
                </div>
                <div
                    v-else
                    :class="['dnd-placeholder', { 'dnd-placeholder--submitted': submitted || dontKnow }]"
                    :style="{ width: `${item.width}px` }"
                ></div>
            </div>
        </draggable>
    </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import shuffleArray from '@/functions/shuffleArray';

export default {
    components: { draggable },
    props: {
        word: String,
        wordList: Array,
        status: String,
        correctAnswer: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            drag: false,
            isDragging: false,
            tiles: this.getTiles(this.word),
            dropArea: [],
            dragArea: []
        };
    },
    created() {
        if (this.correctAnswer) this.setCorrectAnswersFromStart();
        this.dragArea = this.initializeDragArea(this.getTiles(this.word));
    },
    mounted() {
        this.setDragAreaHeight();
    },
    computed: {
        ...mapState('flashcards', ['submitted', 'dontKnow']),
        ...mapGetters('flashcards', ['getCorrectAnswer']),
        ...mapGetters('learning', ['isPreviewMode']),
        getStatusClass() {
            return this.status ? `dnd--${this.status}` : '';
        },
        groupId() {
            return this.wordList?.join(' ') || this.word;
        }
    },
    methods: {
        onDragStart() {
            this.isDragging = true;
        },
        onDragEnd() {
            this.isDragging = false;
            this.dropEnd();
        },
        setDragAreaHeight() {
            const firstTile = this.$el.querySelector('.dnd-drag-tile');
            if (firstTile) {
                const tileHeight = firstTile.offsetHeight;
                this.$el.style.setProperty('--dnd-drag-area-height', `${tileHeight}px`);
            }
        },
        dropClick(index) {
            const removedItem = this.dropArea[index];
            if (removedItem) {
                this.dropArea.splice(index, 1);
                this.addTileToDrag(removedItem.tile, removedItem.width, removedItem.originalIndex);
                this.dropEnd();
            }
        },
        dragClick(index) {
            const movedItem = this.dragArea[index];

            if (movedItem && movedItem.tile !== null) {
                this.dropArea.push(movedItem);
                this.dragArea.splice(index, 1, { tile: null, width: movedItem.width, originalIndex: movedItem.originalIndex });
                this.dropEnd();
            }
        },
        checkMove(evt) {
            const { relatedContext, draggedContext } = evt;
            return (
                relatedContext.element && draggedContext.element &&
                draggedContext.element.tile !== null && relatedContext.element.tile !== null
            );
        },
        getTiles(word) {
            if (this.wordList) return [...this.wordList];
            return word.includes(" ") ? word.split(" ") : word.split("");
        },
        dropEnd() {
            this.drag = false;
            let result;

            if (this.wordList) result = this.dropArea.map(item => item.tile);
            else {
                result = this.word.includes(" ") 
                    ? this.dropArea.map(item => item.tile).join(" ")
                    : this.dropArea.map(item => item.tile).join("");
            }

            this.$emit('input', result);
        },
        getTileClass(tile, index) {
            if (this.status === 'half-correct') {
                return this.getCorrectAnswer?.split(' ').length > 1
                    ?  this.getCorrectAnswer?.split(' ')[index] === tile ? 'correct' : 'half-correct'
                    :  this.getCorrectAnswer[index] === tile ? 'correct' : 'half-correct'
            } else if (this.status === 'incorrect') {
                return 'incorrect';
            }
            return '';
        },
        setCorrectAnswersFromStart() {
            this.dropArea = this.correctAnswer.map((tile, index) => ({
                tile,
                width: this.getTileWidth(tile),
                originalIndex: index
            }));
            this.dragArea = this.tiles.map((tile, index) => 
                this.correctAnswer.includes(tile) 
                ? { tile: null, width: this.getTileWidth(tile), originalIndex: index }
                : { tile, width: this.getTileWidth(tile), originalIndex: index }
            );
        },
        addTileToDrag(tile, width, originalIndex) {
            const index = this.dragArea.findIndex(item => item.originalIndex === originalIndex && item.tile === null);
            if (index !== -1) {
                this.dragArea.splice(index, 1, { tile, width, originalIndex });
            } else {
                this.dragArea.push({ tile, width, originalIndex });
            }
        },
        handleDropChange(evt) {
            if (evt.removed) {
                const removedItem = evt.removed.element;

                if (removedItem) {
                    this.addTileToDrag(removedItem.tile, removedItem.width, removedItem.originalIndex);
                    this.dropEnd();
                }
            }
        },
        handleDragChange(evt) {            
            if (evt.added) {
                const movedItem = evt.added.element;

                setTimeout(() => {
                    const index = this.dragArea.findIndex(item => item.originalIndex === movedItem.originalIndex);
                    if (index !== -1) this.dragArea.splice(index, 1);
                },0)
            } else if (evt.removed) {
                setTimeout(() => {
                    const movedItem = evt.removed.element;

                    if (movedItem && movedItem.tile !== null) {
                        this.dragArea.push({ tile: null, width: movedItem.width, originalIndex: movedItem.originalIndex });
                        this.dropEnd();
                    }
                },0)
            }
        },
        initializeDragArea(tiles) {
            return shuffleArray(tiles.map((tile, index) => ({
                tile: tile || null,
                width: this.getTileWidth(tile),
                originalIndex: index
            })));
        },
        getTileWidth(tile) {
            const div = document.createElement('div');
            div.style.visibility = 'hidden';
            div.style.position = 'absolute';
            div.style.width = 'auto';
            div.style.fontSize = '20px';
            div.style.lineHeight = '32px';
            div.style.fontWeight = '400';
            div.style.padding = '8px 14px';
            div.style.border = '1px solid #CCCFD4';
            div.style.borderRadius = '8px';
            div.innerHTML = tile;
            document.body.appendChild(div);
            const width = parseFloat(window.getComputedStyle(div).width);
            document.body.removeChild(div);
            return width;
        }
    },
};
</script>

<style lang="scss" scoped>
.dnd-drop-area {
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 8px;
    gap: 8px;
    border: 2px solid $primary;
    border-radius: 16px;
    box-shadow: 0px 4px 8px -4px rgba(70, 80, 94, 0.08);

    &--submitted {
        pointer-events: none;
        border-color: $gray-200;
    }
}

.dnd-drag-area {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    min-height: var(--dnd-drag-area-height, 50px);
    margin-top: 12px;
    padding: 0 8px !important;
}

.dnd-drag-tile, .dnd-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    cursor: pointer;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $gray-300;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.08);
    user-select: none;
    min-width: 64px;
    text-align: center;
    height: 50px;
    @include font-lead;

    .dnd--pending & {
        color: $warning-dark;
        border-color: $warning-dark;
        background: $warning-light;
        pointer-events: none;
    }

    .dnd--incorrect & {
        color: $danger-dark;
        border-color: $danger-dark;
        background: $danger-light;
        pointer-events: none;
    }
    
    .dnd--correct &, &.correct {
        color: $secondary-dark;
        border-color: $secondary-dark;
        background: $secondary-light;
        pointer-events: none;
    }
    
    &.half-correct {
        color: $orange;
        border-color: $orange;
        background: $orange-light;
        pointer-events: none;
    }
}

.dnd-placeholder {
    min-height: 50px;
    background-color: $gray-100 !important;
    border: 1px solid $gray-200 !important;

    &--submitted {
        cursor: default;
    }
}

.pointer-none {
    pointer-events: none;
}
</style>
