<template>
    <div class="wrapper wrapper--p0">
        <div class="login">
            <div class="login__left">
                <img src="@/assets/img/quote.svg" alt="Quote">
                <p class="quote">{{$i18n.t('quote1')}}</p>
                <p class="dash">-</p>
                <p class="author">Frank Smith</p>
            </div>
            <div class="login__right">
                <div class="navigation">
                    <span v-if="$route.meta.backToLogin">
                        {{$i18n.t('reset-password.back-to')}} <router-link :to="{name: 'login'}">{{$i18n.t('reset-password.login')}}</router-link>
                    </span>
                </div>
                <div class="content">
                    <img class="main-logo" src="@/assets/img/logo-full.svg" alt="Fluentbe">
                    <router-view />
                </div>
                <div class="navigation bottom">
                    <PrivacyFooter blue />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrivacyFooter from '@/components/layout/PrivacyFooter'

export default {
    name: 'LoginWrapper',
    components: {
        PrivacyFooter
    }
}
</script>