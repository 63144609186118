import moment from 'moment-timezone';
import { STUDENT_TYPE } from '@/constants/studentTypes';

export default function getStudentTypeByAge(birthDate) {
    if (!birthDate) return STUDENT_TYPE.INDIVIDUAL;

    const today = moment();
    const ageInYears = today.diff(moment(birthDate), 'years');

    // preschool children (under 7) === kid
    if (ageInYears < 10 ) return STUDENT_TYPE.KID; // 7-9
    if (ageInYears < 15 ) return STUDENT_TYPE.TEEN; // 10-14
    if (ageInYears < 21 ) return STUDENT_TYPE.YOUNG_ADULT; // 15-20

    return STUDENT_TYPE.INDIVIDUAL
}