import gql from 'graphql-tag'

export const getProgressTest = gql`
    query($uuid: String!) {
        getProgressTest(uuid: $uuid) {
            uuid
            language {
                name
                shortName
            }
            languageLevel {
                name
                shortName
            }
            status
            validTo
            testLength
            timeLeft
            link
            isRecent
            steps {
                name
                questions {
                    uuid
                    question
                    answers {
                        uuid
                        answer
                        isSelected
                        isCorrect
                    }
                }
            }
            result {
                score
                knowledge
                recommendations
                entries {
                    type
                    value
                    maxValue
                }
            }
        }
    }
`