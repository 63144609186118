<template>
    <div class="bg-gray-100">
        <div class="wrapper wrapper--small">
            <div class="messages-only">
                <Messages />
            </div>
        </div>
    </div>
</template>

<script>
import Messages from './Messages.vue'

export default {
    name: 'MessagesOnly',
    components: {
        Messages
    },
    beforeRouteLeave(to, from, next) {
        next(false)
    }
}
</script>

