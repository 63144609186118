<template>
    <div class="widget blog">
        <div class="blog__wrapper">
            <a
                :href="blogUrl"
                target="_blank"
            >
                <div class="blog__cover-bg">
                    <div
                        class="blog__cover"
                        :style="`background-image: url(${ blogCoverUrl })`"
                    ></div>
                </div>
            </a>
            <div class="blog__content">
                <span class="blog__date">{{ publishedDate }}</span>
                <a
                    :href="blogUrl"
                    target="_blank"
                >
                    <div class="blog__header">{{ title }}</div>
                </a>
                <div class="blog__pill">{{ category }}</div>
            </div>
        </div>
        <div class="sources">
            <a
                class="source source--insider"
                href="https://insider.fluentbe.com"
                target="_blank"
                @click="blogClickHandle"
            >
                <img
                    class="source-icon"
                    src="@/assets/img/insider-logo.svg"
                    alt=""
                    height="24"
                >
                <p class="source-name">{{ $i18n.t('blog-fluentbe.insider') }}</p>
            </a>
            <a
                class="source source--article"
                :href="blogUrl"
                target="_blank"
                @click="blogClickHandle"
            >
                <p class="source-name">{{ $i18n.t('blog-fluentbe.read-article') }}</p>
                <img
                    class="source-icon"
                    src="@/assets/img/arrow-dark.svg"
                    alt=""
                    height="20"
                >
            </a>
        </div>
    </div>
</template>

<script>
import timezoneDate from '@/functions/timezoneDate';

export default {
    name: 'Blog',
    props: ['data'],
    data() {
        return {
            blogUrl: '',
            category: '',
            publishedDate: '',
            title: '',
            blogCoverUrl: '',
            timezoneDate
        }
    },
    created() {
        const latestBlog = this.data.querySelectorAll('item')[0];

        this.blogUrl = latestBlog.querySelector('link').innerHTML;
    
        this.title = latestBlog.querySelector('title').innerHTML;

        this.category = latestBlog.querySelectorAll('category')[1].childNodes[0].nodeValue;

        const content = latestBlog.getElementsByTagName('content:encoded')[0].textContent;

        this.blogCoverUrl = content.slice(content.indexOf("src")).split('"')[1];
        
        this.publishedDate = this.$moment(latestBlog.querySelector('pubDate').innerHTML).format(timezoneDate('D MMMM'));
    },
    methods: {
        blogClickHandle() {
            this.$gtag({
                category: 'btnClick',
                label: 'Fluentbe Blog'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.widget.blog {
    color: $gray-900;
    position: relative;

    .sources {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 27px;
    }

    .source {
        height: 40px;
        display: flex;
        width: fit-content;
        padding: 8px 16px;
        gap: 8px;
        align-items: center;
        border-radius: 6px;
        background: $white;
        border: 1px solid $gray-200;
        transition: all .25s;
        flex: none;

        &:hover {
            background: $gray-100;
            border-color: $gray-300;
        }

        .source-name {
            @include font-medium-text;
            
            &:first-letter {
                text-transform: uppercase;
            }
        }

         &--insider {
            .source-name {
                font-family: 'DINNextRoundedLTPro-Medium' !important;
                font-size: 16px;
                line-height: 14px;
                color: $gray-700;
            }
        }

        &--article {
            .source-name {
                color: $gray-900;
            }

            .source-icon {
                transform: rotate(-90deg);
            }
        }
    }

    .blog__wrapper {
        display: flex;
        gap: 23px;
    }

    .blog__cover-bg {
        width: 162px;
        height: 98px;
        background: url('~@/assets/img/insider-cover-bg.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 500px) {
            width: 86px;
            width: 86px;
            background: none;
        }
    }

    .blog__cover {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;
        width: 153px;
        height: 86px;
        cursor: pointer;
        position: relative;
        border: 1px solid $gray-200;

        @media(max-width: 500px) {
            width: 86px;
            width: 86px;
        }
    }

    .blog__date {
        @include font-small-12;
        color: $gray-500;
    }

    .blog__header {
        @include line-break;
        @include font-h3;
        color: $gray-900;
        cursor: pointer;
    }

    .blog__pill {
        @include font-h6-uc;
        color: $white;
        background: $gray-500;
        margin-top: 6px;
        padding: 0 4px;
        border-radius: 4px;
        display: inline-block;
    }
}
</style>