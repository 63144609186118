<template>
    <div class="platform-messages">
        <MessagesSeparator 
            :text="$i18n.t('messages.platform-messages.start')"
            type="lesson"
            :simpleChat="simpleChat"
        />
        <div class="inner">
            <div class="header">
                <div class="lesson-info">
                    <p class="title">{{$i18n.t('messages.platform-messages.title')}}</p>
                    <div class="date">
                        <p>
                            <img :src="calendar" alt="Icon"> {{$moment(lesson.startAt).format(timezoneDate('D MMMM (ddd)'))}}
                        </p>
                        <p>
                            <img :src="clock" alt="Icon"> {{$moment(lesson.startAt).format(timezoneDate('HH:mm'))}} - {{$moment(lesson.stopAt).format(timezoneDate('HH:mm'))}}
                        </p>
                    </div>
                </div>
                <button class="toggle-messages" @click="toggleMessages">
                    {{$i18n.t(showMessages ? 'messages.platform-messages.hide' : 'messages.platform-messages.show')}}
                </button>
            </div>
            <template v-if="showMessages">
                <SingleMessage
                    v-for="(msg, index) in msgs"
                    :key="msg.uuid"
                    :data="msg"
                    :isActive="isActive"
                    isPlatformMessage
                    :class="getGroupedClass(index)"
                />
            </template>
        </div>
        <MessagesSeparator 
            :text="$i18n.t('messages.platform-messages.end')"
            type="lesson"
            :simpleChat="simpleChat"
        />
    </div>
</template>

<script>
import MessagesSeparator from './MessagesSeparator'
import SingleMessage from './SingleMessage'
import groupedMessages from '@/mixins/groupedMessages'
import calendar from '@/assets/img/chat/calendar.svg'
import clock  from '@/assets/img/chat/clock.svg'
import timezoneDate from '@/functions/timezoneDate'

export default {
    name: 'PlatformMessages',
    props: {
        msgs: Array,
        lesson: Object,
        isActive: Boolean,
        simpleChat: Boolean
    },
    components: {
        MessagesSeparator,
        SingleMessage
    },
    data() {
        return {
            showMessages: false,
            calendar,
            clock,
            timezoneDate
        }
    },
    mixins: [groupedMessages],
    methods: {
        toggleMessages() {
            this.showMessages = !this.showMessages
        }
    }
}
</script>

<style lang="scss" scoped>
.platform-messages {
    .inner {
        margin: 8px 0;
    }
    
    .header {
        background: $extended-warning;
        border-radius: 10px;
        border: 1px solid $warning;
        padding: 8px;

        .lesson-info {
            padding: 8px;

            .title {
                @include font-medium;
                color: $gray-900;
            }

            .date {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-top: 8px;

                p {
                    @include font-medium;
                    color: $warning-dark;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
        }

        .toggle-messages {
            background: $white;
            border: 1px solid $warning;
            border-radius: 8px;
            padding: 4px;
            @include font-regular;
            color: $gray-600;
            cursor: pointer;
            transition: all .25s;
            outline: none;
            width: 100%;
            margin-top: 5px;

            &:hover {
                color: $gray-900;
            }
        }
    }
}
</style>