<template>
    <label class="input mb-0">
        <input 
            :type="data.type ? data.type : 'text'" 
            :placeholder="data.placeholder" 
            v-model="value" 
            @input="onChange"
        >
    </label>
</template>

<script>
export default {
    name: 'SimpleInput',
    props: ['data'],
    data() {
        return {
            value: ''
        }
    },
    methods: {
        onChange() {
            this.$emit('input', this.value)
        }
    }
}
</script>
