<template>
    <div class="flashcards__choice">
        <FlashcardsExercise />
        <transition name="flashcards-activity-hide">
            <div v-if="showActivity" :class="['words', { 'words--disabled': submitted || dontKnow }]">
                <div 
                    v-for="word in words" 
                    :key="word" 
                    :class="['words__word', getClass(word)]"
                    @click="setAnswer(word)"
                >
                    {{ word }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import FlashcardsExercise from '@/components/flashcards/FlashcardsExercise'
import { checkSpecificBrowsers } from '@/helpers/browserSupport';
import { mapGetters, mapState } from 'vuex'
import shuffleArray from '@/functions/shuffleArray'

export default {
    name: 'FlashcardsChoice',
    components: {
        FlashcardsExercise
    },
    data() {
        return {
            words: []
        }
    },
    created() {
        this.words = this.getRandomWords(3)
    },
    computed: {
        ...mapState('flashcards', ['vocabulary', 'dontKnow', 'submitted', 'currentAnswer']),
        ...mapGetters('flashcards', ['getCurrentExercise', 'getCorrectAnswer', 'showActivity']),
        ...mapState(['isMobile']),
        isSafariBrowser() {
            return checkSpecificBrowsers(['safari']);
        },
    },
    methods: {
        getRandomWords(count) {
            const possibleWords = this.vocabulary
                .filter(el => el.english !== this.getCurrentExercise.english)
                .map(el => el.word)

            return shuffleArray([...possibleWords.slice(0, count), this.getCorrectAnswer])
        },
        setAnswer(value) {
            this.$store.commit('flashcards/setCurrentAnswer', value)
        },
        getClass(word) {
            let className = 'words__word--'

            if(this.dontKnow && word === this.getCorrectAnswer)  return className + 'pending'
            if(!this.submitted && word === this.currentAnswer)  return className + 'marked'
            if(this.submitted) {
                if(word === this.currentAnswer && word === this.getCorrectAnswer) return className + 'correct'
                if(word === this.currentAnswer && word !== this.getCorrectAnswer) return className + 'incorrect'
                if(word !== this.currentAnswer && word === this.getCorrectAnswer) return className + 'should-be-correct'
            }

            if(this.dontKnow || this.submitted) return className + 'disabled' 
        }
    }
}
</script>

<style lang="scss" scoped>
.flashcards__choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    flex: 1;
    width: 100%;
}

.words {
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: auto;
    padding-top: 12px;
    border-top: 1px solid $gray-100;
    padding: 12px 9px;
    width: 100%;

    @media (min-width: $mobile-flashcards) {
        padding: 12px 0;
        position: relative;
        bottom: auto;
        left: auto;
    }

    &--disabled {
        pointer-events: none;
    }

    @media(max-width: 300px) {
        grid-template-columns: 1fr;
    }
}

.words__word {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px solid $gray-300;
    border-radius: 8px;
    @include font-medium;
    color: $gray-900;
    text-align: center;
    box-shadow: 0px 2px 4px -1px rgba(45, 54, 66, 0.08);
    cursor: pointer;
    min-height: 60px;
    height: 100%;

    &--disabled {
        opacity: .25;
        box-shadow: 0;
    }

    &--correct {
        color: $secondary-dark;
        background: $secondary-light;
        border-color: $secondary-dark;
    }

    &--should-be-correct {
        color: $secondary-dark;
        border-color: $secondary-dark;
        box-shadow: 0;
    }

    &--incorrect {
        color: $danger-dark;
        background: $danger-light;
        border-color: $danger-dark;
    }

    &--pending {
        color: $warning-dark;
        background: $warning-light;
        border-color: $warning-dark;
    }

    &--marked {
        color: $primary;
        background: $primary-light;
        border-color: $primary-dark;
    }
}
</style>