import api from '@/graphql/api.js'
import { coursePlan } from '@/graphql/queries/coursePlan';
import router from '@/router';

const store = {
    state: {
        chosenUuid: null,
        availablePlans: [],
        materials: [],
        statistics: [],
        showBaner: false,
        isPresentationBased: true,
        loading: true,
        error: false,
        count: null,
        teachers: [],
        studentGroups: []
    },
    actions: {
        async initCourseProgram({ state, dispatch, commit }) {
            state.loading = true;
            const { res } = await api(coursePlan, { groupUuid: router.currentRoute.params.uuid });

            commit("setCoursePlanUuid", router.currentRoute.params.uuid)

            state.loading = false;
            
            if (!res || res.coursePlan === null || !res.coursePlan.materials.length) {
                state.error = true;

                return;
            }

            dispatch('setCoursePlanData', res.coursePlan);
            
            state.count = state.materials.length;
        },
        setCoursePlanData({ state }, data) {
            state.isPresentationBased = data.isPresentations;

            state.showBaner = !data.hasPackage;
            state.materials = data.materials;
            state.studentGroups = data.studentGroups.edges;
            state.teachers = [...new Map(data.materials
                .filter(el => el.lesson.teacher && el.lesson.teacher.uuid)
                .map(el => [el.lesson.teacher.uuid, el.lesson.teacher])).values()
            ];            
            state.statistics = data.statistics;
        }
    },
    mutations: {
        setAvailableCoursePlans: (state, data) => state.availablePlans = data.availableCoursePlans,
        setCoursePlanUuid: (state, data) => state.chosenUuid = data,
    },
    getters: {
        getCoursePlanMaterials: state => state.materials,
        getChosenCoursePlanUuid: state => state.chosenUuid,
        getChosenCoursePlan: (state, getters) => getters.getAvailableCoursePlans.find(item => item.groupUuid === getters.getChosenCoursePlanUuid),
        getAvailableCoursePlans: state => state.availablePlans,
        getTeachers: (state) => state.teachers,
        getStudentGroups: (state) => state.studentGroups,
        getBookingUrls: (state) => state.studentGroups
            .filter(el => el.node.instantBookingUrl)
            .map(el => el.node.instantBookingUrl),
    }
}

export default store