<template>
    <div class="summary-section">
        <div class="header">
            <img class="icon" :src="icon" alt="">
            <h3 class="title">{{ title }}</h3>
        </div>
        <div class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Detail',
    props: {
        icon: {
            required: true,
        },
        title: {
            required: true,
        },
        noPadding: {
            required: false,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.summary-section {
    margin-top: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    .header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        
        @media(min-width: $mobile) {
            margin-bottom: 20px;
        }

        .icon {
            margin-right: 12px;
        }

        .title {
            @include font-h3;
            color: $gray-900;
        }

    }

    .content {
        color: $gray-900;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media(max-width: $mobile) {
            padding-left: 0;
        }
    }
}
</style>
