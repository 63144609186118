<template>
    <div class="page-404">
        <h1 style="margin-bottom: 50px;">Error 404 - Page not found</h1>
        <Button @click.native="$router.push({name: 'dashboard'})" style="margin: auto;" class="primary" text="Back"/>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'

export default {
    name: 'Page404',
    components: {
        Button
    }
}
</script>

