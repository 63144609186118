<template>
    <div class="list">
        <div class="filters">
            <div v-for="{id, label} in data.filters" :class="['filter', id]" :key="id">{{label}}</div>
        </div>
        <div v-if="data.items.length" class="body">
            <div v-for="(item, index) in items" :key="index" class="item">
                <template v-if="data.type === 'profilePayments'">
                    <div class="left">
                        <div class="date">{{$moment(item.paidAt).format('DD MMMM')}}</div>
                        <div class="offer">{{item.offerName}}</div>
                        <div class="price">{{item.currencyCode}} {{item.amount}}</div>
                    </div>
                    <div class="invoice">
                        <a v-if="item.invoice" :href="item.invoice.url" target="_blank">
                            <img src="@/assets/img/download.svg" width="16" height="16" alt="">
                        </a>
                    </div>
                </template>
                <template v-if="data.type === 'profilePackages'">
                    <div class="type">{{$i18n.t(`general.lang.${item.language.shortName}`)}} - {{$i18n.t(`general.type.${item.type}`)}}</div>
                    <div class="points">+{{item.quantity}}</div>
                    <div class="date">{{$moment(item.createdAt).format('DD MMMM')}}</div>
                    <div class="remarks" :class="item.reason.name">{{$i18n.t(`account.packages.list.remarks.${item.reason.isMarketing ? 'promo' : 'purchase'}`)}}</div>
                </template>
            </div>
        </div>
        <ListPagination
            v-if="pages > 1"
            :pages="pages"
            :currentPage="currentPage"
            @changePage="changePage"
        />
        <div v-if="!data.items.length" class="empty-state">
            <p class="title">{{$i18n.t(`${data.type}.empty-state.title`)}}</p>
            <p class="subtitle">{{$i18n.t(`${data.type}.empty-state.subtitle`)}}</p>
        </div>
    </div>
</template>

<script>
import ListPagination from '@/components/reusable/list/ListPagination'
import arrow from '@/assets/img/list/arrow.svg'

export default {
    name: 'List',
    props: ['data'],
    components: {
        ListPagination
    },
    data() {
        return {
            perPage: this.data.perPage || 5,
            currentPage: 1,
            arrow
        }
    },
    computed: {
        items() {
            return this.data.items.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
        },
        pages() {
            return Math.ceil(this.data.items.length / this.perPage)
        }
    },
    methods: {
        changePage(value) {
            this.currentPage = value
        }
    }
}
</script>

