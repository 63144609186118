<template>
    <div :class="[
        'schedule-confirmation__box', 
        { 'schedule-confirmation__box--icon-on-top': iconTop}
    ]">
        <div v-if="displayHeader || displaySubheader" class="schedule-confirmation__text">
            {{ displayHeader }}
            <div>
                <strong v-if="displaySubheader">
                    {{ displaySubheader }}
                </strong>
            </div>
        </div>

        <img src="@/assets/img/notify/error.svg" class="schedule-confirmation__icon schedule-confirmation__icon--error" alt="">
    </div>
</template>

<script>
import { SCHEDULE_SCREEN } from '@/constants/schedule';

export default {
    name: 'ScheduleError',
    props: ['iconTop'],
    data() {
        return {
            header: null,
            subheader: null
        }
    },
    created() {
        this.setDetails();
        this.setErrorData();
    },
    computed: {
        displayHeader() {
            const { header } = this.$store.state.schedule.error;
            return header || this.header || null
        },
        displaySubheader() {
            const { subheader } = this.$store.state.schedule.error;
            return subheader || this.subheader || null
        }
    },
    methods: {
        setErrorData() {
            const { type } = this.$store.state.schedule.error;

            const errorTypes = {
                DEFAULT_ERROR: {
                    header: this.$i18n.t('calendar-confirmation.smth-wrong'),
                    subheader: this.$i18n.t('calendar-confirmation.problem-contact'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar-confirmation.try-again'),
                        action: this.resetErrorState
                    }
                },
                ERR_LOAD_CALENDAR: {
                    header: this.$i18n.t('calendar-confirmation.smth-wrong'),
                    subheader: this.$i18n.t('calendar-confirmation.problem-contact'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar-confirmation.try-again'),
                        action: 'backToCourses'
                    }
                },
                ERR_SLOT_NOT_AVAILABLE_ANYMORE: {
                    header: this.$i18n.t('calendar-confirmation.date-no-available'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar-confirmation.choose-different-date'),
                        action: 'showCalendar'
                    }
                },
                ERR_LESSON_ALREADY_CANCELLED: {
                    header: this.$i18n.t('calendar-confirmation.lesson-was-cancel'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar-confirmation.back-to-calendar'),
                        action: 'showCalendar'
                    }
                },
                ERR_LESSON_OVER_THE_LIMIT: {
                    header: this.$i18n.t('calendar-confirmation.paid-lessons-limit'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar-confirmation.back-to-calendar'),
                        action: 'showCalendar'
                    }
                },
                ERR_LESSON_OVER_THE_COMPANY_LIMIT: {
                    header: this.$i18n.t('calendar.error'),
                    subheader: this.$i18n.t('calendar.error.lesson-over-the-company-limit'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar.back'),
                        action: this.primaryButtonHandle
                    } 
                },
                ERR_INVALID_SCHEDULE: {
                    header: this.$i18n.t('calendar-confirmation.recurring-error'),
                    primaryBtn: {
                        label: this.$i18n.t('switch-lector.list.btn-default'),
                        action: 'showCalendar'
                    }
                },
                ERR_FRONT_EMPTY_SWITCH_LECTOR_LIST: {
                    header: this.$i18n.t('switch-lector.empty-list.header'),
                    primaryBtn: {
                        label: this.$i18n.t('switch-lector.list.btn-default'),
                        action: this.backToSpecialities
                    }
                },
                ERR_BOOKING_NOT_ALLOWED: {
                    header: this.$i18n.t('calendar.error'),
                    subheader: this.$i18n.t('calendar.error.booking-not-allowed'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar.back'),
                        action: this.primaryButtonHandle
                    }
                },
                ERR_CANCEL_LESSON_FROM_LINK: {
                    header: this.$i18n.t('calendar.error'),
                    subheader: this.$i18n.t('calendar.error.cancel-lesson'),
                    primaryBtn: {
                        label: this.$i18n.t('calendar.back'),
                        action: this.primaryButtonHandle
                    }
                }
            }

            const { header, subheader, primaryBtn: { label: btnPrimaryLabel, action: btnPrimaryAction }} = errorTypes[type];

            this.header = header;
            this.subheader = subheader;
            this.$store.commit('schedule/setSchedulePrimaryButton', { btnPrimaryAction, btnPrimaryLabel });
            this.$store.commit('schedule/setScheduleDefaultButton', { btnDefaultAction: false, btnDefaultLabel: false });
        },
        setDetails() {
            const { type } = this.$store.state.schedule.error;

            if (type === 'empty-switch-lector-list') {
                this.iconTopp = true
            }
        },
        primaryButtonHandle() {
            const { state, commit } = this.$store;
            commit('schedule/setSwitchLectorApiUrl', false);
            commit('schedule/setErrorMessage', false)

            if (state.schedule.switchLector.activeFlow && state.schedule.lectorDetails) {
                commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LECTOR_DETAILS });
                return;
            }

            if (state.schedule.switchLector.activeFlow) {
                commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.LIST });
                return;
            }

            commit('schedule/setInitialState');
            commit("schedule/setResponseState", false)
        },
        backToSpecialities() {
            this.$store.commit('schedule/setSwitchLectorFlow', { activeScreen: SCHEDULE_SCREEN.SPECIALITY })
            this.$store.commit('schedule/setErrorMessage', false)
        },
        resetErrorState() {
            this.$store.commit('schedule/setErrorMessage', false)
        }
    }
}
</script>

<style scoped lang="scss">
    .schedule-confirmation__box--icon-on-top {
        display: flex;
        flex-direction: column;
        align-items: center;

        .schedule-confirmation__icon {
            display: flex;
            order: -1;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
</style>