<template>
    <div v-if="!responseState" :key="1">
        <ScheduleSummary :action="action" v-if="action === 'book'">
            <template #header>
                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.lesson')}}</span>
                    <span class="confirmation-book__cell2 d-flex align-items-center">
                        <img :src="getCourseIcon(selectedCourse.lang)" class="mr-8" alt="Course">
                        {{$i18n.t(`general.lang.${ selectedCourse.lang || 'eng' }`)}}
                        <template v-if="selectedCourse.type">
                            - {{$i18n.t( `general.type.${selectedCourse.type || 'eng' }`)}}
                        </template>
                    </span>
                </div>

                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.lesson-date')}}</span>
                    <span class="confirmation-book__cell2">{{ formatDay(startDate) }}</span>
                </div>

                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.time')}}</span>
                    <span class="confirmation-book__cell2">
                        {{ formatTime(startDate) }}
                        <span v-if="timezone" class="ml-8">({{ timezone }})</span>
                    </span>
                </div>
            </template>

            <select
                :disabled="isRecurringDisabled"
                @change="getDropdownSelection($event.target.value)"
                class="schedule-calendar__select"
            >
                <option v-for="item in dropdownOptions" :value="item.value" :key="item.value">{{item.text}}</option>
            </select>

            <p
                v-if="isRecurringDisabled"
                class="schedule-calendar__disabled-recurring"
            >
                {{ $t('calendar-confirmation.disabled-recurring') }}
            </p>

            <div class="schedule-calendar__checkbox">
                <ModernCheckbox :data="{label: $i18n.t('calendar-confirmation.repeat-every-week')}" @input="getDropdownSelection"/>
            </div>
        </ScheduleSummary>

        <ScheduleSummary :action="action" v-if="action === 'cancel'" :modalTitle="$t('single-lesson.cancel-lesson')">
            <template #header>
                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.lesson')}}</span>
                    <span class="confirmation-book__cell2 d-flex align-items-center">
                        <img :src="getCourseIcon(selectedCourse.lang)" class="mr-8" alt="Course">
                        {{$i18n.t(`general.lang.${ selectedCourse.lang || 'eng' }`)}}
                        <template v-if="selectedCourse.type">
                            - {{$i18n.t( `general.type.${selectedCourse.type || 'eng' }`)}}
                        </template>
                    </span>
                </div>

                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.lesson-date')}}</span>
                    <span class="confirmation-book__cell2">{{ formatDay(startDate) }}</span>
                </div>

                <div class="confirmation-book__row mb-16">
                    <span class="confirmation-book__cell1">{{$i18n.t('calendar-confirmation.time')}}</span>
                    <span class="confirmation-book__cell2">
                        {{ formatTime(startDate) }}
                        <span v-if="timezone" class="ml-8">({{ timezone }})</span>
                    </span>
                </div>
            </template>

            <template v-if="selectedSlot.slot.lesson.recurringSince">
                <select @change="getDropdownSelection($event.target.value)" class="schedule-calendar__select">
                    <option v-for="item in dropdownOptions" :value="item.value" :key="item.value">{{item.text}}</option>
                </select>

                <div class="schedule-calendar__checkbox">
                    <ModernCheckbox :data="{label: $i18n.t('calendar.this-and-all-lessons')}" @input="getDropdownSelection"/>
                </div>
            </template>
        </ScheduleSummary>
    </div>
    
    <ScheduleSummary 
        v-else-if="responseState === SCHEDULE_STATE.BOOK_SUCCESS" 
        :key="2"
        :responseState="responseState" 
        :modalTitle="$i18n.t('modal.book-lesson.header')"
    >
        <img :src="checkGreen" class="schedule-confirmation__icon">

        <template #header>
            {{$i18n.t('calendar-confirmation.success')}} 
            <br>
            <template>
                <strong>{{ recurringState ? $i18n.t('calendar.lessons-have-been-booked') : $i18n.t('calendar.lesson-has-been-booked')}}</strong>
            </template>
        </template>
    </ScheduleSummary>

    <ScheduleSummary 
        v-else-if="responseState === SCHEDULE_STATE.CANCEL_SUCCESS" 
        :key="3"
        :responseState="responseState" 
        :modalTitle="$i18n.t('single-lesson.cancel-lesson')"
    >
        <img :src="checkGreen" class="schedule-confirmation__icon">

        <template #header>
            {{$i18n.t('calendar-confirmation.success')}} 
            <br>
            <template>
                <strong>{{ recurringState ? $i18n.t('calendar.lessons-have-been-canceled') : $i18n.t('calendar.lesson-has-been-canceled')}}</strong>
            </template>
        </template>
    </ScheduleSummary>


    <ScheduleSummary 
        v-else-if="responseState === SCHEDULE_STATE.CANCEL_CONFIRMATION"
        :key="4" 
        :responseState="responseState" 
        :modalTitle="$i18n.t('single-lesson.cancel-lesson')"
    >
        <template #header>
            {{$i18n.t('calendar-confirmation.lesson-cancel')}} {{ recurringState ? $i18n.t('calendar-confirmation.this-and-next-lessons') : $i18n.t('calendar.lesson-singular2') }}?
            <br>
            <strong v-if="nonRefundable">{{$i18n.t('calendar-confirmation.24-hours-to-lesson')}}</strong>
        </template>
    </ScheduleSummary>
</template>

<script>
import { mapState } from 'vuex';
import ScheduleSummary from '@/components/schedule/ScheduleSummary';
import ModernCheckbox from '@/components/reusable/ModernCheckbox';
import xRed from '@/assets/img/x-red.svg';
import checkGreen from '@/assets/img/check-green.svg';
import timezoneDate from '@/functions/timezoneDate';
import flagMixin from '@/mixins/flag';
import { SCHEDULE_STATE } from '@/constants/schedule';


export default {
    name: 'ScheduleConfirmation',
    components: {
        ScheduleSummary,
        ModernCheckbox
    },
    mixins: [flagMixin],
    props: {
        action: {
            type: String,
            default: 'book'
        }
    },
    data () {
        return {
            timezoneDate,
            showDetails: false,
            success: false,
            state: '',
            now: '',
            dropdownOptions: [],
            configHeaders: {
                'content-type': 'application/json'
            },
            xRed,
            checkGreen,
            SCHEDULE_STATE
        }
    },
    created() {
        this.$store.state.schedule.queue.push('book-or-cancel');
        const { 
            apiUrl,
            switchLector: { switchLectorApiUrl }
        } = this.$store.state.schedule;

        this.$store.commit('schedule/setSwitchLectorAnchor', switchLectorApiUrl || apiUrl)
    },
    mounted () {
        this.now = this.$moment().format(timezoneDate('YYYY-MM-DD'))
        this.getDropdownData();

        this.$store.commit('schedule/setSchedulePrimaryButtonDisabled', false);
    },
    computed: {
        ...mapState('schedule', ['selectedCourse']),
        ...mapState({
            timezone: state => state.user.timezone
        }),
        startDate () {
            return this.selectedSlot.slot.startAt;
        },
        nonRefundable () {
           return this.$moment(this.startDate).subtract(24, 'hours').isBefore()
        },
        selectedSlot() {
            return this.$store.state.schedule.selectedSlot;
        },
        responseState() {
            return this.$store.state.schedule.responseState;
        },
        recurringState() {
            return this.$store.getters['schedule/isRecurring'];
        },
        isRecurringDisabled() {
            return !this.selectedSlot.slot.canBeRecurring;
        },
    },
    methods: {
        formatDate (date) {
            return `${this.$moment(date).format(timezoneDate('dddd, D MMMM'))},
                ${this.$i18n.t('calendar.hour-shortcut')} ${this.$moment(date).format(timezoneDate('HH:mm'))}`;
        },
        formatDay (date) {
            return this.$moment(date).format(timezoneDate('dddd, D MMMM'));
        },
        formatTime (date) {
            return this.$moment(date).format(timezoneDate('HH:mm'));
        },
        formatDateCancelation(date) {
            return `${this.$moment(date).format(timezoneDate('dddd'))},
                ${this.$i18n.t('calendar.hour-shortcut')} ${this.$moment(date).format(timezoneDate('HH:mm'))}`;
        },
        fromatDayOfWeek (date) {
            const dayOfWeek = this.$moment(date).format(timezoneDate('dddd'));

            return `(${dayOfWeek})`;
        },
        getDropdownData () {
            this.dropdownOptions = [
                {
                text: `${this.selectedSlot.slot.lesson && this.selectedSlot.slot.lesson.recurringSince
                    ? this.$i18n.t('calendar-confirmation.just-this-lesson')
                    : this.$i18n.t('calendar-confirmation.dont-repeat')
                }`,
                value: false
                },
                {
                text: `${this.selectedSlot.slot.lesson && this.selectedSlot.slot.lesson.recurringSince
                    ? `${this.$i18n.t('calendar.all-lessons')} (${this.formatDateCancelation(this.startDate)})`
                    : `${this.$i18n.t('calendar-confirmation.repeat-every-week')} ${this.fromatDayOfWeek(this.startDate)}`
                }`,
                value: true
                }
            ]
        },
        getDropdownSelection(value) {
            this.$store.commit('schedule/setRecurring', JSON.parse(value));
        },
    }
}
</script>

<style lang="scss" scoped>
    .mr-8 {
        margin-right: 8px;
    }
    .ml-8 {
        margin-left: 8px;
    }

    @media(min-width: 992px) {
        .schedule {
            .schedule-calendar__checkbox .modern-checkbox {
                display: none;
            }
        }
    }
</style>