<template>
    <div v-if="loading" :class="['plan-header', { 'plan-header--mobile': isMobile }]">
        <HeaderSkeleton v-if="loading" />
    </div>
    <div v-else-if="!isMobile" class="plan-header">
        <div v-if="isMoreCoursesThanOne" @click="toggleMenu" v-click-outside="hideMenu" class="change-course-plan">
            <div class="text-wrapper">
                <span class="title">{{ $t('new-course-plan.header.dropdown-title') }}</span>
                <span :class="['arrow', { 'arrow--active': showMenu }]"></span>
            </div>
            <dropdown v-if="showMenu" class="dropdown" pos="bottom">
                <div v-for="(course, idx) in getAvailableCoursePlans" :key="idx" @click="changeCoursePlan(course.groupUuid)" class="dropdown-item">
                    <img :src="require(`@/assets/img/flags/${ course.language.shortName }.png`)" />
                    <div :class="['level', { 'level--active': course.groupUuid === getChosenCoursePlanUuid }]">{{ getAvailableCoursePlan(course) }}</div>
                    <img v-if="course.groupUuid === getChosenCoursePlanUuid" class="check" src="@/assets/img/check.svg"/>
                </div>
            </dropdown>
        </div>
        <span :class="['header', { 'header--only' : !isMoreCoursesThanOne }]">{{ $t('new-course-plan.header.course-plan') }}: {{ getCoursePlanTitle }}</span>
        <span class="lessons-count">{{ count }} {{ $t('new-course-plan.header.lessons') }}</span>
    </div>
    <div v-else class="plan-header plan-header--mobile">
        <div class="mobile-header">
            <span class="header">{{ getCoursePlanTitle }}</span>
            <span class="lessons-count">{{ count }} {{ $t('new-course-plan.header.lessons') }}</span>
        </div>
        <div v-if="isMoreCoursesThanOne" @click="toggleMenu" v-click-outside="hideMenu" class="change-course-plan">
            <div class="text-wrapper">
                <span :class="['arrow', { 'arrow--active': showMenu, 'arrow--mobile': isMobile }]"></span>
            </div>
            <dropdown v-if="showMenu" class="dropdown dropdown--mobile" pos="bottom">
                <div v-for="(course, idx) in getAvailableCoursePlans" :key="idx" @click="changeCoursePlan(course.groupUuid)" class="dropdown-item">
                    <img :src="require(`@/assets/img/flags/${ course.language.shortName }.png`)" />
                    <div :class="['level', { 'level--active': course.groupUuid === getChosenCoursePlanUuid }]">{{ getAvailableCoursePlan(course) }}</div>
                    <img v-if="course.groupUuid === getChosenCoursePlanUuid" class="check" src="@/assets/img/check.svg"/>
                </div>
            </dropdown>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import HeaderSkeleton from '@/components/coursePlan/skeletons/HeaderSkeleton';

export default {
    name: 'PlanHeader',
    components: { Dropdown, HeaderSkeleton },
    mixins: [dropdownMixin],
    computed: {
        ...mapState(['isMobile']),
        ...mapState('coursePlan', ['count', 'loading']),
        ...mapGetters('coursePlan', ['getAvailableCoursePlans', 'getChosenCoursePlanUuid', 'getChosenCoursePlan']),
        getCoursePlanTitle () {
            return `${ this.getChosenCoursePlan.languageLevel.isBusiness || this.getChosenCoursePlan.isBusiness ? 'Business' : 'General' } ${ this.getChosenCoursePlan.language.name } - ${ this.getChosenCoursePlan.languageLevel.shortName }`;
        },
        isMoreCoursesThanOne() {
            return this.getAvailableCoursePlans.length > 1
        }
    },
    methods: {
        getAvailableCoursePlan(course) {
            return `${ course.languageLevel.isBusiness || course.isBusiness ? 'Business' : 'General' } ${ course.language.name } - ${ course.languageLevel.shortName }`
        },
        changeCoursePlan(uuid) {
            if(uuid === this.getChosenCoursePlanUuid) return;

            this.hideMenu();
            this.$store.commit('coursePlan/setCoursePlanUuid', uuid);
            this.$router.push({ name: 'coursePlan', params: { uuid } });
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-header {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #3A9CFF 0%, #0782FF 100%);
    padding: 24px 32px;
    border-radius: 10px;
    width: 100%;
    color: $white;
    min-height: 165px;

    .header {
        @include font-h1;
        margin-top: 16px;

        &--only {
            margin-top: 36px;
        }
    }

    .lessons-count {
        @include font-lead;
    }

    &--mobile {
        flex-direction: row;
        align-items: center;
        padding: 16px 24px;
        min-height: auto;

        .header, .lessons-count {
            margin-top: 0;
            @include font-h3;
        }
    }
    
    .mobile-header {
        display: flex;
        flex-direction: column;
    }

    .change-course-plan {
        margin-left: auto;
        position: relative;
        cursor: pointer;

        .text-wrapper {
            display: flex;
            gap: 6px;
            align-items: center;

            .arrow {
                transform: rotate(0deg);
                transition: all $arrow-transition-time;
                -webkit-mask-size: cover;
                mask-size: cover;
                width: 16px;
                height: 16px;
                background-color: $white;
                -webkit-mask-image: url("~@/assets/img/arrow.svg");
                mask-image: url("~@/assets/img/arrow.svg");

                &--active {
                    transform: rotate(180deg);
                    filter: none;
                }

                &--mobile {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .dropdown {
            min-width: 343px;
            transform: translate(-80%, 100%);
            padding: 0;
            overflow: hidden;

            &--mobile {
                min-width: 316px;
                transform: translate(-95%, 100%);
            }
        }

        .dropdown-item {
            display: flex;
            align-items: center;

            .level {
                @include font-h3;
                margin-left: 12px;
                white-space: nowrap;
                padding-left: 0;
                color: $gray-600;

                &--active {
                    color: $gray-900;
                }
            }

            .check {
                margin-left: auto;
                width: 20px;
                height: 20px;
            }

            &:hover {
                background-color: $gray-100;
            }
        }
    }
}
</style>