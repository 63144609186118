<template>
    <div class="loading">
        <img 
            class="loading__loader" 
            :src="loadingImg" 
            alt=""
            :width="size"
            :height="size"
        >
    </div>
</template>

<script>
import lightLoader from '@/assets/img/loader.svg'
import darkLoader from '@/assets/img/loader-dark.svg'
import dangerLoader from '@/assets/img/loader-danger.svg'
import primaryLight from '@/assets/img/loader-primary-light.svg'

export default {
    name: 'Loading',
    props: {
        light: {
            type: Boolean,
        },
        danger: {
            type: Boolean
        },
        primaryLight: {
            type: Boolean
        },
        size: {
            type: Number,
            default: 20
        }
    },
    data() {
        return {
            lightLoader,
            darkLoader
        }
    },
    computed: {
        loadingImg() {
            if(this.light) return lightLoader
            if(this.danger) return dangerLoader
            if(this.primaryLight) return primaryLight

            return darkLoader
        }
    }
}
</script>

<style lang="scss" scoped>
@mixin spin {
    @keyframes spin {
        from { transform:rotate(0deg); }
        to { transform:rotate(360deg); }
    }

    animation: spin 1s linear infinite;
}

.loading {
    width: 100%;
    text-align: center;
    padding: 10px;

    &__loader {
        @include spin;
    }
}
</style>