<template>
    <div class="meeting">
        <p class="title">{{ printTitle }}</p>
        <p :class="
            ['subtitle',
            {'subtitle--left': isPlacementConversation()}]"
            v-html="printSubtitle"></p>

        <div class="ycbmiframe-wrapper">
            <iframe
                v-show="!loading"
                id="ycbmiframetestpage"
                class="ycbmiframe"
                :src="ycbmUrl"
                ref="ycbmiframe"
                frameborder="0"
                allowtransparency="true"
            ></iframe>

            <div v-if="loading" class="schedule">
                <div class="loader-wrapper">
                    <div class="loader"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import onboardingType from '@/mixins/onboardingMeetingType';
import { schoolV2 } from '@/plugins/axios';

export default {
    name: 'OnboardingMeeting',
    mixins: [ onboardingType ],
    beforeRouteEnter(to, from, next) {
        if(to.params.url || (to.query.url && to.name === 'Meeting') ) {
            next()
        } else next({name: 'dashboard', params: {locale: to.params.locale}})
    },
    data() {
        return {
            interval: 0,
            loading: true,
            url:  this.$route.params.url || decodeURIComponent(this.$route.query.url),
            type: this.$route.params.type || this.$route.query.type
        }
    },
    computed: {
        ycbmUrl() {
            return `${this.url}&noframe=true&skipHeaderFooter=true`;
        },
        printTitle() {
            if (this.isWelcomeMeeting()) return this.$i18n.t('onboarding.meeting.hello-meeting.title');
            if (this.isPlacementConversation()) return this.$i18n.t('onboarding.meeting.placement-conversation.title');
            
            return '';
        },
        printSubtitle() {
            if (this.isWelcomeMeeting()) return this.$i18n.t('onboarding.meeting.hello-meeting.subtitle');
            if (this.isPlacementConversation()) return this.$i18n.t('onboarding.meeting.placement-conversation.subtitle');

            return '';
        },
        isNonLoggedMeeting() {
            return this.$route.name === 'Meeting';
        }
    },
    created() {
        this.$store.state.wideOnboarding = true;

        // non-logged students
        if (this.isNonLoggedMeeting && this.$route.query.uuid) {
            this.interval = setInterval(() => {
                this.checkIfMeetingBooked()
            }, 3000);
            return;
        }

        // only logged students have configured pusher 
        this.$bus.$on('meetingBooked', () => {
            setTimeout(() => {
                this.$router.push({name: 'dashboard'});
            }, 3000);
        });
    },
    beforeDestroy() {
        this.$bus.$off('meetingBooked');
    },
    mounted() {
        this.$refs.ycbmiframe.addEventListener('load', () => {this.loading = false})
    },
    methods: {
        async checkIfMeetingBooked() {
            const { uuid } = this.$route.query;

            const res = await schoolV2.get(`student/${uuid}/booking/status`)
            const { status, redirectUrl } = res.data;

            if (redirectUrl && status === 'booked') {
                clearInterval(this.interval);

                window.location = redirectUrl;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .ycbmiframe-wrapper {
        margin-top: 32px; 
        padding: 5px 5px 0;
        border: 1px solid #E6E7E9; 
        border-radius: 8px;
    }

    .ycbmiframe {
        width: 100%;
        height: 500px;
        border: 0px;
        background-color: transparent;
    }

    .loader-wrapper {
        height: 100%;
    }

    .schedule {
        position: relative;
        height: 300px;
    }

    .loader:after {
        left: 0;
    }
</style>