<template>
    <v-facebook-login v-if="!isMobileApp" @login="facebookLogin" @sdk-init="handleSdkInit" :class="['fb-btn btn', { 'hide-btn' : thirdParty, 'icon' : iconOnly }]" :app-id="FBid" text-class="text">
        <template slot="login">{{ $t('login-with-facebook') }}</template>
        <img slot="logo" class="logo" src="@/assets/img/social/facebook.svg" alt="">
    </v-facebook-login>
    <a v-else :class="['fb-btn btn', { 'icon' : iconOnly }]" href="https://m.facebook.com/login">
        <img class="logo" src="@/assets/img/social/facebook.svg" alt="">
        <span class="text">{{ $t('login-with-facebook') }}</span>
    </a>
</template>

<script>
import { mapGetters } from 'vuex'
import VFacebookLogin from 'vue-facebook-login-component'

export default {
    props: {
        thirdParty: {
            type: Boolean,
            default: false
        },
        iconOnly: {
            type: Boolean,
            default: false
        }
    },
    components: { VFacebookLogin },
    data() {
        return {
            FBid: process.env.VUE_APP_FACEBOOK_APP_ID,
        }
    },
    computed: {
        ...mapGetters(['isMobileApp', 'getFacebookLoginScope']),
    },
    methods: {
        facebookLogin(e) {
            const externalToken = e?.authResponse.accessToken
            this.$emit('action', { token: externalToken, type: 'facebook' });
        },
        handleSdkInit({ scope }) {
            this.$store.commit('setFacebookLoginScope', scope);
        }
    }
}
</script>

<style lang="scss">
    .fb-btn {
        cursor: pointer !important;
        padding: 3px 14px !important;
        border-width: 2px !important;
        background: $white !important;
        border: 1px solid #dadce0 !important;
        transition: all 0.2s, padding 0s !important;

        &.icon {
           min-height: 32px;
           max-width: 32px;
            
            .logo {
                min-width: 16px;
            }
        }

        &.hide-btn {
            .text, .logo, .loader {
                opacity: 0.0001;
            }
        }

        &:hover {
            background-color: rgba(66,133,244,.04) !important;
            border-color: #d2e3fc !important;
        }

        .text {
            @include font-medium-text;
            color: $gray-900;
        }

        .logo {
            fill: #1877F1 !important;
            width: 16px !important;
            height: 16px !important;
            margin-right: 0 !important;
        }
    }
</style>