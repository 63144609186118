var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'schedule-lector',
    { 
        'selected': _vm.selected,
        'schedule-lector--border': _vm.action
    }
]},[_c('div',{class:[
        'confirmation-book__row',
        { 
            'confirmation-book__row--calendar': !_vm.action,
            'confirmation-book__row--center': _vm.$store.state.schedule.responseState && !_vm.lectorToDisplay.headline
        }
    ]},[_c('div',{staticClass:"confirmation-book__cell1"},[_c('img',{staticClass:"schedule-lector__avatar",attrs:{"src":_vm.lectorToDisplay.avatarUrl}})]),_c('div',{staticClass:"confirmation-book__cell2"},[_c('div',{staticClass:"schedule-lector__name"},[_vm._v(_vm._s(_vm.lectorToDisplay.name))]),(_vm.lectorToDisplay.headline)?_c('div',{class:[
                    'schedule-lector__desc',
                    {'schedule-lector__desc--lines-limit': _vm.action !== 'book'}
                ]},[_vm._v(_vm._s(_vm.lectorToDisplay.headline))]):_vm._e(),(_vm.isCalendarPreviewMode && _vm.slotsExists)?_c('Select',{staticClass:"change-timezone-select",attrs:{"data":{
                    options: _vm.timezones
                },"value":_vm.activeTimezone},on:{"input":_vm.changeTimezone}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }