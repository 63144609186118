<template>
    <div
        :class="['status-icon', { 'status-icon--success': success, 'status-icon--half-correct': success === 'HALF_CORRECT' || (isFlashcardsActivity && dontKnow) }]"
        :style="{
            width: size + 'px',
            height: size + 'px'
        }"
    >
        <img
            :width="iconSize + 'px'"
            :height="iconSize + 'px'"
            :src="getIcon()"
            alt=""
        >
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
    name: 'StatusIcon',
    props: {
        success: [Boolean, String],
        size: {
            type: Number,
            default: 42
        },
        iconSize: {
            type: Number,
            default: 24
        }
    },
    computed: {
        ...mapState('flashcards', ['dontKnow']),
        ...mapGetters('learning', ['isFlashcardsActivity'])
    },
    methods: {
        getIcon() {
            if (this.success === 'HALF_CORRECT') return require('@/assets/img/exclamation-point.svg');

            return require(this.success ? '@/assets/img/check-white.svg' : '@/assets/img/close-white.svg')
        }
    }
}
</script>

<style lang="scss" scoped>
.status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $danger-middle;
    flex-shrink: 0;

    &--success {
        background: $secondary;
    }

    &--half-correct {
        background: $orange;
    }
}
</style>