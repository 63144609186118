<template>
    <div class="gifs">
        <div v-for="item in gifs" :key="item.id" class="gif">
            <img :src="item.images.original.url" :alt="item.title" @click="send({url: item.images.original.url, width: item.images.original.width, height: item.images.original.height})">
        </div>
    </div>
</template>

<script>
import { giphy } from '@/plugins/axios'

export default {
    name: 'Gifs',
    props: ['search'],
    data() {
        return {
            gifs: [],
            timeout: null
        }
    },
    async mounted() {
        await this.getTrending()
    },
    methods: {
        find() {
            clearTimeout(this.timeout)
            var self = this

            this.timeout = setTimeout(async function() {
                if(self.search) {
                    let res = await giphy.get(`search?api_key=${process.env.VUE_APP_GIPHY_KEY}&q=${self.search}&limit=10&offset=0&rating=g&lang=en`)
                    self.gifs = res.data.data
                } else {
                    await self.getTrending()
                }
            }, 1000)
        },
        async getTrending() {
            let res = await giphy.get(`trending?api_key=${process.env.VUE_APP_GIPHY_KEY}&limit=10&rating=g`)
            this.gifs = res.data.data
        },
        send(data) {
            this.$bus.$emit('sendGif', data)
        }
    },
    watch: {
        search() {
            this.find()
        }
    }
}
</script>

<style>

</style>