<template>
    <div class="data">
        <div class="data-header">
            <h3 class="section-title">{{ $t('account.basic.form.manage-account') }}</h3>
        </div>
        <Button @click.native="deleteAccountModal = true" class="danger-gray" :text="$t('account.basic.form.manage-account.delete.btn')" />
        <p class="text--delete" v-html="$t('account.basic.form.manage-account.delete.text')"></p>
        
        <Modal class="delete-account" v-if="deleteAccountModal" @hide="deleteAccountModal = false">
            <div class="modal-inner" v-click-outside="hide">
                <div class="action">
                    <p class="delete-account__title">{{ $t('account.basic.form.manage-account.delete.modal.title') }}</p>

                    <p class="delete-account__text" v-html="$t('account.basic.form.manage-account.delete.modal.text')"></p>

                    <Button @click.native="deleteAccount" class="danger mt-12" :text="$t('account.basic.form.manage-account.delete.modal.btn.primary')" />
                    <Button @click.native="deleteAccountModal = false" class="default mt-2" :text="$t('account.basic.form.manage-account.delete.modal.btn.default')" />

                    <p class="delete-account__warning">{{ $t('account.basic.form.manage-account.delete.modal.warning') }}</p>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button';
import modalMixin from '@/mixins/modal'
import ClickOutside from 'vue-click-outside'
import defaultLogout from '@/functions/defaultLogout'

import api from '@/graphql/api.js'
import { deleteStudentAccount } from '@/graphql/mutations/deleteStudentAccount'

export default {
    name: 'ManageAccount',
    components: {
        Button
    },
    mixins: [modalMixin],
    directives: {
        ClickOutside
    },
    data() {
        return {
            deleteAccountModal: false
        }
    },
    mounted() {
        this.popupItem = document.querySelector('.modal-inner')
    },
    methods: {
        async deleteAccount() {
            const { error } = await api(deleteStudentAccount)
            if(error) return { error };

            defaultLogout()
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style scoped lang="scss">
    .mt-2 {
        margin-top: 8px;
    }

    .mt-12 {
        margin-top: 12px;
    }

    .text--delete {
        margin-top: 8px;
        color: $gray-600;
        @include font-small-12-regular; 
    }

    .danger-gray {
        background: $gray-100;
        border-color: $gray-100;
        color: $danger-middle;

        &:hover {
            background: rgba($gray-100, .95);
        }
    }

    .danger {
        background: $danger-middle;
        border-color: $danger-middle;

        &:hover {
            background: rgba($danger-middle, .95);
        }
    }

    .delete-account {
        display: flex;
        justify-content: center;

        .modal-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 346px;
            padding: 32px;
            text-align: center;
        }

        .btn {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .delete-account__title {
        @include font-h2;
    }

    .delete-account__text {
        padding: 8px;
        @include font-regular-text;
    }

    .delete-account__warning {
        margin-top: 24px;
        @include font-medium;
        color: $gray-600;
    }
</style>