function setCookie(name, value, hours) {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + hours);

    const cookieValue = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/;`;
    document.cookie = cookieValue;
}

function getCookie(name) {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const cookie = cookies.find(cookie => cookie.startsWith(`${name}=`));

    if (cookie) return cookie.split('=')[1];
    else return;
}

export { setCookie, getCookie };