<template>
    <AudioPlayerLevels :data="data" />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import AudioPlayerLevels from '@/components/reusable/AudioPlayerLevels';

export default {
    name: 'Audio',
    props: ['data'],
    components: { AudioPlayerLevels },
    data() {
        return {
            uuidv4,
            type: null
        };
    },
    computed: {
        ...mapGetters('learning', ['getCurrentMedia']),
        formattedElements() {
            return this.data
                .map(x => {
                    return x.split('<nl>');
                })
                .flat();
        }
    }
};
</script>

<style scoped lang="scss">
.text-listening {
    padding: 8px;
}

.audio-player {
    margin-top: 12px;
}

::v-deep {
    .audio-player .player-wrapper {
        margin: initial;
        width: initial;
    }
}
</style>
