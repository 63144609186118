<template>
    <div>
        <p v-if="active" class="widget-info"><img src="@/assets/img/warning-light.svg" class="icon" alt=""> {{$i18n.t('dashboard.next-lesson.active')}}</p>
        <div class="widget lesson upcoming" :class="{active}">
            <LessonHeader :data="headerData" />
            <div class="buttons">
                <Button class="primary" @click.native="goToLesson(data.lesson.uuid)" :text="$i18n.t('dashboard.next-lesson.start')"/>
            </div>
            <span class="reload-info">{{ $t('lesson.reload-info') }}</span>
        </div>
    </div>
</template>

<script>
import LessonHeader from './LessonHeader'
import icon from '@/assets/img/lesson.svg'
import Button from '@/components/reusable/Button'

export default {
    name: 'NextLesson',
    props: ['data'],
    components: {
        LessonHeader,
        Button
    },
    computed: {
        active() {
            return this.data.joinLessonUrl
        },
        headerData() {
            return {
                icon,
                title: this.$i18n.t('dashboard.next-lesson.title'),
                data: this.data.lesson,
                action: true,
                actionUrl: this.data.rescheduleLessonUrl
            }
        }
    },
    methods: {
        goToLesson(uuid) {
            this.$router.push({name: 'singleLesson', params: {uuid: uuid}})
        }
    }
}
</script>

