<template>
    <router-link :to="{ name: 'singleLesson', params: { uuid: data.lesson.uuid }}" class="lesson" :class="getLessonStatus">
        <div class="date">
            <p class="day">{{ $moment(data.lesson.startAt).format(timezoneDate('D MMMM')) }}</p>
            <p class="hour">{{ $moment(data.lesson.startAt).format(timezoneDate('HH:mm')) }}</p>
        </div>
        <div class="subject">
            <p class="title">{{ data.lesson.lessonSubjects?.length ? data.lesson.lessonSubjects?.[0].subject : '-' }}</p>
            <p class="info">{{ data.lesson.languageLevel ? data.lesson.languageLevel.shortName : '' }} {{ data.lesson.number && data.lesson.languageLevel ? ` - ${ data.lesson.number }` : '' }}</p>
        </div>
        <div class="lector">
            <div class="avatar">
                <img class="avatar-style" :src="setAvatar(data.lesson.user.avatarUrl)" width="24" height="24" alt="">
            </div>
            <p class="name">{{ data.lesson.user.firstName }}</p>
            <span v-if="isTeacherSubstituted" class="info-tooltip"><img src="@/assets/img/info.svg" alt="">
                <Tooltip :text="`${ $t('lesson.replacement-info') }`"/>
            </span>
        </div>
        <div class="status">
            <span class="label">{{ labels[getLessonStatus] }}</span>
        </div>
        <div class="presence">
            <div class="presence-marker" :class="createMarker()"></div>
        </div>
    </router-link>
</template>

<script>
import setAvatarMixin from '@/mixins/setAvatar'
import timezoneDate from '@/functions/timezoneDate'
import Tooltip from '@/components/reusable/Tooltip'

export default {
    name: 'SingleLesson',
    props: ['data'],
    data() {
        return {
            labels: {
                finished: this.$i18n.t('lessons.status.finished'),
                cancelled: this.$i18n.t('lessons.status.cancelled'),
                scheduled: this.$i18n.t('lessons.status.scheduled'),
                'in-progress': this.$i18n.t('lessons.status.in-progress'),
                pending: this.$i18n.t('lessons.status.pending')
            },
            timezoneDate
        }
    },
    components: { Tooltip },
    mixins: [setAvatarMixin],
    methods: {
        createMarker() {
            if(this.data.isIncludedInParticipation) return this.data.hasParticipated ? 'on' : 'off'
        }
    },
    computed: {
        getLessonStatus() {
            return this.data.lesson.studentLesson.joinLessonUrl ? 'in-progress' : this.data.lesson.status;
        },
        isTeacherSubstituted() {
            return ['in-progress', 'pending', 'scheduled'].includes(this.getLessonStatus) && this.data.lesson.isTeacherSubstituted
        }
    }
}
</script>
