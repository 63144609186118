<template>
    <div class="placement-test-summary__scheme">
        <div class="q-all q1">1</div><div class="q-all q2">2</div><div class="q-all q3">3</div><div class="q-all q4">4</div><div class="q-all q5">5</div><div class="q-all q6">6</div><div class="q-all q7">7</div><div class="q-all q8">8</div><div class="q-all q9">9</div><div class="q-all q10">10</div><div class="q-all q11">11</div><div class="q-all q12">12</div><div class="q-all q13">13</div><div class="q-all q14">14</div><div class="q-all q15">15</div><div class="q-all q16">16</div>
        <div class="a-all a1">A0</div><div class="a-all a2">A1.1</div><div class="a-all a3">A1.2</div><div class="a-all a4">A2.1</div><div class="a-all a5">A2.2</div><div class="a-all a6">A2.3</div><div class="a-all a7">B1.1</div><div class="a-all a8">B1.2</div><div class="a-all a9">B1.3</div><div class="a-all a10">B2.1</div><div class="a-all a11">B2.2</div><div class="a-all a12">B2.3</div><div class="a-all a13">C1.1</div><div class="a-all a14">C1.2</div><div class="a-all a15">C1.3</div><div class="a-all a16">C2</div>
        <div class="l-all l1"></div><div class="l-all l2"></div><div class="l-all l3"></div><div class="l-all l4"></div>
    </div>
</template>

<script>

export default {
    name: 'skillsChart',
}
</script>

<style lang="scss" scoped>
    .placement-test-summary__scheme {
        display: grid;
        grid-template-columns: 8px 48px 32px repeat(17, 15px);
        grid-template-rows: 24px 12px repeat(4, 24px 4px) 24px 12px repeat(5, 24px 4px) 24px 12px repeat(3, 24px 4px) 24px;
        grid-template-areas: 
            "l1 a1 q1 . . . . . . . . . . . . . . ."
            ". . . . . . . . . . . . . . . . . ."
            "l2 a2 q2 q2 . . . . . . . . . . . . . ."
            "l2 . . . . . . . . . . . . . . . . ."
            "l2 a3 q3 q3 q3 . . . . . . . . . . . . ."
            "l2 . . . . . . . . . . . . . . . . ."
            "l2 a4 q4 q4 q4 q4 . . . . . . . . . . . ."
            "l2 . . . . . . . . . . . . . . . . ."
            "l2 a5 q5 q5 q5 q5 q5 . . . . . . . . . . ."
            "l2 . . . . . . . . . . . . . . . . ."
            "l2 a6 q6 q6 q6 q6 q6 q6 . . . . . . . . . ."
            ". . . . . . . . . . . . . . . . . ."
            "l3 a7 q7 q7 q7 q7 q7 q7 q7 . . . . . . . . ."
            "l3 . . . . . . . . . . . . . . . . ."
            "l3 a8 q8 q8 q8 q8 q8 q8 q8 q8 . . . . . . . ."
            "l3 . . . . . . . . . . . . . . . . ."
            "l3 a9 q9 q9 q9 q9 q9 q9 q9 q9 q9 . . . . . . ."
            "l3 . . . . . . . . . . . . . . . . ."
            "l3 a10 q10 q10 q10 q10 q10 q10 q10 q10 q10 q10 . . . . . ."
            "l3 . . . . . . . . . . . . . . . . ."
            "l3 a11 q11 q11 q11 q11 q11 q11 q11 q11 q11 q11 q11 . . . . ."
            "l3 . . . . . . . . . . . . . . . . ."
            "l3 a12 q12 q12 q12 q12 q12 q12 q12 q12 q12 q12 q12 q12 . . . ."
            ". . . . . . . . . . . . . . . . . ."
            "l4 a13 q13 q13 q13 q13 q13 q13 q13 q13 q13 q13 q13 q13 q13 . . ."
            "l4 . . . . . . . . . . . . . . . . ."
            "l4 a14 q14 q14 q14 q14 q14 q14 q14 q14 q14 q14 q14 q14 q14 q14 . ."
            "l4 . . . . . . . . . . . . . . . . ."
            "l4 a15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 q15 ."
            "l4 . . . . . . . . . . . . . . . . ."
            "l4 a16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16 q16"
        ;
        @media (min-width: 625px) {
            justify-content: center;
            grid-template-columns: repeat(15, 1fr 12px) 1fr;
            grid-template-rows: repeat(15, 8px) repeat(2, 32px) 8px;
            grid-template-areas: 
                ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . q16"
                ". . . . . . . . . . . . . . . . . . . . . . . . . . . . q15 . q16"
                ". . . . . . . . . . . . . . . . . . . . . . . . . . q14 . q15 . q16"
                ". . . . . . . . . . . . . . . . . . . . . . . . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . . . . . . . . . . . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . . . . . . . . . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . . . . . . . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . . . . . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . . . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . . . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . . . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . . . q5 . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . . . q4 . q5 . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . . . q3 . q4 . q5 . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                ". . q2 . q3 . q4 . q5 . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                "q1 . q2 . q3 . q4 . q5 . q6 . q7 . q8 . q9 . q10 . q11 . q12 . q13 . q14 . q15 . q16"
                "a1 . a2 . a3 . a4 . a5 . a6 . a7 . a8 . a9 . a10 . a11 . a12 . a13 . a14 . a15 . a16"
                "l1 . l2 l2 l2 l2 l2 l2 l2 l2 l2 . l3 l3 l3 l3 l3 l3 l3 l3 l3 l3 l3 . l4 l4 l4 l4 l4 l4 l4" 
            ;
        }
    }

    .q-all { 
        @include font-medium-text;
        background-color: $primary; 
        color: $white;
        border-radius: 6px;
        padding-right: 8px;
        text-align: right;

        @media (min-width: 625px) {
            text-align: center;
            padding-right: 0;
            padding-top: 4px;
        }
    }

    .a-all {
        @include font-small-10;
        color: $gray-900;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: IBM Plex Sans;
    }

    @for $i from 1 through 16 {
        .a#{$i} {
            grid-area: a#{$i};
        }

        .q#{$i} {
            grid-area: q#{$i};
        }
    }

    .l-all {
        background-color: $gray-200;
        border-radius: 32px;
    }

    .l1 { grid-area: l1; background-color: $gray-100; }
    .l2 { grid-area: l2; background-color: $gray-200; }
    .l3 { grid-area: l3; background-color: $gray-300; }
    .l4 { grid-area: l4; background-color: $gray-600; }
</style>