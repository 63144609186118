import { render, staticRenderFns } from "./GrammarInput.vue?vue&type=template&id=1a702796&scoped=true"
import script from "./GrammarInput.vue?vue&type=script&lang=js"
export * from "./GrammarInput.vue?vue&type=script&lang=js"
import style0 from "./GrammarInput.vue?vue&type=style&index=0&id=1a702796&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a702796",
  null
  
)

export default component.exports