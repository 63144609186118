function mapFiltersValues(filters) {
    const keys = ['advanceNotice', 'languagesISpeak', 'groupType', 'nativeSpeaker'];

    const result = {};
    
    keys.forEach(key => {
        const { value, active } = filters[key];
        if (!active) return;

        if (key === 'advanceNotice' ) {
            result[key] = 24;
        } else if (key === 'groupType') {
            result[key] = value.toUpperCase();
        } else if (Array.isArray(value) && value.length) {
            result[key] = value.map(lang => lang.value);
        } else result[key] = value;
    });

    return result;
}

export {
    mapFiltersValues as default
}