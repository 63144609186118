<template>
    <div :class="['tools-pill', { 'tools-pill--pointer': pointer }]">
        <img class="tools-pill__flag" :src="getCourseIcon(lang)" alt=""> 
            <span class="tools-pill__text" v-if="!shorter">{{ $i18n.t(`general.language.${ lang }`) }}</span>
        <div v-if="pointer" class="tools-pill__arrow"></div>
    </div>
</template>

<script>
import flag from '@/mixins/flag'

export default {
    name: 'ToolsPill',
    props: {
        lang: {
            type: String
        },
        pointer: {
            type: Boolean
        },
        shorter: {
            type: Boolean,
            default: false
        }
    },
    mixins: [flag]
}
</script>

<style lang="scss" scoped>
.tools-pill {
    padding: 4px 8px;
    border-radius: 48px;
    background: $gray-100;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    @include font-h4;
    color: $gray-900;
    transition: all .25s;

    &--pointer {
        cursor: pointer;

        &:hover {
            background: $gray-200;
        }
    }

    &--active {
        background: $primary-light !important;
        color: $primary-dark;

        .tools-pill__arrow {
            transform: rotate(270deg);
            background: url('@/assets/img/arrow-blue.svg');
        }
    }

    &__text {
        white-space: nowrap;
    }

    &__flag {
        border-radius: 50%;
    }

    &__arrow {
        width: 16px;
        height: 16px;
        background: url('@/assets/img/arrow.svg');
        transition: all .25s;
    }
}
</style>