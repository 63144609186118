<template>
    <div v-if="!loading" :class="['add-calendar', { 'add-calendar--disabled': isDisabled }]">
        <div class="add-calendar__calendars">
            <img
                v-for="calendar in calendars"
                :key="calendar.type"
                :src="require(`@/assets/img/calendar/${ calendar.type }.png`)" 
                :alt="calendar.type" 
                width="32" 
                height="32"
            >
        </div>
        <p class="add-calendar__title">{{ $t('lessons.add-calendar.title') }}</p>
        <p class="add-calendar__description">{{ $t('lessons.add-calendar.description') }}</p>
        <Button
            class="secondary"
            :text="$t('lessons.add-calendar.btn')"
            :icon="require('@/assets/img/arrow-blue.svg')"
            @click.native="$router.push({ name: 'accountCalendar' })"
        />
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import { calendarIntegrations } from '@/graphql/queries/calendarIntegrations'
import api from '@/graphql/api.js'
import { mapGetters } from 'vuex'

export default {
    name: 'AddCalendar',
    components: {
        Button
    },
    data() {
        return {
            calendars: [],
            loading: true
        }
    },
    async created() {
        const { res } = await api(calendarIntegrations)

        this.calendars = res.currentUser.student.iCalendarIntegrations
        this.loading = false
    },
    computed: {
        ...mapGetters(['getHavePackage', 'getStudentHadLesson']),
        isDisabled() {
            return !this.getHavePackage && !this.getStudentHadLesson
        }
    }
}
</script>

<style lang="scss" scoped>
.add-calendar {
    padding: 20px 24px;

    &--disabled {
        pointer-events: none;
        opacity: .5;
    }

    &__calendars {
        display: flex;
        gap: 16px;
    }

    &__title {
        @include font-h5-uc;
        color: $gray-900;
        margin: 16px 0 8px;
    }

    &__description {
        @include font-small-12-regular;
        color: $dark-stone;
        margin-bottom: 16px;
    }

    .btn {
        width: 100%;
        flex-direction: row-reverse;
        gap: 4px;
    }
}
</style>