<template>
    <div class="widget plan-lesson">
        <div class="header">
            <h3 class="title">
                {{$i18n.t('dashboard.plan-lesson.title')}}
            </h3>
            <p v-if="!isBusiness" class="subtitle">
                {{$i18n.t('dashboard.plan-lesson.available-lessons')}}: {{data}}
            </p>
        </div>
        <BookLessonBtn placement="noNextLessonWidgetButton"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BookLessonBtn  from '@/components/reusable/BookLessonBtn'

export default {
    name: 'PlanLesson',
    props: ['data'],
    components: {
        BookLessonBtn
    },
    data() {
        return {
            bookLoading: false
        }
    },
    computed: {
        ...mapGetters([
            'isBusiness'
        ])
    }
}
</script>

