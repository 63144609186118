import { gqlAPI } from '@/plugins/axios'
import router from '@/router'
import accessStorage from '@/functions/accessStorage'
import defaultLogout from '@/functions/defaultLogout'

const api = async (query, variables = {}, name) => {
    const data = {
        res: null,
        error: null
    }

    try {
        let res = await gqlAPI.post('', {
            query: query.loc.source.body,
            variables
        },
        {
            headers: {
                'Authorization': `Bearer ${ accessStorage('authToken') }`,
                'Content-Type': 'application/json'
            }
        })

        data.res = res.data.data
        data.error = res.data.errors

        if(window.Cypress && name) window.app.graphql[name] = data.res
        
    } catch(err) {
        if(err.response.status === 401) {
            defaultLogout()
            router.push({ name: 'login' })
        }
    }

    return data
}

export default api