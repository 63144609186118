<template>
    <div class="translator__formality-mobile">
        <ModernCheckbox 
            @input="setFormality"
            :value="value"
            :data="{
                label: $i18n.t('tools.translator.turn-on-formality')
            }"
        />
    </div>
</template>

<script>
import ModernCheckbox from '@/components/reusable/ModernCheckbox'
import { mapState } from 'vuex'

export default {
    name: 'TranslatorFormalityMobile',
    components: {
        ModernCheckbox
    },
    computed: {
        ...mapState('tools', ['translator']),
        value() {
            return this.translator.formality === 'less' ? false : true
        }
    },
    methods: {
        setFormality(payload) {
            this.$store.commit('tools/setTranslatorFormality', payload ? 'more' : 'less')
        }
    }
}
</script>

<style lang="scss">
.translator__formality-mobile {
    display: none;
    padding: 0 16px 16px;

    .modern-checkbox {
        padding: 0;
        height: 24px;
        @include font-regular-text;

        .checkmark {
            left: unset;
            right: 0px;
            width: 40px;
            height: 24px;
            
            .point {
                top: 3px;
                left: 4px;
                width: 18px;
                height: 18px;
            }
        }

        input {
            &:checked ~ .checkmark {
                .point {
                    transform: translateX(14px);
                }
            }
        }
    }
}
</style>