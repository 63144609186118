<template>
    <div v-if="!loading" class="certificates">
        <h3 class="section-title">{{ $i18n.t('account.certificates.title') }}</h3>
        <div v-for="cert in certificates" :key="cert.uuid" class="certificate">
            <div class="certificate__header">
                <p class="certificate__title"><img src="@/assets/img/progressTest/award.svg" alt=""> {{$i18n.t('account.certificates.certificate')}}</p>
                <p class="certificate__date">{{ $moment(cert.stopAt).format(timezoneDate('D MMMM, YYYY')) }}</p>
            </div>
            <div class="certificate__content">
                <div class="certificate__language">
                    <div class="course-icon">
                        <img :src="getCourseIcon(cert.language.shortName)" alt="Course">
                    </div>
                    <div>
                        <p class="certificate__description">{{ $i18n.t(`account.certificates.description.${ cert.language.shortName }`) }}</p>
                        <p class="certificate__language-level">{{ $i18n.t('account.certificates.language-level') }} {{ cert.languageLevel.shortName }}</p>
                    </div>
                </div>
                <Button
                    v-if="cert.link" 
                    class="default" 
                    @click.native="download(cert.link)" 
                    :text="$i18n.t('account.certificates.download')" 
                    :icon="require('@/assets/img/progressTest/download.svg')"
                />
            </div>
        </div>
        <div v-if="!certificates.length" class="empty-state">
            <p class="title">{{ $i18n.t('account.certificates.empty-state.title') }}</p>
        </div>
    </div>
</template>

<script>
import Button from '@/components/reusable/Button'
import timezoneDate from '@/functions/timezoneDate'
import flag from '@/mixins/flag'
import { progressTests } from '@/graphql/queries/progressTests'
import api from '@/graphql/api.js'

export default {
    name: 'AccountCalendar',
    components: {
        Button
    },
    mixins: [flag],
    data() {
        return {
            timezoneDate,
            loading: true,
            certificates: []
        }
    },
    async created() {
        const { res } = await api(progressTests)

        this.certificates = res.currentUser.student.progressTests.filter(({ status }) => status === 'COMPLETED')

        this.loading = false
        this.$emit('loaded')
    },
    methods: {
        download(link) {
            open(link)
        }
    }
}
</script>

<style lang="scss" scoped>
.certificate {
    border-radius: 10px;
    border: 2px solid $gray-200;
    overflow: hidden;
    margin-top: 16px;
    background-image: url('~@/assets/img/progressTest/stars.svg');
    background-repeat: no-repeat;
    background-position: bottom right;

    &__header {
        padding: 16px;
        background: $gray-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }

    &__title {
        @include font-h5-uc;
        color: $gray-700;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__date {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $gray-600;
    }

    &__content {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media(min-width: 992px) {
            padding: 24px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .btn {
            flex-shrink: 0;
        }
    }

    &__language {
        display: flex;
        gap: 8px;
    }

    &__description {
        @include font-h4;
    }

    &__language-level {
        @include font-regular;
    }
}

.empty-state {
    text-align: center;
    margin-top: 80px;

    .title {
        @include font-h3;
        color: $gray-500;
    }
}
</style>