<template>
    <div class="no-package-baner" v-if="showBaner">
        <img
            class="img"
            src="@/assets/img/coursePlan/baner.webp"
            alt=""
        />
        <div class="baner-wrapper">
            <span class="baner-wrapper__title">{{ $t('course-plan.baner-title') }}</span>
            <span class="baner-wrapper__subtitle">{{ $t('course-plan.baner-subtitle') }}</span>
            <div class="baner-wrapper__btn">
                <a
                    class="btn primary button"
                    :href="checkoutUrl"
                    target="_blank"
                >
                    {{ $t('course-plan.baner-buy') }}
                </a>
                <a
                    class="btn secondary button"
                    :href="startUrl"
                    target="_blank"
                >
                    {{ $t('course-plan.baner-contact') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import brandAppUrls from '@/mixins/brandAppUrls';

export default {
    name: 'NoPackageBaner',
    props: {},
    mixins: [brandAppUrls],
    computed: {
        ...mapState('coursePlan', ['showBaner'])
    },
}
</script>

<style lang="scss" scoped>
.no-package-baner {
    top: 88px;
    position: sticky;
    max-width: 352px;
    height: 100%;
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);

    .img {
        border-radius: 10px;
        width: 100%;
        height: 337px;
        object-fit: cover;
    }

    .baner-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 24px 40px 24px;
        position: relative;

        &__title {
            color: $gray-900;
            font-size: 28px;
            line-height: 40px;
            font-weight: 600;
            z-index: 1;
        }

        &__subtitle {
            color: $gray-900;
            @include font-regular-text;
        }

        &__btn {
            display: flex;
            gap: 8px;

            .button {
                padding: 8px 16px;
            }
        }
    }

    @media(max-width: $mobile) {
        max-width: 100%;
        top: 0;
        height: auto;
        max-width: initial;

        .img {
            width: 100%;
            height: 337px;
            object-fit: cover;
            border-radius: 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .baner-wrapper {
            padding: 12px 24px 40px 24px;
        }
    }
}
</style>
