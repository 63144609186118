<template>
    <div v-if="loading" :class="['plan-header', { 'plan-header--mobile': isMobile }]">
        <HeaderSkeleton />
    </div>
    <div v-else-if="!isMobile" :class="['plan-header', { 'plan-header--disabled': !getLanguageLevel }]">
        <div v-if="isMoreCoursesThanOne" @click="toggleMenu" v-click-outside="hideMenu" class="change-course-plan">
            <div class="text-wrapper">
                <span class="title">{{ $t('new-course-plan.header.dropdown-title') }}</span>
                <span :class="['arrow', { 'arrow--active': showMenu }]"></span>
            </div>
            <dropdown v-if="showMenu" class="dropdown" pos="bottom">
                <div class="change-course">
                    <img src="@/assets/img/flags/eng.png"/>
                    <span class="change-course__title">{{ $t('elearning.course-plan.header.change-course') }}</span>
                </div>
                <div v-for="(course, idx) in getAvailableCourseLevels" :key="idx" @click="changeCoursePlan(course.uuid)" :class="['dropdown-item', { 'dropdown-item--active': course.uuid === getChosenCourseLevelUuid }]">
                    <span :class="['dropdown-item__level', { 'dropdown-item__level--active': course.uuid === getChosenCourseLevelUuid }]">{{ course.name }}</span>
                    <span class="dropdown-item__kind">{{ getCourseKind(course) }}</span>
                    <img v-if="course.uuid === getChosenCourseLevelUuid" class="check" src="@/assets/img/check.svg"/>
                </div>
            </dropdown>
        </div>
        <span class="plan-header__title">{{ $t('elearning.course-plan.header.title') }}:</span>
        <span :class="['header', { 'header--only' : !isMoreCoursesThanOne }]">{{ getKind(getChosenLearningCoursePlan) }} {{ getCoursePlanTitle }}</span>
    </div>
    <div v-else :class="['plan-header plan-header--mobile', { 'plan-header--disabled': !getLanguageLevel } ]">
        <div class="mobile-header">
            <span class="plan-header__title plan-header__title--mobile">{{ $t('elearning.course-plan.header.title') }}:</span>
            <span class="header">{{ getCoursePlanTitle }}</span>
        </div>
        <div v-if="isMoreCoursesThanOne" @click="toggleMenu" v-click-outside="hideMenu" class="change-course-plan">
            <div class="text-wrapper">
                <span :class="['arrow', { 'arrow--active': showMenu, 'arrow--mobile': isMobile }]"></span>
            </div>
            <dropdown v-if="showMenu" class="dropdown dropdown--mobile" pos="bottom">
                <div class="change-course">
                    <img src="@/assets/img/flags/eng.png"/>
                    <span class="change-course__title">{{ $t('elearning.course-plan.header.change-course') }}</span>
                </div>
                <div v-for="(course, idx) in getAvailableCourseLevels" :key="idx" @click="changeCoursePlan(course.uuid)" :class="['dropdown-item', { 'dropdown-item--active': course.uuid === getChosenCourseLevelUuid }]">
                    <span :class="['dropdown-item__level', { 'dropdown-item__level--active': course.uuid === getChosenCourseLevelUuid }]">{{ course.name }}</span>
                    <span class="dropdown-item__kind">{{ getCourseKind(course) }}</span>
                    <img v-if="course.uuid === getChosenCourseLevelUuid" class="check" src="@/assets/img/check.svg"/>
                </div>
            </dropdown>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Dropdown from '@/components/reusable/Dropdown'
import dropdownMixin from '@/mixins/dropdown'
import HeaderSkeleton from '@/components/learning/coursePlan/skeletons/HeaderSkeleton';

export default {
    name: 'PlanHeader',
    components: { Dropdown, HeaderSkeleton },
    mixins: [dropdownMixin],
    computed: {
        ...mapState(['isMobile']),
        ...mapState('learningCoursePlan', ['count', 'loading']),
        ...mapGetters('learningCoursePlan', ['getAvailableCourseLevels', 'getChosenCourseLevelUuid', 'getChosenLearningCoursePlan']),
        ...mapGetters(['getLanguageLevel']),
        getCoursePlanTitle () {
            return `${ this.getChosenLearningCoursePlan?.language.name } - ${ this.getChosenLearningCoursePlan?.languageLevel.shortName }`;
        },
        isMoreCoursesThanOne() {
            return this.getAvailableCourseLevels.length > 1
        }
    },
    methods: {
        getKind(course) {
            return course?.languageLevel.isBusiness || course?.isBusiness ? 'Business' : 'General'
        },
        getCourseKind(course) {
            return `${ this.getKind(course) } ${ course.language.name }`
        },
        // getLevel(course) {
        //     return `${ course.languageLevel.shortName }`
        // },
        changeCoursePlan(uuid) {
            if(uuid === this.getChosenCourseLevelUuid) return;

            this.hideMenu();
            this.$store.commit('learningCoursePlan/setLearningCoursePlanUuid', uuid);
            this.$router.push({ name: 'learningCoursePlan', params: { coursePlanUuid: uuid } });
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(180deg, #3A9CFF 0%, #0782FF 100%);
    padding: 24px 32px;
    border-radius: 10px;
    width: 100%;
    color: $white;
    min-height: 165px;

    &__title {
        @include font-h2;
        margin-bottom: -9px;

        &--mobile {
            @include font-h3;
            font-weight: 500;
            margin-bottom: 8px;
        }
    }

    .header {
        @include font-h1;
    }

    &--mobile {
        flex-direction: row;
        align-items: center;
        padding: 16px 24px;
        min-height: auto;

        .header {
            margin-top: 0;
            @include font-h2;
        }
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }

    .mobile-header {
        display: flex;
        flex-direction: column;
    }

    .change-course-plan {
        margin-left: auto;
        position: relative;
        cursor: pointer;

        .text-wrapper {
            display: flex;
            gap: 6px;
            align-items: center;

            .arrow {
                transform: rotate(0deg);
                transition: all $arrow-transition-time;
                -webkit-mask-size: cover;
                mask-size: cover;
                width: 16px;
                height: 16px;
                background-color: $white;
                -webkit-mask-image: url("~@/assets/img/arrow.svg");
                mask-image: url("~@/assets/img/arrow.svg");

                &--active {
                    transform: rotate(180deg);
                    filter: none;
                }

                &--mobile {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .dropdown {
            min-width: 343px;
            transform: translate(-80%, 100%);
            padding: 8px;
            overflow: hidden;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);

            &--mobile {
                min-width: 316px;
                transform: translate(-95%, 100%);
            }

            .change-course {
                display: flex;
                align-items: center;
                padding: 6px 8px 14px 8px;
                gap: 12px;
                border-bottom: 1px solid $gray-200;
                margin-bottom: 8px;
                cursor: default;

                &__title {
                    @include font-medium;
                }
            }

            span {
                padding: 0;
                border: none;
                height: auto;
                margin: 0;
                background: none;
            }
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px !important;

            &:not(:last-of-type) {
                margin-bottom: 8px;
            }

            &:hover {
                background-color: $gray-200 !important;
                border-radius: 4px;
            }

            &--active {
                .dropdown-item__level {
                    background: $primary !important;
                    color: $white;
                }

                background-color: $primary-light;
                border-radius: 4px;

                &:hover {
                    background-color: $primary-light;
                }
            }

            &__level {
                color: $primary;
                background: $gray-100 !important;
                border-radius: 4px;
                padding: 4px !important;
                min-width: 44px;
                text-align: center;

                &--soon {
                    background: $gray-600 !important;
                    color: $white;
                }
            }

            &__kind {
                @include font-regular;
            }

            .check {
                margin-left: auto;
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>