<template>
    <div class="langDropdown" v-click-outside="hideMenu">
        <ToolsPill 
            :class="{ 'tools-pill--active' : showMenu }"
            :lang="chosenLang"
            @click.native="toggleMenu"
            pointer
            :shorter="shorter"
        />
        <Dropdown
            v-if="showMenu"
            pos="bottom"
        >
            <div
                :class="['lang', { 'active' : chosenLang === lang }]"
                v-for="lang in langs"
                :key="lang"
                @click="setLang(lang)"
            >
                {{ $i18n.t(`general.language.${ lang }`) }}
            </div>
        </Dropdown>
    </div>
</template>

<script>
import ToolsPill from '@/components/tools/ToolsPill';
import ClickOutside from 'vue-click-outside';
import dropdownMixin from '@/mixins/dropdown'
import Dropdown from '@/components/reusable/Dropdown';

export default {
    name: 'TranslatorLangs',
    components: { Dropdown, ToolsPill },
    props: {
        langs: {
            type: Array
        },
        chosenLang: {
            type: String
        },
        shorter: {
            type: Boolean,
            default: false
        }
    },
    mixins: [dropdownMixin],
    methods: {
        setLang(lang) {
            this.$emit('setLang', lang);
            this.hideMenu();
        }
    },
    directives: { ClickOutside }
}
</script>

<style lang="scss" scoped>
.langDropdown {
    flex-shrink: 0;

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        background: #F2F3F4;
        border-radius: 48px;
        gap: 8px;

        .text {
            @include font-h4;
            color: $gray-900;
        }
    }
}
</style>