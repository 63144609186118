<template>
    <div class="form">
        <div class="title-section">
            <h2 class="title">{{$i18n.t(`reset-password.${step === 2 ? 'after-send.' : ''}title`)}}</h2>
            <p class="dsc">{{$i18n.t(`reset-password.${step === 2 ? 'after-send.' : ''}subtitle`)}}</p>
        </div>
        <ValidationObserver v-if="step === 1" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider v-for="{name, rules, component, data} in form" :key="name" :rules="rules" v-slot="{classes, errors}">
                    <component :is="component" v-model="formData[name]" :class="classes" :data="data"/>
                    <p class="invalid-feedback">{{errors[0]}}</p>
                </ValidationProvider>
                <Button class="primary b2" :loading="submitLoading" :text="$i18n.t('reset-password.form.submit')"/>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Button from '../components/reusable/Button'
import { resetPassword as form } from '@/forms'
import { schoolV1 } from '@/plugins/axios'

export default {
    name: 'ResetPassword',
    components: {
        Button
    },
    data() {
        return {
            form,
            formData: {},
            step: 1,
            submitLoading: false
        }
    },
    methods: {
        async submit() {
            this.submitLoading = true
            await schoolV1.post('account/password/forgot', {
                email: this.formData.email
            })
            this.submitLoading = false
            this.step = 2
        }
    }
}
</script>