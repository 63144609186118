<template>
    <div class="nav-wrapper">
        <div v-show="showNav" class="bg-white nav-bar">
            <div class="wrapper wrapper--big">
                <Nav />
            </div>
        </div>
        <div :class="[
            'wrapper', 
            'wrapper--small',
            'content-box',
            { 'wrapper--tools': this.$route.name === 'tools' },
            { 'wrapper--tools-askAI': mobileAskAITool },
            { 'blur': blurBackground }
        ]">
            <router-view />
        </div>
        <div>
            <div class="wrapper wrapper--big">
                <PrivacyFooter v-if="showFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/layout/Nav'
import PrivacyFooter from '@/components/layout/PrivacyFooter'

export default {
    name: 'NavWrapper',
    components: {
        Nav,
        PrivacyFooter
    },
    computed: {
        ...mapState(['isMobile']),
        ...mapState('tools', ['activeTool']),
        blurBackground() {
            return this.$store.state.blurBackground
        },
        showFooter() {
            return !['messages', 'messagesOnly'].includes(this.$route.name)
        },
        mobileAskAITool() {
            return this.$route.name === 'tools' && this.isMobile && this.activeTool === 'askAI'
        },
        showNav() {
            return !(this.isMobile && this.$route.name === 'tools' && this.activeTool === 'askAI')
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrapper--tools {
        padding: 65px 0 0 0;

        @media(min-width: $mobile-tools) {
            padding: calc(64px + 16px) 16px 0;
        }

        @media(min-width: $mobile) {
            padding: calc(64px + 32px) 16px 0;
        }
    }

    .wrapper--tools-askAI {
        padding: 0 !important;
    }
</style>
